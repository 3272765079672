import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import useTollExpenseHistoryService from '../../../app/services/hooks/useTollExpenseHistoryService';
import {
    TollExpenseHistoryDataForm,
    TollExpenseHistoryFilter
} from '../../../app/models/TollExpenseHistory';
import TollExpenseHistoryDataTable from './tables/TollExpenseHistoryDataTable';
import TollExpenseHistoryEdit from './TollExpenseHistoryEdit';

interface Props {
    tollExpenseId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TollExpenseHistoryContainer = ({ tollExpenseId, onSaved, onCancel, onError }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getAllTollExpensehistoriesByTollExpense,
        fetchingGetTollExpenseHistoriesByTollExpense
    } = useTollExpenseHistoryService();

    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);

    const [tollExpenseHistoryIdEdit, setTollExpenseHistoryIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [tollExpenseHistories, setTollExpenseHistories] = useState<TollExpenseHistoryDataForm[]>(
        []
    );
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<TollExpenseHistoryFilter>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const showEdit = (TollExpenseHistoryId: number) => {
        setShowingEdit(true);
        setTollExpenseHistoryIdEdit(TollExpenseHistoryId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setTollExpenseHistoryIdEdit(-1);
    };

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getAllTollExpensehistoriesByTollExpense(filter, tollExpenseId, {
            onSuccess: (response: ServiceResponse) => {
                setTollExpenseHistories(response.data.toll_expense_histories);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    if (onError) onError();
                }
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <TollExpenseHistoryDataTable
                            tollExpenseHistories={tollExpenseHistories}
                            edit={showEdit}
                            loading={fetchingGetTollExpenseHistoriesByTollExpense}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                        />
                    </div>
                </div>
            </DefaultCard>

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar información"
                    backdrop={true}
                    showFooter={false}
                    centered={true}
                >
                    <TollExpenseHistoryEdit
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                        tollExpenseHistoryId={tollExpenseHistoryIdEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default TollExpenseHistoryContainer;
