import { TruckDataForm, TruckFilters } from '../../../../app/models/Truck';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ActiveControl from '../../../../components/ActiveControl';
import { HopperDataForm } from '../../../../app/models/Hopper';
import HopperFilter from './HopperFilter';
import HopperSwitchChange from '../HopperSwitchChange';

interface Props {
    hoppers: HopperDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: TruckFilters;
    setFilter: (filter: TruckFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const HopperDataTable = ({
    hoppers,
    totalRows,
    loading = false,
    edit,
    destroy,
    active,
    show,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: HopperDataForm, index: any, column: any) => (
                <span className="">{row?.id}</span>
            )
        },
        {
            name: 'Sociedad',
            selector: (row: any) => row?.society,
            sortable: true,
            sortField: 'society',
            cell: (row: HopperDataForm, index: any, column: any) => (
                <span className="">{row?.society}</span>
            )
        },
        {
            name: 'Proveedor',
            selector: (row: any) => row?.provider,
            sortable: true,
            sortField: 'provider',
            cell: (row: HopperDataForm, index: any, column: any) => (
                <span className="">{row?.provider}</span>
            )
        },
        {
            name: 'Patente',
            selector: (row: any) => row?.patent,
            sortable: true,
            sortField: 'patent',
            cell: (row: HopperDataForm, index: any, column: any) => (
                <span className="">{row?.patent}</span>
            )
        },
        {
            name: 'Marca',
            selector: (row: any) => row?.brand.name,
            sortable: true,
            sortField: 'brand',
            cell: (row: HopperDataForm, index: any, column: any) => (
                <span className="">{row?.brand?.name}</span>
            )
        },
        {
            name: 'Modelo',
            selector: (row: any) => row?.vehicle_model.name,
            sortable: true,
            sortField: 'vehicle_model',
            cell: (row: HopperDataForm, index: any, column: any) => (
                <span className="">{row?.vehicle_model?.name}</span>
            )
        },
        {
            name: 'Año',
            selector: (row: any) => row?.year,
            sortable: true,
            sortField: 'year',
            cell: (row: HopperDataForm, index: any, column: any) => (
                <span className="">{row?.year}</span>
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: HopperDataForm, index: any, column: any) => (
                <HopperSwitchChange
                    checkValue={row.is_active ?? true}
                    hopperId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: HopperDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <HopperFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={hoppers}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default HopperDataTable;
