import React, { useContext } from 'react';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import QuoteChangeStatus from '../QuoteChangeStatus';
import { QuoteStatus } from '../../../../app/models/QuoteStatus';
import { momentParseDate } from '../../../../helpers';
import {
    Quote,
    QuoteDataForm,
    QuoteDataFormFilters,
    TransportationQuoteDetailDataForm
} from '../../../../app/models/Quote';
import { formatMoney } from '../../../../utils/utils';
import { Roles } from '../../../../app/shared/enums';
import { AuthContext } from '../../../../contexts/AuthContext';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import QuoteFilter from './QuoteFilter';

interface Props {
    quotes: Quote[];
    statuses: QuoteStatus[];
    callbackSuccess?: () => void;
    totalRows: number;
    loading?: boolean;
    edit?: (quoteId: number) => void;
    show?: (quoteId: number) => void;
    destroy?: (quoteId: number) => void;
    repeat?: (quoteId: number) => void;
    documentQuote?: (quoteId: number) => void;
    filter: QuoteDataFormFilters;
    setFilter: (filter: QuoteDataFormFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const QuoteDataTable = ({
    quotes,
    totalRows,
    statuses,
    callbackSuccess,
    loading = false,
    edit,
    destroy,
    show,
    repeat,
    documentQuote,
    filter,
    setFilter
}: Props) => {
    const { auth } = useContext(AuthContext);

    const columns = [
        {
            name: 'Oferta',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: Quote, index: any, column: any) => <span className="">{row.id}</span>
        },
        {
            name: 'Cliente',
            selector: (row: any) => row.client.business_name,
            sortable: true,
            sortField: 'client_business_name',
            cell: (row: Quote, index: any, column: any) => (
                <span className="">{row?.client?.business_name}</span>
            )
        },
        {
            name: 'Servicio',
            selector: (row: any) => row.service_name,
            sortable: false,
            sortField: 'service_name',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.service_name}</span>
            )
        },
        {
            name: 'Sociedad',
            selector: (row: any) => row.society.name,
            sortable: true,
            sortField: 'society_business_name',
            cell: (row: Quote, index: any, column: any) => (
                <span className="">{row.society?.business_name}</span>
            )
        },
        {
            name: 'Total',
            selector: (row: any) => row.total,
            sortable: false,
            sortField: 'total_rate',
            align: (cell: any, row: any) => {
                return 'right';
            },
            cell: (row: any, index: any, column: any) => {
                if (row.currency && row.currency.name && row.total) {
                    return (
                        <span className="nowrap-cell">
                            {formatMoney(row.total, row.currency.name)}
                        </span>
                    );
                }
                return '';
            }
        },
        {
            name: 'Moneda',
            selector: (row: any) => row.currency.name,
            sortable: true,
            sortField: 'currency_name',
            cell: (row: Quote, index: any, column: any) => (
                <span className="">{row.currency?.name}</span>
            )
        },
        {
            name: 'F. Expiración',
            selector: (row: any) => row.expired_at,
            sortable: true,
            sortField: 'expired_at',
            cell: (row: any, index: any, column: any) => {
                return momentParseDate(row.expired_at);
            }
        },
        {
            name: 'Estado',
            selector: (row: any) => row.status,
            sortable: true,
            sortField: 'status',
            cell: (row: QuoteDataForm, index: any, column: any) => {
                const status = statuses.find((quoteStatus) => quoteStatus.id === row.status);
                if (statuses.length == 0) {
                    return '';
                }
                return (
                    <QuoteChangeStatus
                        callbackSuccess={callbackSuccess}
                        statuses={row.status_choices! ?? []}
                        selectedStatus={status!}
                        quoteId={row.id!}
                    />
                );
            }
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: QuoteDataForm, index: any, column: any) => {
                const lockedDestroy = row.status == 'CONFIRMADA';
                return (
                    <div>
                        {documentQuote && (
                            <ButtonTableAction
                                callbackFunction={() => documentQuote(row.id ?? -1)}
                                classIcon={'mdi mdi-download'}
                                colorIcon={'text-success'}
                                errorMessage={'No se puede descargar este registro.'}
                                title={'Descargar'}
                            />
                        )}
                        {repeat && (
                            <ButtonTableAction
                                callbackFunction={() => repeat(row.id ?? -1)}
                                classIcon={'mdi mdi-content-copy'}
                                colorIcon={'text-secondary'}
                                errorMessage={'No se puede duplicar este registro.'}
                                title={'Duplicar'}
                            />
                        )}
                        {show && (
                            <ButtonTableAction
                                callbackFunction={() => show(row.id ?? -1)}
                                classIcon={'mdi mdi-eye'}
                                colorIcon={'text-info'}
                                errorMessage={'No se puede mostrar este registro.'}
                                title={'Ver'}
                            />
                        )}
                        {edit && (
                            <ButtonTableAction
                                callbackFunction={() => edit(row.id ?? -1)}
                                classIcon={'mdi mdi-pencil'}
                                colorIcon={'text-warning'}
                                errorMessage={'No se puede editar este registro.'}
                                title={'Editar'}
                            />
                        )}
                        {destroy && (
                            <ButtonTableAction
                                callbackFunction={() => destroy(row.id ?? -1)}
                                locked={lockedDestroy}
                                classIcon={'mdi mdi-delete'}
                                colorIcon={'text-danger'}
                                errorMessage={'No se puede eliminar este registro.'}
                                title={'Eliminar'}
                            />
                        )}
                    </div>
                );
            }
        }
    ];

    return (
        <div className="row">
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={quotes}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
        </div>
    );
};

export default QuoteDataTable;
