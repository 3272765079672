import React from 'react';

import ClientForm from './forms/ClientForm';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { Bank } from '../../../app/models/Bank';
import { Provision } from '../../../app/models/Provision';
import { Afp } from '../../../app/models/Afp';
import { ClientDataForm } from '../../../app/models/Client';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { User } from '../../../app/models/User';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    clientForm: ClientDataForm;
    setClientForm: (client: ClientDataForm) => void;
    users: User[];
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const ClientFormContainer = ({
    fetching,
    action,
    cancel,
    clientForm,
    setClientForm,
    users,
    errorFields = null,
    setErrorFields
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ClientForm
                        clientForm={clientForm}
                        setClientForm={setClientForm}
                        users={users}
                        errorFields={errorFields}
                        setErrorFields={setErrorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ClientFormContainer;
