import React, { useContext } from 'react';
import {
    TransportationOrderDataForm,
    TransportationOrderFilters
} from '../../../../app/models/TransportationOrder';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import TransportationOrderFilter from '../TransportationOrderFilter';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../../../utils/utils';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import { AuthContext } from '../../../../contexts/AuthContext';
import { Roles } from '../../../../app/shared/enums';

interface Props {
    transportationOrders: TransportationOrderDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (transportationOrderId: number) => void;
    destroy?: (transportationOrderId: number) => void;
    filter: TransportationOrderFilters;
    setFilter: (filter: TransportationOrderFilters) => void;
    paginationRowsPerPageOptions?: any[];
    show?: (quoteId: number) => void;
    repeat?: (quoteId: number) => void;
    documentQuote?: (quoteId: number) => void;
    downloadExcel?: () => void;
    onSearch: () => void;
}

const TransportationOrderDataTable = ({
    transportationOrders,
    totalRows,
    loading = false,
    edit,
    destroy,
    filter,
    setFilter,
    show,
    repeat,
    documentQuote,
    downloadExcel,
    onSearch
}: Props) => {
    const { auth } = useContext(AuthContext);

    const columns = [
        {
            name: 'Pedidos',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: TransportationOrderDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Cotización',
            selector: (row: any) => row.transportation_quote_detail?.quote_id,
            sortable: true,
            sortField: 'quote_id',
            cell: (row: any, index: any, column: any) => {
                return row.transportation_quote_detail ? (
                    <Link to={`/quotes/${row.transportation_quote_detail.quote_id}/show`}>
                        #{row.transportation_quote_detail.quote_id}
                    </Link>
                ) : null;
            }
        },
        {
            name: 'Cliente',
            selector: (row: any) => row.client.name,
            sortable: true,
            sortField: 'client',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.client?.business_name}</span>
            )
        },
        {
            name: 'Total guías',
            selector: (row: any) => row.row.total_guides,
            sortable: true,
            sortField: 'total_guides',
            cell: (row: any, index: any, column: any) => {
                return row.total_guides ? formatNumber(row.total_guides) : '';
            }
        },
        {
            name: 'Estado',
            selector: (row: any) => row.status_selected.name,
            sortable: true,
            sortField: 'status',
            cell: (row: any, index: any, column: any) => {
                return row.status_selected.name;
            }
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: TransportationOrderDataForm, index: any, column: any) => (
                <div className="">
                    {documentQuote && (
                        <ButtonTableAction
                            callbackFunction={() => documentQuote(row.id ?? -1)}
                            classIcon={'mdi mdi-download'}
                            colorIcon={'text-success'}
                            errorMessage={'No se puede descargar este registro.'}
                            title={'Descargar'}
                        />
                    )}
                    {repeat && (
                        <ButtonTableAction
                            callbackFunction={() => repeat(row.id ?? -1)}
                            classIcon={'mdi mdi-content-copy'}
                            colorIcon={'text-secondary'}
                            errorMessage={'No se puede duplicar este registro'}
                            title={'Duplicar'}
                        />
                    )}
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && auth?.roles && !auth?.roles.includes(Roles.RUNNER) && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <div className="row mt-3">
            <div className="col">
                <TransportationOrderFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
            </div>
            <div className="col-auto" style={{ paddingTop: 22 }}>
                <button className="btn btn-success btn-sm" onClick={downloadExcel}>
                    <i className="fa fa-file-excel" /> Descargar
                </button>
            </div>
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={transportationOrders}
                    progressPending={loading}
                    paginationTotalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                />
            </div>
        </div>
    );
};

export default TransportationOrderDataTable;
