import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

import { ServiceEvents } from '../shared/interfaces';
import { DeductionRateDataForm } from '../../models/DeductionRate';

const useDeductionRateService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetDeductionRatesByDeduction, setFetchingGetDeductionRatesByDeduction] = useState(false);
    const [fetchingStoreDeductionRate, setFetchingStoreDeductionRate] = useState(false);
    const [fetchingEditDeductionRate, setFetchingEditDeductionRate] = useState(false);
    const [fetchingUpdateDeductionRate, setFetchingUpdateDeductionRate] = useState(false);
    const [fetchingDeleteDeductionRate, setFetchingDeleteDeductionRate] = useState(false);
    const [fetchingCreateDeductionRate, setFetchingCreateDeductionRate] = useState(false);

    const getAllDeductionRatesByDeduction = (deduction_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DEDUCTION_RATES.GET_DEDUCTION_RATES_BY_DEDUCTION.replace(
                ':deduction_id',
                deduction_id.toString()
            ),
            setFetching: setFetchingGetDeductionRatesByDeduction
        });
    };

    const createDeductionRate = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DEDUCTION_RATES.CREATE_DEDUCTION_RATE,
            setFetching: setFetchingCreateDeductionRate
        });
    };

    const storeDeductionRate = (DeductionRateDataForm: DeductionRateDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.DEDUCTION_RATES.STORE_DEDUCTION_RATE,
            body: DeductionRateDataForm,
            setFetching: setFetchingStoreDeductionRate
        });
    };

    const editDeductionRate = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DEDUCTION_RATES.EDIT_DEDUCTION_RATE.replace(':id', id.toString()),
            setFetching: setFetchingEditDeductionRate
        });
    };

    const updateDeductionRate = (
        id: number,
        DeductionRateDataForm: DeductionRateDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.DEDUCTION_RATES.UPDATE_DEDUCTION_RATE.replace(':id', id.toString()),
            body: DeductionRateDataForm,
            setFetching: setFetchingUpdateDeductionRate
        });
    };

    const deleteDeductionRate = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.DEDUCTION_RATES.DELETE_DEDUCTION_RATE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteDeductionRate
        });
    };

    return {
        getAllDeductionRatesByDeduction,
        createDeductionRate,
        storeDeductionRate,
        editDeductionRate,
        updateDeductionRate,
        deleteDeductionRate,
        fetchingGetDeductionRatesByDeduction,
        fetchingStoreDeductionRate,
        fetchingEditDeductionRate,
        fetchingUpdateDeductionRate,
        fetchingDeleteDeductionRate,
        fetchingCreateDeductionRate,
    };
};

export default useDeductionRateService;
