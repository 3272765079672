import { useContext, useEffect, useState } from 'react';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { Currency } from '../../../app/models/Currency';
import { useHistory, useParams } from 'react-router-dom';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import BonusRateForm from '../components/forms/BonusRateForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import { formatDateToInputMonth } from '../../../helpers';
import useBonusRateService from '../../../app/services/hooks/useBonusRateService';
import { TypeOfBonusesValues } from '../../../app/models/Bonus';
import { Item } from '../../../app/models/Item';
import { BonusRateDataForm, defaultBonusRateDataForm } from '../../../app/models/BonusRate';

interface Props {
    bonusRateId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const BonusRateEdit = ({ bonusRateId, onSaved, onCancel, onError }: Props) => {

    const history = useHistory();
    const { fetchingEditBonusRate, editBonusRate, fetchingUpdateBonusRate, updateBonusRate } = useBonusRateService();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [errorFields, setErrorFields] = useState<any>();
    const [bonusRate, setBonusRate] = useState<BonusRateDataForm>(defaultBonusRateDataForm);
    const [bonusTypes, setBonusTypes] = useState<TypeOfBonusesValues[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [items, setItems] = useState<Item[]>([])
    const [formulaItems, setFormulaItems] = useState<string[]>([])

    useEffect(() => {
        edit(bonusRateId);
    }, [bonusRateId]);

    useEffect(() => {
        if(bonusRate.bonus_type === 'Calculado' && bonusRate.formula){
            const formula = bonusRate.formula;
            const items = formula.match(/\{.*?\}/g);
            if (items) setFormulaItems(items)
        }
    }, [bonusRate]);

    const edit = (bonusRateId: number) => {
        if (showLoading) showLoading('loading', 'Cargando tarifa de bonificación...');
        editBonusRate(bonusRateId, {
            onSuccess: (response: ServiceResponse) => {
                setBonusRate({
                    ...response.data.bonus_rate,
                    valid_from: formatDateToInputMonth(response.data.bonus_rate.valid_from),
                    valid_until: formatDateToInputMonth(response.data.bonus_rate.valid_until)
                });
                setBonusTypes(response.data.type_of_bonuses);
                setCurrencies(response.data.currencies);
                setItems(response.data.item_list);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando tarifa de bonificación...');
        updateBonusRate(bonusRateId, bonusRate, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if(onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };


    return fetchingEditBonusRate ? (
        <LazyLoading height="300" />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <BonusRateForm 
                        bonusRateForm={bonusRate} 
                        setBonusRateForm={setBonusRate}
                        TypeOfBonuses={bonusTypes}
                        currencies={currencies}
                        items={items}
                        formulaItems={formulaItems}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingUpdateBonusRate} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateBonusRate} />
                </div>
            </div>
        </>
    );
}

export default BonusRateEdit;