import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';

import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { UnitEquivalenceFilters, UnitEquivalence } from '../../models/Unit';

const useUnitEquivalenceService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetUnitEquivalence, setFetchingGetUnitEquivalence] = useState(false);
    const [fetchingCreateUnitEquivalence, setFetchingCreateUnitEquivalence] = useState(false);
    const [fetchingStoreUnitEquivalence, setFetchingStoreUnitEquivalence] = useState(false);
    const [fetchingEditUnitEquivalence, setFetchingEditUnitEquivalence] = useState(false);
    const [fetchingUpdateUnitEquivalence, setFetchingUpdateUnitEquivalence] = useState(false);
    const [fetchingDeleteUnitEquivalence, setFetchingDeleteUnitEquivalence] = useState(false);
    const [fetchingActiveUnitEquivalence, setFetchingActiveUnitEquivalence] = useState(false);
    const [fetchingCalculateUnitEquivalence, setFetchingCalculateUnitEquivalence] = useState(false);

    const getUnitEquivalences = (
        UnitEquivalenceFilters: UnitEquivalenceFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(UnitEquivalenceFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.UNIT_EQUIVALENCE.GET_UNIT_EQUIVALENCES}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetUnitEquivalence
        });
    };

    const createUnitEquivalence = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.UNIT_EQUIVALENCE.CREATE_UNIT_EQUIVALENCE,
            setFetching: setFetchingCreateUnitEquivalence
        });
    };

    const storeUnitEquivalence = (UnitEquivalence: UnitEquivalence, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.UNIT_EQUIVALENCE.STORE_UNIT_EQUIVALENCE,
            body: UnitEquivalence,
            setFetching: setFetchingStoreUnitEquivalence
        });
    };

    const editUnitEquivalence = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.UNIT_EQUIVALENCE.EDIT_UNIT_EQUIVALENCE.replace(':id', id.toString()),
            setFetching: setFetchingEditUnitEquivalence
        });
    };

    const updateUnitEquivalence = (
        id: number,
        UnitEquivalence: UnitEquivalence,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.UNIT_EQUIVALENCE.UPDATE_UNIT_EQUIVALENCE.replace(':id', id.toString()),
            body: UnitEquivalence,
            setFetching: setFetchingUpdateUnitEquivalence
        });
    };

    const deleteUnitEquivalence = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.UNIT_EQUIVALENCE.DELETE_UNIT_EQUIVALENCE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteUnitEquivalence
        });
    };

    const activeUnitEquivalence = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.UNIT_EQUIVALENCE.ACTIVE_UNIT_EQUIVALENCE.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveUnitEquivalence
        });
    };

    const calculateUnitEquivalence = (
        unitEquivalence: UnitEquivalence,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.UNIT_EQUIVALENCE.CALCULATE_UNIT_EQUIVALENCE,
            body: unitEquivalence,
            setFetching: setFetchingCalculateUnitEquivalence
        });
    };

    return {
        fetchingGetUnitEquivalence,
        fetchingCreateUnitEquivalence,
        fetchingStoreUnitEquivalence,
        fetchingEditUnitEquivalence,
        fetchingUpdateUnitEquivalence,
        fetchingDeleteUnitEquivalence,
        fetchingActiveUnitEquivalence,
        fetchingCalculateUnitEquivalence,
        getUnitEquivalences,
        createUnitEquivalence,
        storeUnitEquivalence,
        editUnitEquivalence,
        updateUnitEquivalence,
        deleteUnitEquivalence,
        activeUnitEquivalence,
        calculateUnitEquivalence
    };
};

export default useUnitEquivalenceService;
