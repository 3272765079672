import { EventType } from './EventType';
import { Worker } from './Workers';

export interface CalendarRequest {
    id?: number;
    worker_id?: number;
    start_date: Date | string;
    end_date: Date | string;
    description: string;
    event_type?: EventType;
    event_type_id?: number;
    state?: boolean;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface CalendarRequestfilter {
    id?: number | null;
    user_id?: number | null;
    worker_id?: number | null;
    event_type_name?: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface CalendarRequestDataForm {
    id?: number;
    start_date: Date | string;
    state?: boolean;
    worker?: Worker;
    worker_id?: number;
    end_date: Date | string;
    event_type?: EventType;
    event_type_id?: number;
    document_file?: File;
    photo_url?: string;
    description: string;
}

export const defaultCalendarRequestDataForm: CalendarRequestDataForm = {
    start_date: '',
    end_date: '',
    description: '',
    event_type_id: 0,
    worker_id: undefined
};
