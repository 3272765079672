import React, { useCallback, useContext, useState } from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useEventService from '../../../app/services/hooks/useEventService';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import WorkerShiftWorkUpdateOptionForm from './forms/WorkerShiftWorkUpdateOptionForm';
import { CalendarEventDataForm } from '../../../app/models/Event';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { momentParseDate } from '../../../helpers';

interface Props {
    ShiftWork: CalendarEventDataForm;
    workerId: number;
    selectedDate: string;
    onCancel?: () => void;
    onError?: () => void;
}

type OptionType = 'all' | 'next';

const WorkerShiftWorkUpdateOption = ({
    ShiftWork,
    workerId,
    selectedDate,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const { navigationPage } = useNavigationPage();
    const { updateAllShiftWorkEvent, updateNextShiftWorkEvent } = useEventService();

    const [selectedOption, setSelectedOption] = useState<OptionType | ''>('');
    const [isLocked, setIsLocked] = useState<boolean>(true);

    const dateToShow = momentParseDate(selectedDate);

    const optionConfig: Record<OptionType, { message: string; deleteAction: () => void }> = {
        all: {
            message: 'Está a punto de modificar todas las fechas de esta jornada de trabajo',
            deleteAction: () =>
                updateAllShiftWorkEvent(ShiftWork, {
                    onSuccess: onSuccesAction,
                    onError: onErrorAction
                })
        },
        next: {
            message: `Está a punto de modificar esta jornada a partir de esta fecha ${dateToShow}`,
            deleteAction: () =>
                updateNextShiftWorkEvent(ShiftWork, selectedDate, {
                    onSuccess: onSuccesAction,
                    onError: onErrorAction
                })
        }
    };

    const showConfirmAlert = () => {
        if (selectedOption === '') return;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: optionConfig[selectedOption].message,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Modificando jornada...');
                        optionConfig[selectedOption].deleteAction();
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La jornada no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const onSuccesAction = (response: ServiceResponse) => {
        if (hideLoading) hideLoading();
        useReactConfirmAlert().successAlert({
            title: 'Éxito',
            message: response.message
        });
        navigationPage(`/workers/${workerId}/edit?defaultActiveKey=calendar`);
    };

    const onErrorAction = (response: ServiceResponse) => {
        if (hideLoading) hideLoading();
        useReactConfirmAlert().errorAlert({
            title: 'Error',
            message: response.message
        });
    };

    const handleOptionChange = useCallback((option: OptionType) => {
        setSelectedOption(option);
        setIsLocked(false);
    }, []);

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <WorkerShiftWorkUpdateOptionForm
                        selectedDate={dateToShow}
                        updateMode={selectedOption}
                        update={handleOptionChange}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={showConfirmAlert}
                        locked={isLocked}
                        message="Seleccione una opción"
                    />
                </div>
            </div>
        </>
    );
};

export default WorkerShiftWorkUpdateOption;
