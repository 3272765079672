import { Client } from './Client';
import { RouteSegment } from './RouteSegment';
import { WorkOrder } from './WorkOrder';
import { Product } from './Product';
import { Truck } from './Truck';
import { Carrier } from './Carrier';
import { Hopper } from './Hopper';
import { DispatchProgramItem } from './DispatchProgramItem';
import { Guide } from './Guide';

export interface TransportOrder {
    id?: number;
    client_id?: number;

    currency_rate_id?: number;

    program_date?: string;

    program_time?: string;
    unit_rate_id?: number;

    rate?: number;

    is_type_sell?: boolean;

    is_external?: boolean;
    start_date?: string;
    client?: Client;
    status?: string;

    route_segment_id?: number;

    route_segment?: RouteSegment;

    truck_id?: number;

    truck?: Truck;

    carrier_id?: number;

    carrier?: Carrier;

    hopper_id?: number;

    hopper?: Hopper;

    work_order_id?: number;

    work_order?: WorkOrder;

    product_id?: number;

    product?: Product;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;

    external_rate?: number | string;
    currency_external_rate_id?: number;
    unit_external_rate_id?: number;

    commission?: number | string;
    unit_commission_id?: number;
    currency_commission_id?: number;
    name?: string;

    guide?: Guide;
}

export interface TransportOrderDataForm {
    id?: number;
    client_id?: number;
    currency_rate_id?: number;
    currency_external_rate_id?: number;
    program_date?: string;
    program_time?: string;
    unit_rate_id?: number;
    unit_external_rate_id?: number;
    rate?: number;
    external_rate?: number;
    is_type_sell?: boolean;
    is_external?: boolean;
    start_date?: string;
    client?: string;
    status?: string;
    route_segment_id?: number;
    route_segment?: RouteSegment;
    origin?: string;
    destiny?: string;
    truck_id?: number;
    truck?: string;
    carrier_id?: number;
    carrier?: Carrier;
    worker_id?: number;
    worker?: Worker;
    hopper_id?: number;
    hopper?: string;
    work_order_id?: number;
    work_order?: WorkOrder;
    product_id?: number;
    product?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
    commission?: number | string;
    unit_commission_id?: number;
    currency_commission_id?: number;
    period?: string;
    total_amount?: number;
    option_trip?: string;
    return_route_segment_id?: number;
    return_program_date?: string;
    return_program_time?: string;
    quote_id?: number;
    transportation_order_id?: number;
    transportation_order_item_id?: number;
    has_dpi?: boolean;
    dispatch_program_item?: DispatchProgramItem;
    confirm_program_date?: boolean;
    toll_expense_reported?: number;
    toll_expense_not_reported?: number;
    other_expense_reported?: number;
    other_expense_not_reported?: number;
}

export interface TransportOrderShowDataForm {
    id?: number;
    client?: string;

    currency_rate?: string;

    program_date?: string;

    program_time?: string;
    unit_rate?: string;

    rate?: number;

    is_type_sell?: boolean;
    is_external?: boolean;

    status?: string;

    route_segment?: string;

    truck?: string;

    carrier?: string;

    hopper?: string;

    work_order_id?: number;

    product?: string;

    created_at?: string;
    updated_at?: string;
    deleted_at?: string;

    external_rate?: number | string;
    currency_external_rate_id?: number;
    unit_external_rate_id?: number;

    commission?: number | string;
    unit_commission_id?: number;
    currency_commission_id?: number;

    option_trip?: string;
}

export interface TransportOrderFilters {
    id?: string | null;
    status?: string | null;
    work_order_id?: number | '';
    truck?: string | '';
    carrier_rut?: string | '';
    carrier_name?: string | '';
    carrier?: string | '';
    client?: string | '';
    program_date_from?: string | '';
    program_date_until?: string | '';
    issue_date_guide_from?: string | '';
    issue_date_guide_until?: string | '';
    folios?: string[];
    folio_list?: string[];
    period_from?: string | '';
    period_until?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface TransportOrderToDispatchProgramFilters {
    file?: File | undefined;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultTransportOrder: TransportOrderDataForm = {
    carrier_id: 0,
    hopper_id: 0,
    route_segment_id: 0,
    return_route_segment_id: 0,
    external_rate: undefined,
    currency_external_rate_id: undefined,
    unit_external_rate_id: undefined,
    commission: undefined,
    currency_commission_id: undefined,
    unit_commission_id: undefined,
    program_date: '',
    program_time: '',
    return_program_date: '',
    return_program_time: ''
};

export interface TransportOrderStatusRequestDataForm {
    id: number | string;
    name: number | string;
}

export interface TransportOrderOptionTrip {
    id: string;
    name: string;
}
