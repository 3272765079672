import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import LazyLoading from '../../../components/LazyLoading';
import DefaultModal from '../../../components/default/DefaultModal';
import useGuideService from '../../../app/services/hooks/useGuideService';
import { GuideDataForm, GuideFilters } from '../../../app/models/Guide';
import GuideCreate from './GuideCreate';
import GuideEdit from './GuideEdit';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import GuideDataTable from './tables/GuideDataTable';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { GuideHistoryDataForm, GuideHistoryFilter } from '../../../app/models/GuideHistory';
import GuideHistoryCreate from './GuideHistoryCreate';
import GuideHistoryContainer from './GuideHistoryContainer';

interface Props {
    transportOrderId: number;
    refreshKey?: boolean
}

const initialFilter = {
    page: 1,
    per_page: 10,
    sort: 'id',
    order: 'desc'
};

const GuideContainer = ({ transportOrderId, refreshKey }: Props) => {
    const history = useHistory();

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const {
        getAllGuidesByTransportOrder,
        fetchingGetGuidesByTransportOrder,
        deleteGuide,
        fetchingDeleteGuide,
        getUrlDocumentGuideOnTransportOrder,
        approvedGuide,
        fetchingGetUrlDocumentOnTransportOrder,
        cancelIsApprovedGuide
    } = useGuideService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);

    const [guideIdEdit, setGuideIdEdit] = useState<number>(-1);

    const [guides, setGuides] = useState<GuideDataForm[]>([]);

    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<GuideFilters>(initialFilter);

    const [showingCreateGuideHistory, setShowingCreateGuideHistory] = useState(false);
    const [guideHistoryIdCreate, setGuideHistoryIdCreate] = useState<number>(-1);

    const [showingGetGuideHistories, setShowingGetGuideHistories] = useState(false);
    const [guideHistoryIdGet, setGuideHistoryIdGet] = useState<number>(-1);
    const [guideHistory, setGuideHistory] = useState<GuideHistoryDataForm>({
        comment: '',
        is_approved: true
    });

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        reloadTable();
    }, [refreshKey]);

    const reloadTable = () => {
        getAllGuidesByTransportOrder(filter, transportOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setGuides(response.data.guides);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (guideId: number) => {
        setShowingEdit(true);
        setGuideIdEdit(guideId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setGuideIdEdit(-1);
    };

    const showCreateGuideHistory = (guideId: number) => {
        setShowingCreateGuideHistory(true);
        setGuideHistoryIdCreate(guideId);
    };

    const hideCreateGuideHistory = () => {
        setShowingCreateGuideHistory(false);
        setGuideHistoryIdCreate(-1);
        useReactConfirmAlert().infoAlert({
            title: 'Cancelado',
            message: `La guia no ha cambiado de estado.`
        });
    };

    const hideSuccessCreateGuideHistory = () => {
        setShowingCreateGuideHistory(false);
        setGuideHistoryIdCreate(-1);
    };

    const showGetGuideHistories = (guideId: number) => {
        setShowingGetGuideHistories(true);
        setGuideHistoryIdGet(guideId);
    };

    const hideGetGuideHistories = () => {
        setShowingGetGuideHistories(false);
        setGuideHistoryIdGet(-1);
    };

    const destroy = (guideId: number) => {
        const _text = 'Está a punto de eliminar la guia #' + guideId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando gasto de viaje...');
                        deleteGuide(transportOrderId, guideId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La guia no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const cancel = (guideId: number, approved: boolean) => {
        const _text = 'Está a punto de pasar el gasto #' + guideId + ` de ${approved ? 'aprobado' : 'rechazado'} a nulo`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando gasto de viaje...');
                        cancelIsApprovedGuide(guideId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El gasto de viaje no ha cambiado de estado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const document = (guideId: number) => {
        if (showLoading) showLoading('download', 'descargando archivo...');
        getUrlDocumentGuideOnTransportOrder(transportOrderId, guideId, {
            onSuccess: (response: ServiceResponse) => {
                window.open(response.data.url_file);
                if (changeAnimation)
                    changeAnimation('downloadSuccess', 'archivo recuperado con exito', true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) {
                    hideLoading();
                }
                toast.error(response.message);
            }
        });
    };
    const approved = (guideId: number, value: boolean, message: string) => {
        const _text = `La guia de despacho # ${guideId} está apunto de ser ${message}`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (value) {
                            getApprovedGuide(guideId, guideHistory);
                        } else {
                            showCreateGuideHistory(guideId);
                        }
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: `La guia no se ha ${message}.`
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const getApprovedGuide = (guideId: number, guideHistory: GuideHistoryDataForm) => {
        if (showLoading) showLoading('loading', `Cambiando estado de guia de despacho...`);
        approvedGuide(transportOrderId, guideId, guideHistory, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });

                reloadTable();
                hideSuccessCreateGuideHistory();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <h5>Guias de Despacho</h5>
                    <div className="col-12 mb-2 mt-2">
                        <ButtonCreate title="Nueva Guia" callbackCreate={showCreate} />
                    </div>

                    <div className="col-12">
                        {guides ? (
                            <GuideDataTable
                                loading={fetchingGetGuidesByTransportOrder}
                                guides={guides}
                                totalRows={totalRows}
                                edit={showEdit}
                                destroy={destroy}
                                approved={approved}
                                cancel={cancel}
                                filter={filter}
                                setFilter={setFilter}
                                documentGuide={document}
                                showGuideHistory={showGetGuideHistories}
                                onSearch={reloadTable}
                            />
                        ) : null}
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear guia"
                    backdrop={true}
                    showFooter={false}
                >
                    <GuideCreate
                        transportOrderId={transportOrderId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Guia"
                    backdrop={true}
                    showFooter={false}
                >
                    <GuideEdit
                        guideId={guideIdEdit}
                        transportOrderId={transportOrderId}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}

            {showingCreateGuideHistory ? (
                <DefaultModal
                    show={showingCreateGuideHistory}
                    handleClose={hideCreateGuideHistory}
                    title="Añadir información"
                    backdrop={true}
                    showFooter={false}
                >
                    <GuideHistoryCreate
                        guideId={guideHistoryIdCreate}
                        getApprovedGuide={getApprovedGuide}
                        errorFields={errorFields}
                        onSaved={() => {
                            reloadTable();
                            hideSuccessCreateGuideHistory();
                        }}
                        onCancel={hideCreateGuideHistory}
                        onError={hideCreateGuideHistory}
                    />
                </DefaultModal>
            ) : null}

            {showingGetGuideHistories ? (
                <DefaultModal
                    show={showingGetGuideHistories}
                    handleClose={hideGetGuideHistories}
                    title="Historial guia de despacho"
                    backdrop={true}
                    showFooter={false}
                    size="lg"
                >
                    <GuideHistoryContainer
                        guideId={guideHistoryIdGet}
                        onCancel={hideGetGuideHistories}
                        onError={hideGetGuideHistories}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default GuideContainer;
