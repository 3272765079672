import React, { useContext, useState, useEffect, useRef } from 'react';
import { formatMoney, formatNumber } from '../../../../utils/utils';
import { momentParseDate } from '../../../../helpers';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import {
    DispatchProgramItem,
    DispatchProgramItemFilters,
    DispatchProgramItemStatus,
    MassiveStatusForm,
    dispatchProgramItemStatuses
} from '../../../../app/models/DispatchProgramItem';
import DispatchProgramItemChangeStatus from '../DispatchProgramItemChangeStatus';
import DispatchProgramItemFilter from './DispatchProgramItemFilter';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { AppContext } from '../../../../contexts/AppContext';
import useDispatchProgramItemService from '../../../../app/services/hooks/useDispatchProgramItemService';
import ChangeStatusRows from '../../../../components/ChangeStatusRows';
import { StatusRows } from '../../../../app/shared/interfaces';
import { Link } from 'react-router-dom';

interface Props {
    dispatchProgramId: number;
    dispatchProgramItems: DispatchProgramItem[];
    totalRows: number;
    callbackSuccess?: () => void;
    edit?: (dispatchProgramItemId: number, status: string) => void;
    destroy?: (dispatchProgramItemId: number) => void;
    show?: (dispatchProgramItemId: number) => void;
    syncDailyCarrier?: (dispatchProgramItemId: number) => void;
    reloadModule: () => void;
    canChangeStatus?: boolean;
    filter: DispatchProgramItemFilters;
    setFilter: (filter: DispatchProgramItemFilters) => void;
    loading: boolean;
    isShow?: boolean;
    canBeEdited: boolean;
    downloadExcel: () => void;
    showMassiveLoad: () => void;
    loadTransportOrderData: () => void;
    editWithTransportOrder?: (dispatchProgramItemId: number) => void;
    setShowingModalDocument: (value: boolean) => void;
    onSearch: () => void;
}

const DispatchProgramItemDataTable = ({
    dispatchProgramId,
    dispatchProgramItems,
    edit,
    destroy,
    syncDailyCarrier,
    canChangeStatus,
    totalRows = 0,
    filter,
    setFilter,
    loading,
    reloadModule,
    callbackSuccess,
    downloadExcel,
    showMassiveLoad,
    loadTransportOrderData,
    editWithTransportOrder,
    setShowingModalDocument,
    canBeEdited,
    isShow = false,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'Posición',
            selector: (row: any) => row?.position,
            sortable: true,
            sortField: 'position'
        },
        {
            name: 'Cantidad Plan',
            selector: (row: any) => row?.quantity,
            sortable: true,
            sortField: 'quantity',
            cell: (row: DispatchProgramItem, index: any, column: any) => (
                <span className="">
                    {formatNumber(row.quantity ?? 0)} {row.product_unit}
                </span>
            )
        },
        {
            name: 'F. Despacho Plan',
            selector: (row: any) => row?.dispatch_date,
            sortable: true,
            sortField: 'dispatch_date',
            cell: (row: DispatchProgramItem, index: any, column: any) => (
                <span className="">{momentParseDate(row.dispatch_date)}</span>
            )
        },
        {
            name: 'Orden de transporte',
            selector: (row: any) => row?.transport_order_id,
            sortable: true,
            sortField: 'transport_order_id',
            cell: (row: DispatchProgramItem, index: any, column: any) =>
                row.transport_order &&
                row.transport_order.id && (
                    <Link to={`/transport-orders/${row.transport_order.id}/edit`}>
                        #{row.transport_order.id}
                    </Link>
                )
        },

        {
            name: 'Conductor',
            selector: (row: any) => row?.carrier,
            sortable: true,
            sortField: 'carrier',
            width: '210px',
            cell: (row: any, index: any, column: any) => {
                let worker = row.transport_order?.worker;
                if (!worker) {
                    worker = row.dispatch_program_daily_carrier[0]?.daily_carrier.carrier;
                }

                if (!worker) {
                    return '';
                }

                return (
                    <span
                        className=""
                        dangerouslySetInnerHTML={{
                            __html: worker.user
                                ? `
                            ${worker.user.rut} </br> 
                            ${worker.user.first_name} ${worker.user.last_name} ${worker.user.second_last_name}`
                                : ''
                        }}
                    ></span>
                );
            }
        },
        {
            name: 'Tracto',
            selector: (row: any) => row?.truck,
            sortable: true,
            sortField: 'truck',
            width: '150px',
            cell: (row: any, index: any, column: any) => {
                let truck = row.transport_order?.truck;
                if (!truck) {
                    truck = row.dispatch_program_daily_carrier[0]?.daily_carrier.truck;
                    if (truck) {
                        return (
                            <span
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: truck
                                        ? `${truck.patent} </br> ${truck.brand?.name}  ${truck.vehicle_model?.name}`
                                        : ''
                                }}
                            ></span>
                        );
                    }
                }

                if (!truck) {
                    return '';
                }

                return (
                    <span
                        className=""
                        dangerouslySetInnerHTML={{
                            __html: row.transport_order?.truck ? row.transport_order.truck : ''
                        }}
                    ></span>
                );
            }
        },

        {
            name: 'Semi remolque',
            selector: (row: any) => row?.hopper,
            sortable: true,
            sortField: 'hopper',
            width: '160px',
            cell: (row: any, index: any, column: any) => {
                let hopper = row.transport_order?.hopper;
                if (!hopper) {
                    hopper = row.dispatch_program_daily_carrier[0]?.daily_carrier.hopper;
                    if (hopper) {
                        return (
                            <span
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: hopper
                                        ? `${hopper.patent} </br> ${hopper.brand?.name}  ${hopper?.vehicle_model?.name}`
                                        : ''
                                }}
                            ></span>
                        );
                    }
                }

                if (!hopper) {
                    return '';
                }

                return (
                    <span
                        className=""
                        dangerouslySetInnerHTML={{
                            __html: row.transport_order?.hopper ? row.transport_order.hopper : ''
                        }}
                    ></span>
                );
            }
        },

        {
            name: 'Folio Guia',
            selector: (row: any) => row?.folio,
            sortable: true,
            sortField: 'folio',
            cell: (row: DispatchProgramItem, index: any, column: any) => (
                <span className="">{row.transport_order?.guide?.folio}</span>
            )
        },
        {
            name: 'Cantidad guia',
            selector: (row: any) => row?.quantity,
            sortable: true,
            sortField: 'quantity',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {row.transport_order && row.transport_order.guide
                        ? formatNumber(row.transport_order.guide.quantity) +
                          ' ' +
                          row.transport_order.guide.unit
                        : ''}
                </span>
            )
        },

        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            width: '150px',
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {canChangeStatus ? (
                        <DispatchProgramItemChangeStatus
                            callbackSuccess={callbackSuccess}
                            statuses={row.status_choices!}
                            selectedStatus={row.status_selected!}
                            dispatchProgramItemId={row.id!}
                            reloadModule={reloadModule!}
                        />
                    ) : row.status_selected ? (
                        row.status_selected.name
                    ) : (
                        ''
                    )}
                </div>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '170px',
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {syncDailyCarrier && (
                        <ButtonTableAction
                            callbackFunction={() => syncDailyCarrier(row.id ?? -1)}
                            classIcon={'mdi mdi-truck'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede asignar este transportista.'}
                            title={'Asignar Transporte'}
                        />
                    )}
                    {/* {row.dispatch_program_daily_carrier[0]?.guides[0]?.file ? (
                        <a
                            href={row.dispatch_program_daily_carrier[0]?.guides[0]?.file}
                            className="text-success icon-pointer mx-2"
                            title={'Descargar Guia'}
                            download
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <i className="mdi mdi-download font-size-18" />
                        </a>
                    ) : (
                        <a
                            className="text-secondary icon-pointer mx-2 disabled"
                            title={'Descargar Guia'}
                        >
                            <i className="mdi mdi-download font-size-18 disabled" />
                        </a>
                    )} */}

                    {/*{(editWithTransportOrder && canBeEdited) && (*/}
                    {/*    <ButtonTableAction*/}
                    {/*        callbackFunction={() => editWithTransportOrder(row.id ?? -1)}*/}
                    {/*        classIcon={'mdi mdi-truck-delivery'}*/}
                    {/*        colorIcon={'text-success'}*/}
                    {/*        errorMessage={'No se puede editar este registro.'}*/}
                    {/*        title={'Editar orden transporte'}*/}
                    {/*    />*/}
                    {/*)}*/}

                    {/*{show && <ButtonTableShow callbackShow={() => show(row.id ?? -1)} />}*/}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1, row.status)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [rows, setRows] = useState<any[]>([]);
    const [toggleCleared, setToggleCleared] = useState<boolean>(false);
    const [massiveStatusForm, setMassiveStatusForm] = useState<MassiveStatusForm>({
        dispatch_program_id: dispatchProgramId,
        all_selected: false,
        selected_count: 0,
        rows_ids: []
    });

    const {
        changeStatusesRowsToDispatchDispatchProgramItem,
        fetchingChangeStatusesRowsToDispatchDispatchProgramItem
    } = useDispatchProgramItemService();

    const contextMessage = ({
        selectedCount,
        allSelected
    }: {
        selectedCount: number;
        allSelected: boolean;
    }) => {
        return {
            singular: 'fila seleccionada',
            plural: 'filas seleccionadas'
        };
    };

    const resetContextActions = () => {
        setToggleCleared(!toggleCleared);
        setRows([]);
    };

    // useEffect(() => {

    //     const miDiv = document.querySelector('.rdt_TableHeader .sc-ksJisA');

    //     if(massiveStatusForm.all_selected){

    //         if (miDiv) {

    //             miDiv.innerHTML = "Toda la página seleccionada."
    //         }
    //     }

    // }, [massiveStatusForm.all_selected])

    const changeStatus = (
        value: string,
        massiveChange: boolean,
        setSelected: (status: string) => void
    ) => {
        if (value === '') {
            setSelected('');
            return;
        }

        const _status = dispatchProgramItemStatuses.find(
            (status: StatusRows) => status.value == value
        );

        const _text = `Está a punto de cambiar el estado ${
            massiveChange ? 'de todos los registros ' : 'de los registros marcados'
        } a ${_status!.label}`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estados...');

                        changeStatusesRowsToDispatchDispatchProgramItem(
                            {
                                ...massiveStatusForm,
                                status: value,
                                all_selected: massiveChange
                            },
                            {
                                onSuccess: (response: ServiceResponse) => {
                                    setSelected(value);
                                    if (hideLoading) hideLoading();

                                    useReactConfirmAlert().successAlert({
                                        title: 'Éxito',
                                        message: response.message,
                                        message_list: response.data?.message_list ?? []
                                    });
                                    resetContextActions();
                                    if (callbackSuccess) callbackSuccess();
                                },
                                onError: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();

                                    useReactConfirmAlert().errorAlert({
                                        title: 'Error',
                                        message: response.message
                                    });
                                }
                            }
                        );
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'Los estados no se han cambiado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const contextActions = React.useMemo(() => {
        return (
            <ChangeStatusRows
                changeStatus={changeStatus}
                className="form-select-sm"
                statuses={dispatchProgramItemStatuses}
            />
        );
    }, [dispatchProgramItems, rows, toggleCleared]);

    const handleRowSelected = React.useCallback(
        ({ allSelected, selectedCount, selectedRows }: any) => {
            setRows(selectedRows);
            setMassiveStatusForm({
                ...massiveStatusForm,
                all_selected: false,
                selected_count: selectedCount,
                rows_ids: selectedRows.map((row: any) => row.id)
            });
        },
        []
    );

    return (
        <>
            <div className="row">
                <div className="col-12 mt-md-0 mt-3">
                    <div className="row">
                        <div className="col-auto">
                            <DispatchProgramItemFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                        </div>

                        <div className="col-auto" style={{ paddingTop: 22 }}>
                            <button className="btn btn-success btn-sm" onClick={downloadExcel}>
                                <i className="fa fa-file-excel" /> Descargar
                            </button>
                        </div>
                        {canBeEdited && (
                            <div className="col-auto" style={{ paddingTop: 22 }}>
                                <button
                                    className="btn btn-info btn-sm  float-end"
                                    onClick={loadTransportOrderData}
                                >
                                    <i className="fa fa-file-excel" /> Carga ordenes
                                </button>
                            </div>
                        )}
                        <div className="col" style={{ paddingTop: 22 }}>
                            <button
                                className="btn btn-success btn-sm  float-end"
                                onClick={showMassiveLoad}
                            >
                                <i className="fa fa-file-excel" /> Carga Masiva
                            </button>
                        </div>

                        <div className="col-auto" style={{ paddingTop: 22 }}>
                            <button
                                className="btn btn-primary btn-sm  float-end"
                                onClick={() => setShowingModalDocument(true)}
                            >
                                <i className="fa fa-file-archive" /> Subida de documentos
                            </button>
                        </div>
                    </div>
                    {canBeEdited && (
                        <div className="row">
                            {/*<div className="col-auto" style={{ paddingTop: 22 }}>*/}
                            {/*    Cambio masivo estado*/}
                            {/*    <ChangeStatusRows*/}
                            {/*        changeStatus={changeStatus}*/}
                            {/*        className="form-select-sm"*/}
                            {/*        statuses={dispatchProgramItemStatuses}*/}
                            {/*        massive_change={true}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                    )}
                </div>
                <div className="col-12 mt-4">
                    <DefaultDataTable
                        title="Posiciones"
                        columns={columns}
                        data={dispatchProgramItems}
                        progressPending={loading}
                        paginationTotalRows={totalRows}
                        filter={filter}
                        setFilter={setFilter}
                        selectableRows={canBeEdited}
                        onSelectedRowsChange={handleRowSelected}
                        selectableRowsHighlight={true}
                        clearSelectedRows={toggleCleared}
                        contextActions={contextActions}
                        resetContextActions={resetContextActions}
                        contextMessage={contextMessage({
                            selectedCount: massiveStatusForm.selected_count ?? 0,
                            allSelected: massiveStatusForm.all_selected!
                        })}
                        // selectableRowsNoSelectAll={true}
                    />
                </div>
            </div>
        </>
    );
};

export default DispatchProgramItemDataTable;
