import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import useTransportOrderService from '../../../app/services/hooks/useTransportOrderService';
import { TransportOrderShowDataForm } from '../../../app/models/TransportOrder';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import ExpenseContainer from '../components/ExpensesContainer';
import LoadTicketContainer from '../components/LoadTicketContainer';
import TransportOrderShowForm from '../components/forms/TransportOrderShowForm';
import { Tab, Tabs } from 'react-bootstrap';
import useTransportOrderStatusHistoryService from '../../../app/services/hooks/useTransportOrderStatusHistoryService';
import { momentParseDate } from '../../../helpers';
import GuideContainer from '../components/GuideContainer';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ChangeStatus from '../components/TransportOrderChangeStatus';
import { TransportationOrderStatus } from '../../../app/models/TransportationOrderStatus';
import TollExpenseContainer from '../components/TollExpenseContainer';
import useNavigationPage from '../../../hooks/useNavigationPage';
import DefaultModal from '../../../components/default/DefaultModal';
import UnlockState from '../components/UnlockState';
import IncidentContainer from '../components/IncidentContainer';

const TransportOrdershow = () => {
    const history = useHistory();
    const { id } = useParams<RouterParams>();
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingShowTransportOrder,
        showTransportOrder,
        changeStatusTransportOrder,
        unlockStatusTransportOrder,
        fetchingChangeStatusTransportOrder,
        validateStatusChange
    } = useTransportOrderService();

    const [transportOrder, setTransportOrder] = useState<TransportOrderShowDataForm>({});
    const {
        getAllTransportOrderStatusHistoriesByTransportOrder,
        fetchingGetTransportOrderStatusHistoriesByTransportOrder
    } = useTransportOrderStatusHistoryService();

    const [transportOrderStatusHistories, setTransportOrderStatusHistories] = useState<any[]>([]);

    const [transportOrderStatus, setTransportOrderStatus] = useState<any[]>([]);
    const [statuses, setStatuses] = useState<TransportationOrderStatus[]>([]);
    const [canTollExpense, setCanTollExpense] = useState<boolean>(false);
    const [showingUnlock, setShowingUnlock] = useState(false);
    const [refreshComponents, setRefreshComponents] = useState(false);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        show();
    }, []);

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Ordenes de transporte',
            url: '/transport-orders',
            isActive: false
        },
        {
            name: 'Ver orden de transporte #' + id,
            url: '/transport-orders/' + id + '/edit',
            isActive: true
        }
    ];

    const show = () => {
        showTransportOrder(Number(id), {
            onSuccess: (response: ServiceResponse) => {
                setTransportOrder(response.data.transport_order);
                setStatuses(response.data.transport_order_statuses);
                setCanTollExpense(response.data.can_toll_expense);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/transport-orders');
                }
            }
        });

        getAllTransportOrderStatusHistoriesByTransportOrder(Number(id), {
            onSuccess: (response: ServiceResponse) => {
                setTransportOrderStatus(response.data.statuses);
                setTransportOrderStatusHistories(response.data.transport_order_status_histories);
                if (hideLoading) hideLoading();
            }
        });

        refresh();
    };

    const showUnlock = () => {
        setShowingUnlock(true);
    };

    const hideUnlock = () => {
        setShowingUnlock(false);
        setErrorFields('')
    };

    const refresh = () => {
        setRefreshComponents(prev => !prev);
    };

    const requestChangeStatus = (status: string) => {
        if (showLoading) showLoading('loading', 'Cargando...');
        validateStatusChange(parseInt(id), status, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                changeStatus(status, response.message);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };


    const changeStatus = (status: string, message: string) => {
        const _text = `Está a punto de cambiar el estado de la orden de transporte #${id}.${
            message ?? ''
        }`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        changeStatusTransportOrder(Number(id), status, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                show();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La orden transporte no ha cambiado el estado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const unlockStatus = (reason: string) => {
        const _text = `Está a punto de desbloquear el estado de la orden de transporte #${id}.`;
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        unlockStatusTransportOrder(Number(id), reason, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                show();
                                hideUnlock();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            },
                            onFieldError: (errorFields: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                setErrorFields(errorFields.data);
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El estado de la orden transporte no se ha desbloqueado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection={`Ver Orden de transporte #${id}`} breadcrumbs={breadcrumbs} />

            <Tabs defaultActiveKey="general-data" id="quote-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <div className="row">
                        <div className="col-md-5">
                            <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                                {!fetchingShowTransportOrder ? (
                                    <TransportOrderShowForm
                                        transportOrderShowForm={transportOrder!}
                                        showGeneralInfo={true}
                                        showSellInfo={false}
                                    />
                                ) : (
                                    <LazyLoading />
                                )}
                            </DefaultCard>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="sell" title="Venta">
                    <div className="row">
                        <div className="col-md-5">
                            <DefaultCard>
                                {!fetchingShowTransportOrder ? (
                                    <TransportOrderShowForm
                                        transportOrderShowForm={transportOrder!}
                                        showGeneralInfo={false}
                                        showSellInfo={true}
                                    />
                                ) : (
                                    <LazyLoading />
                                )}
                            </DefaultCard>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="incidents" title="Incidentes">
                    <div className="row">
                        <div className="col-md-12">
                            <DefaultCard>
                                <IncidentContainer transportOrderId={Number(id)} />
                            </DefaultCard>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="management" title="Gestión">
                    <div className="row">
                        <div className="col-md-8">
                            <DefaultCard>
                                {!fetchingShowTransportOrder ? (
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Estado</th>
                                                <th>Fecha</th>
                                                <th>Motivo</th>
                                                <th>Usuario</th>
                                            </tr>
                                        </thead>
                                        {/* <tbody>
                                            {transportOrderStatus.map((status, index) => {
                                                return (
                                                <tr key={index}>
                                                    <td>{status.name}</td>
                                                    <td>
                                                        {' '}
                                                        {momentParseDate(
                                                            transportOrderStatusHistories.find(
                                                                (history) =>
                                                                    history.name === status.id
                                                            )?.updated_at,
                                                            'DD/MM/YYYY  HH:mm'
                                                        )}
                                                    </td>
                                                    <td>{status.reason}</td>
                                                </tr>
                                            )})}
                                        </tbody> */}
                                        <tbody>
                                            {transportOrderStatusHistories.map((status, index) => {
                                                return (
                                                <tr key={index}>
                                                    <td>{transportOrderStatus.find(
                                                        (history) =>
                                                            history.id === status.name
                                                    ).name}</td>
                                                    <td>
                                                        {' '}
                                                        {momentParseDate(
                                                            status.updated_at,
                                                            'DD/MM/YYYY  HH:mm'
                                                        )}
                                                    </td>
                                                    <td>{status.reason}</td>
                                                    <td>{status?.user?.name}</td>
                                                </tr>
                                            )})}
                                        </tbody>
                                    </table>
                                ) : (
                                    <LazyLoading />
                                )}
                            </DefaultCard>
                        </div>
                    </div>
                </Tab>
            </Tabs>

            <>
                {<GuideContainer 
                    transportOrderId={Number(id)} 
                    refreshKey={refreshComponents}
                />}
                {canTollExpense && (
                    <TollExpenseContainer
                        transportOrderId={Number(id)}
                        canTollExpense={canTollExpense}
                        refreshKey={refreshComponents}
                    />
                )}
                <ExpenseContainer 
                    transportOrderId={Number(id)} 
                    refreshKey={refreshComponents}
                />
                {<LoadTicketContainer 
                    transportOrderId={Number(id)} 
                    refreshKey={refreshComponents}
                />}
            </>
            <div className="row">
                <div className="col-md-12">
                    <DefaultCard>
                        <ChangeStatus
                            status={String(transportOrder?.status)}
                            statuses={statuses}
                            requestChangeStatus={requestChangeStatus}
                            requestUnlockStatus={showUnlock}
                            fetchingChangeStatus={fetchingChangeStatusTransportOrder}
                        />
                    </DefaultCard>
                </div>
            </div>
            {showingUnlock ? (
                <DefaultModal
                    show={showingUnlock}
                    handleClose={hideUnlock}
                    title="Desbloquear Estado"
                    backdrop={true}
                    showFooter={false}>
                    <UnlockState
                        errorFields={errorFields}
                        unlockStatus={unlockStatus}
                        onCancel={hideUnlock}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default TransportOrdershow;
