import moment from 'moment';
import { Currency } from './Currency';
import { Place } from './Place';
import { Provider } from './Provider';
import { Toll } from './Toll';
import { VehicleModel } from './VehicleModel';
import { VehicleType } from './VehicleType';

export interface TollRate {
    id?: number;
    toll_id?: number;
    toll?: Toll;
    vehicle_type?: VehicleType;
    start_time?: string;
    end_time?: string;
    amount?: number;
    currency_id?: number;
    currency?: Currency;
    valid_from?: string;
    valid_until?: string;
}

export interface TollRateDataForm {
    id?: number;
    toll_id?: number;
    vehicle_type?: string;
    start_time?: string;
    end_time?: string;
    amount?: number;
    currency_id?: number;
    currency?: string;
    valid_from?: string;
    valid_until?: string;
}

export interface TollRateFilters {
    toll_rate_id?: number | null;

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultTollRateDataForm: TollRateDataForm = {
    vehicle_type: '',
    start_time: '',
    end_time: '',
    amount: 0,
    currency_id: 0,
    valid_from: moment().format('YYYY-MM-dddd'),
    valid_until: moment().format('YYYY-MM-dddd')
};
