import React, { useContext, useEffect, useRef, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useCheckingAccountService from '../../../app/services/hooks/useCheckingAccount';
import {
    CheckingAccountDataForm,
    defaultCheckingAccountData
} from '../../../app/models/CheckingAccount';
import { Bank } from '../../../app/models/Bank';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { Carrier } from '../../../app/models/Carrier';
import { Currency } from '../../../app/models/Currency';
import CheckingAccountFormContainer from './CheckingAccountFormContainer';
import moment from 'moment';
import { Expense } from '../../../app/models/Expense';
import useExpenseService from '../../../app/services/hooks/useExpenseService';
import { TransferReason } from '../../../app/models/TransferReason';
import useBankAccountService from '../../../app/services/hooks/useBankAccount';

interface Props {
    checkingAccountId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CheckingAccountEdit = ({ checkingAccountId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditCheckingAccount,
        editCheckingAccount,
        fetchingUpdateCheckingAccount,
        updateCheckingAccount
    } = useCheckingAccountService();

    const [checkingAccount, setCheckingAccount] = useState<CheckingAccountDataForm>({
        issue_date: moment().format('YYYY-MM-DD'),
        worker_id: 0,
        currency_id: 0,
        description: ''
    });

    const [firstEditCarrierId, setFirstEditCarrierId] = useState<number>(0);
    const [firstEditExpenses, setFirstEditExpenses] = useState<Expense[]>();
    const [transferReasons, setTransferReasons] = useState<TransferReason[]>([]);

    const [banks, setBanks] = useState<Bank[]>([]);
    const [bankAccountTypes, setBankAccountTypes] = useState<BankAccountType[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [expenses, setExpenses] = useState<Expense[]>([]);
    const [firstExpenses, setFirstExpenses] = useState<Expense[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
    const carrierIdSelected = useRef<number | undefined>(0);
    const transferReasonIdSelected = useRef<number | undefined>(0);

    const { getExpensesEditByCarrier, fetchingGetExpensesEditByCarrier } = useExpenseService();
    const { getBankAccountByReason, fetchingGetBankAccountByReason } = useBankAccountService();

    useEffect(() => {
        edit();
    }, []);

    useEffect(() => {
        if (checkingAccount.worker_id == undefined) {
            carrierIdSelected.current = undefined;
        }
        if (checkingAccount.worker_id && checkingAccount.worker_id != carrierIdSelected.current) {
            // if(checkingAccount.worker_id != firstCheckingAccount.worker_id){

            getExpensesEditByCarrier(checkingAccount.worker_id, checkingAccountId, {
                onSuccess: (response: ServiceResponse) => {
                    setExpenses(response.data.expenses);

                    if (checkingAccount.worker_id == firstEditCarrierId) {
                        carrierIdSelected.current = checkingAccount.worker_id ?? 0;
                        const expenses_data = response.data.data_edit.expenses.map(
                            (expense: any) => {
                                return {
                                    value: expense.id,
                                    label: expense.name
                                };
                            }
                        );
                        setCheckingAccount({
                            ...checkingAccount,
                            ...defaultCheckingAccountData,
                            ...response.data.data_edit,
                            expenses: expenses_data
                        });
                    } else {
                        carrierIdSelected.current = checkingAccount.worker_id ?? 0;
                        setCheckingAccount({
                            ...checkingAccount,
                            ...defaultCheckingAccountData,
                            ...response.data.data_edit
                        });
                    }

                    setErrorFields(undefined);
                },
                onError: (response: ServiceResponse) => {
                    setCheckingAccount({
                        ...checkingAccount,
                        worker_id: carrierIdSelected.current
                    });
                }
            });
            // }
            // else{

            //     setExpenses(firstExpenses);
            //     setCheckingAccount({
            //         ...checkingAccount,
            //        worker_id: firstCheckingAccount.worker_id,
            //        bank_id: firstCheckingAccount.bank_id,
            //        account_type_id: firstCheckingAccount.account_type_id,
            //        account_number: firstCheckingAccount.account_number,
            //        amount: firstCheckingAccount.amount,
            //        expenses: firstCheckingAccount.expenses
            //     });
            //     setErrorFields({
            //         ...errorFields,
            //         worker_id:'',
            //         account_type_id: '',
            //         bank_id: '',
            //         account_number: '',
            //     });
            // }
        }
    }, [checkingAccount.worker_id]);

    useEffect(() => {
        if (checkingAccount.transfer_reason_id == undefined) {
            transferReasonIdSelected.current = undefined;
        }
        if (
            checkingAccount.transfer_reason_id &&
            checkingAccount.transfer_reason_id != transferReasonIdSelected.current &&
            checkingAccount.worker_id
        ) {
            getBankAccountByReason(checkingAccount.transfer_reason_id, checkingAccount.worker_id, {
                onSuccess: (response: ServiceResponse) => {
                    transferReasonIdSelected.current = checkingAccount.transfer_reason_id ?? 0;
                    setErrorFields(undefined);
                    setCheckingAccount({
                        ...checkingAccount,
                        account_number: response.data.bank_account.account_number,
                        bank_id: response.data.bank_account.bank_id,
                        account_type_id: response.data.bank_account.account_type_id
                    });
                },
                onError: (response: ServiceResponse) => {
                    setCheckingAccount({
                        ...checkingAccount,
                        transfer_reason_id: transferReasonIdSelected.current
                    });
                }
            });
        }
    }, [checkingAccount.transfer_reason_id]);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando transferencia...');
        editCheckingAccount(checkingAccountId, {
            onSuccess: (response: ServiceResponse) => {
                setBanks(response.data.banks);
                setBankAccountTypes(response.data.bank_account_types);
                setCarriers(response.data.carriers);
                setCurrencies(response.data.currencies);
                setTransferReasons(response.data.transfer_reasons);
                setExpenses(response.data.expenses);
                const _checking_account = response.data.checking_account;
                _checking_account.expenses = response.data.checking_account.expenses.map(
                    (expense: any) => {
                        return {
                            value: expense.id,
                            label: expense.name,
                            amount: expense.amount,
                            currency: expense.currency
                        };
                    }
                );
                carrierIdSelected.current = _checking_account.worker_id;
                setCheckingAccount(_checking_account);
                setFirstEditCarrierId(_checking_account.worker_id);
                setFirstExpenses(_checking_account.expenses);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando transferencia...');
        updateCheckingAccount(checkingAccountId, checkingAccount, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditCheckingAccount ? (
        <LazyLoading height="300" />
    ) : (
        <CheckingAccountFormContainer
            fetching={fetchingUpdateCheckingAccount}
            action={update}
            cancel={cancel}
            transferReasons={transferReasons}
            checkingAccount={checkingAccount}
            firstEditExpenses={firstEditExpenses}
            firstEditCarrierId={firstEditCarrierId}
            setCheckingAccount={setCheckingAccount}
            banks={banks}
            bankAccountTypes={bankAccountTypes}
            carriers={carriers}
            currencies={currencies}
            expenses={expenses}
            setExpenses={setExpenses}
            errorFields={errorFields}
            loading={fetchingGetExpensesEditByCarrier}
            updated={true}
        />
    );
};

export default CheckingAccountEdit;
