import React, { useContext, useState } from 'react';
import { formatMoney, formatNumber } from '../../../../utils/utils';
import { momentParseDate } from '../../../../helpers';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { DispatchProgram, DispatchProgramFilters } from '../../../../app/models/DispatchProgram';
import DispatchProgramFilter from './DispatchProgramFilter';
import { Link } from 'react-router-dom';

interface Props {
    dispatchPrograms: DispatchProgram[];
    totalRows: number;
    callbackSuccess?: () => void;
    edit?: (dispatchProgramId: number, status: string) => void;
    destroy?: (dispatchProgramId: number) => void;
    show?: (dispatchProgramId: number) => void;
    syncDailyCarrier?: (dispatchProgramId: number) => void;
    changeStatus?: boolean;
    filter: DispatchProgramFilters;
    setFilter: (filter: DispatchProgramFilters) => void;
    loading: boolean;
    isShow?: boolean;
    canBeEdited?: boolean;
    repeat?: (quoteId: number) => void;
    downloadExcel: () => void;
    showMassiveLoad?: () => void;
    setShowingModalDocument?: (value: boolean) => void;
    onSearch: () => void;
}

const DispatchProgramDataTable = ({
    dispatchPrograms,
    edit,
    destroy,
    totalRows = 0,
    filter,
    setFilter,
    loading,
    downloadExcel,
    show,
    repeat,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'Despacho',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id'
        },
        {
            name: 'Pedido',
            selector: (row: any) => row?.transportation_order_id,
            sortable: true,
            sortField: 'transportation_order_id',
            cell: (row: any, index: any, column: any) => {
                if (row.transportation_order_id) {
                    return (
                        <Link
                            to={'/transportation-orders/' + row.transportation_order_id + '/show'}
                        >
                            #{row.transportation_order_id}
                        </Link>
                    );
                } else {
                    return 'Sin pedido de transporte';
                }
            }
        },
        {
            name: 'Oferta',
            selector: (row: any) => row?.quote_id,
            sortable: true,
            sortField: 'quote_id',
            cell: (row: any, index: any, column: any) => {
                if (row.quote_id) {
                    return <Link to={'/quotes/' + row.quote_id + '/show'}>#{row.quote_id}</Link>;
                } else {
                    return 'Sin oferta';
                }
            }
        },
        {
            name: 'Cliente',
            selector: (row: any) => row?.contact_email,
            sortable: true,
            sortField: 'client',
            cell: (row: any, index: any, column: any) => {
                return row.client.business_name;
            }
        },
        {
            name: 'Origen',
            selector: (row: any) => row?.transportation_order_item,
            sortable: true,
            sortField: 'transportation_order_item.origin',
            cell: (row: any, index: any, column: any) => {
                return row.transportation_order_item.origin;
            }
        },
        {
            name: 'Destino',
            selector: (row: any) => row?.transportation_order_item,
            sortable: true,
            sortField: 'transportation_order_item.destiny',
            cell: (row: any, index: any, column: any) => {
                return row.transportation_order_item.destiny;
            }
        },
        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            cell: (row: any, index: any, column: any) => {
                return row.status_selected.name;
            }
        },
        {
            name: 'Cantidad despachada',
            selector: (row: any) => row?.total_guide,
            sortable: false,
            sortField: 'status',
            cell: (row: any, index: any, column: any) => {
                return row.total_guide ? formatNumber(row.total_guide) : null;
            }
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="nowrap-cell nowrap-cell-no-min">
                    {repeat && (
                        <ButtonTableAction
                            callbackFunction={() => repeat(row.id ?? -1)}
                            classIcon={'mdi mdi-content-copy'}
                            colorIcon={'text-secondary'}
                            errorMessage={'No se puede duplicar este registro.'}
                            title={'Duplicar'}
                        />
                    )}
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro'}
                            title={'Ver'}
                        />
                    )}
                    {/*{show && <ButtonTableShow callbackShow={() => show(row.id ?? -1)} />}*/}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1, row.status)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row">
                <div className="col-12 mt-md-0 mt-3">
                    <div className="row mt-3">
                        <div className="col">
                            <DispatchProgramFilter filter={filter} setFilter={setFilter} onSearch={onSearch} />
                        </div>
                        <div className="col-auto" style={{ paddingTop: 22 }}>
                            <button className="btn btn-success btn-sm" onClick={downloadExcel}>
                                <i className="fa fa-file-excel" /> Descargar
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <DefaultDataTable
                        columns={columns}
                        data={dispatchPrograms}
                        progressPending={loading}
                        paginationTotalRows={totalRows}
                        filter={filter}
                        setFilter={setFilter}
                    />
                </div>
            </div>
        </>
    );
};

export default DispatchProgramDataTable;
