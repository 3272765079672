import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { ContractDataForm, ContractFilters } from '../../models/Contract';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useContractService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetContracts, setFetchingGetContract] = useState(false);
    const [fetchingDeleteContract, setFetchingDeleteContract] = useState(false);
    const [fetchingActiveContract, setFetchingActiveContract] = useState(false);
    const [fetchingStoreContract, setFetchingStoreContract] = useState(false);
    const [fetchingCreateContract, setFetchingCreateContract] = useState(false);
    const [fetchingEditContract, setFetchingEditContract] = useState(false);
    const [fetchingUpdateContract, setFetchingUpdateContract] = useState(false);

    const getContracts = (ContractFilters: ContractFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(ContractFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.CONTRACTS.GET_CONTRACTS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetContract
        });
    };

    const createContract = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CONTRACTS.CREATE_CONTRACTS,
            setFetching: setFetchingCreateContract
        });
    };

    const storeContract = (Contract: ContractDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            requestType: 'json',
            url: EndPoints.CONTRACTS.STORE_CONTRACTS,
            body: Contract,
            setFetching: setFetchingStoreContract
        });
    };

    const deleteContract = (ContractId: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.CONTRACTS.DELETE_CONTRACTS.replace(':id', ContractId.toString()),
            setFetching: setFetchingDeleteContract
        });
    };

    const activeContract = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CONTRACTS.ACTIVE_CONTRACTS.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveContract
        });
    };

    const editContract = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CONTRACTS.EDIT_CONTRACTS.replace(':id', id.toString()),
            setFetching: setFetchingEditContract
        });
    };

    const updateContract = (
        id: number,
        contracts: ContractDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            requestType: 'json',
            url: EndPoints.CONTRACTS.UPDATE_CONTRACTS.replace(':id', id.toString()),
            body: contracts,
            setFetching: setFetchingUpdateContract
        });
    };

    return {
        fetchingGetContracts,
        fetchingActiveContract,
        fetchingDeleteContract,
        fetchingCreateContract,
        fetchingStoreContract,
        fetchingEditContract,
        fetchingUpdateContract,
        storeContract,
        createContract,
        activeContract,
        getContracts,
        deleteContract,
        editContract,
        updateContract
    };
};

export default useContractService;
