import RouteSegmentFilter from './RouteSegmentFilter';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { RouteSegmentDataForm, RouteSegmentFilters } from '../../../../app/models/RouteSegment';

interface Props {
    routeSegments: RouteSegmentDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (quoteId: number) => void;
    show?: (quoteId: number) => void;
    destroy?: (quoteId: number) => void;
    filter: RouteSegmentFilters;
    setFilter: (filter: RouteSegmentFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const RouteSegmentDataTable = ({
    routeSegments,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    // const subHeaderComponentMemo = useMemo(() => {

    // 			return (

    // 					<UserFilter filter={filter} setFilter={setFilter} />

    // 				// <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    // 			);
    // 	}, [filter]);

    const columns = [
        {
            name: 'tramo',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: RouteSegmentDataForm, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    {row.id}
                </span>
            )
        },
        {
            name: 'origen',
            selector: (row: any) => row.origin.name,
            sortable: true,
            sortField: 'origin',
            cell: (row: RouteSegmentDataForm, index: any, column: any) => (
                <span className="">{row.origin?.name}</span>
            )
        },
        {
            name: 'destino',
            selector: (row: any) => row.destiny.name,
            sortable: true,
            sortField: 'destiny',
            cell: (row: RouteSegmentDataForm, index: any, column: any) => (
                <span className="">{row.destiny?.name}</span>
            )
        },
        {
            name: 'distancia',
            selector: (row: any) => row.distance,
            sortable: true,
            sortField: 'distance',
            cell: (row: RouteSegmentDataForm, index: any, column: any) => (
                <span className="">{row.distance}</span>
            )
        },
        // {
        //     name: 'peaje',
        //     selector: (row: any) => row.toll_ids,
        //     sortable: false,
        //     cell: (row: RouteSegmentDataForm, index: any, column: any) => (
        //         <span className="list-unstyled">
        //                 {row.toll_ids?.map((tollId) => (
        //                 <li key={tollId}>{tollId}</li>
        //                 ))}
        //     </span>
        //     )
        // },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: RouteSegmentDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <RouteSegmentFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={routeSegments}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default RouteSegmentDataTable;
