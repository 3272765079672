import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Bank, BankFilters } from '../../models/Bank';

const useBankService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBanks, setFetchingGetBanks] = useState(false);
    const [fetchingShowBank, setFetchingShowBank] = useState(false);
    const [fetchingStoreBank, setFetchingStoreBank] = useState(false);
    const [fetchingEditBank, setFetchingEditBank] = useState(false);
    const [fetchingUpdateBank, setFetchingUpdateBank] = useState(false);
    const [fetchingDeleteBank, setFetchingDeleteBank] = useState(false);
    const [fetchingActiveBank, setFetchingActiveBank] = useState(false);

    const getBanks = (bankFilters: BankFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(bankFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BANKS.GET_BANKS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetBanks
        });
    };

    const showBank = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BANKS.SHOW_BANK.replace(':id', id.toString()),
            setFetching: setFetchingShowBank
        });
    };

    const storeBank = (bank: Bank, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BANKS.STORE_BANK,
            body: bank,
            setFetching: setFetchingStoreBank
        });
    };

    const editBank = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BANKS.EDIT_BANK.replace(':id', id.toString()),
            setFetching: setFetchingEditBank
        });
    };

    const updateBank = (id: number, bank: Bank, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.BANKS.UPDATE_BANK.replace(':id', id.toString()),
            body: bank,
            setFetching: setFetchingUpdateBank
        });
    };

    const deleteBank = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.BANKS.DELETE_BANK.replace(':id', id.toString()),
            setFetching: setFetchingDeleteBank
        });
    };

    const activeBank = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BANKS.ACTIVE_BANK.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveBank
        });
    };

    return {
        fetchingGetBanks,
        fetchingShowBank,
        fetchingStoreBank,
        fetchingEditBank,
        fetchingUpdateBank,
        fetchingDeleteBank,
        fetchingActiveBank,
        getBanks,
        showBank,
        storeBank,
        editBank,
        updateBank,
        deleteBank,
        activeBank
    };
};

export default useBankService;
