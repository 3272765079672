import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { ExpenseType, ExpenseTypeFilters } from '../../models/ExpenseType';

const useExpenseTypeService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetExpenseTypes, setFetchingGetExpenseTypes] = useState(false);
    const [fetchingShowExpenseType, setFetchingShowExpenseType] = useState(false);
    const [fetchingCreateExpenseType, setFetchingCreateExpenseType] = useState(false);
    const [fetchingStoreExpenseType, setFetchingStoreExpenseType] = useState(false);
    const [fetchingEditExpenseType, setFetchingEditExpenseType] = useState(false);
    const [fetchingUpdateExpenseType, setFetchingUpdateExpenseType] = useState(false);
    const [fetchingDeleteExpenseType, setFetchingDeleteExpenseType] = useState(false);
    const [fetchingActiveExpenseType, setFetchingActiveExpenseType] = useState(false);

    const getExpenseTypes = (
        is_system: boolean,
        ExpenseTypeFilters: ExpenseTypeFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(ExpenseTypeFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.EXPENSE_TYPES.GET_EXPENSE_TYPES}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':is_system', is_system.toString()),
            setFetching: setFetchingGetExpenseTypes
        });
    };

    const createExpenseType = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSE_TYPES.CREATE_EXPENSE_TYPE,
            setFetching: setFetchingCreateExpenseType
        });
    };

    const showExpenseType = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSE_TYPES.SHOW_EXPENSE_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingShowExpenseType
        });
    };

    const storeExpenseType = (expenseType: ExpenseType, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.EXPENSE_TYPES.STORE_EXPENSE_TYPE,
            body: expenseType,
            setFetching: setFetchingStoreExpenseType
        });
    };

    const editExpenseType = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSE_TYPES.EDIT_EXPENSE_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingEditExpenseType
        });
    };

    const updateExpenseType = (
        id: number,
        expenseType: ExpenseType,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.EXPENSE_TYPES.UPDATE_EXPENSE_TYPE.replace(':id', id.toString()),
            body: expenseType,
            setFetching: setFetchingUpdateExpenseType
        });
    };

    const deleteExpenseType = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.EXPENSE_TYPES.DELETE_EXPENSE_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteExpenseType
        });
    };

    const activeExpenseType = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.EXPENSE_TYPES.ACTIVE_EXPENSE_TYPE.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveExpenseType
        });
    };

    return {
        fetchingGetExpenseTypes,
        fetchingShowExpenseType,
        fetchingCreateExpenseType,
        fetchingStoreExpenseType,
        fetchingEditExpenseType,
        fetchingUpdateExpenseType,
        fetchingDeleteExpenseType,
        fetchingActiveExpenseType,
        getExpenseTypes,
        showExpenseType,
        createExpenseType,
        storeExpenseType,
        editExpenseType,
        updateExpenseType,
        deleteExpenseType,
        activeExpenseType
    };
};

export default useExpenseTypeService;
