import { StatusRows } from '../shared/interfaces';
import { PaymentMethod } from './PaymentMethod';
import { Place } from './Place';
import { Provider } from './Provider';

export interface Toll {
    id?: number;
    description?: string;
    name?: string;
    commune?: Place;
    commune_id?: number;
    provider?: Provider;
    provider_id?: number;
    latitude?: number;
    longitude?: number;
    payment_method?: PaymentMethod;
    fixed_rate?: boolean;
}

export interface TollDataForm {
    id?: number;
    description?: string;
    name?: string;
    commune_id?: number;
    commune?: string;
    provider_id?: number;
    provider?: string;
    latitude?: number;
    longitude?: number;
    payment_method?: PaymentMethod;
    fixed_rate?: boolean;
}

export interface TollFilters {
    toll_id?: number | '';
    description?: string | '';
    name?: string | '';
    provider?: string | '';
    commune?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultTollDataForm: TollDataForm = {
    description: '',
    provider_id: 0,
    commune_id: 0,
    latitude: 0,
    longitude: 0
};

export interface MassiveApproveForm {
    transport_order_id?: number;
    approve_status?: string;
    all_selected?: boolean;
    is_toll_expense?: boolean;
    selected_count?: number;
    rows_ids?: number[];
}

export const defaultMassiveApproveForm: MassiveApproveForm = {
    all_selected: false,
    selected_count: 0,
    rows_ids: []
};

export const tollApproveOptions: StatusRows[] = [
    {
        value: 'APPROVED',
        label: 'Aprobar'
    },
    {
        value: 'REJECT',
        label: 'Rechazar'
    }
];
