import { Society } from '../../../../app/models/Society'; // Asegúrate de tener este modelo
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import {
    SocietySignature,
    SocietySignatureDataForm
} from '../../../../app/models/SocietySignature';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import React, { useEffect, useState } from 'react';
import { User } from '../../../../app/models/User';

type Props = {
    societySignatureForm: SocietySignatureDataForm;
    setSocietySignatureDataForm: (societySignature: SocietySignatureDataForm) => void;
    societies: Society[];
    users: User[];
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
    disableUpdate?: boolean;
};

// const DEFAULT_IMAGE_URL = 'https://cdn.icon-icons.com/icons2/3251/PNG/512/signature_regular_icon_203276.png';

const SocietySignatureForm = ({
    societySignatureForm,
    setSocietySignatureDataForm,
    societies,
    users,
    errorFields,
    disableUpdate = false
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [signatureFile, setSignatureFile] = useState<File | null>(null);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields, setErrors]);

    const [image, setImage] = useState<string>(
        societySignatureForm.signature ||
            'https://cdn.icon-icons.com/icons2/3251/PNG/512/signature_regular_icon_203276.png'
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setSocietySignatureDataForm({ ...societySignatureForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setSocietySignatureDataForm({ ...societySignatureForm, [name]: value });
    };

    const handleUserChangeSelectTwo = (value: any) => {
        let name = '';
        if (value.label.includes('|')) {
            const parts = value.label.split('|');
            name = parts[1].trim();
        } else {
            const parts = value.label.split('-');
            name = parts[1].trim();
        }
        const obj = {
            name: name,
            user_id: value.value
        };

        setSocietySignatureDataForm({ ...societySignatureForm, ...obj });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            setSignatureFile(file);
            // Si necesitas actualizar el estado con el archivo seleccionado
        }
    };

    const dataOnChangeImages = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const fileUrl = URL.createObjectURL(file);
            setImage(fileUrl);
            setSocietySignatureDataForm({
                ...societySignatureForm,
                [e.target.name]: file // Aquí sigues pasando el archivo al formulario
            });
        }
    };

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Usuario</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="user_id"
                        id="user_id"
                        inputValue={societySignatureForm.user_id}
                        options={SelectTwoMapperOptions(users, 'id', ['id_number', 'name'])}
                        hasError={fieldHasError('user_id') !== ''}
                        onChange={(value: any) => {
                            handleUserChangeSelectTwo(value);
                        }}
                        placeholder={'Seleccione un usuario'}
                        onFocus={() => onFocusRemove('user_id')}
                        disabled={disableUpdate}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('user_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Nombre</label>
                <div className="col-md-9">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        disabled
                        className={`form-control ${fieldHasError('name') ? 'is-invalid' : ''}`}
                        value={societySignatureForm.name || ''}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Nombre de usuario"
                    />
                    {fieldHasError('name') && (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                            {fieldErrorMessage('name')}
                        </div>
                    )}
                </div>
            </div>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Cargo</label>
                <div className="col-md-9">
                    <input
                        name="job_title"
                        id="job_title"
                        type="text"
                        className={`form-control ${fieldHasError('job_title') ? 'is-invalid' : ''}`}
                        value={societySignatureForm.job_title || ''}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('jobTitle')}
                        placeholder="Ingrese el cargo"
                    />
                    {fieldHasError('job_title') && (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                            {fieldErrorMessage('jobTitle')}
                        </div>
                    )}
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Sociedad</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="society_id"
                        id="society_id"
                        inputValue={societySignatureForm.society_id}
                        options={SelectTwoMapperOptions(societies, 'id', ['rut', 'business_name'])}
                        hasError={fieldHasError('society_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('society_id', value.value)}
                        placeholder={'Seleccione una sociedad'}
                        onFocus={() => onFocusRemove('society_id')}
                        disabled={disableUpdate}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('society_id')}
                    </div>
                </div>
            </div>

            <div className="col-md-12 text-center d-flex">
                <div className="mx-auto mb-3">
                    <label htmlFor="logo">
                        <img
                            src={image}
                            className="rounded-circle pointer bg-white"
                            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                        />
                    </label>
                </div>
                <input
                    type="file"
                    className="custom-file-input"
                    style={{ height: '0px', width: '0px' }}
                    id="logo"
                    name="logo"
                    onChange={dataOnChangeImages}
                />
            </div>
        </>
    );
};

export default SocietySignatureForm;
