import React, { useEffect, useState } from 'react';
import { formatNumberWithDots, formatNumberWithDecimals } from '../../helpers';

type CurrencyProps = {
    name: string;
    currency?: string | number;
    currencies?: any[];
    value?: string | number;
    onChange: (event: any) => void;
    onFocus?: () => void;
    className: string;
    placeholder?: string;
    disabled?: boolean;
};

const CurrencyInput = ({
    currency,
    currencies,
    value,
    name,
    onChange,
    onFocus,
    className,
    placeholder,
    disabled
}: CurrencyProps) => {
    const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>('');
    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {
        if (currency && currencies) {
            setSelectedCurrency(getNameById(currency, currencies));
        } else if (currency && currency == 'CLP') {
            setSelectedCurrency('CLP');
        }
    }, [currency, currencies]);

    useEffect(() => {
        setInputValue(formattedInputValue(value));
    }, [value, selectedCurrency]);

    const getNameById = (id: number | string, items: any[]): string | undefined => {
        const item = items.find((item) => item.id === id);
        return item ? item.name : undefined;
    };

    const formattedInputValue = (value: string | number | undefined): string => {
        if (selectedCurrency === 'CLP') {
            if (value) return formatNumberWithDots(value);
        } else {
            if (value) return formatNumberWithDecimals(value);
        }
        return value?.toString() || '';
    };

    const formattedOnChangeValue = (value: string): string => {
        if (selectedCurrency === 'CLP') {
            value = value.replace(/\D/g, '');
        } else {
            value = value.replace(/[^\d,]/g, '');
            const parts = value.split(',');
            if (parts.length > 1) {
                parts[1] = parts[1].substring(0, 2);
            }
            value = parts.join('.');
        }
        return value;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = formattedOnChangeValue(e.target.value);
        e.target.value = inputValue;
        onChange(e);
    };

    return (
        <input
            name={name}
            id={name}
            type="text"
            value={inputValue}
            className={className}
            onChange={handleChange}
            onFocus={onFocus}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete="off"
        />
    );
};

export default CurrencyInput;
