import { Brand } from './Brand';
import { VehicleModel } from './VehicleModel';
import { DocumentSet } from './DocumentSet';

export interface Hopper {
    id?: number;
    patent?: string;
    documents?: HopperDocument[];

    brand?: Brand;
    vehicle_model?: VehicleModel;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface HopperFilters {
    hopper_id?: number | null;
    provider?: string | null;
    society?: string | null;
    patent?: string | null;
    brand?: string | null;
    owner?: string | null;
    vehicle_model?: string | null;
    year?: string | null;
    vin?: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface HopperDataForm {
    id?: number;
    user_id?: number;
    patent?: string;
    society_id?: number;
    provider_id?: number;
    provider?: string;
    society?: string;
    brand_id?: number;
    vehicle_model_id?: number;
    brand?: Brand;
    vehicle_model?: VehicleModel;
    document_sets?: DocumentSet[];
    year?: number;
    vin?: string;
    is_active?: boolean;

    is_internal?: boolean;
}

export const defaultHopperDataForm: HopperDataForm = {
    society_id: 0,
    provider_id: undefined,
    brand_id: 0,
    vehicle_model_id: 0,
    is_internal: true,
    year: 2000,
    vin: ''
};

export const defaultHopper: Hopper = {
    patent: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
    documents: []
};

export interface HopperDocument {
    id?: number;
    name?: string;
    file?: File;
    issue_date?: string;
    expiration_date?: string;
    type?: string;
    is_expire_date_indefinite: boolean;
    document_id?: number;
    hopper_id?: number;
}
