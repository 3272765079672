import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useExpenseTypeService from '../../../app/services/hooks/useExpenseTypeService';
import { ExpenseType } from '../../../app/models/ExpenseType';
import ExpenseTypeFormContainer from './ExpenseTypeFormContainer';
import { Account } from '../../../app/models/Account';

interface Props {
    expenseTypeId: number;
    is_system: boolean;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ExpenseTypeEdit = ({ expenseTypeId, is_system, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditExpenseType,
        editExpenseType,
        fetchingUpdateExpenseType,
        updateExpenseType
    } = useExpenseTypeService();

    const [expenseType, setExpenseType] = useState<ExpenseType>({ name: '' });
    const [errorFields, setErrorFields] = useState<any>();
    const [accounts, setAccounts] = useState<Account[]>([]);

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando tipo de gasto...');
        editExpenseType(expenseTypeId, {
            onSuccess: (response: ServiceResponse) => {
                setExpenseType(response.data.expense_type);
                setAccounts(response.data.accounts);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando tipo de gasto...');
        updateExpenseType(expenseTypeId, expenseType, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditExpenseType ? (
        <LazyLoading height="300" />
    ) : (
        <ExpenseTypeFormContainer
            fetching={fetchingUpdateExpenseType}
            action={update}
            cancel={cancel}
            expenseType={expenseType}
            setExpenseType={setExpenseType}
            accounts={accounts}
            errorFields={errorFields}
            is_system={is_system}
        />
    );
};

export default ExpenseTypeEdit;
