import React, { useEffect, useState } from 'react';

import {
    defaultTransportationOrderDataForm,
    TransportationOrderDataForm
} from '../../../app/models/TransportationOrder';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import TransportationOrderForm from './forms/TransportationOrderForm';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import { PaymentCondition } from '../../../app/models/PaymentCondition';
import { Currency } from '../../../app/models/Currency';
import useTransportationOrderService from '../../../app/services/hooks/useTransportationOrderService';
import { Quote } from '../../../app/models/Quote';
import { Unit } from '../../../app/models/Unit';
import { useHistory } from 'react-router-dom';
import { TransportationQuoteDetail } from '../../../app/models/TransportationQuoteDetail';
import useSweetAlert from '../../../hooks/useSweetAlert';
import useNavigationPage from '../../../hooks/useNavigationPage';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TransportationOrderCreate = ({ onSaved, onCancel, onError }: Props) => {
    const [societies, setSocieties] = useState<Society[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [paymentConditions, setPaymentsConditions] = useState<PaymentCondition[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentCondition[]>([]);
    const [quotes, setQuotes] = useState<TransportationQuoteDetail[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [errorGetData, setErrorGetData] = useState('');
    const history = useHistory();
    const { navigationPage } = useNavigationPage();

    const {
        fetchingStoreTransportationOrder,
        storeTransportationOrder,
        fetchingCreateTransportationOrder,
        createTransportationOrder,
        fetchingShowQuoteTransportationOrder,
        showQuoteTransportationOrder
    } = useTransportationOrderService();

    const [transportationOrder, setTransportationOrder] = useState<TransportationOrderDataForm>(
        defaultTransportationOrderDataForm
    );

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        createTransportationOrder({
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setClients(response.data.clients);
                setPaymentMethods(response.data.payment_methods);
                setPaymentsConditions(response.data.payment_conditions);
                setCurrencies(response.data.currencies);
                setQuantityUnits(response.data.quantity_units);
                setQuotes(response.data.quotes);
            },
            onError: (response: ServiceResponse) => {
                setErrorGetData(response.message);
            }
        });
    }, []);

    useEffect(() => {
        if (transportationOrder.transportation_quote_detail_id) {
            showQuoteTransportationOrder(transportationOrder.transportation_quote_detail_id, {
                onSuccess: (response: ServiceResponse) => {
                    setErrorFields(undefined);
                    console.log(response.data.quote);
                    setTransportationOrder({
                        ...transportationOrder,
                        ...response.data.quote
                    });
                }
            });
        }
    }, [transportationOrder.transportation_quote_detail_id]);

    const store = () => {
        useSweetAlert().spinnerAlert('Guardando pedido...');
        storeTransportationOrder(transportationOrder, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                navigationPage(
                    '/transportation-orders/' + response.data.transportation_order_id + '/edit'
                );

                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return fetchingCreateTransportationOrder || fetchingShowQuoteTransportationOrder ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <TransportationOrderForm
                        transportationOrderForm={transportationOrder}
                        setTransportationOrderForm={setTransportationOrder}
                        societies={societies}
                        clients={clients}
                        paymentConditions={paymentConditions}
                        paymentMethods={paymentMethods}
                        currencies={currencies}
                        quantityUnits={quantityUnits}
                        errorFields={errorFields}
                        quotes={quotes}
                        isCreated={true}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreTransportationOrder}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={store}
                        locked={fetchingStoreTransportationOrder}
                    />
                </div>
            </div>
        </>
    );
};

export default TransportationOrderCreate;
