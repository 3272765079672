import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';

import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { CalendarRequest } from '../../models/CalendarRequest';

const useCalendarRequestService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetAllCalendarRequest, setFetchingGetAllCalendarRequest] = useState(false);
    const [fetchingStoreCalendarRequest, setFetchingStoreCalendarRequest] = useState(false);
    const [fetchingCreateCalendarRequest, setFetchingCreateCalendarRequest] = useState(false);
    const [fetchingGetWorkerCalendarRequest, setFetchingGetWorkerCalendarRequest] = useState(false);
    const [fetchingActiveCalendarRequest, setFetchingActiveCalendarRequest] = useState(false);
    const [fetchingDeleteCalendarRequest, setFetchingDeleteCalendarRequest] = useState(false);
    const [fetchingApprovedCalendarRequest, setFetchingApprovedCalendarRequest] = useState(false);
    const [fetchingGetUrlDocument, setFetchingGetUrlDocument] = useState(false);
    const [fetchingEditCalendarRequest, setFetchingEditCalendarRequest] = useState(false);
    const [fetchingUpdateCalendarRequest, setFetchingUpdateCalendarRequest] = useState(false);

    const getAllCalendarRequests = (calendarEventFilter: any, events: ServiceEvents = {}) => {
        const queryString = Object.entries(calendarEventFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.CALENDAR_REQUESTS.GET_ALL_CALENDAR_REQUESTS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetAllCalendarRequest
        });
    };

    const getWorkerCalendarRequests = (
        calendarEventFilter: any,
        id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(calendarEventFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.CALENDAR_REQUESTS.GET_WORKER_CALENDAR_REQUESTS.replace(
            ':id',
            id.toString()
        )}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetWorkerCalendarRequest
        });
    };

    const activeCalendarRequest = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CALENDAR_REQUESTS.ACTIVE_CALENDAR_REQUESTS.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveCalendarRequest
        });
    };

    const createCalendarRequest = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CALENDAR_REQUESTS.CREATE_CALENDAR_REQUESTS,
            setFetching: setFetchingCreateCalendarRequest
        });
    };

    const storeCalendarRequest = (calendarEvent: CalendarRequest, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.CALENDAR_REQUESTS.STORE_CALENDAR_REQUESTS,
            body: calendarEvent,
            setFetching: setFetchingStoreCalendarRequest
        });
    };

    const approvedCalendarRequest = (id: number, value: boolean, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.CALENDAR_REQUESTS.WORKER_APPROVED_CALENDAR_REQUESTS.replace(
                ':id',
                id.toString()
            ),
            body: { state: value },
            setFetching: setFetchingApprovedCalendarRequest
        });
    };

    const editCalendarRequest = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CALENDAR_REQUESTS.EDIT_CALENDAR_REQUEST.replace(':id', id.toString()),
            setFetching: setFetchingEditCalendarRequest
        });
    };

    const updateCalendarRequest = (
        id: number,
        ExpenseDataForm: CalendarRequest,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.CALENDAR_REQUESTS.UPDATE_CALENDAR_REQUEST.replace(':id', id.toString()),
            body: ExpenseDataForm,
            setFetching: setFetchingUpdateCalendarRequest
        });
    };

    const deleteCalendarRequest = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.CALENDAR_REQUESTS.DELETE_CALENDAR_REQUESTS.replace(':id', id.toString()),
            setFetching: setFetchingDeleteCalendarRequest
        });
    };

    const getUrlDocumentCalendarRequest = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CALENDAR_REQUESTS.DOCUMENT_CALENDAR_REQUESTS.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingGetUrlDocument
        });
    };

    return {
        fetchingEditCalendarRequest,
        fetchingUpdateCalendarRequest,
        fetchingGetAllCalendarRequest,
        fetchingStoreCalendarRequest,
        fetchingCreateCalendarRequest,
        fetchingGetWorkerCalendarRequest,
        fetchingDeleteCalendarRequest,
        fetchingApprovedCalendarRequest,
        fetchingActiveCalendarRequest,
        fetchingGetUrlDocument,
        editCalendarRequest,
        updateCalendarRequest,
        getAllCalendarRequests,
        getWorkerCalendarRequests,
        createCalendarRequest,
        activeCalendarRequest,
        deleteCalendarRequest,
        approvedCalendarRequest,
        storeCalendarRequest,
        getUrlDocumentCalendarRequest
    };
};

export default useCalendarRequestService;
