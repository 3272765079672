import { useContext, useEffect, useState } from 'react'
import { defaultDocumentSetDataForm, DocumentSetDataForm } from '../../../../app/models/DocumentSet'
import { ServiceResponse } from '../../../../app/services/shared/interfaces'
import LazyLoading from '../../../../components/LazyLoading'
import JobTitleLiquidationSetForm from '../forms/JobTitleLiquidationSetForm'
import { AppContext } from '../../../../contexts/AppContext'
import { toast } from 'react-toastify'
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert'
import useDocumentSetService from '../../../../app/services/hooks/useDocumentSetService'
import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm'
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm'
import { Deduction } from '../../../../app/models/Deduction'
import { Bonus } from '../../../../app/models/Bonus'
import { JobTitle } from '../../../../app/models/JobTitle'
import { defaultJobTitleLiquidationSetDataForm, JobTitleLiquidationSetDataForm } from '../../../../app/models/JobTitleLiquidationSet'
import useJobTitleLiquidationSetService from '../../../../app/services/hooks/useJobTitleLiquidationSetService'

interface Props {
    jobTitleLiquidationSetId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const JobTitleLiquidationSetEdit = ({ jobTitleLiquidationSetId, onSaved, onCancel, onError }: Props) => {

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { editJobTitleLiquidationSet, updateJobTitleLiquidationSet, fetchingEditJobTitleLiquidationSet, fetchingUpdateJobTitleLiquidationSet } = useJobTitleLiquidationSetService();
    const [jobTitleLiquidationSet, setJobTitleLiquidationSet] = useState<JobTitleLiquidationSetDataForm>(defaultJobTitleLiquidationSetDataForm);
    const [deductions, setDeductions] = useState<Deduction[]>([]);
    const [bonuses, setBonuses] = useState<Bonus[]>([]);
    const [jobTitles, setJobTitles] = useState<JobTitle[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, [])

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando set de documento...');
        editJobTitleLiquidationSet(jobTitleLiquidationSetId, {
            onSuccess: (response: ServiceResponse) => {
                setJobTitleLiquidationSet(response.data.job_title_liquidation_set);
                const _job_title_liquidation_set = response.data.job_title_liquidation_set;
                _job_title_liquidation_set.deductions = response.data.job_title_liquidation_set.deductions.map((deduction: any) => {
                    return {
                        value: deduction.id,
                        label: deduction.name
                    }
                })
                _job_title_liquidation_set.bonuses = response.data.job_title_liquidation_set.bonuses.map((bonus: any) => {
                    return {
                        value: bonus.id,
                        label: bonus.name
                    }
                })
                _job_title_liquidation_set.job_title_id = response.data.job_title_liquidation_set.job_title.id
                setJobTitleLiquidationSet(_job_title_liquidation_set);
                setJobTitles(response.data.job_titles);
                setDeductions(response.data.deductions);
                setBonuses(response.data.bonuses)
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando el set documento...');
        updateJobTitleLiquidationSet(jobTitleLiquidationSetId, jobTitleLiquidationSet, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };


    return fetchingEditJobTitleLiquidationSet ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <JobTitleLiquidationSetForm
                        jobTitleLiquidationSet={jobTitleLiquidationSet}
                        setJobTitleLiquidationSet={setJobTitleLiquidationSet}
                        deductions={deductions}
                        bonuses={bonuses}
                        jobTitles={jobTitles}
                        errorFields={errorFields}
                        setErrorFields={setErrorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingUpdateJobTitleLiquidationSet} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateJobTitleLiquidationSet} />
                </div>
            </div>
        </>
    )
}

export default JobTitleLiquidationSetEdit