import React from 'react';
import { DeductionFilters } from '../../../../app/models/Deduction';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: DeductionFilters;
    setFilter: (filter: DeductionFilters) => void;
    onSearch: () => void;
};

const DeductionFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="row">
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1">Nombre</label>
                            <input
                                title="Nombre de la bonificación"
                                type="text"
                                id="name"
                                name="name"
                                className="form-control form-control-sm"
                                value={typeof filter.name === 'string' ? filter.name : ''}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className='col-md-auto'>
                        <div style={{marginTop: '22px'}}>
                            <ButtonSearchFilter callbackSearch={onSearch}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeductionFilter;
