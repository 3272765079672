import React, { useEffect, useState } from 'react';
import CurrencyInput from '../../../../components/inputs/CurrencyInput';
import { DeductionsBasisValues, TypeOfDeductionsValues } from '../../../../app/models/Deduction';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { DeductionRateDataForm } from '../../../../app/models/DeductionRate';
import { Currency } from '../../../../app/models/Currency';
import { Item } from '../../../../app/models/Item';
import { Accordion } from 'react-bootstrap';
import ToggleBox from '../../../../components/ToggleBox';

interface Props {
    deductionRateForm: DeductionRateDataForm;
    setDeductionRateForm: (deduction: DeductionRateDataForm) => void;
    TypeOfDeductions: TypeOfDeductionsValues[];
    DeductionBasis: DeductionsBasisValues[];
    currencies: Currency[];
    items?: Item[];
    formulaItems?: string[];
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const DeductionRateForm = ({
    deductionRateForm,
    setDeductionRateForm,
    TypeOfDeductions,
    DeductionBasis,
    currencies,
    items,
    formulaItems,
    errorFields,
    setErrorFields
}: Props) => {

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [formula, setFormula] = useState<string>(deductionRateForm.formula ? deductionRateForm.formula : '');
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        if (formulaItems) setSelectedItems([...formulaItems]);
    }, [formulaItems]);

    useEffect(() => {
        setDeductionRateForm({ ...deductionRateForm, 'formula': `${formula}` });
    }, [formula]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setDeductionRateForm({ ...deductionRateForm, [name]: value });
    };


    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setDeductionRateForm({ ...deductionRateForm, ...obj });
    };

    const handleSelectionChange = (
        key: string
    ) => {
        setSelectedItems([...selectedItems, key]);
        setFormula(prevFormula => prevFormula + `${key}`);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const lastChar = value.slice(-1);
        const validChars = /^[+\-*/().0-9%]*$/;
    
        if (validChars.test(lastChar)) setFormula(`${formula}${lastChar}`);
    };

    const isSelected = (key: string) => {
        if(selectedItems.find((item) => item === key)){
            return true
        }else {
            return false
        }
    };

    const cleanFormula = () => {
        setSelectedItems([]);
        setFormula('');
        setDeductionRateForm({ ...deductionRateForm, 'formula': '' });
    }

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 mt-2 col-form-label">Tipo de deducción</label>
                <div className="mt-3 col-md-9">
                    <SelectTwo
                        name="deduction_type"
                        id="deduction_type"
                        inputValue={deductionRateForm.deduction_type}
                        options={SelectTwoMapperOptions(TypeOfDeductions, 'id', ['name'])}
                        hasError={fieldHasError('deduction_type') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('deduction_type', value.value)
                        }
                        placeholder={'Seleccione el tipo de deducción'}
                        onFocus={() => onFocusRemove('deduction_type')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('deduction_type')}
                    </div>
                </div>
            
                {deductionRateForm.deduction_type == 'Fijo' && 
                <>
                    <div className="col-md-6">
                        <label className="col-md-6 col-form-label">Monto</label>
                        <div className="col-md-12">
                            <CurrencyInput
                                name="value"
                                onFocus={() => onFocusRemove('value')}
                                className={`form-control ${fieldHasError('value')}`}
                                value={deductionRateForm.value}
                                onChange={handleChange}
                                placeholder="Ingrese valor"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('value')}
                            </div>
                        </div>
                    </div>
                        <div className="col-md-6">
                        <label className="col-form-label">Moneda</label>
                        <SelectTwo
                            name="currency_id"
                            id="currency_id"
                            inputValue={deductionRateForm.currency_id}
                            options={SelectTwoMapperOptions(currencies)}
                            hasError={fieldHasError('currency_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('currency_id', value?.value)
                            }
                            placeholder={'Seleccione moneda'}
                            onFocus={() => onFocusRemove('currency_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('currency_id')}
                        </div>
                    </div>
                    </>
                }

                {deductionRateForm.deduction_type == 'Porcentaje' && 
                    <>
                        <label className="col-md-6 col-form-label">Porcentaje</label>
                        <div className="col-md-6">
                            <input
                                type="value"
                                name="value"
                                id="value"
                                onFocus={() => onFocusRemove('value')}
                                className={`form-control ${fieldHasError('value')}`}
                                value={deductionRateForm.value}
                                onChange={handleChange}
                                placeholder="Ingrese valor"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('value')}
                            </div>
                        </div>
                    </>
                }

                {deductionRateForm.deduction_type == 'Calculado' && items &&
                    <div className="col-md-12">
                        <label className="col-md-12 col-form-label">Items</label>
                        <Accordion>
                            {items.length ===0 ? (
                                <div className="mt-2 text-danger fw-bold">
                                    No se encontraron coincidencias
                                </div>
                            ) : (
                                items.map((item, index)=> {
                                    return (
                                        <Accordion.Item
                                            eventKey={`group${index}`}
                                            key={index}
                                        >
                                            <Accordion.Header>
                                                <span className="text-uppercase">{item.name}</span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="row pt-2">
                                                    {item.key_list &&
                                                        item.key_list.map(
                                                            (key_item, index) => {
                                                                return (
                                                                    <ToggleBox
                                                                        key={`permission${index}`}
                                                                        onClick={() => handleSelectionChange(key_item.key)}
                                                                        isSelected={isSelected(key_item.key)}
                                                                        title=""
                                                                        name={key_item.name}
                                                                        hover={key_item.hover}
                                                                    />
                                                                );
                                                            }
                                                        )
                                                    }
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                )})
                            )}
                        </Accordion>
                        <label className="col-md-9 col-form-label">Formula</label>
                        <div className="col-md-12 row">
                            <div className="col-md-9">
                                <input
                                    name="name"
                                    id="name"
                                    type="text"
                                    className={`form-control ${fieldHasError('name')}`}
                                    value={formula}
                                    onChange={handleInputChange}
                                    onFocus={() => onFocusRemove('name')}
                                    placeholder="Ingrese la formula"
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('name')}
                                </div>
                            </div>
                            <button 
                                type="button"
                                className="btn btn-outline-light col-md-3"
                                onClick={() => cleanFormula()}
                            >
                                Borrar
                            </button>
                        </div>
                    </div>
                }

                <label className="col-md-3 mt-2 col-form-label">Base deducción</label>
                <div className="mt-3 col-md-9">
                    <SelectTwo
                        name="deduction_basis"
                        id="deduction_basis"
                        inputValue={deductionRateForm.deduction_basis}
                        options={SelectTwoMapperOptions(DeductionBasis, 'id', ['name'])}
                        hasError={fieldHasError('deduction_basis') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('deduction_basis', value.value)
                        }
                        placeholder={'Seleccione la base de deducción'}
                        onFocus={() => onFocusRemove('deduction_basis')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('deduction_basis')}
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="col-md-6 col-form-label">Fecha de inicio</label>
                    <div className="col-md-12">
                        <input
                            type="month"
                            className={`form-control ${fieldHasError('valid_from')}`}
                            name="valid_from"
                            id="valid_from"
                            value={deductionRateForm.valid_from?.toString()}
                            onChange={handleChange}
                            placeholder="Ingrese una fecha"
                            onFocus={() => onFocusRemove('date')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('valid_from')}
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="col-md-9 col-form-label">Fecha de término</label>
                    <div className="col-md-12">
                        <input
                            type="month"
                            className={`form-control ${fieldHasError('valid_until')}`}
                            name="valid_until"
                            id="valid_until"
                            value={deductionRateForm.valid_until?.toString()}
                            onChange={handleChange}
                            placeholder="Ingrese una fecha"
                            onFocus={() => onFocusRemove('valid_until')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('valid_until')}
                        </div>
                    </div>
                </div> 

                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('non_field_errors')}
                </div>

            </div>
        </>
    );
};

export default DeductionRateForm;
