import React, { useEffect, useState } from 'react';
import { Provider } from '../../../../app/models/Provider';
import { ExpenseDataForm } from '../../../../app/models/Expense';
import { ExpenseType } from '../../../../app/models/ExpenseType';
import { ExpenseDocumentType } from '../../../../app/models/ExpenseDocumentType';
import { Currency } from '../../../../app/models/Currency';
import { setCleanTextAreaError } from '../../../../utils/utils';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { momentParseDateToInput } from '../../../../helpers';
import CurrencyInput from '../../../../components/inputs/CurrencyInput';

interface Props {
    ExpenseForm: ExpenseDataForm;
    selectedEventDate: string | Date;
    setExpenseForm: (expense: ExpenseDataForm) => void;
    providers: Provider[];
    expenseTypes: ExpenseType[];
    expenseDocumentTypes: ExpenseDocumentType[];
    errorFields?: any;
    currencies: Currency[];
}

const CalendarExpenseForm = ({
    ExpenseForm,
    setExpenseForm,
    providers,
    expenseTypes,
    currencies,
    expenseDocumentTypes,
    selectedEventDate,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        setExpenseForm({ ...ExpenseForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setExpenseForm({ ...ExpenseForm, ...obj });
    };

    const handleChangeDocumentFile = (e: FileList | null, type: string) => {
        if (e) {
            setExpenseForm({ ...ExpenseForm, ['photo']: e[0] });
        }
    };

    const handleReportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        setExpenseForm({ ...ExpenseForm, is_reported: checked });
    };

    return (
        <>
            <div className="mb-2-row">
                <div className="col-md-12">
                    <label className="col-form-label">Tipo de gasto</label>
                    <SelectTwo
                        name="expense_type_id"
                        id="expense_type_id"
                        inputValue={ExpenseForm.expense_type_id}
                        options={SelectTwoMapperOptions(expenseTypes, 'id', ['name'])}
                        hasError={fieldHasError('expense_type_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('expense_type_id', value.value)
                        }
                        placeholder={'Seleccione tipo de gasto'}
                        onFocus={() => onFocusRemove('expense_type_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('si')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Valor</label>
                    <CurrencyInput
                        name={'amount'}
                        currency={ExpenseForm.currency_id}
                        currencies={currencies}
                        onChange={handleChange}
                        value={ExpenseForm.amount}
                        className={`form-control ${fieldHasError(`amount`)}`}
                        onFocus={() => onFocusRemove(`amount`)}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage(`amount`)}
                    </div>
                </div>

                <div className="col-6">
                    <label className="col-form-label">Moneda</label>

                    <SelectTwo
                        name="currency_id"
                        id="currency_id"
                        inputValue={ExpenseForm.currency_id}
                        options={SelectTwoMapperOptions(currencies)}
                        hasError={fieldHasError('currency_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('currency_id', value.value)}
                        onFocus={() => onFocusRemove('currency_id')}
                        placeholder={'Moneda'}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('currency_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Fecha de gasto</label>
                    <input
                        name={'issue_date'}
                        id={`issue_date`}
                        type="date"
                        value={
                            selectedEventDate.toString()
                                ? selectedEventDate.toString()
                                : ExpenseForm.issue_date
                        }
                        className={`form-control ${fieldHasError(`issue_date`)}`}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove(`issue_date`)}
                        readOnly
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage(`issue_date`)}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Proveedor</label>
                    <SelectTwo
                        name="provider_id"
                        id="provider_id"
                        inputValue={ExpenseForm.provider_id}
                        options={SelectTwoMapperOptions(providers, 'id', ['name'])}
                        hasError={fieldHasError('provider_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('provider_id', value.value)}
                        placeholder={'Seleccione proveedor'}
                        onFocus={() => onFocusRemove('provider_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provider_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Tipo de documento</label>
                    <SelectTwo
                        name="expense_document_type"
                        id="expense_document_type"
                        inputValue={ExpenseForm.expense_document_type}
                        options={SelectTwoMapperOptions(expenseDocumentTypes, 'id', ['name'])}
                        hasError={fieldHasError('expense_document_type') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('expense_document_type', value.value)
                        }
                        placeholder={'Seleccione tipo de documento'}
                        onFocus={() => onFocusRemove('expense_document_type')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('expense_document_type')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Folio</label>
                    <input
                        name={'folio'}
                        id={`folio`}
                        type="text"
                        value={ExpenseForm.folio}
                        className={`form-control ${fieldHasError(`folio`)}`}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove(`folio`)}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage(`folio`)}
                    </div>
                </div>
            </div>

            <div className="mb-2 row align-items-center">
                <label htmlFor="is_reported" className="col-md-3 col-form-label">
                    Rendir gasto
                </label>
                <div className="col-9">
                    <div className="square-switch d-flex  form-check form-switch">
                        <input
                            type="checkbox"
                            name="is_reported"
                            id="is_reported"
                            className={`form-check-input ${
                                ExpenseForm.is_reported ? 'active' : ''
                            }`}
                            checked={ExpenseForm.is_reported}
                            onChange={handleReportChange}
                        />
                        {fieldErrorMessage('is_reported')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Boleta</label>
                    <InputButtonFile
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        name={'photo'}
                        objectFile={ExpenseForm?.photo}
                        onFocus={() => onFocusRemove('photo')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('photo')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Descripción</label>
                    <textarea
                        className={`form-control ${fieldHasError('description')}`}
                        name="description"
                        id="description"
                        value={ExpenseForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CalendarExpenseForm;
