import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';
import { TransportOrderDataForm, TransportOrderFilters } from '../../models/TransportOrder';
import { ServiceEvents, ServiceEventsDocuments } from '../shared/interfaces';

const useTransportOrderService = () => {
    const { doGet, doPost, doPut, doDelete, doGetDocument } = useFetch();

    const [fetchingGetTransportOrdersByWorkOrder, setFetchingGetTransportOrdersByWorkOrder] =
        useState(false);
    const [fetchingGetExcelTransportOrders, setFetchingGetExcelTransportOrders] = useState(false);
    const [fetchingStoreTransportOrder, setFetchingStoreTransportOrder] = useState(false);
    const [fetchingEditTransportOrder, setFetchingEditTransportOrder] = useState(false);
    const [fetchingUpdateTransportOrder, setFetchingUpdateTransportOrder] = useState(false);
    const [fetchingDeleteTransportOrder, setFetchingDeleteTransportOrder] = useState(false);
    const [fetchingCreateTransportOrder, setFetchingCreateTransportOrder] = useState(false);
    const [fetchingShowTransportOrder, setFetchingShowTransportOrder] = useState(false);
    const [fetchingChangeStatusTransportOrder, setFetchingChangeStatusTransportOrder] =
        useState(false);
    const [fetchingUnlockStatusTransportOrder, setFetchingUnlockStatusTransportOrder] =
        useState(false);
    const [fetchingGetTransportOrders, setFetchingGetTransportOrders] = useState(false);
    const [fetchingValidateStatusChange, setFetchingValidateStatusChange] = useState(false);
    const [
        fetchingGetExcelTransportOrdersByWorkOrder,
        setFetchingGetExcelTransportOrdersByWorkOrder
    ] = useState(false);

    const [
        fetchingGetTransportOrdersReadyToDispatchProgram,
        setFetchingGetTransportOrdersReadyToDispatchProgram
    ] = useState(false);

    const [fetchingGetTransportOrdersByCarrierChart, setFetchingGetTransportOrdersByCarrierChart] =
        useState(false);

    const getTransportOrders = async (
        transportOrderFilters: TransportOrderFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(transportOrderFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.TRANSPORT_ORDERS.GET_TRANSPORT_ORDER}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetTransportOrders
        });
    };

    const getTransportOrdersReadyToDispatchProgram = (
        filter: TransportOrderFilters,
        dispatchProgramId: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.TRANSPORT_ORDERS.GET_TRANSPORT_ORDERS_TO_DISPATCH_PROGRAM}?${queryString}`;

        doGet({
            ...events,
            url: url.replace(':dispatch_program_id', dispatchProgramId.toString()),
            setFetching: setFetchingGetTransportOrdersReadyToDispatchProgram
        });
    };

    const getTransportOrdersByCarrierChart = async (
        transportOrderFilters: TransportOrderFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(transportOrderFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.TRANSPORT_ORDERS.GET_TRANSPORT_ORDER_BY_CARRIER_CHART}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetTransportOrdersByCarrierChart
        });
    };
    const getAllTransportOrdersByWorkOrder = (
        transportOrderFilters: TransportOrderFilters,
        work_order_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(transportOrderFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.TRANSPORT_ORDERS.GET_TRANSPORT_ORDER_BY_WORK_ORDER}?${queryString}`;

        doGet({
            ...events,
            url: url.replace(':work_order_id', work_order_id.toString()),
            setFetching: setFetchingGetTransportOrdersByWorkOrder
        });
    };

    const getTransportOrderByIdWithGuide = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORT_ORDERS.GET_TRANSPORT_ORDER_BY_ID_WITH_GUIDE.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingGetTransportOrdersByWorkOrder
        });
    };

    const createTransportOrder = (workOrderId: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORT_ORDERS.CREATE_TRANSPORT_ORDER.replace(
                ':work_order_id',
                workOrderId.toString()
            ),
            setFetching: setFetchingCreateTransportOrder
        });
    };

    const storeTransportOrder = (
        transportOrderDataForm: TransportOrderDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.TRANSPORT_ORDERS.STORE_TRANSPORT_ORDER,
            body: transportOrderDataForm,
            setFetching: setFetchingStoreTransportOrder
        });
    };

    const editTransportOrder = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORT_ORDERS.EDIT_TRANSPORT_ORDER.replace(':id', id.toString()),
            setFetching: setFetchingEditTransportOrder
        });
    };

    const updateTransportOrder = (
        id: number,
        transportOrderDataForm: TransportOrderDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.TRANSPORT_ORDERS.UPDATE_TRANSPORT_ORDER.replace(':id', id.toString()),
            body: transportOrderDataForm,
            setFetching: setFetchingUpdateTransportOrder
        });
    };
    const showTransportOrder = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORT_ORDERS.SHOW_TRANSPORT_ORDER.replace(':id', id.toString()),
            setFetching: setFetchingShowTransportOrder
        });
    };

    const deleteTransportOrder = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.TRANSPORT_ORDERS.DELETE_TRANSPORT_ORDER.replace(':id', id.toString()),
            setFetching: setFetchingDeleteTransportOrder
        });
    };

    const changeStatusTransportOrder = (id: number, status: string, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.TRANSPORT_ORDERS.CHANGE_STATUS_TRANSPORT_ORDER.replace(
                ':id',
                id.toString()
            ),
            body: { status: status },
            setFetching: setFetchingChangeStatusTransportOrder
        });
    };

    const unlockStatusTransportOrder = (id: number, reason: string, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.TRANSPORT_ORDERS.UNLOCK_STATUS_TRANSPORT_ORDER.replace(
                ':id',
                id.toString()
            ),
            body: { reason: reason },
            setFetching: setFetchingUnlockStatusTransportOrder
        });
    };

    const validateStatusChange = (id: number, status: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORT_ORDERS.VALIDATE_STATUS_CHANGE_TRANSPORT_ORDER.replace(
                ':id',
                id.toString()
            ).replace(':transport_order_status', status),
            setFetching: setFetchingValidateStatusChange
        });
    };

    const getExcelAllTransportOrders = (
        transportOrderFilters: TransportOrderFilters,
        events: ServiceEventsDocuments = {}
    ) => {
        const queryString = Object.entries(transportOrderFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.TRANSPORT_ORDERS.GET_EXCEL_TRANSPORT_ORDERS}?${queryString}`;

        doGetDocument({
            ...events,
            url: url,
            successData: {
                nameDocument: 'ordenes_transporte.xlsx',
                message: 'Descarga exitosa.'
            },
            setFetching: setFetchingGetExcelTransportOrders
        });
    };

    const getExcelAllTransportOrdersByWorkOrder = (
        work_order_id: number,
        transportOrderFilters: TransportOrderFilters,
        events: ServiceEventsDocuments = {}
    ) => {
        const queryString = Object.entries(transportOrderFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.TRANSPORT_ORDERS.GET_EXCEL_TRANSPORT_ORDERS_BY_WORK_ORDER}?${queryString}`;

        doGetDocument({
            ...events,
            url: url.replace(':work_order_id', work_order_id.toString()),
            successData: {
                nameDocument: 'ordenes_transporte.xlsx',
                message: 'Descarga exitosa.'
            },
            setFetching: setFetchingGetExcelTransportOrdersByWorkOrder
        });
    };

    return {
        getAllTransportOrdersByWorkOrder,
        createTransportOrder,
        storeTransportOrder,
        editTransportOrder,
        updateTransportOrder,
        showTransportOrder,
        deleteTransportOrder,
        validateStatusChange,
        changeStatusTransportOrder,
        getTransportOrders,
        getExcelAllTransportOrders,
        getExcelAllTransportOrdersByWorkOrder,
        getTransportOrdersByCarrierChart,
        getTransportOrdersReadyToDispatchProgram,
        getTransportOrderByIdWithGuide,
        unlockStatusTransportOrder,
        fetchingGetTransportOrders,
        fetchingGetTransportOrdersByWorkOrder,
        fetchingStoreTransportOrder,
        fetchingEditTransportOrder,
        fetchingUpdateTransportOrder,
        fetchingShowTransportOrder,
        fetchingDeleteTransportOrder,
        fetchingCreateTransportOrder,
        fetchingChangeStatusTransportOrder,
        fetchingGetExcelTransportOrders,
        fetchingValidateStatusChange,
        fetchingGetExcelTransportOrdersByWorkOrder,
        fetchingGetTransportOrdersByCarrierChart,
        fetchingGetTransportOrdersReadyToDispatchProgram,
        fetchingUnlockStatusTransportOrder
    };
};

export default useTransportOrderService;
