import { useContext, useEffect, useState } from 'react';
import useBonusService from '../../../app/services/hooks/useBonusService';
import LazyLoading from '../../../components/LazyLoading';
import { Bonus, TypeOfBonusesValues } from '../../../app/models/Bonus';
import BonusFormContainer from '../components/BonusFormContainer';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { Currency } from '../../../app/models/Currency';
import { useHistory, useParams } from 'react-router-dom';
import BonusForm from '../components/forms/BonusForm';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import BonusRateContainer from '../components/BonusRateContainer';

type RouterParams = {
    id: string;
};

const BonusEdit = () => {

    const history = useHistory();
    const { id } = useParams<RouterParams>();
    const { fetchingEditBonus, editBonus, fetchingUpdateBonus, updateBonus } =
        useBonusService();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [errorFields, setErrorFields] = useState<any>();
    const [bonus, setBonus] = useState<Bonus>({ name: '' });

    useEffect(() => {
        edit(parseInt(id));
    }, [id]);

    const edit = (id: number) => {
        if (showLoading) showLoading('loading', 'Cargando bonificación...');
        editBonus(id, {
            onSuccess: (response: ServiceResponse) => {
                setBonus(response.data.bonus);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando bonificación...');
        updateBonus(parseInt(id), bonus, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Bonificaciones',
            url: '/bonus',
            isActive: false
        },
        {
            name: 'Editar #' + id,
            url: '/bonus/' + id + '/edit',
            isActive: true
        }
    ]

    return fetchingEditBonus ? (
        <LazyLoading height="300" />
    ) : (
        <>
            <Breadcrumbs pageSection={`Editar #${id}`} breadcrumbs={breadcrumbs} />
            <div className='row'>
                <div className='col-md-6'>
                    <DefaultCard>
                        <BonusForm
                            bonusForm={bonus}
                            setBonusForm={setBonus}
                            errorFields={errorFields}
                        />
                        <div className='row justify-content-end'>
                            <div className='col-auto'>
                                <ButtonSaveForm callbackSave={update} locked={fetchingUpdateBonus} />
                            </div>
                        </div>
                    </DefaultCard>
                </div>
            </div>
            <>
                <BonusRateContainer bonusId={parseInt(id)} />
            </>
        </>
    );
};

export default BonusEdit;
