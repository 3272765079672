import { Deduction } from "../../../app/models/Deduction"
import ButtonCancelForm from "../../../components/buttons/ButtonCancelForm"
import ButtonSaveForm from "../../../components/buttons/ButtonSaveForm"
import DeductionForm from "./forms/DeductionForm"

interface Props {
  fetching?: boolean;
    action: () => void;
    cancel: () => void;
    deduction: Deduction;
    setDeduction: (deduction: Deduction) => void;
    errorFields?: any;
}

const DeductionFormContainer = ({
    fetching,
    action,
    cancel,
    deduction,
    setDeduction,
    errorFields = null,
}: Props) => {
  return (
    <>
        <div className="row">
            <div className="col-12">
                <DeductionForm
                  deductionForm={deduction}
                  setDeductionForm={setDeduction}
                  errorFields={errorFields}
                />
            </div>
        </div>
        <hr />
        <div className="row justify-content-end">
            <div className="col-auto">
                <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
            </div>
            <div className="col-auto">
                <ButtonSaveForm callbackSave={action} locked={fetching} />
            </div>
        </div>
    </>
);
}

export default DeductionFormContainer