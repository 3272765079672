import { useEffect, ChangeEvent, useState, ReactNode } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { Unit, UnitEquivalenceDataForm } from '../../../../app/models/Unit';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import CustomInput from '../../../../components/inputs/CustomInput';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';

type Props = {
    unitEquivalenceDataForm: UnitEquivalenceDataForm;
    setUnitEquivalenceDataForm: (UnitEquivalence: UnitEquivalenceDataForm) => void;
    units: Unit[];
    errorFields?: any;
};

const UnitEquivalenceForm = ({
    unitEquivalenceDataForm,
    setUnitEquivalenceDataForm,
    units,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [text, setText] = useState<string>('');
    const [fromUnitOptions, setFromUnitOptions] = useState<Unit[]>(units);
    const [toUnitOptions, setToUnitOptions] = useState<Unit[]>(units);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        if (unitEquivalenceDataForm.from_unit_id && unitEquivalenceDataForm.to_unit_id) {
            setText(`1 ${findUnitName(Number(unitEquivalenceDataForm.from_unit_id))} equivale a`);
        } else {
            setText('');
        }
        setToUnitOptions(filterUnits(Number(unitEquivalenceDataForm.from_unit_id)));
        setFromUnitOptions(filterUnits(Number(unitEquivalenceDataForm.to_unit_id)));
    }, [unitEquivalenceDataForm]);

    const findUnitName = (id: number) => {
        const unit = units.find((unit) => unit.id == id);
        return unit?.name;
    };

    const filterUnits = (id: number) => {
        const filteredUnits = units.filter((unit) => unit.id !== id);
        return filteredUnits;
    };

    const changeUnits = () => {
        if (unitEquivalenceDataForm.from_unit_id && unitEquivalenceDataForm.to_unit_id) {
            const newToUnit = unitEquivalenceDataForm.from_unit_id;
            const newFromUnit = unitEquivalenceDataForm.to_unit_id;
            setToUnitOptions(filterUnits(Number(newFromUnit)));
            setFromUnitOptions(filterUnits(Number(newToUnit)));
            setUnitEquivalenceDataForm({
                ...unitEquivalenceDataForm,
                from_unit_id: newFromUnit,
                to_unit_id: newToUnit
            });
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUnitEquivalenceDataForm({ ...unitEquivalenceDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setUnitEquivalenceDataForm({ ...unitEquivalenceDataForm, ...obj });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <div className="col-md-7">
                    <label className="col-form-label">De</label>
                </div>
                <div className="col-md-2">
                    <label className="col-form-label">A</label>
                </div>
                <div className="col-md-5">
                    <SelectTwo
                        name="from_unit_id"
                        id="from_unit_id"
                        inputValue={unitEquivalenceDataForm.from_unit_id}
                        options={SelectTwoMapperOptions(fromUnitOptions)}
                        hasError={fieldHasError('from_unit') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('from_unit_id', value?.value)
                        }
                        placeholder={'Seleccione una unidad'}
                        onFocus={() => onFocusRemove('from_unit')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('from_unit')}
                    </div>
                </div>
                <div className="col-md-2 d-flex justify-content-center">
                    <ButtonTableAction
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        callbackFunction={changeUnits}
                        classIcon={'mdi mdi-transfer'}
                        colorIcon={'text-success'}
                        sizeIcon={'30'}
                    />
                </div>
                <div className="col-md-5">
                    <SelectTwo
                        name="to_unit_id"
                        id="to_unit_id"
                        inputValue={unitEquivalenceDataForm.to_unit_id}
                        options={SelectTwoMapperOptions(toUnitOptions)}
                        hasError={fieldHasError('to_unit') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('to_unit_id', value?.value)}
                        placeholder={'Seleccione una unidad'}
                        onFocus={() => onFocusRemove('to_unit')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('to_unit')}
                    </div>
                </div>
                {text && (
                    <div className="mt-3 row needs-validation">
                        <div className="col-md-4">
                            <label className="col-form-label">{text}</label>
                        </div>
                        <div className="col-md-8 d-flex align-items-center">
                            <div className="flex-grow-1">
                                <CustomInput
                                    name="to_unit_quantity"
                                    className="form-control"
                                    value={
                                        unitEquivalenceDataForm.to_unit_quantity !== 0
                                            ? unitEquivalenceDataForm.to_unit_quantity
                                            : ''
                                    }
                                    onChange={handleChange}
                                    onFocus={() => onFocusRemove('phone')}
                                    placeholder="Cantidad"
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('to_unit_quantity')}
                                </div>
                            </div>
                            <div className="col-md-1 ms-3 mt-1">
                                <h6>{findUnitName(Number(unitEquivalenceDataForm.to_unit_id))}</h6>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default UnitEquivalenceForm;
