import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultCalendar from '../../components/default/DefaultCalendar';
import useHolidayService from '../../app/services/hooks/useHolidayService';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useNavigationPage from '../../hooks/useNavigationPage';
import { Holiday } from '../../app/models/Holiday';
import useEventService from '../../app/services/hooks/useEventService';
import { formatDateToInputDate, momentParseDateToInput } from '../../helpers';
import {
    CalendarEvent,
    CalendarEventDataForm,
    defaultCalendarEventDataForm
} from '../../app/models/Event';
import DefaultModal from '../../components/default/DefaultModal';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import CalendarRequestFormContainer from './components/containers/CalendarRequestFormContainer';
import LazyLoading from '../../components/LazyLoading';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Calendario',
        url: '/calendar',
        isActive: true
    }
];

const Calendar = () => {
    const { getHolidaysByDate } = useHolidayService();
    const { navigationPage } = useNavigationPage();
    const [holidays, setHolidays] = useState<Holiday[]>([]);
    const [date, setDate] = useState<Date | string>(new Date());
    const [view, setView] = useState<string>('month');
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [selectedEventId, setSelectedEventId] = useState<number>(-1);
    const [selectedEvent, setSelectedEvent] = useState<CalendarEventDataForm>(
        defaultCalendarEventDataForm
    );
    const { fetchingGetEventsByWorker, getEventsByWorker } = useEventService();
    const [eventsByWorker, setEventsByWorker] = useState<CalendarEvent[]>([]);
    const [showingCreateRequest, setShowingCreateRequest] = useState(false);

    useEffect(() => {
        reloadCalendar();
    }, [date, view]);

    useEffect(() => {
        const holidaysEvents = holidays.map((holiday) => {
            return {
                title: holiday.name!,
                start: new Date(holiday.this_year_date!),
                end: new Date(holiday.this_year_date!),
                color: '#f01d20'
            };
        });
        const shiftEvents = eventsByWorker.map((event) => {
            return {
                id: event.id,
                title: event.title,
                start: new Date(event.start_date!),
                end: new Date(event.end_date!),
                color: event.color,
                expenseId: event.expense_id,
                isShiftWork: event.is_shift_work
            };
        });
        setEvents([...shiftEvents, ...holidaysEvents]);
    }, [holidays, eventsByWorker]);

    const reloadCalendar = () => {
        getHolidays();
        getAllEvents();
    };

    const getHolidays = () => {
        getHolidaysByDate(formatDateToInputDate(date), view, {
            onSuccess: (response: ServiceResponse) => {
                setHolidays(response.data.holidays);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const getAllEvents = () => {
        getEventsByWorker(formatDateToInputDate(date), view, {
            onSuccess: (response: ServiceResponse) => {
                setEventsByWorker(response.data.events);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = (event: any) => {
        setSelectedEventId(event.id);
        setSelectedEvent(event);
        navigationPage(
            `/calendar-by-worker/${event?.id}/event/show?start_date=${momentParseDateToInput(
                event.start
            )}&end_date=${momentParseDateToInput(event.end)}&admin=false`
        );
    };

    const showRequest = () => {
        setShowingCreateRequest(true);
    };

    const hideRequest = () => {
        setShowingCreateRequest(false);
    };

    return (
        <>
            <Breadcrumbs pageSection="Calendario" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <ButtonCreate
                                    callbackCreate={showRequest}
                                    title="Nueva solicitud"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <DefaultCalendar
                            events={events}
                            setDate={setDate}
                            setView={setView}
                            selectable={true}
                            onSelectEvent={showCreate}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreateRequest && (
                <DefaultModal
                    show={showingCreateRequest}
                    handleClose={hideRequest}
                    title="Añadir nueva solicitud"
                    backdrop={true}
                    showFooter={false}
                >
                    <CalendarRequestFormContainer
                        onSaved={hideRequest}
                        onCancel={hideRequest}
                        onError={hideRequest}
                        showWorkerField={false}
                    />
                </DefaultModal>
            )}
        </>
    );
};

export default Calendar;
