import { TruckDataForm, TruckFilters } from '../../../../app/models/Truck';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ActiveControl from '../../../../components/ActiveControl';
import { HopperDataForm } from '../../../../app/models/Hopper';
import { WorkOrderDataForm, WorkOrderFilters } from '../../../../app/models/WorkOrder';
import { momentParseDate } from '../../../../helpers';
import { formatMoney } from '../../../../utils/utils';
import WorkOrderFilter from './WorkOrderFilter';
import React from 'react';
import { customInterspersedStyles } from '../../../../utils/dataTableStyles';

interface Props {
    workOrders: WorkOrderDataForm[];
    totalRows: number;
    statuses: any[];
    loading?: boolean;
    edit?: (id: number) => void;
    // active: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: TruckFilters;
    setFilter: (filter: WorkOrderFilters) => void;

    paginationRowsPerPageOptions?: any[];

    onSearch: () => void;
}

const WorkOrderDataTable = ({
    workOrders,
    totalRows,
    loading = false,
    edit,
    statuses,
    destroy,
    // active,
    show,
    filter,
    setFilter,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: WorkOrderDataForm, index: any, column: any) => (
                <span className="">{row?.id}</span>
            )
        },
        {
            name: 'Sociedad',
            selector: (row: any) => row?.society,
            sortable: true,
            sortField: 'society',
            cell: (row: WorkOrderDataForm, index: any, column: any) => (
                <span className="">{row?.society}</span>
            )
        },

        {
            name: 'Patente Tracto',
            selector: (row: any) => row?.truck,
            sortable: true,
            sortField: 'patent_truck',
            cell: (row: WorkOrderDataForm, index: any, column: any) => (
                <span
                    dangerouslySetInnerHTML={{
                        __html: row.truck ? String(row.truck.patent) : ''
                    }}
                ></span>
            )
        },
        {
            name: 'Marca Tracto',
            selector: (row: any) => row?.truck,
            sortable: true,
            sortField: 'name_brand',
            cell: (row: WorkOrderDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{
                        __html: row.truck ? String(row.truck.brand?.name) : ''
                    }}
                ></span>
            )
        },
        {
            name: 'Modelo Tracto',
            selector: (row: any) => row?.truck,
            sortable: true,
            sortField: 'vehicle_model',
            cell: (row: WorkOrderDataForm, index: any, column: any) => (
                <span
                    dangerouslySetInnerHTML={{
                        __html: row.truck ? String(row.truck.vehicle_model?.name) : ''
                    }}
                ></span>
            )
        },
        {
            name: 'Total Ventas',
            selector: (row: any) => row?.total_sales,
            sortable: true,
            sortField: 'total_sales',
            cell: (row: WorkOrderDataForm, index: any, column: any) => (
                <span
                    dangerouslySetInnerHTML={{
                        __html: row.total_sales ? formatMoney(row.total_sales) : ''
                    }}
                ></span>
            )
        },
        {
            name: 'Total Gastos',
            selector: (row: any) => row?.total_expenses,
            sortable: true,
            sortField: 'total_expenses',
            cell: (row: WorkOrderDataForm, index: any, column: any) => (
                <span
                    dangerouslySetInnerHTML={{
                        __html: row.total_expenses ? formatMoney(row.total_expenses) : ''
                    }}
                ></span>
            )
        },
        {
            name: 'Periodo',
            selector: (row: any) => row?.period,
            sortable: true,
            sortField: 'period',
            cell: (row: WorkOrderDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.period, 'MM-YYYY')}</span>
            )
        },
        // {
        //     name: 'Fecha termino',
        //     selector: (row: any) => row?.end_date,
        //     sortable: true,
        //     sortField: 'end_date',
        //     cell: (row: WorkOrderDataForm, index: any, column: any) => (
        //         <span className="">{row?.end_date}</span>
        //     )
        // },
        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            cell: (row: WorkOrderDataForm, index: any, column: any) => (
                <span className="">
                    {statuses.find((workOrderStatus) => workOrderStatus.id === row.status)?.name}
                </span>
            )
        },
        // {
        //     name: 'Valor real',
        //     selector: (row: any) => row?.real_value,
        //     sortable: true,
        //     sortField: 'real_value',
        //     cell: (row: WorkOrderDataForm, index: any, column: any) => (
        //         <span className="">{row?.real_value}</span>
        //     )
        // },

        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: WorkOrderDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <WorkOrderFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={workOrders}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
                isMobileStacked={true}
                styleTable={customInterspersedStyles}
            />
        </>
    );
};

export default WorkOrderDataTable;
