import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Group, GroupDataForm, GroupFilters } from '../../models/Group';

const useGroupService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetGroups, setFetchingGetGroups] = useState(false);
    const [fetchingShowGroup, setFetchingShowGroup] = useState(false);
    const [fetchingStoreGroup, setFetchingStoreGroup] = useState(false);
    const [fetchingEditGroup, setFetchingEditGroup] = useState(false);
    const [fetchingCreateGroup, setFetchingCreateGroup] = useState(false);
    const [fetchingUpdateGroup, setFetchingUpdateGroup] = useState(false);
    const [fetchingDeleteGroup, setFetchingDeleteGroup] = useState(false);
    const [fetchingActiveGroup, setFetchingActiveGroup] = useState(false);

    const getGroups = (groupFilters: GroupFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(groupFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.GROUPS.GET_GROUPS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetGroups
        });
    };

    const showGroup = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.GROUPS.SHOW_GROUP.replace(':id', id.toString()),
            setFetching: setFetchingShowGroup
        });
    };

    const storeGroup = (group: GroupDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.GROUPS.STORE_GROUP,
            body: group,
            setFetching: setFetchingStoreGroup
        });
    };

    const editGroup = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.GROUPS.EDIT_GROUP.replace(':id', id.toString()),
            setFetching: setFetchingEditGroup
        });
    };

    const createGroup = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.GROUPS.CREATE_GROUP,
            setFetching: setFetchingEditGroup
        });
    };

    const updateGroup = (id: number, group: GroupDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.GROUPS.UPDATE_GROUP.replace(':id', id.toString()),
            body: group,
            setFetching: setFetchingUpdateGroup
        });
    };

    const deleteGroup = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.GROUPS.DELETE_GROUP.replace(':id', id.toString()),
            setFetching: setFetchingDeleteGroup
        });
    };

    const activeGroup = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.GROUPS.ACTIVE_GROUP.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveGroup
        });
    };

    return {
        fetchingGetGroups,
        fetchingShowGroup,
        fetchingStoreGroup,
        fetchingEditGroup,
        fetchingUpdateGroup,
        fetchingDeleteGroup,
        fetchingActiveGroup,
        fetchingCreateGroup,
        getGroups,
        showGroup,
        storeGroup,
        createGroup,
        editGroup,
        updateGroup,
        deleteGroup,
        activeGroup
    };
};

export default useGroupService;
