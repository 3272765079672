import React from 'react';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { TransferReason } from '../../../app/models/TransferReason';
import TransferReasonForm from './forms/TransferReasonForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    transferReason: TransferReason;
    setTransferReason: (transferReason: TransferReason) => void;
    errorFields?: any;
}

const TransferReasonFormContainer = ({
    fetching,
    action,
    cancel,
    transferReason,
    setTransferReason,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <TransferReasonForm
                        transferReasonDataForm={transferReason}
                        setTransferReasonDataForm={setTransferReason}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default TransferReasonFormContainer;
