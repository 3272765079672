import React from 'react';
import { toast } from 'react-toastify';

type Props = {
    callbackDelete: () => void;
    title?: string;
    loading?: boolean;
    locked?: boolean;
    message?: string;
};

const ButtonDeleteForm = ({
    callbackDelete,
    title = 'Borrar',
    loading = false,
    locked = false,
    message = 'No se puede borrar este registro.'
}: Props) => {
    const showLockedMessage = () => {
        toast.error(message);
    };

    return !loading ? (
        <button
            type="button"
            className="waves-effect btn btn-primary btn-sm"
            onClick={locked ? showLockedMessage : callbackDelete}
        >
            <i className="fa fa-trash" /> {title}
        </button>
    ) : (
        <button type="button" className="waves-effect btn btn-primary btn-sm disabled">
            <i className="fa fa-trash" /> {title}
        </button>
    );
};

export default ButtonDeleteForm;
