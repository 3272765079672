import React, { useContext, useEffect, useState } from 'react';
import { EChart } from '@kbox-labs/react-echarts';
import TransportOrderFilter from '../WorkOrders/components/tables/TransportOrderFilter';
import { TransportOrderFilters } from '../../app/models/TransportOrder';
import TransportOrderChartFilter from './components/TransportationOrderChartFilter';
import useTransportOrderService from '../../app/services/hooks/useTransportOrderService';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { formatMoney } from '../../utils/utils';
import { AuthContext } from '../../contexts/AuthContext';
import moment from 'moment/moment';
const Dashboard = () => {
    const [filter, setFilter] = useState<TransportOrderFilters>({
        page: 1,
        per_page: 10,
        sort: 'program_date',
        order: 'desc',
        program_date_from: moment().subtract(6, 'months').format('YYYY-MM-DD'),
        program_date_until: moment().format('YYYY-MM-DD')
    });

    const [transportOrdersChart, setTransportOrdersChart] = useState<any>();
    const { getTransportOrdersByCarrierChart, fetchingGetTransportOrdersByCarrierChart } =
        useTransportOrderService();
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        if (auth?.roles && auth?.roles.includes('Conductor')) {
            getTransportOrdersByCarrierChart(filter, {
                onSuccess: (response: ServiceResponse) => {
                    setTransportOrdersChart(response.data.transport_orders_chart);
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message);
                }
            });
        }
    }, [filter]);

    return (
        <div className="row">
            {/*<iframe title='El Origen - Duplicado de Página 2' width='1024' height='804'*/}
            {/*src='https://app.powerbi.com/view?r=eyJrIjoiZDNmNjIwNDEtMDFkZi00Nzg1LTk0MzItZjdmZTIzZDVmZmY0IiwidCI6IjM1ZjU5YTcwLTZlMzctNGUwOS1iMTJhLTg4YTU4NDQ1ZGVhZSJ9'*/}
            {/*frameBorder='0' />*/}
            {transportOrdersChart && auth?.roles && auth?.roles.includes('Conductor') && (
                <>
                    <div className="col">
                        <TransportOrderChartFilter filter={filter} setFilter={setFilter} />
                    </div>
                    <div className="col-12">
                        <EChart
                            renderer={'svg'}
                            onClick={() => console.log('clicked!')}
                            style={{
                                height: '600px',
                                width: '100%'
                            }}
                            dataZoom={[
                                {
                                    type: 'inside'
                                }
                            ]}
                            tooltip={{
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                },
                                formatter: function (params: any) {
                                    const param = params[0];
                                    return `${param.name}<br/>Comisión: ${formatMoney(
                                        param.value
                                    )}`;
                                }
                            }}
                            xAxis={{
                                type: 'category',
                                data: transportOrdersChart.dates,
                                axisLabel: {
                                    rotate: 60, // Rota las etiquetas para que sean más legibles
                                    interval: 0 // Muestra todas las etiquetas, ajustar según necesidad
                                }
                            }}
                            yAxis={{
                                type: 'value',
                                boundaryGap: [0, '10%'],
                                axisLabel: {
                                    formatter: function (value: any) {
                                        const screenWidth = window.innerWidth;
                                        if (screenWidth < 768) {
                                            // Tamaño arbitrario, ajustar según sea necesario
                                            return ''; // Oculta las etiquetas en pantallas pequeñas
                                        }
                                        return formatMoney(value);
                                    }
                                }
                            }}
                            series={[
                                {
                                    type: 'bar',
                                    data: transportOrdersChart.commissions,
                                    label: {
                                        show: true, // Esto activa la visualización de las etiquetas
                                        position: 'top', // Coloca las etiquetas en la parte superior de las barras
                                        formatter: function (params: any) {
                                            return formatMoney(params.value);
                                        }
                                    }
                                }
                            ]}
                            title={
                                {
                                    text: 'Comisiones por fecha',
                                    subtext: 'Comisiones generadas por las órdenes de transporte',
                                    left: 'center'
                                } as any
                            }
                        />
                    </div>
                </>
            )}

            {auth?.roles && !auth?.roles.includes('Conductor') && (
                <iframe
                    title="Operaciones Pegasus"
                    width="1920"
                    height="1080"
                    src="https://app.powerbi.com/reportEmbed?reportId=652f1aa4-a5a1-4817-b37b-d72663db2e4b&autoAuth=true&ctid=b7039a2a-61e2-42d1-b606-516f36697736"
                    frameBorder="0"
                ></iframe>
            )}
        </div>
    );
};
export default Dashboard;
