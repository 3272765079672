import React from 'react';
import { TransportationOrderFilters } from '../../../app/models/TransportationOrder';
import ButtonSearchFilter from '../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: TransportationOrderFilters;
    setFilter: (filter: TransportationOrderFilters) => void;
    onSearch: () => void;
};

const TransportationOrderFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            {/* primera fila */}
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="transportation_order_id">
                            Pedido
                        </label>
                        <input
                            type="number"
                            id="transportation_order_id"
                            name="transportation_order_id"
                            className="form-control form-control-sm"
                            value={filter.transportation_order_id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="quote_id">
                            Oferta
                        </label>
                        <input
                            type="number"
                            id="quote_id"
                            name="quote_id"
                            className="form-control form-control-sm"
                            value={filter.quote_id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="client">
                            Cliente
                        </label>
                        <input
                            type="text"
                            id="client"
                            name="client"
                            className="form-control form-control-sm"
                            value={filter.client}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="type">
                            Estatuses
                        </label>
                        <select
                            id="transportation_order_status"
                            name="transportation_order_status"
                            className="form-control form-select-sm"
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option value="TODOS">TODOS</option>
                            <option value="PREPARACION">Preparacion</option>
                            <option value="DESPACHO">Entregando</option>
                            <option value="FINALIZADO">Finalizado</option>
                        </select>
                    </div>
                </div>

                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="start_date">
                            Desde
                        </label>
                        <input
                            type="date"
                            id="start_date"
                            name="start_date"
                            className="form-control form-control-sm"
                            value={filter.start_date}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="end_date">
                            Hasta
                        </label>
                        <input
                            type="date"
                            id="end_date"
                            name="end_date"
                            className="form-control form-control-sm"
                            value={filter.end_date}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransportationOrderFilter;
