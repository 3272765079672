import { Afp } from './Afp';
import { Bank } from './Bank';
import { BankAccount, BankAccountReason, BankAccountType } from './BankAccountType';
import { Provision } from './Provision';
import { User } from './User';
import { Shift } from './Shift';
import { Provider } from './Provider';
import { Society } from './Society';
import { JobTitle } from './JobTitle';
import { DocumentSet } from './DocumentSet';
import { Contract } from './Contract';

export interface Worker {
    id?: number;
    user_id?: number;
    user?: User;
    user_name?: string;
    address?: string;
    society_id?: number;
    society?: Society;
    provider_id?: number;
    provider?: Provider;
    provision?: Provision;
    is_internal?: boolean;
    job_title?: JobTitle;
    job_title_id?: number;
    work_shift?: string;
    shift_work_id?: number;
    shift_work?: Shift;
    afp_id?: number;
    afp?: Afp;
    provision_id?: number;
    prevision?: Provision;
    bank_id?: number;
    bank?: Bank;
    account_type_id?: number;
    bank_account_type?: BankAccountType;
    account_number?: string;
    contract?: Contract;
    is_active: boolean;
    bank_accounts?: BankAccount[];
}

export interface WorkerFilters {
    workers_id?: number | null;
    rut?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    job_title?: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultWorkeDataForm: Worker = {
    user_id: 0,
    is_active: true,
    address: '',
    society_id: 0,
    provider_id: undefined,
    is_internal: true,
    account_number: ''
};

export interface WorkerDataForm {
    id?: number;
    user_id?: number;
    user?: User;
    is_active: boolean;
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    phone_code?: string;
    second_last_name?: string;
    address?: string;
    job_title?: JobTitle;
    work_shift?: string;
    shift_work_id?: number;
    afp_id?: number;
    afp?: Afp;
    provision_id?: number;
    prevision?: Provision;
    bank_id?: number;
    bank?: Bank;
    shift_id?: number;
    shifts?: Shift[];
    document_sets?: DocumentSet[];
    bank_accounts?: BankAccount[];
    account_type_id?: number;
    bank_account_type?: BankAccountType;
    account_reason_id?: number;
    bank_account_reason?: BankAccountReason;
    account_number?: string;
    is_internal?: boolean;
    provider_id?: number;
    provider?: Provider;
    society_id?: number;
    society?: Society;
    job_title_id?: number;
    start_date?: string;
    contract?: Contract;
    end_date?: string;
    provision_value?: number;
    FUN_document?: any;
}

export interface WorkerDocument {
    id?: number;
    name?: string;
    file?: File;
    issue_date?: string;
    expiration_date?: string;
    document_name?: string;
    type?: string;
    is_expire_date_indefinite: boolean;
    document_id?: number;
    worker_id?: number;
}
