import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { MassiveApproveForm, TollDataForm, TollFilters } from '../../models/Toll';
import { getFetchDefaultHeaders } from '../shared/vars';

const useTollService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetTolls, setFetchingGetTolls] = useState(false);
    const [fetchingShowToll, setFetchingShowToll] = useState(false);
    const [fetchingCreateToll, setFetchingCreateToll] = useState(false);
    const [fetchingStoreToll, setFetchingStoreToll] = useState(false);
    const [fetchingEditToll, setFetchingEditToll] = useState(false);
    const [fetchingUpdateToll, setFetchingUpdateToll] = useState(false);
    const [fetchingDeleteToll, setFetchingDeleteToll] = useState(false);
    const [fetchingActiveToll, setFetchingActiveToll] = useState(false);

    const getTolls = (filter: TollFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.TOLLS.GET_TOLLS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetTolls
        });
    };

    const showToll = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TOLLS.GET_TOLL.replace(':id', id.toString()),
            setFetching: setFetchingShowToll
        });
    };

    const createToll = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TOLLS.CREATE_TOLL,
            setFetching: setFetchingCreateToll
        });
    };

    const storeToll = (Toll: TollDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TOLLS.STORE_TOLL,
            body: Toll,
            requestType: 'multipart',
            setFetching: setFetchingStoreToll
        });
    };

    const editToll = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TOLLS.EDIT_TOLL.replace(':id', id.toString()),
            setFetching: setFetchingEditToll
        });
    };

    const updateToll = (id: number, Toll: TollDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.TOLLS.UPDATE_TOLL.replace(':id', id.toString()),
            body: Toll,
            requestType: 'multipart',
            setFetching: setFetchingUpdateToll
        });
    };

    const deleteToll = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.TOLLS.DELETE_TOLL.replace(':id', id.toString()),
            setFetching: setFetchingDeleteToll
        });
    };

    return {
        fetchingGetTolls,
        fetchingShowToll,
        fetchingCreateToll,
        fetchingStoreToll,
        fetchingEditToll,
        fetchingUpdateToll,
        fetchingDeleteToll,
        fetchingActiveToll,
        getTolls,
        showToll,
        createToll,
        storeToll,
        editToll,
        updateToll,
        deleteToll
    };
};

export default useTollService;
