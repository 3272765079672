import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
    selectedYear: Date | null;
    onChange: (year: Date | null) => void;
}

const YearPicker: React.FC<Props> = ({ selectedYear, onChange }) => {
    return (
        <DatePicker
            selected={selectedYear}
            onChange={onChange}
            dateFormat="yyyy"
            showYearPicker
            className="form-control form-control-sm"
        />
    );
};

export default YearPicker;
