import { useContext, useEffect, useState } from 'react';
import LazyLoading from '../../../components/LazyLoading';
import { DeductionsBasisValues, TypeOfDeductionsValues } from '../../../app/models/Deduction';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { Currency } from '../../../app/models/Currency';
import { useHistory, useParams } from 'react-router-dom';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import DeductionRateForm from '../components/forms/DeductionRateForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import { DeductionRateDataForm, defaultDeductionRateDataForm } from '../../../app/models/DeductionRate';
import useDeductionRateService from '../../../app/services/hooks/useDeductionRateService';
import { formatDateToInputMonth } from '../../../helpers';
import { Item } from '../../../app/models/Item';

interface Props {
    deductionRateId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DeductionRateEdit = ({ deductionRateId, onSaved, onCancel, onError }: Props) => {

    const history = useHistory();
    const { fetchingEditDeductionRate, editDeductionRate, fetchingUpdateDeductionRate, updateDeductionRate } = useDeductionRateService();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [errorFields, setErrorFields] = useState<any>();
    const [deductionRate, setDeductionRate] = useState<DeductionRateDataForm>(defaultDeductionRateDataForm);
    const [deductionTypes, setDeductionTypes] = useState<TypeOfDeductionsValues[]>([]);
    const [deductionBasis, setDeductionBasis] = useState<DeductionsBasisValues[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [items, setItems] = useState<Item[]>([])
    const [formulaItems, setFormulaItems] = useState<string[]>([])

    useEffect(() => {
        edit(deductionRateId);
    }, [deductionRateId]);

    useEffect(() => {
        if(deductionRate.deduction_type === 'Calculado' && deductionRate.formula){
            const formula = deductionRate.formula;
            const items = formula.match(/\{.*?\}/g);
            if (items) setFormulaItems(items)
        }
    }, [deductionRate]);

    const edit = (deductionRateId: number) => {
        if (showLoading) showLoading('loading', 'Cargando deducción...');
        editDeductionRate(deductionRateId, {
            onSuccess: (response: ServiceResponse) => {
                setDeductionRate({
                    ...response.data.deduction_rate,
                    valid_from: formatDateToInputMonth(response.data.deduction_rate.valid_from),
                    valid_until: formatDateToInputMonth(response.data.deduction_rate.valid_until)
                });
                setDeductionTypes(response.data.type_of_deductions);
                setDeductionBasis(response.data.deduction_basis);
                setCurrencies(response.data.currencies);
                setItems(response.data.item_list);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando deducción...');
        updateDeductionRate(deductionRateId, deductionRate, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if(onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };


    return fetchingEditDeductionRate ? (
        <LazyLoading height="300" />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <DeductionRateForm 
                        deductionRateForm={deductionRate} 
                        setDeductionRateForm={setDeductionRate}
                        TypeOfDeductions={deductionTypes}
                        DeductionBasis={deductionBasis}
                        currencies={currencies}
                        items={items}
                        formulaItems={formulaItems}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingUpdateDeductionRate} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateDeductionRate} />
                </div>
            </div>
        </>
    );
}

export default DeductionRateEdit;