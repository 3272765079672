import React, { useEffect, useState } from 'react';
import { TravelExpenseDataForm } from '../../../../app/models/TravelExpense';
import { EventType } from '../../../../app/models/EventType';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Currency } from '../../../../app/models/Currency';
import { formatMoney } from '../../../../utils/utils';
import CurrencyInput from '../../../../components/inputs/CurrencyInput';

interface Props {
    travelExpenseDataForm: TravelExpenseDataForm;
    setTravelExpenseDataForm: (travelExpenseForm: TravelExpenseDataForm) => void;
    eventTypes: EventType[];
    currencies: Currency[];
    errorFields?: any;
    loading?: boolean;
    updated?: boolean;
}

const TravelExpenseForm = ({
    travelExpenseDataForm,
    setTravelExpenseDataForm,
    currencies,
    eventTypes,
    errorFields,
    loading = false,
    updated = false
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [suggestedAmount, setSuggestedAmount] = useState<number>(0);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setTravelExpenseDataForm({ ...travelExpenseDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };

        if (name === 'currency_id') {
            setTravelExpenseDataForm({ ...travelExpenseDataForm, ...obj });
        }
    };

    if (!travelExpenseDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-md-6 col-form-label">
                        Monto{' '}
                        {suggestedAmount > 0 && (
                            <span className="text-primary">
                                (sugerido: {formatMoney(suggestedAmount)})
                            </span>
                        )}
                    </label>
                    <CurrencyInput
                        name={'budget'}
                        currency={travelExpenseDataForm.currency_id}
                        currencies={currencies}
                        onChange={handleChange}
                        value={travelExpenseDataForm.budget}
                        className={`form-control ${fieldHasError(`budget`)}`}
                        onFocus={() => onFocusRemove(`budget`)}
                        placeholder="Ingrese el monto"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('budget')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-md-6 col-form-label">Moneda</label>
                    <SelectTwo
                        name="currency_id"
                        id="currency_id"
                        inputValue={travelExpenseDataForm.currency_id}
                        options={SelectTwoMapperOptions(currencies)}
                        hasError={fieldHasError('currency_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('currency_id', value?.value)
                        }
                        placeholder={'Seleccione moneda'}
                        onFocus={() => onFocusRemove('currency_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('currency_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-md-6 col-form-label">Fecha de inicio</label>
                    <div className="col-md-12">
                        <input
                            type="date"
                            className={`form-control ${fieldHasError('start_date')}`}
                            name="start_date"
                            id="start_date"
                            value={travelExpenseDataForm.start_date?.toString()}
                            onChange={handleChange}
                            placeholder="Ingrese una fecha"
                            onFocus={() => onFocusRemove('date')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('start_date')}
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="col-md-9 col-form-label">Fecha de término</label>
                    <div className="col-md-12">
                        <input
                            type="date"
                            className={`form-control ${fieldHasError('end_date')}`}
                            name="end_date"
                            id="end_date"
                            value={travelExpenseDataForm.end_date?.toString()}
                            onChange={handleChange}
                            placeholder="Ingrese una fecha"
                            onFocus={() => onFocusRemove('end_date')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('end_date')}
                        </div>
                    </div>
                </div>

                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('non_field_errors')}
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tipo de eventos</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="event_types"
                        id="event_types"
                        inputValue={travelExpenseDataForm.event_types}
                        options={SelectTwoMapperOptions(eventTypes)}
                        hasError={fieldHasError('event_types') !== ''}
                        onChange={(value: any) =>
                            setTravelExpenseDataForm({
                                ...travelExpenseDataForm,
                                event_types: value
                            })
                        }
                        isSortable={true}
                        isMulti={true}
                        placeholder={'Seleccione el tipo de evento(s)'}
                        onFocus={() => onFocusRemove('event_types')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('event_types')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TravelExpenseForm;
