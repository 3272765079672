import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Place, PlaceFilters } from '../../models/Place';

const usePlaceService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetPlaces, setFetchingGetPlaces] = useState(false);
    const [fetchingShowPlace, setFetchingShowPlace] = useState(false);
    const [fetchingCreatePlace, setFetchingCreatePlace] = useState(false);
    const [fetchingStorePlace, setFetchingStorePlace] = useState(false);
    const [fetchingEditPlace, setFetchingEditPlace] = useState(false);
    const [fetchingUpdatePlace, setFetchingUpdatePlace] = useState(false);
    const [fetchingDeletePlace, setFetchingDeletePlace] = useState(false);
    const [fetchingActivePlace, setFetchingActivePlace] = useState(false);

    const getPlaces = (placeFilter: PlaceFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(placeFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.PLACES.GET_PLACES}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetPlaces
        });
    };

    const showPlace = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PLACES.GET_PLACE.replace(':id', id.toString()),
            setFetching: setFetchingShowPlace
        });
    };

    const createPlace = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PLACES.CREATE_PLACE,
            setFetching: setFetchingCreatePlace
        });
    };

    const storePlace = (place: Place, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PLACES.STORE_PLACE,
            body: place,
            setFetching: setFetchingStorePlace
        });
    };

    const editPlace = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PLACES.EDIT_PLACE.replace(':id', id.toString()),
            setFetching: setFetchingEditPlace
        });
    };

    const updatePlace = (id: number, place: Place, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PLACES.UPDATE_PLACE.replace(':id', id.toString()),
            body: place,
            setFetching: setFetchingUpdatePlace
        });
    };

    const deletePlace = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.PLACES.DELETE_PLACE.replace(':id', id.toString()),
            setFetching: setFetchingDeletePlace
        });
    };

    const activePlace = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PLACES.ACTIVE_PLACE.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActivePlace
        });
    };

    return {
        fetchingGetPlaces,
        fetchingShowPlace,
        fetchingCreatePlace,
        fetchingStorePlace,
        fetchingEditPlace,
        fetchingUpdatePlace,
        fetchingDeletePlace,
        fetchingActivePlace,
        getPlaces,
        showPlace,
        createPlace,
        storePlace,
        editPlace,
        updatePlace,
        deletePlace,
        activePlace
    };
};

export default usePlaceService;
