import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';

import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { BrandDataForm, BrandFilters } from '../../models/Brand';

const useBrandService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBrands, setFetchingGetBrands] = useState(false);
    const [fetchingShowBrand, setFetchingShowBrand] = useState(false);
    const [fetchingCreateBrand, setFetchingCreateBrand] = useState(false);
    const [fetchingStoreBrand, setFetchingStoreBrand] = useState(false);
    const [fetchingEditBrand, setFetchingEditBrand] = useState(false);
    const [fetchingUpdateBrand, setFetchingUpdateBrand] = useState(false);
    const [fetchingDeleteBrand, setFetchingDeleteBrand] = useState(false);
    const [fetchingActiveBrand, setFetchingActiveBrand] = useState(false);

    const getBrands = (brandFilter: BrandFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(brandFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BRANDS.GET_BRANDS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetBrands
        });
    };

    const createBrand = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BRANDS.CREATE_BRAND,
            setFetching: setFetchingCreateBrand
        });
    };

    const showBrand = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BRANDS.SHOW_BRAND.replace(':id', id.toString()),
            setFetching: setFetchingShowBrand
        });
    };

    const storeBrand = (brand: BrandDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BRANDS.STORE_BRAND,
            body: brand,
            setFetching: setFetchingStoreBrand
        });
    };

    const editBrand = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BRANDS.EDIT_BRAND.replace(':id', id.toString()),
            setFetching: setFetchingEditBrand
        });
    };

    const updateBrand = (id: number, brand: BrandDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.BRANDS.UPDATE_BRAND.replace(':id', id.toString()),
            body: brand,
            setFetching: setFetchingUpdateBrand
        });
    };

    const deleteBrand = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.BRANDS.DELETE_BRAND.replace(':id', id.toString()),
            setFetching: setFetchingDeleteBrand
        });
    };

    const activeBrand = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BRANDS.ACTIVE_BRAND.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveBrand
        });
    };

    return {
        fetchingGetBrands,
        fetchingCreateBrand,
        fetchingShowBrand,
        fetchingStoreBrand,
        fetchingEditBrand,
        fetchingUpdateBrand,
        fetchingDeleteBrand,
        fetchingActiveBrand,
        getBrands,
        createBrand,
        showBrand,
        storeBrand,
        editBrand,
        updateBrand,
        deleteBrand,
        activeBrand
    };
};

export default useBrandService;
