import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Worker, WorkerFilters } from '../../app/models/Workers';
import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useWorkerService from '../../app/services/hooks/useWorkerService';
import DefaultModal from '../../components/default/DefaultModal';
import WorkersCreate from './components/forms/WorkersCreate';
import { AppContext } from '../../contexts/AppContext';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import WorkersDataTable from './components/tables/WorkersDataTable';
import { toast } from 'react-toastify';
import useNavigationPage from '../../hooks/useNavigationPage';

interface Props {
    is_carrier?: boolean;
}

const Workers = ({ is_carrier = false }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const { fetchingGetWorkers, getWorkers, deleteWorker, activeWorker } = useWorkerService();
    const [pageSectionText, setPageSectionText] = useState<string>('Trabajador');
    const [url, setUrl] = useState<string>('workers');
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: `${pageSectionText}es`,
            url: `/${url}`,
            isActive: true
        }
    ];

    const initialFilterState = useCallback((): WorkerFilters => {
        const baseFilter: WorkerFilters = {
            page: 1,
            per_page: 10,
            sort: 'id',
            order: 'desc',
            rut: '',
            first_name: ''
        };

        if (is_carrier) {
            setPageSectionText('Conductor');
            setUrl('carriers');
            return {
                ...baseFilter,
                job_title: 'conductor'
            };
        }
        return baseFilter;
    }, [is_carrier]);

    const [filter, setFilter] = useState<WorkerFilters>(initialFilterState);

    useEffect(() => {
        reloadTable();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);

    const reloadTable = () => {
        getWorkers(filter, {
            onSuccess: (response: ServiceResponse) => {
                setWorkers(response.data.workers);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (WorkerId: number) => {
        navigationPage(
            `/workers/${WorkerId}/edit?isCarrier=${is_carrier}&defaultActiveKey=general-data`
        );
    };

    const showShow = (WorkerId: number) => {
        navigationPage(`/workers/${WorkerId}/edit?defaultActiveKey=calendar`);
    };

    const showRequest = (WorkerId: number) => {
        navigationPage(`/workers/${WorkerId}/edit?defaultActiveKey=calendar-requests`);
    };

    const active = (carrierId: number, active: boolean) => {
        activeWorker(carrierId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const destroy = (workerId: number) => {
        const _text = 'Está a punto de eliminar el trabajador #' + workerId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando trabajador...');
                        deleteWorker(workerId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El trabajador no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection={`${pageSectionText}es`} breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <ButtonCreate
                                    callbackCreate={showCreate}
                                    title={`Nuevo ${pageSectionText}`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <WorkersDataTable
                            active={active}
                            workers={workers}
                            totalRows={totalRows}
                            loading={fetchingGetWorkers}
                            destroy={destroy}
                            edit={showEdit}
                            showRequests={showRequest}
                            show={showShow}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                            onSearch={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title={`Crear ${pageSectionText}`}
                    backdrop={true}
                    showFooter={false}
                >
                    <WorkersCreate
                        isCarrier={is_carrier}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Workers;
