import React from 'react';
import ButtonSearchFilter from '../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: any;
    setFilter: (filter: any) => void;
    onSearch: () => void;
};

const PlacesFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="col-md-auto">
                <div className="mb-3">
                    <label className="font-size-10 mb-1" htmlFor="name">
                        Nombre
                    </label>
                    <input
                        title="Nombre"
                        type="text"
                        id="name"
                        name="name"
                        className="form-control form-control-sm"
                        value={filter.name}
                        onChange={(e) => handlerInputFilter(e)}
                    />
                </div>
            </div>
            <div className='col-md-auto'>
                <div style={{marginTop: '22px'}}>
                    <ButtonSearchFilter callbackSearch={onSearch}/>
                </div>
            </div>
        </div>
    );
};

export default PlacesFilter;
