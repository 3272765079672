import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import { CalendarRequest, CalendarRequestfilter } from '../../../../app/models/CalendarRequest';
import CalendarRequestFilter from './CalendarRequestFilter';
import CalendarRequestSwitchChange from '../CalendarRequestSwitchChange';
import circleRed from '../../../../assets/images/icons/circle-color-red.svg';
import circleGreen from '../../../../assets/images/icons/circle-color-green.svg';
import circleGrey from '../../../../assets/images/icons/circle-color-grey.svg';
import { useContext } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';

interface Props {
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    download?: (id: number) => void;
    show?: (id: number) => void;
    approved?: (id: number, value: boolean, message: string) => void;
    destroy?: (id: number) => void;
    worker?: boolean;
    calendarRequests: CalendarRequest[];
    totalRows: number;
    loading?: boolean;
    filter: CalendarRequestfilter;
    setFilter: (filter: CalendarRequestfilter) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const CalendarRequestDataTable = ({
    calendarRequests,
    totalRows,
    loading = false,
    edit,
    download,
    approved,
    destroy,
    worker = false,
    show,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
        const { auth } = useContext(AuthContext);

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: CalendarRequest, index: any, column: any) => (
                <span className="">
                    <img
                        src={row.state == null ? circleGrey : !row.state ? circleRed : circleGreen}
                        alt="circle"
                        style={{
                            width: '25px',
                            height: '25px',
                            marginRight: '5px',
                            marginBottom: '3px'
                        }}
                    />
                    <span className="">{row.id}</span>
                </span>
            )
        },
        {
            name: 'Trabajador',
            selector: (row: any) => row.worker.user.first_name,
            sortable: true,
            omit: worker,
            sortField: 'event_type',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {row.user.first_name} {row.user.last_name}
                </span>
            )
        },
        {
            name: 'Tipo de solicitud',
            selector: (row: any) => row.event_type.name,
            sortable: true,
            sortField: 'event_type',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.event_type.name}</span>
            )
        },
        {
            name: 'Fecha de inicio / Fecha de termino',
            selector: (row: any) => row.dates,
            sortable: true,
            sortField: 'start_date',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {row.start_date}/{row.end_date}
                </span>
            )
        },
        // {
        //     name: 'Días hábiles',
        //     selector: (row: any) => row.dates,
        //     sortable: false,
        //     sortField: 'workdays',
        //     cell: (row: any, index: any, column: any) => (
        //         <span className="">
        //             {row.workdays}
        //         </span>
        //     )
        // },
        // {
        //     name: 'Documento',
        //     selector: (row: any) => row.document,
        //     sortable: false,
        //     sortField: 'document',
        //     cell: (row: any, index: any, column: any) => <span className="">si</span>
        // },
        {
            name: 'Descripción',
            selector: (row: any) => row.description,
            sortable: true,
            sortField: 'description',
            cell: (row: any, index: any, column: any) => <span className="">{row.description}</span>
        },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: any, index: any, column: any) => (
                <CalendarRequestSwitchChange
                    checkValue={row.is_active ?? true}
                    CalendarRequestId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: CalendarRequest, index: any, column: any) => (
                <div className="">
                    {download && (
                        <ButtonTableAction
                            callbackFunction={() => download(row?.id ?? -1)}
                            classIcon={'mdi mdi-download'}
                            colorIcon={'text-success'}
                            errorMessage={'No se puede descargar este documento.'}
                            title={'Descargar documento'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {/* {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )} */}
                    {(approved && row.state === null &&                         auth &&
                        auth?.roles && !auth?.roles.includes('Conductor') ) && (
                        <>
                            <ButtonTableAction
                                callbackFunction={() => approved(row?.id ?? -1, true, 'aprobado')}
                                classIcon={'mdi mdi-checkbox-marked-circle'}
                                colorIcon={'text-info'}
                                errorMessage={'No se puede aprobar este registro.'}
                                title={'Aprobar'}
                            />
                            {row.state === null && (
                                <ButtonTableAction
                                    callbackFunction={() =>
                                        approved(row?.id ?? -1, false, 'rechazado')
                                    }
                                    classIcon={'mdi mdi-close-circle'}
                                    colorIcon={'text-danger'}
                                    errorMessage={'No se puede rechazar este registro.'}
                                    title={'Rechazar'}
                                />
                            )}
                        </>
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <CalendarRequestFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>
            <DefaultDataTable
                columns={columns}
                data={calendarRequests}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default CalendarRequestDataTable;
