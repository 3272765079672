import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useDispatchProgramService from '../../app/services/hooks/useDispatchProgramService';
import {
    DispatchProgram,
    DispatchProgramFilters,
    DispatchProgramStatus
} from '../../app/models/DispatchProgram';
import moment from 'moment';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import DispatchProgramFilter from './components/tables/DispatchProgramFilter';
import useSweetAlert from '../../hooks/useSweetAlert';
import DefaultModal from '../../components/default/DefaultModal';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import DispatchProgramCreate from './components/DispatchProgramCreate';
import { AuthContext } from '../../contexts/AuthContext';
import DispatchProgramDataTable from './components/tables/DispatchProgramDataTable';
import { Roles } from '../../app/shared/enums';
import useNavigationPage from '../../hooks/useNavigationPage';
import { AppContext } from '../../contexts/AppContext';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Despachos',
        url: '/dispatch-programs',
        isActive: true
    }
];

const DispatchPrograms = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { navigationPage } = useNavigationPage();
    const [totalRows, setTotalRows] = useState<number>(0);

    const {
        fetchingGetDispatchPrograms,
        fetchingDeleteDispatchProgram,
        getDispatchPrograms,
        deleteDispatchProgram,
        getExcelAllDispatchPrograms
    } = useDispatchProgramService();

    const [dispatchPrograms, setDispatchPrograms] = useState<DispatchProgram[]>([]);
    const [statuses, setStatuses] = useState<DispatchProgramStatus[]>([]);
    const [showingCreate, setShowingCreate] = useState(false);

    const [filter, setFilter] = useState<DispatchProgramFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, []);

    const reloadTable = () => {
        getDispatchPrograms(filter, {
            onSuccess: (response: ServiceResponse) => {
                setDispatchPrograms(response.data.dispatch_programs);
                setStatuses(response.data.dispatch_program_statuses);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2500
                });
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };
    const downloadExcel = () => {
        getExcelAllDispatchPrograms(filter);
    };
    const edit = (id?: number) => {
        navigationPage('/dispatch-programs/' + id + '/edit');
    };

    const show = (id?: number) => {
        navigationPage('/dispatch-programs/' + id + '/show');
    };

    const destroy = (dispatchProgramId: number) => {
        const _text = 'Está a punto de eliminar el despacho #' + dispatchProgramId;
        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                deleteDispatchProgram(dispatchProgramId, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: 'Se ha eliminado el despacho #' + dispatchProgramId
                        });
                        reloadTable();
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: response.message
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El despacho no se ha eliminado.'
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Despachos" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col" style={{ paddingTop: 23 }}>
                                <ButtonCreate callbackCreate={showCreate} title="Nuevo despacho" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <DispatchProgramDataTable
                            dispatchPrograms={dispatchPrograms}
                            callbackSuccess={reloadTable}
                            loading={fetchingGetDispatchPrograms}
                            destroy={destroy}
                            edit={edit}
                            show={show}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                            downloadExcel={downloadExcel}
                            onSearch={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear despacho"
                    backdrop={true}
                    showFooter={false}
                >
                    <DispatchProgramCreate
                        // onSaved={() => {
                        //     reloadTable();
                        //     hideCreate();
                        // }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default DispatchPrograms;
