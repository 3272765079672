import React, { useContext } from 'react';
import { GuideFilters } from '../../../../app/models/Guide';
import { AuthContext } from '../../../../contexts/AuthContext';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: GuideFilters;
    showTransportOrder: boolean;
    setFilter: (filter: GuideFilters) => void;
    onSearch: () => void;
};

const GuideFilter = ({ filter, setFilter, showTransportOrder, onSearch }: Props) => {
    const { auth } = useContext(AuthContext);
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                {showTransportOrder && (
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="transport_order_id">
                                Orden de transporte
                            </label>
                            <input
                                title="Orden de transporte"
                                type="text"
                                id="transport_order_id"
                                name="transport_order_id"
                                className="form-control form-control-sm"
                                value={filter.transport_order_id ?? ''}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                )}

                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="society">
                            Sociedad
                        </label>
                        <input
                            title="Sociedad"
                            type="text"
                            id="society"
                            name="society"
                            className="form-control form-control-sm"
                            value={filter.society}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="client">
                            Cliente
                        </label>
                        <input
                            title="Cliente"
                            type="text"
                            id="client"
                            name="client"
                            className="form-control form-control-sm"
                            value={filter.client}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="transporter">
                            Transportista
                        </label>
                        <input
                            title="transportista"
                            type="text"
                            id="transporter"
                            name="transporter"
                            className="form-control form-control-sm"
                            value={filter.transporter}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                {/* <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="issue_date">
                            Fecha de emisión
                        </label>
                        <input
                            title="Fecha de emisión"
                            type="date"
                            id="issue_date"
                            name="issue_date"
                            className="form-control form-control-sm"
                            value={filter.issue_date}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div> */}
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="folio">
                            folio
                        </label>
                        <input
                            title="Folio"
                            type="text"
                            id="folio"
                            name="folio"
                            className="form-control form-control-sm"
                            value={filter.folio}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                {(auth?.hasAllPermissions || auth?.roles?.includes('Administrador')) && (
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="carrier">
                                Conductor
                            </label>
                            <input
                                title="Conductor"
                                type="text"
                                id="carrier"
                                name="carrier"
                                className="form-control form-control-sm"
                                value={filter.carrier}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                )}
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="truck">
                            Tracto
                        </label>
                        <input
                            title="Tracto"
                            type="text"
                            id="truck"
                            name="truck"
                            className="form-control form-control-sm"
                            value={filter.truck}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuideFilter;
