import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultToolBar from '../../components/default/DefaultToolBar';

import { useHistory } from 'react-router-dom';

import { AppContext } from '../../contexts/AppContext';

import { ExpenseDataForm, ExpenseFilters } from '../../app/models/Expense';
import useExpenseService from '../../app/services/hooks/useExpenseService';
import ExpenseDataTable from '../WorkOrders/components/tables/ExpenseDataTable';

import { toast } from 'react-toastify';
import moment from 'moment';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Gastos',
        url: '/Expenses',
        isActive: true
    }
];

const Expense = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingGetExpenses,
        getExpenses,
        getUrlDocumentExpense,
        downloadDocument,
        fetchingGetUrlDocument
    } = useExpenseService();
    const [expenses, setExpenses] = useState<ExpenseDataForm[]>([]);

    const [totalRows, setTotalRows] = useState<number>(0);

    const [expenseIdEdit, setExpenseIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const { navigationPage } = useNavigationPage();

    const [filter, setFilter] = useState<ExpenseFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        issue_date_first: moment().date(1).format('YYYY-MM-DD'),
        issue_date_last: moment().format('YYYY-MM-DD')
    });

    useEffect(() => {
        reloadTable();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);

    const getAllExpenses = () => {
        getExpenses(filter, {
            onSuccess: (response: ServiceResponse) => {
                setExpenses(response.data.expenses);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (expenseId: number) => {
        setShowingEdit(true);
        setExpenseIdEdit(expenseId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setExpenseIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllExpenses();
    };

    const document = (expenseId: number) => {
        if (showLoading) showLoading('download', 'descargando archivo...');
        getUrlDocumentExpense(expenseId, {
            onSuccess: (response: ServiceResponse) => {
                window.open(response.data.url_file);
                if (changeAnimation)
                    changeAnimation('downloadSuccess', 'archivo recuperado con exito', true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) {
                    hideLoading();
                }
                toast.error(response.message);
            }
        });
    };

    // const showExpense = (expenseId: number) => {
    //     navigationPage('/transport-orders/' + expenseId + '/edit');
    // };

    return (
        <>
            <Breadcrumbs pageSection="Gastos" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar />

                <ExpenseDataTable
                    expenses={expenses}
                    loading={fetchingGetExpenses}
                    totalRows={totalRows}
                    filter={filter}
                    setFilter={setFilter}
                    expensePage={true}
                    documentExpense={document}
                    onSearch={reloadTable}
                />
            </DefaultCard>
        </>
    );
};

export default Expense;
