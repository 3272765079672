import React from 'react';
import { LiquidationDataForm } from '../../../app/models/Liquidation';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import LiquidationForm from './forms/LiquidationForm';
import { Contract } from '../../../app/models/Contract';
import { Bonus } from '../../../app/models/Bonus';
import { Deduction } from '../../../app/models/Deduction';
import { JobTitle } from '../../../app/models/JobTitle';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    liquidation: LiquidationDataForm;
    setLiquidation: (liquidation: LiquidationDataForm) => void;
    errorFields?: any;
    contracts: Contract[];
    jobTitles: JobTitle[];
    is_edit?: boolean;
}

const LiquidationFormContainer = ({
    fetching,
    action,
    cancel,
    liquidation,
    setLiquidation,
    contracts,
    jobTitles,
    is_edit,
    errorFields = null,
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <LiquidationForm
                        liquidation={liquidation}
                        setLiquidation={setLiquidation}
                        contracts={contracts}
                        jobTitles={jobTitles}
                        is_edit={is_edit}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default LiquidationFormContainer;
