import { BankAccount } from './BankAccountType';
import { JobTitle } from './JobTitle';
import { Worker } from './Workers';

export interface Contract {
    id?: number;
    worker?: Worker | string;
    worker_id?: number;
    start_date?: Date | string;
    end_date?: Date | string;
    is_indefinite?: boolean;
    applies_gratification?: boolean;
    base_salary?: number;
    job_title?: JobTitle;
    status?: string;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface ContractDataForm {
    id?: number;
    worker?: Worker | string;
    worker_id?: number;
    start_date?: Date | string;
    end_date?: Date | string;
    is_indefinite?: boolean;
    base_salary?: number;
    status?: string;
    is_active?: boolean;
    applies_gratification?: boolean;
    job_title_id?: number;
    shift_work_id?: number;
    afp_id?: number;
    provision_id?: number;
    currency_id?: number;
    email?: string;
    bank_accounts?: BankAccount[];

    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface ContractFilters {
    id?: number | null;
    worker?: Worker | string;
    worker_id?: number;

    is_indefinite?: boolean;
    is_active?: boolean;

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultContract: ContractDataForm = {
    worker: '',
    applies_gratification: false
};
