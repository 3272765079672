import React, { useEffect, useState } from 'react';
import { SocietyDataForm } from '../../../../app/models/Society';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { countriesOptions } from '../../../../utils/utils';
import useRut from '../../../../hooks/useRut';
import { User } from '../../../../app/models/User';
import { DocumentSet } from '../../../../app/models/DocumentSet';

interface GeneralSocietyProps {
    societyForm: SocietyDataForm;
    setSocietyForm: (society: SocietyDataForm) => void;
    documentSets: DocumentSet[];
    users: User[];
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
    isEdit?: boolean;
}

const SocietyForm = ({
    societyForm,
    setSocietyForm,
    documentSets,
    users,
    isEdit,
    errorFields,
    setErrorFields
}: GeneralSocietyProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const [image, setImage] = useState(
        societyForm.logo
            ? societyForm.logo
            : 'https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg'
    );

    const documentSetsInputValue = societyForm.document_sets?.map((set: any) => {
        return {
            value: set.id,
            label: set.name
        };
    });

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value, type } = event.target;
        if (type === 'checkbox') {
            event.target = event.target as HTMLInputElement;
            setSocietyForm({ ...societyForm, [name]: event.target.checked });
        } else {
            if (name == 'rut') {
                setSocietyForm({ ...societyForm, [name]: formatRut(value) });
            } else {
                setSocietyForm({ ...societyForm, [name]: value });
            }
        }
    };

    const dataOnChangeImages = (e: any) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setSocietyForm({
            ...societyForm,
            [e.target.name]: e.target.files[0]
        });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if (name === 'user_id') {
            const user = users.find((user) => user.id === value);
            console.log(user);
            if (user) {
                obj['first_name_contact'] = user.first_name ?? '';
                obj['last_name_contact'] = user.last_name ?? '';
                obj['email_contact'] = user.email ?? '';
                obj['phone_contact'] = user.phone ?? '';
                obj['phone_code_contact'] = user.phone_code ?? '';
                obj['second_last_name_contact'] = user.second_last_name ?? '';
                if (setErrorFields)
                    setErrorFields({
                        ...errorFields,
                        first_name_contact: '',
                        last_name_contact: '',
                        email_contact: '',
                        phone_contact: '',
                        second_last_name_contact: ''
                    });
            }
        }
        setSocietyForm({ ...societyForm, ...obj });
    };

    if (!societyForm) return null;

    return (
        <>
            <div className="col-md-12 text-center d-flex">
                <div className="mx-auto mb-3">
                    <label htmlFor="logo">
                        <img
                            src={
                                typeof image === 'string' && image !== ''
                                    ? image
                                    : window.URL.createObjectURL(image as Blob)
                            }
                            className="rounded-circle pointer bg-white"
                            style={{
                                width: '150px',
                                height: '150px',
                                objectFit: 'cover'
                            }}
                        />
                    </label>
                </div>
                <input
                    type="file"
                    className="custom-file-input"
                    style={{ height: '0px', width: '0px' }}
                    id="logo"
                    name="logo"
                    onChange={dataOnChangeImages}
                />
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">RUT</label>
                    <input
                        name="rut"
                        id="rut"
                        type="text"
                        className="form-control"
                        value={societyForm.rut}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('rut')}
                        placeholder="Ingrese el RUT"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('rut')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Razon Social</label>
                    <input
                        name="business_name"
                        id="business_name"
                        type="text"
                        className={`form-control ${fieldHasError('business_name')}`}
                        value={societyForm.business_name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('business_name')}
                        placeholder="Ingrese el nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('business_name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Pais</label>
                    <SelectTwo
                        name="country"
                        id="country"
                        inputValue={societyForm.country}
                        options={SelectTwoMapperOptions(countriesOptions, 'value', ['label'])}
                        hasError={fieldHasError('country') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('country', value?.value)}
                        placeholder={'Seleccione Pais'}
                        onFocus={() => onFocusRemove('country')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('country')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label"> Dirección </label>
                    <input
                        name="address"
                        id="address"
                        type="text"
                        className={`form-control ${fieldHasError('address')}`}
                        value={societyForm.address}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('address')}
                        placeholder="Ingrese la dirección"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('address')}
                    </div>
                </div>
            </div>

            <hr />
            <h5 className="mb-3">Datos de Contacto</h5>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Usuario</label>
                    <SelectTwo
                        name="user_id"
                        id="user_id"
                        inputValue={societyForm.user_id}
                        options={SelectTwoMapperOptions(users, 'id', ['name'])}
                        hasError={fieldHasError('user_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('user_id', value?.value)}
                        placeholder={'Seleccione Usuario'}
                        onFocus={() => onFocusRemove('user_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('user_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Nombre</label>
                    <input
                        name="first_name_contact"
                        id="first_name_contact"
                        type="text"
                        className={`form-control ${fieldHasError('first_name_contact')}`}
                        value={societyForm.first_name_contact}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('first_name_contact')}
                        placeholder="Ingrese el nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('first_name_contact')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Email</label>

                    <input
                        name="email_contact"
                        id="email_contact"
                        type="email"
                        className={`form-control ${fieldHasError('email_contact')}`}
                        value={societyForm.email_contact}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('email_contact')}
                        placeholder="Ingrese el email"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email_contact')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <div className="col-md-6">
                    <label className="col-form-label">Apellido Paterno</label>
                    <input
                        name="last_name_contact"
                        id="last_name_contact"
                        type="text"
                        className={`form-control ${fieldHasError('last_name_contact')}`}
                        value={societyForm.last_name_contact}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('last_name_contact')}
                        placeholder="Ingrese el apellido paterno"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('last_name_contact')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Apellido Materno</label>

                    <input
                        name="second_last_name_contact"
                        id="second_last_name_contact"
                        type="text"
                        className={`form-control ${fieldHasError('second_last_name_contact')}`}
                        value={societyForm.second_last_name_contact}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('second_last_name_contact')}
                        placeholder="Ingrese el apellido materno"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('second_last_name_contact')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <div className="col-md-12 ">
                    <label className=" col-form-label">Teléfono</label>
                    <div className="row">
                        <div className="col-auto">
                            <select
                                className="form-control form-control-custom pl-2"
                                id="phone_code_contact"
                                name="phone_code_contact"
                                onChange={handleChange}
                                value={societyForm.phone_code_contact}
                                onFocus={() => onFocusRemove('phone_code')}
                            >
                                <option defaultValue={'+56'} value="+56">
                                    +56
                                </option>
                            </select>
                        </div>

                        <div className="col">
                            <input
                                name="phone_contact"
                                id="phone_contact"
                                type="number"
                                className="form-control"
                                value={societyForm.phone_contact}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('phone_contact')}
                                placeholder="Teléfono"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone_contact')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Set de documentos</label>
                    <SelectTwo
                        name="document_sets"
                        id="document_sets"
                        inputValue={SelectTwoMapperOptions(documentSets)}
                        disabled={true}
                        options={SelectTwoMapperOptions(documentSets)}
                        hasError={fieldHasError('documentsSets') !== ''}
                        onChange={(value: any) =>
                            setSocietyForm({
                                ...societyForm,
                                document_sets: value
                            })
                        }
                        isSortable={true}
                        isMulti={true}
                        placeholder={'Seleccione los set de documentos'}
                        onFocus={() => onFocusRemove('document_sets')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('document_sets')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SocietyForm;
