import { useContext, useEffect, useState } from 'react';
import TollExpenseHistoryForm from './forms/TollExpenseHistoryForm';
import LazyLoading from '../../../components/LazyLoading';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { TollExpenseHistoryDataForm } from '../../../app/models/TollExpenseHistory';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useTollExpenseHistoryService from '../../../app/services/hooks/useTollExpenseHistoryService';

interface Props {
    tollExpenseHistoryId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TollExpenseHistoryEdit = ({ tollExpenseHistoryId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const {
        editTollExpenseHistory,
        updateTollExpenseHistory,
        fetchingHistoryTollExpenseEdit,
        fetchingHistoryTollExpenseUpdate
    } = useTollExpenseHistoryService();

    const [tollExpenseHistory, setTollExpenseHistory] = useState<TollExpenseHistoryDataForm>({
        comment: ''
    });

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        editHistory();
    }, [tollExpenseHistoryId]);

    const editHistory = () => {
        if (showLoading) showLoading('loading', 'Cargando información de historial...');
        editTollExpenseHistory(tollExpenseHistoryId, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setTollExpenseHistory(response.data.toll_expense_history);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onCancel) onCancel();
            }
        });
    };

    const updateHistory = () => {
        if (showLoading) showLoading('loading', 'Actualizando información de historial...');
        updateTollExpenseHistory(tollExpenseHistoryId, tollExpenseHistory, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingHistoryTollExpenseEdit ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <TollExpenseHistoryForm
                        tollExpenseHistoryDataForm={tollExpenseHistory}
                        setTollExpenseHistoryDataForm={setTollExpenseHistory}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingHistoryTollExpenseUpdate}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={updateHistory}
                        locked={fetchingHistoryTollExpenseUpdate}
                    />
                </div>
            </div>
        </>
    );
};

export default TollExpenseHistoryEdit;
