import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { TollExpenseDataForm, defaultTollExpenseDataForm } from '../../../app/models/TollExpense';
import { Provider } from '../../../app/models/Provider';
import TollExpenseForm from './forms/TollExpenseForm';
import { Currency } from '../../../app/models/Currency';
import useTollExpenseService from '../../../app/services/hooks/useExpenseTollService';
import { ExpenseDocumentType } from '../../../app/models/ExpenseDocumentType';
import { Toll } from '../../../app/models/Toll';
import { PaymentMethod } from '../../../app/models/PaymentMethod';
import useTollRateService from '../../../app/services/hooks/useTollRateService';

interface Props {
    tollExpenseId: number;
    transportOrderId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TollExpenseEdit = ({
    transportOrderId,
    tollExpenseId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditTollExpense,
        editTollExpense,
        fetchingUpdateTollExpense,
        updateTollExpense
    } = useTollExpenseService();

    const { fetchingGetTollRateByToll, getTollRateByToll } = useTollRateService();

    const [tollExpense, setTollExpense] = useState<TollExpenseDataForm>({
        ...defaultTollExpenseDataForm,
        transport_order_id: transportOrderId
    });

    const [providers, setProviders] = useState<Provider[]>([]);
    const [expenseDocumentTypes, setExpenseDocumentTypes] = useState<ExpenseDocumentType[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [tolls, setTolls] = useState<Toll[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

    const currencyDefault = useRef<number | undefined>(0);
    const tollIdSelected = useRef<number | undefined>(0);

    useEffect(() => {
        edit();
    }, [tollExpenseId]);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando gasto de viaje...');
        editTollExpense(transportOrderId, tollExpenseId, {
            onSuccess: (response: ServiceResponse) => {
                setPaymentMethods(response.data.payment_methods);
                setTolls(response.data.tolls);
                setProviders(response.data.providers);
                setExpenseDocumentTypes(response.data.expense_document_types);
                setCurrencies(response.data.currencies);
                tollIdSelected.current = response.data.toll_expense.toll_id;
                setTollExpense({
                    ...tollExpense,
                    toll_id: response.data.toll_expense.toll_id,
                    currency_id: response.data.toll_expense.expense.currency_id,
                    amount: response.data.toll_expense.expense.amount,
                    provider_id: response.data.toll_expense.expense.provider_id,
                    issue_date: response.data.toll_expense.expense.issue_date,
                    expense_document_type: response.data.toll_expense.expense.expense_document_type,
                    folio: response.data.toll_expense.expense.folio,
                    is_reported: response.data.toll_expense.expense.is_reported,
                    description: response.data.toll_expense.expense.description,
                    payment_method: response.data.toll_expense.payment_method,
                    photo: response.data.toll_expense.expense.photo
                });
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (tollExpense.toll_id == undefined) {
            tollIdSelected.current = undefined;
        }

        if (tollExpense.toll_id && tollExpense.toll_id != tollIdSelected.current) {
            getTollRateByToll(transportOrderId, tollExpense.toll_id, tollExpenseId, {
                onSuccess: (response: ServiceResponse) => {
                    tollIdSelected.current = tollExpense.toll_id ?? 0;
                    setTollExpense({
                        ...tollExpense,
                        amount: response.data.instance_data
                            ? response.data.instance_data.expense?.amount
                            : response.data?.toll_rate?.amount ?? undefined,
                        currency_id: response.data.instance_data
                            ? response.data.instance_data.expense?.currency_id
                            : response.data?.toll_rate?.currency_id ?? currencyDefault.current
                    });
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message);
                    setTollExpense({
                        ...tollExpense,
                        toll_id: tollIdSelected.current
                    });
                }
            });
        }
    }, [tollExpense.toll_id]);

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando Gasto de peaje...');
        updateTollExpense(transportOrderId, tollExpenseId, tollExpense, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditTollExpense ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <TollExpenseForm
                        tollExpenseDataForm={tollExpense}
                        setTollExpenseForm={setTollExpense}
                        tolls={tolls}
                        expenseDocumentTypes={expenseDocumentTypes}
                        errorFields={errorFields}
                        paymentMethods={paymentMethods}
                        providers={providers}
                        currencies={currencies}
                        fetching={fetchingGetTollRateByToll}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingUpdateTollExpense} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateTollExpense} />
                </div>
            </div>
        </>
    );
};

export default TollExpenseEdit;
