import React, { useState, useContext, useEffect } from 'react';
import useCalendarRequestService from '../../../app/services/hooks/useCalendarRequestService';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import CalendarRequestForm from '../../Calendar/components/forms/CalendarRequestForm';
import {
    CalendarRequestDataForm,
    defaultCalendarRequestDataForm
} from '../../../app/models/CalendarRequest';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import { EventType } from '../../../app/models/EventType';
import { Worker } from '../../../app/models/Workers';

interface Props {
    calendarRequestId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CalendarRequestEdit = ({ calendarRequestId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const {
        fetchingEditCalendarRequest,
        editCalendarRequest,
        fetchingUpdateCalendarRequest,
        updateCalendarRequest
    } = useCalendarRequestService();

    const [calendarRequest, setCalendarRequest] = useState<CalendarRequestDataForm>({
        ...defaultCalendarRequestDataForm
    });
    const [calendarRequestDocument, setCalendarRequestDocument] = useState<string>('');
    const [eventTypes, setEventTypes] = useState<EventType[]>([]);
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, [calendarRequestId]);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando solicitud...');
        editCalendarRequest(calendarRequestId, {
            onSuccess: (response: ServiceResponse) => {
                setEventTypes(response.data.event_types);
                setCalendarRequest(response.data.calendar_request);
                setCalendarRequestDocument(response.data.calendar_request_document);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando Solicitud de fecha...');
        updateCalendarRequest(calendarRequestId, calendarRequest, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditCalendarRequest ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <CalendarRequestForm
                        calendarRequest={calendarRequest}
                        setCalendarRequest={setCalendarRequest}
                        calendarRequestDocument={calendarRequestDocument}
                        eventTypes={eventTypes}
                        workers={workers}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateCalendarRequest}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateCalendarRequest} />
                </div>
            </div>
        </>
    );
};

export default CalendarRequestEdit;
