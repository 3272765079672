import React, { useContext, useEffect, useState } from 'react';

import useSocietyService from '../../../app/services/hooks/useSocietyService';
import { DocumentSet } from '../../../app/models/DocumentSet';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import SocietyForm from './forms/SocietyForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { defaultSocietyDataForm, SocietyDataForm } from '../../../app/models/Society';
import { User } from '../../../app/models/User';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const SocietyCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [users, setUsers] = useState<User[]>([]);
    const [society, setSociety] = useState<SocietyDataForm>(defaultSocietyDataForm);
    const [documentSets, setDocumentSets] = useState<DocumentSet[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingStoreSociety, storeSociety, fetchingCreateSociety, createSociety } =
        useSocietyService();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createSociety({
            onSuccess: (response: ServiceResponse) => {
                setUsers(response.data.users);
                setDocumentSets(response.data.document_sets);
                setSociety(defaultSocietyDataForm);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        // useSweetAlert().spinnerAlert("Guardando sociedad...");
        if (showLoading) showLoading('loading', 'Guardando sociedad...');
        storeSociety(society, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateSociety ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <SocietyForm
                        societyForm={society}
                        setSocietyForm={setSociety}
                        users={users}
                        documentSets={documentSets}
                        errorFields={errorFields}
                        setErrorFields={setErrorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreSociety} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreSociety} />
                </div>
            </div>
        </>
    );
};

export default SocietyCreate;
