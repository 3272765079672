import React, { useEffect, useState } from 'react';
import useDispatchProgramItemService from '../../../app/services/hooks/useDispatchProgramItemService';
import {
    defaultDispatchProgramItem,
    DispatchProgramItem
} from '../../../app/models/DispatchProgramItem';
import { Product } from '../../../app/models/Product';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import { Place } from '../../../app/models/Place';
import { Service } from '../../../app/models/Service';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import DispatchProgramItemForm from './forms/DispatchProgramItemForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import LazyLoading from '../../../components/LazyLoading';
import useSweetAlert from '../../../hooks/useSweetAlert';

interface Props {
    canBeEdited: boolean;
    dispatchProgramId: number;
    dispatchProgramItemId: number;
    dispatchProgramItemStatus: string;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message?: string) => void;
}

const DispatchProgramItemEdit = ({
    canBeEdited,
    dispatchProgramId,
    dispatchProgramItemId,
    dispatchProgramItemStatus,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const {
        editDispatchProgramItem,
        updateDispatchProgramItem,
        fetchingEditDispatchProgramItem,
        fetchingUpdateDispatchProgramItem
    } = useDispatchProgramItemService();

    const [errorFields, setErrorFields] = useState<any>();

    const [dispatchProgramItemForm, setDispatchProgramItemForm] = useState<DispatchProgramItem>({
        ...defaultDispatchProgramItem,
        dispatch_program_id: dispatchProgramId
    });

    const [products, setProducts] = useState<Product[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [origins, setOrigins] = useState<Place[]>([]);
    const [destinies, setDestinies] = useState<Place[]>([]);
    const [services, setServices] = useState<Service[]>([]);

    useEffect(() => {
        if (dispatchProgramItemId) {
            edit();
        }
    }, [dispatchProgramItemId]);

    useEffect(() => {
        if (services.length) {
            setDispatchProgramItemForm({
                ...dispatchProgramItemForm,
                service_id: services[0].id
            });
        }
    }, [services]);

    const edit = () => {
        editDispatchProgramItem(dispatchProgramItemId, {
            onSuccess: (response: ServiceResponse) => {
                setProducts(response.data.products);
                setQuantityUnits(response.data.quantity_units);
                setCurrencies(response.data.currencies);
                setOrigins(response.data.origins);
                setDestinies(response.data.destinies);
                setServices(response.data.services);
                setDispatchProgramItemForm(response.data.dispatch_program_item);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2500
                });
                if (onError) onError;
            },
            onFieldError: (errorFields: ServiceResponse) => {
                setErrorFields(errorFields.data);
            }
        });
    };

    const update = () => {
        useSweetAlert().spinnerAlert('Actualizando detalle despacho...', 400);
        updateDispatchProgramItem(dispatchProgramItemId, dispatchProgramItemForm, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                if (onError) onError(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {!fetchingEditDispatchProgramItem ? (
                        dispatchProgramItemStatus === 'DESPACHO' ? (
                            <DispatchProgramItemForm
                                products={products}
                                quantityUnits={quantityUnits}
                                currencies={currencies}
                                origins={origins}
                                destinies={destinies}
                                services={services}
                                dispatchProgramItemForm={dispatchProgramItemForm}
                                setDispatchProgramItemForm={setDispatchProgramItemForm}
                                errorFields={errorFields}
                                canBeEdited={canBeEdited}
                            />
                        ) : (
                            <DispatchProgramItemForm
                                products={products}
                                quantityUnits={quantityUnits}
                                currencies={currencies}
                                origins={origins}
                                destinies={destinies}
                                services={services}
                                dispatchProgramItemForm={dispatchProgramItemForm}
                                setDispatchProgramItemForm={setDispatchProgramItemForm}
                                errorFields={errorFields}
                                canBeEdited={canBeEdited}
                            />
                        )
                    ) : (
                        <LazyLoading height="300" />
                    )}
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateDispatchProgramItem}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={update}
                        locked={fetchingUpdateDispatchProgramItem}
                    />
                </div>
            </div>
        </>
    );
};

export default DispatchProgramItemEdit;
