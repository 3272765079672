import React from 'react';
import { IncidentDataForm } from '../../../../app/models/Incident';

interface Props {
    incidentDataForm: IncidentDataForm;
}

const IncidentShowForm = ({ incidentDataForm }: Props) => {
    if (!incidentDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Fecha</label>
                    <input
                        type="date"
                        name="incident_date"
                        className="form-control"
                        readOnly={true}
                        value={incidentDataForm.incident_date}
                    />
                </div>

                <div className="col-md-6">
                    <label className="col-form-label">Hora</label>
                    <input
                        type="time"
                        name="incident_hour"
                        className="form-control"
                        readOnly={true}
                        value={incidentDataForm.incident_hour}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Motivo</label>
                    <textarea
                        name="reason"
                        className="form-control"
                        readOnly={true}
                        value={incidentDataForm.reason}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Solución</label>
                    <textarea
                        name="solution"
                        className="form-control"
                        readOnly={true}
                        value={incidentDataForm.solution}
                    />
                </div>
            </div>
        </>
    );
};

export default IncidentShowForm;
