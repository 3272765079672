import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { UnitEquivalence, UnitEquivalenceFilters } from '../../../../app/models/Unit';
import UnitEquivalenceSwitchChange from '../UnitEquivalenceSwitchChange';

interface Props {
    unitEquivalences: UnitEquivalence[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: UnitEquivalenceFilters;
    setFilter: (filter: UnitEquivalenceFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
}

const UnitEquivalenceDataTable = ({
    unitEquivalences,
    totalRows,
    loading = false,
    edit,
    destroy,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {
    const columns = [
        {
            name: 'Unidad de referencia',
            selector: (row: any) => row.from_unit.name,
            sortable: true,
            sortField: 'from_unit',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {row.from_unit_quantity} {row.from_unit.name}
                </span>
            )
        },
        {
            name: 'Conversión',
            selector: (row: any) => row.to_unit_quantity,
            sortable: true,
            sortField: 'to_unit_quantity',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    Equivale a {row.to_unit_quantity} {row.to_unit.name}
                </span>
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: any, index: any, column: any) => (
                <UnitEquivalenceSwitchChange
                    checkValue={row.is_active ?? true}
                    unitEquivalenceId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <DefaultDataTable
                columns={columns}
                data={unitEquivalences}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default UnitEquivalenceDataTable;
