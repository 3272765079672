import { useContext, useEffect, useState } from 'react';
import { defaultDocument, Document } from '../../../app/models/Document';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import DocumentFormContainer from './DocumentFormContainer';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import useDocumentService from '../../../app/services/hooks/useDocumentService';

interface Props {
    documentId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DocumentEdit = ({ documentId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditDocument, editDocument, fetchingUpdateDocument, updateDocument } =
        useDocumentService();
    const [document, setDocument] = useState<Document>(defaultDocument);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando documento...');
        editDocument(documentId, {
            onSuccess: (response: ServiceResponse) => {
                setDocument(response.data.documents);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando nombre del documento...');
        updateDocument(documentId, document, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditDocument ? (
        <LazyLoading height="300" />
    ) : (
        <DocumentFormContainer
            fetching={fetchingUpdateDocument}
            action={update}
            cancel={cancel}
            document={document}
            setDocument={setDocument}
            errorFields={errorFields}
        />
    );
};

export default DocumentEdit;
