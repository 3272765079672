import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { AccountDataForm } from '../../../../app/models/Account';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Clasification } from '../../../../app/models/Clasification';
import { formatAccounts } from '../../../../utils/utils';

type Props = {
    accountDataForm: AccountDataForm;
    setAccountDataForm: (accountDataForm: AccountDataForm) => void;
    clasifs: Clasification[];
    clasifsTwo: Clasification[];
    clasifsThree: Clasification[];
    clasifications: Clasification[];
    errorFields?: any;
};

const AccountForm = ({
    accountDataForm,
    setAccountDataForm,
    clasifs,
    clasifsTwo,
    clasifsThree,
    clasifications,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [filteredClasifsTwo, setFilteredClasifsTwo] = useState<Clasification[]>([]);
    const [filteredClasifsThree, setFilteredClasifsThree] = useState<Clasification[]>([]);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setAccountDataForm({ ...accountDataForm, [name]: value });
    };

    useEffect(() => {
        if (accountDataForm.clasif_three_id) {
            const selectedClasifThree = filteredClasifsThree.find(
                (clasif) => clasif.id === accountDataForm.clasif_three_id
            );
            const selectedAccountNumberThree = selectedClasifThree?.account_number;

            if (selectedAccountNumberThree) {
                // Tomar los primeros 7 dígitos del account_number seleccionado
                const baseAccountNumber = selectedAccountNumberThree.substr(0, 8);
                // Mantener los últimos 3 dígitos ingresados por el usuario si existen
                const userDigits = accountDataForm.account?.substr(8, 3) || '';
                // Actualizar el estado con los nuevos valores
                setAccountDataForm({
                    ...accountDataForm,
                    account: baseAccountNumber + userDigits
                });
            }
        }
    }, [accountDataForm.clasif_three_id, filteredClasifsThree, setAccountDataForm]);

    const handleAccountChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        if (name == 'account') {
            const selectedClasifThree = filteredClasifsThree.find(
                (clasif) => clasif.id === accountDataForm.clasif_three_id
            );
            const selectedAccountNumberThree = selectedClasifThree?.account_number;

            if (selectedAccountNumberThree) {
                // Tomar los primeros 7 dígitos del account_number seleccionado
                const baseAccountNumber = selectedAccountNumberThree.substr(0, 8);

                const newUserDigits = value.substr(8, 3);
                setAccountDataForm({
                    ...accountDataForm,
                    [name]: baseAccountNumber + newUserDigits
                });
            }
        } else {
            setAccountDataForm({ ...accountDataForm, [name]: value });
        }
    };

    useEffect(() => {
        if (accountDataForm.clasif_id) {
            const selectedClasif = clasifs.find(
                (clasif) => clasif.id === accountDataForm.clasif_id
            );
            const selectedAccountNumber = selectedClasif?.account_number;

            if (selectedAccountNumber) {
                const filteredOptions = clasifsTwo.filter(
                    (clasifTwo) =>
                        clasifTwo.account_number.charAt(0) === selectedAccountNumber.charAt(0)
                );
                setFilteredClasifsTwo(filteredOptions);
            } else {
                setFilteredClasifsTwo([]);
            }
        } else {
            setFilteredClasifsTwo([]);
        }
    }, [accountDataForm.clasif_id, clasifs, clasifsTwo]);

    // Filtrar clasifsThree basado en clasif_two_id
    useEffect(() => {
        if (accountDataForm.clasif_two_id) {
            const selectedClasifTwo = filteredClasifsTwo.find(
                (clasif) => clasif.id === accountDataForm.clasif_two_id
            );
            const selectedAccountNumberTwo = selectedClasifTwo?.account_number;

            if (selectedAccountNumberTwo) {
                const filteredOptions = clasifsThree.filter((clasifThree) =>
                    clasifThree.account_number.startsWith(selectedAccountNumberTwo.substr(0, 3))
                );
                setFilteredClasifsThree(filteredOptions);
            } else {
                setFilteredClasifsThree([]);
            }
        } else {
            setFilteredClasifsThree([]);
        }
    }, [accountDataForm.clasif_two_id, filteredClasifsTwo, clasifsThree]);

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setAccountDataForm({ ...accountDataForm, ...obj });
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Primera Clasificación</label>
                    <SelectTwo
                        name="clasif_id"
                        id="clasif_id"
                        inputValue={accountDataForm.clasif_id}
                        hasError={fieldHasError('clasif_id') !== ''}
                        options={SelectTwoMapperOptions(clasifs, 'id', ['account_number', 'name'])}
                        onChange={(value: any) => handleChangeSelectTwo('clasif_id', value.value)}
                        onFocus={() => onFocusRemove('clasif_id')}
                        placeholder="Seleccione clasificación"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('clasif_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Segunda Clasificación</label>
                    <SelectTwo
                        name="clasif_two_id"
                        id="clasif_two_id"
                        inputValue={accountDataForm.clasif_two_id}
                        hasError={fieldHasError('clasif_two_id') !== ''}
                        options={SelectTwoMapperOptions(filteredClasifsTwo, 'id', [
                            'account_number',
                            'name'
                        ])}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('clasif_two_id', value.value)
                        }
                        onFocus={() => onFocusRemove('clasif_two_id')}
                        placeholder="Seleccione clasificación"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('clasif_two_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Tercera Clasificación</label>
                    <SelectTwo
                        name="clasif_three_id"
                        id="clasif_three_id"
                        inputValue={accountDataForm.clasif_three_id}
                        hasError={fieldHasError('clasif_three_id') !== ''}
                        options={SelectTwoMapperOptions(filteredClasifsThree, 'id', [
                            'account_number',
                            'name'
                        ])}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('clasif_three_id', value.value)
                        }
                        onFocus={() => onFocusRemove('clasif_three_id')}
                        placeholder="Seleccione clasificación"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('clasif_three_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nombre cuenta</label>
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={accountDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre del cuenta"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Código de la Cuenta</label>
                    <input
                        name="account"
                        id="account"
                        type="text"
                        className={`form-control ${fieldHasError('account')}`}
                        value={accountDataForm.account}
                        onChange={handleAccountChange}
                        onFocus={() => onFocusRemove('account')}
                        placeholder="Ingrese cuenta"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('account')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Agrupador adicional </label>
                    <input
                        name="clasification"
                        id="clasification"
                        type="text"
                        className={`form-control ${fieldHasError('clasification')}`}
                        value={accountDataForm.clasification}
                        onChange={handleAccountChange}
                        onFocus={() => onFocusRemove('clasification')}
                        placeholder="Ingrese Clasificación"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('clasification')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountForm;
