import React, { useContext, useEffect, useState } from 'react';
import { Provision } from '../../../../app/models/Provision';

import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import LazyLoading from '../../../../components/LazyLoading';

import { AppContext } from '../../../../contexts/AppContext';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useProvisionService from '../../../../app/services/hooks/useProvisionService';
import ProvisionFormContainer from './../ProvisionFormContainer';

interface Props {
    provisionId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ProvisionEdit = ({ provisionId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditProvision, editProvision, fetchingUpdateProvision, updateProvision } =
        useProvisionService();

    const [Provision, setProvision] = useState<Provision>({ name: '' });
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando previsión...');
        editProvision(provisionId, {
            onSuccess: (response: ServiceResponse) => {
                setProvision(response.data.provision);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando previsión...');
        updateProvision(provisionId, Provision, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditProvision ? (
        <LazyLoading height="300" />
    ) : (
        <ProvisionFormContainer
            fetching={fetchingUpdateProvision}
            action={update}
            cancel={cancel}
            provision={Provision}
            setProvision={setProvision}
            errorFields={errorFields}
        />
    );
};

export default ProvisionEdit;
