import { EventType } from './EventType';
import { Shift, ShiftDays, ShiftWorkDayTravelExpense } from './Shift';
import { TravelExpense } from './TravelExpense';
import { Worker } from './Workers';

export interface CalendarEvent {
    id?: number;
    title?: string;
    color?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    start_hour?: Date | string;
    end_hour?: Date | string;
    start?: Date;
    end?: Date;
    event_type?: EventType;
    event_type_id?: number;
    travel_expense?: TravelExpense;
    travel_expense_id?: number;
    worker?: Worker;
    worker_id?: number;
    expense_id?: number;
    is_undefined?: boolean;
    is_shift_work?: boolean;
    shift_Work_day_travel_expense?: ShiftWorkDayTravelExpense[];
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface CalendarEventDataForm {
    id?: number;
    title?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    start_hour?: Date | string;
    end_hour?: Date | string;
    start?: Date;
    end?: Date;
    event_type?: EventType;
    event_type_id?: number;
    shift_work?: Shift;
    shift_work_id?: number;
    travel_expense?: TravelExpense;
    travel_expense_id?: number;
    worker_id?: number;
    is_undefined?: boolean;
    is_shift_work?: boolean;
    is_active?: boolean;
    shift_Work_day_travel_expense?: ShiftWorkDayTravelExpense[];
    shift_days?: ShiftDays[];
}

export const defaultCalendarEventDataForm: CalendarEvent = {
    title: '',
    start_date: '',
    end_date: '',
    start_hour: '00:00',
    end_hour: '23:59',
    event_type_id: 0,
    worker_id: 0,
    is_undefined: false,
    is_shift_work: false,
    is_active: true
};
