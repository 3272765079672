import React, { useContext, useEffect, useState } from 'react';

import { Place } from '../../app/models/Place';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';

import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';

import { toast } from 'react-toastify';

import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import useForeingExchangeService from '../../app/services/hooks/useForeignExchangeService';
import ForeignExchangeDataTable from './components/tables/ForeignExchangeDataTable';
import { useHistory } from 'react-router-dom';
import useNavigationPage from '../../hooks/useNavigationPage';
import { ForeignExchanges,ForeignExchangeFilters } from '../../app/models/ForeignExchange'

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Divisas',
        url: '/foreign-exchanges',
        isActive: true
    }
];

const ForeignExchange = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetForeignExchanges,getForeignExchange} =
        useForeingExchangeService();
    const [foreingExchange, setForeingExchange] = useState<ForeignExchanges[]>([]);
    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [foreignExchangeFilter, setForeingExchangeFilter] = useState<ForeignExchangeFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, []);

    const getAllForeingExchange = () => {
        getForeignExchange(foreignExchangeFilter, {
            onSuccess: (response: ServiceResponse) => {
                setForeingExchange(response.data.foreign_exchanges);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };



    // const showCreate = () => {
    //     setShowingCreate(true);
    // };

    // const hideCreate = () => {
    //     setShowingCreate(false);
    // };

    const reloadTable = () => {
        getAllForeingExchange();
    };



    return (
        <>
            <Breadcrumbs pageSection="Divisas" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                {/* <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nueva moneda" />}
                /> */}
                <ForeignExchangeDataTable
                    foreignExchanges={foreingExchange}
                    loading={fetchingGetForeignExchanges}
                    filter={foreignExchangeFilter}
                    setFilter={setForeingExchangeFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                    onSearch={reloadTable}
                />
            </DefaultCard>

            {/* {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear moneda"
                    backdrop={true}
                    showFooter={false}
                >
                    <CurrencyCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                    />
                </DefaultModal>
            ) : null} */}

        </>
    );
};

export default ForeignExchange;
