import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { SocietyDocument } from '../../../app/models/Society';
import useSocietyDocumentService from '../../../app/services/hooks/useSocietyDocumentService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import DocumentsHistoryDataTable from '../../../components/DocumentHistoryDataTable';

interface Props {
    onCancel?: () => void;
    onError?: () => void;
    societyId: number;
    typeDocument: number;
}

const SocietyDocumentHistory = ({ onCancel, onError, societyId, typeDocument }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const [societyDocuments, setSocietyDocuments] = useState<SocietyDocument[]>([]);

    const {
        getSocietyDocumentHistoryBySocietyAndDocumentType,
        fetchingGetSocietyDocumentHistoryBySocietyAndDocumentType
    } = useSocietyDocumentService();

    const getDocumentsHistory = (societyId: number, typeDocument: number) => {
        getSocietyDocumentHistoryBySocietyAndDocumentType(societyId, typeDocument, {
            onSuccess: (response: ServiceResponse) => {
                setSocietyDocuments(response.data.society_documents);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        getDocumentsHistory(societyId, typeDocument);
    }, []);

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingGetSocietyDocumentHistoryBySocietyAndDocumentType ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    {societyDocuments && <DocumentsHistoryDataTable data={societyDocuments} />}
                </div>
            </div>
        </>
    );
};

export default SocietyDocumentHistory;
