import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useDispatchProgramItemService from '../../../app/services/hooks/useDispatchProgramItemService';
import MassiveLoadForm from './forms/MassiveLoadForm';
import { PaymentStatusLoad, defaultPaymentStatusLoad } from '../../../app/models/PaymentStatusLoad';
import usePaymentStatusService from '../../../app/services/hooks/usePaymentStatusService';
import PaymentStatusForm from './forms/PaymentStatusForm';

interface Props {
    dispatch_program_id: number;
    transportation_order_item_id: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const PaymentStatusLoadCreate = ({
    dispatch_program_id,
    transportation_order_item_id,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation, showProgressiveLoading } =
        useContext(AppContext);
    const history = useHistory();
    const { generatePaymentStatusDocument, fetchingGeneratePaymentStatus } =
        usePaymentStatusService();
    const { excelLoadBulk, fetchingExcelLoadBulk } = useDispatchProgramItemService();
    const [load, setLoad] = useState<PaymentStatusLoad>({
        ...defaultPaymentStatusLoad,
        dispatch_program_id: dispatch_program_id,
        transportation_order_item_id: transportation_order_item_id
    });

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (load.id) {
            generatePaymentStatuses();
        }
    }, [load.id, load.blockStart]);

    const generatePaymentStatuses = () => {
        if (showProgressiveLoading)
            showProgressiveLoading('Generando estados de pago...', load.progressPercentage);

        generatePaymentStatusDocument(load, {
            onSuccess: (response) => {
                if (response.data.load.all_load) {
                    if (showProgressiveLoading)
                        showProgressiveLoading('Generando estados de pago...', 100);
                    setTimeout(() => {
                        if (hideLoading) hideLoading();
                        setLoad(defaultPaymentStatusLoad);

                        useReactConfirmAlert().successAlert({
                            title: 'Éxito',
                            message: 'Estados de pago generados de forma exitosa.'
                        });
                        if (onSaved) onSaved();
                    }, 2000);
                } else {
                    setLoad({
                        ...load,
                        ...response.data.load
                    });
                }
            },
            onError: (response) => {
                if (hideLoading) hideLoading();
                setLoad({
                    ...load,
                    ...defaultPaymentStatusLoad
                });
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <PaymentStatusForm load={load} setLoad={setLoad} errorFields={errorFields} />
                </div>
            </div>
            <hr />
            <div className="text-center">
                <p>¿Está seguro de generar los estados de pago?</p>
            </div>

            <div className="row justify-content-center">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingExcelLoadBulk} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={generatePaymentStatuses}
                        locked={fetchingExcelLoadBulk}
                    />
                </div>
            </div>
        </>
    );
};

export default PaymentStatusLoadCreate;
