import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultToolBar from '../../components/default/DefaultToolBar';

import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';

import { toast } from 'react-toastify';
import LoadTicketDataTable from '../WorkOrders/components/tables/LoadTicketDataTable';
import { LoadTicketDataForm, LoadTicketFilters } from '../../app/models/LoadTicket';
import useLoadTicketService from '../../app/services/hooks/useLoadTicketService';
import LazyLoading from '../../components/LazyLoading';
import { useHistory } from 'react-router-dom';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Tickets de descarga',
        url: '/Load-tickets',
        isActive: true
    }
];

const LoadTicket = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { getLoadTickets, fetchingGetLoadTickets, getUrlDocumentLoadTicket, downloadDocument } =
        useLoadTicketService();
    const { navigationPage } = useNavigationPage();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingGet, setShowingGet] = useState(false);

    const [loadTicketIdEdit, setLoadTicketIdEdit] = useState<number>(-1);

    const [loadTickets, setLoadTickets] = useState<LoadTicketDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<LoadTicketFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, []);

    const reloadTable = () => {
        getLoadTickets(filter, {
            onSuccess: (response: ServiceResponse) => {
                setLoadTickets(response.data.load_tickets);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (expenseId: number) => {
        setShowingEdit(true);
        setLoadTicketIdEdit(expenseId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setLoadTicketIdEdit(-1);
    };

    const showGet = (expenseId: number) => {
        setShowingGet(true);
        setLoadTicketIdEdit(expenseId);
    };

    const hideGet = () => {
        setShowingGet(false);
        setLoadTicketIdEdit(-1);
    };

    const document = (loadTicketId: number) => {
        if (showLoading) showLoading('download', 'descargando archivo...');
        getUrlDocumentLoadTicket(loadTicketId, {
            onSuccess: (response: ServiceResponse) => {
                window.open(response.data.url_file);
                if (changeAnimation)
                    changeAnimation('downloadSuccess', 'archivo recuperado con exito', true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) {
                    hideLoading();
                }
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Tickets de descarga" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <h5>Tickets de descarga</h5>

                    <div className="col-12">
                        {loadTickets ? (
                            <LoadTicketDataTable
                                loading={fetchingGetLoadTickets}
                                loadTickets={loadTickets}
                                totalRows={totalRows}
                                filter={filter}
                                setFilter={setFilter}
                                documentLoadTicket={document}
                                loadTicketPage={true}
                                onSearch={reloadTable}
                            />
                        ) : null}
                    </div>
                </div>
            </DefaultCard>
        </>
    );
};

export default LoadTicket;
