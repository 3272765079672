import { useContext, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';
import { ServiceEvents } from '../shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { fetchDefaultHeaders } from '../shared/vars';
import {
    LoadTicketHistoryDataForm,
    LoadTicketHistoryFilter
} from '../../models/LoadTicketsHistory';

const useLoadTicketHistoryService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [
        fetchingGetLoadTicketHistoriesByLoadTicket,
        setFetchingGetLoadTicketHistoriesByLoadTicket
    ] = useState(false);
    const [fetchingHistoryLoadTicketUpdate, setFetchingHistoryLoadTicketUpdate] = useState(false);
    const [fetchingHistoryLoadTicketEdit, setFetchingEditLoadTicketHistory] = useState(false);

    const getAllLoadTickethistoriesByLoadTicket = (
        filter: LoadTicketHistoryFilter,
        load_ticket_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.LOAD_TICKET_HISTORIES.GET_LOAD_TICKET_HISTORIES_BY_LOAD_TICKETE.replace(
            ':load_ticket_id',
            load_ticket_id.toString()
        )}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetLoadTicketHistoriesByLoadTicket
        });
    };

    const editLoadTicketHistory = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.LOAD_TICKET_HISTORIES.EDIT_LOAD_TICKET_HISTORY.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingEditLoadTicketHistory
        });
    };

    const updateLoadTicketHistory = (
        id: number,
        loadTicketHistoryDataForm: LoadTicketHistoryDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.LOAD_TICKET_HISTORIES.UPDATE_LOAD_TICKET_HISTORY.replace(
                ':id',
                id.toString()
            ),
            body: loadTicketHistoryDataForm,
            setFetching: setFetchingHistoryLoadTicketUpdate
        });
    };

    return {
        fetchingGetLoadTicketHistoriesByLoadTicket,
        fetchingHistoryLoadTicketEdit,
        fetchingHistoryLoadTicketUpdate,
        getAllLoadTickethistoriesByLoadTicket,
        editLoadTicketHistory,
        updateLoadTicketHistory
    };
};

export default useLoadTicketHistoryService;
