import { EventType } from './EventType';
import { TravelExpense } from './TravelExpense';

export interface Shift {
    id?: number;
    name?: string;
    shift_days?: ShiftDays[];
    workdays?: number;
    free_days?: number;
    workdays_event_type?: number;
    free_days_event_type?: number;
    workdays_event_type_id?: number;
    free_days_event_type_id?: number;

    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface ShiftDays {
    id?: number;
    number_of_days?: number;
    event_type_id?: number;
    event_type?: EventType;
    shift_work_id?: number;
    position?: number;
    travel_expense?: TravelExpense;
    travel_expense_id?: number;
    travel_expenses?: TravelExpense[];
}

export interface ShiftDayTravelExpense {
    travel_expense?: TravelExpense;
    travel_expense_id?: number;
    shift_day?: ShiftDays;
}

export interface ShiftWorkDayTravelExpense {
    travel_expense_id?: number | string | null;
    shift_id?: number | string | null;
    position?: number;
    travel_expense?: TravelExpense | null;
}

export interface ShiftFilters {
    id?: number | null;
    name?: string;
    workdays?: number;
    free_days?: number;

    is_repeat?: boolean;
    resignable?: boolean;
    is_active?: boolean;

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface ShiftDataForm {
    id?: number;
    name: string;
    shift_days?: ShiftDays[];
    workdays?: number;
    free_days?: number;
    workdays_event_type_id?: number;
    free_days_event_type_id?: number;

    is_repeat?: boolean;
    resignable?: boolean;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export const defaultShiftDataForm: Shift = {
    name: ''
};
