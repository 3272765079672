import React, { useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import { toast } from 'react-toastify';
import useDispatchProgramItemService from '../../../app/services/hooks/useDispatchProgramItemService';
import useSweetAlert from '../../../hooks/useSweetAlert';
import LazyLoading from '../../../components/LazyLoading';
import useDispatchProgramService from '../../../app/services/hooks/useDispatchProgramService';
import TransportationSubOrderItemsDataTable from './tables/TransportationSubOrderItemsDataTable';
import { TransportationSubOrderItemDataForm } from '../../../app/models/TransportationSubOrderItem';
import TransportationSubOrderItemSplit from './TransportationSubOrderItemSplit';
import usePaymentStatusService from '../../../app/services/hooks/usePaymentStatusService';
import TransportationSubOrderItemsContainer from './TransportationSubOrderItemsContainer';
import PaymentStatusLoadCreate from './PaymentStatusLoadCreate';

interface Props {
    dispatchProgramId: number;
    transportationSubOrderItemId: number;
    canBeEdited: boolean;
    isShow?: boolean;
    changeStatus?: boolean;
    reloadModule?: () => void;
}

const DispatchProgramItemsContainer = ({
    dispatchProgramId,
    transportationSubOrderItemId,
    canBeEdited,
    reloadModule,
    changeStatus = false,
    isShow = false
}: Props) => {
    const {
        fetchingGetDispatchProgramItemsByDispatch,
        getAllDispatchProgramItemsByDispatch,
        deleteDispatchProgramItem
    } = useDispatchProgramItemService();

    const [refreshOtherComponents, setRefreshOtherComponents] = useState(false);
    const {
        getTransportationSubOrderItemsDispatchProgram,
        fetchingGetTransportationSubOrderItemsDispatchProgram
    } = useDispatchProgramService();
    const { generatePaymentStatusDocument, fetchingGeneratePaymentStatus } =
        usePaymentStatusService();

    const [showingSplit, setShowingSplit] = useState(false);
    const [showingPaymentStatusLoad, setShowingPaymentStatusLoad] = useState(false);
    const [transportOrderItemIdEdit, setTransportOrderItemIdEdit] = useState<number>(-1);

    const [transportationSubOrderItems, setTransportationSubOrderItems] = useState<
        TransportationSubOrderItemDataForm[]
    >([]);

    const [transportationOrderItemIdSplit, setTransportationOrderItemIdSplit] =
        useState<number>(-1);

    useEffect(() => {
        reloadTable();
    }, []);

    const showSplit = (transportationOrderItemId: number) => {
        setShowingSplit(true);
        setTransportationOrderItemIdSplit(transportationOrderItemId);
    };

    const hideSplit = () => {
        setShowingSplit(false);
        setTransportationOrderItemIdSplit(-1);
    };

    const showPaymentStatusLoad = (id: number) => {
        setShowingPaymentStatusLoad(true);
        setTransportOrderItemIdEdit(id);
    };

    const hidePaymentStatusLoad = () => {
        setShowingPaymentStatusLoad(false);
        setTransportOrderItemIdEdit(-1);
    };

    const reloadTable = () => {
        getTransportationSubOrderItemsDispatchProgram(dispatchProgramId, {
            onSuccess: (response: ServiceResponse) => {
                setTransportationSubOrderItems(response.data.transportation_sub_order_items);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                {!fetchingGetTransportationSubOrderItemsDispatchProgram ? (
                    <div className="row">
                        <div className="col-12">
                            <TransportationSubOrderItemsDataTable
                                transportationSubOrderItems={transportationSubOrderItems}
                                split={showSplit}
                                generatePaymentStatus={showPaymentStatusLoad}
                                isShow={isShow}
                            />
                        </div>
                    </div>
                ) : (
                    <LazyLoading height="300" />
                )}
            </DefaultCard>
            <DefaultCard>
                <TransportationSubOrderItemsContainer
                    dispatchProgramId={dispatchProgramId}
                    transportationSubOrderItemId={transportationSubOrderItemId}
                    canBeEdited={canBeEdited}
                    isShow={isShow}
                    callbackSuccess={reloadTable}
                    reloadModule={reloadModule}
                    refresh={refreshOtherComponents}
                />
            </DefaultCard>
            {showingSplit ? (
                <DefaultModal
                    show={showingSplit}
                    handleClose={hideSplit}
                    title="DIVIDIR DETALLE DEL PEDIDO DE TRANSPORTE"
                    backdrop={true}
                    showFooter={false}
                >
                    <TransportationSubOrderItemSplit
                        canBeEdited={true}
                        transportationSubOrderItemId={transportationOrderItemIdSplit}
                        onSaved={() => {
                            reloadTable();
                            if (reloadModule) reloadModule();
                            hideSplit();
                            setRefreshOtherComponents(!refreshOtherComponents);
                        }}
                        onError={hideSplit}
                        onCancel={hideSplit}
                        dispatchProgramId={dispatchProgramId}
                    />
                </DefaultModal>
            ) : null}
            {showingPaymentStatusLoad ? (
                <DefaultModal
                    show={showingPaymentStatusLoad}
                    handleClose={hidePaymentStatusLoad}
                    title="Generar estados de pago"
                    backdrop={true}
                    showFooter={false}
                >
                    <PaymentStatusLoadCreate
                        dispatch_program_id={dispatchProgramId}
                        transportation_order_item_id={transportOrderItemIdEdit}
                        onSaved={() => {
                            reloadTable();
                            if (reloadModule) reloadModule();
                            hidePaymentStatusLoad();
                        }}
                        onCancel={hidePaymentStatusLoad}
                        onError={hidePaymentStatusLoad}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default DispatchProgramItemsContainer;
