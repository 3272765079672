import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import { useHistory } from 'react-router-dom';

import useGuideService from '../../../app/services/hooks/useGuideService';
import { GuideDataForm, defaultGuideDataForm } from '../../../app/models/Guide';
import { Provider } from '../../../app/models/Provider';
import { Currency } from '../../../app/models/Currency';
import { Product } from '../../../app/models/Product';
import { Carrier } from '../../../app/models/Carrier';
import { Truck } from '../../../app/models/Truck';
import { Hopper } from '../../../app/models/Hopper';
import { Unit } from '../../../app/models/Unit';
import { Place } from '../../../app/models/Place';
import GuideForm from './forms/GuideForm';

interface Props {
    transportOrderId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const GuideCreate = ({ onSaved, onCancel, onError, transportOrderId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();
    const { createGuide, storeGuide, fetchingCreateGuide, fetchingStoreGuide } = useGuideService();

    const [guide, setGuide] = useState<GuideDataForm>({
        ...defaultGuideDataForm,
        transport_order_id: transportOrderId
    });

    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [hoppers, setHoppers] = useState<Hopper[]>([]);
    const [units, setUnits] = useState<Unit[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [origins, setOrigins] = useState<Place[]>([]);
    const [destinies, setDestinies] = useState<Place[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createGuide(transportOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setCarriers(response.data.carriers);
                setTrucks(response.data.trucks);
                setHoppers(response.data.hoppers);
                setUnits(response.data.units);
                setProducts(response.data.products);
                setOrigins(response.data.origins);
                setDestinies(response.data.destinies);
                const kgUnitId =
                    response.data.units.find((unit: any) => unit.name.toLowerCase() == 'kg')?.id ??
                    0;
                setGuide({
                    ...response.data.guide,
                    unit_id: kgUnitId
                });

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando guia...');
        storeGuide(transportOrderId, guide, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateGuide ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <GuideForm
                        guide={guide}
                        setGuide={setGuide}
                        carriers={carriers}
                        trucks={trucks}
                        hoppers={hoppers}
                        units={units}
                        products={products}
                        origins={origins}
                        destinies={destinies}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreGuide} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreGuide} />
                </div>
            </div>
        </>
    );
};

export default GuideCreate;
