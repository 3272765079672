import { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../../components/LazyLoading';
import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../../contexts/AppContext';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useAfpRateService from '../../../../app/services/hooks/useAfpRateService';
import { AfpRateDataForm, defaultAfpRateDataForm } from '../../../../app/models/AfpRate';
import AfpRateForm from '../forms/AfpRateForm';
import { ValueType } from '../../../../app/models/AfpRate';

interface Props {
    afpId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const AfpRateCreate = ({ onSaved, onCancel, onError, afpId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const { createAfpRate, storeAfpRate, fetchingCreateAfpRate, fetchingStoreAfpRate } =
        useAfpRateService();

    const [afpRate, setAfpRate] = useState<AfpRateDataForm>({
        ...defaultAfpRateDataForm,
        afp_id: afpId
    });

    const [valueTypes, setValueTypes] = useState<ValueType[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createAfpRate({
            onSuccess: (response: ServiceResponse) => {
                setValueTypes(response.data.value_types);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando tarifa de Afp...');
        storeAfpRate(afpRate, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateAfpRate ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <AfpRateForm
                        afpRateForm={afpRate}
                        errorFields={errorFields}
                        setAfpRateForm={setAfpRate}
                        valueTypes={valueTypes}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreAfpRate} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreAfpRate} />
                </div>
            </div>
        </>
    );
};

export default AfpRateCreate;
