import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { Society, SocietyFilters } from '../../models/Society';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useSocietyService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetSocieties, setFetchingGetSocieties] = useState(false);
    const [fetchingShowSociety, setFetchingShowSociety] = useState(false);
    const [fetchingStoreSociety, setFetchingStoreSociety] = useState(false);
    const [fetchingEditSociety, setFetchingEditSociety] = useState(false);
    const [fetchingCreateSociety, setFetchingCreateSociety] = useState(false);
    const [fetchingUpdateSociety, setFetchingUpdateSociety] = useState(false);
    const [fetchingDeleteSociety, setFetchingDeleteSociety] = useState(false);
    const [fetchingGetSociety, setFetchingGetSociety] = useState(false);
    const [fetchingActiveSociety, setFetchingActiveSociety] = useState(false);
    const getSocieties = (societyFilters: SocietyFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(societyFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SOCIETIES.GET_SOCIETIES}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetSocieties
        });
    };

    const activeSociety = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            body: { active: active },
            url: EndPoints.SOCIETIES.ACTIVE_SOCIETY.replace(':id', id.toString()),
            setFetching: setFetchingActiveSociety
        });
    };

    const showSociety = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SOCIETIES.SHOW_SOCIETY.replace(':id', id.toString()),
            setFetching: setFetchingShowSociety
        });
    };

    const storeSociety = (society: Society, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SOCIETIES.STORE_SOCIETY,
            body: society,
            setFetching: setFetchingStoreSociety
        });
    };

    const editSociety = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SOCIETIES.EDIT_SOCIETY.replace(':id', id.toString()),
            setFetching: setFetchingEditSociety
        });
    };

    const createSociety = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SOCIETIES.CREATE_SOCIETY,
            setFetching: setFetchingCreateSociety
        });
    };

    const updateSociety = (id: number, society: Society, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.SOCIETIES.UPDATE_SOCIETY.replace(':id', id.toString()),
            body: society,
            setFetching: setFetchingUpdateSociety
        });
    };

    const getSociety = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SOCIETIES.GET_SOCIETY.replace(':id', id.toString()),
            setFetching: setFetchingGetSociety
        });
    };

    const deleteSociety = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SOCIETIES.DELETE_SOCIETY.replace(':id', id.toString()),
            setFetching: setFetchingDeleteSociety
        });
    };

    return {
        fetchingGetSocieties,
        fetchingShowSociety,
        fetchingStoreSociety,
        fetchingEditSociety,
        fetchingUpdateSociety,
        fetchingDeleteSociety,
        fetchingGetSociety,
        fetchingCreateSociety,
        fetchingActiveSociety,
        activeSociety,
        getSocieties,
        showSociety,
        storeSociety,
        createSociety,
        editSociety,
        updateSociety,
        getSociety,
        deleteSociety
    };
};

export default useSocietyService;
