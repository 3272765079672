import React from 'react';
import { JobTitle, JobTitleFilters } from '../../../../app/models/JobTitle';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import JobTitleSwitchChange from '../JobTitleSwitchChange';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import JobTitleFilter from './JobTitleFilter';

interface Props {
    jobTitles: JobTitle[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number, name: string) => void;
    filter: JobTitleFilters;
    setFilter: (filter: JobTitleFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const JobTitleDataTable = ({
    jobTitles,
    totalRows,
    loading = false,
    edit,
    active,
    show,
    destroy,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: JobTitle, index: any, column: any) => <span className="">{row.id}</span>
        },
        {
            name: 'Puesto',
            selector: (row: any) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: JobTitle, index: any, column: any) => <span className="">{row.name}</span>
        },
        {
            name: 'Descripción',
            selector: (row: any) => row.description,
            sortable: true,
            sortField: 'description',
            cell: (row: JobTitle, index: any, column: any) => (
                <span className="">{row.description}</span>
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: JobTitle, index: any, column: any) => (
                <JobTitleSwitchChange
                    checkValue={row.is_active ?? true}
                    JobTitleId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: JobTitle, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1, row.name)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <div>
            <div className="row mt-3">
                <div className="col">
                    <JobTitleFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={jobTitles}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </div>
    );
};

export default JobTitleDataTable;
