import React, { useEffect, useMemo, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { GroupDataForm } from '../../../../app/models/Group';
import ToggleBox from '../../../../components/ToggleBox';
import { ContentType } from '../../../../app/models/ContentType';
import { Accordion } from 'react-bootstrap';

type Props = {
    groupDataForm: GroupDataForm;

    contentTypes: ContentType[];
    setGroupDataForm: (groupDataForm: GroupDataForm) => void;
    errorFields?: any;
};

const GroupForm = ({ groupDataForm, setGroupDataForm, errorFields, contentTypes }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [searchTerm, setSearchTerm] = useState('');

    const filterGroups = (contentType: ContentType) => {
        return contentType.name.toLowerCase().includes(searchTerm.toLowerCase());
    };
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const filteredContentTypes = useMemo(() => {
        if (!contentTypes) return [];
        return contentTypes.filter(filterGroups);
    }, [contentTypes, searchTerm]);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const isSelectedPermission = (id: number) => {
        return !!groupDataForm.permissions.find((s) => s === id);
    };

    const setSelectedPermissions = async (id: number) => {
        const selection = groupDataForm.permissions.find((s) => s === id);

        if (!selection) {
            setGroupDataForm({
                ...groupDataForm,
                permissions: [...groupDataForm.permissions, id]
            });
        } else {
            setGroupDataForm({
                ...groupDataForm,
                permissions: groupDataForm.permissions.filter((s) => s !== id)
            });
        }
    };
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setGroupDataForm({ ...groupDataForm, [name]: value });
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <div className="row">
                <div className="mb-2 row needs-validation">
                    <label className="col-md-12 col-form-label">Nombre</label>
                    <div className="col-md-12">
                        <input
                            name="name"
                            id="name"
                            type="text"
                            className={`form-control ${fieldHasError('name')}`}
                            value={groupDataForm.name}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('name')}
                            placeholder="Ingrese el nombre del rol"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('name')}
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <label htmlFor="name" className="form-label">
                        Permisos
                    </label>
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Buscar grupos de permisos"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <Accordion>
                        {contentTypes.length != 0 ? (
                            filteredContentTypes.length === 0 ? (
                                <div className="mt-2 text-danger fw-bold">
                                    No se encontraron coincidencias
                                </div>
                            ) : (
                                filteredContentTypes.map((group, index) => {
                                    return (
                                        <Accordion.Item
                                            onFocus={() => onFocusRemove('permissions')}
                                            eventKey={`group${index}`}
                                            key={`group${index}`}
                                        >
                                            <Accordion.Header>
                                                <span className="text-uppercase">{group.name}</span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="row pt-2">
                                                    {group.permission_set &&
                                                        group.permission_set.map(
                                                            (permission, index) => {
                                                                return (
                                                                    <ToggleBox
                                                                        key={`permission${index}`}
                                                                        onClick={() =>
                                                                            setSelectedPermissions(
                                                                                permission.id
                                                                            )
                                                                        }
                                                                        isSelected={isSelectedPermission(
                                                                            permission.id
                                                                        )}
                                                                        title=""
                                                                        name={String(
                                                                            permission.description ??
                                                                                ''
                                                                        )}
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })
                            )
                        ) : (
                            <div className="mt-2 text-danger fw-bold">
                                No hay permisos disponibles
                            </div>
                        )}
                    </Accordion>
                </div>
            </div>
        </form>
    );
};

export default GroupForm;
