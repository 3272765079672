import React, { useContext, useEffect } from 'react';
import useRut from '../../../../hooks/useRut';
import { TransportOrderShowDataForm } from '../../../../app/models/TransportOrder';
import { AuthContext } from '../../../../contexts/AuthContext';

interface Props {
    transportOrderShowForm: TransportOrderShowDataForm;
    showGeneralInfo?: boolean;
    showSellInfo?: boolean;
}

const TransportOrderShowForm = ({
    transportOrderShowForm,
    showSellInfo = true,
    showGeneralInfo = true
}: Props) => {
    const { auth } = useContext(AuthContext);

    return (
        <>
            {showGeneralInfo && (
                <>
                    {auth &&
                    auth?.roles &&
                    (auth?.roles.includes('Administrador') ||
                        !auth?.roles.includes('Conductor')) ? (
                        <div className="mb-2 row">
                            <div className="col-md-12">
                                <label className="col-form-label">Orden de Trabajo</label>
                                <div>
                                    <a
                                        href={`/work-orders/${transportOrderShowForm.work_order_id}/edit`}
                                    >
                                        #{transportOrderShowForm.work_order_id}
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Conductor</label>
                            <input
                                type="text"
                                name="carrier"
                                id="carrier"
                                className="form-control"
                                value={transportOrderShowForm.carrier}
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Tracto</label>
                            <input
                                type="text"
                                name="truck"
                                id="truck"
                                className="form-control"
                                value={transportOrderShowForm.truck}
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Semi remolque</label>
                            <input
                                type="text"
                                name="hopper"
                                id="hopper"
                                className="form-control"
                                value={transportOrderShowForm.hopper}
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Tramo</label>
                            <input
                                type="text"
                                name="route_segment"
                                id="route_segment"
                                className="form-control"
                                value={transportOrderShowForm.route_segment}
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Fecha de programa</label>
                            <input
                                type="text"
                                name="program_date"
                                id="program_date"
                                className="form-control"
                                value={transportOrderShowForm.program_date}
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Hora de programa</label>
                            <input
                                type="text"
                                name="program_time"
                                id="program_time"
                                className="form-control"
                                value={transportOrderShowForm.program_time}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <hr />
                </>
            )}

            {transportOrderShowForm.is_type_sell && showSellInfo ? (
                <>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label fs-5">Venta</label>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Cliente</label>
                            <input
                                type="text"
                                name="client"
                                id="client"
                                className="form-control"
                                value={transportOrderShowForm.client}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Producto</label>
                            <input
                                type="text"
                                name="product"
                                id="product"
                                className="form-control"
                                value={transportOrderShowForm.product}
                                disabled={true}
                            />
                        </div>
                    </div>
                    {auth && auth?.roles && !auth?.roles.includes('Conductor') ? (
                        <div className="mb-2 row">
                            <label className="col-md-12 col-form-label">Tarifa de venta</label>
                            <div className="col-md-12">
                                <div className="row g-1">
                                    <div className="col-6">
                                        <input
                                            type="string"
                                            name="rate"
                                            id="rate"
                                            className="form-control"
                                            value={transportOrderShowForm.rate}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <input
                                            type="string"
                                            name="currency_rate"
                                            id="currency_rate"
                                            className="form-control"
                                            value={transportOrderShowForm.currency_rate}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <input
                                            type="string"
                                            name="unit_rate"
                                            id="unit_rate"
                                            className="form-control"
                                            value={transportOrderShowForm.unit_rate}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </>
            ) : (
                showSellInfo && (
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label fs-5">Desplazamiento</label>
                        </div>
                    </div>
                )
            )}
        </>
    );
};

export default TransportOrderShowForm;
