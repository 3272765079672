import React from 'react';
import { TruckFilters } from '../../../../app/models/Truck';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: TruckFilters;
    setFilter: (filter: TruckFilters) => void;
    onSearch: () => void;
};

const TruckFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };
    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="patent">
                            Patente
                        </label>
                        <input
                            title="Patente"
                            type="text"
                            id="patent"
                            name="patent"
                            className="form-control form-control-sm"
                            value={filter.patent ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="year">
                            Año
                        </label>
                        <input
                            title="Año"
                            type="number"
                            id="year"
                            name="year"
                            className="form-control form-control-sm"
                            value={filter.year ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="brand">
                            Marca
                        </label>
                        <input
                            title="Año"
                            type="text"
                            id="brand"
                            name="brand"
                            className="form-control form-control-sm"
                            value={filter.brand ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="vehicle_model">
                            Modelo
                        </label>
                        <input
                            title="Año"
                            type="text"
                            id="vehicle_model"
                            name="vehicle_model"
                            className="form-control form-control-sm"
                            value={filter.vehicle_model ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="status">
                            Estado
                        </label>
                        <select
                            name="status"
                            className="form-control form-select-sm"
                            value={filter.status ?? ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'OPERATIVO'} value="OPERATIVO">
                                Operativo
                            </option>
                            <option key={'EN MANTENIMIENTO'} value="EN MANTENIMIENTO">
                                En mantenimiento
                            </option>
                            <option key={'NO OPERATIVO'} value="NO OPERATIVO">
                                No operativo
                            </option>
                            <option key={'EN TALLER'} value="EN TALLER">
                                En taller
                            </option>
                        </select>
                    </div>
                </div>

                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TruckFilter;
