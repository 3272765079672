import React from 'react';
import { TravelExpenseFilters } from '../../../../app/models/TravelExpense';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: TravelExpenseFilters;
    setFilter: (filter: TravelExpenseFilters) => void;
    onSearch: () => void;
};

const TravelExpensesFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="row">
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="issue_date_first">
                                Presupuesto
                            </label>
                            <input
                                title="Presupuesto"
                                type="text"
                                id="budget"
                                name="budget"
                                className="form-control form-control-sm"
                                value={filter.budget}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>

                    <div className='col-md-auto'>
                        <div style={{marginTop: '22px'}}>
                            <ButtonSearchFilter callbackSearch={onSearch}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TravelExpensesFilter;
