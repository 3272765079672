import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouterParams } from '../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { AuthContext } from '../../../contexts/AuthContext';
import useWorkOrderService from '../../../app/services/hooks/useWorkOrderService';
import {
    defaultWorkOrder,
    WorkOrderDataForm,
    WorkOrderStatusRequestDataForm
} from '../../../app/models/WorkOrder';
import { Society } from '../../../app/models/Society';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { Truck } from '../../../app/models/Truck';
import DefaultCard from '../../../components/default/DefaultCard';
import WorkOrderForm from '../components/forms/WorkOrderForm';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import TransportOrderContainer from '../components/TransportOrdersContainer';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useWorkOrderStatusHistoryService from '../../../app/services/hooks/useWorkOrderStatusHistoryService';
import ChangeStatus from '../components/TransportOrderChangeStatus';

const WorkOrderEdit = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Ordenes de trabajo',
            url: '/work-orders',
            isActive: false
        },
        {
            name: 'Editar orden de trabajo #' + id,
            url: '/work-orders/' + id + '/edit',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const {
        editWorkOrder,
        fetchingEditWorkOrder,
        fetchingShowWorkOrder,
        fetchingUpdateWorkOrder,
        updateWorkOrder,
        changeStatusWorkOrder,
        fetchingChangeStatusWorkOrder,
        hasAllFinishedStatuses
    } = useWorkOrderService();

    const { getAllWorkOrderStatusHistoriesByWorkOrder } = useWorkOrderStatusHistoryService();

    const [workOrderForm, setWorkOrderForm] = useState<WorkOrderDataForm>(defaultWorkOrder);

    const [errorFields, setErrorFields] = useState<any>();

    const [societies, setSocieties] = useState<Society[]>([]);
    const [trucks, setTrucks] = useState<Truck[]>([]);

    const [workOrderStatusHistories, setWorkOrderStatusHistories] = useState<any[]>([]);

    const [workOrderStatus, setWorkOrderStatus] = useState<any[]>([]);
    const [statuses, setStatuses] = useState<WorkOrderStatusRequestDataForm[]>([]);

    useEffect(() => {
        reloadModule();
    }, [id]);

    const edit = (id: number) => {
        editWorkOrder(id, {
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setTrucks(response.data.trucks);
                setWorkOrderForm(response.data.work_order);
                setStatuses(response.data.work_order_statuses);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });

        getAllWorkOrderStatusHistoriesByWorkOrder(Number(id), {
            onSuccess: (response: ServiceResponse) => {
                setWorkOrderStatus(response.data.statuses);

                setWorkOrderStatusHistories(response.data.transport_order_status_histories);
                if (hideLoading) hideLoading();
            }
        });
    };

    const reloadModule = () => {
        edit(parseInt(id));
    };

    const update = () => {
        if (workOrderForm != null) {
            if (showLoading) showLoading('loading', 'Actualizando...');
            updateWorkOrder(Number(workOrderForm?.id), workOrderForm, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    reloadModule();
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                    toast.error(response.message);
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    const requestChangeStatus = (status: string) => {
        if (showLoading) showLoading('loading', 'Cargando...');
        hasAllFinishedStatuses(parseInt(id), status, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                changeStatus(status, response.message);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                toast.error(response.message);
            }
        });
    };

    const changeStatus = (status: string, message: string) => {
        const _text = `Está a punto de cambiar el estado de la orden de trabajo #${id}.${
            message ?? ''
        }`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, cambiar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        changeStatusWorkOrder(Number(id), status, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                reloadModule();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La orden trabajo no ha cambiado el estado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection={`Editar Orden de trabajo #${id}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditWorkOrder ? (
                            <>
                                <WorkOrderForm
                                    workOrderForm={workOrderForm}
                                    setWorkOrderForm={setWorkOrderForm}
                                    errorFields={errorFields}
                                    societies={societies}
                                    trucks={trucks}
                                />
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdateWorkOrder}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            // )
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6">
                    <DefaultCard>
                        <ChangeStatus
                            status={String(workOrderForm?.status)}
                            statuses={statuses}
                            requestChangeStatus={requestChangeStatus}
                            fetchingChangeStatus={fetchingChangeStatusWorkOrder}
                        />
                    </DefaultCard>
                </div>
            </div>

            {!fetchingEditWorkOrder && !fetchingShowWorkOrder ? (
                <>
                    <TransportOrderContainer
                        workOrderId={parseInt(id)}
                        truckId={Number(workOrderForm.truck_id)}
                    />
                </>
            ) : null}
        </>
    );
};

export default WorkOrderEdit;
