import React, { useContext, useEffect, useState } from 'react';
import useTransportationOrderItemService from '../../../app/services/hooks/useTransportationOrderItemService';
import { TransportationOrderItemShowDataForm } from '../../../app/models/TransportationOrderItem';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import TransportationOrderItemShowForm from './forms/TransportationOrderItemShowForm';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';

interface Props {
    transportationOrderItemId: number;
    onError?: (message?: string) => void;
}

const TransportationOrderItemShow = ({ transportationOrderItemId, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingShowTransportationOrderItem, showTransportationOrderItem } =
        useTransportationOrderItemService();

    const [transportationOrderItemShow, setTransportationOrderItemShow] =
        useState<TransportationOrderItemShowDataForm>({});

    useEffect(() => {
        if (transportationOrderItemId) {
            show();
        }
    }, [transportationOrderItemId]);

    const show = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        showTransportationOrderItem(transportationOrderItemId, {
            onSuccess: (response: ServiceResponse) => {
                setTransportationOrderItemShow(response.data.transportation_order_item);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                onError && onError(response.message);
            }
        });
    };

    return !fetchingShowTransportationOrderItem ? (
        <TransportationOrderItemShowForm
            transportationOrderItemShowForm={transportationOrderItemShow}
        />
    ) : (
        <LazyLoading height={300} />
    );
};

export default TransportationOrderItemShow;
