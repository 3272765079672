import { JobTitleDataForm } from '../../../app/models/JobTitle';
import JobTitleForm from './forms/JobTitleForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Group } from '../../../app/models/Group';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    groups: Group[];
    jobTitle: JobTitleDataForm;
    setJobTitle: (jobTitle: JobTitleDataForm) => void;
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const JobTitleFormContainer = ({
    fetching,
    action,
    cancel,
    groups,
    jobTitle,
    setJobTitle,
    errorFields = null,
    setErrorFields
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <JobTitleForm
                        jobTitleDataForm={jobTitle}
                        setJobTitleDataForm={setJobTitle}
                        groups={groups}
                        errorFields={errorFields}
                        setErrorFields={setErrorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default JobTitleFormContainer;
