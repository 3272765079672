import React, { useContext, useEffect, useRef, useState } from 'react';
import { Product } from '../../../app/models/Product';
import { Place } from '../../../app/models/Place';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import { Service } from '../../../app/models/Service';
import useTransportationQuoteDetailService from '../../../app/services/hooks/useTransportationQuoteDetailService';
import {
    QuoteDataForm,
    TransportationQuoteDetailDataForm,
    ActionResquest,
    defaultTransportationQuoteDetailDataForm,
    defaultEditTransportationQuoteDetailDataForm
} from '../../../app/models/Quote';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import QuoteDetailForm from './forms/QuoteDetailForm';
import { RouteSegment } from '../../../app/models/RouteSegment';
import { QuoteRouteSegment } from '../../../app/models/QuoteRouteSegment';
import { toast } from 'react-toastify';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    quoteId: number;
    quoteDetailId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    quoteForm: QuoteDataForm;
    setQuoteForm: (quoteForm: QuoteDataForm) => void;
    setQuoteRouteSegmentTable: (quoteRouteSegment: QuoteRouteSegment[]) => void;
    reloadQuoteRouteSegmentTable: () => void;
}

const QuoteDetailEdit = ({
    quoteId,
    quoteDetailId,
    onSaved,
    onCancel,
    onError,
    quoteForm,
    setQuoteForm
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        editTransportationQuoteDetail,
        updateTransportationQuoteDetail,
        fetchingUpdateTransportationQuoteDetail,
        getQuoteRouteSegmentsToQuoteDetail,
        fetchingGetQuoteRouteSegmentsToQuoteDetail
    } = useTransportationQuoteDetailService();

    const { fetchingShowRsToTransportationQuoteDetail, showRsToTransportationQuoteDetail } =
        useQuoteRouteSegmentService();

    const [errorFields, setErrorFields] = useState<any>();

    const [products, setProducts] = useState<Product[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [origins, setOrigins] = useState<Place[]>([]);
    const [destinies, setDestinies] = useState<Place[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [routeSegments, setRouteSegments] = useState<RouteSegment[]>([]);
    const [quoteRouteSegments, setQuoteRouteSegments] = useState<QuoteRouteSegment[]>([]);
    const [action, setAction] = useState<ActionResquest>({});
    const [disableUpdate, setDisableUpdate] = useState<boolean>(false);
    const routeSegmentIdSelected = useRef<number | undefined>(0);
    const routeSegmentIdInstance = useRef<number | undefined>(0);
    const [quoteDetail, setQuoteDetail] = useState<TransportationQuoteDetailDataForm>({
        ...defaultTransportationQuoteDetailDataForm,
        ['quote_id']: quoteId,
        ['route_segment_id']: undefined,
        ['agreed_distance']: 0,
        ['service_id']: 0,
        ['product_id']: 0,
        ['quantity']: 0,
        ['first_dispatch_date']: '',
        ['description']: ''
    });

    useEffect(() => {
        edit();
    }, [quoteDetailId]);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando posición de oferta...');

        editTransportationQuoteDetail(quoteDetailId, {
            onSuccess: (response: ServiceResponse) => {
                setProducts(response.data.products);
                setQuantityUnits(response.data.quantity_units);
                setCurrencies(response.data.currencies);
                setOrigins(response.data.origins);
                setDestinies(response.data.destinies);
                setServices(response.data.services);
                setRouteSegments(response.data.route_segments);
                setAction(response.data.actions);
                if (!response.data.can_update) {
                    setDisableUpdate(true);
                }

                routeSegmentIdSelected.current =
                    response.data.transportation_quote_detail.route_segment_id;
                routeSegmentIdInstance.current =
                    response.data.transportation_quote_detail.route_segment_id;
                setQuoteDetail({
                    ...quoteDetail,
                    ...response.data.transportation_quote_detail
                });

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (quoteDetail.route_segment_id == undefined) {
            routeSegmentIdSelected.current = undefined;
        }

        if (
            quoteDetail.route_segment_id &&
            quoteDetail.route_segment_id != routeSegmentIdSelected.current
        ) {
            showRsToTransportationQuoteDetail(quoteDetailId, quoteDetail.route_segment_id, {
                onSuccess: (response: ServiceResponse) => {
                    setQuoteRouteSegments([]);
                    setErrorFields(undefined);
                    routeSegmentIdSelected.current = quoteDetail.route_segment_id ?? 0;
                    if (response.data.instance_data) {
                        routeSegmentIdInstance.current = quoteDetail.route_segment_id;
                        setQuoteDetail({
                            ...quoteDetail,
                            ...defaultTransportationQuoteDetailDataForm,
                            origin_id: response.data.instance_data.origin_id,
                            destiny_id: response.data.instance_data.destiny_id,
                            distance: response.data.instance_data.distance,
                            agreed_distance: response.data.instance_data.agreed_distance,
                            rate: response.data.instance_data.rate,
                            currency_id: response.data.instance_data.currency_id,
                            quantity_unit_id: response.data.instance_data.quantity_unit_id,
                            rate_runner: response.data.instance_data.rate_runner,
                            currency_rate_runner_id:
                                response.data.instance_data.currency_rate_runner_id,
                            quantity_unit_rate_runner_id:
                                response.data.instance_data.quantity_unit_rate_runner_id,
                            external_rate: response.data.instance_data.external_rate,
                            currency_external_rate_id:
                                response.data.instance_data.currency_external_rate_id,
                            unit_external_rate_id:
                                response.data.instance_data.unit_external_rate_id,
                            quote_route_segment_id:
                                response.data.instance_data.quote_route_segment_id
                        });
                    } else {
                        setQuoteDetail({
                            ...quoteDetail,
                            ...defaultTransportationQuoteDetailDataForm,
                            distance: response.data.route_segment.distance,
                            rate: response.data.route_segment.rate,
                            currency_id: response.data.route_segment.currency_id,
                            quantity_unit_id: response.data.route_segment.unit_id,
                            rate_runner: response.data.route_segment.rate_runner,
                            currency_rate_runner_id:
                                response.data.route_segment.currency_rate_runner_id,
                            quantity_unit_rate_runner_id:
                                response.data.route_segment.quantity_unit_rate_runner_id,
                            external_rate: response.data.route_segment.external_rate,
                            currency_external_rate_id:
                                response.data.route_segment.currency_external_rate_id,
                            unit_external_rate_id:
                                response.data.route_segment.unit_external_rate_id,
                            action_to_do: action.first_time
                        });
                    }
                },
                onError: () => {
                    setQuoteDetail({
                        ...quoteDetail,
                        route_segment_id: routeSegmentIdSelected.current
                    });
                }
            });
        }
    }, [quoteDetail.route_segment_id]);

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando posición de oferta...');
        updateTransportationQuoteDetail(quoteDetailId, quoteDetail, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                if (response.data.continue) {
                    questionAction(response.message);

                    return;
                }

                setQuoteForm({ ...quoteForm, ...response.data.quote_date });
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });

                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const questionAction = (message: string) => {
        useReactConfirmAlert().requestConfirmationMulti({
            title: '¿Qué desea hacer?',
            message: message,
            buttons: {
                confirmButton: {
                    label: 'Agregar opción',
                    onClick: () => {
                        quoteDetail.action_to_do = action.add;
                        setQuoteDetail({ ...quoteDetail });
                        update();
                    }
                },
                updateButton: {
                    label: 'Actualizar opción',
                    onClick: () => {
                        console.log('traer las opciones de ruta');
                        if (showLoading) showLoading('loading', 'Consultando opciones de ruta...');

                        getQuoteRouteSegmentsToQuoteDetail(
                            quoteDetail.quote_id!,
                            quoteDetail.route_segment_id!,
                            {
                                onSuccess: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();
                                    setQuoteRouteSegments(response.data.quote_route_segments);
                                    setQuoteDetail({ ...quoteDetail, action_to_do: action.update });
                                },
                                onError: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();
                                    toast.error(response.message);
                                }
                            }
                        );
                    }
                },

                cancelButton: {
                    label: 'cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'No se ha ejecutado ninguna acción.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <QuoteDetailForm
                        products={products}
                        quantityUnits={quantityUnits}
                        currencies={currencies}
                        origins={origins}
                        destinies={destinies}
                        services={services}
                        routeSegments={routeSegments}
                        quoteDetailForm={quoteDetail}
                        setQuoteDetailForm={setQuoteDetail}
                        errorFields={errorFields}
                        quoteRouteSegments={quoteRouteSegments}
                        fetching={fetchingShowRsToTransportationQuoteDetail}
                        disableUpdate={disableUpdate}
                        routeSegmentIdInstance={routeSegmentIdInstance.current}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateTransportationQuoteDetail}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={update}
                        locked={fetchingUpdateTransportationQuoteDetail}
                    />
                </div>
            </div>
        </>
    );
};

export default QuoteDetailEdit;
