import { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import LazyLoading from '../../../../components/LazyLoading';
import useAfpRateService from '../../../../app/services/hooks/useAfpRateService';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../contexts/AppContext';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import AfpRateForm from '../forms/AfpRateForm';
import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import { ValueType } from '../../../../app/models/AfpRate';
import { AfpRateDataForm, defaultAfpRateDataForm } from '../../../../app/models/AfpRate';

interface Props {
    afpRateId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const AfpRateEdit = ({ afpRateId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditAfpRate, editAfpRate, fetchingUpdateAfpRate, updateAfpRate } =
        useAfpRateService();
    const [afpRate, setAfpRate] = useState<AfpRateDataForm>(defaultAfpRateDataForm);
    const [valueTypes, setValueTypes] = useState<ValueType[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando afp...');
        editAfpRate(afpRateId, {
            onSuccess: (response: ServiceResponse) => {
                setAfpRate(response.data.afp_rate);
                setValueTypes(response.data.value_types);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando tarifa de afp...');
        updateAfpRate(afpRateId, afpRate, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditAfpRate ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <AfpRateForm
                        afpRateForm={afpRate}
                        errorFields={errorFields}
                        valueTypes={valueTypes}
                        setAfpRateForm={setAfpRate}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingUpdateAfpRate} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateAfpRate} />
                </div>
            </div>
        </>
    );
};

export default AfpRateEdit;
