
export interface BonusRateDataForm {
    id?: number;
    bonus_id?: number;
    value?: number;
    currency?: string;
    formula?: string;
    currency_id?: number;
    valid_from?: string;
    valid_until?: string;
    bonus_type?: string;
    is_taxable?: boolean
}

export interface BonusRateFilters {
    bonus_rate_id?: number | null;

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface ValueType {
    id: number | string;
    name: number | string;
}

export const defaultBonusRateDataForm: BonusRateDataForm = {
    bonus_type: '',
    is_taxable: false
};