import React from 'react';
import useNotificationService from '../../../app/services/hooks/useNotificationService';
import { momentParseDateTime } from '../../../helpers';
import { Notification } from '../../../app/models/Notification';

type Props = {
    notification: Notification;
};

const NotificationCard = ({ notification }: Props) => {
    const { setNotificationAsViewed } = useNotificationService();
    // const history = useHistory();
    const goToNotification = () => {
        setNotificationAsViewed(notification.id ?? -1);
        window.location.replace('/notifications');
    };

    return (
        <div className="card mb-1">
            <div className="card-body">
                <div className="row">
                    {!notification.is_viewed && (
                        <div className="col-md-auto d-flex">
                            <div className="event-list my-auto active">
                                <div className="event-timeline-dot text-info">
                                    <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-md">
                        <h5 className="text-truncate font-size-14 pointer">
                            <div className="text-dark" onClick={goToNotification}>
                                {notification.title}
                            </div>
                        </h5>
                        <p className="text-muted mb-0">{notification.description ?? ''}</p>
                    </div>
                    <div className="col-md-auto pt-2 font-size-10 d-flex">
                        <div className="mt-auto">
                            <i className="mdi mdi-clock-outline" />{' '}
                            {momentParseDateTime(
                                notification.created_at ? notification.created_at.toString() : ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationCard;
