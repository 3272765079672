import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { Holiday, HolidayFilters, HolidayDataForm } from '../../models/Holiday';
import { EndPoints } from '../EndPoints';

const useHolidayService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingStoreHoliday, setFetchingStoreHoliday] = useState(false);
    const [fetchingGetHolidays, setFetchingGetHolidays] = useState(false);
    const [fetchingCreateHoliday, setFetchingCreateHoliday] = useState(false);
    const [fetchingDeleteHoliday, setFetchingDeleteHoliday] = useState(false);
    const [fetchingEditHoliday, setFetchingEditHoliday] = useState(false);
    const [fetchingUpdateHoliday, setFetchingUpdateHoliday] = useState(false);
    const [fetchingActiveHoliday, setFetchingActiveHoliday] = useState(false);

    const getHolidays = (HolidayFilters: HolidayFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(HolidayFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.HOLIDAYS.GET_HOLIDAYS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetHolidays
        });
    };

    const getHolidaysByDate = (date: string, view: string, events: ServiceEvents = {}) => {
        const url = `${EndPoints.HOLIDAYS.GET_HOLIDAYS_BY_DATE}?date=${date}&view=${view}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetHolidays
        });
    };

    const storeHoliday = (holiday: Holiday, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.HOLIDAYS.STORE_HOLIDAYS,
            body: holiday,
            setFetching: setFetchingStoreHoliday
        });
    };

    const createHoliday = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.HOLIDAYS.CREATE_HOLIDAY,
            setFetching: setFetchingCreateHoliday
        });
    };

    const editHoliday = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.HOLIDAYS.EDIT_HOLIDAY.replace(':id', id.toString()),
            setFetching: setFetchingEditHoliday
        });
    };

    const updateHoliday = (id: number, holidays: HolidayDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.HOLIDAYS.UPDATE_HOLIDAY.replace(':id', id.toString()),
            body: holidays,
            setFetching: setFetchingUpdateHoliday
        });
    };

    const deleteHoliday = (holidayId: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.HOLIDAYS.DELETE_HOLIDAY.replace(':id', holidayId.toString()),
            setFetching: setFetchingDeleteHoliday
        });
    };

    const activeHoliday = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.HOLIDAYS.ACTIVE_HOLIDAY.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveHoliday
        });
    };

    return {
        fetchingStoreHoliday,
        fetchingGetHolidays,
        fetchingCreateHoliday,
        fetchingDeleteHoliday,
        fetchingEditHoliday,
        fetchingUpdateHoliday,
        fetchingActiveHoliday,
        storeHoliday,
        createHoliday,
        getHolidays,
        editHoliday,
        updateHoliday,
        deleteHoliday,
        activeHoliday,
        getHolidaysByDate
    };
};

export default useHolidayService;
