import React, { useEffect, useRef, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Client } from '../../../../app/models/Client';
import {
    TransportOrderDataForm,
    TransportOrderOptionTrip
} from '../../../../app/models/TransportOrder';
import { RouteSegment } from '../../../../app/models/RouteSegment';
import { Product } from '../../../../app/models/Product';
import { Carrier } from '../../../../app/models/Carrier';
import { Worker } from '../../../../app/models/Workers';
import { Unit } from '../../../../app/models/Unit';
import { Currency } from '../../../../app/models/Currency';
import { Hopper } from '../../../../app/models/Hopper';
import { Truck } from '../../../../app/models/Truck';
import { OptionTrip } from '../../../../app/shared/enums';
import LazyLoading from '../../../../components/LazyLoading';

interface Props {
    transportOrderForm: TransportOrderDataForm;
    setTransportOrderForm: (transportOrder: TransportOrderDataForm) => void;
    clients: Client[];
    carriers: Worker[];
    trucks: Truck[];
    hoppers: Hopper[];
    currenciesExternalRate: Currency[];
    unitsExternalRate: Unit[];
    currenciesCommissionRate: Currency[];
    unitsCommissionRate: Unit[];
    route_segments: RouteSegment[];
    inverseRouteSegments: RouteSegment[];
    products: Product[];
    optionTrips: TransportOrderOptionTrip[];
    fetchingRouteSegment: boolean;
    isUpdate?: boolean;
    errorFields?: any;
}

const TransportOrderForm = ({
    transportOrderForm,
    setTransportOrderForm,
    clients,
    carriers,
    trucks,
    hoppers,
    currenciesExternalRate,
    unitsExternalRate,
    currenciesCommissionRate,
    unitsCommissionRate,
    route_segments,
    inverseRouteSegments,
    products,
    optionTrips,
    fetchingRouteSegment,
    errorFields,
    isUpdate = false
}: Props) => {
    console.log(transportOrderForm);
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } =
        useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        setTransportOrderForm({ ...transportOrderForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if (name === 'option_trip') {
            if (value === OptionTrip.ONE_WAY) {
                setErrors({
                    ...errors,
                    return_program_date: '',
                    return_program_time: '',
                    return_route_segment_id: ''
                });
            }
            setTransportOrderForm({ ...transportOrderForm, ...obj });
        } else {
            setTransportOrderForm({ ...transportOrderForm, ...obj });
        }
    };

    if (!transportOrderForm) return null;

    const handleCheckChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        setTransportOrderForm({
            ...transportOrderForm,
            is_type_sell: !transportOrderForm.is_type_sell
        });

        if (!transportOrderForm.is_type_sell) {
            setErrors({
                ...errors,
                client_id: '',
                product_id: ''
            });
        }
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Conductor</label>
                    <SelectTwo
                        name="worker_id"
                        id="worker_id"
                        inputValue={transportOrderForm.worker_id}
                        hasError={fieldHasError('worker_id') !== ''}
                        options={SelectTwoMapperOptions(carriers)}
                        onChange={(value: any) => handleChangeSelectTwo('worker_id', value.value)}
                        placeholder={'seleccione transporte'}
                        onFocus={() => onFocusRemove('worker_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('worker_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">tracto</label>
                    <SelectTwo
                        name="truck_id"
                        id="truck_id"
                        inputValue={transportOrderForm.truck_id}
                        hasError={fieldHasError('truck_id') !== ''}
                        options={SelectTwoMapperOptions(trucks)}
                        disabled={true}
                        placeholder={'seleccione tracto'}
                        onFocus={() => onFocusRemove('truck_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('truck_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Semi Remolque</label>
                    <SelectTwo
                        name="hopper_id"
                        id="hopper_id"
                        inputValue={transportOrderForm.hopper_id}
                        hasError={fieldHasError('hopper_id') !== ''}
                        options={SelectTwoMapperOptions(hoppers)}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('hopper_id', value ? value.value : null)
                        }
                        placeholder={'seleccione Semi Remolque'}
                        onFocus={() => onFocusRemove('hopper_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('hopper_id')}
                    </div>
                </div>
            </div>
            {!isUpdate && (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Opción de viaje</label>
                        <SelectTwo
                            name="option_trip"
                            id="option_trip"
                            inputValue={transportOrderForm.option_trip}
                            hasError={fieldHasError('option_trip') !== ''}
                            options={SelectTwoMapperOptions(optionTrips)}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('option_trip', value.value)
                            }
                            placeholder={'seleccione op. viaje'}
                            onFocus={() => onFocusRemove('option_trip')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('option_trip')}
                        </div>
                    </div>
                </div>
            )}
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Opción ruta</label>
                    <SelectTwo
                        name="route_segment_id"
                        id="route_segment_id"
                        inputValue={transportOrderForm.route_segment_id}
                        hasError={fieldHasError('route_segment_id') !== ''}
                        options={SelectTwoMapperOptions(route_segments)}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('route_segment_id', value.value)
                        }
                        placeholder={'seleccione tramo'}
                        onFocus={() => onFocusRemove('route_segment_id')}
                        disabled={
                            transportOrderForm.has_dpi ||
                            transportOrderForm.transportation_order_item_id
                                ? true
                                : false
                        }
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('route_segment_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Fecha de programa</label>

                    <input
                        type="date"
                        className={`form-control ${fieldHasError('program_date')}`}
                        name="program_date"
                        id="program_date"
                        value={transportOrderForm.program_date}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('program_date')}
                        disabled={transportOrderForm.has_dpi ? true : false}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('program_date')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Hora de programa</label>

                    <input
                        type="time"
                        className={`form-control ${fieldHasError('program_time')}`}
                        name="program_time"
                        id="program_time"
                        value={transportOrderForm.program_time}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('program_time')}
                        disabled={transportOrderForm.has_dpi ? true : false}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('program_time')}
                    </div>
                </div>
            </div>
            {!isUpdate && transportOrderForm.option_trip === OptionTrip.ROUND_TRIP && (
                <>
                    {fetchingRouteSegment ? (
                        <LazyLoading height="300" />
                    ) : (
                        <div className="mb-2 row">
                            <div className="col-md-12">
                                <label className="col-form-label">Opción ruta vuelta</label>
                                <SelectTwo
                                    name="return_route_segment_id"
                                    id="return_route_segment_id"
                                    inputValue={transportOrderForm.return_route_segment_id}
                                    hasError={fieldHasError('return_route_segment_id') !== ''}
                                    options={SelectTwoMapperOptions(inverseRouteSegments)}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo(
                                            'return_route_segment_id',
                                            value.value
                                        )
                                    }
                                    placeholder={'seleccione tramo'}
                                    onFocus={() => onFocusRemove('return_route_segment_id')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('return_route_segment_id')}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mb-2 row">
                        <div className="col-md-6">
                            <label className="col-form-label">Fecha de vuelta</label>
                            <input
                                type="date"
                                className={`form-control ${fieldHasError('return_program_date')}`}
                                name="return_program_date"
                                id="return_program_date"
                                value={transportOrderForm.return_program_date}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('return_program_date')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('return_program_date')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Hora de vuelta</label>
                            <input
                                type="time"
                                className={`form-control ${fieldHasError('return_program_time')}`}
                                name="return_program_time"
                                id="return_program_time"
                                value={transportOrderForm.return_program_time}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('return_program_time')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('return_program_time')}
                            </div>
                        </div>
                    </div>
                </>
            )}

            <hr />
            {isUpdate ? (
                transportOrderForm.is_type_sell &&
                transportOrderForm.option_trip === OptionTrip.ROUND_TRIP ? (
                    <div className="mb-2 mt-4 row needs-validation">
                        <div className="col-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="internal"
                                    value="internal"
                                    checked={transportOrderForm.is_type_sell}
                                    id="it_internal"
                                    onChange={handleCheckChange}
                                />
                                <label className="form-check-label" htmlFor="it_internal">
                                    Venta
                                </label>
                            </div>
                        </div>
                    </div>
                ) : !transportOrderForm.is_type_sell &&
                  transportOrderForm.option_trip === OptionTrip.ROUND_TRIP ? (
                    <div className="mb-2 mt-4 row needs-validation">
                        <div className="col-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="external"
                                    value="external"
                                    checked={!transportOrderForm.is_type_sell}
                                    id="it_external"
                                    onChange={handleCheckChange}
                                />
                                <label className="form-check-label" htmlFor="it_external">
                                    Desplazamiento
                                </label>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mb-2 mt-4 row needs-validation">
                        <div className="col-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="internal"
                                    value="internal"
                                    checked={transportOrderForm.is_type_sell}
                                    id="it_internal"
                                    onChange={handleCheckChange}
                                />
                                <label className="form-check-label" htmlFor="it_internal">
                                    Venta
                                </label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="external"
                                    value="external"
                                    checked={!transportOrderForm.is_type_sell}
                                    id="it_external"
                                    onChange={handleCheckChange}
                                />
                                <label className="form-check-label" htmlFor="it_external">
                                    Desplazamiento
                                </label>
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <div className="mb-2 mt-4 row needs-validation">
                    <div className="col-6">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="internal"
                                value="internal"
                                checked={transportOrderForm.is_type_sell}
                                id="it_internal"
                                onChange={handleCheckChange}
                            />
                            <label className="form-check-label" htmlFor="it_internal">
                                Venta
                            </label>
                        </div>
                    </div>
                    {transportOrderForm.option_trip !== OptionTrip.ROUND_TRIP && (
                        <div className="col-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="external"
                                    value="external"
                                    checked={!transportOrderForm.is_type_sell}
                                    id="it_external"
                                    onChange={handleCheckChange}
                                />
                                <label className="form-check-label" htmlFor="it_external">
                                    Desplazamiento
                                </label>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {transportOrderForm.is_type_sell && (
                <>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Cliente</label>
                            <SelectTwo
                                name="client_id"
                                id="client_id"
                                inputValue={transportOrderForm.client_id}
                                options={SelectTwoMapperOptions(clients, 'id', [
                                    'rut',
                                    'business_name'
                                ])}
                                hasError={fieldHasError('client_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('client_id', value.value)
                                }
                                placeholder={'Seleccione Cliente'}
                                onFocus={() => onFocusRemove('client_id')}
                                disabled={transportOrderForm.has_dpi ? true : false}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('client_id')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Producto</label>
                            <SelectTwo
                                name="product_id"
                                id="product_id"
                                inputValue={transportOrderForm.product_id}
                                options={SelectTwoMapperOptions(products, 'id', ['name'])}
                                hasError={fieldHasError('product_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('product_id', value.value)
                                }
                                placeholder={'Seleccione Producto'}
                                onFocus={() => onFocusRemove('product_id')}
                                disabled={
                                    transportOrderForm.has_dpi ||
                                    transportOrderForm.transportation_order_item_id
                                        ? true
                                        : false
                                }
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('product_id')}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {fetchingRouteSegment ? (
                <LazyLoading height="300" />
            ) : trucks.length > 0 &&
              trucks.find((truck) => truck.id === transportOrderForm.truck_id)?.is_external ? (
                <div className="mb-2 row">
                    <label className="col-md-12 col-form-label">Tarifa de externo</label>
                    <div className="col-md-12">
                        <div className="row g-1">
                            <div className="col-6">
                                <input
                                    type="number"
                                    name="external_rate"
                                    id="external_rate"
                                    className={`form-control ${fieldHasError('external_rate')}`}
                                    value={transportOrderForm.external_rate}
                                    onChange={handleChange}
                                    placeholder="Ingrese tarifa"
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('external_rate')}
                                </div>
                            </div>

                            <div className="col-3">
                                <SelectTwo
                                    name="currency_external_rate_id"
                                    id="currency_external_rate_id"
                                    inputValue={transportOrderForm.currency_external_rate_id}
                                    options={SelectTwoMapperOptions(currenciesExternalRate)}
                                    hasError={fieldHasError('currency_external_rate_id') !== ''}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo(
                                            'currency_external_rate_id',
                                            value.value
                                        )
                                    }
                                    onFocus={() => onFocusRemove('currency_external_rate_id')}
                                    placeholder={'Moneda'}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('currency_external_rate_id')}
                                </div>
                            </div>

                            <div className="col-3">
                                <SelectTwo
                                    name="unit_external_rate_id"
                                    id="unit_external_rate_id"
                                    inputValue={transportOrderForm.unit_external_rate_id}
                                    options={SelectTwoMapperOptions(unitsExternalRate)}
                                    hasError={fieldHasError('unit_external_rate_id') !== ''}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('unit_external_rate_id', value.value)
                                    }
                                    onFocus={() => onFocusRemove('unit_external_rate_id')}
                                    placeholder={'unidad'}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('unit_external_rate_id')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mb-2 row">
                    <label className="col-md-12 col-form-label">Comisión conductor </label>
                    <div className="col-md-12">
                        <div className="row g-1">
                            <div className="col-6">
                                <input
                                    type="number"
                                    name="commission"
                                    id="commission"
                                    className={`form-control ${fieldHasError('commission')}`}
                                    value={transportOrderForm.commission}
                                    onChange={handleChange}
                                    placeholder="Ingrese tarifa"
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('commission')}
                                </div>
                            </div>

                            <div className="col-3">
                                <SelectTwo
                                    name="currency_commission_id"
                                    id="currency_commission_id"
                                    inputValue={transportOrderForm.currency_commission_id}
                                    options={SelectTwoMapperOptions(currenciesCommissionRate)}
                                    hasError={fieldHasError('currency_commission_id') !== ''}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('currency_commission_id', value.value)
                                    }
                                    onFocus={() => onFocusRemove('currency_commission_id')}
                                    placeholder={'Moneda'}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('currency_commission_id')}
                                </div>
                            </div>

                            <div className="col-3">
                                <SelectTwo
                                    name="unit_commission_id"
                                    id="unit_commission_id"
                                    inputValue={transportOrderForm.unit_commission_id}
                                    options={SelectTwoMapperOptions(unitsCommissionRate)}
                                    hasError={fieldHasError('unit_commission_id') !== ''}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('unit_commission_id', value.value)
                                    }
                                    onFocus={() => onFocusRemove('unit_commission_id')}
                                    placeholder={'unidad'}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('unit_commission_id')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TransportOrderForm;
