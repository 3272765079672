import React, { useContext, useEffect, useState } from 'react';
import { defaultPlace, Place } from '../../../app/models/Place';
import usePlaceService from '../../../app/services/hooks/usePlaceService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import PlaceFormContainer from './PlaceFormContainer';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    placeId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const PlaceEdit = ({ placeId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditPlace, editPlace, fetchingUpdatePlace, updatePlace } = usePlaceService();

    const [place, setPlace] = useState<Place>(defaultPlace);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando origen/destino...');
        editPlace(placeId, {
            onSuccess: (response: ServiceResponse) => {
                setPlace(response.data.place);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando origen/destino...');
        updatePlace(placeId, place, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditPlace ? (
        <LazyLoading height="300" />
    ) : (
        <PlaceFormContainer
            fetching={fetchingUpdatePlace}
            action={update}
            cancel={cancel}
            place={place}
            setPlace={setPlace}
            errorFields={errorFields}
        />
    );
};

export default PlaceEdit;
