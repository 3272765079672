import React, { useContext } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useClientService from '../../../app/services/hooks/useClientService';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import ActiveControl from '../../../components/ActiveControl';

interface Props {
    clientId: number;
    checkValue: boolean;
    callbackSuccess: () => void;
}

const ClientSwitchChange = ({ clientId, checkValue, callbackSuccess }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [checked, setChecked] = React.useState<boolean>(checkValue);

    const { activeClient, fetchingActiveClient } = useClientService();

    const active = (value: boolean) => {
        setChecked(value);

        activeClient(clientId, value, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                callbackSuccess();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setChecked(!value);
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return <ActiveControl status={checked} id={clientId} callback={active} />;
};

export default ClientSwitchChange;
