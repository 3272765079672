import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import {
    TravelExpense,
    TravelExpenseDataForm,
    TravelExpenseFilters
} from '../../../../app/models/TravelExpense';
import TravelExpensesFilter from './TravelExpensesFilter';
import TravelExpenseSwitchChange from '../TravelExpenseSwitchChange';
import { formatNumber } from '../../../../utils/utils';

interface Props {
    travelExpenses: TravelExpenseDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: TravelExpenseFilters;
    setFilter: (filter: TravelExpenseFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const TravelExpensesDataTable = ({
    travelExpenses,
    totalRows,
    loading = false,
    edit,
    destroy,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: any, index: any, column: any) => <span className="">{row.id}</span>
        },
        {
            name: 'Presupuesto',
            selector: (row: any) => row.budget,
            sortable: true,
            sortField: 'budget',
            cell: (row: any, index: any, column: any) => (
                <>
                    <span className="">
                        {formatNumber(row.budget ? row.budget : 0)} {row.currency}
                    </span>
                </>
            )
        },
        {
            name: 'Fecha de inicio',
            selector: (row: any) => row.start_date,
            sortable: true,
            sortField: 'start_date',
            cell: (row: any, index: any, column: any) => <span className="">{row.start_date}</span>
        },
        {
            name: 'Fecha de termino',
            selector: (row: any) => row.end_date,
            sortable: true,
            sortField: 'end_date',
            cell: (row: any, index: any, column: any) => <span className="">{row.end_date}</span>
        },
        {
            name: 'Evento',
            selector: (row: any) => row.event_type_ids,
            sortable: true,
            sortField: 'event',
            cell: (row: any, index: any, column: any) => (
                <div>
                    {row.event_type_ids.map((eventType: any, index: number) => (
                        <div key={eventType.id}>
                            <span>
                                {eventType.name}
                                {index < row.event_type_ids.length - 1 ? ',' : ''}
                            </span>
                            <br />
                        </div>
                    ))}
                </div>
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: false,
            sortField: 'is_active',
            cell: (row: any, index: any, column: any) => (
                <TravelExpenseSwitchChange
                    checkValue={row.is_active ?? true}
                    TravelExpenseId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <TravelExpensesFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={travelExpenses}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default TravelExpensesDataTable;
