import { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Unit, UnitEquivalenceDataForm } from '../../../app/models/Unit';
import useUnitEquivalenceService from '../../../app/services/hooks/useUnitEquivalenceService';
import UnitEquivalenceForm from './forms/UnitEquivalenceForm';
import LazyLoading from '../../../components/LazyLoading';
import UnitEquivalenFormContainer from './UnitEquivalenFormContainer';

interface Props {
    unitEquivalenceId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const UnitEquivalenceEdit = ({ unitEquivalenceId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const {
        fetchingEditUnitEquivalence,
        editUnitEquivalence,
        fetchingUpdateUnitEquivalence,
        updateUnitEquivalence
    } = useUnitEquivalenceService();
    const [unit, setUnit] = useState<Unit[]>([]);
    const [unitEquivalence, setUnitEquivalence] = useState<UnitEquivalenceDataForm>({
        from_unit_quantity: 1,
        to_unit_quantity: 0
    });

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando equivalencia...');
        editUnitEquivalence(unitEquivalenceId, {
            onSuccess: (response: ServiceResponse) => {
                setUnit(response.data.units);
                setUnitEquivalence(response.data.unit_equivalences);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando equivalencia...');
        updateUnitEquivalence(unitEquivalenceId, unitEquivalence, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditUnitEquivalence ? (
        <LazyLoading height="300" />
    ) : (
        <UnitEquivalenFormContainer
            fetching={fetchingUpdateUnitEquivalence}
            action={update}
            cancel={cancel}
            unitEquivalence={unitEquivalence}
            setUnitEquivalence={setUnitEquivalence}
            unit={unit}
        />
    );
};

export default UnitEquivalenceEdit;
