import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { EventType, EventTypeFilters } from '../../../app/models/EventType';

const useEventTypeService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetEventTypes, setFetchingGetEventTypes] = useState(false);
    const [fetchingShowEventType, setFetchingShowEventType] = useState(false);
    const [fetchingCreateEventType, setFetchingCreateEventType] = useState(false);
    const [fetchingStoreEventType, setFetchingStoreEventType] = useState(false);
    const [fetchingEditEventType, setFetchingEditEventType] = useState(false);
    const [fetchingUpdateEventType, setFetchingUpdateEventType] = useState(false);
    const [fetchingDeleteEventType, setFetchingDeleteEventType] = useState(false);
    const [fetchingActiveEventType, setFetchingActiveEventType] = useState(false);

    const getEventTypes = (eventTypeFilter: EventTypeFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(eventTypeFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.EVENT_TYPES.GET_EVENT_TYPES}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetEventTypes
        });
    };

    const showEventType = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EVENT_TYPES.GET_EVENT_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingShowEventType
        });
    };

    const createEventType = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EVENT_TYPES.CREATE_EVENT_TYPE,
            setFetching: setFetchingCreateEventType
        });
    };

    const storeEventType = (eventType: EventType, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.EVENT_TYPES.STORE_EVENT_TYPE,
            body: eventType,
            setFetching: setFetchingStoreEventType
        });
    };

    const editEventType = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EVENT_TYPES.EDIT_EVENT_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingEditEventType
        });
    };

    const updateEventType = (id: number, eventType: EventType, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.EVENT_TYPES.UPDATE_EVENT_TYPE.replace(':id', id.toString()),
            body: eventType,
            setFetching: setFetchingUpdateEventType
        });
    };

    const deleteEventType = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.EVENT_TYPES.DELETE_EVENT_TYPE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteEventType
        });
    };

    const activeEventType = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.EVENT_TYPES.ACTIVE_EVENT_TYPE.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveEventType
        });
    };

    return {
        fetchingGetEventTypes,
        fetchingShowEventType,
        fetchingCreateEventType,
        fetchingStoreEventType,
        fetchingEditEventType,
        fetchingUpdateEventType,
        fetchingDeleteEventType,
        fetchingActiveEventType,
        getEventTypes,
        showEventType,
        createEventType,
        storeEventType,
        editEventType,
        updateEventType,
        deleteEventType,
        activeEventType
    };
};

export default useEventTypeService;
