import React, { useEffect, useRef, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';

import { CheckingAccountDataForm } from '../../../../app/models/CheckingAccount';
import { Bank } from '../../../../app/models/Bank';
import { BankAccountType } from '../../../../app/models/BankAccountType';
import { Carrier } from '../../../../app/models/Carrier';
import { Currency } from '../../../../app/models/Currency';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import LazyLoading from '../../../../components/LazyLoading';
import { Expense } from '../../../../app/models/Expense';
import { formatAmount, formatMoney, setCleanTextAreaError } from '../../../../utils/utils';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import { TransferReason } from '../../../../app/models/TransferReason';
import CurrencyInput from '../../../../components/inputs/CurrencyInput';

type Props = {
    checkingAccountDataForm: CheckingAccountDataForm;
    setCheckingAccountDataForm: (checkingAccountDataForm: CheckingAccountDataForm) => void;
    banks: Bank[];
    bankAccountTypes: BankAccountType[];
    carriers: Carrier[];
    currencies: Currency[];
    expensesData: Expense[];
    setExpenses: (expenses: Expense[]) => void;
    errorFields?: any;
    loading?: boolean;
    updated?: boolean;
    loadingBankAccount?: boolean;

    transferReasons: TransferReason[];
};

const CheckingAccountForm = ({
    checkingAccountDataForm,
    setCheckingAccountDataForm,
    banks,
    bankAccountTypes,
    transferReasons,
    carriers,
    currencies,
    expensesData,
    setExpenses,
    errorFields,
    loading = false,
    updated = false,
    loadingBankAccount = false
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [suggestedAmount, setSuggestedAmount] = useState<number>(0);
    const firstEditCarrierId = useRef<number | undefined>(undefined);
    const firstEditExpenses = useRef<any[] | undefined>(undefined);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        if (updated) {
            firstEditCarrierId.current = checkingAccountDataForm.worker_id;
            firstEditExpenses.current = checkingAccountDataForm.expenses ?? [];

            let amount = 0;

            for (const expense of firstEditExpenses.current) {
                amount += expense.amount ?? 0;
            }

            setSuggestedAmount(amount);
        }
    }, []);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setCheckingAccountDataForm({ ...checkingAccountDataForm, [name]: value });
    };

    const handleChangeDocumentFile = (e: FileList | null, type: string) => {
        if (e) {
            setCheckingAccountDataForm({ ...checkingAccountDataForm, ['file']: e[0] });
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };

        if (name == 'worker_id' && value == undefined) {
            setExpenses([]);
            obj.expenses = undefined;
            obj.bank_id = 0;
            obj.account_type_id = 0;
            obj.account_number = '';
            setSuggestedAmount(0);
        } else if (updated) {
            if (name == 'worker_id' && value == firstEditCarrierId.current) {
                let totalAmount = 0;

                for (const item of firstEditExpenses.current ?? []) {
                    totalAmount += item.amount ?? 0;
                }

                setSuggestedAmount(totalAmount);
            } else if (name == 'worker_id') {
                setSuggestedAmount(0);
            }
        }

        setCheckingAccountDataForm({ ...checkingAccountDataForm, ...obj });
    };

    const changeMultipleSelectTwo = (expensesSelect: any) => {
        const obj = { expenses: expensesSelect };
        let totalAmount = 0;

        for (const item of expensesSelect) {
            const matchingExpense = expensesData.find((expense) => expense.id === item.value);

            if (matchingExpense) {
                totalAmount += matchingExpense.amount ?? 0;
            }
        }

        setCheckingAccountDataForm({
            ...checkingAccountDataForm,
            ...obj
        });
        setSuggestedAmount(totalAmount);
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label"> Fecha de emisión </label>
                    <input
                        type="date"
                        className={`form-control ${fieldHasError('issue_date')}`}
                        name="issue_date"
                        id="issue_date"
                        value={checkingAccountDataForm.issue_date}
                        onChange={handleChange}
                        placeholder="Ingrese una fecha de emisión"
                        onFocus={() => onFocusRemove('issue_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('issue_date')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label"> Conductor </label>
                    <SelectTwo
                        name="worker_id"
                        id="worker_id"
                        inputValue={checkingAccountDataForm.worker_id}
                        options={SelectTwoMapperOptions(carriers)}
                        hasError={fieldHasError('worker_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('worker_id', value?.value)}
                        placeholder={'Seleccione conductor'}
                        onFocus={() => onFocusRemove('worker_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('worker_id')}
                    </div>
                </div>
            </div>

            {/*{!loading ? (*/}
            {/*    <>*/}
            {/*        <div className="mb-2 row">*/}
            {/*            <div className="col-md-12">*/}
            {/*                <label className="col-form-label">Gastos</label>*/}

            {/*                <SelectTwo*/}
            {/*                    name="expenses"*/}
            {/*                    id="expenses"*/}
            {/*                    inputValue={checkingAccountDataForm.expenses}*/}
            {/*                    options={SelectTwoMapperOptions(expensesData)}*/}
            {/*                    hasError={fieldHasError('expenses') !== ''}*/}
            {/*                    onChange={changeMultipleSelectTwo}*/}
            {/*                    isMulti={true}*/}
            {/*                    placeholder={'Seleccione gasto(s)'}*/}
            {/*                    onFocus={() => onFocusRemove('expenses')}*/}
            {/*                />*/}
            {/*                <div className="invalid-feedback" style={{ display: 'flex' }}>*/}
            {/*                    {fieldErrorMessage('expenses')}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*) : (*/}
            {/*    <LazyLoading height={300} />*/}
            {/*)}*/}
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Motivo</label>
                    <SelectTwo
                        name="transfer_reason_id"
                        id="transfer_reason_id"
                        inputValue={checkingAccountDataForm.transfer_reason_id}
                        options={SelectTwoMapperOptions(transferReasons)}
                        hasError={fieldHasError('transfer_reason_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('transfer_reason_id', value?.value)
                        }
                        placeholder={'Seleccione motivo'}
                        onFocus={() => onFocusRemove('transfer_reason_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('transfer_reason_id')}
                    </div>
                </div>
            </div>

            {!loadingBankAccount ? (
                <>
                    <div className="mb-2 row">
                        <div className="col-md-6">
                            <label className="col-form-label">Banco</label>

                            <SelectTwo
                                name="bank_id"
                                id="bank_id"
                                inputValue={checkingAccountDataForm.bank_id}
                                options={SelectTwoMapperOptions(banks)}
                                hasError={fieldHasError('bank_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('bank_id', value?.value)
                                }
                                placeholder={'Seleccione banco'}
                                onFocus={() => onFocusRemove('bank_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('bank_id')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Tipo de cuenta</label>

                            <SelectTwo
                                name="account_type_id"
                                id="account_type_id"
                                inputValue={checkingAccountDataForm.account_type_id}
                                options={SelectTwoMapperOptions(bankAccountTypes)}
                                hasError={fieldHasError('account_type_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('account_type_id', value?.value)
                                }
                                placeholder={'Seleccione tipo'}
                                onFocus={() => onFocusRemove('account_type_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('account_type_id')}
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">N° de cuenta</label>
                            <input
                                name="account_number"
                                id="account_number"
                                type="text"
                                className={`form-control ${fieldHasError('account_number')}`}
                                value={checkingAccountDataForm.account_number}
                                onFocus={() => onFocusRemove('account_number')}
                                placeholder="Ingrese el número de cuenta"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('account_number')}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <LazyLoading height={300} />
            )}

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">
                        Monto{' '}
                        {suggestedAmount > 0 && (
                            <span className="text-primary">
                                (sugerido: {formatMoney(suggestedAmount)})
                            </span>
                        )}
                    </label>
                    <CurrencyInput
                        name={'amount'}
                        currency={checkingAccountDataForm.currency_id}
                        currencies={currencies}
                        onChange={handleChange}
                        value={checkingAccountDataForm.amount}
                        className={`form-control ${fieldHasError(`amount`)}`}
                        onFocus={() => onFocusRemove(`amount`)}
                        placeholder="Ingrese el monto"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('amount')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Moneda</label>
                    <SelectTwo
                        name="currency_id"
                        id="currency_id"
                        inputValue={checkingAccountDataForm.currency_id}
                        options={SelectTwoMapperOptions(currencies)}
                        hasError={fieldHasError('currency_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('currency_id', value?.value)
                        }
                        placeholder={'Seleccione moneda'}
                        onFocus={() => onFocusRemove('currency_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('currency_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Comprobante</label>
                    <InputButtonFile
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        name={'file'}
                        objectFile={checkingAccountDataForm?.file}
                        onFocus={() => onFocusRemove('photo')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('file')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Descripción</label>
                    <textarea
                        className={`form-control ${fieldHasError('description')}`}
                        name="description"
                        id="description"
                        value={checkingAccountDataForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CheckingAccountForm;
