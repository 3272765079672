import { useContext, useEffect, useState } from 'react';
import GuideHistoryForm from './forms/GuideHistoryForm';
import LazyLoading from '../../../components/LazyLoading';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { GuideHistoryDataForm } from '../../../app/models/GuideHistory';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useGuideHistoryService from '../../../app/services/hooks/useGuideHistoryService';

interface Props {
    guideHistoryId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const GuideHistoryEdit = ({ guideHistoryId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const {
        editGuideHistory,
        updateGuideHistory,
        fetchingHistoryGuideEdit,
        fetchingHistoryGuideUpdate
    } = useGuideHistoryService();

    const [guideHistory, setGuideHistory] = useState<GuideHistoryDataForm>({
        comment: ''
    });

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        editHistory();
    }, [guideHistoryId]);

    const editHistory = () => {
        if (showLoading) showLoading('loading', 'Cargando información de historial...');
        editGuideHistory(guideHistoryId, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setGuideHistory(response.data.guide_history);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onCancel) onCancel();
            }
        });
    };

    const updateHistory = () => {
        if (showLoading) showLoading('loading', 'Actualizando información de historial...');
        updateGuideHistory(guideHistoryId, guideHistory, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingHistoryGuideEdit ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <GuideHistoryForm
                        guideHistoryDataForm={guideHistory}
                        setGuideHistoryDataForm={setGuideHistory}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingHistoryGuideUpdate} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={updateHistory}
                        locked={fetchingHistoryGuideUpdate}
                    />
                </div>
            </div>
        </>
    );
};

export default GuideHistoryEdit;
