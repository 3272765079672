import { Bonus } from './Bonus';
import { Contract } from './Contract';
import { Deduction } from './Deduction';
import { JobTitle } from './JobTitle';

export interface Liquidation {
    id?: number;
    worker_name?: string;
    contract?: Contract;
    contract_id?: number;
    period?: string;
    base_salary?: number;
    workdays?: number;
    deduction?: Deduction;
    bonus?: Bonus;
    taxes?: number;
    net_salary?: number;
    tax_base?: number;
    total_discounts?: number;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
    job_title?: JobTitle;
    state?: string
}

export interface LiquidationGenerator {
    job_title_id?: number;
    period?: string;
}

export interface LiquidationDataForm {
    id?: number;
    contract?: Contract;
    contract_id?: number;
    worker_name?: string;
    period?: string;
    base_salary?: number;
    workdays?: number;
    deductions?: number;
    deduction?: Deduction;
    deduction_id?: number;
    bonus?: Bonus;
    bonus_id?: number;
    taxes?: number;
    applies_gratification?: boolean;
    net_salary?: number;
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface LiquidationFilters {
    id?: number | null;
    contract?: Contract;
    contract_id?: number | null;
    period?: string;
    base_salary?: number | null;
    workdays?: number | null;
    deduction?: Deduction;
    bonus?: Bonus;
    taxes?: number | null;
    net_salary?: number | null;
    is_active?: boolean;

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultLiquidationGenerator: LiquidationGenerator = {
    period: ''
};
