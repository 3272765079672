import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

import { ServiceEvents } from '../shared/interfaces';
import { AfpRateDataForm } from '../../models/AfpRate';

const useAfpRateService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetAfpRatesByAfp, setFetchingGetAfpRatesByAfp] = useState(false);
    const [fetchingStoreAfpRate, setFetchingStoreAfpRate] = useState(false);
    const [fetchingEditAfpRate, setFetchingEditAfpRate] = useState(false);
    const [fetchingUpdateAfpRate, setFetchingUpdateAfpRate] = useState(false);
    const [fetchingDeleteAfpRate, setFetchingDeleteAfpRate] = useState(false);
    const [fetchingCreateAfpRate, setFetchingCreateAfpRate] = useState(false);
    const [fetchingShowAfpRate, setFetchingShowAfpRate] = useState(false);

    const getAllAfpRatesByAfp = (afp_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.AFP_RATES.GET_AFP_RATES_BY_AFP.replace(':afp_id', afp_id.toString()),
            setFetching: setFetchingGetAfpRatesByAfp
        });
    };

    const createAfpRate = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.AFP_RATES.CREATE_AFP_RATE,
            setFetching: setFetchingCreateAfpRate
        });
    };

    const storeAfpRate = (AfpRateDataForm: AfpRateDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.AFP_RATES.STORE_AFP_RATE,
            body: AfpRateDataForm,
            setFetching: setFetchingStoreAfpRate
        });
    };

    const editAfpRate = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.AFP_RATES.EDIT_AFP_RATE.replace(':id', id.toString()),
            setFetching: setFetchingEditAfpRate
        });
    };

    const updateAfpRate = (
        id: number,
        AfpRateDataForm: AfpRateDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.AFP_RATES.UPDATE_AFP_RATE.replace(':id', id.toString()),
            body: AfpRateDataForm,
            setFetching: setFetchingUpdateAfpRate
        });
    };
    const showAfpRate = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.AFP_RATES.SHOW_AFP_RATE.replace(':id', id.toString()),
            setFetching: setFetchingShowAfpRate
        });
    };

    const deleteAfpRate = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.AFP_RATES.DELETE_AFP_RATE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteAfpRate
        });
    };

    return {
        getAllAfpRatesByAfp,
        createAfpRate,
        storeAfpRate,
        editAfpRate,
        updateAfpRate,
        showAfpRate,
        deleteAfpRate,
        fetchingGetAfpRatesByAfp,
        fetchingStoreAfpRate,
        fetchingEditAfpRate,
        fetchingUpdateAfpRate,
        fetchingShowAfpRate,
        fetchingDeleteAfpRate,
        fetchingCreateAfpRate
    };
};

export default useAfpRateService;
