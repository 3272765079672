import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';

import { useHistory } from 'react-router-dom';
import { LOCAL_STORAGE } from '../../config';
import { AppContext } from '../../contexts/AppContext';
import useNavigationPage from '../../hooks/useNavigationPage';

type Props = {
    children: React.ReactNode;
};

const PublicMiddleware = ({ children }: Props) => {
    const history = useHistory();
    const { logged } = useContext(AuthContext);
    const [showChildren, setShowChildren] = useState(false);
    const { navigationPage } = useNavigationPage();

    useEffect(() => {
        if (logged) {
            setShowChildren(true);
            navigationPage('/');
        }

        setShowChildren(true);
    }, [logged]);

    return !showChildren ? null : <>{children}</>;
};

export default PublicMiddleware;
