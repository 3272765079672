import React, { useContext, useEffect, useRef, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import { useHistory } from 'react-router-dom';
import {
    TransportOrderDataForm,
    TransportOrderOptionTrip
} from '../../../app/models/TransportOrder';
import useTransportOrderService from '../../../app/services/hooks/useTransportOrderService';
import TransportOrderForm from './forms/TransportOrderForm';
import { Client } from '../../../app/models/Client';
import { RouteSegment } from '../../../app/models/RouteSegment';
import { Product } from '../../../app/models/Product';
import { Carrier } from '../../../app/models/Carrier';
import { Worker } from '../../../app/models/Workers';
import { Truck } from '../../../app/models/Truck';
import { Hopper } from '../../../app/models/Hopper';
import { Currency } from '../../../app/models/Currency';
import { Unit } from '../../../app/models/Unit';
import useRouteSegmentService from '../../../app/services/hooks/useRouteSegmentService';
import { OptionTrip } from '../../../app/shared/enums';

interface Props {
    workOrderId: number;
    truckId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TransportOrderCreate = ({ onSaved, onCancel, onError, workOrderId, truckId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const {
        createTransportOrder,
        storeTransportOrder,
        fetchingCreateTransportOrder,
        fetchingStoreTransportOrder
    } = useTransportOrderService();

    const { getInverseRouteSegments, fetchingGetInverseRouteSegmentByRouteSegments } =
        useRouteSegmentService();

    const [transportOrder, setTransportOrder] = useState<TransportOrderDataForm>({
        work_order_id: workOrderId,
        worker_id: 0,
        hopper_id: 0,
        route_segment_id: 0,
        is_type_sell: true,
        client_id: 0,
        product_id: 0,
        truck_id: truckId
    });

    const [clients, setClients] = useState<Client[]>([]);
    const [carriers, setCarriers] = useState<Worker[]>([]);
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [hoppers, setHoppers] = useState<Hopper[]>([]);
    const [currenciesExternalRate, setCurrenciesExternalRate] = useState<Currency[]>([]);
    const [unitsExternalRate, setUnitsExternalRate] = useState<Unit[]>([]);
    const [currenciesCommissionRate, setCurrenciesCommissionRate] = useState<Currency[]>([]);
    const [unitsCommissionRate, setUnitsCommissionRate] = useState<Unit[]>([]);
    const [routeSegments, setRouteSegments] = useState<RouteSegment[]>([]);
    const [inverseRouteSegments, setInverseRouteSegments] = useState<RouteSegment[]>([]);
    const [products, setProducts] = useState<Product[]>([]);

    const [optionTrips, setOptionTrips] = useState<TransportOrderOptionTrip[]>([]);

    const routeSegmentIdSelected = useRef<number | undefined>(0);

    const [errorFields, setErrorFields] = useState<any>();
    const confirmProgramDateRef = useRef<boolean>(false);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createTransportOrder(workOrderId,{
            onSuccess: (response: ServiceResponse) => {
                setClients(response.data.clients);
                setCarriers(response.data.carriers);
                setRouteSegments(response.data.route_segments);
                setProducts(response.data.products);
                setTrucks(response.data.trucks);
                setHoppers(response.data.hoppers);
                setCurrenciesExternalRate(response.data.currency_external_rates);
                setUnitsExternalRate(response.data.unit_external_rates);
                setCurrenciesCommissionRate(response.data.currency_commissions);
                setUnitsCommissionRate(response.data.unit_commissions);
                setOptionTrips(response.data.option_trips);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {
        if (transportOrder.route_segment_id == undefined) {
            routeSegmentIdSelected.current = undefined;
        }

        if (
            transportOrder.route_segment_id &&
            transportOrder.route_segment_id != routeSegmentIdSelected.current
        ) {
            getInverseRouteSegments(transportOrder.route_segment_id, {
                onSuccess: (response: ServiceResponse) => {
                    routeSegmentIdSelected.current = transportOrder.route_segment_id ?? 0;

                    setInverseRouteSegments(response.data.inverse_route_segments);

                    setTransportOrder({
                        ...transportOrder,
                        return_route_segment_id:
                            response.data.inverse_route_segments.length > 0
                                ? response.data.inverse_route_segments[0].id
                                : 0,
                        external_rate: response.data.route_segment.external_rate,
                        currency_external_rate_id:
                            response.data.route_segment.currency_external_rate_id ?? undefined,
                        unit_external_rate_id:
                            response.data.route_segment.unit_external_rate_id ?? undefined,
                        commission: response.data.route_segment.commission ?? undefined,
                        currency_commission_id:
                            response.data.route_segment.currency_commission_id ?? undefined,
                        unit_commission_id:
                            response.data.route_segment.unit_commission_id ?? undefined
                    });
                    if (
                        transportOrder.option_trip === OptionTrip.ROUND_TRIP &&
                        response.data.inverse_route_segments.length === 0
                    ) {
                        useReactConfirmAlert().errorAlert({
                            title: 'Error',
                            message:
                                'No se encontró ninguna opción ruta de vuelta para la opción de ruta seleccionada, debe crearla en el módulo de tramos.'
                        });
                    }
                },
                onError: (response: ServiceResponse) => {
                    setTransportOrder({
                        ...transportOrder,
                        route_segment_id: routeSegmentIdSelected.current
                    });
                }
            });
        }
    }, [transportOrder.route_segment_id]);

    useEffect(() => {
        if (!transportOrder.is_type_sell) {
            setTransportOrder({
                ...transportOrder,
                client_id: undefined,
                product_id: undefined,
                option_trip: OptionTrip.ONE_WAY
            });
        }
    }, [transportOrder.is_type_sell]);

    useEffect(() => {
        if (transportOrder.option_trip === OptionTrip.ONE_WAY) {
            setTransportOrder({
                ...transportOrder,
                return_program_date: '',
                return_program_time: '',
                return_route_segment_id: 0
            });
        } else if (transportOrder.option_trip === OptionTrip.ROUND_TRIP) {
            setTransportOrder({
                ...transportOrder,
                is_type_sell: true
            });
        }
    }, [transportOrder.option_trip]);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando orden de transporte...');
        storeTransportOrder(
            {
                ...transportOrder,
                confirm_program_date: confirmProgramDateRef.current
            },
            {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    if (response.data.validate) {
                        confirmProgramDate(response.data.message_list);
                    } else {
                        useReactConfirmAlert().successAlert({
                            title: 'Éxito',
                            message: response.message
                        });
                        toast.success(response.message, {
                            autoClose: 2500
                        });
                        confirmProgramDateRef.current = false;
                        if (onSaved) onSaved();
                    }
                },
                onError: (response: ServiceResponse) => {
                    confirmProgramDateRef.current = false;
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    confirmProgramDateRef.current = false;
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            }
        );
    };

    const confirmProgramDate = (message_list: []) => {
        const _text =
            'Está seguro que desea crear la orden de transporte con los siguientes detalles en fecha(s) de programa:';

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            message_list: message_list,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        confirmProgramDateRef.current = true;
                        store();
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        confirmProgramDateRef.current = false;
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La orden de transporte no se ha creado.'
                            });
                        }, 0);
                    }
                }
            },
            onClickOutsideFunction: () => {
                confirmProgramDateRef.current = false;
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateTransportOrder ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <TransportOrderForm
                        transportOrderForm={transportOrder}
                        errorFields={errorFields}
                        clients={clients}
                        products={products}
                        route_segments={routeSegments}
                        inverseRouteSegments={inverseRouteSegments}
                        setTransportOrderForm={setTransportOrder}
                        carriers={carriers}
                        trucks={trucks}
                        hoppers={hoppers}
                        currenciesExternalRate={currenciesExternalRate}
                        unitsExternalRate={unitsExternalRate}
                        currenciesCommissionRate={currenciesCommissionRate}
                        unitsCommissionRate={unitsCommissionRate}
                        optionTrips={optionTrips}
                        fetchingRouteSegment={fetchingGetInverseRouteSegmentByRouteSegments}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreTransportOrder}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreTransportOrder} />
                </div>
            </div>
        </>
    );
};

export default TransportOrderCreate;
