import { PlaceType } from './PlaceType';

export interface Place {
    id?: number;
    name: string;
    latitude?: number;
    longitude?: number;
    is_active?: boolean;
    placeTypes?: PlaceType[];
}

export interface PlaceFilters {
    id?: number | null;
    name?: string | '';
    is_active?: boolean | '';
    latitude?: number | '';
    longitude?: number | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultPlace: Place = {
    name: '',
    latitude: 0,
    longitude: 0
};
