import React from 'react';
import { TransportOrderFilters } from '../../../../app/models/TransportOrder';
import MonthYearPicker from '../../../../components/inputs/MonthYearPicker';
import { momentParseDate } from '../../../../helpers';
import { useScreenDetector } from '../../../../hooks/useScreenDetector';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: TransportOrderFilters;
    setFilter: (filter: TransportOrderFilters) => void;
    onSearch: () => void;
};

const TransportOrderFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };
    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };
    const { isMobile, isTablet, isDesktop } = useScreenDetector();

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="truck">
                            Tracto
                        </label>
                        <input
                            title="Tracto"
                            type="text"
                            id="truck"
                            name="truck"
                            className="form-control form-control-sm"
                            value={filter.truck ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                {isDesktop && (
                    <>
                        <div className="col-md-auto">
                            <div className="mb-3">
                                <label className="font-size-10 mb-1" htmlFor="carrier_rut">
                                    Rut conductor
                                </label>
                                <input
                                    title="Conductor"
                                    type="text"
                                    id="carrier_rut"
                                    name="carrier_rut"
                                    className="form-control form-control-sm"
                                    value={filter.carrier_rut ?? ''}
                                    onChange={(e) => handlerInputFilter(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-auto">
                            <div className="mb-3">
                                <label className="font-size-10 mb-1" htmlFor="carrier_name">
                                    Nombre conductor
                                </label>
                                <input
                                    title="Nombre conductor"
                                    type="text"
                                    id="carrier_name"
                                    name="carrier_name"
                                    className="form-control form-control-sm"
                                    value={filter.carrier_name ?? ''}
                                    onChange={(e) => handlerInputFilter(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-auto">
                            <div className="mb-3">
                                <label className="font-size-10 mb-1" htmlFor="client">
                                    Cliente
                                </label>
                                <input
                                    title="Cliente"
                                    type="text"
                                    id="client"
                                    name="client"
                                    className="form-control form-control-sm"
                                    value={filter.client ?? ''}
                                    onChange={(e) => handlerInputFilter(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-auto">
                            <div className="mb-3">
                                <label className="font-size-10 mb-1" htmlFor="id">
                                    Número Orden
                                </label>
                                <input
                                    title="Número Orden"
                                    type="text"
                                    id="id"
                                    name="id"
                                    className="form-control form-control-sm"
                                    value={filter.id ?? ''}
                                    onChange={(e) => handlerInputFilter(e)}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="program_date_from">
                            Fecha de programa desde
                        </label>
                        <input
                            title="Fecha de programa desde"
                            type="date"
                            id="programprogram_date_from_date"
                            name="program_date_from"
                            className="form-control form-control-sm"
                            value={filter.program_date_from ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="program_date_until">
                            Fecha de programa hasta
                        </label>
                        <input
                            title="Fecha de programa hasta"
                            type="date"
                            id="program_date_until"
                            name="program_date_until"
                            className="form-control form-control-sm"
                            value={filter.program_date_until ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="quote_status">
                            Estado
                        </label>
                        <select
                            id="status"
                            name="status"
                            className="form-control form-select-sm"
                            value={filter.status ?? ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option value="TODOS">TODOS</option>
                            <option value="PREPARANDO">Programado</option>
                            <option value="CONFIRMADA">Confirmada</option>
                            <option value="ACTIVA">Activa</option>
                            <option value="FINALIZADA">Finalizada</option>
                            <option value="DESBLOQUEADA">Desbloqueada</option>
                        </select>
                    </div>
                </div>

                {isDesktop && (
                    <>
                        <div className="col-md-auto">
                            <div className="mb-3">
                                <label className="font-size-10 mb-1" htmlFor="period_from">
                                    Período orden de trabajo desde
                                </label>
                                <MonthYearPicker
                                    className="form-control form-control-sm input-datepicker"
                                    onMonthYearChange={(date) =>
                                        handlerInputFilter({
                                            target: {
                                                name: 'period_from',
                                                value: String(momentParseDate(date, 'YYYY-MM-DD'))
                                            }
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-md-auto">
                            <div className="mb-3">
                                <label className="font-size-10 mb-1" htmlFor="period_until">
                                    Período orden de trabajo hasta
                                </label>
                                <MonthYearPicker
                                    className="form-control form-control-sm input-datepicker"
                                    onMonthYearChange={(date) =>
                                        handlerInputFilter({
                                            target: {
                                                name: 'period_until',
                                                value: String(momentParseDate(date, 'YYYY-MM-DD'))
                                            }
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </>
                )}

                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransportOrderFilter;
