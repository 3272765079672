import React, { useContext, useEffect, useState } from 'react';
import useContractService from '../../../../app/services/hooks/useContractService';
import { ContractDataForm, defaultContract } from '../../../../app/models/Contract';
import { AppContext } from '../../../../contexts/AppContext';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import LazyLoading from '../../../../components/LazyLoading';
import ContractFormContainer from '../ContractFormContainer';
import { Status } from '../../../../app/models/Status';
import { Worker } from '../../../../app/models/Workers';
import { Shift } from '../../../../app/models/Shift';
import { JobTitle } from '../../../../app/models/JobTitle';
import { Currency } from '../../../../app/models/Currency';


interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ContractCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const { fetchingStoreContract, storeContract, createContract, fetchingCreateContract } =
        useContractService();
    const [errorFields, setErrorFields] = useState<any>();
    const [contract, setContract] = useState<ContractDataForm>(defaultContract);
    const [status, setStatus] = useState<Status[]>([]);
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [shifts, setShifts] = useState<Shift[]>([])
    const [jobTitles, setJobTitles] = useState<JobTitle[]>([])


    useEffect(() => {
        create();
    }, []);

    const create = () => {
        createContract({
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setStatus(response.data.status);
                setWorkers(response.data.workers);
                setCurrencies(response.data.currencies);
                setShifts(response.data.shift_works);
                setJobTitles(response.data.job_titles);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando contrato de trabajo...');
        storeContract(contract, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateContract ? (
        <LazyLoading height={300} />
    ) : (
        <ContractFormContainer
            action={store}
            cancel={cancel}
            contract={contract}
            setContract={setContract}
            status={status}
            workers={workers}
            errorFields={errorFields}
            currencies={currencies}
            shifts={shifts}
            jobTitles={jobTitles}
        />
    );
};

export default ContractCreate;
