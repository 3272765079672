import React, { ChangeEvent, useEffect, useState } from 'react';
import { UnitEquivalence, Unit, defaultUnitEquivalence } from '../../../app/models/Unit';
import CustomInput from '../../../components/inputs/CustomInput';
import SelectTwo, { SelectTwoMapperOptions } from '../../../components/SelectTwo';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import useUnitEquivalenceService from '../../../app/services/hooks/useUnitEquivalenceService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';

interface Props {
    unitEquivalences: UnitEquivalence[];
}

const UnitEquivalenceCalculator = ({ unitEquivalences }: Props) => {
    const [units, setUnits] = useState<Unit[]>([]);
    const [unitEquivalence, setUnitEquivalence] = useState<UnitEquivalence>(defaultUnitEquivalence);
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { fetchingCalculateUnitEquivalence, calculateUnitEquivalence } =
        useUnitEquivalenceService();

    useEffect(() => {
        const unitsDict: { [key: number]: Unit } = {};
        unitEquivalences.forEach((equivalence) => {
            if (equivalence.from_unit?.id)
                unitsDict[equivalence.from_unit?.id] = equivalence.from_unit;
            if (equivalence.to_unit?.id) unitsDict[equivalence.to_unit?.id] = equivalence.to_unit;
        });
        setUnits(Object.values(unitsDict));
    }, [unitEquivalences]);

    useEffect(() => {
        if (
            unitEquivalence.from_unit &&
            unitEquivalence.to_unit &&
            unitEquivalence.from_unit_quantity
        ) {
            calculateEquivalence(unitEquivalence);
        } else {
            setUnitEquivalence({
                ...unitEquivalence,
                to_unit_quantity: 0
            });
        }
    }, [unitEquivalence.from_unit, unitEquivalence.to_unit, unitEquivalence.from_unit_quantity]);

    const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUnitEquivalence({ ...unitEquivalence, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setUnitEquivalence({ ...unitEquivalence, ...obj });
    };

    const calculateEquivalence = (unitEquivalence: UnitEquivalence) => {
        calculateUnitEquivalence(unitEquivalence, {
            onSuccess: (response: ServiceResponse) => {
                setUnitEquivalence({
                    ...unitEquivalence,
                    to_unit_quantity: response.data.unit_equivalence
                });
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <div className=" row needs-validation">
                <div className="col-md-6 d-flex align-items-center">
                    <div className="flex-grow-1">
                        <CustomInput
                            name="from_unit_quantity"
                            className="form-control"
                            value={
                                unitEquivalence.from_unit_quantity !== 0
                                    ? unitEquivalence.from_unit_quantity
                                    : ''
                            }
                            onChange={handleChange}
                            placeholder="Cantidad"
                        />
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center">
                    <div className="col-md-12">
                        <SelectTwo
                            name="from_unit"
                            id="from_unit"
                            inputValue={unitEquivalence.from_unit}
                            options={SelectTwoMapperOptions(units)}
                            hasError={fieldHasError('from_unit') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('from_unit', value?.value)
                            }
                            placeholder={'Seleccione unidad'}
                            onFocus={() => onFocusRemove('from_unit')}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-3 row needs-validation">
                <div className="col-md-6 d-flex align-items-center">
                    <div className="flex-grow-1">
                        <CustomInput
                            name="to_unit_quantity"
                            className="form-control"
                            type={'BIGFLOAT'}
                            value={unitEquivalence.to_unit_quantity}
                            onChange={handleChange}
                            placeholder="Cantidad"
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center">
                    <div className="col-md-12">
                        <SelectTwo
                            name="to_unit"
                            id="to_unit"
                            inputValue={unitEquivalence.to_unit}
                            options={SelectTwoMapperOptions(units)}
                            hasError={fieldHasError('to_unit') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('to_unit', value?.value)
                            }
                            placeholder={'Seleccione unidad'}
                            onFocus={() => onFocusRemove('to_unit')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default UnitEquivalenceCalculator;
