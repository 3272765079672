import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';

const useBankAccountService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBankAccountByReason, setFetchingGetBankAccountByReason] = useState(false);

    const getBankAccountByReason = (
        reason_id: number,
        worker_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.BANK_ACCOUNT.BANK_ACCOUNT_BY_REASON.replace(
                ':reason_id',
                reason_id.toString()
            ).replace(':worker_id', worker_id.toString()),
            setFetching: setFetchingGetBankAccountByReason
        });
    };

    return {
        fetchingGetBankAccountByReason,
        getBankAccountByReason
    };
};

export default useBankAccountService;
