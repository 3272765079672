import React, { useState, useContext, useEffect } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import ShiftDataTable from './components/tables/ShiftDataTable';
import { Shift, ShiftFilters } from '../../app/models/Shift';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useNavigationPage from '../../hooks/useNavigationPage';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import useShiftService from '../../app/services/hooks/useShiftService';
import DefaultModal from '../../components/default/DefaultModal';
import ShiftCreate from './components/ShiftCreate';
import ShiftEdit from './components/ShiftEdit';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Jornada',
        url: '/shifts',
        isActive: true
    }
];

const Shifts = () => {
    const { navigationPage } = useNavigationPage();
    const { fetchingGetShifts, getShifts, deleteShift } = useShiftService();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [shiftIdEdit, setShiftIdEdit] = useState<number>(-1);
    const [shifts, setShifts] = useState<Shift[]>([]);
    const [eventTypes, SetEventTypes] = useState<[]>([]);
    const [shiftWorkdays, setShiftWorkdays] = useState<[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [shiftFilter, setShiftFilter] = useState<ShiftFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [shiftFilter.page, shiftFilter.per_page, shiftFilter.sort, shiftFilter.order]);

    const getAllShifts = () => {
        getShifts(shiftFilter, {
            onSuccess: (response: ServiceResponse) => {
                setShifts(response.data.shifts);
                SetEventTypes(response.data.event_types);
                setShiftWorkdays(response.data.shift_workdays);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const destroy = (shiftId: number) => {
        const _text = 'Está a punto de eliminar el turno #' + shiftId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando turno...');
                        deleteShift(shiftId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El turno no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const showEdit = (shiftId: number) => {
        setShowingEdit(true);
        setShiftIdEdit(shiftId);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllShifts();
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setShiftIdEdit(-1);
    };

    return (
        <>
            <Breadcrumbs pageSection="Jornada" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nueva jornada" />}
                />
                <ShiftDataTable
                    shifts={shifts}
                    eventTypes={eventTypes}
                    shiftWorkdays={shiftWorkdays}
                    totalRows={totalRows}
                    filter={shiftFilter}
                    loading={fetchingGetShifts}
                    setFilter={setShiftFilter}
                    callbackSuccess={reloadTable}
                    destroy={destroy}
                    edit={showEdit}
                    onSearch={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear jornada"
                    backdrop={true}
                    showFooter={false}
                >
                    <ShiftCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar jornada de Trabajo"
                    backdrop={true}
                    showFooter={false}
                >
                    <ShiftEdit
                        shiftId={shiftIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Shifts;
