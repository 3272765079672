import React, { useState } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';

interface Props {
    handleColorChange: (color: string) => void;
    color: string;
}

const ColorPicker = ({ handleColorChange, color }: Props) => {
    return (
        <div className="col-md-12">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    marginBottom: '1.5rem'
                }}
            >
                <label>Color</label>
                <span>
                    <button
                        disabled
                        style={{
                            background: color,
                            padding: '1rem 2.4rem',
                            border: 'solid 0.08rem #CED4DA',
                            borderRadius: '0.3rem'
                        }}
                    ></button>
                </span>
            </div>
            <HexColorPicker style={{ width: 'auto' }} color={color} onChange={handleColorChange} />
        </div>
    );
};

export default ColorPicker;
