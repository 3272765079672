import React, { useEffect } from 'react';
import { RouteSegmentDataForm } from '../../../../app/models/RouteSegment';
import { Place } from '../../../../app/models/Place';
import { Currency } from '../../../../app/models/Currency';
import { Unit } from '../../../../app/models/Unit';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Toll } from '../../../../app/models/Toll';
import CurrencyInput from '../../../../components/inputs/CurrencyInput';

interface GeneralRouteSegmentProps {
    routeSegmentForm: RouteSegmentDataForm;
    setRouteSegmentForm: (routeSegment: RouteSegmentDataForm) => void;
    origins: Place[];
    destinations: Place[];
    tolls: Toll[];
    errorFields?: any;

    currencies: Currency[];

    units: Unit[];
}

const RouteSegmentForm = ({
    routeSegmentForm,
    setRouteSegmentForm,
    origins,
    destinations,
    tolls,
    currencies,
    units,
    errorFields
}: GeneralRouteSegmentProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    console.log(routeSegmentForm);
    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setRouteSegmentForm({ ...routeSegmentForm, [name]: value });
    };
    const handleRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (String(name) == 'rate') {
            const foundCurrency = currencies.find(
                (item) => item.id === routeSegmentForm.currency_id
            );
            const currency = foundCurrency ? foundCurrency.name : '';
            if (currency === 'CLP') {
                setRouteSegmentForm({ ...routeSegmentForm, [name]: value.replace(/\D/g, '') });

                return;
            }
        } else if (String(name) == 'rate_runner') {
            const foundCurrency = currencies.find(
                (item) => item.id === routeSegmentForm.currency_rate_runner_id
            );
            const currency = foundCurrency ? foundCurrency.name : '';
            if (currency === 'CLP') {
                setRouteSegmentForm({ ...routeSegmentForm, [name]: value.replace(/\D/g, '') });

                return;
            }
        } else if (String(name) == 'external_rate') {
            const foundCurrency = currencies.find(
                (item) => item.id === routeSegmentForm.currency_external_rate_id
            );
            const currency = foundCurrency ? foundCurrency.name : '';
            if (currency === 'CLP') {
                setRouteSegmentForm({ ...routeSegmentForm, [name]: value.replace(/\D/g, '') });

                return;
            }
        } else if (String(name) == 'commission') {
            const foundCurrency = currencies.find(
                (item) => item.id === routeSegmentForm.currency_commission_id
            );
            const currency = foundCurrency ? foundCurrency.name : '';
            if (currency === 'CLP') {
                setRouteSegmentForm({ ...routeSegmentForm, [name]: value.replace(/\D/g, '') });

                return;
            }
        }

        setRouteSegmentForm({ ...routeSegmentForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        // if(name == "currency_id"){
        //     obj.rate = format_currency(routeSegmentForm.rate, value);
        // } else if(name == "currency_rate_runner_id"){
        //     obj.rate_runner = format_currency(routeSegmentForm.rate_runner, value);
        // } else if(name == "currency_external_rate_id"){
        //     obj.external_rate = format_currency(routeSegmentForm.currency_external_rate_id, value);
        // } else if(name == "currency_commission_id"){
        //     obj.commission = format_currency(routeSegmentForm.currency_commission_id, value);
        // }

        setRouteSegmentForm({ ...routeSegmentForm, ...obj });
    };

    if (!routeSegmentForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Origen</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="origin_id"
                        id="origin_id"
                        inputValue={routeSegmentForm.origin_id}
                        options={SelectTwoMapperOptions(origins)}
                        hasError={fieldHasError('origin_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('origin_id', value.value)}
                        placeholder={'Seleccione origen'}
                        onFocus={() => onFocusRemove('origin_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('origin_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Destino</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="destiny_id"
                        id="destiny_id"
                        inputValue={routeSegmentForm.destiny_id}
                        options={SelectTwoMapperOptions(destinations)}
                        hasError={fieldHasError('destiny_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('destiny_id', value.value)}
                        placeholder={'Seleccione destino'}
                        onFocus={() => onFocusRemove('destiny_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('destiny_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Distancia</label>
                <div className="col-md-9">
                    <input
                        name="distance"
                        id="distance"
                        type="number"
                        className={`form-control ${fieldHasError('distance')}`}
                        value={routeSegmentForm.distance}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('distance')}
                        placeholder="Ingrese distancia del tramo"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('distance')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Peajes</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="tolls"
                        id="tolls"
                        inputValue={routeSegmentForm.tolls}
                        options={SelectTwoMapperOptions(tolls)}
                        hasError={fieldHasError('tolls') !== ''}
                        onChange={(value: any) =>
                            setRouteSegmentForm({
                                ...routeSegmentForm,
                                tolls: value
                            })
                        }
                        isSortable={true}
                        isMulti={true}
                        placeholder={'Seleccione peaje(s)'}
                        onFocus={() => onFocusRemove('tolls')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('tolls')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Tarifa Transportista Externo</label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-6">
                            <CurrencyInput
                                name={'external_rate'}
                                currency={routeSegmentForm.currency_external_rate_id}
                                currencies={currencies}
                                onChange={handleRateChange}
                                value={routeSegmentForm.external_rate}
                                className={`form-control ${fieldHasError(`external_rate`)}`}
                                onFocus={() => onFocusRemove(`external_rate`)}
                                placeholder="Ingrese tarifa"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('external_rate')}
                            </div>
                        </div>

                        <div className="col-3">
                            <SelectTwo
                                name="currency_external_rate_id"
                                id="currency_external_rate_id"
                                inputValue={routeSegmentForm.currency_external_rate_id}
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_external_rate_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('currency_external_rate_id', value.value)
                                }
                                onFocus={() => onFocusRemove('currency_external_rate_id')}
                                placeholder={'Moneda'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_external_rate_id')}
                            </div>
                        </div>

                        <div className="col-3">
                            <SelectTwo
                                name="unit_external_rate_id"
                                id="unit_external_rate_id"
                                inputValue={routeSegmentForm.unit_external_rate_id}
                                options={SelectTwoMapperOptions(units)}
                                hasError={fieldHasError('unit_external_rate_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('unit_external_rate_id', value.value)
                                }
                                onFocus={() => onFocusRemove('unit_external_rate_id')}
                                placeholder={'unidad'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('unit_external_rate_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Tarifa de venta</label>
                <div className="col-md-12 ">
                    <div className="row g-1">
                        <div className="col-6">
                            <CurrencyInput
                                name={'rate'}
                                currency={routeSegmentForm.currency_id}
                                currencies={currencies}
                                onChange={handleRateChange}
                                value={routeSegmentForm.rate}
                                className={`form-control ${fieldHasError(`rate`)}`}
                                onFocus={() => onFocusRemove(`rate`)}
                                placeholder="Ingrese tarifa"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('rate')}
                            </div>
                        </div>

                        <div className="col-3">
                            <SelectTwo
                                name="currency_id"
                                id="currency_id"
                                inputValue={routeSegmentForm.currency_id}
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('currency_id', value.value)
                                }
                                onFocus={() => onFocusRemove('currency_id')}
                                placeholder={'moneda'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_id')}
                            </div>
                        </div>
                        <div className="col-3">
                            <SelectTwo
                                name="unit_id"
                                id="unit_id"
                                inputValue={routeSegmentForm.unit_id}
                                options={SelectTwoMapperOptions(units)}
                                hasError={fieldHasError('unit_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('unit_id', value.value)
                                }
                                onFocus={() => onFocusRemove('unit_id')}
                                placeholder={'unidad'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('unit_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Tarifa Runner</label>
                <div className="col-md-12 ">
                    <div className="row g-1">
                        <div className="col-6">
                            <CurrencyInput
                                name={'rate_runner'}
                                currency={routeSegmentForm.currency_rate_runner_id}
                                currencies={currencies}
                                onChange={handleRateChange}
                                value={routeSegmentForm.rate_runner}
                                className={`form-control ${fieldHasError(`rate_runner`)}`}
                                onFocus={() => onFocusRemove(`rate_runner`)}
                                placeholder="Ingrese tarifa"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('rate_runner')}
                            </div>
                        </div>

                        <div className="col-3">
                            <SelectTwo
                                name="currency_rate_runner_id"
                                id="currency_rate_runner_id"
                                inputValue={routeSegmentForm.currency_rate_runner_id}
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_rate_runner_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('currency_rate_runner_id', value.value)
                                }
                                onFocus={() => onFocusRemove('currency_rate_runner_id')}
                                placeholder={'moneda'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_rate_runner_id')}
                            </div>
                        </div>
                        <div className="col-3">
                            <SelectTwo
                                name="quantity_unit_rate_runner_id"
                                id="quantity_unit_rate_runner_id"
                                inputValue={routeSegmentForm.quantity_unit_rate_runner_id}
                                options={SelectTwoMapperOptions(units)}
                                hasError={fieldHasError('quantity_unit_rate_runner_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo(
                                        'quantity_unit_rate_runner_id',
                                        value.value
                                    )
                                }
                                onFocus={() => onFocusRemove('quantity_unit_rate_runner_id')}
                                placeholder={'unidad'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('quantity_unit_rate_runner_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-12 col-form-label"> Comisión Conductor </label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-6">
                            <CurrencyInput
                                name={'commission'}
                                currency={routeSegmentForm.currency_commission_id}
                                currencies={currencies}
                                onChange={handleRateChange}
                                value={routeSegmentForm.commission}
                                className={`form-control ${fieldHasError(`commission`)}`}
                                onFocus={() => onFocusRemove(`commission`)}
                                placeholder="Ingrese tarifa"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('commission')}
                            </div>
                        </div>

                        <div className="col-3">
                            <SelectTwo
                                name="currency_commission_id"
                                id="currency_commission_id"
                                inputValue={routeSegmentForm.currency_commission_id}
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_commission_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('currency_commission_id', value.value)
                                }
                                onFocus={() => onFocusRemove('currency_commission_id')}
                                placeholder={'Moneda'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_commission_id')}
                            </div>
                        </div>

                        <div className="col-3">
                            <SelectTwo
                                name="unit_commission_id"
                                id="unit_commission_id"
                                inputValue={routeSegmentForm.unit_commission_id}
                                options={SelectTwoMapperOptions(units)}
                                hasError={fieldHasError('unit_commission_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('unit_commission_id', value.value)
                                }
                                onFocus={() => onFocusRemove('unit_commission_id')}
                                placeholder={'unidad'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('unit_commission_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RouteSegmentForm;
