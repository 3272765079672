import React, { useContext, useState, useEffect } from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useShiftService from '../../../app/services/hooks/useShiftService';
import ShiftForm from './forms/ShiftForm';
import { ShiftDataForm } from '../../../app/models/Shift';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ShiftCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingStoreShift, storeShift, createShift } = useShiftService();
    const [errorFields, setErrorFields] = useState<any>();
    const [eventTypes, setEventTypes] = useState<any>();
    const [shift, setShift] = useState<ShiftDataForm>({ name: '' });

    const cancel = () => {
        if (onCancel) onCancel();
    };

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createShift({
            onSuccess: (response: ServiceResponse) => {
                setEventTypes(response.data.event_types);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando turno...');
        storeShift(shift, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                setErrorFields([]);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ShiftForm
                        shiftDataForm={shift}
                        workdays={[]}
                        setShiftDataForm={setShift}
                        errorFields={errorFields}
                        eventTypes={eventTypes}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreShift} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreShift} />
                </div>
            </div>
        </>
    );
};

export default ShiftCreate;
