import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { WorkerDocument } from '../../models/Workers';
import { DocumentNameFilters } from '../../models/Document';

const useWorkerDocumentService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetWorkerDocuments, setFetchingGetWorkerDocuments] = useState(false);
    const [fetchingStoreWorkerDocument, setFetchingStoreWorkerDocument] = useState(false);
    const [
        fetchingGetWorkerDocumentHistoryByWrokerAndDocumentType,
        setFetchingGetWorkerDocumentHistoryByWorkerAndDocumentType
    ] = useState(false);

    const getWorkerDocumentsByWorker = (worker_id: number, filters: DocumentNameFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = EndPoints.WORKER_DOCUMENTS.GET_WORKER_DOCUMENTS_BY_WORKER.replace(
            ':id',
            worker_id.toString()
        );
        doGet({
            ...events,
            url: `${url}?${queryString}`,
            setFetching: setFetchingGetWorkerDocuments
        });
    };

    const storeWorkerDocument = (workerDocument: WorkerDocument, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.WORKER_DOCUMENTS.STORE_WORKER_DOCUMENT,
            requestType: 'multipart',
            body: workerDocument,
            setFetching: setFetchingStoreWorkerDocument
        });
    };

    const getWorkerDocumentHistoryByWorkerAndDocumentType = (
        worker_id: number,
        document_id: number,
        events: ServiceEvents = {}
    ) => {
        const url = `${EndPoints.WORKER_DOCUMENTS.HISTORY_BY_WORKER_AND_TYPE}`;
        doGet({
            ...events,
            url: url
                .replaceAll(':worker_id', worker_id.toString())
                .replaceAll(':document_id', document_id.toString()),
            setFetching: setFetchingGetWorkerDocumentHistoryByWorkerAndDocumentType
        });
    };

    return {
        fetchingGetWorkerDocuments,
        fetchingStoreWorkerDocument,
        fetchingGetWorkerDocumentHistoryByWrokerAndDocumentType,
        getWorkerDocumentsByWorker,
        getWorkerDocumentHistoryByWorkerAndDocumentType,
        storeWorkerDocument
    };
};

export default useWorkerDocumentService;
