import { useContext, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

import { ServiceEvents } from '../shared/interfaces';
import { LoadTicketDataForm, LoadTicketFilters } from '../../models/LoadTicket';
import { fetchDefaultHeaders } from '../shared/vars';
import { AppContext } from '../../../contexts/AppContext';
import {
    LoadTicketHistoryDataForm,
    LoadTicketHistoryFilter
} from '../../models/LoadTicketsHistory';

const useLoadTicketService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetLoadTicketsByTransportOrder, setFetchingGetLoadTicketsByTransportOrder] =
        useState(false);
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [fetchingGetLoadTickets, setFetchingGetLoadTickets] = useState(false);
    const [fetchingStoreLoadTicket, setFetchingStoreLoadTicket] = useState(false);
    const [fetchingEditLoadTicket, setFetchingEditLoadTicket] = useState(false);
    const [fetchingUpdateLoadTicket, setFetchingUpdateLoadTicket] = useState(false);
    const [fetchingDeleteLoadTicket, setFetchingDeleteLoadTicket] = useState(false);
    const [fetchingCreateLoadTicket, setFetchingCreateLoadTicket] = useState(false);
    const [fetchingShowLoadTicket, setFetchingShowLoadTicket] = useState(false);
    const [fetchingGetUrlDocument, setFetchingGetUrlDocument] = useState(false);
    const [fetchingGetUrlDocumentOnTransportOrder, setFetchingGetUrlDocumentOnTransportOrder] =
        useState(false);
    const [fetchingApprovedLoadTicket, setFetchingApprovedLoadTicket] = useState(false);
    const [fetchingCancelIsApprovedLoadTicket, setFetchingCancelIsApprovedLoadTicket] = useState(false);

    const getAllLoadTicketsByTransportOrder = (
        transport_order_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.LOAD_TICKETS.GET_LOAD_TICKET_BY_TRANSPORT_ORDER.replace(
                ':transport_order_id',
                transport_order_id.toString()
            ),
            setFetching: setFetchingGetLoadTicketsByTransportOrder
        });
    };

    const getLoadTickets = (filter: LoadTicketFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.LOAD_TICKETS.GET_LOAD_TICKETS}?${queryString}`;
        console.log(url);
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetLoadTickets
        });
    };

    const createLoadTicket = (transportOrderId: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.LOAD_TICKETS.CREATE_LOAD_TICKET.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ),
            setFetching: setFetchingCreateLoadTicket
        });
    };

    const storeLoadTicket = (
        transportOrderId: number,
        LoadTicketDataForm: LoadTicketDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.LOAD_TICKETS.STORE_LOAD_TICKET.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ),
            body: LoadTicketDataForm,
            setFetching: setFetchingStoreLoadTicket
        });
    };

    const editLoadTicket = (transportOrderId: number, id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.LOAD_TICKETS.EDIT_LOAD_TICKET.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingEditLoadTicket
        });
    };

    const updateLoadTicket = (
        transportOrderId: number,
        id: number,
        LoadTicketDataForm: LoadTicketDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.LOAD_TICKETS.UPDATE_LOAD_TICKET.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            body: LoadTicketDataForm,
            setFetching: setFetchingUpdateLoadTicket
        });
    };

    // const showLoadTicket = (id: number, events: ServiceEvents = {}) => {
    //     doGet({
    //         ...events,
    //         url: EndPointsPegasus.LOAD_TICKETS.SHOW_LOAD_TICKET.replace(
    //             ':id',
    //             id.toString()
    //         ),
    //         setFetching: setFetchingShowLoadTicket
    //     });
    // };

    const deleteLoadTicket = (transportOrderId: number, id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.LOAD_TICKETS.DELETE_LOAD_TICKET.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingDeleteLoadTicket
        });
    };

    const getUrlDocumentLoadTicket = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.LOAD_TICKETS.DOCUMENT_LOAD_TICKET.replace(':id', id.toString()),
            setFetching: setFetchingGetUrlDocument
        });
    };

    const getUrlDocumentLoadTicketOnTransportOrder = (
        transportOrderId: number,
        id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.LOAD_TICKETS.DOCUMENT_LOAD_TICKET_ON_TRANSPORT_ORDER.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingGetUrlDocumentOnTransportOrder
        });
    };

    const downloadDocument = (url_file: string) => {
        const link = document.createElement('a');
        link.href = url_file;
        link.download = 'documento ticket descarga';
        link.target = '_blank';
        document.body.appendChild(link);

        // Simular un clic en el enlace para iniciar la descarga
        link.click();
        if (changeAnimation)
            changeAnimation('downloadSuccess', 'archivo recuperado con exito', true);
    };

    const approvedLoadTicket = (
        transportOrderId: number,
        loadTicketId: number,
        formData: LoadTicketHistoryDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.LOAD_TICKETS.APPROVED_LOAD_TICKET.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', loadTicketId.toString()),
            body: formData,
            setFetching: setFetchingApprovedLoadTicket
        });
    };

    const cancelIsApprovedLoadTicket = (
        id: number,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.LOAD_TICKETS.CANCEL_APPROVED_LOAD_TICKET.replace(':id', id.toString()),
            setFetching: setFetchingCancelIsApprovedLoadTicket
        });
    };

    return {
        getAllLoadTicketsByTransportOrder,
        createLoadTicket,
        storeLoadTicket,
        editLoadTicket,
        updateLoadTicket,
        // showLoadTicket,
        deleteLoadTicket,
        approvedLoadTicket,
        getLoadTickets,
        getUrlDocumentLoadTicket,
        getUrlDocumentLoadTicketOnTransportOrder,
        downloadDocument,
        cancelIsApprovedLoadTicket,
        fetchingGetLoadTicketsByTransportOrder,
        fetchingGetLoadTickets,
        fetchingGetUrlDocument,
        fetchingGetUrlDocumentOnTransportOrder,
        fetchingStoreLoadTicket,
        fetchingEditLoadTicket,
        fetchingUpdateLoadTicket,
        fetchingShowLoadTicket,
        fetchingDeleteLoadTicket,
        fetchingCreateLoadTicket,
        fetchingApprovedLoadTicket,
        fetchingCancelIsApprovedLoadTicket
    };
};

export default useLoadTicketService;
