import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { UserFilters } from '../../../../app/models/User';
import { Worker } from '../../../../app/models/Workers';
import WorkerFilter from './WorkerFilter';
import WorkerSwitchChange from '../WorkerSwitchChange';
import { momentParseDate } from '../../../../helpers';

interface Props {
    workers: Worker[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    showRequests?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: UserFilters;
    setFilter: (filter: UserFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const WorkersDataTable = ({
    workers,
    totalRows,
    loading = false,
    edit,
    destroy,
    showRequests,
    // active,
    show,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'RUT',
            selector: (row: any) => row.rut,
            sortable: true,
            sortField: 'rut',
            cell: (row: any, index: any, column: any) => <span className="">{row.user.rut}</span>
        },
        {
            name: 'Nombre',
            selector: (row: any) => row.first_name,
            sortable: true,
            sortField: 'first_name',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row?.user.first_name}</span>
            )
        },
        {
            name: 'Apellido',
            selector: (row: any) => row.last_name,
            sortable: true,
            sortField: 'last_name',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {row?.user.last_name} {row?.user.second_last_name}
                </span>
            )
        },
        {
            name: 'Email',
            selector: (row: any) => row.email,
            sortable: true,
            sortField: 'email',
            cell: (row: any, index: any, column: any) => <span className="">{row?.user.email}</span>
        },
        {
            name: 'Contrato',
            selector: (row: any) => row.contract,
            sortable: false,
            sortField: 'contract',
            cell: (row: Worker, index: any, column: any) => (
                <span className="">
                    {row?.contract
                        ? row?.contract?.is_indefinite
                            ? `${row?.contract.id}.- ${row?.contract?.job_title?.name} - Indefinido`
                            : `${row?.contract.id}.- ${row?.contract?.job_title?.name} - Temporal`
                        : ''}
                </span>
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: any, index: any, column: any) => (
                <WorkerSwitchChange
                    checkValue={row.is_active ?? true}
                    WorkerId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {showRequests && (
                        <ButtonTableAction
                            callbackFunction={() => showRequests(row.id ?? -1)}
                            classIcon={'mdi mdi-more'}
                            colorIcon={'text-success'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Solicitudes'}
                        />
                    )}
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-calendar'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este calendario.'}
                            title={'Calendario'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <WorkerFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>
            <DefaultDataTable
                columns={columns}
                data={workers}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default WorkersDataTable;
