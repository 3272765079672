import { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import LazyLoading from '../../../../components/LazyLoading';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../contexts/AppContext';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import useEventService from '../../../../app/services/hooks/useEventService';
import { CalendarEventDataForm } from '../../../../app/models/Event';
import {
    defaultShiftDataForm,
    Shift,
    ShiftDays,
    ShiftWorkDayTravelExpense
} from '../../../../app/models/Shift';
import { TravelExpenseDataForm } from '../../../../app/models/TravelExpense';
import WorkerEventEditForm from '../../../Workers/components/forms/WorkerEventEditForm';
import useNavigationPage from '../../../../hooks/useNavigationPage';
import { momentParseDateToDate } from '../../../../helpers';
import { selectTravelExpenseById } from '../../../Workers/utils/shiftWorkTravelExpense';

interface Props {
    eventId: number;
    workerId: number;
    selectedEvent: CalendarEventDataForm;
    selectedTravelExpense?: TravelExpenseDataForm;
    shiftWorkDayTravelExpenses?: ShiftWorkDayTravelExpense[];
    setCalendarEventDataForm: (calendarEventDataForm: CalendarEventDataForm) => void;
    parentEvent: CalendarEventDataForm;
    setParentEvent: (calendarEventDataForm: CalendarEventDataForm) => void;
    shiftWorkUpdate: () => void;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CalendarEventEdit = ({
    eventId,
    workerId,
    selectedEvent,
    selectedTravelExpense,
    shiftWorkDayTravelExpenses,
    setCalendarEventDataForm,
    parentEvent,
    setParentEvent,
    shiftWorkUpdate,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingUpdateEvent, updateEvent, editEvent, fetchingEditEvent } = useEventService();
    const { navigationPage } = useNavigationPage();

    const [isShiftWork, setIsShiftWork] = useState(false);
    const [errorFields, setErrorFields] = useState<any>();
    const [eventTypes, setEventTypes] = useState<any>();
    const [travelExpenses, setTravelExpenses] = useState<TravelExpenseDataForm[]>([]);
    const [shiftWorks, setShiftWorks] = useState<Shift[]>([defaultShiftDataForm]);
    const [shiftWorkDays, setShiftWorkDays] = useState<ShiftDays[]>([]);
    const [filteredTravelExpenses, setFilteredTravelExpenses] = useState<any[]>([]);

    useEffect(() => {
        if (selectedEvent.event_type_id) {
            const selectedEventType = eventTypes?.find(
                (eventType: { id: number | undefined }) =>
                    eventType.id === selectedEvent.event_type_id
            );
            if (selectedEventType && selectedEventType.is_travel_expense && selectedEventType.id) {
                const selectedEventTypeId = selectedEventType.id;
                const filteredTravelExpensesByIds = travelExpenses.filter((travelExpense) =>
                    travelExpense?.event_type_ids?.includes(selectedEventTypeId)
                );

                const filteredTravelExpenses = filteredTravelExpensesByIds.filter(
                    (travelExpense) => {
                        const traExStDate = momentParseDateToDate(travelExpense.start_date);
                        const traExEndDate = momentParseDateToDate(travelExpense.end_date);
                        const caEventStDate = momentParseDateToDate(selectedEvent.start_date);
                        if (caEventStDate && traExStDate && traExEndDate) {
                            if (caEventStDate >= traExStDate && caEventStDate < traExEndDate) {
                                return travelExpense;
                            }
                        }
                    }
                );
                setFilteredTravelExpenses(filteredTravelExpenses);
            }
        }
    }, [selectedEvent.event_type_id, eventTypes, travelExpenses]);

    useEffect(() => {
        if (parentEvent.shift_work_id) {
            const filteredShiftWorkDays = shiftWorkDays?.filter(
                (item) => item.shift_work_id === parentEvent.shift_work_id
            );
            const updatedShiftWorks: any[] = [];
            filteredShiftWorkDays?.forEach((shiftWorkDay, index) => {
                const positionTravelExpense = shiftWorkDayTravelExpenses?.find(
                    (swte) => swte.position == index
                );
                updatedShiftWorks.push({
                    ...shiftWorkDay,
                    travel_expense_id: positionTravelExpense?.travel_expense_id,
                    travel_expenses: selectTravelExpenseById(
                        travelExpenses,
                        shiftWorkDay.event_type?.id,
                        selectedEvent?.start_date
                    )
                });
            });
            setParentEvent({
                ...parentEvent,
                shift_days: updatedShiftWorks
            });
        }
    }, [parentEvent.shift_work_id, shiftWorkDays]);

    useEffect(() => {
        edit();
    }, [eventId]);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando gasto de viaje...');
        editEvent({
            onSuccess: (response: ServiceResponse) => {
                setEventTypes(response.data.event_types);
                setTravelExpenses(response.data.travel_expenses);
                setShiftWorks(response.data.shift_works);
                setShiftWorkDays(response.data.shift_work_days);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando Gasto de viaje...');
        updateEvent(eventId, selectedEvent, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                navigationPage(`/workers/${workerId}/edit?defaultActiveKey=calendar`);
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const handleUpdate = () => {
        if (isShiftWork) {
            shiftWorkUpdate();
        } else {
            update();
        }
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditEvent ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <WorkerEventEditForm
                        calendarEventDataForm={selectedEvent}
                        setCalendarEventDataForm={setCalendarEventDataForm}
                        eventTypes={eventTypes}
                        shiftWorks={shiftWorks}
                        travelExpenses={travelExpenses}
                        filteredTravelExpenses={filteredTravelExpenses}
                        selectedTravelExpense={selectedTravelExpense}
                        shiftWorkEvent={parentEvent}
                        setShiftWorkEvent={setParentEvent}
                        showIsShiftWork={isShiftWork}
                        setShowIsShiftWork={setIsShiftWork}
                        editable={true}
                        editableDate={false}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingUpdateEvent} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={handleUpdate} locked={fetchingUpdateEvent} />
                </div>
            </div>
        </>
    );
};

export default CalendarEventEdit;
