import React, { useState, useContext, useEffect } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import DocumentSetDataTable from './components/tables/DocumentSetDataTable';
import useDocumentSetService from '../../app/services/hooks/useDocumentSetService';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import { toast } from 'react-toastify';
import { DocumentSetDataForm, DocumentSetFilters } from '../../app/models/DocumentSet';
import useNavigationPage from '../../hooks/useNavigationPage';
import DefaultModal from '../../components/default/DefaultModal';
import DocumentSetCreate from './components/subcomponents/DocumentSetCreate';
import DocumentSetEdit from './components/subcomponents/DocumentSetEdit';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Set de Documentos',
        url: '/document-sets',
        isActive: true
    }
];

const DocumentSet = () => {
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [documentSetIdEdit, setDocumentSetIdEdit] = useState<number>(-1);
    const { activeDocumentSet, getDocumentSets, fetchingGetDocumentSets, deleteDocumentSet } =
        useDocumentSetService();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { navigationPage } = useNavigationPage();
    const [documentSets, setDocumentSets] = useState<DocumentSetDataForm[]>([]);
    const [totalRows, setTotalRows] = useState(0);

    const [filter, setFilter] = useState<DocumentSetFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        name: ''
    });

    useEffect(() => {
        reloadTable();
    }, []);

    const reloadTable = () => {
        getDocumentSets(filter, {
            onSuccess: (response: ServiceResponse) => {
                setDocumentSets(response.data.document_sets);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const active = (documentSetId: number, active: boolean) => {
        activeDocumentSet(documentSetId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const destroy = (documentSetId: number) => {
        const _text = 'Está a punto de eliminar el documento #' + documentSetId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando documento...');
                        deleteDocumentSet(documentSetId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El documento no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (documentSetId: number) => {
        setShowingEdit(true);
        setDocumentSetIdEdit(documentSetId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setDocumentSetIdEdit(-1);
    };

    return (
        <>
            <Breadcrumbs pageSection="Set de Documentos" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <ButtonCreate
                                    callbackCreate={showCreate}
                                    title="Nuevo Set de documentos"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <DocumentSetDataTable
                            active={active}
                            documentSets={documentSets}
                            totalRows={totalRows}
                            loading={fetchingGetDocumentSets}
                            destroy={destroy}
                            edit={showEdit}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                            onSearch={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Set de Documentos"
                    backdrop={true}
                    showFooter={false}
                >
                    <DocumentSetCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar documento"
                    backdrop={true}
                    showFooter={false}
                >
                    <DocumentSetEdit
                        documentSetId={documentSetIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default DocumentSet;
