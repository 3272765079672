import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useExpenseService from '../../../app/services/hooks/useExpenseService';
import { ExpenseHistoryDataForm } from '../../../app/models/ExpenseHistory';
import ExpenseHistoryForm from './forms/ExpenseHistoryForm';

interface Props {
    expenseId: number;
    errorFields?: any;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    getApprovedExpense: (expenseId: number, expenseHistory: ExpenseHistoryDataForm) => void;
}

const ExpenseHistoryCreate = ({
    onSaved,
    onCancel,
    onError,
    errorFields,
    getApprovedExpense,
    expenseId
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const { fetchingApprovedExpense, approvedExpense } = useExpenseService();

    const [expenseHistory, setExpenseHistory] = useState<ExpenseHistoryDataForm>({
        comment: '',
        is_approved: false
    });

    const store = () => {
        getApprovedExpense(expenseId, expenseHistory);
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ExpenseHistoryForm
                        expenseHistoryDataForm={expenseHistory}
                        setExpenseHistoryDataForm={setExpenseHistory}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingApprovedExpense} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingApprovedExpense} />
                </div>
            </div>
        </>
    );
};

export default ExpenseHistoryCreate;
