import { useContext, useEffect, useState } from 'react'
import { ServiceResponse } from '../../../app/services/shared/interfaces'
import { toast } from 'react-toastify'
import LazyLoading from '../../../components/LazyLoading'
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm'
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm'
import { AppContext } from '../../../contexts/AppContext'
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert'
import { DeductionRateDataForm, defaultDeductionRateDataForm } from '../../../app/models/DeductionRate'
import DeductionRateForm from '../components/forms/DeductionRateForm'
import { DeductionsBasisValues, TypeOfDeductionsValues } from '../../../app/models/Deduction'
import { Currency } from '../../../app/models/Currency'
import useDeductionRateService from '../../../app/services/hooks/useDeductionRateService'
import { getCurrentMonth } from '../../../helpers'
import useCalculatedFieldService from '../../../app/services/hooks/useCalculatedFielService'
import { Item } from '../../../app/models/Item'

interface Props {
    deductionId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DeductionRateCreate = ({ onSaved, onCancel, onError, deductionId }: Props) => {

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { createCalculatedField } = useCalculatedFieldService()
    const { createDeductionRate, storeDeductionRate, fetchingCreateDeductionRate, fetchingStoreDeductionRate } =
        useDeductionRateService();

    const [deductionRate, setDeductionRate] = useState<DeductionRateDataForm>({
        ...defaultDeductionRateDataForm,
        valid_from: getCurrentMonth(),
        valid_until: getCurrentMonth(),
        deduction_id: deductionId
    });
    
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [deductionTypes, setDeductionTypes] = useState<TypeOfDeductionsValues[]>([]);
    const [deductionBasis, setDeductionBasis] = useState<DeductionsBasisValues[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
    const [items, setItems] = useState<Item[]>([]);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createDeductionRate({
            onSuccess: (response: ServiceResponse) => {
                setDeductionTypes(response.data.type_of_deductions);
                setCurrencies(response.data.currencies);
                setDeductionBasis(response.data.deduction_basis)
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {
        if (deductionRate.deduction_type === 'Calculado' && items.length < 1) createCalculatedFields();
    },[deductionRate])

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando tarifa de deducción...');
        storeDeductionRate(deductionRate, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const createCalculatedFields = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createCalculatedField(deductionId, 'deduction', {
            onSuccess: (response: ServiceResponse) => {
                setItems(response.data.item_list)
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateDeductionRate ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <DeductionRateForm 
                        deductionRateForm={deductionRate} 
                        setDeductionRateForm={setDeductionRate}
                        TypeOfDeductions={deductionTypes}
                        DeductionBasis={deductionBasis}
                        currencies={currencies}
                        errorFields={errorFields}
                        items={items}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreDeductionRate} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreDeductionRate} />
                </div>
            </div>
        </>
    );
}

export default DeductionRateCreate