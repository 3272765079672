import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

import { ServiceEvents } from '../shared/interfaces';
import { TollRateDataForm } from '../../models/TollRate';

const useTollRateService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetTollRatesByToll, setFetchingGetTollRatesByToll] = useState(false);
    const [fetchingStoreTollRate, setFetchingStoreTollRate] = useState(false);
    const [fetchingEditTollRate, setFetchingEditTollRate] = useState(false);
    const [fetchingUpdateTollRate, setFetchingUpdateTollRate] = useState(false);
    const [fetchingDeleteTollRate, setFetchingDeleteTollRate] = useState(false);
    const [fetchingCreateTollRate, setFetchingCreateTollRate] = useState(false);
    const [fetchingShowTollRate, setFetchingShowTollRate] = useState(false);
    const [fetchingGetTollRateByToll, setFetchingGetTollRateByToll] = useState(false);

    const getAllTollRatesByToll = (work_order_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TOLL_RATES.GET_TOLL_RATES_BY_TOLL.replace(
                ':toll_id',
                work_order_id.toString()
            ),
            setFetching: setFetchingGetTollRatesByToll
        });
    };

    const createTollRate = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TOLL_RATES.CREATE_TOLL_RATE,
            setFetching: setFetchingCreateTollRate
        });
    };

    const storeTollRate = (TollRateDataForm: TollRateDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TOLL_RATES.STORE_TOLL_RATE,
            body: TollRateDataForm,
            setFetching: setFetchingStoreTollRate
        });
    };

    const editTollRate = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TOLL_RATES.EDIT_TOLL_RATE.replace(':id', id.toString()),
            setFetching: setFetchingEditTollRate
        });
    };

    const updateTollRate = (
        id: number,
        TollRateDataForm: TollRateDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.TOLL_RATES.UPDATE_TOLL_RATE.replace(':id', id.toString()),
            body: TollRateDataForm,
            setFetching: setFetchingUpdateTollRate
        });
    };
    const showTollRate = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TOLL_RATES.SHOW_TOLL_RATE.replace(':id', id.toString()),
            setFetching: setFetchingShowTollRate
        });
    };

    const deleteTollRate = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.TOLL_RATES.DELETE_TOLL_RATE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteTollRate
        });
    };

    const getTollRateByToll = (
        transport_order_id: number,
        toll_id: number,
        toll_expense_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.TOLL_RATES.GET_TOLL_RATE_BY_TOLL.replace(
                ':transport_order_id',
                transport_order_id.toString()
            )
                .replace(':toll_id', toll_id.toString())
                .replace(':toll_expense_id', toll_expense_id.toString()),
            setFetching: setFetchingGetTollRateByToll
        });
    };

    return {
        getAllTollRatesByToll,
        createTollRate,
        storeTollRate,
        editTollRate,
        updateTollRate,
        showTollRate,
        deleteTollRate,
        getTollRateByToll,
        fetchingGetTollRatesByToll,
        fetchingStoreTollRate,
        fetchingEditTollRate,
        fetchingUpdateTollRate,
        fetchingShowTollRate,
        fetchingDeleteTollRate,
        fetchingCreateTollRate,
        fetchingGetTollRateByToll
    };
};

export default useTollRateService;
