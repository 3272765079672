import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { CarrierDocument } from '../../models/Carrier';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { TruckDocument } from '../../models/Truck';
import { DocumentNameFilters } from '../../models/Document';

const useTruckDocumentService = () => {
    const { doGet, doPost } = useFetch();

    const [
        fetchingGetTruckDocumentsByTruckAndDocumentType,
        setFetchingGetTruckDocumentsByTruckAndDocumentType
    ] = useState(false);
    const [fetchingGetTruckDocuments, setFetchingGetTruckDocuments] = useState(false);
    const [fetchingStoreTruckDocument, setFetchingStoreTruckDocument] = useState(false);

    const getTruckDocumentsByTruck = (truck_id: number, filters: DocumentNameFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = EndPoints.TRUCK_DOCUMENT.GET_TRUCK_DOCUMENTS_BY_TRUCK.replace(
            ':id',
            truck_id.toString()
        );
        doGet({
            ...events,
            url: `${url}?${queryString}`,
            setFetching: setFetchingGetTruckDocuments
        });
    };

    const getTruckDocumentByTruckAndDocumentType = (
        truck_id: number,
        document_id: number,
        events: ServiceEvents = {}
    ) => {
        const url = `${EndPoints.TRUCK_DOCUMENT.HISTORY_BY_TRUCK_AND_TYPE}`;
        doGet({
            ...events,
            url: url
                .replaceAll(':truck_id', truck_id.toString())
                .replaceAll(':document_id', document_id.toString()),
            setFetching: setFetchingGetTruckDocumentsByTruckAndDocumentType
        });
    };

    const storeTruckDocument = (truckDocument: TruckDocument, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TRUCK_DOCUMENT.STORE_TRUCK_DOCUMENT,
            requestType: 'multipart',
            body: truckDocument,
            setFetching: setFetchingStoreTruckDocument
        });
    };

    return {
        fetchingGetTruckDocumentsByTruckAndDocumentType,
        fetchingGetTruckDocuments,
        storeTruckDocument,
        getTruckDocumentsByTruck,
        getTruckDocumentByTruckAndDocumentType,
        fetchingStoreTruckDocument
    };
};

export default useTruckDocumentService;
