import { useContext, useEffect, useState } from 'react';
import useDeductionService from '../../../app/services/hooks/useDeductionServices';
import LazyLoading from '../../../components/LazyLoading';
import { Deduction, TypeOfDeductionsValues } from '../../../app/models/Deduction';
import DeductionFormContainer from '../components/DeductionFormContainer';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { Currency } from '../../../app/models/Currency';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import DeductionForm from '../components/forms/DeductionForm';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import DeductionRateContainer from '../components/DeductionRateContainer';

type RouterParams = {
    id: string;
};

const DeductionEdit = () => {

    const history = useHistory();
    const { id } = useParams<RouterParams>();
    const { fetchingEditDeduction, editDeduction, fetchingUpdateDeduction, updateDeduction } =
        useDeductionService();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [errorFields, setErrorFields] = useState<any>();
    const [deduction, setDeduction] = useState<Deduction>({ name: '' });


    useEffect(() => {
        edit(parseInt(id));
    }, [id]);

    const edit = (id: number) => {
        if (showLoading) showLoading('loading', 'Cargando deducción...');
        editDeduction(id, {
            onSuccess: (response: ServiceResponse) => {
                setDeduction(response.data.deduction);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando deducción...');
        updateDeduction(parseInt(id), deduction, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Deducciones',
            url: '/deductions',
            isActive: false
        },
        {
            name: 'Editar #' + id,
            url: '/deductions/' + id + '/edit',
            isActive: true
        }
    ]


    return fetchingEditDeduction ? (
        <LazyLoading height="300" />
    ) : (
        <>
            <Breadcrumbs pageSection={`Editar #${id}`} breadcrumbs={breadcrumbs} />
            <div className='row'>
                <div className='col-md-6'>
                    <DefaultCard>
                        <DeductionForm
                            deductionForm={deduction}
                            setDeductionForm={setDeduction}
                            errorFields={errorFields}
                        />
                        <div className='row justify-content-end'>
                            <div className='col-auto'>
                                <ButtonSaveForm callbackSave={update} locked={fetchingUpdateDeduction} />
                            </div>
                        </div>
                    </DefaultCard>
                </div>
            </div>
            <>
                <DeductionRateContainer deductionId={parseInt(id)} />
            </>
        </>
    );
}

export default DeductionEdit