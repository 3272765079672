import React, { useState } from 'react';
import RecoveryPasswordForm from './RecoveryPasswordForm';
import ChangePasswordForm from './ChangePasswordForm';

const RecoveryPassword = () => {
    const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
    const [successPassword, setSuccessPassword] = useState(false);

    return (
        <div className="vh-100 d-flex bg-primary">
            <div className="m-auto px-3 w-100" style={{ maxWidth: '380px' }}>
                <img src="" alt="" />
                <div className="card">
                    <h5 className="card-header bg-transparent border-bottom text-uppercase text-center">
                        Recuperar contraseña
                    </h5>
                    <div className="card-body">
                        {showChangePasswordForm ? (
                            successPassword ? (
                                <>
                                    <p>Se ha cambiado la contraseña correctamente.</p>
                                    <p>
                                        <a href="/login"> Iniciar sesión</a>
                                    </p>
                                </>
                            ) : (
                                <ChangePasswordForm
                                    setChangePassword={setSuccessPassword}
                                ></ChangePasswordForm>
                            )
                        ) : (
                            <RecoveryPasswordForm
                                setSendEmail={setShowChangePasswordForm}
                            ></RecoveryPasswordForm>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecoveryPassword;
