import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { Document, DocumentFilters } from '../../models/Document';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useDocumentService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetDocuments, setFetchingGetDocuments] = useState(false);
    const [fetchingCreateDocument, setFetchingCreateDocument] = useState(false);
    const [fetchingStoreDocument, setFetchingStoreDocument] = useState(false);
    const [fetchingEditDocument, setFetchingEditDocument] = useState(false);
    const [fetchingUpdateDocument, setFetchingUpdateDocument] = useState(false);
    const [fetchingDeleteDocument, setFetchingDeleteDocument] = useState(false);
    const [fetchingActiveDocument, setFetchingActiveDocument] = useState(false);

    const getDocuments = (DocumentFilters: DocumentFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(DocumentFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.DOCUMENTS.GET_DOCUMENTS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetDocuments
        });
    };

    const createDocument = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DOCUMENTS.CREATE_DOCUMENT,
            setFetching: setFetchingCreateDocument
        });
    };

    const storeDocument = (document: Document, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.DOCUMENTS.STORE_DOCUMENT,
            body: document,
            setFetching: setFetchingStoreDocument
        });
    };

    const editDocument = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DOCUMENTS.EDIT_DOCUMENT.replace(':id', id.toString()),
            setFetching: setFetchingEditDocument
        });
    };

    const activeDocument = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            body: { active: active },
            url: EndPoints.DOCUMENTS.ACTIVE_DOCUMENT.replace(':id', id.toString()),
            setFetching: setFetchingActiveDocument
        });
    };

    const updateDocument = (id: number, document: Document, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.DOCUMENTS.UPDATE_DOCUMENT.replace(':id', id.toString()),
            body: document,
            setFetching: setFetchingUpdateDocument
        });
    };

    const deleteDocument = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.DOCUMENTS.DELETE_DOCUMENT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteDocument
        });
    };

    return {
        fetchingGetDocuments,
        fetchingCreateDocument,
        fetchingEditDocument,
        fetchingStoreDocument,
        fetchingUpdateDocument,
        fetchingDeleteDocument,
        fetchingActiveDocument,
        getDocuments,
        createDocument,
        editDocument,
        storeDocument,
        updateDocument,
        deleteDocument,
        activeDocument
    };
};

export default useDocumentService;
