import React, { useEffect } from 'react';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Society } from '../../../../app/models/Society';
import { Client } from '../../../../app/models/Client';
import { Currency } from '../../../../app/models/Currency';
import { PaymentCondition } from '../../../../app/models/PaymentCondition';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuoteDataForm, QuoteOfferTypeDataForm } from '../../../../app/models/Quote';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { setCleanTextAreaError } from '../../../../utils/utils';
import { Unit } from '../../../../app/models/Unit';

interface GeneralQuoteFormProps {
    quoteForm: QuoteDataForm;
    setQuoteForm: (quote: QuoteDataForm) => void;
    societies: Society[];
    clients: Client[];
    currencies: Currency[];
    paymentConditions: PaymentCondition[];
    offerTypes: QuoteOfferTypeDataForm[];
    paymentMethods: PaymentCondition[];
    errorFields?: any;
    disableUpdate?: boolean;
}

const QuoteForm = ({
    quoteForm,
    setQuoteForm,
    societies,
    clients,
    paymentMethods,
    paymentConditions,
    offerTypes,
    currencies,
    errorFields,
    disableUpdate = false
}: GeneralQuoteFormProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setQuoteForm({ ...quoteForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setQuoteForm({ ...quoteForm, [name]: value });
    };

    const handleChangeSelectTwoClient = (name: string, value: number | string | null) => {
        setQuoteForm({
            ...quoteForm,
            [name]: value,
            contact_email: clients.find((client) => client.id == value)?.email_contact || ''
        });
    };

    if (!quoteForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Sociedad</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="society_id"
                        id="society_id"
                        inputValue={quoteForm.society_id}
                        options={SelectTwoMapperOptions(societies, 'id', ['rut', 'business_name'])}
                        hasError={fieldHasError('society_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('society_id', value.value)}
                        placeholder={'Seleccione una sociedad'}
                        onFocus={() => onFocusRemove('society_id')}
                        disabled={disableUpdate}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('society_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Cliente</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="client_id"
                        id="client_id"
                        inputValue={quoteForm.client_id}
                        options={SelectTwoMapperOptions(clients, 'id', ['rut', 'business_name'])}
                        hasError={fieldHasError('client_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwoClient('client_id', value.value)
                        }
                        placeholder={'Seleccione un cliente'}
                        onFocus={() => onFocusRemove('client_id')}
                        disabled={disableUpdate}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('client_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Contacto</label>
                <div className="col-md-9">
                    <input
                        name="contact_email"
                        id="contact_email"
                        type="email"
                        className={`form-control ${fieldHasError('contact_email')}`}
                        value={quoteForm.contact_email}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('contact_email')}
                        placeholder="Ingrese un correo de contacto"
                        disabled={disableUpdate}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('contact_email')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tipo de oferta</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="offer_type"
                        id="offer_type"
                        inputValue={quoteForm.offer_type}
                        hasError={fieldHasError('offer_type') !== ''}
                        options={SelectTwoMapperOptions(offerTypes)}
                        onChange={(value: any) => handleChangeSelectTwo('offer_type', value.value)}
                        onFocus={() => onFocusRemove('offer_type')}
                        placeholder="Seleccione Referencia"
                        disabled={disableUpdate}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('offer_type')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Forma de Pago</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="payment_method_id"
                        id="payment_method_id"
                        inputValue={quoteForm.payment_method_id}
                        options={SelectTwoMapperOptions(paymentMethods)}
                        hasError={fieldHasError('payment_method_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('payment_method_id', value.value)
                        }
                        onFocus={() => onFocusRemove('payment_method_id')}
                        placeholder={'Seleccione una forma de pago'}
                        disabled={disableUpdate}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('payment_method_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Condición de pago</label>
                <div className="col-md-9 ">
                    <div className="row g-1">
                        <div className="col-9">
                            <SelectTwo
                                name="payment_condition_id"
                                id="payment_condition_id"
                                inputValue={quoteForm.payment_condition_id}
                                hasError={fieldHasError('payment_condition_id') !== ''}
                                options={SelectTwoMapperOptions(paymentConditions)}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('payment_condition_id', value.value)
                                }
                                onFocus={() => onFocusRemove('payment_condition_id')}
                                placeholder="Seleccione una condición de pago"
                                disabled={disableUpdate}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('payment_condition_id')}
                            </div>
                        </div>

                        <div className="col-3">
                            <input
                                name="days_count"
                                id="days_count"
                                type="number"
                                className={`form-control ${fieldHasError('days_count')}`}
                                value={quoteForm.days_count}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('days_count')}
                                placeholder="días"
                                disabled={disableUpdate}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('days_count')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Moneda</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="currency_id"
                        id="currency_id"
                        inputValue={quoteForm.currency_id}
                        hasError={fieldHasError('currency_id') !== ''}
                        options={SelectTwoMapperOptions(currencies)}
                        onChange={(value: any) => handleChangeSelectTwo('currency_id', value.value)}
                        onFocus={() => onFocusRemove('currency_id')}
                        placeholder={'Seleccione una Moneda'}
                        disabled={disableUpdate}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('currency_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <OverlayTrigger
                    overlay={
                        <Tooltip id="my-tooltip-id">
                            La fecha de expiración se calcula con la cantidad de días después de que
                            se confirma la Oferta, Ej.: Si se confirma el 11 de junio y la cantidad
                            de días para expirar es de 4, la fecha de expiración será el 15 de junio
                        </Tooltip>
                    }
                >
                    <label className="col-md-3 col-form-label">Expira en días (Confirmada)</label>
                </OverlayTrigger>
                <div className="col-md-9">
                    <input
                        name="expired_in_days"
                        id="expired_in_days"
                        type="number"
                        className={`form-control ${fieldHasError('expired_in_days')}`}
                        value={quoteForm.expired_in_days}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('expired_in_days')}
                        placeholder="Ingrese cantidad de días"
                        disabled={disableUpdate}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('expired_in_days')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Comentario</label>
                <div className="col-md-9">
                    <textarea
                        className={`form-control ${fieldHasError('comment')}`}
                        name="comment"
                        id="comment"
                        value={quoteForm.comment}
                        onChange={handleChange}
                        placeholder="Ingrese un comentario"
                        onFocus={setCleanTextAreaError}
                        disabled={disableUpdate}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('comment')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuoteForm;
