import { useContext, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

import { ServiceEvents } from '../shared/interfaces';
import { GuideDataForm, GuideFilters } from '../../models/Guide';
import { AppContext } from '../../../contexts/AppContext';
import { fetchDefaultHeaders } from '../shared/vars';
import { GuideHistoryDataForm, GuideHistoryFilter } from '../../models/GuideHistory';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import GuideHistoryDataTable from '../../../pages/WorkOrders/components/tables/LoadTicketHistoryDataTable';

const useGuideHistoryService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [fetchingGetGuideHistoriesByGuide, setFetchingGetGuideHistoriesByGuide] = useState(false);
    const [fetchingHistoryGuideUpdate, setFetchingHistoryGuideUpdate] = useState(false);
    const [fetchingHistoryGuideEdit, setFetchingEditGuideHistory] = useState(false);

    const getAllGuidehistoriesByGuide = (
        filter: GuideHistoryFilter,
        guide_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.GUIDE_HISTORIES.GET_GUIDE_HISTORIES_BY_GUIDE.replace(
            ':guide_id',
            guide_id.toString()
        )}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetGuideHistoriesByGuide
        });
    };

    const editGuideHistory = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.GUIDE_HISTORIES.EDIT_GUIDE_HISTORY.replace(':id', id.toString()),
            setFetching: setFetchingEditGuideHistory
        });
    };

    const updateGuideHistory = (
        id: number,
        GuideHistoryDataForm: GuideHistoryDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.GUIDE_HISTORIES.UPDATE_GUIDE_HISTORY.replace(':id', id.toString()),
            body: GuideHistoryDataForm,
            setFetching: setFetchingHistoryGuideUpdate
        });
    };

    return {
        fetchingGetGuideHistoriesByGuide,
        fetchingHistoryGuideEdit,
        fetchingHistoryGuideUpdate,
        getAllGuidehistoriesByGuide,
        editGuideHistory,
        updateGuideHistory
    };
};

export default useGuideHistoryService;
