import React from 'react';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { ExpenseType } from '../../../app/models/ExpenseType';
import ExpenseTypeForm from './forms/ExpenseTypeForm';
import { Account } from '../../../app/models/Account';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    expenseType: ExpenseType;
    accounts: Account[];
    setExpenseType: (expenseType: ExpenseType) => void;
    errorFields?: any;
    is_system: boolean;
}

const ExpenseTypeFormContainer = ({
    fetching,
    action,
    cancel,
    expenseType,
    accounts,
    setExpenseType,
    errorFields = null,
    is_system
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ExpenseTypeForm
                        expenseTypeDataForm={expenseType}
                        setExpenseTypeDataForm={setExpenseType}
                        accounts={accounts}
                        errorFields={errorFields}
                        is_system={is_system}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ExpenseTypeFormContainer;
