import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import { HopperDocument } from '../../../app/models/Hopper';
import useHopperDocumentService from '../../../app/services/hooks/useHopperDocumentService';
import DocumentsHistoryDataTable from '../../../components/DocumentHistoryDataTable';

interface Props {
    onCancel?: () => void;
    onError?: () => void;
    hopperId: number;
    typeDocument: number;
}

const HopperDocumentHistory = ({ onCancel, onError, hopperId, typeDocument }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const [hopperDocuments, setHopperDocuments] = useState<HopperDocument[]>();
    const {
        fetchingGetHopperDocumentsByHopperAndDocumentType,
        getHopperDocumentByHopperAndDocumentType
    } = useHopperDocumentService();

    const getDocumentsHistory = (hopperId: number, typeDocument: number) => {
        getHopperDocumentByHopperAndDocumentType(hopperId, typeDocument, {
            onSuccess: (response: ServiceResponse) => {
                console.log(response);
                setHopperDocuments(response.data.hopper_documents);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        getDocumentsHistory(hopperId, typeDocument);
    }, []);

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingGetHopperDocumentsByHopperAndDocumentType ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    {hopperDocuments && (
                        // <GeneralDocumentsTransporterHistoryByTypeTable
                        //     documents={hopperDocuments}
                        // />
                        <DocumentsHistoryDataTable data={hopperDocuments} />
                    )}
                </div>
            </div>
        </>
    );
};

export default HopperDocumentHistory;
