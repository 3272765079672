import { useContext, useEffect, useState } from 'react';
import useContractService from '../../../../app/services/hooks/useContractService';
import LazyLoading from '../../../../components/LazyLoading';
import { ContractDataForm, defaultContract } from '../../../../app/models/Contract';
import ContractFormContainer from '../ContractFormContainer';
import { AppContext } from '../../../../contexts/AppContext';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { Status } from '../../../../app/models/Status';
import { Worker } from '../../../../app/models/Workers';
import { toast } from 'react-toastify';
import { Shift } from '../../../../app/models/Shift';
import { JobTitle } from '../../../../app/models/JobTitle';
import { Currency } from '../../../../app/models/Currency';

interface Props {
    contractId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ContractEdit = ({ contractId, onSaved, onCancel, onError }: Props) => {
    const { fetchingEditContract, editContract, fetchingUpdateContract, updateContract } =
        useContractService();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [errorFields, setErrorFields] = useState<any>();
    const [contract, setContract] = useState<ContractDataForm>(defaultContract);
    const [status, setStatus] = useState<Status[]>([]);
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [shifts, setShifts] = useState<Shift[]>([])
    const [jobTitles, setJobTitles] = useState<JobTitle[]>([])

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando contrato...');
        editContract(contractId, {
            onSuccess: (response: ServiceResponse) => {
                // setContract(response.data.contract);
                setStatus(response.data.status);
                setWorkers(response.data.workers);
                setCurrencies(response.data.currencies);
                setShifts(response.data.shift_works);
                setJobTitles(response.data.job_titles);
                const _contract = response.data.contract;
                if (response.data.bank_accounts) {
                    _contract.bank_accounts = response.data.bank_accounts;
                }
                setContract(_contract)
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando contrato...');
        updateContract(contractId, contract, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditContract ? (
        <LazyLoading height="300" />
    ) : (
        <>
        {contract && (<ContractFormContainer
            fetching={fetchingUpdateContract}
            action={update}
            cancel={cancel}
            contract={contract}
            setContract={setContract}
            errorFields={errorFields}
            status={status}
            workers={workers}
            currencies={currencies}
            shifts={shifts}
            jobTitles={jobTitles}
        />)}
        </>
    );
};

export default ContractEdit;
