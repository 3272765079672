import React from 'react';
import { QuoteFilters } from '../../../../app/models/Quote';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: QuoteFilters;
    setFilter: (filter: QuoteFilters) => void;
    onSearch: () => void;
};

const QuoteFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(filter);
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            {/* primera fila */}
            <div className="row">
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="quote_id">
                            Oferta
                        </label>
                        <input
                            title="Número"
                            type="number"
                            id="quote_id"
                            name="quote_id"
                            className="form-control form-control-sm"
                            value={filter.quote_id ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="client">
                            Cliente
                        </label>
                        <input
                            title="Cliente"
                            type="text"
                            id="client"
                            name="client"
                            className="form-control form-control-sm"
                            value={filter.client ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-4">
                        <label className="font-size-10 mb-1" htmlFor="quote_status">
                            Estatus
                        </label>
                        <select
                            id="quote_status"
                            name="quote_status"
                            className="form-control form-select-sm"
                            value={filter.quote_status ?? ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option value="TODOS">TODOS</option>
                            <option value="PREPARACION">Preparacion</option>
                            <option value="REVISION_OPERACION">Revision operación</option>
                            <option value="REVISION_CLIENTE">Revision Cliente</option>
                            <option value="CONFIRMADA">Confirmada</option>
                            <option value="RECHAZADA">Rechazada</option>
                        </select>
                    </div>
                </div>
                {/* </div>
           
            <div className="row"> */}
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="start_date">
                            Desde
                        </label>
                        <input
                            type="date"
                            id="start_date"
                            name="start_date"
                            className="form-control form-control-sm"
                            value={filter.start_date ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="end_date">
                            Hasta
                        </label>
                        <input
                            type="date"
                            id="end_date"
                            name="end_date"
                            className="form-control form-control-sm"
                            value={filter.end_date ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuoteFilter;
