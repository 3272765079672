import { useContext, useEffect, useState } from 'react';
import useLiquidationService from '../../../app/services/hooks/useLiquidationService';
import LazyLoading from '../../../components/LazyLoading';
import { Liquidation } from '../../../app/models/Liquidation';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { formatDateToMonth, formatNumberWithDots } from '../../../helpers';
import DefaultCard from '../../../components/default/DefaultCard';
import { Tab, Tabs } from 'react-bootstrap';
import DeductionAndBonusDataTable from '../components/tables/DeductionAndBonusDataTable';

type RouterParams = {
    id: string;
};

const LiquidationShow = () => {
    const {
        fetchingShowLiquidation,
        showLiquidation
    } = useLiquidationService();
    const { id } = useParams<RouterParams>();
    const history = useHistory();

    const { showLoading, hideLoading } = useContext(AppContext);
    const [liquidation, setLiquidation] = useState<Liquidation>();
    const [deductions, setDeductions]  = useState<any>(); 
    const [employerDeductions, setEmployerDeductions]  = useState<any>(); 
    const [taxableBonus, setTaxableBonus]  = useState<any>(); 
    const [nonTaxableBonus, setNonTaxableBonus]  = useState<any>(); 
    const [name, setName] = useState('');

    useEffect(() => {
        show();
    }, []);

    const show = () => {
        if (showLoading) showLoading('loading', 'Cargando liquidación...');
        showLiquidation(parseInt(id), {
            onSuccess: (response: ServiceResponse) => {
                setLiquidation(response.data.liquidation);
                const selectedLiquidation = `${formatDateToMonth(response.data.liquidation.period)} | ${response.data.liquidation.worker_name}`;
                setName(selectedLiquidation);
                setDeductions(response.data.liquidation.deductions.Deductions);
                setTaxableBonus(response.data.liquidation.taxable_bonus.Taxable_Bonus);
                setNonTaxableBonus(response.data.liquidation.non_taxable_bonus.Non_Taxable_Bonus);
                setEmployerDeductions(response.data.liquidation.employer_deductions.Employer_Deductions)
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                history.goBack();
            }
        });
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Liquidaciones',
            url: '/liquidations/' + id + '/show',
            isActive: true
        }
    ];


    return fetchingShowLiquidation ? (
        <LazyLoading height="300" />
    ) : (
        <>
            <Breadcrumbs pageSection={`Liquidación - ${name}`} breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Liquidación</div>

                        <div className="mb-2 row">
                            <div className="col-md-6">
                                <label className="col-form-label">Empleado</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={liquidation?.worker_name}
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="col-form-label">Periodo</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={formatDateToMonth(liquidation?.period)}
                                />
                            </div>
                        </div>
                    
                        <div className="mb-2 row">
                            <div className="col-md-3">
                                <label className="col-form-label">Cargo</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={liquidation?.contract?.job_title?.name}
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="col-form-label">Tipo Contrato</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={liquidation?.contract?.is_indefinite 
                                        ? 'Indefinido'
                                        : 'Plazo fijo'
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="col-form-label">Sueldo Base</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={liquidation?.base_salary && 
                                        formatNumberWithDots(liquidation?.base_salary)
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <label className="col-form-label">Días Trabajados</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={liquidation?.workdays}
                                />
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <div className="col-md-4">
                                <label className="col-form-label">Base Imponible</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={liquidation?.tax_base && 
                                        formatNumberWithDots(liquidation?.tax_base)
                                    }
                                />
                            </div>
                            <div className="col-md-4">
                                <label className="col-form-label">Total Deducciones</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={liquidation?.total_discounts && 
                                        formatNumberWithDots(liquidation?.total_discounts)
                                    }
                                />
                            </div>
                            <div className="col-md-4">
                                <label className="col-form-label">Salario Líquido</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={liquidation?.net_salary && 
                                        formatNumberWithDots(liquidation?.net_salary)
                                    }
                                />
                            </div>
                        </div>

                    </DefaultCard>

                    <Tabs defaultActiveKey={'bonus'} id="quote-tabs" className="mb-3">
                        
                        <Tab eventKey="bonus" title="Haberes">
                            <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">
                                    Haberes Imponibles
                                </div>
                                <DeductionAndBonusDataTable 
                                    data={taxableBonus} 
                                    progressPending={fetchingShowLiquidation}
                                />
                            </DefaultCard>
                            <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">
                                    Haberes No Imponibles
                                </div>
                                <DeductionAndBonusDataTable 
                                    data={nonTaxableBonus} 
                                    progressPending={fetchingShowLiquidation}
                                />
                            </DefaultCard>
                        </Tab>

                        <Tab eventKey="deductions" title="Deducciones">
                            <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">
                                    Deducciones
                                </div>
                                <DeductionAndBonusDataTable 
                                    data={deductions} 
                                    progressPending={fetchingShowLiquidation}
                                />
                            </DefaultCard>
                        </Tab>

                        <Tab eventKey="employer-deductions" title="Gastos Empleador">
                            <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">
                                    Gastos de Empleador
                                </div>
                                <DeductionAndBonusDataTable 
                                    data={employerDeductions} 
                                    progressPending={fetchingShowLiquidation}
                                />
                            </DefaultCard>
                        </Tab>

                    </Tabs>

                </div>
            </div>
        </>
    );
};

export default LiquidationShow;
