import React from 'react';
import { QuoteRouteSegmentShowDataForm } from '../../../../app/models/QuoteRouteSegment';
import { formatMoney, formatNumber } from '../../../../utils/utils';

interface Props {
    quoteRouteSegmentShow: QuoteRouteSegmentShowDataForm;
}

const QuoteRouteSegmentShowForm = ({ quoteRouteSegmentShow }: Props) => {
    if (!quoteRouteSegmentShow) return null;

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Ruta</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="quote_route_segment_id"
                        className="form-control"
                        readOnly={true}
                        value={formatNumber(quoteRouteSegmentShow.id ?? 0)}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">n° Tramo</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="route_segment_id"
                        className="form-control"
                        readOnly={true}
                        value={formatNumber(quoteRouteSegmentShow.route_segment_id ?? 0)}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tramo</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="route_segment_name"
                        className="form-control"
                        readOnly={true}
                        value={`${quoteRouteSegmentShow.origin} - ${quoteRouteSegmentShow.destiny}`}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tarifa de venta</label>
                <div className="col-md-9">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                type="text"
                                name="rate"
                                className="form-control"
                                readOnly={true}
                                value={formatMoney(
                                    quoteRouteSegmentShow.rate ?? 0,
                                    quoteRouteSegmentShow.currency ?? 'CLP'
                                )}
                            />
                        </div>
                        <div className="col-3">
                            <input
                                type="text"
                                name="currency"
                                className="form-control"
                                readOnly={true}
                                value={quoteRouteSegmentShow.currency}
                            />
                        </div>
                        <div className="col-3">
                            <input
                                type="text"
                                name="unit"
                                className="form-control"
                                readOnly={true}
                                value={quoteRouteSegmentShow.unit}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Distancia real km</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="distance"
                        className="form-control"
                        readOnly={true}
                        value={formatNumber(quoteRouteSegmentShow.distance ?? 0)}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Distancia acordada km</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="agreed_distance"
                        className="form-control"
                        readOnly={true}
                        value={formatNumber(quoteRouteSegmentShow.agreed_distance ?? 0)}
                    />
                </div>
            </div>

            <hr />

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tarifa Runner</label>
                <div className="col-md-9">
                    <div className="row g-1">
                        <div className="col-4">
                            <input
                                type="text"
                                name="rate_runner"
                                className="form-control"
                                readOnly={true}
                                value={formatMoney(
                                    quoteRouteSegmentShow.rate_runner ?? 0,
                                    quoteRouteSegmentShow.currency_rate_runner ?? undefined
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <input
                                type="text"
                                name="currency_rate_runner"
                                className="form-control"
                                readOnly={true}
                                value={quoteRouteSegmentShow.currency_rate_runner}
                            />
                        </div>
                        <div className="col-4">
                            <input
                                type="text"
                                name="quantity_unit_rate_runner"
                                className="form-control"
                                readOnly={true}
                                value={quoteRouteSegmentShow.quantity_unit_rate_runner}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tarifa Transportista Externo</label>
                <div className="col-md-9">
                    <div className="row g-1">
                        <div className="col-4">
                            <input
                                type="text"
                                name="external_rate"
                                className="form-control"
                                readOnly={true}
                                value={formatMoney(
                                    quoteRouteSegmentShow.external_rate ?? 0,
                                    quoteRouteSegmentShow.currency_external_rate ?? undefined
                                )}
                            />
                        </div>
                        <div className="col-4">
                            <input
                                type="text"
                                name="currency_external_rate"
                                className="form-control"
                                readOnly={true}
                                value={quoteRouteSegmentShow.currency_external_rate}
                            />
                        </div>
                        <div className="col-4">
                            <input
                                type="text"
                                name="unit_external_rate"
                                className="form-control"
                                readOnly={true}
                                value={quoteRouteSegmentShow.unit_external_rate}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">F. Creación</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="created_at"
                        className="form-control"
                        readOnly={true}
                        value={
                            quoteRouteSegmentShow.created_at
                                ? new Date(quoteRouteSegmentShow.created_at).toLocaleDateString()
                                : ''
                        }
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">F. Modificación</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        name="updated_at"
                        className="form-control"
                        readOnly={true}
                        value={
                            quoteRouteSegmentShow.updated_at
                                ? new Date(quoteRouteSegmentShow.updated_at).toLocaleDateString()
                                : ''
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default QuoteRouteSegmentShowForm;
