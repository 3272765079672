import React, { useContext, useState } from 'react'
import ActiveControl from '../../../../components/ActiveControl';
import useDocumentSetService from '../../../../app/services/hooks/useDocumentSetService';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { AppContext } from '../../../../contexts/AppContext';
import useJobTitleLiquidationSetService from '../../../../app/services/hooks/useJobTitleLiquidationSetService';


interface Props {
    deductionSetId: number;
    checkValue: boolean;
    callbackSuccess: () => void;
}

const DeductionSetSwitchChange = ({
    deductionSetId,
    checkValue,
    callbackSuccess
} : Props) => {

    const { hideLoading } = useContext(AppContext);
    const [checked, setChecked] = useState<boolean>(checkValue);
    const { activeJobTitleLiquidationSet } = useJobTitleLiquidationSetService();

    const active = (value: boolean) => {
        setChecked(value);

        activeJobTitleLiquidationSet(deductionSetId, value, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                callbackSuccess();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setChecked(!value);
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return <ActiveControl status={checked} id={deductionSetId} callback={active} />;
}

export default DeductionSetSwitchChange
