import React, { useContext } from 'react';
import useQuoteService from '../../../app/services/hooks/useQuoteService';
import { QuoteStatusRequestDataForm } from '../../../app/models/Quote';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';

interface Props {
    quoteId: number;
    statuses: QuoteStatusRequestDataForm[];
    selectedStatus: QuoteStatusRequestDataForm;
    callbackSuccess?: () => void;
}

const QuoteChangeStatus = ({ quoteId, statuses, selectedStatus, callbackSuccess }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [selected, setSelected] = React.useState<QuoteStatusRequestDataForm>(selectedStatus);

    const { confirmQuote, rejectQuote, operationReviewQuote, clientReviewQuote, preparationQuote } =
        useQuoteService();

    // useEffect(() => {
    //     if (status === ResponseStatus.SUCCESS) {
    //         if (callbackSuccess) {
    //             callbackSuccess();
    //         }
    //         useSweetAlert().successAlert({
    //             title: 'Éxito',
    //             text: 'Se ha cambiado el estado de la Oferta #' + quoteId
    //         });
    //     }
    // }, [status]);

    const eventChangeStatus = {
        onSuccess: (response: ServiceResponse) => {
            if (hideLoading) hideLoading();
            if (callbackSuccess) {
                callbackSuccess();
            }

            useReactConfirmAlert().successAlert({
                title: 'Éxito',
                message: 'Se ha cambiado el estado de la Oferta #' + quoteId
            });
        },
        onError: (response: ServiceResponse) => {
            if (hideLoading) hideLoading();
            useReactConfirmAlert().errorAlert({
                title: 'Error',
                message: response.message
            });
            toast.error(response.message);
            if (callbackSuccess) {
                callbackSuccess();
            }
        }
    };

    const changeStatus = (value: string) => {
        if (value == selected.id) return null;

        const _status = statuses.find((status: QuoteStatusRequestDataForm) => status.id == value);

        setSelected(_status!);

        const _text =
            'Está a punto de cambiar el estado de la Oferta #' +
            quoteId +
            ' a ' +
            (_status ? _status.name : '');

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, cambiar estado',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        switch (value) {
                            case 'PREPARACION':
                                preparationQuote(quoteId, eventChangeStatus);
                                break;
                            case 'REVISION_OPERACION':
                                operationReviewQuote(quoteId, eventChangeStatus);
                                break;
                            case 'REVISION_CLIENTE':
                                clientReviewQuote(quoteId, eventChangeStatus);
                                break;
                            case 'RECHAZADA':
                                rejectQuote(quoteId, eventChangeStatus);
                                break;
                            case 'CONFIRMADA':
                                confirmQuote(quoteId, eventChangeStatus);
                                break;
                            default:
                                if (hideLoading) hideLoading();
                                setTimeout(() => {
                                    useReactConfirmAlert().infoAlert({
                                        title: 'Cancelado',
                                        message: 'Opción no encontrada.'
                                    });
                                }, 0);
                                setSelected(selectedStatus);
                                break;
                        }
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El estado de la Oferta no se ha cambiado.'
                            });
                        }, 0);
                        setSelected(selectedStatus);
                    }
                }
            }
        });
    };

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => changeStatus(e.target.value)}
        >
            <option value={selectedStatus.id}>{selectedStatus.name}</option>
            {statuses.map((status) => {
                return (
                    <option key={status.id} value={status.id}>
                        {status.name}
                    </option>
                );
            })}
        </select>
    );
};

export default QuoteChangeStatus;
