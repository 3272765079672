import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import useRouteSegmentService from '../../app/services/hooks/useRouteSegmentService';
import { AuthContext } from '../../contexts/AuthContext';
import { RouteSegmentDataForm, RouteSegmentFilters } from '../../app/models/RouteSegment';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import RouteSegmentDataTable from './components/tables/RouteSegmentDataTable';
import DefaultModal from '../../components/default/DefaultModal';
import RouteSegmentCreate from './components/RouteSegmentCreate';
import RouteSegmentEdit from './components/RouteSegmentEdit';
import { toast } from 'react-toastify';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Tramos',
        url: '/route-segments',
        isActive: true
    }
];

const RouteSegment = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetRouteSegments, getRouteSegments, deleteRouteSegment } =
        useRouteSegmentService();

    const { auth } = useContext(AuthContext);

    const [routeSegmentIdEdit, setRouteSegmentIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [routeSegments, setRouteSegments] = useState<RouteSegmentDataForm[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);

    const [filter, setFilter] = useState<RouteSegmentFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, []);

    const reloadTable = () => {
        getRouteSegments(filter, {
            onSuccess: (response: ServiceResponse) => {
                setRouteSegments(response.data.route_segments);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (RouteSegmentId: number) => {
        setShowingEdit(true);
        setRouteSegmentIdEdit(RouteSegmentId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setRouteSegmentIdEdit(-1);
    };

    const onError = () => {
        setShowingEdit(false);
        setRouteSegmentIdEdit(-1);
    };

    const destroy = (routeSegmentId: number) => {
        const _text = 'Está a punto de eliminar el tramo #' + routeSegmentId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando tramo...');
                        deleteRouteSegment(routeSegmentId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El tramo no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Tramos" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                <ButtonCreate callbackCreate={showCreate} title="Nuevo tramo" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        {/* <UserTable
                            routeSegments={routeSegments}
                            callbackSuccess={reloadTable}
                            loading={fetchingGetRouteSegments}
                            destroy={destroy}
                            edit={showEdit}
                            
                        /> */}

                        <RouteSegmentDataTable
                            routeSegments={routeSegments}
                            totalRows={totalRows}
                            loading={fetchingGetRouteSegments}
                            destroy={destroy}
                            edit={showEdit}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                            onSearch={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear tramo"
                    backdrop={true}
                    showFooter={false}
                >
                    <RouteSegmentCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Tramo"
                    backdrop={true}
                    showFooter={false}
                >
                    <RouteSegmentEdit
                        routeSegmentId={routeSegmentIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={onError}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default RouteSegment;
