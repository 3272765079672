import React from 'react';
import { AccountFilters } from '../../../../app/models/Account';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: AccountFilters;
    setFilter: (filter: AccountFilters) => void;
    onSearch: () => void;
};

const AccountFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="name">
                            Nombre cuenta
                        </label>
                        <input
                            title="name"
                            type="text"
                            id="name"
                            name="name"
                            className="form-control form-control-sm"
                            value={filter.name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
                {/*<div className="col-md-auto">*/}
                {/*    <div className="mb-3">*/}
                {/*        <label className="font-size-10 mb-1" htmlFor="accountant">*/}
                {/*            Contable*/}
                {/*        </label>*/}
                {/*        <input*/}
                {/*            title="Contable"*/}
                {/*            type="text"*/}
                {/*            id="accountant"*/}
                {/*            name="accountant"*/}
                {/*            className="form-control form-control-sm"*/}
                {/*            value={filter.accountant}*/}
                {/*            onChange={(e) => handlerInputFilter(e)}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default AccountFilter;
