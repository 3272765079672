import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useDispatchProgramItemService from '../../../app/services/hooks/useDispatchProgramItemService';
import MassiveLoadForm from './forms/MassiveLoadForm';
import { MassiveLoad, defaultMassiveLoad } from '../../../app/models/MassiveLoad';
import { toast } from 'react-toastify';

interface Props {
    dispatch_program_id: number;
    transportation_order_item_id: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const MassiveLoadCreate = ({
    dispatch_program_id,
    transportation_order_item_id,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation, showProgressiveLoading } =
        useContext(AppContext);
    const history = useHistory();
    const { excelLoadBulk, fetchingExcelLoadBulk } = useDispatchProgramItemService();
    const [massiveLoad, setMassiveLoad] = useState<MassiveLoad>({
        ...defaultMassiveLoad,
        dispatch_program_id: dispatch_program_id,
        transportation_order_item_id: transportation_order_item_id
    });

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (massiveLoad.id) {
            bulkExcel();
        }
    }, [massiveLoad.id, massiveLoad.blockStart]);

    const bulkExcel = () => {
        if (showProgressiveLoading)
            showProgressiveLoading('Cargando datos...', massiveLoad.progressPercentage);

        excelLoadBulk(massiveLoad, {
            onSuccess: (response) => {
                if (response.data.massive_load.all_load) {
                    if (showProgressiveLoading) showProgressiveLoading('Cargando datos...', 100);
                    setTimeout(() => {
                        if (hideLoading) hideLoading();
                        setMassiveLoad(defaultMassiveLoad);

                        useReactConfirmAlert().successAlert({
                            title: 'Éxito',
                            message: 'Carga masiva realizada con éxito'
                        });
                        if (onSaved) onSaved();
                    }, 2000);
                } else {
                    setMassiveLoad({
                        ...massiveLoad,
                        ...response.data.massive_load
                    });
                }
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setMassiveLoad({
                    ...massiveLoad,
                    ...defaultMassiveLoad
                });
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <MassiveLoadForm
                        massiveLoad={massiveLoad}
                        setMassiveLoad={setMassiveLoad}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingExcelLoadBulk} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={bulkExcel} locked={fetchingExcelLoadBulk} />
                </div>
            </div>
        </>
    );
};

export default MassiveLoadCreate;
