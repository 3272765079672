import {
    InternalSettlement,
    InternalSettlementFilters,
    InternalSettlementTable
} from '../../../../app/models/InternalSettlement';
import {
    MonthlyReconociliationDataForm,
    MonthlyReconociliationFilters
} from '../../../../app/models/MonthlyReconciliation';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';

import { momentParseDate } from '../../../../helpers';
import { formatAmount, formatMoney, formatNumber } from '../../../../utils/utils';
import MonthlyReconciliationFilter from './MonthlyReconciliationFilter';

interface Props {
    monthlyReconciliations: MonthlyReconociliationDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: MonthlyReconociliationFilters;
    setFilter: (filter: MonthlyReconociliationFilters) => void;
    paginationRowsPerPageOptions?: any[];
    downloadExcel?: () => void;
    onSearch: () => void;
}

const MonthlyReconciliationDataTable = ({
    monthlyReconciliations,
    totalRows,
    loading = false,
    filter,
    setFilter,
    downloadExcel,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Conductor',
            selector: (row: any) => row?.worker,
            sortable: true,
            sortField: 'carrier',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <span className="">
                    <div dangerouslySetInnerHTML={{ __html: row.worker ? row.worker : '' }} />
                </span>
            )
        },
        {
            name: 'Tracto',
            selector: (row: any) => row?.truck,
            sortable: true,
            sortField: 'truck',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <span className="">
                    <div dangerouslySetInnerHTML={{ __html: row.truck ? row.truck : '' }} />
                </span>
            )
        },
        {
            name: 'Semi remolque',
            selector: (row: any) => row?.hopper,
            sortable: true,
            sortField: 'hopper',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <span className="">
                    <div dangerouslySetInnerHTML={{ __html: row.hopper ? row.hopper : '' }} />
                </span>
            )
        },
        {
            name: 'Cliente',
            selector: (row: any) => row?.client,
            sortable: true,
            sortField: 'client',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <span className="">{row.client}</span>
            )
        },
        {
            name: 'Origen',
            selector: (row: any) => row?.origin,
            sortable: true,
            sortField: 'origin',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <span className="">{row.origin}</span>
            )
        },
        {
            name: 'Destino',
            selector: (row: any) => row?.destiny,
            sortable: true,
            sortField: 'destiny',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <span className="">{row.destiny}</span>
            )
        },
        {
            name: 'Product',
            selector: (row: any) => row?.product,
            sortable: true,
            sortField: 'product',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <span className="">{row.product}</span>
            )
        },

        {
            name: 'Tarifa cliente',
            selector: (row: any) => row?.rate,
            sortable: true,
            sortField: 'rate',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {row.rate !== null &&
                            `${formatMoney(row.rate ?? 0, row.currency_rate)} ${
                                row.currency_rate
                            } / ${row.unit_rate}`}
                    </span>
                </>
            )
        },

        {
            name: 'Peso carga',
            selector: (row: any) => row?.total_guides,
            sortable: true,
            sortField: 'total_guides',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <>
                    <span className="">{formatNumber(row.total_guides ?? 0)}</span>
                </>
            )
        },
        {
            name: 'Peso descarga',
            selector: (row: any) => row?.total_load_tickets,
            sortable: true,
            sortField: 'total_load_tickets',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <>
                    <span className="">{formatNumber(row.total_load_tickets ?? 0)}</span>
                </>
            )
        },
        {
            name: 'Folio guia',
            selector: (row: any) => row?.folio,
            sortable: true,
            sortField: 'folio',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <>
                    <span className="">{row.folio}</span>
                </>
            )
        },
        {
            name: 'Comisión conductor',
            selector: (row: any) => row?.commission,
            sortable: true,
            sortField: 'commission',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {formatMoney(row.commission ?? 0, row.currency_commission)}{' '}
                        {row.currency_commission}
                        {(row.currency_commission || row.unit_commission) && '/'}
                        {row.unit_commission}
                    </span>
                </>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <>
                    <span className="">{row.status}</span>
                </>
            )
        },
        {
            name: 'Fecha de programa',
            selector: (row: any) => row?.program_date,
            sortable: true,
            sortField: 'program_date',
            cell: (row: MonthlyReconociliationDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.program_date)}</span>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <MonthlyReconciliationFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
                <div className="col-auto" style={{ paddingTop: 22 }}>
                    <button className="btn btn-success btn-sm" onClick={downloadExcel}>
                        <i className="fa fa-file-excel" /> Descargar
                    </button>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={monthlyReconciliations}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default MonthlyReconciliationDataTable;
