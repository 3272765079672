import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Provider } from '../../../../app/models/Provider';
import { ExpenseDataForm } from '../../../../app/models/Expense';
import { ExpenseType } from '../../../../app/models/ExpenseType';
import { ExpenseDocumentType } from '../../../../app/models/ExpenseDocumentType';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import { Currency } from '../../../../app/models/Currency';
import { processFile, setCleanTextAreaError } from '../../../../utils/utils';
import { TollExpenseDataForm } from '../../../../app/models/TollExpense';
import { Toll } from '../../../../app/models/Toll';
import { PaymentMethod } from '../../../../app/models/PaymentMethod';
import LazyLoading from '../../../../components/LazyLoading';
import CurrencyInput from '../../../../components/inputs/CurrencyInput';

interface GeneralExpenseProps {
    tollExpenseDataForm: TollExpenseDataForm;
    setTollExpenseForm: (tollExpenseDataForm: TollExpenseDataForm) => void;
    providers: Provider[];
    expenseDocumentTypes: ExpenseDocumentType[];
    tolls: Toll[];
    errorFields?: any;
    currencies: Currency[];
    fetching: boolean;
    paymentMethods: PaymentMethod[];
}

const TollExpenseForm = ({
    tollExpenseDataForm,
    setTollExpenseForm,
    providers,
    currencies,
    tolls,
    expenseDocumentTypes,
    paymentMethods,
    errorFields,
    fetching
}: GeneralExpenseProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } =
        useHandleErrorFields();
    const [isReported, setIsReported] = useState(false);
    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        setTollExpenseForm({ ...tollExpenseDataForm, [name]: value });
    };

    const handleChangeDocumentFile = async (e: FileList | null, type: string) => {
        if (e) {
            const file = e[0];
            // const fileExtension = file.name.split('.').pop()?.toLowerCase();
            // // Por problemas se dejo solo el processFile solo para imagenes Heic que al parecer aca no hay drama
            // if (fileExtension === 'heic' || file.type === 'image/heic') {
            //     const result = await processFile(file);
            //     if (result.error) {
            //         handleRemoveFile();
            //         setErrors({
            //             ...errors,
            //             photo: [result.error]
            //         });
            //     } else {
            //         setTollExpenseForm({ ...tollExpenseDataForm, ['photo']: result.file });
            //     }
            // }else{
                setTollExpenseForm({ ...tollExpenseDataForm, ['photo']: file });
            // }

        }
    };

    const handleRemoveFile = () => {
        setTollExpenseForm({ ...tollExpenseDataForm, ['photo']: undefined });
        const fileInput = document.getElementById('photo') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if (name === 'toll_id') {
            setErrors({
                ...errors,
                amount: '',
                currency_id: ''
            });
        }

        setTollExpenseForm({ ...tollExpenseDataForm, ...obj });
    };

    const handleReportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        setTollExpenseForm({ ...tollExpenseDataForm, is_reported: checked });
    };

    if (!tollExpenseDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Peaje</label>
                    <SelectTwo
                        name="toll_id"
                        id="toll_id"
                        inputValue={tollExpenseDataForm.toll_id}
                        options={SelectTwoMapperOptions(tolls, 'id', ['name'])}
                        hasError={fieldHasError('toll_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('toll_id', value.value)}
                        placeholder={'Seleccione peaje'}
                        onFocus={() => onFocusRemove('toll_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('toll_id')}
                    </div>
                </div>
            </div>
            {fetching ? (
                <LazyLoading height={300} />
            ) : (
                <>
                    <div className="mb-2 row">
                        <div className="col-md-6">
                            <label className="col-form-label">Valor</label>
                            <CurrencyInput
                                name={'amount'}
                                currency={tollExpenseDataForm.currency_id}
                                currencies={currencies}
                                onChange={handleChange}
                                value={tollExpenseDataForm.amount}
                                className={`form-control ${fieldHasError(`amount`)}`}
                                onFocus={() => onFocusRemove(`amount`)}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage(`amount`)}
                            </div>
                        </div>

                        <div className="col-6">
                            <label className="col-form-label">Moneda</label>

                            <SelectTwo
                                name="currency_id"
                                id="currency_id"
                                inputValue={tollExpenseDataForm.currency_id}
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('currency_id', value.value)
                                }
                                onFocus={() => onFocusRemove('currency_id')}
                                placeholder={'Moneda'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_id')}
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/*issue_date*/}

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Fecha de gasto</label>
                    <input
                        name={'issue_date'}
                        id={`issue_date`}
                        type="date"
                        value={tollExpenseDataForm.issue_date}
                        className={`form-control ${fieldHasError(`issue_date`)}`}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove(`issue_date`)}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage(`issue_date`)}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Forma de pago</label>
                    <SelectTwo
                        name="payment_method"
                        id="payment_method"
                        inputValue={tollExpenseDataForm.payment_method}
                        options={SelectTwoMapperOptions(paymentMethods, 'id', ['name'])}
                        hasError={fieldHasError('payment_method') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('payment_method', value.value)
                        }
                        placeholder={'Seleccione forma de pago'}
                        onFocus={() => onFocusRemove('payment_method')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('payment_method')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Tipo de documento</label>
                    <SelectTwo
                        name="expense_document_type"
                        id="expense_document_type"
                        inputValue={tollExpenseDataForm.expense_document_type}
                        options={SelectTwoMapperOptions(expenseDocumentTypes, 'id', ['name'])}
                        hasError={fieldHasError('expense_document_type') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('expense_document_type', value.value)
                        }
                        placeholder={'Seleccione tipo de documento'}
                        onFocus={() => onFocusRemove('expense_document_type')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('expense_document_type')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Folio</label>
                    <input
                        name={'folio'}
                        id={`folio`}
                        type="text"
                        value={tollExpenseDataForm.folio}
                        className={`form-control ${fieldHasError(`folio`)}`}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove(`folio`)}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage(`folio`)}
                    </div>
                </div>
            </div>

            <div className="mb-2 row align-items-center">
                <label htmlFor="is_reported" className="col-md-3 col-form-label">
                    Rendir gasto
                </label>
                <div className="col-9">
                    <div className="square-switch d-flex  form-check form-switch">
                        <input
                            type="checkbox"
                            name="is_reported"
                            id="is_reported"
                            className={`form-check-input ${
                                tollExpenseDataForm.is_reported ? 'active' : ''
                            }`}
                            checked={tollExpenseDataForm.is_reported}
                            onChange={handleReportChange}
                        />
                        {fieldErrorMessage('is_reported')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Boleta</label>
                    <InputButtonFile
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        name={'photo'}
                        objectFile={tollExpenseDataForm?.photo}
                        handleRemoveFile={handleRemoveFile}
                        onFocus={() => onFocusRemove('photo')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('photo')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Descripción</label>
                    <textarea
                        className={`form-control ${fieldHasError('description')}`}
                        name="description"
                        id="description"
                        value={tollExpenseDataForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TollExpenseForm;
