import React from 'react';
import DataTable from 'react-data-table-component';
import LazyLoading from './LazyLoading';
import { momentParseDate } from '../helpers';

type Props = {
    data: any[];
    progressPending?: boolean;
};

const DocumentsHistoryDataTable = ({ 
    data, 
    progressPending,
}: Props) => {
    const columns = [
        {
            name: 'Archivo',
            selector: (row: any) => row.name,
            cell: (row: any, index: any, column: any) => (
                <a
                    className="fw-bold"
                    href={String(row?.file)}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {row?.name}
                </a>
            )
        },
        {
            name: 'Fecha de Emisión',
            selector: (row: any) => row.issue_date,
            cell: (row: any, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date)}</span>
            )
        },
        {
            name: 'Fecha de Vencimiento',
            selector: (row: any) => row.expiration_date,
            cell: (row: any, index: any, column: any) => (
                <span className="">{momentParseDate(row?.expiration_date)}</span>
            )
        },
        {
            name: 'Indefinido',
            selector: (row: any) => row.is_expire_date_indefinite,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {row.is_expire_date_indefinite ? <span>Sí</span> : <span>No</span>}
                </div>
            )
        },
        {
            name: 'Último Documento Cargado',
            selector: (row: any) => row.is_most_recent,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {row.is_most_recent ? <span>Sí</span> : <span>No</span>}
                </div>
            )
        }
    ];

    return (
        <div>
            <DataTable
                columns={columns}
                data={data}
                progressPending={progressPending}
                progressComponent={<LazyLoading height={300} />}
                noDataComponent="No hay documentos registrados"
            />
        </div>
    );
};

export default DocumentsHistoryDataTable;
