import React, { useContext, useState } from 'react';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import { toast } from 'react-toastify';
import QuoteRouteSegmentDataTable from './tables/QuoteRouteSegmentDataTable';
import { AuthContext } from '../../../contexts/AuthContext';
import {
    QuoteRouteSegment,
    QuoteRouteSegmentShowDataForm,
    QuoteRouteSegmentFilters
} from '../../../app/models/QuoteRouteSegment';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';
import QuoteRouteSegmentCreate from '../../RouteSegment/components/QuoteRouteSegmentCreate';
import QuoteRouteSegmentEdit from '../../RouteSegment/components/QuoteRouteSegmentEdit';
import QuoteRouteSegmentShowForm from '../../RouteSegment/components/forms/QuoteRouteSegmentShowForm';
import useQuoteService from '../../../app/services/hooks/useQuoteService';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    quoteId: number;
    quoteRouteSegments: QuoteRouteSegment[];
    setQuoteRouteSegments: (quoteRouteSegments: QuoteRouteSegment[]) => void;
    reloadQuoteRouteSegmentTable: () => void;
    filter: QuoteRouteSegmentFilters;
    setFilter: (filter: QuoteRouteSegmentFilters) => void;
    isFormShow?: boolean;
    quoteCheckDocument: boolean;
    fetchingGetQuoteRouteSegments: boolean;

    // fetchingGetQuoteRouteSegments?:boolean;
    // getQuoteRouteSegments?:(quoteId:number, events: ServiceEvents) => void;
}

const QuoteRouteSegmentsContainer = ({
    quoteId,
    quoteRouteSegments,
    setQuoteRouteSegments,
    isFormShow = false,
    quoteCheckDocument,
    reloadQuoteRouteSegmentTable,
    filter,
    setFilter,
    fetchingGetQuoteRouteSegments
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { deleteQuoteRouteSegment, showQuoteRouteSegment } = useQuoteRouteSegmentService();

    const { changeDocumentCheckRoutes } = useQuoteService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [quoteRouteSegmentShow, setQuoteRouteSegmentShow] =
        useState<QuoteRouteSegmentShowDataForm>({});

    const { auth } = useContext(AuthContext);
    const [quoteRouteSegmentIdEdit, setQuoteRouteSegmentIdEdit] = useState<number>(-1);
    const [quoteRouteSegmentIdShow, setQuoteRouteSegmentIdShow] = useState<number>(-1);

    const [checkboxDocument, setCheckboxDocument] = useState<boolean>(quoteCheckDocument);

    const showEdit = (quoteDetailId: number) => {
        setShowingEdit(true);
        setQuoteRouteSegmentIdEdit(quoteDetailId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setQuoteRouteSegmentIdEdit(-1);
    };

    const showShow = (quoteDetailId: number) => {
        setShowingShow(true);
        setQuoteRouteSegmentIdShow(quoteDetailId);
    };

    const hideShow = () => {
        setShowingShow(false);
        setQuoteRouteSegmentIdShow(-1);
    };

    const show = (quoteDetailId: number) => {
        if (showLoading) showLoading('loading', 'cargando opción de ruta...');
        showQuoteRouteSegment(quoteDetailId, {
            onSuccess: (response: ServiceResponse) => {
                setQuoteRouteSegmentShow(response.data.quote_route_segment);
                setShowingShow(true);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                hideShow();
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _select = event.target.checked;

        if (showLoading) showLoading('loading', 'Cambiando check...');
        changeDocumentCheckRoutes(quoteId, _select, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setCheckboxDocument(_select);
                toast.success(response.message);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                setCheckboxDocument(!_select);
            }
        });
    };

    const destroy = (quoteRouteSegmentId: number) => {
        const _text = 'Está a punto de eliminar la opción de ruta #' + quoteRouteSegmentId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, eliminar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando opción...');
                        deleteQuoteRouteSegment(quoteRouteSegmentId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadQuoteRouteSegmentTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La opción de ruta no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="h3 mt-0 mb-4 card-title">Rutas</div>
                <div className="row">
                    {!isFormShow ? (
                        <>
                            <div className="row">
                                <div className="col-12 mb-1">
                                    <DefaultToolBar
                                        left={<ButtonCreate callbackCreate={showCreate} />}
                                    />
                                </div>
                            </div>
                            <div className="col-12 mb-2">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkboxDocument}
                                        id="routes-document"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="routes-document">
                                        Incluir en documento
                                    </label>
                                </div>
                            </div>

                            <div className="col-12">
                                <QuoteRouteSegmentDataTable
                                    quoteRouteSegments={quoteRouteSegments}
                                    totalRows={totalRows}
                                    show={show}
                                    edit={showEdit}
                                    destroy={destroy}
                                    filter={filter}
                                    setFilter={setFilter}
                                    loading={fetchingGetQuoteRouteSegments}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-12 mb-2">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkboxDocument}
                                        id="routes-document"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="routes-document">
                                        Incluir en documento
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <QuoteRouteSegmentDataTable
                                    quoteRouteSegments={quoteRouteSegments}
                                    totalRows={totalRows}
                                    show={show}
                                    filter={filter}
                                    setFilter={setFilter}
                                    loading={fetchingGetQuoteRouteSegments}
                                />
                            </div>
                        </>
                    )}
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear opción de ruta"
                    backdrop={true}
                    showFooter={false}
                >
                    <QuoteRouteSegmentCreate
                        onSaved={() => {
                            reloadQuoteRouteSegmentTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                        quoteId={quoteId}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar opción de tramo"
                    backdrop={true}
                    showFooter={false}
                >
                    <QuoteRouteSegmentEdit
                        quoteRouteSegmentIdEdit={quoteRouteSegmentIdEdit}
                        quoteId={quoteId}
                        onSaved={() => {
                            reloadQuoteRouteSegmentTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title="Información opción de ruta"
                    backdrop={true}
                    showFooter={false}
                >
                    <QuoteRouteSegmentShowForm quoteRouteSegmentShow={quoteRouteSegmentShow} />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default QuoteRouteSegmentsContainer;
