import React, { useState, useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import {
    Shift,
    ShiftDataForm,
    ShiftDays,
    ShiftWorkDayTravelExpense
} from '../../../../app/models/Shift';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { EventType } from '../../../../app/models/EventType';
import { CalendarEventDataForm } from '../../../../app/models/Event';
import { TravelExpenseDataForm } from '../../../../app/models/TravelExpense';
import { Table } from 'react-bootstrap';
import { momentParseDateToDate } from '../../../../helpers';
import { selectTravelExpenseById } from '../../utils/shiftWorkTravelExpense';

type Props = {
    calendarEventDataForm: CalendarEventDataForm;
    setCalendarEventDataForm: (calendarEventDataForm: CalendarEventDataForm) => void;
    eventTypes: EventType[];
    shiftWorks: Shift[];
    shiftWorkDays?: ShiftDays[];
    travelExpenses: TravelExpenseDataForm[];
    selectedTravelExpense?: TravelExpenseDataForm;
    errorFields?: any;
    selectedEventDate?: string | Date;
    selectedEventDateEnd?: string | Date;
    selectedStartHour?: string | Date;
    selectedEndHour?: string | Date;
    shifWorkSelected?: Shift;
    editable?: boolean;
    editableDate?: boolean;
};

const WorkerEventForm = ({
    calendarEventDataForm,
    setCalendarEventDataForm,
    selectedEventDateEnd,
    selectedStartHour,
    selectedEndHour,
    eventTypes,
    selectedEventDate,
    shiftWorks,
    shiftWorkDays,
    travelExpenses,
    selectedTravelExpense,
    errorFields,
    editable,
    shifWorkSelected,
    editableDate = true
}: Props) => {
    const [showIsShiftWork, setShowIsShiftWork] = useState(false);
    const [showEventType, setShowEventType] = useState(true);
    const [showShiftWorkDays, setShiftWorkDays] = useState(false);
    const [showTravelExpense, setShowTravelExpense] = useState(false);
    const [travelExpensesToShow, setTravelExpensesToShow] =
        useState<TravelExpenseDataForm[]>(travelExpenses);
    const [disabledEndDate, setDisabledEndDate] = useState(false);
    const [isDisabled, setIsDisabled] = useState(disabledEndDate || !editable);
    const [shiftDayTravelExpense, setShiftDayTravelExpense] = useState<ShiftWorkDayTravelExpense[]>(
        []
    );
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleCheckEventTypeChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name } = event.target;

        let isShiftWork;
        if (name === 'is_shiftwork') {
            isShiftWork = true;
            setShowIsShiftWork(true);
            setShowEventType(false);
            setShowTravelExpense(false);
        } else {
            isShiftWork = false;
            setShowIsShiftWork(false);
            setShowEventType(true);
        }

        setCalendarEventDataForm({
            ...calendarEventDataForm,
            is_shift_work: isShiftWork,
            title: '',
            event_type_id: 0
        });
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        const obj = { [name]: value };
        setCalendarEventDataForm({ ...calendarEventDataForm, ...obj });
    };

    const handleCheckChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name } = event.target;
        if (name === 'undefined') {
            setDisabledEndDate(true);
        } else {
            setDisabledEndDate(false);
        }

        setCalendarEventDataForm({
            ...calendarEventDataForm,
            is_undefined: !calendarEventDataForm.is_undefined
        });
    };

    useEffect(() => {
        setIsDisabled(disabledEndDate || !editable);
    }, [disabledEndDate, editable]);

    const handleChangeTravelExpenseSelectTwo = (
        name: string,
        value: number | string | null,
        index: number
    ) => {
        const obj = { [name]: value };
        const updatedTravelExpenses = shiftDayTravelExpense.map((row, i) => {
            if (i === index && value !== null) {
                const travelExpenseSelected = travelExpenses.filter((item) => item.id === value);
                if (travelExpenseSelected)
                    return {
                        ...row,
                        ...obj,
                        travel_expense: travelExpenseSelected[0]
                    };
            }
            return row;
        });
        setShiftDayTravelExpense(updatedTravelExpenses);
        setCalendarEventDataForm({
            ...calendarEventDataForm,
            shift_Work_day_travel_expense: updatedTravelExpenses
        });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        const eventTypeSelected = eventTypes.find((eventType) => eventType.id === value);
        let title: string;
        if (eventTypeSelected) {
            title = eventTypeSelected.name;
            if (eventTypeSelected.is_travel_expense) {
                setShowTravelExpense(true);
                const travelExpenseByEvent = selectTravelExpenseById(
                    travelExpenses,
                    eventTypeSelected.id,
                    calendarEventDataForm?.start_date
                );
                if (travelExpenseByEvent) setTravelExpensesToShow(travelExpenseByEvent);
            }
        } else {
            title = '-';
        }

        setCalendarEventDataForm({
            ...calendarEventDataForm,
            ...obj,
            title
        });
    };

    const handleChangeSelectTravelExpense = (name: string, value: number | string | null) => {
        const obj = { [name]: value };
        setCalendarEventDataForm({
            ...calendarEventDataForm,
            ...obj
        });
    };

    const handleChangeShiftWorkSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };
        setShiftWorkDays(true);

        const filteredShiftWorkDays = shiftWorkDays?.filter((item) => item.shift_work_id === value);

        const updatedShiftWorks: any[] = [];
        filteredShiftWorkDays?.forEach((shiftWorkDay) => {
            updatedShiftWorks.push({
                ...shiftWorkDay,
                travel_expenses: selectTravelExpenseById(
                    travelExpenses,
                    shiftWorkDay.event_type?.id,
                    calendarEventDataForm?.start_date
                )
            });
        });

        const shiftWorkDaysTravelExpenses = updatedShiftWorks.map((item, index) => ({
            shift_work_id: value,
            position: index
        }));

        setShiftDayTravelExpense(shiftWorkDaysTravelExpenses);
        setCalendarEventDataForm({
            ...calendarEventDataForm,
            ...obj,
            shift_days: updatedShiftWorks,
            shift_Work_day_travel_expense: [],
            event_type_id: updatedShiftWorks[0].event_type?.id,
            title: updatedShiftWorks[0].event_type?.name
        });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                {editable && (
                    <>
                        <div className="my-2 row">
                            <div className="col-6">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="not_shiftwork"
                                        value="not_shiftwork"
                                        checked={!calendarEventDataForm.is_shift_work}
                                        id="not_shiftwork"
                                        onChange={handleCheckEventTypeChange}
                                    />
                                    <label className="form-check-label" htmlFor="is_shiftwork">
                                        Agregar Evento
                                    </label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="is_shiftwork"
                                        value="is_shiftwork"
                                        checked={calendarEventDataForm.is_shift_work}
                                        id="is_shiftwork"
                                        onChange={handleCheckEventTypeChange}
                                    />
                                    <label className="form-check-label" htmlFor="is_shiftwork">
                                        Agregar Jornada
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {showEventType && (
                    <>
                        <label className="col-md-3 col-form-label">Tipo de evento</label>
                        <div className="col-md-8">
                            <SelectTwo
                                name="event_type_id"
                                id="event_type_id"
                                inputValue={calendarEventDataForm.event_type_id}
                                options={SelectTwoMapperOptions(eventTypes)}
                                disabled={!editable}
                                hasError={fieldHasError('event_type_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('event_type_id', value?.value)
                                }
                                placeholder={'Seleccione el tipo de evento'}
                                onFocus={() => onFocusRemove('event_type_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('event_type_id')}
                            </div>
                        </div>
                    </>
                )}

                {showIsShiftWork && (
                    <>
                        <label className="col-md-3 col-form-label">Jornada</label>
                        <div className="col-md-8">
                            <SelectTwo
                                name="shift_work_id"
                                id="shift_work_id"
                                inputValue={calendarEventDataForm.shift_work_id}
                                disabled={!editable}
                                options={SelectTwoMapperOptions(shiftWorks)}
                                hasError={fieldHasError('shift_work_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeShiftWorkSelectTwo('shift_work_id', value?.value)
                                }
                                placeholder={'Seleccione la jornada'}
                                onFocus={() => onFocusRemove('shift_work_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('shift_work_id')}
                            </div>
                        </div>

                        <div className="my-2 row">
                            <div className="col-6">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="temporal"
                                        value="temporal"
                                        disabled={!editable}
                                        checked={!calendarEventDataForm.is_undefined}
                                        id="is_defined"
                                        onChange={handleCheckChange}
                                    />
                                    <label className="form-check-label" htmlFor="is_defined">
                                        Temporal
                                    </label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="undefined"
                                        value="undefined"
                                        disabled={!editable}
                                        checked={calendarEventDataForm.is_undefined}
                                        id="is_undefined"
                                        onChange={handleCheckChange}
                                    />
                                    <label className="form-check-label" htmlFor="is_undefined">
                                        Indefinido
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {showShiftWorkDays && (
                    <div className="col-md-12 my-3">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Días</th>
                                    <th>Tipo de turno</th>
                                    <th>Viáticos</th>
                                </tr>
                            </thead>
                            <tbody>
                                {calendarEventDataForm?.shift_days?.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{item.number_of_days}</td>
                                        <td>{item.event_type?.name}</td>
                                        <td>
                                            <div className="col-md-9">
                                                <SelectTwo
                                                    name="travel_expense"
                                                    id={`travel_expense_${index}`}
                                                    inputValue={
                                                        calendarEventDataForm
                                                            ?.shift_Work_day_travel_expense?.[index]
                                                            ?.travel_expense?.id
                                                    }
                                                    options={SelectTwoMapperOptions(
                                                        item?.travel_expenses || []
                                                    )}
                                                    hasError={
                                                        fieldHasError('travel_expense') !== ''
                                                    }
                                                    onChange={(value: any) =>
                                                        handleChangeTravelExpenseSelectTwo(
                                                            'travel_expense_id',
                                                            value?.value,
                                                            index
                                                        )
                                                    }
                                                    placeholder={'Seleccione viático'}
                                                    onFocus={() => onFocusRemove('travel_expense')}
                                                />
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: 'flex' }}
                                                >
                                                    {fieldErrorMessage('travel_expense')}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}

                {editableDate && (
                    <div className="mb-2 row">
                        <div className="col-md-6">
                            <label className="col-md-6 col-form-label">Fecha de inicio</label>
                            <div className="col-md-9">
                                <input
                                    type="date"
                                    className={`form-control ${fieldHasError('start_date')}`}
                                    name="start_date"
                                    id="start_date"
                                    value={
                                        selectedEventDate
                                            ? selectedEventDate.toString()
                                            : calendarEventDataForm.start_date?.toString()
                                    }
                                    disabled={!editable}
                                    onChange={handleChange}
                                    placeholder="Ingrese una fecha"
                                    onFocus={() => onFocusRemove('date')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('start_date')}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="col-md-9 col-form-label">Fecha de término</label>
                            <div className="col-md-9">
                                <input
                                    type="date"
                                    className={`form-control ${fieldHasError('end_date')}`}
                                    name="end_date"
                                    id="end_date"
                                    disabled={isDisabled}
                                    value={
                                        selectedEventDateEnd
                                            ? selectedEventDateEnd.toString()
                                            : calendarEventDataForm.end_date?.toString()
                                    }
                                    onChange={handleChange}
                                    placeholder="Ingrese una fecha"
                                    onFocus={() => onFocusRemove('end_date')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('end_date')}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="mb-2 row">
                    <div className="col-md-6">
                        <label className="col-md-6 col-form-label">Hora de inicio</label>
                        <div className="col-md-9">
                            <input
                                type="time"
                                className={`form-control ${fieldHasError('start_hour')}`}
                                name="start_hour"
                                id="start_hour"
                                value={
                                    selectedStartHour
                                        ? selectedStartHour.toString()
                                        : calendarEventDataForm.start_hour?.toString()
                                }
                                disabled={!editable}
                                onChange={handleChange}
                                placeholder="Ingrese una hora"
                                onFocus={() => onFocusRemove('start_hour')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('start_hour')}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className="col-md-9 col-form-label">Hora de término</label>
                        <div className="col-md-9">
                            <input
                                type="time"
                                className={`form-control ${fieldHasError('end_hour')}`}
                                name="end_hour"
                                id="end_hour"
                                value={
                                    selectedEndHour
                                        ? selectedEndHour.toString()
                                        : calendarEventDataForm.end_hour?.toString()
                                }
                                disabled={!editable}
                                onChange={handleChange}
                                placeholder="Ingrese una hora"
                                onFocus={() => onFocusRemove('end_hour')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('end_hour')}
                            </div>
                        </div>
                    </div>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('non_field_errors')}
                    </div>

                    {selectedTravelExpense && (
                        <div className="mb-2 row">
                            <div className="col-md-6">
                                <label className="col-md-6 col-form-label">Viático</label>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className={`form-control ${fieldHasError('start_date')}`}
                                        name="start_date"
                                        id="start_date"
                                        value={`${selectedTravelExpense.budget} ${selectedTravelExpense.currency}`}
                                        disabled={!editable}
                                        onChange={handleChange}
                                        placeholder="Ingrese una fecha"
                                        onFocus={() => onFocusRemove('date')}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('start_date')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showTravelExpense && (
                        <div className="mb-2 row">
                            <div className="col-md-8">
                                <label className="col-md-6 col-form-label">Viático</label>
                                <div className="col-md-9">
                                    <SelectTwo
                                        name="start_date"
                                        id="start_date"
                                        inputValue={calendarEventDataForm?.travel_expense_id}
                                        options={SelectTwoMapperOptions(travelExpensesToShow)}
                                        disabled={!editable}
                                        onChange={(value: any) =>
                                            handleChangeSelectTravelExpense(
                                                'travel_expense_id',
                                                value.value
                                            )
                                        }
                                        placeholder="Ingrese un  viático"
                                        onFocus={() => onFocusRemove('date')}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('start_date')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('worker_id')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default WorkerEventForm;
