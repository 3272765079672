import { useContext, useEffect, useState} from 'react'
import useBonusService from '../../../app/services/hooks/useBonusService'
import { Bonus, defaultBonus, TypeOfBonusesValues } from '../../../app/models/Bonus'
import { AppContext } from '../../../contexts/AppContext'
import { ServiceResponse } from '../../../app/services/shared/interfaces'
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert'
import { toast } from 'react-toastify'
import LazyLoading from '../../../components/LazyLoading'
import BonusFormContainer from '../components/BonusFormContainer'
import { Currency } from '../../../app/models/Currency'

interface Props {
  onSaved?: () => void;
  onCancel?: () => void;
  onError?: () => void;
}

const BonusCreate = ({ onSaved, onCancel, onError }: Props) => {

  const {showLoading, hideLoading} = useContext(AppContext);
  const { fetchingStoreBonus, storeBonus, createBonus, fetchingCreateBonus} = useBonusService();
  const [errorFields, setErrorFields] = useState<any>();
  const [bonus, setBonus] = useState<Bonus>(defaultBonus);

  const store = () => {
    if (showLoading) showLoading('loading', 'Guardando Bonificación...');
    storeBonus(bonus, {
        onSuccess: (response: ServiceResponse) => {
            if (hideLoading) hideLoading();
            useReactConfirmAlert().successAlert({
                title: 'Éxito',
                message: response.message
            });
            toast.success(response.message, {
                autoClose: 2500
            });
            if (onSaved) onSaved();
        },
        onError: (response: ServiceResponse) => {
            if (hideLoading) hideLoading();
            useReactConfirmAlert().errorAlert({
                title: 'Error',
                message: response.message
            });
        },
        onFieldError: (errorFields: ServiceResponse) => {
            if (hideLoading) hideLoading();
            setErrorFields(errorFields.data);
        }
    });
  };
  
  const cancel = () => {
    if (onCancel) onCancel();
  };

  return fetchingCreateBonus ? (
    <LazyLoading height={300} />
  ) : (
    <BonusFormContainer
      action={store} 
      cancel={cancel} 
      bonus={bonus} 
      setBonus={setBonus}
      errorFields={errorFields}
    />
  )
}

export default BonusCreate