import React, { useEffect, useState } from 'react';
import { LiquidationGenerator, LiquidationDataForm } from '../../../../app/models/Liquidation';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Contract } from '../../../../app/models/Contract';

import { JobTitle } from '../../../../app/models/JobTitle';

interface Props {
    liquidation: LiquidationGenerator;
    setLiquidation: (Liquidation: LiquidationGenerator) => void;
    contracts: Contract[];
    jobTitles: JobTitle[];
    is_edit?: boolean;
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const LiquidationForm = ({
    liquidation,
    setLiquidation,
    contracts,
    jobTitles,
    is_edit,
    errorFields,
    setErrorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        const obj: { [key: string]: any } = { [name]: value };
        if (name === 'period') {
            obj[name] = value;
        }

        setLiquidation({ ...liquidation, ...obj });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj: { [key: string]: any } = {
            [name]: value
        };
        setLiquidation({ ...liquidation, ...obj });
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-md-6 col-form-label">Periodo</label>
                    <div className="col-md-12">
                        <input
                            type="month"
                            className={`form-control ${fieldHasError('period')}`}
                            name="period"
                            id="period"
                            value={liquidation.period}
                            onChange={handleChange}
                            disabled={is_edit}
                            placeholder="Ingrese una fecha"
                            onFocus={() => onFocusRemove('date')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('period')}
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="col-form-label">Puestos de trabajo</label>
                    <SelectTwo
                        name="job_title_id"
                        id="job_title_id"
                        inputValue={liquidation.job_title_id ?? ''}
                        options={SelectTwoMapperOptions(jobTitles, 'id', ['name'])}
                        hasError={fieldHasError('job_title_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('job_title_id', value?.value)
                        }
                        placeholder={'Seleccione'}
                        onFocus={() => onFocusRemove('job_title_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('job_title_id')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LiquidationForm;
