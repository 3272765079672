import { EndPoints } from '../EndPoints';
import {
    TransportationQuoteDetailDataForm,
    TransportationQuoteDetailDataFormFilters
} from '../../models/Quote';
import useFetch from '../../../hooks/useFetch';
import { useState, useContext } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';

const useTransportationQuoteDetailService = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetTransportationQuoteDetails, setFetchingGetTransportationQuoteDetails] =
        useState(false);
    const [fetchingStoreTransportationQuoteDetail, setFetchingStoreTransportationQuoteDetail] =
        useState(false);
    const [fetchingEditTransportationQuoteDetail, setFetchingEditTransportationQuoteDetail] =
        useState(false);
    const [fetchingUpdateTransportationQuoteDetail, setFetchingUpdateTransportationQuoteDetail] =
        useState(false);
    const [fetchingDeleteTransportationQuoteDetail, setFetchingDeleteTransportationQuoteDetail] =
        useState(false);
    const [fetchingCreateTransportationQuoteDetail, setFetchingCreateTransportationQuoteDetail] =
        useState(false);
    const [fetchingShowTransportationQuoteDetail, setFetchingShowTransportationQuoteDetail] =
        useState(false);

    const [
        fetchingGetQuoteRouteSegmentsToQuoteDetail,
        setFetchingGetQuoteRouteSegmentsToQuoteDetail
    ] = useState(false);

    const getAllTransportationQuoteDetailsByRequest = (
        id: number,
        transportationQuoteFilter: TransportationQuoteDetailDataFormFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(transportationQuoteFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.QUOTES.GET_TRANSPORTATION_QUOTE_DETAILS_BY_REQUEST}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':id', id.toString()),
            setFetching: setFetchingGetTransportationQuoteDetails
        });
    };

    // const getAllTransportationQuoteDetailsByRequest = (id: number, events: ServiceEvents = {}) => {
    //     doGet({
    //         ...events,
    //         url: EndPoints.QUOTES.GET_TRANSPORTATION_QUOTE_DETAILS_BY_REQUEST.replace(
    //             ':id',
    //             id.toString()
    //         ),
    //         setFetching: setFetchingGetTransportationQuoteDetails
    //     });
    // };

    const createTransportationQuoteDetail = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.QUOTES.CREATE_TRANSPORTATION_QUOTE_DETAIL,
            setFetching: setFetchingCreateTransportationQuoteDetail
        });
    };

    const storeTransportationQuoteDetail = (
        transportationQuoteDetailDataForm: TransportationQuoteDetailDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.QUOTES.STORE_TRANSPORTATION_QUOTE_DETAIL,
            body: transportationQuoteDetailDataForm,
            setFetching: setFetchingStoreTransportationQuoteDetail
        });
    };

    const editTransportationQuoteDetail = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.QUOTES.EDIT_TRANSPORTATION_QUOTE_DETAIL.replace(':id', id.toString()),
            setFetching: setFetchingEditTransportationQuoteDetail
        });
    };

    const showTransportationQuoteDetail = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.QUOTES.SHOW_TRANSPORTATION_QUOTE_DETAIL.replace(':id', id.toString()),
            setFetching: setFetchingShowTransportationQuoteDetail
        });
    };

    const updateTransportationQuoteDetail = (
        id: number,
        transportationQuoteDetailDataForm: TransportationQuoteDetailDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.QUOTES.UPDATE_TRANSPORTATION_QUOTE_DETAIL.replace(':id', id.toString()),
            body: transportationQuoteDetailDataForm,
            setFetching: setFetchingUpdateTransportationQuoteDetail
        });
    };

    const deleteTransportationQuoteDetail = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.QUOTES.DELETE_TRANSPORTATION_QUOTE_DETAIL.replace(':id', id.toString()),
            setFetching: setFetchingDeleteTransportationQuoteDetail
        });
    };

    const getQuoteRouteSegmentsToQuoteDetail = (
        quote_id: number,
        route_segment_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.QUOTES.GET_QUOTE_ROUTE_SEGMENTS_TO_QUOTE_DETAIL.replace(
                ':quote_id',
                quote_id.toString()
            ).replace(':route_segment_id', route_segment_id.toString()),
            setFetching: setFetchingGetQuoteRouteSegmentsToQuoteDetail
        });
    };

    return {
        getAllTransportationQuoteDetailsByRequest,
        createTransportationQuoteDetail,
        storeTransportationQuoteDetail,
        editTransportationQuoteDetail,
        updateTransportationQuoteDetail,
        deleteTransportationQuoteDetail,
        showTransportationQuoteDetail,
        getQuoteRouteSegmentsToQuoteDetail,
        fetchingShowTransportationQuoteDetail,
        fetchingGetTransportationQuoteDetails,
        fetchingStoreTransportationQuoteDetail,
        fetchingEditTransportationQuoteDetail,
        fetchingUpdateTransportationQuoteDetail,
        fetchingDeleteTransportationQuoteDetail,
        fetchingCreateTransportationQuoteDetail,
        fetchingGetQuoteRouteSegmentsToQuoteDetail
    };
};

export default useTransportationQuoteDetailService;
