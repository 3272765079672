export enum Roles {
    ADMIN = 'administrador',
    MANAGERS = 'gerentes',
    RUNNER = 'runner',
    OPERATIONS_MANAGER = 'jefe_operaciones'
}

export enum OptionTrip {
    ONE_WAY = 'ONE_WAY',
    ROUND_TRIP = 'ROUND_TRIP'
}
