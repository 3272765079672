import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import DefaultCard from '../../../../components/default/DefaultCard';
import ButtonCreate from '../../../../components/buttons/ButtonCreate';
import LazyLoading from '../../../../components/LazyLoading';
import DefaultModal from '../../../../components/default/DefaultModal';

import useExpenseService from '../../../../app/services/hooks/useExpenseService';
import { ExpenseDataForm, ExpenseFilters } from '../../../../app/models/Expense';
import CalendarExpenseDataTable from '../tables/CalendarExpenseDataTable';
import CalendarExpenseCreate from './CalendarExpenseCreate';
import CalendarExpenseEdit from './CalendarExpenseEdit';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../../contexts/AppContext';

interface Props {
    eventId: number;
    calendarDate: string | Date;
    admin: boolean;
}

const CalendarExpensesContainer = ({ eventId, calendarDate, admin }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingGetExpensesByEventId,
        deleteCalendarExpense,
        getAllExpensesByEventId,
        fetchingDeleteCalendarExpense,
        downloadDocument,
        getUrlDocumentCalendarExpense,
        approvedCalendarExpense
    } = useExpenseService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingGet, setShowingGet] = useState(false);

    const [expenseIdEdit, setExpenseIdEdit] = useState<number>(-1);

    const [expenses, setExpenses] = useState<ExpenseDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<ExpenseFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        issue_date_first: calendarDate.toString()
    });

    useEffect(() => {
        reloadTable();
    }, [eventId]);

    const reloadTable = () => {
        getAllExpensesByEventId(filter, eventId, {
            onSuccess: (response: ServiceResponse) => {
                setExpenses(response.data.expenses);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (expenseId: number) => {
        setShowingEdit(true);
        setExpenseIdEdit(expenseId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setExpenseIdEdit(-1);
    };

    const showGet = (expenseId: number) => {
        setShowingGet(true);
        setExpenseIdEdit(expenseId);
    };

    const hideGet = () => {
        setShowingGet(false);
        setExpenseIdEdit(-1);
    };

    const document = (expenseId: number) => {
        if (showLoading) showLoading('download', 'descargando archivo...');
        getUrlDocumentCalendarExpense(expenseId, {
            onSuccess: (response: ServiceResponse) => {
                window.open(response.data.url_file);
                if (changeAnimation)
                    changeAnimation('downloadSuccess', 'Archivo recuperado con exito', true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) {
                    hideLoading();
                }
                toast.error(response.message);
            }
        });
    };
    const destroy = (expenseId: number) => {
        const _text = 'Está a punto de eliminar el gasto #' + expenseId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando gasto de viaje...');
                        deleteCalendarExpense(expenseId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El gasto de viaje no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const approved = (expenseId: number, value: boolean, message: string) => {
        const _text = `El gasto # ${expenseId} está apunto de ser ${message}`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', `Cambiando estado de gasto...`);
                        approvedCalendarExpense(expenseId, value, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: `El gasto no se ha ${message}.`
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <h5>Gastos</h5>
                    <div className="col-12 mb-2 mt-2">
                        <ButtonCreate title="Nuevo gasto" callbackCreate={showCreate} />
                    </div>
                    <div className="col-12">
                        <CalendarExpenseDataTable
                            loading={fetchingGetExpensesByEventId}
                            expenses={expenses}
                            totalRows={totalRows}
                            edit={showEdit}
                            // show={showGet}
                            approved={admin ? approved : undefined}
                            destroy={destroy}
                            filter={filter}
                            setFilter={setFilter}
                            documentExpense={document}
                            onSearch={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear gasto de viaje"
                    backdrop={true}
                    showFooter={false}
                >
                    <CalendarExpenseCreate
                        eventId={eventId}
                        calendarDate={calendarDate}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Gasto de viaje"
                    backdrop={true}
                    showFooter={false}
                >
                    <CalendarExpenseEdit
                        expenseId={expenseIdEdit}
                        eventId={eventId}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default CalendarExpensesContainer;
