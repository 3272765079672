import { useEffect, ChangeEvent, useState } from 'react';
import { JobTitleDataForm } from '../../../../app/models/JobTitle';
import { setCleanTextAreaError } from '../../../../utils/utils';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { Group } from '../../../../app/models/Group';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';

type Props = {
    groups: Group[];
    jobTitleDataForm: JobTitleDataForm;
    setJobTitleDataForm: (jobTitleDataForm: JobTitleDataForm) => void;
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
};

const JobTitleForm = ({
    groups,
    jobTitleDataForm,
    setJobTitleDataForm,
    errorFields,
    setErrorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e:
            | ChangeEvent<HTMLInputElement>
            | ChangeEvent<HTMLSelectElement>
            | ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setJobTitleDataForm({ ...jobTitleDataForm, [name]: value });
    };

    if (!jobTitleDataForm) return null;

    return (
        <>
            <div className="mb-2 row needs-validation">
                <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Nombre</label>
                    <div className="col-md-9">
                        <input
                            name="name"
                            id="name"
                            type="text"
                            className={`form-control ${fieldHasError('name')}`}
                            value={jobTitleDataForm.name}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('name')}
                            placeholder="Ingrese el nombre del puesto de trabajo"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('name')}
                        </div>
                    </div>
                </div>

                <div className="mb-2 row">
                    <label className="col-md-4 col-form-label">Roles</label>
                    <div className="col-md-8">
                        <SelectTwo
                            name="groups"
                            id="groups"
                            inputValue={jobTitleDataForm.groups}
                            options={SelectTwoMapperOptions(groups)}
                            hasError={fieldHasError('groups') !== ''}
                            onChange={(value: any) =>
                                setJobTitleDataForm({
                                    ...jobTitleDataForm,
                                    groups: value
                                })
                            }
                            isSortable={true}
                            isMulti={true}
                            placeholder={'Seleccione los grupos'}
                            onFocus={() => onFocusRemove('groups')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('groups')}
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="col-form-label">Descripción</label>

                    <textarea
                        className={`form-control ${fieldHasError('description')}`}
                        name="description"
                        id="description"
                        value={jobTitleDataForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default JobTitleForm;
