import { useContext, useEffect, useState } from 'react'
import { ServiceResponse } from '../../../app/services/shared/interfaces'
import { toast } from 'react-toastify'
import LazyLoading from '../../../components/LazyLoading'
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm'
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm'
import { AppContext } from '../../../contexts/AppContext'
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert'
import BonusRateForm from '../components/forms/BonusRateForm'
import { TypeOfBonusesValues } from '../../../app/models/Bonus'
import { Currency } from '../../../app/models/Currency'
import { getCurrentMonth } from '../../../helpers'
import useBonusRateService from '../../../app/services/hooks/useBonusRateService'
import { BonusRateDataForm, defaultBonusRateDataForm } from '../../../app/models/BonusRate'
import useCalculatedFieldService from '../../../app/services/hooks/useCalculatedFielService'
import { Item } from '../../../app/models/Item'

interface Props {
    bonusId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const BonusRateCreate = ({ onSaved, onCancel, onError, bonusId }: Props) => {

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { createBonusRate, storeBonusRate, fetchingCreateBonusRate, fetchingStoreBonusRate } = useBonusRateService();
    const { createCalculatedField, fetchingCreateCalculatedFiel } = useCalculatedFieldService()

    const [bonusRate, setBonusRate] = useState<BonusRateDataForm>({
        ...defaultBonusRateDataForm,
        valid_from: getCurrentMonth(),
        valid_until: getCurrentMonth(),
        bonus_id: bonusId
    });
    
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [bonusTypes, setBonusTypes] = useState<TypeOfBonusesValues[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
    const [items, setItems] = useState<Item[]>([]);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createBonusRate({
            onSuccess: (response: ServiceResponse) => {
                setBonusTypes(response.data.type_of_bonuses);
                setCurrencies(response.data.currencies);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {
        if (bonusRate.bonus_type === 'Calculado' && items.length < 1) createCalculatedFields();
    },[bonusRate])

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando tarifa de deducción...');
        storeBonusRate(bonusRate, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const createCalculatedFields = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createCalculatedField(bonusId, 'bonus', {
            onSuccess: (response: ServiceResponse) => {
                setItems(response.data.item_list)
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateBonusRate ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <BonusRateForm 
                        bonusRateForm={bonusRate} 
                        setBonusRateForm={setBonusRate}
                        TypeOfBonuses={bonusTypes}
                        currencies={currencies}
                        errorFields={errorFields}
                        items={items}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreBonusRate} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreBonusRate} />
                </div>
            </div>
        </>
    );
};

export default BonusRateCreate;