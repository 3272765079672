import React, { useState } from 'react';
import { formatMoney, formatNumber } from '../../../../utils/utils';
import { Accordion, Button, Collapse } from 'react-bootstrap';
import { InternalSettlementShowData } from '../../../../app/models/InternalSettlement';

interface Props {
    internalSettlementDataForm: InternalSettlementShowData;
}

const InternalSettlementShowForm = ({ internalSettlementDataForm }: Props) => {
    if (!internalSettlementDataForm) return null;

    return (
        <>
            <>
                <Accordion>
                    {internalSettlementDataForm.expenses
                        ? internalSettlementDataForm.expenses.map((expense, index) => {
                              return (
                                  <Accordion.Item
                                      eventKey={`expense${index}`}
                                      key={`expense${index}`}
                                  >
                                      <Accordion.Header>
                                          <span className="text-uppercase">
                                              Gasto n°{expense.id}
                                          </span>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                          <div className="row pt-2">
                                              <div className="mb-2 row">
                                                  <div className="col-md-12">
                                                      <label className="col-form-label">
                                                          Tipo de gasto
                                                      </label>
                                                      <input
                                                          name="expense_type"
                                                          id="expense_type"
                                                          type="text"
                                                          className="form-control"
                                                          value={expense.expense_type}
                                                          disabled={true}
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="mb-2 row">
                                              <div className="col-md-6">
                                                  <label className="col-form-label">Valor</label>
                                                  <input
                                                      name={'amount'}
                                                      id={`amount`}
                                                      type="text"
                                                      className="form-control"
                                                      value={formatMoney(
                                                          expense.amount ?? 0,
                                                          expense.currency
                                                      )}
                                                      disabled={true}
                                                  />
                                              </div>

                                              <div className="col-md-6">
                                                  <label className="col-form-label">Moneda</label>
                                                  <input
                                                      name={'currency'}
                                                      id={`currency`}
                                                      type="text"
                                                      className="form-control"
                                                      value={expense.currency}
                                                      disabled={true}
                                                  />
                                              </div>
                                          </div>
                                          <div className="row pt-2">
                                              <div className="mb-2 row">
                                                  <div className="col-md-12">
                                                      <label className="col-form-label">
                                                          Fecha de gasto
                                                      </label>
                                                      <input
                                                          name="issue_date"
                                                          id="issue_date"
                                                          type="text"
                                                          className="form-control"
                                                          value={expense.issue_date}
                                                          disabled={true}
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row pt-2">
                                              <div className="mb-2 row">
                                                  <div className="col-md-12">
                                                      <label className="col-form-label">
                                                          Proveedor
                                                      </label>
                                                      <input
                                                          name="provider"
                                                          id="provider"
                                                          type="text"
                                                          className="form-control"
                                                          value={expense.provider}
                                                          disabled={true}
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row pt-2">
                                              <div className="mb-2 row">
                                                  <div className="col-md-12">
                                                      <label className="col-form-label">
                                                          Tipo de documento
                                                      </label>
                                                      <input
                                                          name="expense_document_type"
                                                          id="expense_document_type"
                                                          type="text"
                                                          className="form-control"
                                                          value={expense.expense_document_type}
                                                          disabled={true}
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row pt-2">
                                              <div className="mb-2 row">
                                                  <div className="col-md-12">
                                                      <label className="col-form-label">
                                                          n° folio
                                                      </label>
                                                      <input
                                                          name="folio"
                                                          id="folio"
                                                          type="text"
                                                          className="form-control"
                                                          value={expense.folio}
                                                          disabled={true}
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row pt-2">
                                              <div className="mb-2 row">
                                                  <div className="col-md-12">
                                                      <label className="col-form-label">
                                                          Rendir gasto
                                                      </label>
                                                      <input
                                                          name="is_reported"
                                                          id="is_reported"
                                                          type="text"
                                                          className="form-control"
                                                          value={expense.is_reported ? 'Si' : 'No'}
                                                          disabled={true}
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row pt-2">
                                              <div className="mb-2 row">
                                                  <div className="col-md-12">
                                                      <label className="col-form-label">
                                                          Boleta
                                                      </label>
                                                      {expense.photo_url ? (
                                                          <div>
                                                              <a
                                                                  href={expense.photo_url}
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  className="btn btn-success btn-sm"
                                                                  role="button"
                                                                  aria-pressed="true"
                                                              >
                                                                  Descargar
                                                              </a>
                                                          </div>
                                                      ) : (
                                                          <div>
                                                              <p>No tiene boleta</p>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row pt-2">
                                              <div className="mb-2 row">
                                                  <div className="col-md-12">
                                                      <label className="col-form-label">
                                                          Descripción
                                                      </label>
                                                      <textarea
                                                          name="description"
                                                          id="description"
                                                          className="form-control"
                                                          value={expense.description}
                                                          disabled={true}
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                      </Accordion.Body>
                                  </Accordion.Item>
                              );
                          })
                        : null}
                </Accordion>
            </>
        </>
    );
};

export default InternalSettlementShowForm;
