import { useContext, useEffect, useState} from 'react'
import Breadcrumbs, {BreadcrumbsItem} from '../../template/MainTheme/components/Breadcrumbs'
import DefaultCard from '../../components/default/DefaultCard'
import DefaultToolBar from '../../components/default/DefaultToolBar'
import ButtonCreate from '../../components/buttons/ButtonCreate'
import { ServiceResponse } from '../../app/services/shared/interfaces'
import useNavigationPage from '../../hooks/useNavigationPage'
import { toast } from 'react-toastify'
import { AppContext } from '../../contexts/AppContext'
import DefaultModal from '../../components/default/DefaultModal'
import useDeductionService from '../../app/services/hooks/useDeductionServices'
import { Deduction, DeductionFilters } from '../../app/models/Deduction'
import DeductionDataTable from './components/tables/DeductionDataTable'
import DeductionCreate from './subpages/DeductionCreate'
import DeductionEdit from './subpages/DeductionEdit'
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert'

const breadcrumbs: BreadcrumbsItem[] =[
    {
        name: 'Deducciones',
        url: '/deductions',
        isActive: true
    }
]

const Deductions = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { fetchingGetDeductions, getDeductions, deleteDeduction, activeDeduction } =
        useDeductionService();
    const [showingCreate, setShowingCreate] = useState(false);

    const [deduction, setDeduction] = useState<Deduction[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [deductionFilter, setDeductionFilter] = useState<DeductionFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        name: ''
    });

    useEffect(() => {
        reloadTable();
    }, [deductionFilter.page, deductionFilter.per_page, deductionFilter.sort, deductionFilter.order]);

    const getAllDeductions = () => {
        getDeductions(deductionFilter, {
            onSuccess: (response: ServiceResponse) => {
                setDeduction(response.data.deduction);
                setTotalRows(response.data.total_rows)
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const destroy = (deductionId: number) => {
        const _text = 'Está a punto de eliminar la deducción #' + deductionId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando deducción...');
                        deleteDeduction(deductionId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La deducción no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    }

    const showCreate = () => {
        setShowingCreate(true);
    };

    const showEdit = (deductionId: number) => {
        navigationPage('/deductions/' + deductionId + '/edit');
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllDeductions();
    };


  return (
    <>
        <Breadcrumbs pageSection='Deducciones' breadcrumbs={breadcrumbs} />
        <DefaultCard>
            <DefaultToolBar
                left={
                    <ButtonCreate
                        callbackCreate={showCreate}
                        title='Nueva deducción'
                    />
                }
            />
            <DeductionDataTable
                deductions={deduction}
                totalRows={totalRows}
                filter={deductionFilter}
                loading={fetchingGetDeductions}
                setFilter={setDeductionFilter}
                callbackSuccess={reloadTable}
                destroy={destroy}
                edit={showEdit}
                onSearch={reloadTable}
            />
        </DefaultCard>

        {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear deducción"
                    backdrop={true}
                    showFooter={false}>
                    <DeductionCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

    </>
  )
}

export default Deductions