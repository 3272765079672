import React, { useEffect } from 'react';
import { CarrierDataForm } from '../../../../app/models/Carrier';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useRut from '../../../../hooks/useRut';

import { TollRateDataForm } from '../../../../app/models/TollRate';
import { Currency } from '../../../../app/models/Currency';
import { VehicleType } from '../../../../app/models/VehicleType';

interface GeneralProps {
    tollRateForm: TollRateDataForm;
    setTollRateForm: (tolRate: TollRateDataForm) => void;
    currencies: Currency[];
    vehicleTypes: VehicleType[];
    errorFields?: any;
}

const TollRateForm = ({
    tollRateForm,
    setTollRateForm,
    currencies,
    vehicleTypes,
    errorFields
}: GeneralProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        setTollRateForm({ ...tollRateForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setTollRateForm({ ...tollRateForm, ...obj });
    };

    if (!tollRateForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Tipo de vehículo</label>
                    <SelectTwo
                        name="vehicle_type"
                        id="vehicle_type"
                        inputValue={tollRateForm.vehicle_type}
                        options={SelectTwoMapperOptions(vehicleTypes, 'id', ['name'])}
                        hasError={fieldHasError('vehicle_type') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('vehicle_type', value.value)
                        }
                        placeholder={'Seleccione tipo de vehículo'}
                        onFocus={() => onFocusRemove('vehicle_type')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('vehicle_type')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Horario inicio</label>

                    <input
                        type="time"
                        className={`form-control ${fieldHasError('start_time')}`}
                        name="start_time"
                        id="start_time"
                        value={tollRateForm.start_time}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('start_time')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('start_time')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Horario fin</label>

                    <input
                        type="time"
                        className={`form-control ${fieldHasError('end_time')}`}
                        name="end_time"
                        id="end_time"
                        value={tollRateForm.end_time}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('end_time')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('end_time')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Valor</label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                type="number"
                                name="amount"
                                id="amount"
                                onFocus={() => onFocusRemove('amount')}
                                className={`form-control ${fieldHasError('amount')}`}
                                value={tollRateForm.amount}
                                onChange={handleChange}
                                placeholder="Ingrese cantidad"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('amount')}
                            </div>
                        </div>

                        <div className="col-6">
                            <SelectTwo
                                name="currency_id"
                                id="currency_id"
                                inputValue={tollRateForm.currency_id}
                                options={SelectTwoMapperOptions(currencies)}
                                hasError={fieldHasError('currency_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('currency_id', value.value)
                                }
                                onFocus={() => onFocusRemove('currency_id')}
                                placeholder={'moneda'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('currency_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Válido desde</label>

                    <input
                        type="date"
                        className={`form-control ${fieldHasError('valid_from')}`}
                        name="valid_from"
                        id="valid_from"
                        value={tollRateForm.valid_from}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('valid_from')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('valid_from')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Válido hasta</label>

                    <input
                        type="date"
                        className={`form-control ${fieldHasError('valid_until')}`}
                        name="valid_until"
                        id="valid_until"
                        value={tollRateForm.valid_until}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('valid_until')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('valid_until')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TollRateForm;
