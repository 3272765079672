import React from 'react';
import { HolidayDataForm } from '../../../app/models/Holiday';
import HolidayForm from './forms/HolidayForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    holiday: HolidayDataForm;
    setHoliday: (holiday: HolidayDataForm) => void;
    errorFields?: any;
}

const HolidayFormContainer = ({
    fetching,
    action,
    cancel,
    holiday,
    setHoliday,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <HolidayForm
                        holidayDataForm={holiday}
                        setHolidayDataForm={setHoliday}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default HolidayFormContainer;
