import React from 'react';
import UserForm from './forms/UserForm';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { Bank } from '../../../app/models/Bank';
import { Provision } from '../../../app/models/Provision';
import { Afp } from '../../../app/models/Afp';
import { UserDataForm } from '../../../app/models/User';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Group } from '../../../app/models/Group';
import { JobTitleDataForm } from '../../../app/models/JobTitle';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    userForm: UserDataForm;
    setUserForm: (user: UserDataForm) => void;
    afps: Afp[];
    provisions: Provision[];
    banks: Bank[];
    jobTitles?: JobTitleDataForm[];
    groups: Group[];
    bankAccountTypes: BankAccountType[];
    errorFields?: any;
}

const UserFormContainer = ({
    fetching,
    action,
    cancel,
    userForm,
    setUserForm,
    afps,
    provisions,
    banks,
    jobTitles,
    groups,
    bankAccountTypes,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <UserForm
                        userForm={userForm}
                        setUserForm={setUserForm}
                        afps={afps}
                        groups={groups}
                        jobTitles={jobTitles}
                        provisions={provisions}
                        banks={banks}
                        bankAccountTypes={bankAccountTypes}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default UserFormContainer;
