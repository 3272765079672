import React, { useContext, useEffect, useRef, useState } from 'react';
import { Product } from '../../../app/models/Product';
import { Place } from '../../../app/models/Place';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import { Service } from '../../../app/models/Service';
import useTransportationQuoteDetailService from '../../../app/services/hooks/useTransportationQuoteDetailService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import {
    TransportationQuoteDetailDataForm,
    ActionResquest,
    defaultTransportationQuoteDetailDataForm,
    QuoteDataForm
} from '../../../app/models/Quote';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import QuoteDetailForm from './forms/QuoteDetailForm';
import { RouteSegment } from '../../../app/models/RouteSegment';
import { QuoteRouteSegment } from '../../../app/models/QuoteRouteSegment';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    quoteId: number;
    currencyId: number | string;
    setQuoteRouteSegmentTable: (quoteRouteSegment: QuoteRouteSegment[]) => void;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    quoteForm: QuoteDataForm;
    setQuoteForm: (quoteForm: QuoteDataForm) => void;
    reloadQuoteRouteSegmentTable: () => void;
}

const QuoteDetailCreate = ({
    quoteId,
    currencyId,
    onSaved,
    onCancel,
    onError,
    quoteForm,
    setQuoteForm,
    setQuoteRouteSegmentTable,
    reloadQuoteRouteSegmentTable
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        createTransportationQuoteDetail,
        storeTransportationQuoteDetail,
        getQuoteRouteSegmentsToQuoteDetail,
        fetchingCreateTransportationQuoteDetail,
        fetchingStoreTransportationQuoteDetail
    } = useTransportationQuoteDetailService();

    const { fetchingShowRsToTransportationQuoteDetail, showRsToTransportationQuoteDetail } =
        useQuoteRouteSegmentService();
    const [errorFields, setErrorFields] = useState<any>();

    const [quoteDetailForm, setQuoteDetailForm] = useState<TransportationQuoteDetailDataForm>({
        ...defaultTransportationQuoteDetailDataForm,
        ['quote_id']: quoteId,
        ['quantity']: 0,
        ['route_segment_id']: undefined,
        ['agreed_distance']: 0,
        ['service_id']: 0,
        ['product_id']: 0,
        ['first_dispatch_date']: '',
        ['description']: ''
    });

    const [products, setProducts] = useState<Product[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [origins, setOrigins] = useState<Place[]>([]);
    const [destinies, setDestinies] = useState<Place[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [routeSegments, setRouteSegments] = useState<RouteSegment[]>([]);
    const [quoteRouteSegments, setQuoteRouteSegments] = useState<QuoteRouteSegment[]>([]);
    const [action, setAction] = useState<ActionResquest>({});
    const routeSegmentIdSelected = useRef<number | undefined>(0);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createTransportationQuoteDetail({
            onSuccess: (response: ServiceResponse) => {
                setProducts(response.data.products);
                setQuantityUnits(response.data.quantity_units);
                setCurrencies(response.data.currencies);
                setOrigins(response.data.origins);
                setDestinies(response.data.destinies);
                setServices(response.data.services);
                setRouteSegments(response.data.route_segments);
                setAction(response.data.actions);

                setQuoteDetailForm({
                    ...quoteDetailForm,
                    action_to_do: response.data.actions.first_time
                });
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {
        if (services.length) {
            setQuoteDetailForm({ ...quoteDetailForm, service_id: services[0].id });
        }
    }, [services]);

    useEffect(() => {
        if (quoteDetailForm.route_segment_id == undefined) {
            routeSegmentIdSelected.current = undefined;
        }

        if (
            quoteDetailForm.route_segment_id &&
            quoteDetailForm.route_segment_id != routeSegmentIdSelected.current
        ) {
            showRsToTransportationQuoteDetail(0, quoteDetailForm.route_segment_id, {
                onSuccess: (response: ServiceResponse) => {
                    setQuoteRouteSegments([]);
                    setErrorFields(undefined);
                    setQuoteDetailForm({
                        ...quoteDetailForm,
                        ...defaultTransportationQuoteDetailDataForm,
                        distance: response.data.route_segment.distance,
                        rate: response.data.route_segment.rate,
                        currency_id: response.data.route_segment.currency_id,
                        quantity_unit_id: response.data.route_segment.unit_id,
                        rate_runner: response.data.route_segment.rate_runner,
                        currency_rate_runner_id:
                            response.data.route_segment.currency_rate_runner_id,
                        quantity_unit_rate_runner_id:
                            response.data.route_segment.quantity_unit_rate_runner_id,
                        external_rate: response.data.route_segment.external_rate,
                        currency_external_rate_id:
                            response.data.route_segment.currency_external_rate_id,
                        unit_external_rate_id: response.data.route_segment.unit_external_rate_id,
                        action_to_do: action.first_time
                    });
                },
                onError: (response: ServiceResponse) => {
                    setQuoteDetailForm({
                        ...quoteDetailForm,
                        route_segment_id: routeSegmentIdSelected.current
                    });
                }
            });
        }
    }, [quoteDetailForm.route_segment_id]);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando detalle Oferta...');

        storeTransportationQuoteDetail(quoteDetailForm, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                if (response.data.continue) {
                    console.log('continuar');
                    questionAction(response.message);

                    return;
                }
                reloadQuoteRouteSegmentTable();
                setQuoteForm({ ...quoteForm, ...response.data.quote_date });
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const questionAction = (message: string) => {
        useReactConfirmAlert().requestConfirmationMulti({
            title: '¿Qué desea hacer?',
            message: message,
            buttons: {
                confirmButton: {
                    label: 'Agregar opción',
                    onClick: () => {
                        quoteDetailForm.action_to_do = action.add;
                        setQuoteDetailForm({ ...quoteDetailForm });
                        store();
                    }
                },
                updateButton: {
                    label: 'Actualizar opción',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Consultando opciones de ruta...');

                        getQuoteRouteSegmentsToQuoteDetail(
                            quoteDetailForm.quote_id!,
                            quoteDetailForm.route_segment_id!,
                            {
                                onSuccess: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();
                                    setQuoteRouteSegments(response.data.quote_route_segments);
                                    setQuoteDetailForm({
                                        ...quoteDetailForm,
                                        action_to_do: action.update
                                    });
                                },
                                onError: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();
                                    toast.error(response.message);
                                }
                            }
                        );
                    }
                },

                cancelButton: {
                    label: 'cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'No se ha ejecutado ninguna acción.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateTransportationQuoteDetail ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <QuoteDetailForm
                        products={products}
                        quantityUnits={quantityUnits}
                        currencies={currencies}
                        origins={origins}
                        destinies={destinies}
                        services={services}
                        routeSegments={routeSegments}
                        quoteDetailForm={quoteDetailForm}
                        setQuoteDetailForm={setQuoteDetailForm}
                        errorFields={errorFields}
                        quoteRouteSegments={quoteRouteSegments}
                        fetching={fetchingShowRsToTransportationQuoteDetail}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreTransportationQuoteDetail}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={store}
                        locked={fetchingStoreTransportationQuoteDetail}
                    />
                </div>
            </div>
        </>
    );
};

export default QuoteDetailCreate;
