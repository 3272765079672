import React, { useEffect, useState } from 'react';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { GuideHistoryDataForm, GuideHistoryFilter } from '../../../../app/models/GuideHistory';
import { momentParseDate } from '../../../../helpers';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import useGuideService from '../../../../app/services/hooks/useGuideService';
import { toast } from 'react-toastify';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';

interface Props {
    guideHistories: GuideHistoryDataForm[];
    totalRows: number;
    filter: GuideHistoryFilter;
    setFilter: (filter: GuideHistoryFilter) => void;
    edit?: (id: number) => void;
    loading?: boolean;
}

const GuideHistoryDataTable = ({
    guideHistories,
    totalRows,
    filter,
    setFilter,
    edit,
    loading = false
}: Props) => {
    const columns = [
        {
            name: 'Fecha',
            selector: (row: any) => row?.created_at,
            sortable: true,
            sortField: 'created_at',
            width: '155px',
            cell: (row: GuideHistoryDataForm) => (
                <span className="">{momentParseDate(row?.created_at, 'YYYY-MM-DD HH:mm')}</span>
            )
        },
        {
            name: 'Acción',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            cell: (row: GuideHistoryDataForm) => <span className="">{row.status}</span>
        },
        {
            name: 'Comentario',
            selector: (row: any) => row.comment,
            sortable: true,
            sortField: 'comment',
            cell: (row: GuideHistoryDataForm) => <span className="">{row.comment}</span>
        },
        {
            name: 'Creado por',
            selector: (row: any) => row.created_user,
            sortable: true,
            sortField: 'created_user',
            cell: (row: GuideHistoryDataForm) => 
            <span
                className=""
                dangerouslySetInnerHTML={{ __html: row.created_user ? row.created_user : '' }}
            ></span>
        },
        // {
        //     name: 'Acciones',
        //     selector: (row: any) => row?.id,
        //     sortable: false,
        //     width: '150px',
        //     cell: (row: GuideHistoryDataForm, index: any, column: any) => {
        //         return (
        //             <div className="">
        //                 {edit && !row.is_approved && (
        //                     <ButtonTableAction
        //                         callbackFunction={() => edit(row?.id ?? -1)}
        //                         classIcon={'mdi mdi-pencil'}
        //                         colorIcon={'text-warning'}
        //                         errorMessage={'No se puede editar este registro.'}
        //                         title={'Editar'}
        //                     />
        //                 )}
        //             </div>
        //         );
        //     }
        // }
    ];

    return (
        <DefaultDataTable
            columns={columns}
            data={guideHistories}
            progressPending={loading}
            isMobileStacked={true}
            paginationTotalRows={totalRows}
            filter={filter}
            setFilter={setFilter}
        />
    );
};

export default GuideHistoryDataTable;
