import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import { Society } from '../../../app/models/Society';
import { Provider } from '../../../app/models/Provider';
import { useHistory } from 'react-router-dom';
import { TruckDataForm, defaultTruckDataForm } from '../../../app/models/Truck';
import useTruckService from '../../../app/services/hooks/useTruckService';
import { Brand } from '../../../app/models/Brand';
import { VehicleModel } from '../../../app/models/VehicleModel';
import TruckForm from './forms/TruckForm';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { Status } from '../../../app/models/Status';
import { DocumentSet } from '../../../app/models/DocumentSet';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TruckCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const [societies, setSocieties] = useState<Society[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [vehicleModels, setVehicleModels] = useState<VehicleModel[]>([]);
    const [status, setStatus] = useState<Status[]>([]);
    const [documentSets, setDocumentSets] = useState<DocumentSet[]>([]);
    const [truck, setTruck] = useState<TruckDataForm>(defaultTruckDataForm);
    const { navigationPage } = useNavigationPage();

    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingCreateTruck, fetchingStoreTruck, createTruck, storeTruck } = useTruckService();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createTruck({
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setProviders(response.data.providers);
                setBrands(response.data.brands);
                setVehicleModels(response.data.vehicle_models);
                setStatus(response.data.status);
                setDocumentSets(response.data.document_sets);
                setTruck(defaultTruckDataForm);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {
        if (truck?.is_internal == true) {
            setTruck({
                ...truck,
                society_id: 0,
                provider_id: undefined,
                is_internal: true
            });
        } else if (truck?.is_internal == false) {
            setTruck({
                ...truck,
                provider_id: 0,
                society_id: undefined,
                is_internal: false
            });
        }
    }, [truck?.is_internal]);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando tracto...');
        storeTruck(truck, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                navigationPage('/trucks/' + response.data.truck_id + '/edit');
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateTruck ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <TruckForm
                        truckForm={truck}
                        setTruckForm={setTruck}
                        societies={societies}
                        providers={providers}
                        brands={brands}
                        vehicleModels={vehicleModels}
                        status={status}
                        documentSets={documentSets}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreTruck} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreTruck} />
                </div>
            </div>
        </>
    );
};

export default TruckCreate;
