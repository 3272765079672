import React, { useEffect } from 'react';

import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { TollExpenseHistoryDataForm } from '../../../../app/models/TollExpenseHistory';

interface TollExpenseHistoryFormProps {
    tollExpenseHistoryDataForm: TollExpenseHistoryDataForm;
    setTollExpenseHistoryDataForm: (tollExpenseHistoryDataForm: TollExpenseHistoryDataForm) => void;
    errorFields?: any;
}

const TollExpenseHistoryForm = ({
    tollExpenseHistoryDataForm,
    setTollExpenseHistoryDataForm,
    errorFields
}: TollExpenseHistoryFormProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setTollExpenseHistoryDataForm({ ...tollExpenseHistoryDataForm, [name]: value });
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Comentario</label>
                    <textarea
                        className={`form-control ${fieldHasError('comment')}`}
                        name="comment"
                        id="comment"
                        value={tollExpenseHistoryDataForm.comment}
                        onChange={handleChange}
                        placeholder="Ingrese un comentario"
                        onFocus={() => onFocusRemove('comment')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('comment')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TollExpenseHistoryForm;
