import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { UserPasswordDataForm, defaultUserPasswordDataForm } from '../../../app/models/User';
import useUserService from '../../../app/services/hooks/useUserService';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import UserPasswordForm from '../../Users/components/forms/UserPasswordForm';
import useAuthService from '../../../app/services/hooks/useAuthService';
import DefaultModal from '../../../components/default/DefaultModal';

interface Props {
    showingPasswordEdit: boolean;
    hidePasswordEdit: () => void;
    title?: string;
    backdrop?: boolean;
}

const PasswordProfile = ({
    showingPasswordEdit,
    hidePasswordEdit,
    title = 'Editar contraseña',
    backdrop = true
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingUpdateProfilePassword, updateProfilePassword } = useAuthService();

    const [user, setUser] = useState<UserPasswordDataForm>(defaultUserPasswordDataForm);

    const [errorFields, setErrorFields] = useState<any>();

    const updatePassword = () => {
        if (showLoading) showLoading('loading', 'Actualizando contraseña...');
        updateProfilePassword(user, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                hidePasswordEdit();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    return (
        <>
            <DefaultModal
                show={showingPasswordEdit}
                handleClose={backdrop ? hidePasswordEdit : null}
                title={title}
                backdrop={backdrop}
                showFooter={false}
            >
                <div className="row">
                    <div className="col-12">
                        <UserPasswordForm
                            userForm={user}
                            setUserForm={setUser}
                            errorFields={errorFields}
                            isProfile={true}
                        />
                    </div>
                </div>
                <hr />
                <div className="row justify-content-end">
                    <div className="col-auto">
                        <ButtonCancelForm
                            callbackCancel={backdrop ? hidePasswordEdit : () => ''}
                            locked={fetchingUpdateProfilePassword}
                        />
                    </div>
                    <div className="col-auto">
                        <ButtonSaveForm
                            callbackSave={updatePassword}
                            locked={fetchingUpdateProfilePassword}
                        />
                    </div>
                </div>
            </DefaultModal>
        </>
    );
};

export default PasswordProfile;
