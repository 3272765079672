import { Bank } from './Bank';
import { User } from './User';
import { DocumentSet } from './DocumentSet';

export interface Society {
    id?: number;
    rut: string;
    business_name: string;
    logo?: File;
    document_sets?: DocumentSet[];
    first_name_contact: string;
    last_name_contact: string;
    second_last_name_contact: string;
    email_contact: string;
    phone_code_contact: string;
    phone_contact: string;
    address: string;
    country: string;
    is_active: boolean;
    user_id?: number;
    user?: User;
}

export interface SocietyFilters {
    society_id?: number | null;
    rut?: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultSocietyDataForm: Society = {
    rut: '',
    business_name: '',
    first_name_contact: '',
    last_name_contact: '',
    second_last_name_contact: '',
    email_contact: '',
    phone_code_contact: '',
    phone_contact: '',
    address: '',
    country: '',
    document_sets: undefined,
    is_active: true
};

export interface SocietyDataForm {
    id?: number;
    rut: string;
    business_name: string;
    logo?: File;
    document_sets?: DocumentSet[];
    first_name_contact: string;
    last_name_contact: string;
    second_last_name_contact: string;
    email_contact: string;
    phone_code_contact: string;
    phone_contact: string;
    address: string;
    country: string;
    is_active: boolean;
    user_id?: number;
    user?: User;
}

export const defaultSocietyForm: SocietyDataForm = {
    rut: '',
    business_name: '',
    first_name_contact: '',
    last_name_contact: '',
    second_last_name_contact: '',
    email_contact: '',
    phone_code_contact: '',
    phone_contact: '',
    address: '',
    country: '',
    document_sets: undefined,
    is_active: true
};

export interface SocietyDocument {
    id?: number;
    name?: string;
    file?: File;
    issue_date?: string;
    expiration_date?: string;
    type?: string;
    document_id?: number;
    is_expire_date_indefinite: boolean;
    society_id: number;
}
