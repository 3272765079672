import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import {
    CheckingAccountDataForm,
    defaultCheckingAccountData
} from '../../../app/models/CheckingAccount';
import useCheckingAccountService from '../../../app/services/hooks/useCheckingAccount';
import { Bank } from '../../../app/models/Bank';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { Carrier } from '../../../app/models/Carrier';
import { Currency } from '../../../app/models/Currency';
import CheckingAccountForm from './forms/CheckingAccountForm';
import moment from 'moment';
import useExpenseService from '../../../app/services/hooks/useExpenseService';
import useBankAccountService from '../../../app/services/hooks/useBankAccount';
import { Expense } from '../../../app/models/Expense';
import { TransferReason } from '../../../app/models/TransferReason';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CheckingAccountCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [errorGetData, setErrorGetData] = useState('');
    const [checkingAccount, setCheckingAccount] = useState<CheckingAccountDataForm>({
        issue_date: moment().format('YYYY-MM-DD'),
        worker_id: 0,
        currency_id: 0,
        description: ''
    });
    const [banks, setBanks] = useState<Bank[]>([]);
    const [transferReasons, setTransferReasons] = useState<TransferReason[]>([]);
    const [bankAccountTypes, setBankAccountTypes] = useState<BankAccountType[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [expenses, setExpenses] = useState<Expense[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
    const carrierIdSelected = useRef<number | undefined>(0);
    const transferReasonIdSelected = useRef<number | undefined>(0);

    const {
        fetchingCreateCheckingAccount,
        fetchingStoreCheckingAccount,
        createCheckingAccount,
        storeCheckingAccount
    } = useCheckingAccountService();

    const { getExpensesByCarrier, fetchingGetExpensesByCarrier } = useExpenseService();
    const { getBankAccountByReason, fetchingGetBankAccountByReason } = useBankAccountService();

    useEffect(() => {
        create();
    }, []);

    useEffect(() => {
        if (checkingAccount.worker_id == undefined) {
            carrierIdSelected.current = undefined;
        }
        if (checkingAccount.worker_id && checkingAccount.worker_id != carrierIdSelected.current) {
            getExpensesByCarrier(checkingAccount.worker_id, {
                onSuccess: (response: ServiceResponse) => {
                    carrierIdSelected.current = checkingAccount.worker_id ?? 0;
                    setErrorFields(undefined);
                    setCheckingAccount({
                        ...checkingAccount,
                        ...defaultCheckingAccountData
                        // ...response.data.carrier
                    });
                    setExpenses(response.data.expenses);
                },
                onError: (response: ServiceResponse) => {
                    setCheckingAccount({
                        ...checkingAccount,
                        worker_id: carrierIdSelected.current
                    });
                }
            });
        }
    }, [checkingAccount.worker_id]);

    useEffect(() => {
        if (checkingAccount.transfer_reason_id == undefined) {
            transferReasonIdSelected.current = undefined;
        }
        if (
            checkingAccount.transfer_reason_id &&
            checkingAccount.transfer_reason_id != transferReasonIdSelected.current &&
            checkingAccount.worker_id
        ) {
            getBankAccountByReason(checkingAccount.transfer_reason_id, checkingAccount.worker_id, {
                onSuccess: (response: ServiceResponse) => {
                    transferReasonIdSelected.current = checkingAccount.transfer_reason_id ?? 0;
                    setErrorFields(undefined);
                    setCheckingAccount({
                        ...checkingAccount,
                        account_number: response.data.bank_account.account_number,
                        bank_id: response.data.bank_account.bank_id,
                        account_type_id: response.data.bank_account.account_type_id
                    });
                },
                onError: (response: ServiceResponse) => {
                    setCheckingAccount({
                        ...checkingAccount,
                        transfer_reason_id: transferReasonIdSelected.current
                    });
                }
            });
        }
    }, [checkingAccount.transfer_reason_id]);

    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createCheckingAccount({
            onSuccess: (response: ServiceResponse) => {
                setBanks(response.data.banks);
                setBankAccountTypes(response.data.bank_account_types);
                setCarriers(response.data.carriers);
                setCurrencies(response.data.currencies);
                setExpenses(response.data.expenses);
                setTransferReasons(response.data.transfer_reasons);
                const currencies =
                    response.data.currencies.find(
                        (currency: any) => String(currency.name).toUpperCase() === 'CLP'
                    )?.id ?? 0;
                setCheckingAccount({
                    ...checkingAccount,
                    currency_id: currencies
                });
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando cuenta...');
        storeCheckingAccount(checkingAccount, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <CheckingAccountForm
                        checkingAccountDataForm={checkingAccount}
                        setCheckingAccountDataForm={setCheckingAccount}
                        banks={banks}
                        transferReasons={transferReasons}
                        bankAccountTypes={bankAccountTypes}
                        carriers={carriers}
                        currencies={currencies}
                        expensesData={expenses}
                        setExpenses={setExpenses}
                        errorFields={errorFields}
                        loading={fetchingGetExpensesByCarrier}
                        loadingBankAccount={fetchingGetBankAccountByReason}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreCheckingAccount}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreCheckingAccount} />
                </div>
            </div>
        </>
    );
};

export default CheckingAccountCreate;
