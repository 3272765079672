import { EndPoints } from '../EndPoints';
import {
    SplitDataForm,
    TransportationOrderItemDataForm,
    TransportationOrderItemsFilters
} from '../../models/TransportationOrderItem';
import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';

const useTransportationOrderItemService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [
        fetchingGetTransportationOrderItemsByOrder,
        setFetchingGetTransportationOrderItemsByOrder
    ] = useState(false);
    const [fetchingStoreTransportationOrderItem, setFetchingStoreTransportationOrderItem] =
        useState(false);
    const [fetchingEditTransportationOrderItem, setFetchingEditTransportationOrderItem] =
        useState(false);
    const [fetchingUpdateTransportationOrderItem, setFetchingUpdateTransportationOrderItem] =
        useState(false);
    const [fetchingDeleteTransportationOrderItem, setFetchingDeleteTransportationOrderItem] =
        useState(false);
    const [fetchingCreateTransportationOrderItem, setFetchingCreateTransportationOrderItem] =
        useState(false);
    const [fetchingShowTransportationOrderItem, setFetchingShowTransportationOrderItem] =
        useState(false);
    const [fetchingSplitTransportationOrderItem, setFetchingSplitTransportationOrderItem] =
        useState(false);
    const [
        fetchingChangeStatusTransportationOrderItem,
        setFetchingChangeStatusTransportationOrderItem
    ] = useState(false);
    const [fetchingGetRatesTransportationOrderItem, setFetchingGetRatesTransportationOrderItem] =
        useState(false);
    const [
        fetchingUpdateRatesTransportationOrderItem,
        setFetchingUpdateRatesTransportationOrderItem
    ] = useState(false);

    const [
        fetchingHasAllFinishedTransportationOrderItems,
        setFetchingHasAllFinishedTransportationOrderItems
    ] = useState(false);

    const getAllTransportationOrderItemsByOrder = (
        transportationOrderFilters: TransportationOrderItemsFilters,
        transportationOrderId: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(transportationOrderFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.TRANSPORTATION_ORDERS.GET_TRANSPORTATION_ORDER_ITEMS_BY_ORDER}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':id', transportationOrderId.toString()),
            setFetching: setFetchingGetTransportationOrderItemsByOrder
        });
    };

    const createTransportationOrderItem = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.CREATE_TRANSPORTATION_ORDER_ITEM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingCreateTransportationOrderItem
        });
    };

    const storeTransportationOrderItem = (
        transportationOrderItemDataForm: TransportationOrderItemDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.STORE_TRANSPORTATION_ORDER_ITEM,
            body: transportationOrderItemDataForm,
            setFetching: setFetchingStoreTransportationOrderItem
        });
    };

    const editTransportationOrderItem = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.EDIT_TRANSPORTATION_ORDER_ITEM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingEditTransportationOrderItem
        });
    };

    const showTransportationOrderItem = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.SHOW_TRANSPORTATION_ORDER_ITEM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingShowTransportationOrderItem
        });
    };

    const updateTransportationOrderItem = (
        id: number,
        transportationOrderItemDataForm: TransportationOrderItemDataForm,
        events: ServiceEvents = {}
    ) => {
        console.log(transportationOrderItemDataForm);
        doPut({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.UPDATE_TRANSPORTATION_ORDER_ITEM.replace(
                ':id',
                id.toString()
            ),
            body: transportationOrderItemDataForm,
            setFetching: setFetchingUpdateTransportationOrderItem
        });
    };

    const deleteTransportationOrderItem = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.DELETE_TRANSPORTATION_ORDER_ITEM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingDeleteTransportationOrderItem
        });
    };

    const splitTransportationOrderItem = (
        splitDataForm: SplitDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.SPLIT_TRANSPORTATION_ORDER_ITEM,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: splitDataForm,
            requestType: 'json',
            setFetching: setFetchingSplitTransportationOrderItem
        });
    };

    const changeStatusTransportationOrderItem = (
        id: number,
        status: string,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.CHANGE_STATUS_TRANSPORTATION_ORDER_ITEM.replace(
                ':id',
                id.toString()
            ),
            body: { status: status },
            setFetching: setFetchingChangeStatusTransportationOrderItem
        });
    };

    const getRatesTransportationOrderItem = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.GET_RATES_TRANSPORTATION_ORDER_ITEM.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingGetRatesTransportationOrderItem
        });
    };

    const updateRatesTransportationOrderItem = (
        id: number,
        transportationOrderItemRatesDataForm: TransportationOrderItemDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.UPDATE_RATES_TRANSPORTATION_ORDER_ITEM.replace(
                ':id',
                id.toString()
            ),
            body: transportationOrderItemRatesDataForm,
            setFetching: setFetchingUpdateRatesTransportationOrderItem
        });
    };

    const HasAllFinishedTransportationOrderItems = (
        id: number,
        status: string,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.TRANSPORTATION_ORDERS.HAS_ALL_FINISHED_TRANSPORTATION_ORDER_ITEMS.replace(
                ':id',
                id.toString()
            ).replace(':status_detail_toi', status.toString()),
            setFetching: setFetchingHasAllFinishedTransportationOrderItems
        });
    };

    return {
        getAllTransportationOrderItemsByOrder,
        createTransportationOrderItem,
        storeTransportationOrderItem,
        showTransportationOrderItem,
        editTransportationOrderItem,
        updateTransportationOrderItem,
        deleteTransportationOrderItem,
        splitTransportationOrderItem,
        changeStatusTransportationOrderItem,
        getRatesTransportationOrderItem,
        updateRatesTransportationOrderItem,
        HasAllFinishedTransportationOrderItems,
        fetchingChangeStatusTransportationOrderItem,
        fetchingShowTransportationOrderItem,
        fetchingGetTransportationOrderItemsByOrder,
        fetchingStoreTransportationOrderItem,
        fetchingEditTransportationOrderItem,
        fetchingUpdateTransportationOrderItem,
        fetchingDeleteTransportationOrderItem,
        fetchingCreateTransportationOrderItem,
        fetchingSplitTransportationOrderItem,
        fetchingGetRatesTransportationOrderItem,
        fetchingUpdateRatesTransportationOrderItem,
        fetchingHasAllFinishedTransportationOrderItems
    };
};

export default useTransportationOrderItemService;
