import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="row">
                <div className="container-fluid">
                    <div className="col-sm-6">2023 © Pegasus.</div>
                    <div className="col-sm-6"></div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
