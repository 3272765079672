import { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Unit, UnitEquivalenceDataForm } from '../../../app/models/Unit';
import useUnitEquivalenceService from '../../../app/services/hooks/useUnitEquivalenceService';
import UnitEquivalenceForm from './forms/UnitEquivalenceForm';
import LazyLoading from '../../../components/LazyLoading';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const UnitEquivalenceCreate = ({ onSaved, onCancel, onError }: Props) => {
    const {
        fetchingStoreUnitEquivalence,
        storeUnitEquivalence,
        fetchingCreateUnitEquivalence,
        createUnitEquivalence
    } = useUnitEquivalenceService();
    const { showLoading, hideLoading } = useContext(AppContext);
    const [errorGetData, setErrorGetData] = useState('');
    const [errorFields, setErrorFields] = useState<any>();
    const [unit, setUnit] = useState<Unit[]>([]);
    const [unitEquivalence, setUnitEquivalence] = useState<UnitEquivalenceDataForm>({
        from_unit_quantity: 1,
        to_unit_quantity: 0
    });

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createUnitEquivalence({
            onSuccess: (response: ServiceResponse) => {
                setUnit(response.data.units);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando equivalencia...');
        storeUnitEquivalence(unitEquivalence, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return fetchingCreateUnitEquivalence ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <UnitEquivalenceForm
                        unitEquivalenceDataForm={unitEquivalence}
                        setUnitEquivalenceDataForm={setUnitEquivalence}
                        units={unit}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreUnitEquivalence}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreUnitEquivalence} />
                </div>
            </div>
        </>
    );
};

export default UnitEquivalenceCreate;
