import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

import { ServiceEvents } from '../shared/interfaces';
import { BonusRateDataForm } from '../../models/BonusRate'

const useBonusRateService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBonusRatesByBonus, setFetchingGetBonusRatesByBonus] = useState(false);
    const [fetchingStoreBonusRate, setFetchingStoreBonusRate] = useState(false);
    const [fetchingEditBonusRate, setFetchingEditBonusRate] = useState(false);
    const [fetchingUpdateBonusRate, setFetchingUpdateBonusRate] = useState(false);
    const [fetchingDeleteBonusRate, setFetchingDeleteBonusRate] = useState(false);
    const [fetchingCreateBonusRate, setFetchingCreateBonusRate] = useState(false);

    const getAllBonusRatesByBonus = (bonus_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BONUS_RATES.GET_BONUS_RATES_BY_BONUS.replace(
                ':bonus_id',
                bonus_id.toString()
            ),
            setFetching: setFetchingGetBonusRatesByBonus
        });
    };

    const createBonusRate = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BONUS_RATES.CREATE_BONUS_RATE,
            setFetching: setFetchingCreateBonusRate
        });
    };

    const storeBonusRate = (BonusRateDataForm: BonusRateDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BONUS_RATES.STORE_BONUS_RATE,
            body: BonusRateDataForm,
            setFetching: setFetchingStoreBonusRate
        });
    };

    const editBonusRate = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BONUS_RATES.EDIT_BONUS_RATE.replace(':id', id.toString()),
            setFetching: setFetchingEditBonusRate
        });
    };

    const updateBonusRate = (
        id: number,
        BonusRateDataForm: BonusRateDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.BONUS_RATES.UPDATE_BONUS_RATE.replace(':id', id.toString()),
            body: BonusRateDataForm,
            setFetching: setFetchingUpdateBonusRate
        });
    };

    const deleteBonusRate = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.BONUS_RATES.DELETE_BONUS_RATE.replace(':id', id.toString()),
            setFetching: setFetchingDeleteBonusRate
        });
    };

    return {
        getAllBonusRatesByBonus,
        createBonusRate,
        storeBonusRate,
        editBonusRate,
        updateBonusRate,
        deleteBonusRate,
        fetchingGetBonusRatesByBonus,
        fetchingStoreBonusRate,
        fetchingEditBonusRate,
        fetchingUpdateBonusRate,
        fetchingDeleteBonusRate,
        fetchingCreateBonusRate,
    };
};

export default useBonusRateService;
