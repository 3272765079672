import React from 'react';
import { TravelExpenseDataForm } from '../../../app/models/TravelExpense';
import TravelExpenseForm from './forms/TravelExpenseForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { EventType } from '../../../app/models/EventType';
import { Currency } from '../../../app/models/Currency';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    travelExpense: TravelExpenseDataForm;
    setTravelExpense: (travelExpense: TravelExpenseDataForm) => void;
    errorFields?: any;
    eventTypes: EventType[];
    currencies: Currency[];
}

const TravelExpensesFormContainer = ({
    fetching,
    action,
    cancel,
    travelExpense,
    setTravelExpense,
    eventTypes,
    currencies,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <TravelExpenseForm
                        travelExpenseDataForm={travelExpense}
                        setTravelExpenseDataForm={setTravelExpense}
                        currencies={currencies}
                        errorFields={errorFields}
                        eventTypes={eventTypes}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default TravelExpensesFormContainer;
