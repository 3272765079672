import React, { useContext, useState, useEffect, useRef } from 'react';
import { formatMoney, formatNumber } from '../../../../utils/utils';
import { momentParseDate } from '../../../../helpers';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import {
    DispatchProgramItem,
    DispatchProgramItemFilters,
    MassiveStatusForm,
    dispatchProgramItemStatuses
} from '../../../../app/models/DispatchProgramItem';

import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { AppContext } from '../../../../contexts/AppContext';
import useDispatchProgramItemService from '../../../../app/services/hooks/useDispatchProgramItemService';
import ChangeStatusRows from '../../../../components/ChangeStatusRows';
import { StatusRows } from '../../../../app/shared/interfaces';
import { Link } from 'react-router-dom';
import {
    TransportOrderDataForm,
    TransportOrderFilters
} from '../../../../app/models/TransportOrder';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import TransportOrderReadyToDispatchProgramFilter from './TransportOrderReadyToDispatchProgramFilter';

interface Props {
    transportOrders: TransportOrderDataForm[];
    totalRows: number;
    bulkTransportOrderForm: MassiveStatusForm;
    setbulkTransportOrderForm: (bulkTransportOrderForm: MassiveStatusForm) => void;
    filter: TransportOrderFilters;
    setFilter: (filter: TransportOrderFilters) => void;
    loading: boolean;
    loadTransportOrderData: (massiveChange: boolean) => void;
    folios: any[];
}

const TransportOrderReadyToDispatchProgramDataTable = ({
    transportOrders,
    totalRows = 0,
    bulkTransportOrderForm,
    setbulkTransportOrderForm,
    filter,
    setFilter,
    loading,
    loadTransportOrderData,
    folios
}: Props) => {
    const columns = [
        {
            name: 'Orden de transporte',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: any, index: any, column: any) => (
                <Link to={'/transport-orders/' + row.id + '/edit'}>#{row.id}</Link>
            )
        },
        {
            name: 'Conductor',
            selector: (row: any) => row?.worker,
            sortable: true,
            sortField: 'id',
            cell: (row: any, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{
                        __html:
                            row.worker && row.worker.user
                                ? `
                            ${row.worker.user.rut} </br> 
                            ${row.worker.user.first_name} ${row.worker.user.last_name} ${row.worker.user.second_last_name}`
                                : ''
                    }}
                ></span>
            )
        },
        {
            name: 'Folio Guia',
            selector: (row: any) => row?.folio,
            sortable: true,
            sortField: 'folio',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.guide?.folio}</span>
            )
        },
        {
            name: 'Cantidad guia',
            selector: (row: any) => row?.quantity,
            sortable: true,
            sortField: 'quantity',
            cell: (row: any, index: any, column: any) => (
                <span className="">
                    {row.guide ? formatNumber(row.guide?.quantity) + ' ' + row.guide?.unit : ''}
                </span>
            )
        },
        {
            name: 'Fecha guia despacho',
            selector: (row: any) => row?.issue_date_guide,
            sortable: true,
            sortField: 'issue_date_guide',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.guide ? momentParseDate(row.guide?.issue_date) : ''}</span>
            )
        }
    ];

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [rows, setRows] = useState<any[]>([]);
    const [toggleCleared, setToggleCleared] = useState<boolean>(false);
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [transportOrderfilter, setTransportorderFilter] = useState<TransportOrderFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });
    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([1]);
    const [renderSelectedRows, setRenderSelectedRows] = useState<boolean>(false);
    // const selectedRowIds = useRef<number[]>([1, 129]);

    const contextMessage = ({
        selectedCount,
        allSelected
    }: {
        selectedCount: number;
        allSelected: boolean;
    }) => {
        return {
            singular: 'fila seleccionada',
            plural: 'filas seleccionadas'
        };
    };

    const resetContextActions = () => {
        setToggleCleared(!toggleCleared);
        setRows([]);
    };

    const contextActions = React.useMemo(() => {
        return (
            <button className="btn btn-sm btn-info" onClick={() => loadTransportOrderData(false)}>
                <i className="fa fa-file-archive" /> Ingreso ordenes de transporte
            </button>
        );
    }, [transportOrders, rows, toggleCleared]);

    const handleRowSelected = React.useCallback(
        ({ allSelected, selectedCount, selectedRows }: any) => {
            setRows(selectedRows);
            setbulkTransportOrderForm({
                ...bulkTransportOrderForm,
                all_selected: false,
                selected_count: selectedCount,
                rows_ids: selectedRows.map((row: any) => row.id)
            });
        },
        []
    );

    return (
        <>
            <div className="row">
                <div className="col-12 mt-md-0 mt-3">
                    <div className="row">
                        <div className="col-auto">
                            <TransportOrderReadyToDispatchProgramFilter
                                filter={filter}
                                setFilter={setFilter}
                                folios={folios}
                            />
                        </div>
                        {transportOrders?.length > 0 && (
                            <>
                                {/*<div className="col-auto" style={{ paddingTop: 22 }}>*/}

                                {/*    <button*/}
                                {/*        className="btn btn-success btn-sm float-end"*/}
                                {/*        onClick={() => loadTransportOrderData(true)}*/}
                                {/*    >*/}
                                {/*        <i className="fa fa-file-archive" /> Ingreso masivo ordenes de transporte*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </>
                        )}
                    </div>
                </div>

                <div className="col-12">
                    <DefaultDataTable
                        title=" "
                        columns={columns}
                        data={transportOrders}
                        progressPending={loading}
                        paginationTotalRows={totalRows}
                        filter={filter}
                        setFilter={setFilter}
                        selectableRows={true}
                        // selectableRowSelected={(row: any) => {
                        //     const isSelected = selectedRowIds.includes(row.id);
                        //     // console.log(selectedRowIds)
                        //     // console.log(`Row ID: ${row.id}, Is Selected: ${isSelected}`);
                        //     return isSelected;
                        // }}
                        onSelectedRowsChange={handleRowSelected}
                        selectableRowsHighlight={true}
                        clearSelectedRows={toggleCleared}
                        contextActions={contextActions}
                        resetContextActions={resetContextActions}
                        contextMessage={contextMessage({
                            selectedCount: bulkTransportOrderForm.selected_count ?? 0,
                            allSelected: bulkTransportOrderForm.all_selected!
                        })}
                        // selectableRowsNoSelectAll={true}
                    />
                </div>
            </div>
        </>
    );
};

export default TransportOrderReadyToDispatchProgramDataTable;
