import React, { useEffect, useState } from 'react';
import { ShiftDataForm, ShiftDays } from '../../../../app/models/Shift';
import { EventType } from '../../../../app/models/EventType';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import ButtonCreate from '../../../../components/buttons/ButtonCreate';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';

type Props = {
    shiftDataForm: ShiftDataForm;
    setShiftDataForm: (shiftDataForm: ShiftDataForm) => void;
    eventTypes: EventType[];
    workdays: ShiftDays[];
    errorFields?: any;
};

const ShiftForm = ({
    shiftDataForm,
    setShiftDataForm,
    errorFields,
    eventTypes,
    workdays
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [shiftDays, setShiftDays] = useState<ShiftDays[]>(() => {
        return workdays.map((day) => ({
            ...day,
            event_type: eventTypes.find((eventType) => eventType.id === day.event_type_id)
        }));
    });

    useEffect(() => {
        setShiftDays(workdays || []);
    }, []);

    useEffect(() => {
        setShiftDataForm({ ...shiftDataForm, shift_days: shiftDays });
    }, [shiftDays]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setShiftDataForm({ ...shiftDataForm, [name]: value });
    };

    const handleChangeCount = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
        index: number
    ) => {
        const { name, value } = e.target;
        const updatedShiftDays = [...shiftDays];
        updatedShiftDays[index] = { ...updatedShiftDays[index], [name]: value };
        setShiftDays(updatedShiftDays);
        setShiftDataForm({ ...shiftDataForm, shift_days: updatedShiftDays });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null, index: number) => {
        const selectedEventType = eventTypes.find((eventType) => eventType.id === value);
        const updatedShiftDays = shiftDays.map((row, i) => {
            if (i === index) {
                return { ...row, event_type: selectedEventType };
            }
            return row;
        });
        setShiftDays(updatedShiftDays);
        setShiftDataForm({ ...shiftDataForm, shift_days: updatedShiftDays });
    };

    const addInputRow = () => {
        const newShiftDay: ShiftDays = {
            event_type: undefined
        };
        setShiftDays([...shiftDays, newShiftDay]);
    };

    const deleteInputRow = (index: number) => {
        const updatedRows = shiftDays.filter((row, i) => i !== index);
        setShiftDays(updatedRows);
        setShiftDataForm({ ...shiftDataForm, shift_days: updatedRows });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Nombre</label>
                <div className="col-md-9">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={shiftDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre de la jornada"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {errorFields?.name}
                    </div>
                </div>
            </div>

            <div className="mt-3 row justify-content-center">
                <div className="col-md-5">
                    <label className="col-form-label">Cantidad de días</label>
                </div>
                <div className="col-md-5">
                    <label className="col-form-label">Tipo de evento</label>
                </div>
                <div className="col-md-1 mt-1">
                    <ButtonCreate callbackCreate={addInputRow} title="" />
                </div>
            </div>
            {shiftDays.map((row, index) => (
                <div key={index} className="mt-2 row">
                    <div className="col-md-5">
                        <input
                            name="number_of_days"
                            id={`workdays-${index}`}
                            type="number"
                            className={`form-control ${fieldHasError('number_of_days')}`}
                            value={shiftDataForm?.shift_days?.[index]?.number_of_days}
                            onChange={(e) => handleChangeCount(e, index)}
                            onFocus={() => onFocusRemove('number_of_days')}
                            placeholder="Cantidad de días"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {errorFields?.[index]?.number_of_days}
                        </div>
                    </div>
                    <div className="col-md-5">
                        <SelectTwo
                            name="workdays_event_type_id"
                            id={`workdays_event_type_id_${index}`}
                            inputValue={shiftDataForm?.shift_days?.[index]?.event_type?.id || null}
                            options={SelectTwoMapperOptions(eventTypes)}
                            hasError={fieldHasError('workdays_event_type_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('event_type', value?.value, index)
                            }
                            placeholder={'Seleccione'}
                            onFocus={() => onFocusRemove('workdays_event_type_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {errorFields?.[index]?.event_type}
                        </div>
                    </div>
                    <div className="col-md-1 ms-3 mt-1">
                        <ButtonTableAction
                            callbackFunction={() => deleteInputRow(index)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    </div>
                </div>
            ))}
        </>
    );
};

export default ShiftForm;
