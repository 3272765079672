import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';

const useCalculatedFieldService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingCreateCalculatedFiel, setFetchingCreateCalculatedFiel] = useState(false);

    const createCalculatedField = (id: number, type: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.CALCULATED_FIELD.CREATE_CALCULATED_FIELD.replace(':id', id.toString()).replace(':type', type),
            setFetching: setFetchingCreateCalculatedFiel
        });
    };


    return {
        fetchingCreateCalculatedFiel,
        createCalculatedField,
    };
};

export default useCalculatedFieldService;