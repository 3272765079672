import React, { useContext } from 'react';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { TransportationOrderStatus } from '../../../app/models/TransportationOrderStatus';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useTransportationOrderItemService from '../../../app/services/hooks/useTransportationOrderItemService';
import { TransportationOrderDataForm } from '../../../app/models/TransportationOrder';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    transportationOrderItemId: number;
    statuses: TransportationOrderStatus[];
    selectedStatus: TransportationOrderStatus;
    reloadModule?: () => void;
    callbackSuccess?: () => void;
}

const TransportationOrderItemChangeStatus = ({
    transportationOrderItemId,
    statuses,
    selectedStatus,
    callbackSuccess,
    reloadModule
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [selected, setSelected] = React.useState<TransportationOrderStatus>(selectedStatus);

    const { changeStatusTransportationOrderItem, HasAllFinishedTransportationOrderItems } =
        useTransportationOrderItemService();

    const hasFinished = (id: number, value: string) => {
        if (showLoading) showLoading('loading', 'cargando..');
        if (value == selected.id) return null;

        const _status = statuses.find((status: TransportationOrderStatus) => status.id == value);

        HasAllFinishedTransportationOrderItems(id, value, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                changeStatus(_status, response.data.message);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setSelected(selected);
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const changeStatus = (_status: any, message: string) => {
        const status_value = _status ? _status.name : '';
        const _text = `Está a punto de cambiar el estado de la posición de pedido #${transportationOrderItemId} a ${status_value}.${
            message ?? ''
        }`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, cambiar estado',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        changeStatusTransportationOrderItem(transportationOrderItemId, _status.id, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                toast.success(response.message);

                                setSelected(_status ?? selected);

                                if (reloadModule) reloadModule();
                                if (callbackSuccess) callbackSuccess();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                setSelected(selected);
                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El estado del pedido no se ha cambiado.'
                            });
                            setSelected(selectedStatus);
                        }, 0);
                    }
                }
            }
        });
    };

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => hasFinished(transportationOrderItemId, String(e.target.value))}
        >
            {statuses
                ? statuses.map((status) => {
                      return (
                          <option key={status.id} value={status.id}>
                              {status.name}
                          </option>
                      );
                  })
                : null}
        </select>
    );
};

export default TransportationOrderItemChangeStatus;
