import React from 'react';
import { IconProps } from '../types';

export const Hopper: React.FC<IconProps> = ({ size = 22, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Capa_1"
            width={size}
            height={size}
            viewBox="0 0 481.815 481.815"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path d="M0,288.732h381.769c14.812,14.611,28.598,30.48,34.652,50.79c0.092,0.321,0.244,0.589,0.361,0.882 c0.067,5.049,0.1,10.103,0.16,15.156h-15.594c-6.5,0-11.773,5.266-11.773,11.773s5.273,11.778,11.773,11.778h41.217 c6.5,0,11.773-5.271,11.773-11.778s-5.273-11.773-11.773-11.773h-5.983c-0.136-22.281-0.497-44.551-0.705-66.82h45.938V100.752H0 V288.732z M416.253,288.732c0.032,3.158,0.06,6.308,0.092,9.458c-2.452-3.219-4.997-6.393-7.666-9.458H416.253z" />
                        <path d="M75.292,298.92c22.684,0,41.076,18.39,41.076,41.067c0,22.694-18.392,41.076-41.076,41.076 c-22.68,0-41.072-18.382-41.072-41.076C34.219,317.31,52.611,298.92,75.292,298.92z"></path>{' '}
                        <path d="M163.435,298.92c22.686,0,41.074,18.39,41.074,41.067c0,22.694-18.388,41.076-41.074,41.076 c-22.68,0-41.072-18.382-41.072-41.076C122.363,317.31,140.754,298.92,163.435,298.92z" />
                    </g>
                </g>
            </g>
        </svg>
    );
};
