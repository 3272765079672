import { useContext, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';
import { ServiceEvents } from '../shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { fetchDefaultHeaders } from '../shared/vars';
import {
    TollExpenseHistoryDataForm,
    TollExpenseHistoryFilter
} from '../../models/TollExpenseHistory';

const useTollExpenseHistoryService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [
        fetchingGetTollExpenseHistoriesByTollExpense,
        setFetchingGetTollExpenseHistoriesByTollExpense
    ] = useState(false);
    const [fetchingHistoryTollExpenseUpdate, setFetchingHistoryTollExpenseUpdate] = useState(false);
    const [fetchingHistoryTollExpenseEdit, setFetchingEditTollExpenseHistory] = useState(false);

    const getAllTollExpensehistoriesByTollExpense = (
        filter: TollExpenseHistoryFilter,
        toll_expense_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.TOLL_EXPENSE_HISTORIES.GET_TOLL_EXPENSE_HISTORIES_BY_TOLL_EXPENSE.replace(
            ':toll_expense_id',
            toll_expense_id.toString()
        )}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetTollExpenseHistoriesByTollExpense
        });
    };

    const editTollExpenseHistory = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TOLL_EXPENSE_HISTORIES.EDIT_TOLL_EXPENSE_HISTORY.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingEditTollExpenseHistory
        });
    };

    const updateTollExpenseHistory = (
        id: number,
        tollexpenseHistoryDataForm: TollExpenseHistoryDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.TOLL_EXPENSE_HISTORIES.UPDATE_TOLL_EXPENSE_HISTORY.replace(
                ':id',
                id.toString()
            ),
            body: tollexpenseHistoryDataForm,
            setFetching: setFetchingHistoryTollExpenseUpdate
        });
    };

    return {
        fetchingGetTollExpenseHistoriesByTollExpense,
        fetchingHistoryTollExpenseEdit,
        fetchingHistoryTollExpenseUpdate,
        getAllTollExpensehistoriesByTollExpense,
        editTollExpenseHistory,
        updateTollExpenseHistory
    };
};

export default useTollExpenseHistoryService;
