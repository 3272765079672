import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ActiveControl from '../../../../components/ActiveControl';
import { TollDataForm, TollFilters } from '../../../../app/models/Toll';
import TollFilter from './TollFilter';

interface Props {
    tolls: TollDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: TollFilters;
    setFilter: (filter: TollFilters) => void;
    paginationRowsPerPageOptions?: any[];
    onSearch: () => void;
}

const TollDataTable = ({
    tolls,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: TollDataForm, index: any, column: any) => (
                <span className="">{row?.id}</span>
            )
        },
        // {
        //     name: 'Descripción',
        //     selector: (row: any) => row?.description,
        //     sortable: true,
        //     sortField: 'description',
        //     cell: (row: TollDataForm, index: any, column: any) => (
        //         <span className="">{row.description}</span>
        //     )
        // },
        {
            name: 'Nombre',
            selector: (row: any) => row?.name,
            sortable: true,
            sortField: 'name',
            cell: (row: TollDataForm, index: any, column: any) => (
                <span className="">{row.name}</span>
            )
        },
        {
            name: 'Proveedor',
            selector: (row: any) => row?.provider,
            sortable: true,
            sortField: 'provider',
            cell: (row: TollDataForm, index: any, column: any) => (
                <span className="">{row.provider}</span>
            )
        },
        {
            name: 'Comuna',
            selector: (row: any) => row?.commune,
            sortable: true,
            sortField: 'commune',
            cell: (row: TollDataForm, index: any, column: any) => (
                <span className="">{row.commune}</span>
            )
        },
        {
            name: 'Latitud',
            selector: (row: any) => row?.latitude,
            sortable: true,
            sortField: 'latitude',
            cell: (row: TollDataForm, index: any, column: any) => (
                <span className="">{row.latitude}</span>
            )
        },
        {
            name: 'Longitud',
            selector: (row: any) => row?.longitude,
            sortable: true,
            sortField: 'longitude',
            cell: (row: TollDataForm, index: any, column: any) => (
                <span className="">{row.longitude}</span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: TollDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <TollFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={tolls}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default TollDataTable;
