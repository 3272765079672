import React, { ChangeEvent, useEffect, useState } from 'react';
import { ContractDataForm } from '../../../../app/models/Contract';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import CurrencyInput from '../../../../components/inputs/CurrencyInput';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Status } from '../../../../app/models/Status';
import { Worker } from '../../../../app/models/Workers';
import { JobTitle } from '../../../../app/models/JobTitle';
import { Shift } from '../../../../app/models/Shift';
import { Afp } from '../../../../app/models/Afp';
import { Provision } from '../../../../app/models/Provision';
import { Currency } from '../../../../app/models/Currency';
import ButtonCreate from '../../../../components/buttons/ButtonCreate';
import { BankAccount, BankAccountReason, BankAccountType } from '../../../../app/models/BankAccountType';
import { Bank } from '../../../../app/models/Bank';

interface Props {
    contract: ContractDataForm;
    setContract: (contract: ContractDataForm) => void;
    status: Status[];
    workers: Worker[];
    shifts: Shift[];
    jobTitles: JobTitle[];
    errorFields?: any;
    currencies: Currency[];
}

const ContractForm = ({
    contract,
    setContract,
    status,
    workers,
    shifts,
    jobTitles,
    currencies,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    const [selectedWorker, setSelectedWorker] = useState<Worker>()

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        setSelectedWorker(workers.find(worker => worker.id == Number(contract.worker_id)))
    }, [contract.worker_id]);


    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        const obj = { [name]: value };
        setContract({ ...contract, ...obj });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };
        setContract({ ...contract, ...obj });
    };

    const handleCheckChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name } = event.target;
        if (name === 'is_indefinite') {
            setContract({
                ...contract,
                is_indefinite: !contract.is_indefinite
            });
        } else if (name === 'applies_gratification') {
            setContract({
                ...contract,
                applies_gratification: !contract.applies_gratification
            });
        }
    };


    return (
        <>
            <div className="my-2 row">
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="is_indefinite"
                            value="false"
                            checked={!contract.is_indefinite}
                            id="temporal"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="temporal">
                            Plazo Fijo
                        </label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="is_indefinite"
                            value="true"
                            checked={contract.is_indefinite}
                            id="is_indefinite"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="is_indefinite">
                            Indefinido
                        </label>
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-md-12 col-form-label">Fecha de Ingreso</label>
                    <div className="col-md-12">
                        <input
                            type="date"
                            className={`form-control ${fieldHasError('start_date')}`}
                            name="start_date"
                            id="start_date"
                            value={contract.start_date?.toString()}
                            onChange={handleChange}
                            placeholder="Ingrese una fecha"
                            onFocus={() => onFocusRemove('date')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('start_date')}
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="col-md-9 col-form-label">Fecha de Término</label>
                    <div className="col-md-12">
                        <input
                            type="date"
                            className={`form-control ${fieldHasError('end_date')}`}
                            name="end_date"
                            id="end_date"
                            disabled={contract.is_indefinite}
                            value={contract.end_date?.toString()}
                            onChange={handleChange}
                            placeholder="Ingrese una fecha"
                            onFocus={() => onFocusRemove('end_date')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('end_date')}
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-md-6 col-form-label">Salario Base</label>
                    <div className="col-md-12">
                        <CurrencyInput
                            value={contract.base_salary}
                            currency={'CLP'}
                            name={'base_salary'}
                            onChange={handleChange}
                            className={`form-control ${fieldHasError('base_salary')}`}
                            placeholder={'Ingrese Salario'}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('base_salary')}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Moneda</label>
                    <SelectTwo
                        name="currency_id"
                        id="currency_id"
                        inputValue={contract.currency_id}
                        options={SelectTwoMapperOptions(currencies)}
                        hasError={fieldHasError('currency_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('currency_id', value?.value)
                        }
                        placeholder={'Seleccione moneda'}
                            onFocus={() => onFocusRemove('currency_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('currency_id')}
                    </div>
                </div>
            </div>

            <div className="my-4 row">
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="applies_gratification"
                            value="true"
                            checked={contract.applies_gratification}
                            id="applies_gratification"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="applies_gratification">
                            Con gratificación
                        </label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="applies_gratification"
                            value="false"
                            checked={!contract.applies_gratification}
                            id="applies_gratification"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="applies_gratification">
                            Sin gratificación
                        </label>
                    </div>
                </div>
            </div>

            <div className="col-md-12 mb-2">
                <label className="col-form-label">Trabajador</label>
                <SelectTwo
                    name="worker_id"
                    id="worker_id"
                    inputValue={contract.worker_id}
                    options={SelectTwoMapperOptions(workers, 'id', ['name'])}
                    hasError={fieldHasError('user_id') !== ''}
                    onChange={(value: any) => handleChangeSelectTwo('worker_id', value?.value)}
                    placeholder={'Seleccione Trabajador'}
                    onFocus={() => onFocusRemove('worker_id')}
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('worker_id')}
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Puesto de Trabajo</label>
                    <SelectTwo
                        name="job_title_id"
                        id="job_title_id"
                        inputValue={contract.job_title_id}
                        options={SelectTwoMapperOptions(jobTitles)}
                        hasError={fieldHasError('job_title_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('job_title_id', value?.value)
                        }
                        placeholder={'Seleccione la posición'}
                        onFocus={() => onFocusRemove('job_title_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('job_title_id')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Turno</label>
                    <SelectTwo
                        name="shift_work_id"
                        id="shift_work_id"
                        inputValue={contract.shift_work_id}
                        options={SelectTwoMapperOptions(shifts)}
                        hasError={fieldHasError('shift_work_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('shift_work_id', value?.value)
                        }
                        placeholder={'Seleccione el turno'}
                        onFocus={() => onFocusRemove('shift_work_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('shift_work_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">AFP</label>
                    <input
                        name="first_name"
                        id="first_name"
                        type="text"
                        disabled={true}
                        className={`form-control ${fieldHasError('first_name')}`}
                        value={selectedWorker?.afp?.name}
                        onFocus={() => onFocusRemove('first_name')}
                        placeholder="Puesto de trabajo"
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">SALUD</label>
                    <input
                        name="first_name"
                        id="first_name"
                        type="text"
                        disabled={true}
                        className={`form-control ${fieldHasError('first_name')}`}
                        value={selectedWorker?.provision?.name}
                        onFocus={() => onFocusRemove('first_name')}
                        placeholder="Puesto de trabajo"
                    />
                </div>
            </div>
            <div className="col-md-12">
                <label className="col-form-label">Email</label>
                <input
                    name="email"
                    id="email"
                    type="email"
                    className={`form-control ${fieldHasError('email')}`}
                    value={contract.email}
                    onChange={handleChange}
                    onFocus={() => onFocusRemove('email')}
                    placeholder="Ingrese el email"
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('email')}
                </div>
            </div>

        </>
    );
};

export default ContractForm;
