import React from 'react';
import { IconProps } from '../types';

export const ShiftWorks: React.FC<IconProps> = ({ size = 30, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            width={size}
            height={size}
            viewBox="0 0 20 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M7 3V5M17 3V5M16 18V16.2C16 15.0799 16 14.5198 16.218 14.092C16.4097 13.7157 16.7157 13.4097 17.092 13.218C17.5198 13 18.0799 13 19.2 13H21L16 18ZM16 18H3M7 9H17M7 13L8 14L10 12M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
                stroke="#000000"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
