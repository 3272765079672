import React from 'react';
import { IconProps } from '../../types';

export const FuelConsumption: React.FC<IconProps> = ({ size = 30, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Capa_1"
            width={size}
            height={size}
            viewBox="0 0 179.006 179.006"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <path d="M69.246,97.982c-3.258,0-5.901-2.637-5.901-5.907c0-3.27,5.901-13.61,5.901-13.61 s5.901,10.347,5.901,13.61C75.147,95.339,72.509,97.982,69.246,97.982z M64.8,73.894h-6.766v36.076H46.297v5.549h6.826v5.102H0 v-5.102h6.832V58.392h39.471v46.47h6.623V71.346l0.03-2.56h2.512H67.3l3.962,5.09l-4.028,3.139L64.8,73.894z M37.55,67.235H15.574 v16.403H37.55V67.235z M110.333,78.852c0.668,0.871,1.283,1.82,1.784,2.846c0.495,1.032,0.859,2.094,1.122,3.168l36.464-21.32 L110.333,78.852z M93.244,78.966c-5.812,3.031-8.073,10.209-5.036,16.027c3.037,5.818,10.215,8.079,16.021,5.048 c5.824-3.037,8.085-10.203,5.054-16.033C106.252,78.19,99.074,75.934,93.244,78.966z M104.862,15.938h-9.314v24.464h9.314V15.938z M95.553,163.069h9.314v-24.47h-9.314V163.069z M154.542,84.843v9.32h24.464v-9.32H154.542z M130.931,47.407l3.294,3.294 l17.298-17.298l-3.3-3.294L130.931,47.407z M130.931,131.599l17.298,17.298l3.294-3.294l-17.292-17.298L130.931,131.599z M74.043,152.346H86v-2.112v-2.1H74.043v-5.197h12.894v-2.172v-2.16H68.929v24.464h18.575v-2.178v-2.172H74.043V152.346z M74.61,40.402V30.055h10.716v-2.112v-2.094H74.61v-5.567h12.304v-2.178v-2.166H69.508v24.464h2.56H74.61z" />
                </g>
            </g>
        </svg>
    );
};
