import React, { ChangeEvent, useEffect, useState } from 'react';
import { DocumentSetDataForm, Equipment } from '../../../../app/models/DocumentSet';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { JobTitle } from '../../../../app/models/JobTitle';

interface Props {
    documentSetDataForm: DocumentSetDataForm;
    setDocumentSet: (documentSet: DocumentSetDataForm) => void;
    documents: Document[];
    jobTitles: JobTitle[];
    equipments: Equipment[];
    firstJobTitles?: JobTitle[];
    firstEquipments?: Equipment[];
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const DocumentSetForm = ({
    documentSetDataForm,
    setDocumentSet,
    documents,
    jobTitles,
    equipments,
    firstJobTitles = [],
    firstEquipments = [],
    errorFields,
    setErrorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [selectedCategoryWorker, setSelectedCategoryWorker] = useState<boolean>((documentSetDataForm?.job_titles?.length ?? 0) > 0);
    
    const [selectedEquipment, setSelectedEquipment] = useState<boolean>((documentSetDataForm.equipments?.length ?? 0) > 0);


    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setDocumentSet({ ...documentSetDataForm, [name]: value });
    };

    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        
        if(name != 'is_worker_document_set' && name != 'has_equipment') {
            setDocumentSet({
                ...documentSetDataForm,
                [name]: checked
            });
        } else if(name == 'is_worker_document_set') {
            if(checked) {
                setSelectedCategoryWorker(true);
                setDocumentSet({
                    ...documentSetDataForm,
                    job_titles: firstJobTitles ? firstJobTitles: []
                });
                
            } else {
                setSelectedCategoryWorker(false);
                setDocumentSet({
                    ...documentSetDataForm,
                    job_titles: []
                });
            }
        }
        else{
            if(checked) {
                setSelectedEquipment(true);
                setDocumentSet({
                    ...documentSetDataForm,
                    equipments: firstEquipments? firstEquipments: []
                });
            } else {
                setSelectedEquipment(false);
                setDocumentSet({
                    ...documentSetDataForm,
                    equipments: []
                });
            }
        }
    };

    const MapperOptions = (items: any[]) => {
        return items.map((item) => ({
            value: item.id,
            label: item.document_name
        }));
    };

    if (!documentSetDataForm) return null;

    return (
        <>
       
            <div className="mb-2 row needs-validation">
                <div className="col-md-12">
                <label className="col-form-label">Nombre</label>
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={documentSetDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre del Documento"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12 mb-2">
                    <label className="col-form-label">Categoría</label>
                    <div className="col-12">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="is_society_document_set"
                                checked={documentSetDataForm.is_society_document_set}
                                id="category_society"
                                onChange={handleCheckChange}
                            />
                            <label className="form-check-label" htmlFor="category_society">
                                Sociedades
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="has_equipment"
                            checked={selectedEquipment}
                            id="has_equipment"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="has_equipment">
                            Equipos
                        </label>
                    </div>
                    {selectedEquipment && (
                        <div className="mt-2 mb-2 row">
                        <div className="col-md-9">
                            <SelectTwo
                                name="equipments"
                                id="equipments"
                                inputValue={documentSetDataForm.equipments}
                                options={SelectTwoMapperOptions(equipments)}
                                hasError={fieldHasError('equipments') !== ''}
                                onChange={(value: any) =>
                                    setDocumentSet({
                                        ...documentSetDataForm,
                                        equipments: value
                                    })
                                }
                                isSortable={true}
                                isMulti={true}
                                placeholder={'Seleccione equipos'}
                                onFocus={() => onFocusRemove('equipments')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('equipments')}
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                
                <div className="col-12 mb-2">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="is_worker_document_set"
                            checked={selectedCategoryWorker}
                            id="category_workers"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="category_workers">
                            Trabajadores
                        </label>
                    </div>
                    {selectedCategoryWorker && (
                        <div className="mt-2 mb-2 row">
                            <div className="col-md-9">
                                <SelectTwo
                                    name="jobTitles"
                                    id="jobTitles"
                                    inputValue={documentSetDataForm.job_titles}
                                    options={SelectTwoMapperOptions(jobTitles)}
                                    hasError={fieldHasError('jobTitles') !== ''}
                                    onChange={(value: any) =>
                                        setDocumentSet({
                                            ...documentSetDataForm,
                                            job_titles: value
                                        })
                                    }
                                    isSortable={true}
                                    isMulti={true}
                                    placeholder={'Seleccione los puestos de trabajo'}
                                    onFocus={() => onFocusRemove('jobTitles')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('jobTitles')}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

      
            </div>


            <div className="mb-2 row">
                <div className="col-md-12">
                <label className="col-form-label">Documentos</label>
                    <SelectTwo
                        name="documents"
                        id="documents"
                        inputValue={documentSetDataForm.documents}
                        options={MapperOptions(documents)}
                        hasError={fieldHasError('documents') !== ''}
                        onChange={(value: any) =>
                            setDocumentSet({
                                ...documentSetDataForm,
                                documents: value
                            })
                        }
                        isSortable={true}
                        isMulti={true}
                        placeholder={'Seleccione los documentos'}
                        onFocus={() => onFocusRemove('documents')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('documents')}
                    </div>
                </div>
            </div>



            
            
        </>
    );
};

export default DocumentSetForm;
