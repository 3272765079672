import React, { useEffect, useState } from 'react';
import useIncidentService from '../../../app/services/hooks/useIncidentService';
import { IncidentDataForm, defaultIncidentDataForm } from '../../../app/models/Incident';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import IncidentShowForm from './forms/IncidentShowForm';

interface Props {
    incidentId: number;
    onError?: (message?: string) => void;
}

const IncidentShow = ({ incidentId, onError }: Props) => {
    const { fetchingShowIncident, showIncident } = useIncidentService();

    const [incidentDataForm, setIncidentDataForm] = useState<IncidentDataForm>(defaultIncidentDataForm);

    useEffect(() => {
        if (incidentId) {
            show();
        }
    }, [incidentId]);

    const show = () => {
        showIncident(incidentId, {
            onSuccess: (response: ServiceResponse) => {
                setIncidentDataForm(response.data.incident);
            },
            onError: (response: ServiceResponse) => {
                onError && onError(response.message);
            }
        });
    };

    return !fetchingShowIncident ? (
        <IncidentShowForm incidentDataForm={incidentDataForm} />
    ) : (
        <LazyLoading height={300} />
    );
};

export default IncidentShow;
