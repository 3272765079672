import React from 'react';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: any;
    setFilter: (filter: any) => void;
    onSearch: () => void;
};

const ForeignExchangesFilters = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="exchange_date_from">
                            Desde
                        </label>
                        <input
                            type="date"
                            id="exchange_date_from"
                            name="exchange_date_from"
                            className="form-control form-control-sm"
                            value={filter.exchange_date_from || ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="exchange_date_until">
                            Hasta
                        </label>
                        <input
                            type="date"
                            id="exchange_date_until"
                            name="exchange_date_until"
                            className="form-control form-control-sm"
                            value={filter.exchange_date_until || ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="name">
                            Nombre
                        </label>
                        <select
                            id="name"
                            name="name"
                            className="form-control form-select-sm"
                            value={filter.name || ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option value="">TODOS</option>
                            <option value="Dólar observado">Dólar observado</option>
                            <option value="Unidad de fomento (UF)">Unidad de fomento (UF)</option>
                            <option value="Euro">Euro</option>
                        </select>
                    </div>
                </div>
                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForeignExchangesFilters;
