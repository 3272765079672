import { useContext, useEffect, useState} from 'react'
import useDeductionService from '../../../app/services/hooks/useDeductionServices'
import { Deduction, defaultDeduction, TypeOfDeductionsValues } from '../../../app/models/Deduction'
import { AppContext } from '../../../contexts/AppContext'
import { ServiceResponse } from '../../../app/services/shared/interfaces'
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert'
import { toast } from 'react-toastify'
import LazyLoading from '../../../components/LazyLoading'
import DeductionFormContainer from '../components/DeductionFormContainer'
import { Currency } from '../../../app/models/Currency'

interface Props {
  onSaved?: () => void;
  onCancel?: () => void;
  onError?: () => void;
}

const DeductionCreate = ({ onSaved, onCancel, onError }: Props) => {

  const {showLoading, hideLoading} = useContext(AppContext);
  const { fetchingStoreDeduction, storeDeduction, createDeduction, fetchingCreateDeduction} = useDeductionService();
  const [errorFields, setErrorFields] = useState<any>();
  const [deduction, setdeduction] = useState<Deduction>(defaultDeduction);


  const store = () => {
    if (showLoading) showLoading('loading', 'Guardando deducción...');
    storeDeduction(deduction, {
        onSuccess: (response: ServiceResponse) => {
            if (hideLoading) hideLoading();
            useReactConfirmAlert().successAlert({
                title: 'Éxito',
                message: response.message
            });
            toast.success(response.message, {
                autoClose: 2500
            });
            if (onSaved) onSaved();
        },
        onError: (response: ServiceResponse) => {
            if (hideLoading) hideLoading();
            useReactConfirmAlert().errorAlert({
                title: 'Error',
                message: response.message
            });
        },
        onFieldError: (errorFields: ServiceResponse) => {
            if (hideLoading) hideLoading();
            setErrorFields(errorFields.data);
        }
    });
  };
  
  const cancel = () => {
    if (onCancel) onCancel();
  };

  return fetchingCreateDeduction ? (
    <LazyLoading height={300} />
  ) : (
    <DeductionFormContainer
      action={store} 
      cancel={cancel} 
      deduction={deduction} 
      setDeduction={setdeduction}
      errorFields={errorFields}
    />
  )
}

export default DeductionCreate