import { Currency } from './Currency';
import { Place } from './Place';
import { Toll } from './Toll';
import { Unit } from './Unit';

export interface RouteSegment {
    id?: number;

    origin_id?: number;
    origin?: Place;
    destiny_id?: number;
    destiny?: Place;
    distance?: number | string;
    tolls?: Toll[];

    rate?: number | string;
    currency_id?: number;
    currency?: Currency;
    unit_id?: number;
    unit?: Unit;
    name?: string;
    createdAt?: Date | string;
    updatedAt?: Date | string;

    external_rate?: number;
    currency_external_rate_id?: number;
    unit_external_rate_id?: number;

    commission?: number;
    unit_commission_id?: number;
    currency_commission_id?: number;
}

export interface RouteSegmentFilters {
    route_segment_id?: number | null;
    start_date?: string | null;
    end_date?: string | null;
    origin?: string | null;
    destiny?: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface RouteSegmentDataForm {
    id?: number;
    origin_id?: number | string;
    origin?: Place;
    destiny_id?: number | string;
    destiny?: Place;
    distance?: number | string;
    tolls?: Toll[];
    toll_ids?: number[];
    rate?: number | string;
    currency_id?: number | string;
    currency?: Currency;

    unit_id?: number | string;
    unit?: Unit;
    created_at?: string;
    created_user?: string;
    updated_at?: string;
    last_modified_user?: string;

    external_rate?: number | string;
    currency_external_rate_id?: number;
    unit_external_rate_id?: number;

    commission?: number | string;
    unit_commission_id?: number;
    currency_commission_id?: number;

    currency_rate_runner_id?: number;
    quantity_unit_rate_runner_id?: number;
    rate_runner?: number | string;
}

export interface Routes {
    id?: number;
    name?: string;
    agreed_distance?: number;
    external_rate?: number | string;
    currency_external_rate_id?: number;
    unit_external_rate_id?: number;

    commission?: number | string;
    unit_commission_id?: number;
    currency_commission_id?: number;
    currency_id?: number;
    rate?: number;

    unit_rate_runner_id?: number;
    rate_runner?: number;
    currency_rate_runner_id?: number;
    quantity_unit_rate_runner_id?: number;
}

export interface RouteSegmentShowDataForm {
    id?: number;
    origin?: Place;
    destiny?: Place;
    distance?: number | string;
    currency?: Currency;
    unit?: Unit;
    created_at?: string;
    created_user?: string;
    updated_at?: string;
    last_modified_user?: string;
    external_rate?: number | string;
    currency_external_rate_id?: number;
    unit_external_rate_id?: number;

    commission?: number | string;
    unit_commission_id?: number;
    currency_commission_id?: number;
}

export const defaultRouteSegmentDataForm: RouteSegmentDataForm = {
    origin_id: 0,
    destiny_id: 0,
    distance: 0,
    rate: undefined,
    rate_runner: undefined,
    external_rate: undefined,
    commission: undefined,
    tolls: undefined,
    unit_id: undefined,
    currency_id: undefined
};
