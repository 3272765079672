import React, { useContext, useEffect, useState } from 'react';
import {
    defaultQuoteDataForm,
    QuoteDataForm,
    QuoteOfferTypeDataForm
} from '../../../app/models/Quote';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import QuoteForm from './forms/QuoteForm';
import useQuoteService from '../../../app/services/hooks/useQuoteService';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import { PaymentCondition } from '../../../app/models/PaymentCondition';
import { Currency } from '../../../app/models/Currency';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const QuoteCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [paymentConditions, setPaymentsConditions] = useState<PaymentCondition[]>([]);
    const [offerTypes, setOfferTypes] = useState<QuoteOfferTypeDataForm[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentCondition[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);

    const { fetchingStoreQuote, storeQuote, createQuote, fetchingCreateQuote } = useQuoteService();

    //formulario
    const [quote, setQuote] = useState<QuoteDataForm>(defaultQuoteDataForm);

    const [errorFields, setErrorFields] = useState<any>();
    const history = useHistory();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createQuote({
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setClients(response.data.clients);
                setPaymentMethods(response.data.payment_methods);
                setPaymentsConditions(response.data.payment_conditions);
                setCurrencies(response.data.currencies);
                setOfferTypes(response.data.offer_types);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando oferta...');
        storeQuote(quote, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                history.push('/quotes/' + response.data.quote_id + '/edit');

                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateQuote ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <QuoteForm
                        quoteForm={quote}
                        setQuoteForm={setQuote}
                        societies={societies}
                        clients={clients}
                        paymentConditions={paymentConditions}
                        paymentMethods={paymentMethods}
                        currencies={currencies}
                        errorFields={errorFields}
                        offerTypes={offerTypes}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreQuote} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreQuote} />
                </div>
            </div>
        </>
    );
};

export default QuoteCreate;
