import { Tab, Tabs } from 'react-bootstrap';
import DefaultCard from '../../../../components/default/DefaultCard';
import QuoteForm from '../forms/QuoteForm';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import LazyLoading from '../../../../components/LazyLoading';
import DefaultCreatedModifiedUserForm from '../../../../components/default/DefaultCreatedModifiedUserForm';
import {
    QuoteDataForm,
    QuoteOfferTypeDataForm,
    QuoteShowDataForm
} from '../../../../app/models/Quote';
import { Society } from '../../../../app/models/Society';
import { Currency } from '../../../../app/models/Currency';
import { PaymentCondition } from '../../../../app/models/PaymentCondition';
import { Roles } from '../../../../app/shared/enums';
import { Client } from '../../../../app/models/Client';
import QuoteShowForm from '../forms/QuoteShowForm';

interface Props {
    auth?: any;
    update?: () => void;
    quoteForm?: QuoteDataForm;
    quoteShowForm?: QuoteShowDataForm;
    setQuoteForm?: (quote: QuoteDataForm) => void;
    societies?: Society[];
    clients?: Client[];
    currencies?: Currency[];
    paymentConditions?: PaymentCondition[];
    offerTypes?: QuoteOfferTypeDataForm[];
    paymentMethods?: PaymentCondition[];
    errorFields?: any;
    condition?: boolean;
    fetchingQuote?: boolean;
    isFormShow?: boolean;
    disableUpdate?: boolean;
}

const QuoteTab = ({
    auth,
    fetchingQuote,
    update,
    quoteForm,
    quoteShowForm,
    setQuoteForm,
    societies,
    clients,
    paymentMethods,
    paymentConditions,
    offerTypes,
    currencies,
    errorFields,
    condition,
    isFormShow = false,
    disableUpdate = false
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingQuote ? (
                            <>
                                {!isFormShow ? (
                                    <>
                                        <QuoteForm
                                            quoteForm={quoteForm!}
                                            setQuoteForm={setQuoteForm!}
                                            societies={societies!}
                                            clients={clients!}
                                            paymentConditions={paymentConditions!}
                                            paymentMethods={paymentMethods!}
                                            offerTypes={offerTypes!}
                                            currencies={currencies!}
                                            errorFields={errorFields}
                                            disableUpdate={disableUpdate}
                                        />
                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                {auth?.roles && (
                                                    <ButtonSaveForm callbackSave={update!} />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <QuoteShowForm quoteForm={quoteShowForm!} />
                                )}
                            </>
                        ) : (
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="h3 mt-0 mb-2 card-title">Usuarios</div>
                            {!fetchingQuote ? (
                                <DefaultCreatedModifiedUserForm
                                    createdBy={
                                        !isFormShow
                                            ? quoteForm!.created_user
                                            : quoteShowForm!.created_user
                                    }
                                    createdAt={
                                        !isFormShow
                                            ? quoteForm!.created_at
                                            : quoteShowForm!.created_at
                                    }
                                    updatedBy={
                                        !isFormShow
                                            ? quoteForm!.last_modified_user
                                            : quoteShowForm?.last_modified_user
                                    }
                                    updatedAt={
                                        !isFormShow
                                            ? quoteForm!.updated_at
                                            : quoteShowForm!.updated_at
                                    }
                                    status={!isFormShow ? quoteForm!.status : quoteShowForm!.status}
                                />
                            ) : (
                                <LazyLoading />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuoteTab;
