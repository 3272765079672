import { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import { UnitEquivalenceDataForm, UnitEquivalenceFilters } from '../../app/models/Unit';
import useNavigationPage from '../../hooks/useNavigationPage';
import useUnitEquivalenceService from '../../app/services/hooks/useUnitEquivalenceService';
import UnitEquivalenceEdit from './components/UnitEquivalenceEdit';
import UnitEquivalenceCreate from './components/UnitEquivalenceCreate';
import UnitEquivalenceDataTable from './components/tables/UnitEquivalenceDataTable';
import UnitEquivalenceCalculator from './components/UnitEquivalenceCalculator';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Equivalencia de unidades',
        url: '/unit-equivalence',
        isActive: true
    }
];

const UnitEquivalence = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingGetUnitEquivalence,
        getUnitEquivalences,
        activeUnitEquivalence,
        deleteUnitEquivalence
    } = useUnitEquivalenceService();
    const [unitEquivalence, setUnitEquivalence] = useState<UnitEquivalenceDataForm[]>([]);
    const [unitEquivalenceIdEdit, setUnitEquivalenceIdEdit] = useState<number>(-1);
    const [showingCreateEquivalence, setShowingCreateEquivalence] = useState(false);
    const [showingEditEquivalence, setShowingEditEquivalence] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);
    const { navigationPage } = useNavigationPage();
    const [unitEquivalenceFilter, setUnitEquivalenceFilter] = useState<UnitEquivalenceFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [unitEquivalenceFilter]);

    const getAllUnitEquivalences = () => {
        getUnitEquivalences(unitEquivalenceFilter, {
            onSuccess: (response: ServiceResponse) => {
                setUnitEquivalence(response.data.unit_equivalences);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const activeEquivalence = (unitEquivalenceId: number, active: boolean) => {
        activeUnitEquivalence(unitEquivalenceId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const destroyEquivalence = (UnitEquivalenceId: number) => {
        const _text = 'Está a punto de eliminar la equivalencia #' + UnitEquivalenceId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando equivalencia...');
                        deleteUnitEquivalence(UnitEquivalenceId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La equivalencia no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const showCreateEquivalence = () => {
        setShowingCreateEquivalence(true);
    };

    const hideCreateEquivalence = () => {
        setShowingCreateEquivalence(false);
    };

    const showEditEquivalence = (unitEquivalenceId: number) => {
        setShowingEditEquivalence(true);
        setUnitEquivalenceIdEdit(unitEquivalenceId);
    };

    const hideEditEquivalence = () => {
        setShowingEditEquivalence(false);
        setUnitEquivalenceIdEdit(-1);
    };

    const reloadTable = () => {
        getAllUnitEquivalences();
    };

    return (
        <>
            <Breadcrumbs pageSection="Equivalencia de unidades" breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <UnitEquivalenceCalculator unitEquivalences={unitEquivalence} />
                    </DefaultCard>
                </div>
            </div>
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate
                            callbackCreate={showCreateEquivalence}
                            title="Crear equivalencia"
                        />
                    }
                />
                <UnitEquivalenceDataTable
                    unitEquivalences={unitEquivalence}
                    edit={showEditEquivalence}
                    destroy={destroyEquivalence}
                    active={activeEquivalence}
                    loading={fetchingGetUnitEquivalence}
                    filter={unitEquivalenceFilter}
                    setFilter={setUnitEquivalenceFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                />
            </DefaultCard>

            {showingCreateEquivalence ? (
                <DefaultModal
                    show={showingCreateEquivalence}
                    handleClose={hideCreateEquivalence}
                    title="Crear equivalencia de unidades"
                    backdrop={true}
                    showFooter={false}
                >
                    <UnitEquivalenceCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreateEquivalence();
                        }}
                        onCancel={hideCreateEquivalence}
                        onError={hideCreateEquivalence}
                    />
                </DefaultModal>
            ) : null}

            {showingEditEquivalence ? (
                <DefaultModal
                    show={showingEditEquivalence}
                    handleClose={hideEditEquivalence}
                    title="Editar Equivalencia"
                    backdrop={true}
                    showFooter={false}
                >
                    <UnitEquivalenceEdit
                        unitEquivalenceId={unitEquivalenceIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEditEquivalence();
                        }}
                        onCancel={hideEditEquivalence}
                        onError={hideEditEquivalence}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default UnitEquivalence;
