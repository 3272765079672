import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useNavigationPage from '../../hooks/useNavigationPage';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import DefaultModal from '../../components/default/DefaultModal';
import useJobTitleService from '../../app/services/hooks/useJobTitleService';
import { JobTitle, JobTitleFilters } from '../../app/models/JobTitle';
import JobTitleDataTable from './components/tables/JobTitleDataTable';
import JobTitleCreate from './components/forms/JobTitleCreate';
import JobTitleEdit from './components/forms/JobTitleEdit';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Puestos de Trabajo',
        url: '/job-titles',
        isActive: true
    }
];

const JobTitles = () => {
    const history = useHistory();
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetJobTitles, getJobTitles, deleteJobTitle, activeJobTitle } =
        useJobTitleService();

    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [jobTitleIdEdit, setJobTitleIdEdit] = useState<number>(-1);
    const [jobTitles, setJobTitles] = useState<JobTitle[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [jobTitleFilter, setJobTitleFilter] = useState<JobTitleFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        name: ''
    });

    useEffect(() => {
        reloadTable();
    }, [jobTitleFilter.page, jobTitleFilter.per_page, jobTitleFilter.sort, jobTitleFilter.order]);

    const getAllJobTitles = () => {
        getJobTitles(jobTitleFilter, {
            onSuccess: (response: ServiceResponse) => {
                setJobTitles(response.data.job_titles);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const destroy = (jobTitleId: number, JobTitleName: string) => {
        const _text = 'Está a punto de eliminar el puesto de trabajo ' + `'${JobTitleName}'`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando puesto de trabajo...');
                        deleteJobTitle(jobTitleId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El puesto de trabajo no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const showEdit = (jobtitleId: number) => {
        setShowingEdit(true);
        setJobTitleIdEdit(jobtitleId);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllJobTitles();
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setJobTitleIdEdit(-1);
    };

    const active = (jobTitleId: number, active: boolean) => {
        activeJobTitle(jobTitleId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Puestos de Trabajo" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate callbackCreate={showCreate} title="Nuevo puesto de trabajo" />
                    }
                />
                <JobTitleDataTable
                    jobTitles={jobTitles}
                    totalRows={totalRows}
                    filter={jobTitleFilter}
                    loading={fetchingGetJobTitles}
                    setFilter={setJobTitleFilter}
                    callbackSuccess={reloadTable}
                    destroy={destroy}
                    edit={showEdit}
                    onSearch={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear puesto de trabajo"
                    backdrop={true}
                    showFooter={false}
                >
                    <JobTitleCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar puesto de Trabajo"
                    backdrop={true}
                    showFooter={false}
                >
                    <JobTitleEdit
                        jobTitleId={jobTitleIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default JobTitles;
