import { Bonus } from '../../../app/models/Bonus';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import BonusForm from './forms/BonusForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    bonus: Bonus;
    setBonus: (bonus: Bonus) => void;
    errorFields?: any;
}

const BonusFormContainer = ({
    fetching,
    action,
    cancel,
    bonus,
    setBonus,
    errorFields = null,
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <BonusForm
                        bonusForm={bonus}
                        setBonusForm={setBonus}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default BonusFormContainer;
