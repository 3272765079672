import React, { useContext, useEffect, useState } from 'react';
import useLiquidationService from '../../../app/services/hooks/useLiquidationService';
import { LiquidationGenerator, defaultLiquidationGenerator } from '../../../app/models/Liquidation';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import LiquidationFormContainer from '../components/LiquidationFormContainer';
import { Contract } from '../../../app/models/Contract';
import { Bonus } from '../../../app/models/Bonus';
import { Deduction } from '../../../app/models/Deduction';
import { JobTitle } from '../../../app/models/JobTitle';
import { getCurrentMonth } from '../../../helpers';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const LiquidationCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const {
        fetchingStoreLiquidation,
        storeLiquidation,
        createLiquidation,
        fetchingCreateLiquidation
    } = useLiquidationService();
    const [errorFields, setErrorFields] = useState<any>();
    const [liquidation, setLiquidation] = useState<LiquidationGenerator>({
        ...defaultLiquidationGenerator,
        period: getCurrentMonth()
    });
    const [contract, setContract] = useState<Contract[]>([]);
    const [jobTitles, setJobtitles] = useState<JobTitle[]>([]);

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        createLiquidation({
            onSuccess: (response: ServiceResponse) => {
                setContract(response.data.contracts);
                setJobtitles(response.data.job_titles);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando liquidación...');
        storeLiquidation(liquidation, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateLiquidation ? (
        <LazyLoading height={300} />
    ) : (
        <LiquidationFormContainer
            action={store}
            cancel={cancel}
            liquidation={liquidation}
            setLiquidation={setLiquidation}
            contracts={contract}
            jobTitles={jobTitles}
            fetching={fetchingStoreLiquidation}
            errorFields={errorFields}
        />
    );
};

export default LiquidationCreate;
