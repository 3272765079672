import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import LazyLoading from '../../../../components/LazyLoading';

import { toast } from 'react-toastify';
import { AppContext } from '../../../../contexts/AppContext';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';

import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';

import useExpenseService from '../../../../app/services/hooks/useExpenseService';
import { ExpenseDataForm, defaultExpenseDataForm } from '../../../../app/models/Expense';
import { Provider } from '../../../../app/models/Provider';
import { ExpenseType } from '../../../../app/models/ExpenseType';
import { ExpenseDocumentType } from '../../../../app/models/ExpenseDocumentType';
import CalendarExpenseForm from '../forms/CalendarExpenseForm';
import { Currency } from '../../../../app/models/Currency';

interface Props {
    expenseId: number;
    transportOrderId?: number;
    eventId?: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CalendarExpenseEdit = ({
    transportOrderId,
    eventId,
    expenseId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditCalendarExpense,
        editCalendarExpense,
        fetchingUpdateCalendarExpense,
        updateCalendarExpense
    } = useExpenseService();

    const [expense, setExpense] = useState<ExpenseDataForm>({
        ...defaultExpenseDataForm,
        photo: undefined,
        transport_order_id: transportOrderId
    });

    const [providers, setProviders] = useState<Provider[]>([]);
    const [expenseTypes, setExpenseTypes] = useState<ExpenseType[]>([]);
    const [expenseDocumentTypes, setExpenseDocumentTypes] = useState<ExpenseDocumentType[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, [expenseId]);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando gasto de viaje...');
        editCalendarExpense(expenseId, {
            onSuccess: (response: ServiceResponse) => {
                setExpense(response.data.expense);
                setProviders(response.data.providers);
                setExpenseTypes(response.data.expense_types);
                setExpenseDocumentTypes(response.data.expense_document_types);
                setCurrencies(response.data.currencies);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando Gasto de viaje...');
        updateCalendarExpense(expenseId, expense, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditCalendarExpense ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <CalendarExpenseForm
                        ExpenseForm={expense}
                        setExpenseForm={setExpense}
                        expenseTypes={expenseTypes}
                        expenseDocumentTypes={expenseDocumentTypes}
                        errorFields={errorFields}
                        providers={providers}
                        currencies={currencies}
                        selectedEventDate={''}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateCalendarExpense}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateCalendarExpense} />
                </div>
            </div>
        </>
    );
};

export default CalendarExpenseEdit;
