import React from 'react';
import { IconProps } from '../types';

export const Afp: React.FC<IconProps> = ({ size = 22, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            width={size}
            height={size}
            viewBox="0 0 512 512"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path d="M453.278,420.87l-63.91-63.908c-34.557-34.554-90.783-34.556-125.34,0l-25.684,25.684l-72.692-0.547 c-7.578-0.056-14.727,2.841-20.128,8.161c-1.174,1.157-2.227,2.402-3.169,3.713l-37.012-37.012 c-10.959-10.959-28.331-11.549-39.549-1.34c-5.806,5.283-9.104,12.506-9.288,20.335c-0.181,7.72,2.844,15.278,8.304,20.738 l14.601,14.601c3.24,3.238,8.491,3.238,11.73,0c3.239-3.24,3.239-8.491,0-11.73l-14.6-14.601c-2.302-2.301-3.526-5.362-3.45-8.618 c0.075-3.251,1.451-6.255,3.869-8.456c4.576-4.166,12.047-3.804,16.655,0.803l43.491,43.491c0.005,0.006,0.011,0.01,0.017,0.016 c0.889,14.754,13.109,26.793,28.105,26.908l75.935,0.823c0.022,0,0.043,0,0.064,0c4.552,0,8.258-3.925,8.293-8.483 c0.034-4.581-3.651-8.447-8.231-8.483l-75.935-0.634c-6.501-0.05-11.75-5.41-11.701-11.91c0.023-3.151,1.272-6.117,3.515-8.327 c2.244-2.209,5.229-3.417,8.363-3.399l73.851,0.552c2.758,0.724,5.815,0.011,7.977-2.15l28.403-28.403 c28.087-28.088,73.791-28.091,101.88,0l63.906,63.906c1.62,1.619,3.743,2.428,5.865,2.428s4.245-0.809,5.865-2.428 C456.517,429.359,456.517,424.109,453.278,420.87z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M389.372,484.77l-35.507-35.5c-1.961-1.96-4.772-2.863-7.491-2.32l-70.202,13.988H152.618l-25.973-25.922 c-3.24-3.238-8.491-3.211-11.73,0.025c-3.239,3.24-3.239,8.503,0,11.743l28.403,28.358c1.556,1.555,3.665,2.383,5.865,2.383 h127.812c0.546,0,1.09-0.002,1.627-0.109l66.654-13.304l32.364,32.378c1.62,1.619,3.743,2.435,5.865,2.435 s4.245-0.806,5.865-2.425C392.611,493.258,392.611,488.01,389.372,484.77z"></path>{' '}
                    </g>{' '}
                </g>{' '}
                <g>
                    {' '}
                    <g>
                        {' '}
                        <path d="M504.259,13.076h-70.773c-4.581,0-8.294,3.712-8.294,8.294c0,4.581,3.712,8.294,8.294,8.294h61.927v266.505H16.587V29.664 h381.512c4.581,0,8.294-3.712,8.294-8.294c0-4.581-3.712-8.294-8.294-8.294H8.847C4.265,13.076,0,16.236,0,20.817V303.91 c0,4.581,4.265,8.847,8.847,8.847h495.412c4.581,0,7.741-4.265,7.741-8.847V20.817C512,16.237,508.841,13.076,504.259,13.076z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M468.596,100.99c-24.695,0-44.648-19.814-44.648-44.51c0-4.581-3.574-8.017-8.156-8.017H97.313 c-4.581,0-8.294,3.437-8.294,8.017c0,24.695-20.367,44.648-45.063,44.648c-4.581,0-8.57,3.574-8.57,8.156v106.16 c0,4.581,3.989,8.294,8.57,8.294c24.695,0,44.924,20.367,44.924,45.063c0,4.581,3.85,8.57,8.432,8.57h318.479 c4.581,0,8.294-3.989,8.294-8.57c0-24.695,19.814-44.924,44.51-44.924c4.581,0,8.017-3.851,8.017-8.432v-106.16 C476.613,104.702,473.178,100.99,468.596,100.99z M460.026,207.708c-26.54,3.692-48.276,25.429-51.969,53.074H105.048 c-3.692-27.646-25.429-49.382-53.074-53.074v-90.689c27.646-3.692,49.382-25.429,53.074-51.969h303.009 c3.692,26.54,25.429,48.276,51.969,51.969V207.708z"></path>{' '}
                    </g>{' '}
                </g>{' '}
                <g>
                    {' '}
                    <g>
                        {' '}
                        <path d="M274.246,154.623h-9.953v-45.339h27.646c4.581,0,8.294-3.712,8.294-8.294c0-4.581-3.712-8.294-8.294-8.294h-27.646v-9.953 c0-4.581-3.712-8.294-8.294-8.294s-8.294,3.712-8.294,8.294v9.953h-8.847c-14.329,0-26.54,11.105-26.54,25.434v26.54 c0,14.329,12.211,26.54,26.54,26.54h8.847v45.339h-26.54c-4.581,0-8.294,3.712-8.294,8.294s3.712,8.294,8.294,8.294h26.54v8.847 c0,4.581,3.712,8.294,8.294,8.294s8.294-3.712,8.294-8.294v-8.847h9.953c14.329,0,25.434-12.211,25.434-26.54v-26.54 C299.68,165.728,288.576,154.623,274.246,154.623z M247.706,154.623h-8.847c-5.183,0-9.953-4.769-9.953-9.952v-26.54 c0-5.183,4.769-8.847,9.953-8.847h8.847V154.623z M283.093,206.597c0,5.183-3.664,9.952-8.847,9.952h-9.953V171.21h9.953 c5.183,0,8.847,3.664,8.847,8.847V206.597z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M380.406,127.53c-19.207,0-34.834,15.626-34.834,34.834s15.627,34.834,34.834,34.834 c19.207,0,34.834-15.626,34.834-34.834S399.613,127.53,380.406,127.53z M380.406,180.61c-10.061,0-18.246-8.185-18.246-18.246 s8.185-18.246,18.246-18.246c10.061,0,18.246,8.185,18.246,18.246S390.467,180.61,380.406,180.61z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M132.7,127.53c-19.207,0-34.834,15.626-34.834,34.834s15.627,34.834,34.834,34.834c19.207,0,34.834-15.626,34.834-34.834 S151.907,127.53,132.7,127.53z M132.7,180.61c-10.061,0-18.246-8.185-18.246-18.246s8.185-18.246,18.246-18.246 s18.246,8.185,18.246,18.246S142.761,180.61,132.7,180.61z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M398.099,92.696h-35.387c-4.581,0-8.294,3.712-8.294,8.294s3.712,8.294,8.294,8.294h35.387 c4.581,0,8.294-3.712,8.294-8.294S402.681,92.696,398.099,92.696z"></path>{' '}
                    </g>{' '}
                </g>{' '}
                <g>
                    {' '}
                    <g>
                        {' '}
                        <path d="M398.099,216.549h-35.387c-4.581,0-8.294,3.712-8.294,8.294s3.712,8.294,8.294,8.294h35.387 c4.581,0,8.294-3.712,8.294-8.294S402.681,216.549,398.099,216.549z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M150.393,92.696h-35.387c-4.581,0-8.294,3.712-8.294,8.294s3.712,8.294,8.294,8.294h35.387 c4.581,0,8.294-3.712,8.294-8.294S154.975,92.696,150.393,92.696z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M150.393,216.549h-35.387c-4.581,0-8.294,3.712-8.294,8.294s3.712,8.294,8.294,8.294h35.387 c4.581,0,8.294-3.712,8.294-8.294S154.975,216.549,150.393,216.549z" />
                    </g>
                </g>
            </g>
        </svg>
    );
};
