import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import LazyLoading from '../LazyLoading';
import Paginator from './Paginator';
import '../../assets/scss/custom/components/_datatable.scss';
import { customInterspersedStyles, customStyles } from '../../utils/dataTableStyles';
import { useScreenDetector } from '../../hooks/useScreenDetector';

interface DefaultDataTableProps {
    title?: string;
    columns: any[];
    data: any[];
    paginationTotalRows: number;
    progressPending?: boolean;
    filter: any;
    selectableRows?: boolean;
    selectableRowsHighlight?: boolean;
    onSelectedRowsChange?: (state: any) => void;
    setFilter: (filter: any) => void;
    conditionalRowStyles?: any;
    isMobileStacked?: boolean;
    isMobileIgnoreColumns?: string[];
    styleTable?: any;
    pagination?: boolean;
    contextActions?: any;
    resetContextActions?: () => void;
    contextMessage?: any;
    contextComponent?: any;
    clearSelectedRows?: boolean;
    selectableRowsComponentProps?: any;
    selectableRowSelected?: any;
    selectableRowsNoSelectAll?: boolean;
    renderCustomMobileCards?: (data: any[], columns: any[]) => any;
}

const paginationRowsPerPageOptions: any = [10, 20, 30, 50, 100];

const DefaultDataTable = ({
    title = '',
    columns,
    data,
    paginationTotalRows,
    progressPending,
    filter,
    setFilter,
    selectableRows,
    selectableRowsHighlight,
    onSelectedRowsChange,
    conditionalRowStyles = undefined,
    isMobileStacked = false,
    isMobileIgnoreColumns = [],
    styleTable = undefined,
    pagination,
    clearSelectedRows,
    contextActions,
    resetContextActions,
    contextMessage,
    contextComponent,
    selectableRowsComponentProps,
    selectableRowsNoSelectAll,
    selectableRowSelected,
    renderCustomMobileCards
}: DefaultDataTableProps) => {
    // const [perPage, setPerPage] = useState(10);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [sort, setSort] = useState('id');
    // const [order, setOrder] = useState('asc');

    if (pagination !== false) {
        pagination = true;
    }

    const handlePageChange = (page: number, totalRows: number) => {
        // setCurrentPage(page);
        if (resetContextActions) resetContextActions();
        setFilter({ ...filter, ['page']: page });
    };
    const { isMobile, isTablet, isDesktop } = useScreenDetector();

    const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
        // setPerPage(newPerPage);
        if (resetContextActions) resetContextActions();
        setFilter({ ...filter, ['page']: currentPage, ['per_page']: newPerPage });
    };

    const handleSort = async (column: any, sortDirection: string) => {
        /// reach out to some API and get new data using or sortField and sortDirection
        // e.g. https://api.github.com/search/repositories?q=blog&sort=${column.sortField}&order=${sortDirection}

        setTimeout(() => {
            setFilter({
                ...filter,
                ['order']: sortDirection,
                ['sort']: column.sortField,
                ['page']: 1
            });
            // setSort(column);
            // setOrder(sortDirection);
        }, 100);
    };
    const renderMobileCards = () => (
        <div>
            {data.map((row, index) => (
                <div
                    key={index}
                    style={{
                        margin: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        padding: '10px'
                    }}
                >
                    {columns.map((column) => {
                        // Verificamos si la columna actual debe ser ignorada en móviles
                        if (!isMobileIgnoreColumns.includes(column.name)) {
                            return (
                                <div key={column.selector} className="row">
                                    <div className="col">
                                        <strong>{column.name}: </strong>
                                        {column.cell ? column.cell(row) : row[column.selector]}
                                    </div>
                                </div>
                            );
                        }
                        // Si la columna debe ser ignorada, no retornamos nada
                        return null;
                    })}
                </div>
            ))}
        </div>
    );

    return (
        <>
            {isMobile && isMobileStacked ? (
                <>
                    {
                    renderCustomMobileCards ? renderCustomMobileCards(data, columns) :
                            renderMobileCards()}

                    <Paginator
                        isMobile={true}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        currentPage={filter.page}
                        rowCount={paginationTotalRows}
                        rowsPerPage={filter.per_page}
                    />
                </>
            ) : (
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                    sortServer
                    onSort={handleSort}
                    progressPending={progressPending}
                    progressComponent={<LazyLoading height={300} />}
                    pagination={pagination}
                    paginationServer
                    paginationTotalRows={paginationTotalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationComponent={Paginator}
                    paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                    noDataComponent="No hay registros disponibles"
                    selectableRows={selectableRows}
                    selectableRowsHighlight={selectableRowsHighlight}
                    customStyles={styleTable ? styleTable : customInterspersedStyles}
                    defaultSortAsc={false}
                    conditionalRowStyles={conditionalRowStyles}
                    clearSelectedRows={clearSelectedRows}
                    contextActions={contextActions}
                    contextMessage={contextMessage}
                    contextComponent={contextComponent}
                    onSelectedRowsChange={onSelectedRowsChange}
                    selectableRowsComponentProps={selectableRowsComponentProps}
                    selectableRowsNoSelectAll={selectableRowsNoSelectAll}
                    selectableRowSelected={selectableRowSelected}

                    // fixedHeader={true}
                    // fixedHeaderScrollHeight={'400px'}
                />
            )}
        </>
    );
};

export default DefaultDataTable;
