import React from 'react';

interface Props {
    updateMode: string;
    update: (mode: OptionType) => void;
    selectedDate: string;
}

type OptionType = 'all' | 'next';

const WorkerShiftWorkUpdateOptionForm = ({ update, updateMode, selectedDate }: Props) => {
    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value === 'all' || value === 'next') {
            update(value);
        }
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <div className="my-2 row">
                    <div className="col-12 my-2">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="all"
                                checked={updateMode === 'all'}
                                id="all"
                                onChange={handleCheckChange}
                            />
                            <label className="form-check-label" htmlFor="is_shiftwork">
                                Modificar toda la jornada
                            </label>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="next"
                                checked={updateMode === 'next'}
                                id="next"
                                onChange={handleCheckChange}
                            />
                            <label className="form-check-label" htmlFor="next">
                                Modificar a partir de la fecha {selectedDate}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WorkerShiftWorkUpdateOptionForm;
