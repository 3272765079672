import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import { EventType, EventTypeFilters } from '../../../../app/models/EventType';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import EventTypeSwitchChange from '../EventTypeSwitchChange';
import EventTypeFilter from './EventTypeFilter';

interface Props {
    eventTypes: EventType[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number, name: string) => void;
    filter: EventTypeFilters;
    setFilter: (filter: EventTypeFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const EventTypeDataTable = ({
    eventTypes,
    totalRows,
    loading = false,
    edit,
    destroy,
    active,
    show,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'Nombre',
            selector: (row: any) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: EventType, index: any, column: any) => <span className="">{row.name}</span>
        },
        {
            name: 'Color',
            selector: (row: any) => row.color,
            sortable: true,
            sortField: 'color',
            cell: (row: EventType, index: any, column: any) => (
                <span className="">
                    <button
                        disabled
                        style={{
                            background: row.color,
                            padding: '1rem 2.4rem',
                            border: 'solid 0.08rem #CED4DA',
                            borderRadius: '0.3rem'
                        }}
                    ></button>
                </span>
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: EventType, index: any, column: any) => (
                <EventTypeSwitchChange
                    checkValue={row.is_active ?? true}
                    eventTypeId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: EventType, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1, row.name)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <EventTypeFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={eventTypes}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default EventTypeDataTable;
