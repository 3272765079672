import React, { useContext } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';

import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import ActiveControl from '../../../components/ActiveControl';
import useProvisionService from '../../../app/services/hooks/useProvisionService';

interface Props {
    provisionId: number;
    checkValue: boolean;
    callbackSuccess: () => void;
}

const ProvisionSwitchChange = ({ provisionId, checkValue, callbackSuccess }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [checked, setChecked] = React.useState<boolean>(checkValue);

    const { activeProvision, fetchingActiveProvision } = useProvisionService();

    const active = (value: boolean) => {
        setChecked(value);

        activeProvision(provisionId, value, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                callbackSuccess();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setChecked(!value);
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return <ActiveControl status={checked} id={provisionId} callback={active} />;
};

export default ProvisionSwitchChange;
