import { Provider, ProviderFilters } from '../../../app/models/Provider';
import ButtonTableAction from '../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../components/default/DefaultDataTable';
import ProviderFilter from './ProviderFilter';
import ProviderSwitchChange from './ProviderSwitchChange';

interface Props {
    providers: Provider[];
    totalRows: number;
    loading?: boolean;
    edit?: (providerId: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (providerId: number) => void;
    destroy?: (providerId: number) => void;
    filter: ProviderFilters;
    setFilter: (filter: ProviderFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const ProviderDataTable = ({
    providers,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    active,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: Provider, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    {row.id}
                </span>
            )
        },
        {
            name: 'Rut',
            selector: (row: any) => row.rut,
            sortable: true,
            sortField: 'rut',
            cell: (row: Provider, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    {row.rut}
                </span>
            )
        },
        {
            name: 'Nombre',
            selector: (row: any) => row.business_name,
            sortable: true,
            sortField: 'business_name',
            cell: (row: Provider, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    {row.business_name}
                </span>
            )
        },
        {
            name: 'Nombre fantasía',
            selector: (row: any) => row.fantasy_name,
            sortable: true,
            sortField: 'fantasy_name',
            cell: (row: Provider, index: any, column: any) => (
                <span className="">
                    {' '}
                    {/* Agrega la clase de Bootstrap que desees */}
                    {row.fantasy_name}
                </span>
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row?.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: Provider, index: any, column: any) => (
                <ProviderSwitchChange
                    checkValue={row.is_active ?? true}
                    providerId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: Provider, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <ProviderFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>
            <DefaultDataTable
                columns={columns}
                data={providers}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default ProviderDataTable;
