import React, { useContext, useEffect, useState } from 'react';
import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import {
    CalendarRequestDataForm,
    defaultCalendarRequestDataForm
} from '../../../../app/models/CalendarRequest';
import CalendarRequestForm from '../forms/CalendarRequestForm';
import useCalendarRequestService from '../../../../app/services/hooks/useCalendarRequestService';
import { EventType } from '../../../../app/models/EventType';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { AppContext } from '../../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import LazyLoading from '../../../../components/LazyLoading';
import { Worker } from '../../../../app/models/Workers';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    workerId?: number;
    showWorkerField?: boolean;
}

const CalendarRequestFormContainer = ({
    onSaved,
    onCancel,
    onError,
    workerId,
    showWorkerField
}: Props) => {
    const [calendarRequest, setCalendarRequest] = useState<CalendarRequestDataForm>({
        ...defaultCalendarRequestDataForm
    });
    const [eventTypes, setEventTypes] = useState<EventType[]>([]);
    const [workers, setWorkers] = useState<Worker[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const { storeCalendarRequest, createCalendarRequest, fetchingCreateCalendarRequest } =
        useCalendarRequestService();
    const { showLoading, hideLoading } = useContext(AppContext);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createCalendarRequest({
            onSuccess: (response: ServiceResponse) => {
                setEventTypes(response.data.event_types);
                setWorkers(response.data.worker);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {
        if (workerId) {
            setCalendarRequest({ ...calendarRequest, worker_id: workerId });
        }
    }, [workerId]);

    const store = () => {
        if (showLoading) showLoading('loading', 'Cargando solicitud...');
        storeCalendarRequest(calendarRequest, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateCalendarRequest ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <CalendarRequestForm
                calendarRequest={calendarRequest}
                setCalendarRequest={setCalendarRequest}
                eventTypes={eventTypes}
                workers={workers}
                errorFields={errorFields}
                showWorkerField={showWorkerField}
            />
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} />
                </div>
            </div>
        </>
    );
};

export default CalendarRequestFormContainer;
