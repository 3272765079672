import React from 'react';
import { ContractFilters } from '../../../../app/models/Contract';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: any;
    setFilter: (filter: any) => void;
    onSearch: () => void;
};

const ContractFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(filter);
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };
    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="row">
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1">Trabajador</label>
                            <input
                                title="Nombre del trabajador"
                                type="text"
                                id="worker"
                                name="worker"
                                className="form-control form-control-sm"
                                value={filter.worker}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1">Indefinido</label>
                            <select
                                title="¿Es indefinido?"
                                name="is_indefinite"
                                className="form-control form-control-sm"
                                value={filter.is_indefinite}
                                onChange={(e) => handlerSelectFilter(e)}
                            >
                                <option key={''} value="">
                                    Todos
                                </option>
                                <option value="true">Indefinidos</option>
                                <option value="false">Temporales</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-auto'>
                        <div style={{marginTop: '22px'}}>
                            <ButtonSearchFilter callbackSearch={onSearch}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContractFilter;
