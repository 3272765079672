import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';

import { TollRateDataForm, TollRateFilters } from '../../../../app/models/TollRate';
import { formatMoney } from '../../../../utils/utils';

interface Props {
    tollRates: TollRateDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: TollRateFilters;
    setFilter: (filter: TollRateFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const TollRateDataTable = ({
    tollRates,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: TollRateDataForm, index: any, column: any) => (
                <span className="">{row?.id}</span>
            )
        },
        {
            name: 'Tipo vehículo',
            selector: (row: any) => row?.vehicle_type,
            sortable: true,
            sortField: 'vehicle_type',
            cell: (row: TollRateDataForm, index: any, column: any) => (
                <span className="">{row.vehicle_type}</span>
            )
        },
        {
            name: 'Valor',
            selector: (row: any) => row?.amount,
            sortable: true,
            sortField: 'amount',
            cell: (row: TollRateDataForm, index: any, column: any) => (
                <span className="">{formatMoney(row.amount ?? 0, row.currency)}</span>
            )
        },
        {
            name: 'Moneda',
            selector: (row: any) => row?.currency,
            sortable: true,
            sortField: 'currency',
            cell: (row: TollRateDataForm, index: any, column: any) => (
                <span className="">{row.currency}</span>
            )
        },
        {
            name: 'Horario inicio',
            selector: (row: any) => row?.start_time,
            sortable: true,
            sortField: 'start_time',
            cell: (row: TollRateDataForm, index: any, column: any) => (
                <span className="">{row.start_time}</span>
            )
        },
        {
            name: 'Horario fin',
            selector: (row: any) => row?.end_time,
            sortable: true,
            sortField: 'end_time',
            cell: (row: TollRateDataForm, index: any, column: any) => (
                <span className="">{row.end_time}</span>
            )
        },
        {
            name: 'Válido desde',
            selector: (row: any) => row?.valid_from,
            sortable: true,
            sortField: 'valid_from',
            cell: (row: TollRateDataForm, index: any, column: any) => (
                <span className="">{row.valid_from}</span>
            )
        },

        {
            name: 'Válido hasta',
            selector: (row: any) => row?.valid_until,
            sortable: true,
            sortField: 'valid_until',
            cell: (row: TollRateDataForm, index: any, column: any) => (
                <span className="">{row.valid_until}</span>
            )
        },

        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: TollRateDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    {/*<CarrierFilter filter={filter} setFilter={setFilter} />*/}
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={tollRates}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default TollRateDataTable;
