import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { WorkerDocument } from '../../../app/models/Workers';
import useWorkerDocumentService from '../../../app/services/hooks/useWorkerDocumentService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import DocumentsHistoryDataTable from '../../../components/DocumentHistoryDataTable';

interface Props {
    onCancel?: () => void;
    onError?: () => void;
    workerId: number;
    typeDocument: number;
}

const WorkerDocumentHistory = ({ onCancel, onError, workerId, typeDocument }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const [workerDocuments, setWorkerDocuments] = useState<WorkerDocument[]>([]);
    const {
        getWorkerDocumentHistoryByWorkerAndDocumentType,
        fetchingGetWorkerDocumentHistoryByWrokerAndDocumentType
    } = useWorkerDocumentService();

    const getDocumentsHistory = (workerId: number, typeDocument: number) => {
        getWorkerDocumentHistoryByWorkerAndDocumentType(workerId, typeDocument, {
            onSuccess: (response: ServiceResponse) => {
                setWorkerDocuments(response.data.worker_documents);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        getDocumentsHistory(workerId, typeDocument);
    }, []);

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingGetWorkerDocumentHistoryByWrokerAndDocumentType ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    {workerDocuments && <DocumentsHistoryDataTable data={workerDocuments} />}
                </div>
            </div>
        </>
    );
};

export default WorkerDocumentHistory;
