import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import {
    TravelExpense,
    TravelExpenseDataForm,
    TravelExpenseFilters
} from '../../models/TravelExpense';
import { EndPoints } from '../EndPoints';

const useTravelExpenseService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();
    const [fetchingGetTravelExpenses, setFetchingGetTravelExpenses] = useState(false);
    const [fetchingCreateTravelExpenses, setFetchingCreateTravelExpenses] = useState(false);
    const [fetchingStoreTravelExpenses, setFetchingStoreTravelExpenses] = useState(false);
    const [fetchingEditTravelExpenses, setFetchingEditTravelExpenses] = useState(false);
    const [fetchingUpdateTravelExpenses, setFetchingUpdateTravelExpenses] = useState(false);
    const [fetchingActiveTravelExpenses, setFetchingActiveTravelExpenses] = useState(false);
    const [fetchingDeleteTravelExpenses, setFecthingDeleteTravelExpenses] = useState(false);

    const getTravelExpenses = (
        TravelExpensesFilter: TravelExpenseFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(TravelExpensesFilter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.TRAVEL_EXPENSES.GET_TRAVEL_EXPENSES}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetTravelExpenses
        });
    };

    const storeTravelExpense = (
        TravelExpense: TravelExpenseDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.TRAVEL_EXPENSES.STORE_TRAVEL_EXPENSES,
            body: TravelExpense,
            setFetching: setFetchingStoreTravelExpenses
        });
    };

    const createTravelExpense = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRAVEL_EXPENSES.CREATE_TRAVEL_EXPENSES,
            setFetching: setFetchingCreateTravelExpenses
        });
    };

    const deleteTravelExpense = (travelExpenseId: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.TRAVEL_EXPENSES.DELETE_TRAVEL_EXPENSES.replace(
                ':id',
                travelExpenseId.toString()
            ),
            setFetching: setFecthingDeleteTravelExpenses
        });
    };

    const activeTravelExpense = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TRAVEL_EXPENSES.ACTIVE_TRAVEL_EXPENSES.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveTravelExpenses
        });
    };

    const editTravelExpense = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRAVEL_EXPENSES.EDIT_TRAVEL_EXPENSES.replace(':id', id.toString()),
            setFetching: setFetchingEditTravelExpenses
        });
    };

    const updateTravelExpense = (
        id: number,
        TravelExpenses: TravelExpenseDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.TRAVEL_EXPENSES.UPDATE_TRAVEL_EXPENSES.replace(':id', id.toString()),
            body: TravelExpenses,
            setFetching: setFetchingUpdateTravelExpenses
        });
    };

    return {
        fetchingGetTravelExpenses,
        fetchingCreateTravelExpenses,
        fetchingDeleteTravelExpenses,
        fetchingStoreTravelExpenses,
        fetchingEditTravelExpenses,
        fetchingActiveTravelExpenses,
        fetchingUpdateTravelExpenses,
        getTravelExpenses,
        createTravelExpense,
        deleteTravelExpense,
        storeTravelExpense,
        editTravelExpense,
        activeTravelExpense,
        updateTravelExpense
    };
};

export default useTravelExpenseService;
