import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { formatNumberWithDots } from '../../../../helpers';
import { DeductionRateDataForm, DeductionRateFilters } from '../../../../app/models/DeductionRate';

interface Props {
    deductionRates: DeductionRateDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: DeductionRateFilters;
    setFilter: (filter: DeductionRateFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const DeductionRateDataTable = ({
    deductionRates,
    totalRows,
    loading = false,
    edit,
    active,
    show,
    destroy,
    filter,
    setFilter,
}: Props) => {
    const columns = [
        {
            name: 'Valor',
            selector: (row: any) => row.value,
            sortable: true,
            sortField: 'value',
            cell: (row: any, index: any, column: any) => {
                return (
                    <>
                        {row.deduction_type === "Fijo" ? (
                            <span className="">{formatNumberWithDots(row.value) + ' CLP'}</span>
                        ) : row.deduction_type === "Porcentaje" ? (
                            <span className="">
                                {row.value}
                            </span>
                        ) : null}
                    </>
                );
            }
        },
        {
            name: 'Tipo de deducción',
            selector: (row: any) => row.deduction_type,
            sortable: true,
            sortField: 'deduction_type',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.deduction_type}</span>
            )
        },
        {
            name: 'Fecha de inicio',
            selector: (row: any) => row.valid_from,
            sortable: true,
            sortField: 'valid_from',
            cell: (row: any, index: any, column: any) => <span className="">{row.valid_from}</span>
        },
        {
            name: 'Fecha de fin',
            selector: (row: any) => row.valid_until,
            sortable: true,
            sortField: 'valid_until',
            cell: (row: any, index: any, column: any) => <span className="">{row.valid_until}</span>
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <div>
            <DefaultDataTable
                columns={columns}
                data={deductionRates}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </div>
    );
};

export default DeductionRateDataTable;
