import React from 'react';
import { momentParseDate } from '../../../../helpers';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { IncidentDataForm, IncidentFilters } from '../../../../app/models/Incident';
import ChangeStatusIncident from '../IncidentChangeStatus';
import { IncidentStatus } from '../../../../app/models/IncidentStatus';
import moment from 'moment';
// import ChangeStatusIncident from '../IncidentChangeStatus';

interface Props {
    incidents: IncidentDataForm[];
    statuses: IncidentStatus[];
    callbackSuccess?: () => void;
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: IncidentFilters;
    setFilter: (filter: IncidentFilters) => void;
    downloadEvidence: (id: number) => void;
    show: (id: number) => void;

}

const IncidentDataTable = ({
    incidents,
    totalRows,
    statuses,
    callbackSuccess,
    loading = false,
    edit,
    show,
    destroy,
    downloadEvidence,
    filter,
    setFilter,
}: Props) => {


    const columns = [
        {
            name: 'Id',
            selector: (row: IncidentDataForm) => row?.id,
            sortable: true,
            sortField: 'id',
        },
        {
            name: 'Motivo',
            selector: (row: IncidentDataForm) => row?.reason,
            sortable: true,
            sortField: 'reason',
        },
        {
            name: 'Fecha',
            selector: (row: IncidentDataForm) => momentParseDate(row?.incident_date, 'YYYY-MM-DD'),
            sortable: true,
            sortField: 'incident_date',
        },

        {
            name: 'Hora',
            selector: (row: IncidentDataForm) => moment(row?.incident_hour, 'HH:mm:ss').format('HH:mm'),
            sortable: true,
            sortField: 'incident_hour',
        },

        {
            name: 'Solución',
            selector: (row: IncidentDataForm) => row?.solution,
            sortable: true,
            sortField: 'solution',
        },

        {
            name: 'Estado',
            selector: (row: any) => row.status,
            sortable: true,
            sortField: 'status',
            cell: (row: IncidentDataForm, index: any, column: any) => {
                const status = statuses.find((incidentStatus) => incidentStatus.id === row.status);
                if (statuses.length == 0) {
                    return '';
                }
                return (
                    <ChangeStatusIncident
                        callbackSuccess={callbackSuccess}
                        statuses={row.status_choices! ?? []}
                        selectedStatus={status!}
                        incidentId={row.id!}
                    />
                );
            }
        },
        {
            name: 'Acciones',
            selector: (row: IncidentDataForm) => row?.id,
            cell: (row: IncidentDataForm) => (
                <div>
                    {downloadEvidence && (
                        <ButtonTableAction
                            callbackFunction={() => downloadEvidence(row?.id ?? -1)}
                            classIcon={'mdi mdi-download'}
                            colorIcon={row.evidence ? 'text-success' : 'text-secondary'}
                            locked={!row.evidence}
                            errorMessage={'No se puede descargar archivo evidencia.'}
                            title={'Descargar archivo evidencia'}
                        />
                    )}
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? 0)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? 0)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            ),
        },
    ];

    return (
        <DefaultDataTable
            columns={columns}
            data={incidents}
            progressPending={loading}
            paginationTotalRows={totalRows}
            filter={filter}
            setFilter={setFilter}
        />
    );
};

export default IncidentDataTable;
