import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useBankService from '../../../app/services/hooks/useBankService';
import { Bank } from '../../../app/models/Bank';
import BankFormContainer from './BankFormContainer';

interface Props {
    bankId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const BankEdit = ({ bankId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditBank, editBank, fetchingUpdateBank, updateBank } = useBankService();

    const [bank, setBank] = useState<Bank>({ name: '' });
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando banco...');
        editBank(bankId, {
            onSuccess: (response: ServiceResponse) => {
                setBank(response.data.bank);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando banco...');
        updateBank(bankId, bank, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditBank ? (
        <LazyLoading height="300" />
    ) : (
        <BankFormContainer
            fetching={fetchingUpdateBank}
            action={update}
            cancel={cancel}
            bank={bank}
            setBank={setBank}
            errorFields={errorFields}
        />
    );
};

export default BankEdit;
