import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { ServiceEvents } from '../shared/interfaces';
import { LOCAL_STORAGE } from '../../../config';
import { UserDataForm, UserPasswordDataForm } from '../../models/User';

type Credentials = {
    username: string;
    password: string;
};

const useAuthService = () => {
    const { doGet, doPost, doPut } = useFetch();

    const [fetchingLoginAuth, setFetchingLoginAuth] = useState(false);
    const [fetchingLogoutAuth, setFetchingLogoutAuth] = useState(false);
    const [fetchingValidateTokenAuth, setFetchingValidateTokenAuth] = useState(false);
    const [fetchingGetRoles, setFetchingGetRoles] = useState(false);
    const [fetchingSendCodeRecoveryPassword, setFetchingSendCodeRecoveryPassword] = useState(false);
    const [fetchingChangePasswordByCode, setFetchingChangePasswordByCode] = useState(false);
    const [fetchingGetProfile, setFetchingGetProfile] = useState(false);
    const [fetchingUpdateProfile, setFetchingUpdateProfile] = useState(false);
    const [fetchingUpdateProfilePassword, setFetchingUpdateProfilePassword] = useState(false);

    const loginAuth = (credentials: Credentials, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            headers: {
                Authorization: ''
            },
            url: EndPoints.AUTH.LOGIN,
            body: credentials,
            setFetching: setFetchingLoginAuth
        });
    };

    const logoutAuth = (events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.AUTH.LOGOUT,
            setFetching: setFetchingLogoutAuth
        });
    };

    const validateTokenAuth = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.AUTH.VALIDATE_TOKEN,
            setFetching: setFetchingValidateTokenAuth
        });
    };

    const getRoles = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.AUTH.GET_ROLES,
            setFetching: setFetchingGetRoles
        });
    };

    const sendCodeRecoveryPassword = (email: string, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.AUTH.SEND_CODE_RECOVERY_PASSWORD,
            headers: {
                Authorization: ''
            },
            body: { email: email },
            setFetching: setFetchingSendCodeRecoveryPassword
        });
    };

    const changePasswordByCode = (
        code: string,
        password: string,
        repeat_password: string,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.AUTH.CHANGE_PASSWORD_BY_CODE,
            headers: {
                Authorization: ''
            },
            body: {
                code_recovery_password: code,
                password: password,
                repeat_password: repeat_password
            },
            setFetching: setFetchingChangePasswordByCode
        });
    };

    const getProfile = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.AUTH.GET_PROFILE,
            setFetching: setFetchingGetProfile
        });
    };

    const updateProfile = (userProfile: UserDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.AUTH.UPDATE_PROFILE,
            body: userProfile,
            setFetching: setFetchingUpdateProfile
        });
    };

    const updateProfilePassword = (
        userProfile: UserPasswordDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.AUTH.UPDATE_PROFILE_PASSWORD,
            body: userProfile,
            setFetching: setFetchingUpdateProfilePassword
        });
    };

    return {
        loginAuth,
        logoutAuth,
        validateTokenAuth,
        getRoles,
        sendCodeRecoveryPassword,
        changePasswordByCode,
        fetchingGetProfile,
        fetchingUpdateProfile,
        fetchingChangePasswordByCode,
        fetchingSendCodeRecoveryPassword,
        fetchingLoginAuth,
        fetchingGetRoles,
        fetchingLogoutAuth,
        fetchingValidateTokenAuth,
        fetchingUpdateProfilePassword,
        getProfile,
        updateProfile,
        updateProfilePassword
    };
};

export default useAuthService;
