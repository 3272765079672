import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import { LoadTicketDataForm } from '../../../../app/models/LoadTicket';
import { Unit } from '../../../../app/models/Unit';
import CustomInput from '../../../../components/inputs/CustomInput';
import { processFile } from '../../../../utils/utils';

interface GeneralExpenseProps {
    loadTicketForm: LoadTicketDataForm;
    setLoadTicketForm: (expense: LoadTicketDataForm) => void;
    units: Unit[];
    errorFields?: any;
}

const loadTicketForm = ({
    loadTicketForm,
    setLoadTicketForm,
    units,
    errorFields
}: GeneralExpenseProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, errors, setErrors } =
        useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = event.target;

        setLoadTicketForm({ ...loadTicketForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setLoadTicketForm({ ...loadTicketForm, ...obj });
    };

    const handleChangeDocumentFile = async (e: FileList | null, type: string) => {
        if (e) {
            const file = e[0];
            // const fileExtension = file.name.split('.').pop()?.toLowerCase();
            // Por problemas se dejo solo el processFile solo para imagenes Heic que al parecer aca no hay drama
            // if (fileExtension === 'heic' || file.type === 'image/heic') {
            //     const result = await processFile(file);
            //     if (result.error) {
            //         handleRemoveFile();
            //         setErrors({
            //             ...errors,
            //             file: [result.error]
            //         });
            //     } else {
            //         setLoadTicketForm({ ...loadTicketForm, ['file']: result.file });
            //     }
            // }else{
                setLoadTicketForm({ ...loadTicketForm, ['file']: file });
            // }
        }
    };

    const handleRemoveFile = () => {
        setLoadTicketForm({ ...loadTicketForm, ['file']: undefined });
        const fileInput = document.getElementById('file') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    if (!loadTicketForm) return null;

    return (
        <>
            {/*fecha de emision*/}

            <div className="mb-2 row">
                <label htmlFor="issue_date" className="col-md-3 col-form-label">
                    Fecha de emisión
                </label>
                <div className="col-9">
                    <input
                        type="date"
                        className={`form-control ${fieldHasError('issue_date')}`}
                        id="issue_date"
                        name="issue_date"
                        value={loadTicketForm.issue_date}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('issue_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('issue_date')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Cantidad Neta</label>
                <div className="col-md-9 ">
                    <div className="row g-1">
                        <div className="col-6">
                            <CustomInput
                                name={'quantity'}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('quantity')}
                                className={`form-control ${fieldHasError('quantity')}`}
                                value={loadTicketForm.quantity != 0 ? loadTicketForm.quantity : ''}
                                placeholder="Ingrese cantidad"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('quantity')}
                            </div>
                        </div>

                        <div className="col-6">
                            <SelectTwo
                                name="unit_id"
                                id="unit_id"
                                inputValue={loadTicketForm.unit_id}
                                options={SelectTwoMapperOptions(units)}
                                hasError={fieldHasError('unit_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('unit_id', value.value)
                                }
                                placeholder={'unidad'}
                                onFocus={() => onFocusRemove('unit_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('unit_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Foto</label>
                    <InputButtonFile
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        name={'file'}
                        objectFile={loadTicketForm?.file}
                        onFocus={() => onFocusRemove('file')}
                        handleRemoveFile={handleRemoveFile}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('file')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default loadTicketForm;
