import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useGuideService from '../../../app/services/hooks/useGuideService';
import { GuideHistoryDataForm } from '../../../app/models/GuideHistory';
import GuideHistoryForm from './forms/GuideHistoryForm';

interface Props {
    guideId: number;
    errorFields?: any;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    getApprovedGuide: (guideId: number, guideHistory: GuideHistoryDataForm) => void;
}

const GuideHistoryCreate = ({
    onSaved,
    onCancel,
    onError,
    errorFields,
    getApprovedGuide,
    guideId
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const { fetchingApprovedGuide, approvedGuide } = useGuideService();

    const [guideHistory, setGuideHistory] = useState<GuideHistoryDataForm>({
        comment: '',
        is_approved: false
    });

    const store = () => {
        getApprovedGuide(guideId, guideHistory);
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <GuideHistoryForm
                        guideHistoryDataForm={guideHistory}
                        setGuideHistoryDataForm={setGuideHistory}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingApprovedGuide} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingApprovedGuide} />
                </div>
            </div>
        </>
    );
};

export default GuideHistoryCreate;
