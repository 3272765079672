import { Provider } from './Provider';
import { ExpenseType } from './ExpenseType';
import { ExpenseDocumentType } from './ExpenseDocumentType';
import moment from 'moment';
import { Currency } from './Currency';
import { Toll } from './Toll';
import { PaymentMethod } from './PaymentMethod';

export interface TollExpense {
    id?: number;
    currency?: Currency;
    currency_id?: number;
    amount?: number;
    provider_id?: number;
    provider?: Provider;
    issue_date?: string;
    expense_document_type?: string;
    expense_document_type_selected?: ExpenseDocumentType;
    created_user_id?: number;
    name?: string;
    folio?: string;
    description?: string;
    is_reported?: boolean;
    toll?: Toll;
    toll_id?: number;
}

export interface TollExpenseDataForm {
    transport_order_id?: number;
    payment_method?: string;
    id?: number;
    expense_id?: number;
    currency?: Currency;
    carrier?: string;
    currency_id?: number;
    amount?: number | string;
    provider_id?: number;
    provider?: string;
    issue_date?: string;
    expense_document_type?: string;
    description?: string;
    folio?: string;
    is_reported?: boolean;

    photo?: File;
    photo_url?: string;
    toll?: string;
    toll_id?: number;
    is_approved?: boolean;
}

export const defaultTollExpenseDataForm: TollExpenseDataForm = {
    amount: '',
    provider_id: 0,
    issue_date: moment().format('YYYY-MM-dddd'),
    expense_document_type: '',
    currency_id: 0,
    folio: '',
    description: '',
    is_reported: true,
    toll_id: 0
};

export interface TollExpenseFilters {
    transport_order_id?: string | '';
    expense_id?: number | '';
    provider?: string | '';
    issue_date_first?: string | '';
    issue_date_last?: string | '';
    expense_document_type?: string | '';
    payment_methods?: PaymentMethod[];
    payment_method_list?: string[];
    carrier?: string | '';
    toll?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}
