import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { Provision, ProvisionFilters } from '../../models/Provision';

const useProvisionService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetProvisions, setFetchingGetProvisions] = useState(false);
    const [fetchingShowProvision, setFetchingShowProvision] = useState(false);
    const [fetchingStoreProvision, setFetchingStoreProvision] = useState(false);
    const [fetchingEditProvision, setFetchingEditProvision] = useState(false);
    const [fetchingUpdateProvision, setFetchingUpdateProvision] = useState(false);
    const [fetchingDeleteProvision, setFetchingDeleteProvision] = useState(false);
    const [fetchingActiveProvision, setFetchingActiveProvision] = useState(false);

    const getProvisions = (provisionFilters: ProvisionFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(provisionFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.PROVISIONS.GET_PROVISIONS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetProvisions
        });
    };

    const showProvision = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVISIONS.SHOW_PROVISION.replace(':id', id.toString()),
            setFetching: setFetchingShowProvision
        });
    };

    const storeProvision = (provision: Provision, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PROVISIONS.STORE_PROVISION,
            body: provision,
            setFetching: setFetchingStoreProvision
        });
    };

    const editProvision = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PROVISIONS.EDIT_PROVISION.replace(':id', id.toString()),
            setFetching: setFetchingEditProvision
        });
    };

    const updateProvision = (id: number, provision: Provision, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PROVISIONS.UPDATE_PROVISION.replace(':id', id.toString()),
            body: provision,
            setFetching: setFetchingUpdateProvision
        });
    };

    const deleteProvision = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.PROVISIONS.DELETE_PROVISION.replace(':id', id.toString()),
            setFetching: setFetchingDeleteProvision
        });
    };

    const activeProvision = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PROVISIONS.ACTIVE_PROVISION.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveProvision
        });
    };

    return {
        fetchingGetProvisions,
        fetchingShowProvision,
        fetchingStoreProvision,
        fetchingEditProvision,
        fetchingUpdateProvision,
        fetchingDeleteProvision,
        fetchingActiveProvision,
        getProvisions,
        showProvision,
        storeProvision,
        editProvision,
        updateProvision,
        deleteProvision,
        activeProvision
    };
};

export default useProvisionService;
