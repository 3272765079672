import React, { useContext, useEffect, useRef, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import { useHistory } from 'react-router-dom';

import { Currency } from '../../../app/models/Currency';
import { ExpenseDocumentType } from '../../../app/models/ExpenseDocumentType';
import { TollExpenseDataForm, defaultTollExpenseDataForm } from '../../../app/models/TollExpense';
import { Provider } from '../../../app/models/Provider';
import useTollExpenseService from '../../../app/services/hooks/useExpenseTollService';
import TollExpenseForm from './forms/TollExpenseForm';
import { Toll } from '../../../app/models/Toll';
import { PaymentMethod } from '../../../app/models/PaymentMethod';
import useTollRateService from '../../../app/services/hooks/useTollRateService';

interface Props {
    transportOrderId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TollExpenseCreate = ({ onSaved, onCancel, onError, transportOrderId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const {
        createTollExpense,
        storeTollExpense,
        fetchingCreateTollExpense,
        fetchingStoreTollExpense
    } = useTollExpenseService();

    const { fetchingGetTollRateByToll, getTollRateByToll } = useTollRateService();

    const [tollExpense, setTollExpense] = useState<TollExpenseDataForm>({
        ...defaultTollExpenseDataForm,
        transport_order_id: transportOrderId
    });
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

    const [providers, setProviders] = useState<Provider[]>([]);
    const [expenseDocumentTypes, setExpenseDocumentTypes] = useState<ExpenseDocumentType[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [tolls, setTolls] = useState<Toll[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    const currencyDefault = useRef<number | undefined>(0);
    const tollIdSelected = useRef<number | undefined>(0);

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createTollExpense(transportOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setProviders(response.data.providers);
                setTolls(response.data.tolls);
                setExpenseDocumentTypes(response.data.expense_document_types);
                setCurrencies(response.data.currencies);
                setPaymentMethods(response.data.payment_methods);
                const default_currency = response.data.currencies.find(
                    (currency: Currency) => currency.name == 'CLP'
                );
                if (default_currency) {
                    setTollExpense({ ...tollExpense, currency_id: default_currency.id });
                    currencyDefault.current = default_currency.id;
                }
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {
        if (tollExpense.toll_id == undefined) {
            tollIdSelected.current = undefined;
        }

        if (tollExpense.toll_id && tollExpense.toll_id != tollIdSelected.current) {
            getTollRateByToll(transportOrderId, tollExpense.toll_id, 0, {
                onSuccess: (response: ServiceResponse) => {
                    tollIdSelected.current = tollExpense.toll_id ?? 0;
                    setTollExpense({
                        ...tollExpense,
                        amount: response.data?.toll_rate?.amount ?? undefined,
                        currency_id:
                            response.data?.toll_rate?.currency_id ?? currencyDefault.current
                    });
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message);
                    setTollExpense({
                        ...tollExpense,
                        toll_id: tollIdSelected.current
                    });
                }
            });
        }
    }, [tollExpense.toll_id]);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando gasto de viaje...');
        storeTollExpense(transportOrderId, tollExpense, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateTollExpense ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <TollExpenseForm
                        paymentMethods={paymentMethods}
                        tollExpenseDataForm={tollExpense}
                        setTollExpenseForm={setTollExpense}
                        expenseDocumentTypes={expenseDocumentTypes}
                        tolls={tolls}
                        errorFields={errorFields}
                        providers={providers}
                        currencies={currencies}
                        fetching={fetchingGetTollRateByToll}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreTollExpense} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreTollExpense} />
                </div>
            </div>
        </>
    );
};

export default TollExpenseCreate;
