import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import { useHistory } from 'react-router-dom';

import { Currency } from '../../../app/models/Currency';
import useTollRateService from '../../../app/services/hooks/useTollRateService';
import { TollRateDataForm, defaultTollRateDataForm } from '../../../app/models/TollRate';
import { VehicleModel } from '../../../app/models/VehicleModel';
import TollRateForm from './forms/TollRateForm';
import { VehicleType } from '../../../app/models/VehicleType';

interface Props {
    tollId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TollRateCreate = ({ onSaved, onCancel, onError, tollId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const { createTollRate, storeTollRate, fetchingCreateTollRate, fetchingStoreTollRate } =
        useTollRateService();

    const [TollRate, setTollRate] = useState<TollRateDataForm>({
        ...defaultTollRateDataForm,
        toll_id: tollId
    });

    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createTollRate({
            onSuccess: (response: ServiceResponse) => {
                setVehicleTypes(response.data.vehicle_types);
                setCurrencies(response.data.currencies);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando tarifa de peaje...');
        storeTollRate(TollRate, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateTollRate ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <TollRateForm
                        tollRateForm={TollRate}
                        errorFields={errorFields}
                        setTollRateForm={setTollRate}
                        vehicleTypes={vehicleTypes}
                        currencies={currencies}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreTollRate} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreTollRate} />
                </div>
            </div>
        </>
    );
};

export default TollRateCreate;
