import moment from 'moment';

export interface AfpRateDataForm {
    id?: number;
    afp_id?: number;
    value?: number;
    value_type?: string;
    value_type_id?: number;
    valid_from?: string;
    valid_until?: string;
}

export interface AfpRateFilters {
    afp_rate_id?: number | null;

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface ValueType {
    id: number | string;
    name: number | string;
}

export const defaultAfpRateDataForm: AfpRateDataForm = {
    value_type: '',
    valid_from: moment().format('YYYY-MM-dddd'),
    valid_until: moment().format('YYYY-MM-dddd')
};
