import React, { useEffect } from 'react';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Society } from '../../../../app/models/Society';
import { Client } from '../../../../app/models/Client';
import { Currency } from '../../../../app/models/Currency';
import { PaymentCondition } from '../../../../app/models/PaymentCondition';
import { TransportationOrderDataForm } from '../../../../app/models/TransportationOrder';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { Unit } from '../../../../app/models/Unit';
import { TransportationQuoteDetail } from '../../../../app/models/TransportationQuoteDetail';

interface GeneralTransportationOrderFormProps {
    transportationOrderForm: TransportationOrderDataForm;
    setTransportationOrderForm: (transportationOrder: TransportationOrderDataForm) => void;
    societies: Society[];
    quotes: TransportationQuoteDetail[];
    clients: Client[];
    currencies: Currency[];
    quantityUnits: Unit[];
    paymentConditions: PaymentCondition[];
    paymentMethods: PaymentCondition[];
    errorFields?: any;
    isCreated?: boolean;
}

const TransportationOrderForm = ({
    transportationOrderForm,
    setTransportationOrderForm,
    societies,
    clients,
    paymentMethods,
    paymentConditions,
    currencies,
    quantityUnits,
    quotes,
    errorFields,

    isCreated = false
}: GeneralTransportationOrderFormProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setTransportationOrderForm({ ...transportationOrderForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setTransportationOrderForm({ ...transportationOrderForm, [name]: value });
    };

    const handleChangeSelectTwoClient = (name: string, value: number | string | null) => {
        setTransportationOrderForm({
            ...transportationOrderForm,
            [name]: value,
            contact_email: clients.find((client) => client.id == value)?.email_contact || ''
        });
    };

    if (!transportationOrderForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Oferta</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="quote_id"
                        id="quote_id"
                        inputValue={transportationOrderForm.transportation_quote_detail_id}
                        options={SelectTwoMapperOptions(quotes)}
                        hasError={fieldHasError('transportation_quote_detail_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('transportation_quote_detail_id', value.value)
                        }
                        placeholder={'Seleccione número de posicion de oferta'}
                        onFocus={() => onFocusRemove('transportation_quote_detail_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('transportation_quote_detail_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Sociedad</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="society_id"
                        id="society_id"
                        inputValue={transportationOrderForm.society_id}
                        options={SelectTwoMapperOptions(societies, 'id', ['rut', 'business_name'])}
                        hasError={fieldHasError('society_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('society_id', value.value)}
                        placeholder={'Seleccione una sociedad'}
                        onFocus={() => onFocusRemove('society_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('society_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Cliente</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="client_id"
                        id="client_id"
                        inputValue={transportationOrderForm.client_id}
                        options={SelectTwoMapperOptions(clients, 'id', ['rut', 'business_name'])}
                        hasError={fieldHasError('client_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwoClient('client_id', value.value)
                        }
                        placeholder={'Seleccione un cliente'}
                        onFocus={() => onFocusRemove('client_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('client_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Contacto</label>
                <div className="col-md-9">
                    <input
                        name="contact_email"
                        id="contact_email"
                        type="email"
                        className={`form-control ${fieldHasError('contact_email')}`}
                        value={transportationOrderForm.contact_email}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('contact_email')}
                        placeholder="Ingrese un correo de contacto"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('contact_email')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Forma de Pago</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="payment_method_id"
                        id="payment_method_id"
                        inputValue={transportationOrderForm.payment_method_id}
                        options={SelectTwoMapperOptions(paymentMethods)}
                        hasError={fieldHasError('payment_method_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('payment_method_id', value.value)
                        }
                        onFocus={() => onFocusRemove('payment_method_id')}
                        placeholder={'Seleccione una forma de pago'}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('payment_method_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Condición de pago</label>
                <div className="col-md-9 ">
                    <div className="row g-1">
                        <div className="col-9">
                            <SelectTwo
                                name="payment_condition_id"
                                id="payment_condition_id"
                                inputValue={transportationOrderForm.payment_condition_id}
                                hasError={fieldHasError('payment_condition_id') !== ''}
                                options={SelectTwoMapperOptions(paymentConditions)}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('payment_condition_id', value.value)
                                }
                                onFocus={() => onFocusRemove('payment_condition_id')}
                                placeholder={'Seleccione una condición de pago'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('payment_condition_id')}
                            </div>
                        </div>

                        <div className="col-3">
                            <input
                                name="days_count"
                                id="days_count"
                                type="number"
                                className={`form-control ${fieldHasError('days_count')}`}
                                value={transportationOrderForm.days_count}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('days_count')}
                                placeholder="días"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('days_count')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Moneda</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="currency_id"
                        id="currency_id"
                        inputValue={transportationOrderForm.currency_id}
                        hasError={fieldHasError('currency_id') !== ''}
                        options={SelectTwoMapperOptions(currencies)}
                        onChange={(value: any) => handleChangeSelectTwo('currency_id', value.value)}
                        onFocus={() => onFocusRemove('currency_id')}
                        placeholder={'Seleccione una Moneda'}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('currency_id')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransportationOrderForm;
