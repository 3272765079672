import { Bonus } from './Bonus';
import { Deduction } from './Deduction';
import { JobTitle } from './JobTitle';

export interface JobTitleLiquidationSet {
    id?: number;
    deductions?: Deduction[];
    bonuses?: Bonus[];
    job_title: JobTitle;
    is_active: boolean;
}

export interface JobTitleLiquidationSetDataForm {
    id?: number;
    deductions?: Deduction[];
    job_title_id?: number;
    bonuses?: Bonus[];
    job_title?: JobTitle;
    is_active?: boolean;
}

export interface JobTitleLiquidationSetFilters {
    id?: number | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultJobTitleLiquidationSetDataForm: JobTitleLiquidationSetDataForm = {
    is_active: true,
    deductions: [],
    bonuses: []
};
