import React, { useState, useMemo } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'globalize/lib/cultures/globalize.culture.es';
import '../../assets/css/defaultcalendar.css';
import moment from 'moment';
import 'moment/locale/es';
import { CalendarEvent } from '../../app/models/Event';

moment.locale('es');
const localizer = momentLocalizer(moment);

interface Props {
    events?: CalendarEvent[];
    setDate: (date: string) => void;
    setView: (view: string) => void;
    selectable?: boolean;
    onSelectSlot?: (slotInfo: any) => any;
    onSelectEvent?: (event: any) => any;
}

const DefaultCalendar = ({
    events,
    setDate,
    setView,
    selectable,
    onSelectSlot,
    onSelectEvent
}: Props) => {
    const handleOnNavigate = (newDate: any) => {
        setDate(newDate);
    };

    const handleOnView = (newView: string) => {
        setView(newView);
    };

    const handleOnSelectEvent = (event: any) => {
        if (onSelectEvent) onSelectEvent(event);
    };

    return (
        <div
            style={
                window.innerWidth > 412
                    ? {
                          height: '900px',
                          width: 'auto'
                      }
                    : {
                          height: '450px',
                          width: 'auto'
                      }
            }
        >
            <Calendar
                localizer={localizer}
                events={events}
                views={
                    {
                        month: true,
                        week: true,
                        day: true,
                        agenda: false
                    } as any
                }
                defaultDate={new Date()}
                messages={
                    {
                        week: 'Semana',
                        day: 'Día',
                        month: 'Mes',
                        previous: 'Anterior',
                        next: 'Siguiente',
                        today: 'Hoy',
                        year: 'Año'
                    } as any
                }
                defaultView="month"
                onNavigate={handleOnNavigate}
                onView={handleOnView}
                formats={{
                    dayHeaderFormat: (date) => {
                        return moment(date).format('dddd - DD/MM');
                    }
                }}
                eventPropGetter={(events) => {
                    return {
                        style: {
                            backgroundColor: events.color
                        }
                    };
                }}
                selectable={selectable}
                onSelectSlot={onSelectSlot}
                onSelectEvent={handleOnSelectEvent}
            />
        </div>
    );
};

export default DefaultCalendar;
