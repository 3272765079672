import { useContext, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useNavigationPage from '../../hooks/useNavigationPage';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import DefaultModal from '../../components/default/DefaultModal';
import useLiquidationService from '../../app/services/hooks/useLiquidationService';
import { Liquidation, LiquidationFilters } from '../../app/models/Liquidation';
import LiquidationDataTable from './components/tables/LiquidationDataTable';
import LiquidationCreate from './subpages/LiquidationCreate';
import LiquidationShow from './subpages/LiquidationShow';
// import

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Liquidaciones',
        url: '/liquidations',
        isActive: true
    }
];

const Liquidations = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { fetchingGetLiquidations, getLiquidations, deleteLiquidation, activeLiquidation, approvedLiquidation, getDocumentLiquidation } =
        useLiquidationService();
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [liquidationIdEdit, setLiquidationIdEdit] = useState<number>(-1);
    const [liquidations, setLiquidations] = useState<Liquidation[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [liquidationFilter, setLiquidationFilter] = useState<LiquidationFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [liquidationFilter]);

    const getAllLiquidations = () => {
        getLiquidations(liquidationFilter, {
            onSuccess: (response: ServiceResponse) => {
                console.log(response.data);
                setLiquidations(response.data.liquidations);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const approved = (id: number, value: boolean, message: string) => {
        const _text = `La liquidación # ${id} está a punto de ser ${
            value ? 'aprobada' : 'rechazada'
        }`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', `cambiando estado de solicitud...`);
                        approvedLiquidation(id, value, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: `La solicitud no se ha ${message}.`
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const document = (quoteId: number) => {
        getDocumentLiquidation(quoteId, {});
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const show = (liquidationId: number) => {
        navigationPage(
            `/liquidations/${liquidationId}/show`
        );
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllLiquidations();
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setLiquidationIdEdit(-1);
    };

    return (
        <>
            <Breadcrumbs pageSection="Liquidaciones" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate callbackCreate={showCreate} title="Generar liquidaciones" />
                    }
                />
                <LiquidationDataTable
                    liquidations={liquidations}
                    totalRows={totalRows}
                    filter={liquidationFilter}
                    loading={fetchingGetLiquidations}
                    setFilter={setLiquidationFilter}
                    callbackSuccess={reloadTable}
                    show={show}
                    approved={approved}
                    documentLiquidation={document}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Generar liquidación trabajo"
                    backdrop={true}
                    showFooter={false}
                >
                    <LiquidationCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar puesto de Trabajo"
                    backdrop={true}
                    showFooter={false}
                >
                    <LiquidationShow

                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Liquidations;
