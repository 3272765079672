import useFetch from '../../../hooks/useFetch';
import { useContext, useState } from 'react';
import { IncidentDataForm, IncidentFilters } from '../../models/Incident';
import { ServiceEvents } from '../shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';

import { EndPoints } from '../EndPoints';

const useIncidentService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [fetchingGetIncidents, setFetchingGetIncidents] = useState(false);
    const [fetchingShowIncident, setFetchingShowIncident] = useState(false);
    const [fetchingCreateIncident, setFetchingCreateIncident] = useState(false);
    const [fetchingStoreIncident, setFetchingStoreIncident] = useState(false);
    const [fetchingEditIncident, setFetchingEditIncident] = useState(false);
    const [fetchingUpdateIncident, setFetchingUpdateIncident] = useState(false);
    const [fetchingDeleteIncident, setFetchingDeleteIncident] = useState(false);
    const [fetchingGetIncidentsByTransportOrder, setFetchingGetIncidentsByTransportOrder] = useState(false);
    const [fetchingChangeIncidentStatus, setFetchingChangeIncidentStatus] = useState(false);
    const [fetchingOpenIncident, setFetchingOpenIncident] = useState(false);
    const [fetchingCloseIncident, setFetchingCloseIncident] = useState(false);
    const [fetchingAnnulIncident, setFetchingAnnulIncident] = useState(false);
    const [fetchingGetUrlEvidence, setFetchingGetUrlEvidence] = useState(false);

    // const getIncidents = (incidentFilters: IncidentFilters, events: ServiceEvents = {}) => {
    //     const queryString = Object.entries(incidentFilters)
    //         .map(([key, value]) => `${key}=${value}`)
    //         .join('&');

    //     const url = `${EndPoints.INCIDENTS.GET_INCIDENTS}?${queryString}`;
    //     doGet({
    //         ...events,
    //         url: url,
    //         setFetching: setFetchingGetIncidents
    //     });
    // };

    const getAllIncidentsByTransportOrder = (
            filter: IncidentFilters,
            transport_order_id: number,
            events: ServiceEvents = {}
        ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
    
        const url = `${EndPoints.INCIDENTS.GET_INCIDENTS.replace(
            ':transport_order_id',
            transport_order_id.toString()
            )}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetIncidentsByTransportOrder
        });
    };

    const showIncident = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INCIDENTS.SHOW_INCIDENT.replace(':id', id.toString()),
            setFetching: setFetchingShowIncident
        });
    };

    const createIncident = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INCIDENTS.CREATE_INCIDENT,
            setFetching: setFetchingCreateIncident
        });
    };

    const storeIncident = (incident: IncidentDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.INCIDENTS.STORE_INCIDENT,
            body: incident,
            setFetching: setFetchingStoreIncident
        });
    };

    const editIncident = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.INCIDENTS.EDIT_INCIDENT.replace(':id', id.toString()),
            setFetching: setFetchingEditIncident
        });
    };

    const updateIncident = (id: number, incident: IncidentDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.INCIDENTS.UPDATE_INCIDENT.replace(':id', id.toString()),
            body: incident,
            setFetching: setFetchingUpdateIncident
        });
    };

    const deleteIncident = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.INCIDENTS.DELETE_INCIDENT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteIncident
        });
    };

    
    // const changeIncidentStatus = (id: number, status: string, events: ServiceEvents = {}) => {
    //     doPut({
    //         ...events,
    //         url: EndPoints.INCIDENTS.CHANGE_STATUS_INCIDENT.replace(':id', id.toString()),
    //         body: { status },
    //         setFetching: setFetchingChangeIncidentStatus,
    //     });
    // };

    const openIncident = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.INCIDENTS.OPEN_INCIDENT.replace(':id', id.toString()),
            setFetching: setFetchingOpenIncident
        });
    };

    const closeIncident = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.INCIDENTS.CLOSE_INCIDENT.replace(':id', id.toString()),
            setFetching: setFetchingCloseIncident
        });
    };

    const annulIncident = (id: number, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.INCIDENTS.ANNUL_INCIDENT.replace(':id', id.toString()),
            setFetching: setFetchingAnnulIncident
        });
    };

    const getUrlEvidenceOnIncident = (
        incidentId: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.INCIDENTS.GET_EVIDENCE_INCIDENT.replace(':id', incidentId.toString()),
            setFetching: setFetchingGetUrlEvidence
        });
    };

    const downloadEvidence = (url_file: string) => {
        const link = document.createElement('a');
        link.href = url_file;
        link.download = 'archivo evidencia';
        link.target = '_blank';
        document.body.appendChild(link);

        // Simular un clic en el enlace para iniciar la descarga
        link.click();
        if (changeAnimation)
            changeAnimation('downloadSuccess', 'Archivo recuperado con éxito', true);
    };



    return {

        fetchingShowIncident,
        fetchingCreateIncident,
        fetchingStoreIncident,
        fetchingEditIncident,
        fetchingUpdateIncident,
        fetchingDeleteIncident,
        fetchingGetIncidentsByTransportOrder,
        fetchingOpenIncident,
        fetchingCloseIncident,
        fetchingAnnulIncident,
        fetchingGetUrlEvidence,
        getAllIncidentsByTransportOrder,
        getUrlEvidenceOnIncident,
        downloadEvidence,
        showIncident,
        createIncident,
        storeIncident,
        editIncident,
        updateIncident,
        deleteIncident,
        openIncident,
        closeIncident,
        annulIncident

    };
};

export default useIncidentService;
