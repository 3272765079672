import { useContext, useEffect, useRef, useState } from 'react';
import {
    defaultDocumentSetDataForm,
    DocumentSetDataForm,
    Equipment
} from '../../../../app/models/DocumentSet';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import LazyLoading from '../../../../components/LazyLoading';
import DocumentSetForm from '../forms/DocumentSetForm';
import { AppContext } from '../../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import useDocumentSetService from '../../../../app/services/hooks/useDocumentSetService';
import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import { JobTitle } from '../../../../app/models/JobTitle';
import { label } from 'yet-another-react-lightbox/*';

interface Props {
    documentSetId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DocumentSetEdit = ({ documentSetId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditDocumentSet,
        editDocumentSet,
        fetchingUpdateDocumentSet,
        updateDocumentSet
    } = useDocumentSetService();
    const [documentSet, setDocumentSet] = useState<DocumentSetDataForm>(defaultDocumentSetDataForm);
    const [documents, setDocuments] = useState<Document[]>([]);
    const [jobTitles, setJobTitles] = useState<JobTitle[]>([]);
    const [equipments, setEquipments] = useState<Equipment[]>([]);
    const firstJobTitles = useRef<JobTitle[] | undefined>();
    const firstEquipments = useRef<Equipment[] | undefined>();
    
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando set de documento...');
        editDocumentSet(documentSetId, {
            onSuccess: (response: ServiceResponse) => {
                //setDocumentSet(response.data.document_set);
                setJobTitles(response.data.job_titles);
                setDocuments(response.data.documents);
                setEquipments(response.data.equipments);
                const _docuement_set = response.data.document_set;
                _docuement_set.documents = response.data.document_set.documents.map((doc: any) => {
                    return {
                        value: doc.id,
                        label: doc.document_name
                    };
                });
                _docuement_set.job_titles = response.data.document_set.job_titles.map(
                    (title: any) => {
                        return {
                            value: title.id,
                            label: title.name
                        };
                    }
                );
                firstJobTitles.current = _docuement_set.job_titles;
                _docuement_set.equipments = response.data.document_set.equipments.map(
                    (title: any) => {
                        return {
                            value: title.id,
                            label: title.name
                        };
                    }
                );
                firstEquipments.current = _docuement_set.equipments;
                setDocumentSet(_docuement_set);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando el set documento...');
        updateDocumentSet(documentSetId, documentSet, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditDocumentSet ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <DocumentSetForm
                        documentSetDataForm={documentSet}
                        setDocumentSet={setDocumentSet}
                        documents={documents}
                        jobTitles={jobTitles}
                        equipments={equipments}
                        firstJobTitles={firstJobTitles.current}
                        firstEquipments={firstEquipments.current}
                        errorFields={errorFields}
                        setErrorFields={setErrorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingUpdateDocumentSet} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateDocumentSet} />
                </div>
            </div>
        </>
    );
};

export default DocumentSetEdit;
