import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import { PaymentCondition } from '../../../app/models/PaymentCondition';
import useQuoteService from '../../../app/services/hooks/useQuoteService';
import { Currency } from '../../../app/models/Currency';
import { QuoteDataForm, QuoteOfferTypeDataForm } from '../../../app/models/Quote';
import LazyLoading from '../../../components/LazyLoading';
import DefaultCard from '../../../components/default/DefaultCard';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import QuoteDetailsContainer from '../components/QuoteDetailsContainer';
import { AuthContext } from '../../../contexts/AuthContext';
import { Tab, Tabs } from 'react-bootstrap';
import '../../../assets/scss/custom/components/_tab.scss';
import QuoteTab from '../components/tabs/QuoteTab';
import QuoteRouteSegmentsContainer from '../components/QuoteRouteSegmentsContainer';
import { QuoteRouteSegment, QuoteRouteSegmentFilters } from '../../../app/models/QuoteRouteSegment';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';

type RouterParams = {
    id: string;
};

const QuoteEdit = () => {
    const { navigationPage } = useNavigationPage();

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const { id } = useParams<RouterParams>();

    const [verifyingQuote, setVerifyingQuote] = useState<boolean>(true);

    const { editQuote, fetchingEditQuote, updateQuote } = useQuoteService();
    const { getQuoteRouteSegments, fetchingGetQuoteRouteSegments } = useQuoteRouteSegmentService();

    const { auth } = useContext(AuthContext);

    const [quoteForm, setQuoteForm] = useState<QuoteDataForm>({
        contact_email: '',
        expired_at: '',
        expired_in_days: 0,
        client_id: 0,
        payment_method_id: 0,
        payment_condition_id: 0,
        currency_id: 0,
        society_id: 0
    });

    const [societies, setSocieties] = useState<Society[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [paymentConditions, setPaymentsConditions] = useState<PaymentCondition[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentCondition[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [offerTypes, setOfferTypes] = useState<QuoteOfferTypeDataForm[]>([]);

    const [quoteRouteSegments, setQuoteRouteSegments] = useState<QuoteRouteSegment[]>([]);
    const [currencyId, setCurrencyId] = useState(0);
    const [quoteCheckDocument, setQuoteCheckDocument] = useState<boolean>(false);
    const [disableUpdate, setDisableUpdate] = useState<boolean>(false);
    const [filter, setFilter] = useState<QuoteRouteSegmentFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });

    useEffect(() => {
        edit(parseInt(id));
    }, [id]);

    useEffect(() => {
        reloadQuoteRouteSegmentTable();
    }, [filter]);

    const [errorFields, setErrorFields] = useState<any>();

    const edit = (id: number) => {
        editQuote(id, {
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setClients(response.data.clients);
                setPaymentMethods(response.data.payment_methods);
                setCurrencies(response.data.currencies);
                setOfferTypes(response.data.offer_types);
                setPaymentsConditions(response.data.payment_conditions);

                setQuoteForm(response.data.quote);
                setCurrencyId(response.data.quote.currency_id);
                setQuoteCheckDocument(response.data.quote.is_route_segment_document);

                if (!response.data.can_update) {
                    setDisableUpdate(true);
                }
                setVerifyingQuote(false);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2500
                });
                if (response.data.to_dashboard) {
                    navigationPage('/quotes');
                }
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando Oferta...');

        updateQuote(quoteForm.id!, quoteForm, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.success(response.message);
                setErrorFields(undefined);
                setQuoteForm({ ...quoteForm, ...response.data.quote });
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const reloadQuoteRouteSegmentTable = () => {
        getQuoteRouteSegments(parseInt(id), filter, {
            onSuccess: (response: ServiceResponse) => {
                setQuoteRouteSegments(response.data.quote_route_segments);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2500
                });
            }
        });
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Oferta',
            url: '/quotes',
            isActive: false
        },
        {
            name: 'Editar Oferta #' + id,
            url: '/quotes/' + id + '/edit',
            isActive: true
        }
    ];

    if (verifyingQuote) {
        return (
            <DefaultCard>
                <LazyLoading height={300} />
            </DefaultCard>
        );
    }

    return (
        <>
            <Breadcrumbs pageSection={`Editar Oferta #${id}`} breadcrumbs={breadcrumbs} />

            {/* Tabs */}

            <Tabs defaultActiveKey="general-data" id="quote-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <QuoteTab
                        auth={auth}
                        fetchingQuote={fetchingEditQuote}
                        update={update}
                        quoteForm={quoteForm}
                        setQuoteForm={setQuoteForm}
                        societies={societies}
                        clients={clients}
                        paymentConditions={paymentConditions}
                        paymentMethods={paymentMethods}
                        offerTypes={offerTypes}
                        currencies={currencies}
                        errorFields={errorFields}
                        disableUpdate={disableUpdate}
                    />
                </Tab>

                <Tab eventKey="interlocutors" title="Interlocutores" disabled>
                    Interlocutores
                </Tab>
                <Tab eventKey="options" title="Opciones">
                    <div className="col-12">
                        <QuoteRouteSegmentsContainer
                            quoteId={parseInt(id)}
                            quoteCheckDocument={quoteCheckDocument}
                            quoteRouteSegments={quoteRouteSegments}
                            setQuoteRouteSegments={setQuoteRouteSegments}
                            reloadQuoteRouteSegmentTable={reloadQuoteRouteSegmentTable}
                            filter={filter}
                            setFilter={setFilter}
                            fetchingGetQuoteRouteSegments={fetchingGetQuoteRouteSegments}
                        />
                    </div>
                </Tab>
                <Tab eventKey="management" title="Gestión" disabled>
                    Gestión
                </Tab>
            </Tabs>

            <QuoteDetailsContainer
                quoteId={parseInt(id)}
                currencyId={quoteForm.currency_id}
                quoteForm={quoteForm}
                setQuoteForm={setQuoteForm}
                setQuoteRouteSegmentTable={setQuoteRouteSegments}
                reloadQuoteRouteSegmentTable={reloadQuoteRouteSegmentTable}
                disableUpdate={disableUpdate}
                reloadQuote={edit}
            />
        </>
    );
};

export default QuoteEdit;
