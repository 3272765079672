import DataTable from 'react-data-table-component';
import LazyLoading from '../../../../components/LazyLoading';
import { formatNumberWithDots } from '../../../../helpers';


interface Props {
    data: any[];
    progressPending?: boolean;
}

const DeductionAndBonusDataTable = ({
    data,
    progressPending
}: Props) => {
    const columns = [
        {
            name: 'Concepto',
            selector: (row: any) => row.name,
            sortable: false,
            sortField: 'name',
            cell: (row: any, index: any, column: any) => <span className="">{row.name}</span>
        },
        {
            name: 'Valor',
            selector: (row: any) => row.value,
            sortable: false,
            sortField: 'contract',
            cell: (row: any, index: any, column: any) => <span className="">{formatNumberWithDots(row.value)}</span>
        }
    ];

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                progressPending={progressPending}
                progressComponent={<LazyLoading height={300} />}
                noDataComponent="No hay documentos registrados"
            />
        </>
    );
};

export default DeductionAndBonusDataTable;