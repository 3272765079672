import React from 'react';
import { HolidayDataForm } from '../../../../app/models/Holiday';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';

type Props = {
    holidayDataForm: HolidayDataForm;
    setHolidayDataForm: (holidayDataForm: HolidayDataForm) => void;
    errorFields?: any;
};

const HolidayForm = ({ holidayDataForm, setHolidayDataForm, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setHolidayDataForm({ ...holidayDataForm, [name]: value });
    };

    const handleResignableCheckChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name } = event.target;

        setHolidayDataForm({ ...holidayDataForm, [name]: !holidayDataForm.resignable });
    };

    const handleIsRepeatCheckChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name } = event.target;

        setHolidayDataForm({ ...holidayDataForm, [name]: !holidayDataForm.is_repeat });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Fecha</label>
                <div className="col-md-9">
                    <input
                        type="date"
                        className={`form-control ${fieldHasError('date')}`}
                        name="date"
                        id="date"
                        value={holidayDataForm.date}
                        onChange={handleChange}
                        placeholder="Ingrese una fecha de despacho"
                        onFocus={() => onFocusRemove('date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('date')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Nombre</label>
                <div className="col-md-9">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={holidayDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre del feriado"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row needs-validation">
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="is_repeat"
                            value="is_repeat"
                            checked={holidayDataForm.is_repeat}
                            id="is_repeat"
                            onChange={handleIsRepeatCheckChange}
                        />
                        <label className="form-check-label" htmlFor="is_repeat">
                            Se repite
                        </label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="is_repeat"
                            value="is_repeat"
                            checked={!holidayDataForm.is_repeat}
                            id="is_repeat"
                            onChange={handleIsRepeatCheckChange}
                        />
                        <label className="form-check-label" htmlFor="is_repeat">
                            No se repite
                        </label>
                    </div>
                </div>
            </div>
            <div className="mb-2 row needs-validation">
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="resignable"
                            value="resignable"
                            checked={holidayDataForm.resignable}
                            id="resignable"
                            onChange={handleResignableCheckChange}
                        />
                        <label className="form-check-label" htmlFor="resignable">
                            Renunciable
                        </label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="resignable"
                            value="resignable"
                            checked={!holidayDataForm.resignable}
                            id="resignable"
                            onChange={handleResignableCheckChange}
                        />
                        <label className="form-check-label" htmlFor="resignable">
                            Irrenunciable
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HolidayForm;
