import React from 'react';
import { formatMoney } from '../../../utils/utils';

interface Props {
    loading: boolean;
    allCredit: number;
    allDebit: number;
}

const GetAllSummary = ({ loading, allCredit, allDebit }: Props) => {
    return (
        <div className="row">
            <div className="col-md-4 ms-auto">
                <div
                    className="mini-stats-wid card p-2"
                    style={{
                        backgroundColor: '#E8E4E8',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                    }}
                >
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Abono</p>
                            {loading ? (
                                <h5 className="mb-0">...</h5>
                            ) : (
                                <h5 className="mb-0">{formatMoney(allDebit)}</h5>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div
                    className="mini-stats-wid card p-2"
                    style={{
                        backgroundColor: '#E8E4E8',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                    }}
                >
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Cargo</p>
                            {loading ? (
                                <h5 className="mb-0">...</h5>
                            ) : (
                                <h5 className="mb-0">{formatMoney(allCredit)}</h5>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4">
                <div
                    className="mini-stats-wid card p-2"
                    style={{
                        backgroundColor: '#E8E4E8',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                    }}
                >
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <p className="text-muted fw-medium">Saldo</p>
                            {loading ? (
                                <h5 className="mb-0">...</h5>
                            ) : (
                                <h5 className="mb-0">{formatMoney( allDebit - allCredit)}</h5>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetAllSummary;
