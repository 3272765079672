import { useState, ChangeEvent, useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { Shift, ShiftDays, ShiftWorkDayTravelExpense } from '../../../../app/models/Shift';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { EventType } from '../../../../app/models/EventType';
import { CalendarEventDataForm } from '../../../../app/models/Event';
import { TravelExpenseDataForm } from '../../../../app/models/TravelExpense';
import { Table } from 'react-bootstrap';

type Props = {
    showIsShiftWork: boolean;
    setShowIsShiftWork: (value: boolean) => void;
    calendarEventDataForm: CalendarEventDataForm;
    setCalendarEventDataForm: (calendarEventDataForm: CalendarEventDataForm) => void;
    eventTypes: EventType[];
    shiftWorks: Shift[];
    travelExpenses: TravelExpenseDataForm[];
    filteredTravelExpenses: TravelExpenseDataForm[];
    selectedTravelExpense?: TravelExpenseDataForm;
    shiftWorkEvent: CalendarEventDataForm;
    setShiftWorkEvent: (calendarEventDataForm: CalendarEventDataForm) => void;
    errorFields?: any;
    editable?: boolean;
    editableDate?: boolean;
};

const WorkerEventEditForm = ({
    showIsShiftWork,
    setShowIsShiftWork,
    calendarEventDataForm,
    setCalendarEventDataForm,
    eventTypes,
    shiftWorks,
    travelExpenses,
    filteredTravelExpenses,
    selectedTravelExpense,
    shiftWorkEvent,
    setShiftWorkEvent,
    errorFields,
    editable,
    editableDate = true
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    const [showEventType, setShowEventType] = useState(true);

    const [selectedTravelExpenseEdit, setSelectedTravelExpenseEdit] =
        useState<CalendarEventDataForm>({
            travel_expense_id: calendarEventDataForm.travel_expense?.id
        });

    const handleCheckChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name } = event.target;

        switch (name) {
            case 'is_shiftwork':
                setShowIsShiftWork(true);
                setShowEventType(false);
                break;
            case 'not_shiftwork':
                setShowIsShiftWork(false);
                setShowEventType(true);
                break;
            case 'temporal':
                setShiftWorkEvent({
                    ...shiftWorkEvent,
                    is_undefined: false
                });
                break;
            case 'undefined':
                setShiftWorkEvent({
                    ...shiftWorkEvent,
                    is_undefined: true
                });
                break;
            default:
                break;
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        const formattedValue = value.length === 5 ? `${value}:00` : value;

        if (name !== 'start_date' && name !== 'end_date') {
            setCalendarEventDataForm({
                ...calendarEventDataForm,
                [name]: formattedValue
            });
            setShiftWorkEvent({
                ...shiftWorkEvent,
                [name]: formattedValue
            });
        } else {
            setShiftWorkEvent({
                ...shiftWorkEvent,
                [name]: value
            });
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setShiftWorkEvent({
            ...shiftWorkEvent,
            ...obj
        });
    };

    const handleChangeShiftWorkDay = (value: number, index: number) => {
        if (!shiftWorkEvent.shift_days) return null;

        const updatedShiftDays = [...shiftWorkEvent.shift_days];

        updatedShiftDays[index] = {
            ...updatedShiftDays[index],
            travel_expense_id: value
        };

        setShiftWorkEvent({
            ...shiftWorkEvent,
            shift_days: updatedShiftDays
        });
    };

    const handleChangeTravelExpense = (name: string, value: TravelExpenseDataForm | undefined) => {
        if (value) {
            setSelectedTravelExpenseEdit(value);
            setCalendarEventDataForm({
                ...calendarEventDataForm,
                travel_expense: value,
                travel_expense_id: value.id
            });
        }
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                {calendarEventDataForm?.is_shift_work && (
                    <div className="my-2 row">
                        <div className="col-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="not_shiftwork"
                                    value="not_shiftwork"
                                    checked={!showIsShiftWork}
                                    id="not_shiftwork"
                                    onChange={handleCheckChange}
                                />
                                <label className="form-check-label" htmlFor="is_shiftwork">
                                    Evento
                                </label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_shiftwork"
                                    value="is_shiftwork"
                                    checked={showIsShiftWork}
                                    id="is_shiftwork"
                                    onChange={handleCheckChange}
                                />
                                <label className="form-check-label" htmlFor="is_shiftwork">
                                    Jornada
                                </label>
                            </div>
                        </div>
                    </div>
                )}

                {showEventType && (
                    <>
                        <label className="col-md-3 col-form-label">Tipo de evento</label>
                        <div className="col-md-8">
                            <SelectTwo
                                name="event_type_id"
                                id="event_type_id"
                                inputValue={calendarEventDataForm.event_type_id}
                                options={SelectTwoMapperOptions(eventTypes)}
                                disabled={true}
                                hasError={fieldHasError('event_type_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('event_type_id', value?.value)
                                }
                                placeholder={'Seleccione el tipo de evento'}
                                onFocus={() => onFocusRemove('event_type_id')}
                            />
                            <div className="invalid-feedback" style={{ display: ' flex' }}>
                                {fieldErrorMessage('event_type_id')}
                            </div>
                        </div>
                    </>
                )}

                {showIsShiftWork && (
                    <>
                        <label className="col-md-3 col-form-label">Tipo de jornada</label>
                        <div className="col-md-8">
                            <SelectTwo
                                name="shift_work_id"
                                id="shift_work_id"
                                inputValue={shiftWorkEvent.shift_work_id}
                                options={SelectTwoMapperOptions(shiftWorks)}
                                hasError={fieldHasError('shift_work_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('shift_work_id', value?.value)
                                }
                                placeholder={'Seleccione el tipo de jornada'}
                                onFocus={() => onFocusRemove('shift_work_id')}
                            />
                            <div className="invalid-feedback" style={{ display: ' flex' }}>
                                {fieldErrorMessage('shift_work_id')}
                            </div>
                        </div>

                        <div className="my-2 row">
                            <div className="col-6">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="temporal"
                                        value="temporal"
                                        disabled={!editable}
                                        checked={!shiftWorkEvent.is_undefined}
                                        id="is_defined"
                                        onChange={handleCheckChange}
                                    />
                                    <label className="form-check-label" htmlFor="is_defined">
                                        Temporal
                                    </label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="undefined"
                                        value="undefined"
                                        disabled={!editable}
                                        checked={shiftWorkEvent.is_undefined}
                                        id="is_undefined"
                                        onChange={handleCheckChange}
                                    />
                                    <label className="form-check-label" htmlFor="is_undefined">
                                        Indefinido
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {showIsShiftWork && (
                    <div className="col-md-12 my-3">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Días</th>
                                    <th>Tipo de turno</th>
                                    <th>Viáticos</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shiftWorkEvent.shift_days?.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{item.number_of_days}</td>
                                        <td>{item.event_type?.name}</td>
                                        <td>
                                            <div className="col-md-9">
                                                <SelectTwo
                                                    name="travel_expense"
                                                    id={`travel_expense_${index}`}
                                                    inputValue={item?.travel_expense_id}
                                                    options={SelectTwoMapperOptions(
                                                        item?.travel_expenses || []
                                                    )}
                                                    hasError={
                                                        fieldHasError('travel_expense') !== ''
                                                    }
                                                    onChange={(value: any) =>
                                                        handleChangeShiftWorkDay(
                                                            value?.value,
                                                            index
                                                        )
                                                    }
                                                    placeholder={'Seleccione viático'}
                                                    onFocus={() => onFocusRemove('travel_expense')}
                                                />
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: 'flex' }}
                                                >
                                                    {fieldErrorMessage('travel_expense')}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}

                <div className="mb-2 row">
                    <div className="col-md-6">
                        <label className="col-md-6 col-form-label">Fecha de inicio</label>
                        <div className="col-md-9">
                            <input
                                type="date"
                                className={`form-control ${fieldHasError('start_date')}`}
                                name="start_date"
                                id="start_date"
                                value={
                                    showIsShiftWork
                                        ? shiftWorkEvent.start_date?.toString()
                                        : calendarEventDataForm.start_date?.toString()
                                }
                                disabled={!showIsShiftWork}
                                onChange={handleChange}
                                placeholder="Ingrese una fecha"
                                onFocus={() => onFocusRemove('date')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('start_date')}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className="col-md-9 col-form-label">Fecha de término</label>
                        <div className="col-md-9">
                            <input
                                type="date"
                                className={`form-control ${fieldHasError('end_date')}`}
                                name="end_date"
                                id="end_date"
                                value={
                                    showIsShiftWork
                                        ? shiftWorkEvent.end_date?.toString()
                                        : calendarEventDataForm.end_date?.toString()
                                }
                                disabled={!showIsShiftWork || shiftWorkEvent.is_undefined}
                                onChange={handleChange}
                                placeholder="Ingrese una fecha"
                                onFocus={() => onFocusRemove('date')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('end_date')}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-2 row">
                    <div className="col-md-6">
                        <label className="col-md-6 col-form-label">Hora de inicio</label>
                        <div className="col-md-9">
                            <input
                                type="time"
                                className={`form-control ${fieldHasError('start_hour')}`}
                                name="start_hour"
                                id="start_hour"
                                value={calendarEventDataForm.start_hour?.toString()}
                                disabled={!editable}
                                onChange={handleChange}
                                placeholder="Ingrese una hora"
                                onFocus={() => onFocusRemove('start_hour')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('start_hour')}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className="col-md-9 col-form-label">Hora de término</label>
                        <div className="col-md-9">
                            <input
                                type="time"
                                className={`form-control ${fieldHasError('end_hour')}`}
                                name="end_hour"
                                id="end_hour"
                                value={calendarEventDataForm.end_hour?.toString()}
                                disabled={!editable}
                                onChange={handleChange}
                                placeholder="Ingrese una hora"
                                onFocus={() => onFocusRemove('end_hour')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('end_hour')}
                            </div>
                        </div>
                    </div>
                </div>
                {selectedTravelExpense && !showIsShiftWork && (
                    <div className="mb-2 row">
                        <div className="col-md-6">
                            <label className="col-md-6 col-form-label">Viático</label>
                            <div className="col-md-9">
                                <SelectTwo
                                    name="travel_expense"
                                    id={`travel_expense`}
                                    inputValue={
                                        selectedTravelExpenseEdit?.id ||
                                        calendarEventDataForm.travel_expense?.id
                                    }
                                    options={SelectTwoMapperOptions(filteredTravelExpenses)}
                                    hasError={fieldHasError('travel_expense') !== ''}
                                    onChange={(value: any) => {
                                        const selectedExpenseId = value?.value;
                                        handleChangeTravelExpense(
                                            'travel_expense_id',
                                            travelExpenses.find(
                                                (item) => item.id === selectedExpenseId
                                            )
                                        );
                                    }}
                                    placeholder={'Seleccione viático'}
                                    onFocus={() => onFocusRemove('travel_expense')}
                                />

                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('travel_expense')}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default WorkerEventEditForm;
