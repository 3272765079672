import React, { useContext, useEffect, useState } from 'react';
import { Place } from '../../app/models/Place';
import { ServiceEventsDocuments, ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';

import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { InternalSettlement, InternalSettlementFilters } from '../../app/models/InternalSettlement';
import useInternalSettlementService from '../../app/services/hooks/useInternalSettlementService';
import InternalSettlementDataTable from './components/tables/InternalSettlementDataTable';
import InternalSettlementShow from './components/InternalSettlementShow';
import useNavigationPage from '../../hooks/useNavigationPage';
import { AuthContext } from '../../contexts/AuthContext';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Liquidación interna',
        url: '/internal-settlements',
        isActive: true
    }
];

const InternalSettlements = () => {
    const history = useHistory();

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const {
        fetchingGetInternalSettlements,
        fetchingGetExcelInternalSettlements,
        getExcelAllInternalSettlements,
        getInternalSettlements
    } = useInternalSettlementService();

    const [internalSettlements, setInternalSettlements] = useState<InternalSettlement[]>([]);
    const [showingShow, setShowingShow] = useState(false);
    const [internalSettlementIdShow, setInternalSettlementIdShow] = useState<number>(-1);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [allCredit, setAllCredit] = useState<number>(0);
    const [allDebit, setAllDebit] = useState<number>(0);
    const { navigationPage } = useNavigationPage();
    const { auth } = useContext(AuthContext);

    const [InternalSettlementFilter, setInternalSettlementFilter] =
        useState<InternalSettlementFilters>({
            page: 1,
            per_page: 10,
            sort: 'issue_date',
            order: 'desc',
            issue_date_from: moment().date(1).format('YYYY-MM-DD'),
            issue_date_until: moment().format('YYYY-MM-DD'),
            without_viatic_transport: auth?.roles?.includes('Conductor'),
        });

    useEffect(() => {
        reloadTable();
    }, []);

    const getAllInternalSettlement = () => {
        getInternalSettlements(InternalSettlementFilter, {
            onSuccess: (response: ServiceResponse) => {
                setInternalSettlements(response.data.internal_settlements);
                setTotalRows(response.data.total_rows);
                setAllCredit(response.data.all_credit);
                setAllDebit(response.data.all_debit);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const reloadTable = () => {
        getAllInternalSettlement();
    };

    const downloadExcel = () => {
        if (showLoading) showLoading('download', 'Recuperando documento...');
        getExcelAllInternalSettlements(InternalSettlementFilter, {
            onSuccess: (message: string) => {
                if (changeAnimation) changeAnimation('downloadSuccess', message, true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const show = (internalSettlementId: number) => {
        setShowingShow(true);
        setInternalSettlementIdShow(internalSettlementId);
    };

    const hideShow = () => {
        setShowingShow(false);
        setInternalSettlementIdShow(-1);
    };

    return (
        <>
            <Breadcrumbs pageSection="Liquidación interna" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <InternalSettlementDataTable
                    internalSettlements={internalSettlements}
                    loading={fetchingGetInternalSettlements}
                    filter={InternalSettlementFilter}
                    setFilter={setInternalSettlementFilter}
                    totalRows={totalRows}
                    allCredit={allCredit}
                    allDebit={allDebit}
                    downloadExcel={downloadExcel}
                    show={show}
                    onSearch={reloadTable}
                />
            </DefaultCard>
            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideShow}
                    title="Ver liquidación interna"
                    backdrop={true}
                    showFooter={false}
                >
                    <InternalSettlementShow
                        internalSettlementId={internalSettlementIdShow}
                        onCancel={hideShow}
                        onError={hideShow}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default InternalSettlements;
