import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import { TruckDocument } from '../../../app/models/Truck';
import useTruckDocumentService from '../../../app/services/hooks/useTruckDocumentService';
import DocumentsHistoryDataTable from '../../../components/DocumentHistoryDataTable';

interface Props {
    onCancel?: () => void;
    onError?: () => void;
    truckId: number;
    typeDocument: number;
}

const TruckDocumentHistory = ({ onCancel, onError, truckId, typeDocument }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const [truckDocuments, setTruckDocuments] = useState<TruckDocument[]>();
    const {
        fetchingGetTruckDocumentsByTruckAndDocumentType,
        getTruckDocumentByTruckAndDocumentType
    } = useTruckDocumentService();

    const getDocumentsHistory = (truckId: number, typeDocument: number) => {
        getTruckDocumentByTruckAndDocumentType(truckId, typeDocument, {
            onSuccess: (response: ServiceResponse) => {
                console.log(response);
                setTruckDocuments(response.data.truck_documents);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        getDocumentsHistory(truckId, typeDocument);
    }, []);

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingGetTruckDocumentsByTruckAndDocumentType ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    {truckDocuments && (
                        // <GeneralDocumentsTransporterHistoryByTypeTable documents={truckDocuments} />
                        <DocumentsHistoryDataTable data={truckDocuments} />
                        // <h1>hola</h1>
                    )}
                </div>
            </div>
        </>
    );
};

export default TruckDocumentHistory;
