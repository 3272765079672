import React, { useContext, useEffect, useState } from 'react';
import { Afp } from '../../../../app/models/Afp';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import LazyLoading from '../../../../components/LazyLoading';
import useAfpService from '../../../../app/services/hooks/useAfpService';
import { AppContext } from '../../../../contexts/AppContext';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import AfpFormContainer from './AfpFormContainer';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumbs, {
    BreadcrumbsItem
} from '../../../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../../../components/default/DefaultCard';
import AfpForm from '../forms/AfpForm';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import AfpRateContainer from '../forms/AfpRateContainer';

type RouterParams = {
    id: string;
};

const AfpEdit = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();
    const [verifyingAfp, setVerifyingAfp] = useState<boolean>(true);
    const { editAfp, updateAfp, fetchingEditAfp, fetchingShowAfp, fetchingUpdateAfp } =
        useAfpService();
    const [afp, setAfp] = useState<Afp>({ name: '' });
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit(parseInt(id));
    }, [id]);

    const edit = (id: number) => {
        editAfp(id, {
            onSuccess: (response: ServiceResponse) => {
                setAfp(response.data.afp);
                setVerifyingAfp(false);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    const update = () => {
        if (afp) {
            if (showLoading) showLoading('loading', 'Actualizando...');

            updateAfp(Number(afp?.id), afp, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    setErrorFields(undefined);
                    edit(parseInt(id));
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Afps',
            url: '/afps',
            isActive: false
        },
        {
            name: 'Editar #' + id,
            url: '/afps/' + id + '/edit',
            isActive: true
        }
    ];

    if (verifyingAfp) {
        return (
            <DefaultCard>
                <LazyLoading height={300} />
            </DefaultCard>
        );
    }

    return (
        <>
            <Breadcrumbs pageSection={`Editar #${id}`} breadcrumbs={breadcrumbs} />

            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {afp && (
                            <AfpForm
                                afpDataForm={afp}
                                setAfpDataForm={setAfp}
                                errorFields={errorFields}
                            />
                        )}
                        <div className="row justify-content-end">
                            <div className="col-auto">
                                <ButtonSaveForm callbackSave={update} locked={fetchingUpdateAfp} />
                            </div>
                        </div>
                    </DefaultCard>
                </div>
            </div>

            {!fetchingEditAfp && !fetchingShowAfp ? (
                <>
                    <AfpRateContainer afpId={parseInt(id)} />
                </>
            ) : null}
        </>
    );
};

export default AfpEdit;
