import { useState, useContext } from 'react';
import useFetch from '../../../hooks/useFetch';
import {
    LiquidationDataForm,
    LiquidationFilters,
    LiquidationGenerator
} from '../../models/Liquidation';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { AppContext } from '../../../contexts/AppContext';
import { fetchDefaultHeaders } from '../shared/vars';

const useLiquidationService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [fetchingGetLiquidations, setFetchingGetLiquidation] = useState(false);
    const [fetchingDeleteLiquidation, setFetchingDeleteLiquidation] = useState(false);
    const [fetchingActiveLiquidation, setFetchingActiveLiquidation] = useState(false);
    const [fetchingStoreLiquidation, setFetchingStoreLiquidation] = useState(false);
    const [fetchingCreateLiquidation, setFetchingCreateLiquidation] = useState(false);
    const [fetchingEditLiquidation, setFetchingEditLiquidation] = useState(false);
    const [fetchingUpdateLiquidation, setFetchingUpdateLiquidation] = useState(false);
    const [fetchingShowLiquidation, setFetchingShowLiquidation] = useState(false);
    const [fetchingApprovedLiquidation, setFetchingApprovedLiquidation] = useState(false);

    const getLiquidations = (
        LiquidationFilters: LiquidationFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(LiquidationFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.LIQUIDATIONS.GET_LIQUIDATIONS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetLiquidation
        });
    };

    const createLiquidation = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.LIQUIDATIONS.CREATE_LIQUIDATIONS,
            setFetching: setFetchingCreateLiquidation
        });
    };

    const storeLiquidation = (Liquidation: LiquidationGenerator, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.LIQUIDATIONS.STORE_LIQUIDATIONS,
            body: Liquidation,
            setFetching: setFetchingStoreLiquidation
        });
    };

    const deleteLiquidation = (LiquidationId: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.LIQUIDATIONS.DELETE_LIQUIDATIONS.replace(
                ':id',
                LiquidationId.toString()
            ),
            setFetching: setFetchingDeleteLiquidation
        });
    };

    const activeLiquidation = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.LIQUIDATIONS.ACTIVE_LIQUIDATIONS.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveLiquidation
        });
    };

    const editLiquidation = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.LIQUIDATIONS.EDIT_LIQUIDATIONS.replace(':id', id.toString()),
            setFetching: setFetchingEditLiquidation
        });
    };

    const updateLiquidation = (
        id: number,
        liquidations: LiquidationDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.LIQUIDATIONS.UPDATE_LIQUIDATIONS.replace(':id', id.toString()),
            body: liquidations,
            setFetching: setFetchingUpdateLiquidation
        });
    };

    const showLiquidation = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.LIQUIDATIONS.SHOW_LIQUIDATION.replace(':id', id.toString()),
            setFetching: setFetchingShowLiquidation
        });
    };

    const approvedLiquidation = (id: number, value: boolean, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.LIQUIDATIONS.APPROVED_LIQUIDATION.replace(
                ':id',
                id.toString()
            ),
            body: { state: value },
            setFetching: setFetchingApprovedLiquidation
        });
    };

    const getDocumentLiquidation = (id: number, events: ServiceEvents = {}) => {
        if (showLoading) showLoading('download', 'descargando archivo...');
        fetch(EndPoints.LIQUIDATIONS.DOCUMENT_LIQUIDATION.replace(':id', id.toString()), {
            headers: { ...fetchDefaultHeaders }
        }).then((response) => {
            response.blob().then((blob) => {
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = `liquidación n°${id}.docx`;
                a.click();
                window.URL.revokeObjectURL(url);
                if (changeAnimation)
                    changeAnimation('downloadSuccess', 'descarga exitosa', true);
            }).catch((error) => {
                if (changeAnimation) changeAnimation('error', 'descarga fallida', true);
            });
        })
        .catch((error) => {
            if (changeAnimation) changeAnimation('error', 'descarga fallida', true);
        });
    };

    return {
        fetchingGetLiquidations,
        fetchingActiveLiquidation,
        fetchingDeleteLiquidation,
        fetchingCreateLiquidation,
        fetchingStoreLiquidation,
        fetchingEditLiquidation,
        fetchingUpdateLiquidation,
        fetchingShowLiquidation,
        fetchingApprovedLiquidation,
        storeLiquidation,
        createLiquidation,
        activeLiquidation,
        getLiquidations,
        deleteLiquidation,
        editLiquidation,
        updateLiquidation,
        showLiquidation,
        approvedLiquidation,
        getDocumentLiquidation
    };
};

export default useLiquidationService;
