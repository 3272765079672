import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { AfpRateDataForm, AfpRateFilters } from '../../../../app/models/AfpRate';

interface Props {
    afpRates: AfpRateDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: AfpRateFilters;
    setFilter: (filter: AfpRateFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const AfpRateDataTable = ({
    afpRates,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter
}: Props) => {
    const columns = [
        {
            name: 'Valor',
            selector: (row: any) => row?.value,
            sortable: true,
            sortField: 'value',
            cell: (row: AfpRateDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {row?.value} {row.value_type}
                    </span>
                </>
            )
        },
        {
            name: 'Fecha de inicio',
            selector: (row: any) => row?.valid_from,
            sortable: true,
            sortField: 'valid_from',
            cell: (row: AfpRateDataForm, index: any, column: any) => (
                <span className="">{row.valid_from}</span>
            )
        },
        {
            name: 'Fecha de fin',
            selector: (row: any) => row?.valid_until,
            sortable: true,
            sortField: 'valid_until',
            cell: (row: AfpRateDataForm, index: any, column: any) => (
                <span className="">{row.valid_until}</span>
            )
        },
        {
            name: 'Tipo de valor',
            selector: (row: any) => row?.value_type,
            sortable: true,
            sortField: 'value_type',
            cell: (row: AfpRateDataForm, index: any, column: any) => (
                <span className="">{row.value_type}</span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: AfpRateDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <DefaultDataTable
                columns={columns}
                data={afpRates}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default AfpRateDataTable;
