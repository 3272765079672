const HOST = process.env.API_HOST || 'https://erp.pegasuslogistics.cl';
const API_VERSION = 'api/v1';
const buildEndpoint = (path: string) => `${HOST}/${API_VERSION}/${path}`;

export const EndPoints = {
    AUTH: {
        LOGIN: buildEndpoint('auths/login'),
        LOGOUT: buildEndpoint('auths/logout'),
        // PUSHER_AUTH: buildEndpoint('auths/pusher-auth'),
        VALIDATE_TOKEN: buildEndpoint('auths/validate-token'),
        GET_ROLES: buildEndpoint('auths/get-roles'),
        SEND_CODE_RECOVERY_PASSWORD: buildEndpoint('auths/send-code-recovery-password'),
        CHANGE_PASSWORD_BY_CODE: buildEndpoint('auths/change-password-by-code'),
        GET_PROFILE: buildEndpoint('auths/get-profile'),
        UPDATE_PROFILE: buildEndpoint('auths/update-profile'),
        UPDATE_PROFILE_PASSWORD: buildEndpoint('auths/update-profile-password')
    },
    ACCOUNT: {
        GET_ACCOUNTS: buildEndpoint('accounts/accounts'),
        STORE_ACCOUNT: buildEndpoint('accounts/accounts/store'),
        EDIT_ACCOUNT: buildEndpoint('accounts/accounts/:id/edit'),
        CREATE_ACCOUNT: buildEndpoint('accounts/accounts/create'),
        SHOW_ACCOUNT: buildEndpoint('accounts/accounts/:id/show'),
        UPDATE_ACCOUNT: buildEndpoint('accounts/accounts/:id/update'),
        DELETE_ACCOUNT: buildEndpoint('accounts/accounts/:id/delete'),
        ACTIVE_ACCOUNT: buildEndpoint('accounts/accounts/:id/active')
    },
    CALENDAR_REQUESTS: {
        GET_WORKER_CALENDAR_REQUESTS: buildEndpoint('calendars/calendar-requests/:id/by-worker'),
        GET_ALL_CALENDAR_REQUESTS: buildEndpoint('calendars/calendar-requests'),
        CREATE_CALENDAR_REQUESTS: buildEndpoint('calendars/calendar-requests/create'),
        STORE_CALENDAR_REQUESTS: buildEndpoint('calendars/calendar-requests/store'),
        ACTIVE_CALENDAR_REQUESTS: buildEndpoint('calendars/calendar-requests/:id/active'),
        DELETE_CALENDAR_REQUESTS: buildEndpoint('calendars/calendar-requests/:id/delete'),
        WORKER_APPROVED_CALENDAR_REQUESTS: buildEndpoint(
            'calendars/calendar-requests/:id/approved'
        ),
        DOCUMENT_CALENDAR_REQUESTS: buildEndpoint('calendars/calendar-requests/:id/get-document'),
        EDIT_CALENDAR_REQUEST: buildEndpoint('calendars/calendar-requests/:id/edit'),
        UPDATE_CALENDAR_REQUEST: buildEndpoint('calendars/calendar-requests/:id/update')
    },
    EXPENSE_TYPES: {
        GET_EXPENSE_TYPES: buildEndpoint('work-orders/expense-types/:is_system/all-is-system'),
        CREATE_EXPENSE_TYPE: buildEndpoint('work-orders/expense-types/create'),
        STORE_EXPENSE_TYPE: buildEndpoint('work-orders/expense-types/store'),
        EDIT_EXPENSE_TYPE: buildEndpoint('work-orders/expense-types/:id/edit'),
        SHOW_EXPENSE_TYPE: buildEndpoint('work-orders/expense-types/:id/show'),
        UPDATE_EXPENSE_TYPE: buildEndpoint('work-orders/expense-types/:id/update'),
        DELETE_EXPENSE_TYPE: buildEndpoint('work-orders/expense-types/:id/delete'),
        ACTIVE_EXPENSE_TYPE: buildEndpoint('work-orders/expense-types/:id/active')
    },
    USERS: {
        GET_USERS: buildEndpoint('users/users'),
        GET_USER: buildEndpoint('users/users/:id/get'),
        STORE_USER: buildEndpoint('users/users/store'),
        EDIT_USER: buildEndpoint('users/users/:id/edit'),
        EDIT_PASSWORD_USER: buildEndpoint('users/users/:id/edit-password'),
        CREATE_USER: buildEndpoint('users/users/create'),
        SHOW_USER: buildEndpoint('users/users/:id/show'),
        UPDATE_USER: buildEndpoint('users/users/:id/update'),
        UPDATE_PASSWORD_USER: buildEndpoint('users/users/:id/update-password'),
        DELETE_USER: buildEndpoint('users/users/:id/delete'),
        ACTIVE_USER: buildEndpoint('users/users/:id/active')
    },
    GROUPS: {
        GET_GROUPS: buildEndpoint('auths/groups'),
        STORE_GROUP: buildEndpoint('auths/groups/store'),
        EDIT_GROUP: buildEndpoint('auths/groups/:id/edit'),
        CREATE_GROUP: buildEndpoint('auths/groups/create'),
        SHOW_GROUP: buildEndpoint('auths/groups/:id/show'),
        UPDATE_GROUP: buildEndpoint('auths/groups/:id/update'),
        DELETE_GROUP: buildEndpoint('auths/groups/:id/delete'),
        ACTIVE_GROUP: buildEndpoint('auths/groups/:id/active')
    },
    NOTIFICATIONS: {
        GET_ALL_BY_USER_RANGE: buildEndpoint('notifications/notifications/by-user-range'),
        GET_NOT_VIEWED: buildEndpoint('notifications/notifications/not-viewed'),
        SET_AS_VIEWED: buildEndpoint('notifications/notifications/:id/viewed')
    },
    EVENT_TYPES: {
        GET_EVENT_TYPES: buildEndpoint('calendars/event-types'),
        GET_EVENT_TYPE: buildEndpoint('calendars/event-types/:id'),
        CREATE_EVENT_TYPE: buildEndpoint('calendars/event-types/create'),
        STORE_EVENT_TYPE: buildEndpoint('calendars/event-types/store'),
        EDIT_EVENT_TYPE: buildEndpoint('calendars/event-types/:id/edit'),
        SHOW_EVENT_TYPE: buildEndpoint('calendars/event-types/:id/show'),
        UPDATE_EVENT_TYPE: buildEndpoint('calendars/event-types/:id/update'),
        DELETE_EVENT_TYPE: buildEndpoint('calendars/event-types/:id/delete'),
        ACTIVE_EVENT_TYPE: buildEndpoint('calendars/event-types/:id/active')
    },
    PLACES: {
        GET_PLACES: buildEndpoint('places/places'),
        GET_PLACE: buildEndpoint('places/places/:id'),
        CREATE_PLACE: buildEndpoint('places/places/create'),
        STORE_PLACE: buildEndpoint('places/places/store'),
        EDIT_PLACE: buildEndpoint('places/places/:id/edit'),
        SHOW_PLACE: buildEndpoint('places/:id/show'),
        UPDATE_PLACE: buildEndpoint('places/places/:id/update'),
        DELETE_PLACE: buildEndpoint('places/places/:id/delete'),
        ACTIVE_PLACE: buildEndpoint('places/places/:id/active')
    },
    CLIENTS: {
        GET_CLIENTS: buildEndpoint('clients/clients'),
        GET_CLIENT: buildEndpoint('clients/clients/:id'),
        STORE_CLIENT: buildEndpoint('clients/clients/store'),
        EDIT_CLIENT: buildEndpoint('clients/clients/:id/edit'),
        CREATE_CLIENT: buildEndpoint('clients/clients/create'),
        SHOW_CLIENT: buildEndpoint('clients/clients/:id/show'),
        UPDATE_CLIENT: buildEndpoint('clients/clients/:id/update'),
        DELETE_CLIENT: buildEndpoint('clients/clients/:id/delete'),
        ACTIVE_CLIENT: buildEndpoint('clients/clients/:id/active')
    },
    SOCIETIES: {
        GET_SOCIETIES: buildEndpoint('societies/societies'),
        STORE_SOCIETY: buildEndpoint('societies/societies/store'),
        EDIT_SOCIETY: buildEndpoint('societies/societies/:id/edit'),
        CREATE_SOCIETY: buildEndpoint('societies/societies/create'),
        SHOW_SOCIETY: buildEndpoint('societies/societies/:id/show'),
        UPDATE_SOCIETY: buildEndpoint('societies/societies/:id/update'),
        GET_SOCIETY: buildEndpoint('societies/societies/:id/get'),
        DELETE_SOCIETY: buildEndpoint('societies/societies/:id/delete'),
        ACTIVE_SOCIETY: buildEndpoint('societies/societies/:id/active')
    },
    SOCIETY_DOCUMENTS: {
        HISTORY_BY_SOCIETY_AND_TYPE: buildEndpoint(
            'societies/society-documents/:society_id/:document_id/history'
        ),
        GET_SOCIETY_DOCUMENTS_BY_SOCIETY: buildEndpoint(
            'societies/society-documents/:id/by-society'
        ),
        STORE_SOCIETY_DOCUMENT: buildEndpoint('societies/society-documents/store'),
        EDIT_SOCIETY_DOCUMENT: buildEndpoint(
            'societies/society-documents/:society_id/:document_id/edit'
        )
    },

    SOCIETIES_SIGNATURES: {
        GET_SOCIETY_SIGNATURES: buildEndpoint('societies/societies-signatures'),
        CREATE_SOCIETY_SIGNATURE: buildEndpoint('societies/societies-signatures/create'),
        STORE_SOCIETY_SIGNATURE: buildEndpoint('societies/societies-signatures/store'),
        EDIT_SOCIETY_SIGNATURE: buildEndpoint('societies/societies-signatures/:id/edit'),
        UPDATE_SOCIETY_SGINATURE: buildEndpoint('societies/societies-signatures/:id/update'),
        DELETE_SOCIETY_SIGNATURE: buildEndpoint('societies/societies-signatures/:id/delete')
    },
    CARRIERS: {
        GET_CARRIERS: buildEndpoint('carriers/carriers'),
        GET_CARRIER: buildEndpoint('carriers/carriers/:id/get'),
        CREATE_CARRIER: buildEndpoint('carriers/carriers/create'),
        STORE_CARRIER: buildEndpoint('carriers/carriers/store'),
        EDIT_CARRIER: buildEndpoint('carriers/carriers/:id/edit'),
        SHOW_CARRIER: buildEndpoint('carriers/:id/show'),
        UPDATE_CARRIER: buildEndpoint('carriers/carriers/:id/update'),
        DELETE_CARRIER: buildEndpoint('carriers/carriers/:id/delete'),
        ACTIVE_CARRIER: buildEndpoint('carriers/carriers/:id/active')
    },
    CARRIER_TYPES: {
        GET_CARRIER_TYPES: buildEndpoint('carriers/carrier-types'),
        STORE_CARRIER_TYPE: buildEndpoint('carriers/carrier-types/store'),
        EDIT_CARRIER_TYPE: buildEndpoint('carriers/carrier-types/:id/edit'),
        SHOW_CARRIER_TYPE: buildEndpoint('carriers/carrier-types/:id/show'),
        UPDATE_CARRIER_TYPE: buildEndpoint('carriers/carrier-types/:id/update'),
        DELETE_CARRIER_TYPE: buildEndpoint('carriers/carrier-types/:id/delete'),
        ACTIVE_CARRIER_TYPE: buildEndpoint('carriers/carrier-types/:id/active')
    },
    CARRIER_DOCUMENT: {
        ALL_CARRIER_DOCUMENTS_BY_CARRIER_TYPE: buildEndpoint(
            'carriers/carrier-documents/:carrier_id/:type'
        ),
        STORE_CARRIER_DOCUMENT: buildEndpoint('carriers/carrier-documents/store')
    },
    DOCUMENTS: {
        GET_DOCUMENTS: buildEndpoint('documents/documents'),
        CREATE_DOCUMENT: buildEndpoint('documents/documents/create'),
        STORE_DOCUMENT: buildEndpoint('documents/documents/store'),
        EDIT_DOCUMENT: buildEndpoint('documents/documents/:id/edit'),
        UPDATE_DOCUMENT: buildEndpoint('documents/documents/:id/update'),
        DELETE_DOCUMENT: buildEndpoint('documents/documents/:id/delete'),
        ACTIVE_DOCUMENT: buildEndpoint('documents/documents/:id/active')
    },
    DOCUMENT_SETS: {
        GET_DOCUMENT_SET: buildEndpoint('documents/document-sets'),
        DELETE_DOCUMENT_SET: buildEndpoint('documents/document-set/:id/delete'),
        ACTIVE_DOCUMENT_SET: buildEndpoint('documents/document-set/:id/active'),
        CREATE_DOCUMENT_SET: buildEndpoint('documents/document-set/create'),
        EDIT_DOCUMENT_SET: buildEndpoint('documents/document-set/:id/edit'),
        UPDATE_DOCUMENT_SET: buildEndpoint('documents/document-set/:id/update'),
        STORE_DOCUMENT_SET: buildEndpoint('documents/document-set/store')
    },
    TRUCKS: {
        GET_TRUCKS: buildEndpoint('carriers/trucks'),
        GET_TRUCK: buildEndpoint('carriers/trucks/:id'),
        CREATE_TRUCK: buildEndpoint('carriers/trucks/create'),
        STORE_TRUCK: buildEndpoint('carriers/trucks/store'),
        EDIT_TRUCK: buildEndpoint('carriers/trucks/:id/edit'),
        SHOW_TRUCK: buildEndpoint('carriers/trucks/:id/show'),
        UPDATE_TRUCK: buildEndpoint('carriers/trucks/:id/update'),
        DELETE_TRUCK: buildEndpoint('carriers/trucks/:id/delete'),
        ACTIVE_TRUCK: buildEndpoint('carriers/trucks/:id/active')
    },
    PROVIDER_TYPES: {
        GET_PROVIDER_TYPES: buildEndpoint('providers/provider_types'),
        CREATE_PROVIDER_TYPE: buildEndpoint('providers/provider_types/create'),
        STORE_PROVIDER_TYPE: buildEndpoint('providers/provider_types/store'),
        EDIT_PROVIDER_TYPE: buildEndpoint('providers/provider_types/:id/edit'),
        SHOW_PROVIDER_TYPE: buildEndpoint('providers/provider_types/:id/show'),
        UPDATE_PROVIDER_TYPE: buildEndpoint('providers/provider_types/:id/update'),
        DELETE_PROVIDER_TYPE: buildEndpoint('providers/provider_types/:id/delete'),
        ACTIVE_PROVIDER_TYPE: buildEndpoint('providers/provider_types/:id/active')
    },
    TRUCK_DOCUMENT: {
        HISTORY_BY_TRUCK_AND_TYPE: buildEndpoint(
            'carriers/truck-documents/:truck_id/:document_id/history'
        ),
        GET_TRUCK_DOCUMENTS_BY_TRUCK: buildEndpoint('carriers/truck-documents/:id/by-truck'),
        STORE_TRUCK_DOCUMENT: buildEndpoint('carriers/truck-documents/store')
    },
    HOPPERS: {
        GET_HOPPERS: buildEndpoint('carriers/hoppers'),
        GET_HOPPER: buildEndpoint('carriers/hoppers/:id'),
        CREATE_HOPPER: buildEndpoint('carriers/hoppers/create'),
        STORE_HOPPER: buildEndpoint('carriers/hoppers/store'),
        EDIT_HOPPER: buildEndpoint('carriers/hoppers/:id/edit'),
        SHOW_HOPPER: buildEndpoint('carriers/hoppers/:id/show'),
        UPDATE_HOPPER: buildEndpoint('carriers/hoppers/:id/update'),
        DELETE_HOPPER: buildEndpoint('carriers/hoppers/:id/delete'),
        ACTIVE_HOPPER: buildEndpoint('carriers/hoppers/:id/active')
    },
    HOPPER_DOCUMENT: {
        HISTORY_BY_HOPPER_AND_TYPE: buildEndpoint(
            'carriers/hopper-documents/:hopper_id/:document_id/history'
        ),
        GET_HOPPER_DOCUMENTS_BY_HOPPER: buildEndpoint('carriers/hopper-documents/:id/by-hopper'),
        STORE_HOPPER_DOCUMENT: buildEndpoint('carriers/hopper-documents/store')
    },
    PRODUCTS: {
        GET_PRODUCTS: buildEndpoint('products/products'),
        CREATE_PRODUCT: buildEndpoint('products/products/create'),
        STORE_PRODUCT: buildEndpoint('products/products/store'),
        EDIT_PRODUCT: buildEndpoint('products/products/:id/edit'),
        SHOW_PRODUCT: buildEndpoint('products/products/:id/show'),
        UPDATE_PRODUCT: buildEndpoint('products/products/:id/update'),
        DELETE_PRODUCT: buildEndpoint('products/products/:id/delete'),
        ACTIVE_PRODUCT: buildEndpoint('products/products/:id/active')
    },
    UNITS: {
        GET_UNITS: buildEndpoint('products/units'),
        STORE_UNIT: buildEndpoint('products/units/store'),
        EDIT_UNIT: buildEndpoint('products/units/:id/edit'),
        SHOW_UNIT: buildEndpoint('products/units/:id/show'),
        UPDATE_UNIT: buildEndpoint('products/units/:id/update'),
        DELETE_UNIT: buildEndpoint('products/units/:id/delete'),
        ACTIVE_UNIT: buildEndpoint('products/units/:id/active')
    },
    UNIT_EQUIVALENCE: {
        GET_UNIT_EQUIVALENCES: buildEndpoint('products/unit-equivalences'),
        CREATE_UNIT_EQUIVALENCE: buildEndpoint('products/unit-equivalences/create'),
        STORE_UNIT_EQUIVALENCE: buildEndpoint('products/unit-equivalences/store'),
        EDIT_UNIT_EQUIVALENCE: buildEndpoint('products/unit-equivalences/:id/edit'),
        UPDATE_UNIT_EQUIVALENCE: buildEndpoint('products/unit-equivalences/:id/update'),
        DELETE_UNIT_EQUIVALENCE: buildEndpoint('products/unit-equivalences/:id/delete'),
        ACTIVE_UNIT_EQUIVALENCE: buildEndpoint('products/unit-equivalences/:id/active'),
        CALCULATE_UNIT_EQUIVALENCE: buildEndpoint('products/unit-equivalences/calculate')
    },
    CURRENCIES: {
        GET_CURRENCIES: buildEndpoint('currencies/currencies'),
        CREATE_CURRENCY: buildEndpoint('currencies/currencies/create'),
        STORE_CURRENCY: buildEndpoint('currencies/currencies/store'),
        EDIT_CURRENCY: buildEndpoint('currencies/currencies/:id/edit'),
        SHOW_CURRENCY: buildEndpoint('currencies/currencies/:id/show'),
        UPDATE_CURRENCY: buildEndpoint('currencies/currencies/:id/update'),
        DELETE_CURRENCY: buildEndpoint('currencies/currencies/:id/delete'),
        ACTIVE_CURRENCY: buildEndpoint('currencies/currencies/:id/active')
    },
    FOREIGN_EXCHANGE: {
        GET_FOREIGN_EXCHANGE: buildEndpoint('currencies/foreign-exchanges')
    },
    PROVIDERS: {
        GET_PROVIDERS: buildEndpoint('providers/providers'),
        GET_PROVIDER: buildEndpoint('providers/providers/:id'),
        CREATE_PROVIDER: buildEndpoint('providers/providers/create'),
        STORE_PROVIDER: buildEndpoint('providers/providers/store'),
        EDIT_PROVIDER: buildEndpoint('providers/providers/:id/edit'),
        SHOW_PROVIDER: buildEndpoint('providers/:id/show'),
        UPDATE_PROVIDER: buildEndpoint('providers/providers/:id/update'),
        DELETE_PROVIDER: buildEndpoint('providers/providers/:id/delete'),
        ACTIVE_PROVIDER: buildEndpoint('providers/providers/:id/active')
    },
    WORK_ORDERS: {
        GET_WORK_ORDERS: buildEndpoint('work-orders/work-orders'),
        STORE_WORK_ORDERS: buildEndpoint('work-orders/work-orders/store'),
        CREATE_WORK_ORDERS: buildEndpoint('work-orders/work-orders/create'),
        DELETE_WORK_ORDER: buildEndpoint('work-orders/work-orders/:id/delete'),
        EDIT_WORK_ORDER: buildEndpoint('work-orders/work-orders/:id/edit'),
        SHOW_WORK_ORDER: buildEndpoint('work-orders/work-orders/:id/show'),
        UPDATE_WORK_ORDER: buildEndpoint('work-orders/work-orders/:id/update'),
        HAS_ALL_FINISHED_STATUSES_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/work-orders/:id/:work_order_status/has-finished'
        ),
        CHANGE_STATUS_TRANSPORT_ORDER: buildEndpoint('work-orders/work-orders/:id/change-status')
    },
    TRANSPORT_ORDERS: {
        GET_TRANSPORT_ORDER: buildEndpoint('work-orders/transport-orders'),
        GET_TRANSPORT_ORDER_BY_CARRIER_CHART: buildEndpoint(
            'work-orders/transport-orders/all-transport-order-by-carrier-chart'
        ),
        GET_TRANSPORT_ORDER_BY_WORK_ORDER: buildEndpoint(
            'work-orders/transport-orders/:work_order_id/by-work-order'
        ),
        GET_TRANSPORT_ORDERS_TO_DISPATCH_PROGRAM: buildEndpoint(
            'work-orders/transport-orders/:dispatch_program_id/by-dispatch-program'
        ),
        GET_EXCEL_TRANSPORT_ORDERS_BY_WORK_ORDER: buildEndpoint(
            'work-orders/transport-orders/:work_order_id/by-work-order/excel'
        ),
        STORE_TRANSPORT_ORDER: buildEndpoint('work-orders/transport-orders/store'),
        CREATE_TRANSPORT_ORDER: buildEndpoint('work-orders/transport-orders/:work_order_id/create'),
        DELETE_TRANSPORT_ORDER: buildEndpoint('work-orders/transport-orders/:id/delete'),
        EDIT_TRANSPORT_ORDER: buildEndpoint('work-orders/transport-orders/:id/edit'),
        SHOW_TRANSPORT_ORDER: buildEndpoint('work-orders/transport-orders/:id/show'),
        UPDATE_TRANSPORT_ORDER: buildEndpoint('work-orders/transport-orders/:id/update'),
        VALIDATE_STATUS_CHANGE_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/transport-orders/:id/:transport_order_status/validate-status'
        ),
        CHANGE_STATUS_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/transport-orders/:id/change-status'
        ),
        UNLOCK_STATUS_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/transport-orders/:id/unlock-status'
        ),
        GET_EXCEL_TRANSPORT_ORDERS: buildEndpoint('work-orders/transport-orders/excel'),
        GET_TRANSPORT_ORDER_BY_ID_WITH_GUIDE: buildEndpoint(
            'work-orders/transport-orders/:id/get-transport-order-with-guide'
        )
    },
    TRANSPORT_ORDER_STATUS_HISTORIES: {
        GET_TRANSPORT_ORDER_STATUS_HISTORIES_BY_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/transport-order-status-histories/:transport_order_id/by-transport-order'
        )
    },
    MONTHLY_RECONCILIATIONS: {
        GET_MONTHLY_RECONCILIATIONS: buildEndpoint('work-orders/monthly-reconciliations'),
        GET_EXCEL_MONTHLY_RECONCILIATIONS: buildEndpoint(
            'work-orders/monthly-reconciliations/excel'
        )
    },
    WORK_ORDER_STATUS_HISTORIES: {
        GET_WORK_ORDER_STATUS_HISTORIES_BY_WORK_ORDER: buildEndpoint(
            'work-orders/work-order-status-histories/:transport_work_id/by-work-order'
        )
    },
    EXPENSES: {
        GET_EXPENSES_BY_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/expenses/:transport_order_id/by-transport-order'
        ),
        GET_EXPENSES: buildEndpoint('work-orders/expenses'),
        DOCUMENT_EXPENSE: buildEndpoint('work-orders/expenses/:id/get-document'),
        DOCUMENT_EXPENSE_ON_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/expenses/:transport_order_id/:id/get-document-to'
        ),
        DOCUMENT_EXPENSE_ON_CALENDAR: buildEndpoint('work-orders/expenses/:id/get-document-to'),
        CREATE_EXPENSE: buildEndpoint('work-orders/expenses/:transport_order_id/create'),
        CREATE_CALENDAR_EXPENSE: buildEndpoint('work-orders/expenses/create'),
        STORE_EXPENSE: buildEndpoint('work-orders/expenses/:transport_order_id/store'),
        STORE_EXPENSE_BY_EVENT: buildEndpoint('work-orders/expenses/:event_id/store_by_event'),
        EDIT_EXPENSE: buildEndpoint('work-orders/expenses/:transport_order_id/:id/edit'),
        UPDATE_EXPENSE: buildEndpoint('work-orders/expenses/:transport_order_id/:id/update'),
        EDIT_CALENDAR_EXPENSE: buildEndpoint('work-orders/expenses/:id/edit'),
        UPDATE_CALENDAR_EXPENSE: buildEndpoint('work-orders/expenses/:id/update'),
        DELETE_EXPENSE: buildEndpoint('work-orders/expenses/:transport_order_id/:id/delete'),
        DELETE_CALENDAR_EXPENSE: buildEndpoint('work-orders/expenses/:id/delete'),
        GET_EXPENSE_ON_CHECKING_ACCOUNT_CREATE: buildEndpoint('work-orders/expenses/:id/get'),
        GET_EXPENSE_ON_CHECKING_ACCOUNT_EDIT: buildEndpoint(
            'work-orders/expenses/:id/:checking_account_id/get'
        ),
        GET_EXPENSES_BY_DATES: buildEndpoint('work-orders/expenses/get-by-dates'),
        GET_EXPENSES_BY_EVENT_ID: buildEndpoint('work-orders/expenses/get-by-event-id/:event_id'),
        SHOW_EXPENSE: buildEndpoint('work-orders/expenses/:transport_order_id/:id/show'),
        APPROVED_EXPENSE: buildEndpoint('work-orders/expenses/:transport_order_id/:id/approved'),
        CANCEL_APPROVED_EXPENSE: buildEndpoint('work-orders/expenses/:id/cancel-approved'),
        APPROVED_CALENDAR_EXPENSE: buildEndpoint('work-orders/expenses/:id/approved'),
        CHANGE_APPROVE_ROWS_FROM_EXPENSE: buildEndpoint('work-orders/expenses/change-rows-approve')
    },
    EXPENSE_HISTORIES: {
        GET_EXPENSE_HISTORIES_BY_EXPENSE: buildEndpoint(
            'work-orders/expense-histories/:expense_id/by-expense'
        ),
        EDIT_EXPENSE_HISTORY: buildEndpoint('work-orders/expense-histories/:id/edit'),
        UPDATE_EXPENSE_HISTORY: buildEndpoint('work-orders/expense-histories/:id/update')
    },
    TOLL_EXPENSES: {
        GET_TOLL_EXPENSES_BY_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/toll-expenses/:transport_order_id/by-transport-order'
        ),
        GET_TOLL_EXPENSES: buildEndpoint('work-orders/expenses'),
        DOCUMENT_TOLL_EXPENSE_ON_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/toll-expenses/:transport_order_id/:id/get-document-to'
        ),
        CREATE_TOLL_EXPENSE: buildEndpoint('work-orders/toll-expenses/:transport_order_id/create'),
        STORE_TOLL_EXPENSE: buildEndpoint('work-orders/toll-expenses/:transport_order_id/store'),
        EDIT_TOLL_EXPENSE: buildEndpoint('work-orders/toll-expenses/:transport_order_id/:id/edit'),
        UPDATE_TOLL_EXPENSE: buildEndpoint(
            'work-orders/toll-expenses/:transport_order_id/:id/update'
        ),
        DELETE_TOLL_EXPENSE: buildEndpoint(
            'work-orders/toll-expenses/:transport_order_id/:id/delete'
        ),
        SHOW_TOLL_EXPENSE: buildEndpoint('work-orders/toll-expenses/:transport_order_id/:id/show'),
        APPROVED_TOLL_EXPENSE: buildEndpoint(
            'work-orders/toll-expenses/:transport_order_id/:id/approved'
        ),
        CANCEL_APPROVED_TOLL_EXPENSE: buildEndpoint(
            'work-orders/toll-expenses/:id/cancel-approved'
        )
    },
    TOLL_EXPENSE_HISTORIES: {
        GET_TOLL_EXPENSE_HISTORIES_BY_TOLL_EXPENSE: buildEndpoint(
            'work-orders/toll-expense-histories/:toll_expense_id/by-toll-expense'
        ),
        EDIT_TOLL_EXPENSE_HISTORY: buildEndpoint('work-orders/toll-expense-histories/:id/edit'),
        UPDATE_TOLL_EXPENSE_HISTORY: buildEndpoint('work-orders/toll-expense-histories/:id/update')
    },
    LOAD_TICKETS: {
        GET_LOAD_TICKET_BY_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/load-tickets/:transport_order_id/by-transport-order'
        ),
        GET_LOAD_TICKETS: buildEndpoint('work-orders/load-tickets'),
        CREATE_LOAD_TICKET: buildEndpoint('work-orders/load-tickets/:transport_order_id/create'),
        STORE_LOAD_TICKET: buildEndpoint('work-orders/load-tickets/:transport_order_id/store'),
        EDIT_LOAD_TICKET: buildEndpoint('work-orders/load-tickets/:transport_order_id/:id/edit'),
        UPDATE_LOAD_TICKET: buildEndpoint(
            'work-orders/load-tickets/:transport_order_id/:id/update'
        ),
        DELETE_LOAD_TICKET: buildEndpoint(
            'work-orders/load-tickets/:transport_order_id/:id/delete'
        ),
        SHOW_LOAD_TICKET: buildEndpoint('work-orders/load-tickets/:transport_order_id/:id/show'),
        DOCUMENT_LOAD_TICKET: buildEndpoint('work-orders/load-tickets/:id/get-document'),
        DOCUMENT_LOAD_TICKET_ON_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/load-tickets/:transport_order_id/:id/get-document-to'
        ),
        APPROVED_LOAD_TICKET: buildEndpoint(
            'work-orders/load-tickets/:transport_order_id/:id/approved'
        ),
        CANCEL_APPROVED_LOAD_TICKET: buildEndpoint(
            'work-orders/load-tickets/:id/cancel-approved'
        )
    },
    LOAD_TICKET_HISTORIES: {
        GET_LOAD_TICKET_HISTORIES_BY_LOAD_TICKETE: buildEndpoint(
            'work-orders/load-ticket-histories/:load_ticket_id/by-load-ticket'
        ),
        EDIT_LOAD_TICKET_HISTORY: buildEndpoint('work-orders/load-ticket-histories/:id/edit'),
        UPDATE_LOAD_TICKET_HISTORY: buildEndpoint('work-orders/load-ticket-histories/:id/update')
    },
    GUIDES: {
        GET_GUIDES_BY_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/guides/:transport_order_id/by-transport-order'
        ),
        GET_GUIDES: buildEndpoint('work-orders/guides'),
        CREATE_GUIDE: buildEndpoint('work-orders/guides/:transport_order_id/create'),
        STORE_GUIDE: buildEndpoint('work-orders/guides/:transport_order_id/store'),
        EDIT_GUIDE: buildEndpoint('work-orders/guides/:transport_order_id/:id/edit'),
        UPDATE_GUIDE: buildEndpoint('work-orders/guides/:transport_order_id/:id/update'),
        DELETE_GUIDE: buildEndpoint('work-orders/guides/:transport_order_id/:id/delete'),
        SHOW_GUIDE: buildEndpoint('work-orders/guides/:transport_order_id/:id/show'),
        DOCUMENT_GUIDE: buildEndpoint('work-orders/guides/:id/get-document'),
        DOCUMENT_GUIDE_ON_TRANSPORT_ORDER: buildEndpoint(
            'work-orders/guides/:transport_order_id/:id/get-document-to'
        ),
        APPROVED_GUIDE: buildEndpoint('work-orders/guides/:transport_order_id/:id/approved'),
        CANCEL_APPROVED_GUIDE: buildEndpoint('work-orders/guides/:id/cancel-approved')
    },
    GUIDE_HISTORIES: {
        GET_GUIDE_HISTORIES_BY_GUIDE: buildEndpoint(
            'work-orders/guide-histories/:guide_id/by-guide'
        ),
        EDIT_GUIDE_HISTORY: buildEndpoint('work-orders/guide-histories/:id/edit'),
        UPDATE_GUIDE_HISTORY: buildEndpoint('work-orders/guide-histories/:id/update')
    },

    INCIDENTS: {
        GET_INCIDENTS: buildEndpoint('work-orders/incidents/:transport_order_id/by-transport-order'),
        STORE_INCIDENT: buildEndpoint('work-orders/incidents/store'),
        CREATE_INCIDENT: buildEndpoint('work-orders/incidents/create'),
        DELETE_INCIDENT: buildEndpoint('work-orders/incidents/:id/delete'),
        EDIT_INCIDENT: buildEndpoint('work-orders/incidents/:id/edit'),
        SHOW_INCIDENT: buildEndpoint('work-orders/incidents/:id/show'),
        UPDATE_INCIDENT: buildEndpoint('work-orders/incidents/:id/update'),
        OPEN_INCIDENT: buildEndpoint('work-orders/incidents/:id/open'), 
        CLOSE_INCIDENT: buildEndpoint('work-orders/incidents/:id/close'),
        ANNUL_INCIDENT: buildEndpoint('work-orders/incidents/:id/annul'),
        GET_EVIDENCE_INCIDENT: buildEndpoint('work-orders/incidents/:id/get-evidence'),

    },

    ROUTE_SEGMENTS: {
        GET_ROUTE_SEGMENTS: buildEndpoint('route-segments/route-segments'),
        CREATE_ROUTE_SEGMENTS: buildEndpoint('route-segments/route-segments/create'),
        STORE_ROUTE_SEGMENT: buildEndpoint('route-segments/route-segments/store'),
        EDIT_ROUTE_SEGMENT: buildEndpoint('route-segments/route-segments/:id/edit'),
        UPDATE_ROUTE_SEGMENT: buildEndpoint('route-segments/route-segments/:id/update'),
        SHOW_RS_TO_QUOTE_ROUTE_SEGMENT: buildEndpoint(
            'route-segments/route-segments/:quote_route_segment_id/:id/show-route-segment'
        ),
        SHOW_RS_TO_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'route-segments/route-segments/:transportation_quote_detail_id/:id/show-route-segment-tqd'
        ),
        SHOW_RS_TO_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'route-segments/route-segments/:transportation_order_item_id/:id/show-route-segment-toi'
        ),
        DELETE_ROUTE_SEGMENT: buildEndpoint('route-segments/route-segments/:id/delete'),

        GET_QUOTE_ROUTE_SEGMENTS: buildEndpoint('route-segments/quote-route-segments/:id/all'),
        CREATE_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/create'),
        STORE_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/store'),
        EDIT_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/:id/edit'),
        UPDATE_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/:id/update'),
        DELETE_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/:id/delete'),
        SHOW_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/:id/show'),
        GET_QUOTE_ROUTE_SEGMENT: buildEndpoint('route-segments/quote-route-segments/:id/get'),
        INVERSE_ROUTE_SEGMENTS: buildEndpoint('route-segments/route-segments/:id/get-inverse')
    },
    TOLLS: {
        GET_TOLLS: buildEndpoint('tolls/tolls'),
        GET_TOLL: buildEndpoint('tolls/tolls/:id'),
        CREATE_TOLL: buildEndpoint('tolls/tolls/create'),
        STORE_TOLL: buildEndpoint('tolls/tolls/store'),
        EDIT_TOLL: buildEndpoint('tolls/tolls/:id/edit'),
        SHOW_TOLL: buildEndpoint('tolls/tolls/:id/show'),
        UPDATE_TOLL: buildEndpoint('tolls/tolls/:id/update'),
        DELETE_TOLL: buildEndpoint('tolls/tolls/:id/delete')
    },
    TOLL_RATES: {
        GET_TOLL_RATES_BY_TOLL: buildEndpoint('tolls/toll-rates/:toll_id/by-toll'),
        STORE_TOLL_RATE: buildEndpoint('tolls/toll-rates/store'),
        CREATE_TOLL_RATE: buildEndpoint('tolls/toll-rates/create'),
        DELETE_TOLL_RATE: buildEndpoint('tolls/toll-rates/:id/delete'),
        EDIT_TOLL_RATE: buildEndpoint('tolls/toll-rates/:id/edit'),
        SHOW_TOLL_RATE: buildEndpoint('tolls/toll-rates/:id/show'),
        UPDATE_TOLL_RATE: buildEndpoint('tolls/toll-rates/:id/update'),
        GET_TOLL_RATE_BY_TOLL: buildEndpoint(
            'tolls/toll-rates/:transport_order_id/:toll_id/:toll_expense_id/get-rate'
        )
    },
    AFPS: {
        GET_AFPS: buildEndpoint('afps/afps'),
        STORE_AFP: buildEndpoint('afps/afps/store'),
        EDIT_AFP: buildEndpoint('afps/afps/:id/edit'),
        SHOW_AFP: buildEndpoint('afps/afps/:id/show'),
        UPDATE_AFP: buildEndpoint('afps/afps/:id/update'),
        DELETE_AFP: buildEndpoint('afps/afps/:id/delete'),
        ACTIVE_AFP: buildEndpoint('afps/afps/:id/active')
    },
    AFP_RATES: {
        GET_AFP_RATES_BY_AFP: buildEndpoint('afps/afps-rates/:afp_id/by-afp'),
        STORE_AFP_RATE: buildEndpoint('afps/afps-rates/store'),
        CREATE_AFP_RATE: buildEndpoint('afps/afps-rates/create'),
        DELETE_AFP_RATE: buildEndpoint('afps/afps-rates/:id/delete'),
        EDIT_AFP_RATE: buildEndpoint('afps/afps-rates/:id/edit'),
        SHOW_AFP_RATE: buildEndpoint('afps/afps-rates/:id/show'),
        UPDATE_AFP_RATE: buildEndpoint('afps/afps-rates/:id/update'),
        ACTIVE_AFP_RATE: buildEndpoint('afps/afps-rates/:id/active')
    },
    BANKS: {
        GET_BANKS: buildEndpoint('banks/banks'),
        STORE_BANK: buildEndpoint('banks/banks/store'),
        EDIT_BANK: buildEndpoint('banks/banks/:id/edit'),
        SHOW_BANK: buildEndpoint('banks/banks/:id/show'),
        UPDATE_BANK: buildEndpoint('banks/banks/:id/update'),
        DELETE_BANK: buildEndpoint('banks/banks/:id/delete'),
        ACTIVE_BANK: buildEndpoint('banks/banks/:id/active')
    },
    BANK_ACCOUNT: {
        BANK_ACCOUNT_BY_REASON: buildEndpoint('banks/bank-account/by-reason/:reason_id/:worker_id')
    },
    TRANSFER_REASONS: {
        GET_TRANSFER_REASONS: buildEndpoint('banks/transfer-reasons'),
        STORE_TRANSFER_REASON: buildEndpoint('banks/transfer-reasons/store'),
        EDIT_TRANSFER_REASON: buildEndpoint('banks/transfer-reasons/:id/edit'),
        SHOW_TRANSFER_REASON: buildEndpoint('banks/transfer-reasons/:id/show'),
        UPDATE_TRANSFER_REASON: buildEndpoint('banks/transfer-reasons/:id/update'),
        DELETE_TRANSFER_REASON: buildEndpoint('banks/transfer-reasons/:id/delete'),
        ACTIVE_TRANSFER_REASON: buildEndpoint('banks/transfer-reasons/:id/active')
    },
    BANK_ACCOUNT_TYPES: {
        GET_BANK_ACCOUNT_TYPES: buildEndpoint('banks/bank-account-types'),
        STORE_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/store'),
        EDIT_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/:id/edit'),
        SHOW_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/:id/show'),
        UPDATE_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/:id/update'),
        DELETE_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/:id/delete'),
        ACTIVE_BANK_ACCOUNT_TYPE: buildEndpoint('banks/bank-account-types/:id/active')
    },

    CHECKING_ACCOUNTS: {
        GET_CHECKING_ACCOUNTS: buildEndpoint('banks/checking-accounts'),
        CREATE_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/create'),
        STORE_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/store'),
        EDIT_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/:id/edit'),
        SHOW_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/:id/show'),
        UPDATE_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/:id/update'),
        DELETE_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/:id/delete'),
        ACTIVE_CHECKING_ACCOUNT: buildEndpoint('banks/checking-accounts/:id/active')
    },
    CLASIFICATIONS: {
        GET_CLASIFICATIONS: buildEndpoint('accounts/clasifications'),
        CREATE_CLASIFICATIONS: buildEndpoint('accounts/clasifications/create'),
        STORE_CLASIFICATION: buildEndpoint('accounts/clasifications/store'),
        EDIT_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/edit'),
        UPDATE_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/update'),
        DELETE_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/delete'),
        ACTIVE_CLASIFICATION: buildEndpoint('accounts/clasifications/:id/active')
    },
    INTERNAL_SETTLEMENTS: {
        GET_INTERNAL_SETTLEMENTS: buildEndpoint('banks/internal-settlements'),
        GET_EXCEL_INTERNAL_SETTLEMENTS: buildEndpoint('banks/internal-settlements/excel'),
        SHOW_INTERNAL_SETTLEMENT: buildEndpoint('banks/internal-settlements/:id/show')
    },
    PROVISIONS: {
        GET_PROVISIONS: buildEndpoint('provisions/provisions'),
        STORE_PROVISION: buildEndpoint('provisions/provisions/store'),
        EDIT_PROVISION: buildEndpoint('provisions/provisions/:id/edit'),
        SHOW_PROVISION: buildEndpoint('provisions/provisions/:id/show'),
        UPDATE_PROVISION: buildEndpoint('provisions/provisions/:id/update'),
        DELETE_PROVISION: buildEndpoint('provisions/provisions/:id/delete'),
        ACTIVE_PROVISION: buildEndpoint('provisions/provisions/:id/active')
    },
    PROVISIONS_RATES: {
        GET_PROVISIONS_RATES_BY_PROVISIONS: buildEndpoint(
            'provisions/provisions-rates/:provision_id/by-provisions'
        ),
        STORE_PROVISIONS_RATE: buildEndpoint('provisions/provisions-rates/store'),
        CREATE_PROVISIONS_RATE: buildEndpoint('provisions/provisions-rates/create'),
        DELETE_PROVISIONS_RATE: buildEndpoint('provisions/provisions-rates/:id/delete'),
        EDIT_PROVISIONS_RATE: buildEndpoint('provisions/provisions-rates/:id/edit'),
        SHOW_PROVISIONS_RATE: buildEndpoint('provisions/provisions-rates/:id/show'),
        UPDATE_PROVISIONS_RATE: buildEndpoint('provisions/provisions-rates/:id/update'),
        ACTIVE_PROVISIONS_RATE: buildEndpoint('provisions/provisions-rates/:id/active')
    },
    BRANDS: {
        GET_BRANDS: buildEndpoint('carriers/brands'),
        CREATE_BRAND: buildEndpoint('carriers/brands/create'),
        STORE_BRAND: buildEndpoint('carriers/brands/store'),
        EDIT_BRAND: buildEndpoint('carriers/brands/:id/edit'),
        SHOW_BRAND: buildEndpoint('carriers/brands/:id/show'),
        UPDATE_BRAND: buildEndpoint('carriers/brands/:id/update'),
        DELETE_BRAND: buildEndpoint('carriers/brands/:id/delete'),
        ACTIVE_BRAND: buildEndpoint('carriers/brands/:id/active')
    },
    VEHICLE_MODELS: {
        GET_VEHICLE_MODELS: buildEndpoint('carriers/vehicle-models'),
        CREATE_VEHICLE_MODEL: buildEndpoint('carriers/vehicle-models/create'),
        STORE_VEHICLE_MODEL: buildEndpoint('carriers/vehicle-models/store'),
        EDIT_VEHICLE_MODEL: buildEndpoint('carriers/vehicle-models/:id/edit'),
        SHOW_VEHICLE_MODEL: buildEndpoint('carriers/vehicle-models/:id/show'),
        UPDATE_VEHICLE_MODEL: buildEndpoint('carriers/vehicle-models/:id/update'),
        DELETE_VEHICLE_MODEL: buildEndpoint('carriers/vehicle-models/:id/delete'),
        ACTIVE_VEHICLE_MODEL: buildEndpoint('carriers/vehicle-models/:id/active')
    },
    FUEL_CONSUMPTIONS: {
        GET_FUEL_CONSUMPTIONS: buildEndpoint('sitrack/consumptions'),
        GET_EXCEL_FUEL_CONSUMPTIONS: buildEndpoint('sitrack/consumptions/excel')
    },
    RUNNERS: {
        GET_ALL: buildEndpoint('auths/runners/:transportation_order_item_id'),
        ASSIGN_RUNNER: buildEndpoint('auths/assign-runner')
    },
    PAYMENTS: {
        GET_PAYMENT_METHODS: buildEndpoint('payment-methods/'),
        GET_PAYMENT_METHOD: buildEndpoint('payment-methods/:id'),
        GET_PAYMENT_CONDITIONS: buildEndpoint('payment-conditions/'),
        GET_PAYMENT_CONDITION: buildEndpoint('payment-conditions/:id')
    },

    PAYMENT_STATUS: {
        GET_PAYMENT_STATUSES: buildEndpoint('dispatch-programs/payment-status'),
        GET_PAYMENT_STATUS: buildEndpoint('dispatch-programs/payment-status/:id'),
        GET_PAYMENT_DOCUMENT: buildEndpoint('dispatch-programs/payment-status/:id/document'),
        GENERATE_PAYMENT_STATUS: buildEndpoint('dispatch-programs/payment-status/generate'),
        CREATE_PAYMENT_STATUS: buildEndpoint('dispatch-programs/payment-status/create'),
        STORE_PAYMENT_STATUS: buildEndpoint('dispatch-programs/payment-status/store'),
        EDIT_PAYMENT_STATUS: buildEndpoint('dispatch-programs/payment-status/:id/edit'),
        SHOW_PAYMENT_STATUS: buildEndpoint('dispatch-programs/payment-status/::id/show'),
        UPDATE_PAYMENT_STATUS: buildEndpoint('dispatch-programs/payment-status/:id/update'),
        DELETE_PAYMENT_STATUS: buildEndpoint('dispatch-programs/payment-status/:id/delete')
    },

    TRANSPORTATION_ORDERS: {
        GET_EXCEL_TRANSPORTATION_ORDERS: buildEndpoint(
            'transportation-orders/transportation-orders/excel'
        ),
        GET_TRANSPORTATION_ORDERS: buildEndpoint('transportation-orders/transportation-orders'),
        CREATE_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/create'
        ),
        STORE_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/store'
        ),
        SHOW_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/:id/show'
        ),
        EDIT_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/:id/edit'
        ),
        UPDATE_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/:id/update'
        ),
        UPDATE_TRANSPORTATION_ORDER_RATE_RUNNER: buildEndpoint(
            'transportation-orders/transportation-orders/:id/update-rates'
        ),
        DELETE_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/:id/delete'
        ),
        SHOW_QUOTE_TRANSPORTATION_ORDER: buildEndpoint(
            'transportation-orders/transportation-orders/:id/show-quote'
        ),

        GET_TRANSPORTATION_ORDER_ITEMS_BY_ORDER: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/by-order'
        ),
        GET_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id'
        ),
        CREATE_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/create'
        ),
        STORE_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/store'
        ),
        EDIT_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/edit'
        ),
        SHOW_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/show'
        ),
        UPDATE_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/update'
        ),
        DELETE_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/delete'
        ),
        HAS_ALL_FINISHED_TRANSPORTATION_ORDER_ITEMS: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/:status_detail_toi/has-all-finished-transportation-order-items'
        ),
        CHANGE_STATUS_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/change-status'
        ),
        SPLIT_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/split'
        ),
        GET_RATES_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/edit-rates'
        ),
        UPDATE_RATES_TRANSPORTATION_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-order-items/:id/update-rates'
        ),

        GET_TRANSPORTATION_SUB_ORDER_ITEMS_BY_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id/by-item'
        ),
        GET_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id'
        ),
        CREATE_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/create'
        ),

        STORE_TRANSPORTATION_SUB_ORDER_ITEMS: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/store'
        ),
        EDIT_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id/edit'
        ),
        SHOW_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id/show'
        ),
        UPDATE_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id/update'
        ),
        DELETE_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id/delete'
        ),
        DISPATCH_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'transportation-orders/transportation-sub-order-items/:id/dispatch'
        )
    },

    QUOTES: {
        // quote requests
        GET_QUOTES: buildEndpoint('quotes/quotes'),
        GET_EXCEL_QUOTES: buildEndpoint('quotes/quotes/excel'),
        GET_QUOTE: buildEndpoint('quotes/quotes/:id'),
        CREATE_QUOTE: buildEndpoint('quotes/quotes/create'),
        STORE_QUOTE: buildEndpoint('quotes/quotes/store'),
        EDIT_QUOTE: buildEndpoint('quotes/quotes/:id/edit'),
        UPDATE_QUOTE: buildEndpoint('quotes/quotes/:id/update'),
        SHOW_QUOTE: buildEndpoint('quotes/quotes/:id/show'),
        DELETE_QUOTE: buildEndpoint('quotes/quotes/:id/delete'),
        REPEAT_QUOTE: buildEndpoint('quotes/quotes/repeat'),
        DOCUMENT_QUOTE: buildEndpoint('quotes/quotes/:id/document'),
        CHANGE_CHECK_ROUTES_DOCUMENT_QUOTE: buildEndpoint('quotes/quotes/:id/document-routes'),
        CONFIRM_QUOTE: buildEndpoint('quotes/quotes/:id/confirm'),
        REJECT_QUOTE: buildEndpoint('quotes/quotes/:id/reject'),

        CLIENT_REVIEW_QUOTE: buildEndpoint('quotes/quotes/:id/client-review'),
        OPERATION_REVIEW_QUOTE: buildEndpoint('quotes/quotes/:id/operation-review'),

        PREPARATION_QUOTE: buildEndpoint('quotes/quotes/:id/preparation'),

        // quote details
        GET_TRANSPORTATION_QUOTE_DETAILS_BY_REQUEST: buildEndpoint(
            'quotes/transportation-quote-details/:id/by-request'
        ),
        GET_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint('quotes/transportation-quote-details/:id'),
        CREATE_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/create'
        ),
        STORE_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/store'
        ),
        GET_QUOTE_ROUTE_SEGMENTS_TO_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/:quote_id/:route_segment_id/get-quote-rs'
        ),
        EDIT_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/:id/edit'
        ),
        SHOW_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/:id/show'
        ),
        UPDATE_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/:id/update'
        ),
        DELETE_TRANSPORTATION_QUOTE_DETAIL: buildEndpoint(
            'quotes/transportation-quote-details/:id/delete'
        )
    },
    DISPATCH_PROGRAMS: {
        GET_EXCEL_DISPATCH_PROGRAMS: buildEndpoint('dispatch-programs/dispatch-programs/excel'),
        GET_DISPATCH_PROGRAMS: buildEndpoint('dispatch-programs/dispatch-programs'),
        LOAD_BULK: buildEndpoint('dispatch-programs/dispatch-programs/load-bulk'),
        HAS_ALL_FINISHED_DISPATCH_PROGRAM_ITEMS: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/:status_detail_dpi/has-all-finished-dispatch-program-items'
        ),
        CREATE_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/create'),
        STORE_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/store'),
        SHOW_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/:id/show'),
        EDIT_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/:id/edit'),
        UPDATE_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/:id/update'),
        DELETE_DISPATCH_PROGRAM: buildEndpoint('dispatch-programs/dispatch-programs/:id/delete'),
        SHOW_TRANSPORTATION_ORDER_DISPATCH_PROGRAM: buildEndpoint(
            'dispatch-programs/dispatch-programs/:id/show-transportation-order'
        ),

        GET_TRANSPORTATION_SUB_ORDER_ITEMS_DISPATCH_PROGRAM: buildEndpoint(
            'dispatch-programs/dispatch-programs/:id/transportation-sub-order-items'
        ),

        GET_DISPATCH_PROGRAM_ITEMS_BY_DISPATCH: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/by-dispatch'
        ),

        GET_DISPATCH_PROGRAM_ITEMS_BY_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/by-transportation-sub-order-item'
        ),

        GET_EXCEL_DISPATCH_PROGRAM_ITEMS_BY_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'dispatch-programs/excel-dispatch-program-items/:id/by-transportation-sub-order-item'
        ),
        SPLIT_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-programs/:id/split-transportation-sub-order-item'
        ),

        STORE_SPLIT_TRANSPORTATION_SUB_ORDER_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-programs/store-split-transportation-sub-order-item'
        ),

        // DISPATCH PROGRAM ITEMS

        GET_DISPATCH_PROGRAM_ITEM: buildEndpoint('dispatch-programs/dispatch-program-items/:id'),
        CREATE_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/create'
        ),
        STORE_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/store'
        ),
        EDIT_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/edit'
        ),
        SHOW_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/show'
        ),
        UPDATE_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/update'
        ),
        DELETE_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/delete'
        ),

        PREPARATION_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/preparation'
        ),
        PLANNING_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/planification'
        ),
        DISPATCH_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/dispatch'
        ),
        FINISH_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/finish'
        ),
        GET_DISPATCH_PROGRAM_ITEM_DAILY_CARRIERS_AVAILABLE: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/daily-carriers-available'
        ),
        GET_DISPATCH_PROGRAM_ITEM_GUIDE: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/guide'
        ),

        SYNC_DISPATCH_PROGRAM_DAILY_CARRIERS: buildEndpoint(
            'dispatch-programs/dispatch-program-daily-carriers/store'
        ),
        MASSIVE_UPDATE_DISPATCH_PROGRAM_ITEMS: buildEndpoint(
            'dispatch-programs/dispatch-program-items/bulk-dpi'
        ),
        GET_DISPATCH_PROGRAM_ITEM_WITH_TRANSPORT_ORDER: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/get-transport-orders'
        ),
        UPDATE_DISPATCH_PROGRAM_ITEM_WITH_TRANSPORT_ORDER: buildEndpoint(
            'dispatch-programs/dispatch-program-items/:id/update-transport-order'
        ),

        CHANGE_STATUSES_ROWS_TO_DISPATCH_PROGRAM_ITEM: buildEndpoint(
            'dispatch-programs/dispatch-program-items/change-rows-status'
        )
    },
    DISPATCH_PROGRAM_DOCUMENTS: {
        GET_DISPATCH_PROGRAM_DOCUMENTS: buildEndpoint(
            'dispatch-programs/dispatch-program-documents/:dispatch_program_id'
        ),
        UPDATE_DISPATCH_PROGRAM_DOCUMENTS: buildEndpoint(
            'dispatch-programs/dispatch-program-documents/:dispatch_program_id/update'
        )
    },
    DAILY_CARRIERS: {
        GET_DAILY_CARRIERS: buildEndpoint('carriers/daily-carriers'),
        GET_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/:id'),
        CREATE_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/create'),
        STORE_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/store'),
        EDIT_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/:id/edit'),
        SHOW_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/:id/show'),
        UPDATE_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/:id/update'),
        DELETE_DAILY_CARRIER: buildEndpoint('carriers/daily-carriers/:id/delete')
    },
    CONTRACTS: {
        GET_CONTRACTS: buildEndpoint('calendars/contracts'),
        STORE_CONTRACTS: buildEndpoint('calendars/contracts/store'),
        CREATE_CONTRACTS: buildEndpoint('calendars/contracts/create'),
        DELETE_CONTRACTS: buildEndpoint('calendars/contracts/:id/delete'),
        EDIT_CONTRACTS: buildEndpoint('calendars/contracts/:id/edit'),
        UPDATE_CONTRACTS: buildEndpoint('calendars/contracts/:id/update'),
        ACTIVE_CONTRACTS: buildEndpoint('calendars/contracts/:id/active')
    },
    LIQUIDATIONS: {
        GET_LIQUIDATIONS: buildEndpoint('calendars/liquidations'),
        STORE_LIQUIDATIONS: buildEndpoint('calendars/liquidations/store'),
        CREATE_LIQUIDATIONS: buildEndpoint('calendars/liquidations/create'),
        DELETE_LIQUIDATIONS: buildEndpoint('calendars/liquidations/:id/delete'),
        EDIT_LIQUIDATIONS: buildEndpoint('calendars/liquidations/:id/edit'),
        UPDATE_LIQUIDATIONS: buildEndpoint('calendars/liquidations/:id/update'),
        ACTIVE_LIQUIDATIONS: buildEndpoint('calendars/liquidations/:id/active'),
        SHOW_LIQUIDATION: buildEndpoint('calendars/liquidations/:id/show'),
        APPROVED_LIQUIDATION: buildEndpoint('calendars/liquidations/:id/approved'),
        DOCUMENT_LIQUIDATION: buildEndpoint('calendars/liquidations/:id/document'),
    },
    BONUSES: {
        GET_BONUSES: buildEndpoint('calendars/bonus'),
        STORE_BONUSES: buildEndpoint('calendars/bonus/store'),
        CREATE_BONUSES: buildEndpoint('calendars/bonus/create'),
        DELETE_BONUSES: buildEndpoint('calendars/bonus/:id/delete'),
        EDIT_BONUSES: buildEndpoint('calendars/bonus/:id/edit'),
        UPDATE_BONUSES: buildEndpoint('calendars/bonus/:id/update'),
        ACTIVE_BONUSES: buildEndpoint('calendars/bonus/:id/active')
    },
    BONUS_RATES: {
        GET_BONUS_RATES_BY_BONUS: buildEndpoint('calendars/bonus-rates/:bonus_id/by-bonus'),
        STORE_BONUS_RATE: buildEndpoint('calendars/bonus-rates/store'),
        CREATE_BONUS_RATE: buildEndpoint('calendars/bonus-rates/create'),
        DELETE_BONUS_RATE: buildEndpoint('calendars/bonus-rates/:id/delete'),
        EDIT_BONUS_RATE: buildEndpoint('calendars/bonus-rates/:id/edit'),
        UPDATE_BONUS_RATE: buildEndpoint('calendars/bonus-rates/:id/update'),
    },
    DEDUCTIONS: {
        GET_DEDUCTIONS: buildEndpoint('calendars/deductions'),
        STORE_DEDUCTIONS: buildEndpoint('calendars/deductions/store'),
        CREATE_DEDUCTIONS: buildEndpoint('calendars/deductions/create'),
        DELETE_DEDUCTIONS: buildEndpoint('calendars/deductions/:id/delete'),
        EDIT_DEDUCTIONS: buildEndpoint('calendars/deductions/:id/edit'),
        UPDATE_DEDUCTIONS: buildEndpoint('calendars/deductions/:id/update'),
        ACTIVE_DEDUCTIONS: buildEndpoint('calendars/deductions/:id/active')
    },
    DEDUCTION_RATES: {
        GET_DEDUCTION_RATES_BY_DEDUCTION: buildEndpoint('calendars/deduction-rates/:deduction_id/by-deduction'),
        STORE_DEDUCTION_RATE: buildEndpoint('calendars/deduction-rates/store'),
        CREATE_DEDUCTION_RATE: buildEndpoint('calendars/deduction-rates/create'),
        DELETE_DEDUCTION_RATE: buildEndpoint('calendars/deduction-rates/:id/delete'),
        EDIT_DEDUCTION_RATE: buildEndpoint('calendars/deduction-rates/:id/edit'),
        UPDATE_DEDUCTION_RATE: buildEndpoint('calendars/deduction-rates/:id/update'),
        ACTIVE_DEDUCTIONS: buildEndpoint('calendars/deductions/:id/active')
    },
    CALCULATED_FIELD: {
        CREATE_CALCULATED_FIELD: buildEndpoint('calendars/calculated-field/:id/:type/create')
    },
    HOLIDAYS: {
        GET_HOLIDAYS: buildEndpoint('calendars/holidays'),
        STORE_HOLIDAYS: buildEndpoint('calendars/holidays/store'),
        CREATE_HOLIDAY: buildEndpoint('calendars/holidays/create'),
        DELETE_HOLIDAY: buildEndpoint('calendars/holidays/:id/delete'),
        EDIT_HOLIDAY: buildEndpoint('calendars/holidays/:id/edit'),
        UPDATE_HOLIDAY: buildEndpoint('calendars/holidays/:id/update'),
        ACTIVE_HOLIDAY: buildEndpoint('calendars/holidays/:id/active'),
        GET_HOLIDAYS_BY_DATE: buildEndpoint('calendars/holidays/by-date')
    },
    EVENTS: {
        GET_EVENTS: buildEndpoint('calendars/events'),
        GET_EVENTS_BY_WORKER: buildEndpoint('calendars/events/event-by-worker'),
        GET_EVENT_BY_ID: buildEndpoint('calendars/events/event-by-id/:event_id'),
        CREATE_EXPENSE: buildEndpoint('calendars/events/create-expenses'),
        STORE_EVENTS: buildEndpoint('calendars/events/store'),
        CREATE_EVENT: buildEndpoint('calendars/events/create'),
        EDIT_EVENT: buildEndpoint('calendars/events/edit'),
        UPDATE_EVENT: buildEndpoint('calendars/events/:event_id/update'),
        UPDATE_SHIFT_WORK_EVENT: buildEndpoint('calendars/events/:event_id/update-shift-work'),
        UPDATE_ALL_SHIFT_WORK_EVENT: buildEndpoint('calendars/events/all-update-shift-work'),
        UPDATE_NEXT_SHIFT_WORK_EVENT: buildEndpoint(
            'calendars/events/:date/next-update-shift-work'
        ),
        CREATE_SHIFT_WORK_EVENT: buildEndpoint('calendars/events/create-shift-work'),
        DELETE_EVENT: buildEndpoint('calendars/events/:event_id/delete-event'),
        DELETE_ONE_DAY_SHIFT_WORK_EVENT: buildEndpoint(
            'calendars/events/:event_id/:date/delete-one-day-shiftwork'
        ),
        DELETE_NEXT_DAYS_SHIFT_WORK_EVENT: buildEndpoint(
            'calendars/events/:event_id/:date/delete-next-days-shiftwork'
        )
    },
    WORKERS: {
        GET_WORKERS: buildEndpoint('calendars/workers'),
        // GET_WORKER: buildEndpoint('calendars/workers/:id'),
        CREATE_WORKER: buildEndpoint('calendars/workers/create'),
        STORE_WORKER: buildEndpoint('calendars/workers/store'),
        EDIT_WORKER: buildEndpoint('calendars/workers/:id/edit'),
        SHOW_WORKER: buildEndpoint('calendars/workers/:id/calendar'),
        UPDATE_WORKER: buildEndpoint('calendars/workers/:id/update'),
        DELETE_WORKER: buildEndpoint('calendars/workers/:id/delete'),
        ACTIVE_WORKER: buildEndpoint('calendars/workers/:id/active'),
        FIND_USER: buildEndpoint('calendars/workers/:id/find-user'),
        GET_WORKER_CALENDAR: buildEndpoint('calendars/workers/:id/calendar')
    },
    WORKER_DOCUMENTS: {
        HISTORY_BY_WORKER_AND_TYPE: buildEndpoint(
            'calendars/worker-documents/:worker_id/:document_id/history'
        ),
        GET_WORKER_DOCUMENTS_BY_WORKER: buildEndpoint('calendars/worker-documents/:id/by-worker'),
        STORE_WORKER_DOCUMENT: buildEndpoint('calendars/worker-documents/store')
    },
    SHIFTS: {
        GET_SHIFTS: buildEndpoint('calendars/shifts'),
        STORE_SHIFTS: buildEndpoint('calendars/shifts/store'),
        CREATE_SHIFT: buildEndpoint('calendars/shifts/create'),
        DELETE_SHIFT: buildEndpoint('calendars/shifts/:id/delete'),
        DELETE_NEXT_SHIFT: buildEndpoint('calendars/shifts/:id/delete-next'),
        DELETE_ONE_SHIFT: buildEndpoint('calendars/shifts/:id/delete-one'),
        EDIT_SHIFT: buildEndpoint('calendars/shifts/:id/edit'),
        UPDATE_SHIFT: buildEndpoint('calendars/shifts/:id/update'),
        ACTIVE_SHIFT: buildEndpoint('calendars/shifts/:id/active'),
        GET_SHIFT_BY_DATE: buildEndpoint('calendars/shifts/by-date')
    },
    TRAVEL_EXPENSES: {
        GET_TRAVEL_EXPENSES: buildEndpoint('calendars/travel-expenses'),
        STORE_TRAVEL_EXPENSES: buildEndpoint('calendars/travel-expenses/store'),
        CREATE_TRAVEL_EXPENSES: buildEndpoint('calendars/travel-expenses/create'),
        DELETE_TRAVEL_EXPENSES: buildEndpoint('calendars/travel-expenses/:id/delete'),
        EDIT_TRAVEL_EXPENSES: buildEndpoint('calendars/travel-expenses/:id/edit'),
        UPDATE_TRAVEL_EXPENSES: buildEndpoint('calendars/travel-expenses/:id/update'),
        ACTIVE_TRAVEL_EXPENSES: buildEndpoint('calendars/travel-expenses/:id/active')
    },
    JOB_TITLES: {
        GET_JOB_TITLES: buildEndpoint('calendars/job-titles'),
        STORE_JOB_TITLES: buildEndpoint('calendars/job-titles/store'),
        CREATE_JOB_TITLES: buildEndpoint('calendars/job-titles/create'),
        DELETE_JOB_TITLES: buildEndpoint('calendars/job-titles/:id/delete'),
        EDIT_JOB_TITLES: buildEndpoint('calendars/job-titles/:id/edit'),
        UPDATE_JOB_TITLES: buildEndpoint('calendars/job-titles/:id/update'),
        ACTIVE_JOB_TITLES: buildEndpoint('calendars/job-titles/:id/active')
    },
    JOB_TITLE_LIQUIDATION_SETS: {
        GET_JOB_TITLE_LIQUIDATION_SETS: buildEndpoint('calendars/job-title-liquidation-sets'),
        STORE_JOB_TITLE_LIQUIDATION_SETS: buildEndpoint(
            'calendars/job-title-liquidation-sets/store'
        ),
        CREATE_JOB_TITLE_LIQUIDATION_SETS: buildEndpoint(
            'calendars/job-title-liquidation-sets/create'
        ),
        DELETE_JOB_TITLE_LIQUIDATION_SETS: buildEndpoint(
            'calendars/job-title-liquidation-sets/:id/delete'
        ),
        EDIT_JOB_TITLE_LIQUIDATION_SETS: buildEndpoint(
            'calendars/job-title-liquidation-sets/:id/edit'
        ),
        UPDATE_JOB_TITLE_LIQUIDATION_SETS: buildEndpoint(
            'calendars/job-title-liquidation-sets/:id/update'
        ),
        ACTIVE_JOB_TITLE_LIQUIDATION_SETS: buildEndpoint(
            'calendars/job-title-liquidation-sets/:id/active'
        )
    },
    STATUS_HISTORIES: {
        GET_STATUS_HISTORIES: buildEndpoint('status-histories/status-histories'),
        GET_STATUS_HISTORY_CODES: buildEndpoint('status-histories/status-histories/all-codes')
    },


};
