import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AccountDataForm } from '../../../app/models/Account';
import AccountForm from './forms/AccountForm';
import { Clasification } from '../../../app/models/Clasification';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    account: AccountDataForm;
    setAccount: (account: AccountDataForm) => void;
    clasifs: Clasification[];
    clasifsTwo: Clasification[];
    clasifsThree: Clasification[];
    clasifications: Clasification[];
    errorFields?: any;
}

const AccountFormContainer = ({
    fetching,
    action,
    cancel,
    account,
    setAccount,
    clasifs,
    clasifsTwo,
    clasifsThree,
    clasifications,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <AccountForm
                        accountDataForm={account}
                        setAccountDataForm={setAccount}
                        clasifs={clasifs}
                        clasifsTwo={clasifsTwo}
                        clasifsThree={clasifsThree}
                        clasifications={clasifications}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default AccountFormContainer;
