import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useGuideService from '../../../app/services/hooks/useGuideService';
import GuideHistoryForm from './forms/GuideHistoryForm';
import { LoadTicketHistoryDataForm } from '../../../app/models/LoadTicketsHistory';
import LoadTicketHistoryForm from './forms/LoadTicketHistoryForm';

interface Props {
    loadTicketId: number;
    errorFields?: any;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    getApprovedLoadTicket: (guideId: number, guideHistory: LoadTicketHistoryDataForm) => void;
}

const LoadTicketHistoryCreate = ({
    onSaved,
    onCancel,
    onError,
    errorFields,
    getApprovedLoadTicket,
    loadTicketId
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const { fetchingApprovedGuide, approvedGuide } = useGuideService();

    const [loadTicketHistory, setLoadTicketHistory] = useState<LoadTicketHistoryDataForm>({
        comment: '',
        is_approved: false
    });

    const store = () => {
        getApprovedLoadTicket(loadTicketId, loadTicketHistory);
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <LoadTicketHistoryForm
                        loadTicketHistoryDataForm={loadTicketHistory}
                        setLoadTicketHistoryDataForm={setLoadTicketHistory}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingApprovedGuide} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingApprovedGuide} />
                </div>
            </div>
        </>
    );
};

export default LoadTicketHistoryCreate;
