import { MenuItemStyles, menuClasses } from 'react-pro-sidebar';

type Theme = 'light' | 'dark';

interface SidebarThemes {
    [key: string]: {
        sidebar: {
            backgroundColor: string;
            color: string;
        };
        menu: {
            icon: string;
            disabled: {
                color: string;
            };
            menuContent: string;
            hover: {
                backgroundColor: string;
                color: string;
            };
        };
    };
}

export const themes: SidebarThemes = {
    light: {
        sidebar: {
            backgroundColor: '#ffffff',
            color: '#607489'
        },
        menu: {
            icon: '#0098e5',
            disabled: {
                color: '#9fb6cf'
            },
            menuContent: '#234452',
            hover: {
                backgroundColor: '#c5e4ff',
                color: '#44596e'
            }
        }
    },
    dark: {
        sidebar: {
            backgroundColor: '#183540',
            color: '#a6b0cf'
        },
        menu: {
            icon: '#6a7187',
            disabled: {
                color: '#3e5e7e'
            },
            menuContent: '#082440',
            hover: {
                backgroundColor: '#00458b',
                color: '#ffffff'
            }
        }
    }
};

export const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getMenuItemStyles = (theme: Theme, collapsed: any): MenuItemStyles => {
    return {
        root: {
            fontSize: '12px',
            fontWeight: 400
        },
        icon: {
            color: themes[theme].menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color
            }
        },
        SubMenuExpandIcon: {
            color: '#b6b7b9'
        },
        subMenuContent: ({ level }) => ({
            backgroundColor:
                level === 0
                    ? hexToRgba(themes[theme].menu.menuContent, !collapsed ? 0.4 : 1)
                    : 'transparent',
            fontSize: '12px'
        }),
        button: ({ level, active, disabled }) => {
            return {
                [`&.${menuClasses.disabled}`]: {
                    color: themes[theme].menu.disabled.color
                },
                '&:hover': {
                    backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 1),
                    color: themes[theme].menu.hover.color
                },
                [`&.ps-active`]: {
                    backgroundColor: '#13395e',
                    color: '#ffffff'
                }
            };
        },
        label: ({ open }) => ({
            fontWeight: open ? 600 : undefined,
            color: open ? 'white' : undefined
        })
    };
};
