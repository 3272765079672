import SocietyFilter from './SocietyFilter';
import { SocietyDataForm, SocietyFilters } from '../../../../app/models/Society';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ActiveControl from '../../../../components/ActiveControl';
import { UserDataForm } from '../../../../app/models/User';
import { CarrierDataForm } from '../../../../app/models/Carrier';
import SocietySwitchChange from '../SocietySwitchChange';

interface Props {
    societies: SocietyDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: SocietyFilters;
    setFilter: (filter: SocietyFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const SocietyDataTable = ({
    societies,
    totalRows,
    loading = false,
    edit,
    destroy,
    active,
    show,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        // {
        //     name: 'sociedad',
        //     selector: (row: any) => row.id,
        //     sortable: true,
        //     sortField: 'id',
        //     cell: (row: SocietyDataForm, index: any, column: any) => (
        //         <span className="">
        //             {' '}
        //             {/* Agrega la clase de Bootstrap que desees */}
        //             {row.id}
        //         </span>
        //     )
        // },
        {
            name: 'RUT',
            selector: (row: any) => row.rut,
            sortable: true,
            sortField: 'rut',
            cell: (row: SocietyDataForm, index: any, column: any) => (
                <span className="">{row.rut}</span>
            )
        },
        {
            name: 'Razón Social',
            selector: (row: any) => row.business_name,
            sortable: true,
            sortField: 'businessName',
            cell: (row: SocietyDataForm, index: any, column: any) => (
                <span className="">{row.business_name}</span>
            )
        },
        {
            name: 'Dirección',
            selector: (row: any) => row.address,
            sortable: true,
            sortField: 'address',
            cell: (row: SocietyDataForm, index: any, column: any) => (
                <span className="">{row.address}</span>
            )
        },
        {
            name: 'Pais',
            selector: (row: any) => row.country,
            sortable: true,
            sortField: 'country',
            cell: (row: SocietyDataForm, index: any, column: any) => (
                <span className="">{row.country}</span>
            )
        },
        {
            name: 'Nombre Contacto',
            selector: (row: any) => row.first_name_contact,
            sortable: true,
            sortField: 'first_name',
            cell: (row: SocietyDataForm, index: any, column: any) => (
                <span className="">{row.first_name_contact}</span>
            )
        },
        //         {
        //     name: 'Apellido Paterno',
        //     selector: (row: any) => row.last_name_contact,
        //     sortable: true,
        //     sortField: 'last_name',
        //     cell: (row: SocietyDataForm, index: any, column: any) => (
        //         <span className="">{row.last_name_contact}</span>
        //     )
        // },
        // {
        //     name: 'Apellido Materno',
        //     selector: (row: any) => row.second_last_name_contact,
        //     sortable: true,
        //     sortField: 'second_last_name',
        //     cell: (row: SocietyDataForm, index: any, column: any) => (
        //         <span className="">{row.second_last_name_contact}</span>
        //     )
        // },
        {
            name: 'Email Contacto',
            selector: (row: any) => row.email_contact,
            sortable: true,
            sortField: 'email',
            cell: (row: SocietyDataForm, index: any, column: any) => (
                <span className="">{row.email_contact}</span>
            )
        },
        {
            name: 'Teléfono Contacto',
            selector: (row: any) => row.phone_contact,
            sortable: true,
            sortField: 'phone',
            cell: (row: SocietyDataForm, index: any, column: any) => (
                <span className="">{row.phone_contact}</span>
            )
        },
        // {
        //     name: 'Activo',
        //     selector: (row: any) => row.is_active,
        //     sortable: true,
        //     sortField: 'is_active',
        //     cell: (row: SocietyDataForm, index: any, column: any) => (
        //         <>
        //         {active && (
        //         <ActiveControl status={row.is_active} id={row.id} callback={() => active(Number(row.id), !row.is_active)}/>
        //                             )}
        //             </>
        //     )
        // },
        {
            name: 'Activo',
            selector: (row: any) => row?.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: SocietyDataForm, index: any, column: any) => (
                <SocietySwitchChange
                    checkValue={row.is_active ?? true}
                    societyId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: SocietyDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <SocietyFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={societies}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default SocietyDataTable;
