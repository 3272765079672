import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';

import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { Unit, UnitFilters, UnitEquivalence } from '../../models/Unit';

const useUnitService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetUnits, setFetchingGetUnits] = useState(false);
    const [fetchingShowUnit, setFetchingShowUnit] = useState(false);
    const [fetchingCreateUnit, setFetchingCreateUnit] = useState(false);
    const [fetchingStoreUnit, setFetchingStoreUnit] = useState(false);
    const [fetchingEditUnit, setFetchingEditUnit] = useState(false);
    const [fetchingUpdateUnit, setFetchingUpdateUnit] = useState(false);
    const [fetchingDeleteUnit, setFetchingDeleteUnit] = useState(false);
    const [fetchingActiveUnit, setFetchingActiveUnit] = useState(false);

    const getUnits = (unitFilters: UnitFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(unitFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.UNITS.GET_UNITS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetUnits
        });
    };

    const showUnit = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.UNITS.SHOW_UNIT.replace(':id', id.toString()),
            setFetching: setFetchingShowUnit
        });
    };

    const storeUnit = (unit: Unit, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.UNITS.STORE_UNIT,
            body: unit,
            setFetching: setFetchingStoreUnit
        });
    };

    const editUnit = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.UNITS.EDIT_UNIT.replace(':id', id.toString()),
            setFetching: setFetchingEditUnit
        });
    };

    const updateUnit = (id: number, unit: Unit, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.UNITS.UPDATE_UNIT.replace(':id', id.toString()),
            body: unit,
            setFetching: setFetchingUpdateUnit
        });
    };

    const deleteUnit = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.UNITS.DELETE_UNIT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteUnit
        });
    };

    const activeUnit = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.UNITS.ACTIVE_UNIT.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveUnit
        });
    };

    return {
        fetchingGetUnits,
        fetchingShowUnit,
        fetchingStoreUnit,
        fetchingEditUnit,
        fetchingUpdateUnit,
        fetchingDeleteUnit,
        fetchingActiveUnit,
        getUnits,
        showUnit,
        storeUnit,
        editUnit,
        updateUnit,
        deleteUnit,
        activeUnit
    };
};

export default useUnitService;
