import React, { useContext, useState } from 'react';

import { defaultPlace, Place } from '../../../app/models/Place';
import usePlaceService from '../../../app/services/hooks/usePlaceService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import PlaceFormContainer from './PlaceFormContainer';
import LazyLoading from '../../../components/LazyLoading';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const PlaceCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingStorePlace, storePlace, createPlace, fetchingCreatePlace } = usePlaceService();
    const [place, setPlace] = useState<Place>(defaultPlace);

    const [errorFields, setErrorFields] = useState<any>();

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando origen/destino...');
        storePlace(place, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreatePlace ? (
        <LazyLoading height={300} />
    ) : (
        <PlaceFormContainer
            fetching={fetchingStorePlace}
            action={store}
            cancel={cancel}
            place={place}
            setPlace={setPlace}
            errorFields={errorFields}
        />
    );
};

export default PlaceCreate;
