import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../../components/default/DefaultCard';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import UserForm from '../../Users/components/forms/UserForm';
import { UserDataForm, defaultUserDataForm } from '../../../app/models/User';
import { Afp } from '../../../app/models/Afp';
import { Provision } from '../../../app/models/Provision';
import { Bank } from '../../../app/models/Bank';
import { BankAccountType } from '../../../app/models/BankAccountType';
import LazyLoading from '../../../components/LazyLoading';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useAuthService from '../../../app/services/hooks/useAuthService';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Perfil',
        url: '/edit-profile',
        isActive: true
    }
];

const Profile = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [profile, setProfile] = useState<UserDataForm>(defaultUserDataForm);
    const { getProfile, fetchingGetProfile, updateProfile, fetchingUpdateProfile } =
        useAuthService();

    const [afps, setAfps] = useState<Afp[]>([]);
    const [provisions, setProvisions] = useState<Provision[]>([]);
    const [banks, setBanks] = useState<Bank[]>([]);
    const [bankAccountTypes, setBankAccountTypes] = useState<BankAccountType[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        userProfile();
    }, []);

    const userProfile = () => {
        if (showLoading) showLoading('loading', 'Cargando perfil');
        getProfile({
            onSuccess: (response: ServiceResponse) => {
                setAfps(response.data.afps);
                setProvisions(response.data.previsions);
                setBanks(response.data.banks);
                setBankAccountTypes(response.data.bank_account_types);
                const _user = response.data.profile;

                // _user.roles = response.data.profile.groups.map((group: any) => {
                //     return {
                //         id: group.id,
                //         name: group.name
                //     };
                // });
                const combinedRoles = [
                    ...response.data.profile.groups.map((group: any) => ({
                        id: group.id,
                        name: group.name
                    })),
                    ...response.data.profile.job_title_groups.map((group: any) => ({
                        id: group.id,
                        name: group.name
                    }))
                ];
                _user.roles = combinedRoles;

                setProfile(_user);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message, {
                    autoClose: 2000
                });
            }
        });
    };

    const updateUserProfile = () => {
        if (showLoading) showLoading('loading', 'Actualizando perfil');
        updateProfile(profile, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(undefined);
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    return fetchingGetProfile ? (
        <LazyLoading height="300" />
    ) : (
        <>
            <Breadcrumbs pageSection="Perfil de usuario" breadcrumbs={breadcrumbs} />
            <div className="row">
                <div className="col-12">
                    <UserForm
                        userForm={profile}
                        setUserForm={setProfile}
                        afps={afps}
                        provisions={provisions}
                        bankAccountTypes={bankAccountTypes}
                        banks={banks}
                        isProfile={true}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={updateUserProfile}
                        locked={fetchingUpdateProfile}
                    />
                </div>
            </div>
        </>
    );
};

export default Profile;
