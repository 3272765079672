import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { formatDateToInputDate, momentParseDateToInput } from '../../../helpers';
import { Worker } from '../../../app/models/Workers';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import LazyLoading from '../../../components/LazyLoading';
import useWorkerService from '../../../app/services/hooks/useWorkerService';
import DefaultCalendar from '../../../components/default/DefaultCalendar';
import {
    CalendarEvent,
    CalendarEventDataForm,
    defaultCalendarEventDataForm
} from '../../../app/models/Event';
import useHolidayService from '../../../app/services/hooks/useHolidayService';
import { Holiday } from '../../../app/models/Holiday';
import useNavigationPage from '../../../hooks/useNavigationPage';
import WorkersEventCreate from '../components/WorkersEventCreate';
import useEventService from '../../../app/services/hooks/useEventService';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import { ExpenseDataForm, defaultExpenseDataForm } from '../../../app/models/Expense';
import CalendarRequestFormContainer from '../../Calendar/components/containers/CalendarRequestFormContainer';

type RouterParams = {
    id: string;
};

interface Props {
    worker: Worker;
}

const WorkersCalendar = ({ worker }: Props) => {
    const { id } = useParams<RouterParams>();
    const { navigationPage } = useNavigationPage();
    const [calendarEvent, setCalendarEvent] = useState<CalendarEventDataForm>({
        ...defaultCalendarEventDataForm,
        worker_id: worker.id
    });
    const [expense, setExpense] = useState<ExpenseDataForm>({
        ...defaultExpenseDataForm
    });
    const { fetchingGetWorkerCalendar, getWorkerCalendar } = useWorkerService();
    const { getHolidaysByDate } = useHolidayService();
    const [holidays, setHolidays] = useState<Holiday[]>([]);
    const [workerCalendar, setWorkerCalendar] = useState<CalendarEvent[]>([]);
    const [date, setDate] = useState<Date | string>(new Date());
    const [view, setView] = useState<string>('month');
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingCreateRequest, setShowingCreateRequest] = useState(false);
    const [showingModal, setShowingModal] = useState(false);
    const { getEventsByWorker } = useEventService();

    useEffect(() => {
        reloadCalendar();
    }, [date, view, id]);

    const showRequest = () => {
        setShowingCreateRequest(true);
    };

    const hideRequest = () => {
        setShowingCreateRequest(false);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const { editWorker, fetchingEditWorker, updateWorker, fetchingUpdateWorker } =
        useWorkerService();

    const handleSelectable = ({ start, end }: { start: Date; end: Date }) => {
        const endDate = new Date(end.setDate(end.getDate() - 1));
        setCalendarEvent({
            ...defaultCalendarEventDataForm,
            worker_id: worker.id,
            start_date: start.toISOString().split('T')[0],
            end_date: endDate.toISOString().split('T')[0]
        });

        showCreate();
    };

    const handleSelectEvent = (event: any) => {
        navigationPage(
            `/calendar-by-worker/${event?.id}/event/show?start_date=${momentParseDateToInput(
                event.start
            )}&end_date=${momentParseDateToInput(event.end)}&admin=true&worker=${id}`
        );
    };

    useEffect(() => {
        const holidaysEvents = holidays.map((holiday) => {
            return {
                title: holiday.name!,
                start: new Date(holiday.this_year_date!),
                end: new Date(holiday.this_year_date!),
                color: '#f01d20'
            };
        });
        const shiftEvents = workerCalendar.map((event) => {
            return {
                id: event.id,
                title: event.title,
                start: new Date(event.start_date!),
                end: new Date(event.end_date!),
                color: event.color,
                expenseId: event.travel_expense,
                isShiftWork: event.is_shift_work
            };
        });
        setEvents([...holidaysEvents, ...shiftEvents]);
    }, [holidays, workerCalendar]);

    const reloadCalendar = () => {
        getHolidays();
        getAllEvents();
    };

    const getHolidays = () => {
        getHolidaysByDate(formatDateToInputDate(date), view, {
            onSuccess: (response: ServiceResponse) => {
                setHolidays(response.data.holidays);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const getAllEvents = () => {
        getWorkerCalendar(parseInt(id), formatDateToInputDate(date), view, {
            onSuccess: (response: ServiceResponse) => {
                setWorkerCalendar(response.data.events);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    if (fetchingEditWorker) {
        return (
            <DefaultCard>
                <LazyLoading height={300} />
            </DefaultCard>
        );
    }

    return (
        <>
            {showingCreate && (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear evento"
                    backdrop={true}
                    showFooter={false}
                >
                    <WorkersEventCreate
                        onSaved={() => {
                            reloadCalendar();
                            hideCreate();
                        }}
                        worker={worker}
                        calendarEvent={calendarEvent}
                        setCalendarEvent={setCalendarEvent}
                        onCancel={hideCreate}
                        onError={hideCreate}
                        editable={true}
                    />
                </DefaultModal>
            )}
            <DefaultCard>
                <div className="col-12">
                    <div className="row">
                        <div className="col">
                            <ButtonCreate callbackCreate={showRequest} title="Nueva solicitud" />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <DefaultCalendar
                        events={events}
                        setDate={setDate}
                        setView={setView}
                        selectable={true}
                        onSelectSlot={handleSelectable}
                        onSelectEvent={handleSelectEvent}
                    />
                </div>
            </DefaultCard>
            {showingCreateRequest && (
                <DefaultModal
                    show={showingCreateRequest}
                    handleClose={hideRequest}
                    title="Añadir nueva solicitud"
                    backdrop={true}
                    showFooter={false}
                >
                    <CalendarRequestFormContainer
                        onSaved={hideRequest}
                        onCancel={hideRequest}
                        onError={hideRequest}
                        workerId={worker.id}
                        showWorkerField={false}
                    />
                </DefaultModal>
            )}
        </>
    );
};

export default WorkersCalendar;
