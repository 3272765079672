import React, { useContext, useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import { CalendarRequestDataForm } from '../../../../app/models/CalendarRequest';
import { EventType } from '../../../../app/models/EventType';
import { setCleanTextAreaError } from '../../../../utils/utils';
import { Worker } from '../../../../app/models/Workers';
import { AuthContext } from '../../../../contexts/AuthContext';

interface Props {
    calendarRequest: CalendarRequestDataForm;
    calendarRequestDocument?: string;
    setCalendarRequest: (expense: CalendarRequestDataForm) => void;
    eventTypes: EventType[];
    workers: Worker[];
    errorFields?: any;
    showWorkerField?: boolean;
}

const CalendarRequestForm = ({
    calendarRequest,
    calendarRequestDocument,
    setCalendarRequest,
    showWorkerField,
    workers,
    eventTypes,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    console.log(calendarRequest);
    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);
    const { auth } = useContext(AuthContext);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setCalendarRequest({ ...calendarRequest, [name]: value });
    };

    const handleChangeDocumentFile = (e: FileList | null, type: string) => {
        if (e) {
            setCalendarRequest({ ...calendarRequest, ['document_file']: e[0] });
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };
        setCalendarRequest({ ...calendarRequest, ...obj });
    };

    const workerOptions = workers.map((worker) => ({
        id: worker.id,
        name: worker.user
            ? worker.user.first_name + ' ' + worker.user.last_name
            : 'Nombre no disponible'
    }));

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="mb-2 row needs-validation">
                        <label className="col-md-4 col-form-label">Tipo de solicitud</label>
                        <div className="col-md-12">
                            <SelectTwo
                                name="event_type_id"
                                id="event_type_id"
                                inputValue={calendarRequest.event_type_id}
                                options={SelectTwoMapperOptions(eventTypes)}
                                hasError={fieldHasError('event_type_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('event_type_id', value?.value)
                                }
                                placeholder={'Seleccione el tipo de solicitud'}
                                onFocus={() => onFocusRemove('event_type_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('event_type_id')}
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-md-6">
                            <label className="col-md-6 col-form-label">Fecha de inicio</label>
                            <div className="col-md-12">
                                <input
                                    type="date"
                                    className={`form-control ${fieldHasError('start_date')}`}
                                    name="start_date"
                                    id="start_date"
                                    value={calendarRequest.start_date?.toString()}
                                    onChange={handleChange}
                                    placeholder="Ingrese una fecha"
                                    onFocus={() => onFocusRemove('date')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('start_date')}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="col-md-9 col-form-label">Fecha de termino</label>
                            <div className="col-md-12">
                                <input
                                    type="date"
                                    className={`form-control ${fieldHasError('end_date')}`}
                                    name="end_date"
                                    id="end_date"
                                    value={calendarRequest.end_date?.toString()}
                                    onChange={handleChange}
                                    placeholder="Ingrese una fecha"
                                    onFocus={() => onFocusRemove('end_date')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('end_date')}
                                </div>
                            </div>
                        </div>
                    </div>

                    {showWorkerField && !auth?.roles?.includes('Conductor') && (
                        <div className="mb-2 row needs-validation">
                            <label className="col-md-4 col-form-label">Trabajador</label>
                            <div className="col-md-12">
                                <SelectTwo
                                    name="worker_id"
                                    id="worker_id"
                                    inputValue={calendarRequest.worker_id}
                                    options={SelectTwoMapperOptions(workerOptions)}
                                    hasError={fieldHasError('worker_id') !== ''}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('worker_id', value?.value)
                                    }
                                    placeholder={'Seleccione el trabajador'}
                                    onFocus={() => onFocusRemove('worker_id')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('worker_id')}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-md-12 col-form-label">Documento</label>
                            <InputButtonFile
                                handleChangeDocumentFile={handleChangeDocumentFile}
                                name={'document_file'}
                                objectFile={
                                    calendarRequest.document_file
                                        ? calendarRequest.document_file
                                        : calendarRequestDocument
                                }
                                onFocus={() => onFocusRemove('document_file')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('document_file')}
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-md-12 col-form-label">Descripción</label>
                            <textarea
                                className={`form-control ${fieldHasError('description')}`}
                                name="description"
                                id="description"
                                value={calendarRequest?.description}
                                onChange={handleChange}
                                placeholder="Ingrese una descripción"
                                onFocus={setCleanTextAreaError}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('description')}
                            </div>
                        </div>
                    </div>
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('non_field_errors')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CalendarRequestForm;
