import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { HopperDataForm } from '../../../../app/models/Hopper';

import { momentParseDate } from '../../../../helpers';
import { AuthContext } from '../../../../contexts/AuthContext';

import { formatNumber } from '../../../../utils/utils';
import { GuideDataForm, GuideFilters } from '../../../../app/models/Guide';
import GuideFilter from './GuideFilter';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import circleGrey from '../../../../assets/images/icons/circle-color-grey.svg';
import circleRed from '../../../../assets/images/icons/circle-color-red.svg';
import circleGreen from '../../../../assets/images/icons/circle-color-green.svg';

interface Props {
    guides: GuideDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    approved?: (id: number, value: boolean, message: string) => void;
    cancel?: (id: number, value: boolean) => void;
    filter: GuideFilters;
    setFilter: (filter: GuideFilters) => void;
    guidePage?: boolean;
    paginationRowsPerPageOptions?: any[];
    documentGuide: (id: number) => void;
    showGuideHistory?: (guideId: number) => void;
    onSearch: () => void;
}

const GuideDataTable = ({
    guides,
    totalRows,
    loading = false,
    edit,
    destroy,
    approved,
    cancel,
    show,
    filter,
    setFilter,
    guidePage = false,
    documentGuide,
    showGuideHistory,
    onSearch
}: Props) => {
    const { auth } = useContext(AuthContext);

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: GuideDataForm, index: any, column: any) => (
                <span className="">
                    <img
                        src={
                            row.is_approved == null
                                ? circleGrey
                                : !row.is_approved
                                ? circleRed
                                : circleGreen
                        }
                        alt="circle"
                        style={{
                            width: '25px',
                            height: '25px',
                            marginRight: '5px',
                            marginBottom: '3px'
                        }}
                    />
                    {row.id}
                </span>
            )
        },
        {
            name: 'Orden de transporte',
            selector: (row: any) => row?.transport_order_id,
            sortable: true,
            sortField: 'transport_order_id',
            omit: !guidePage,
            cell: (row: GuideDataForm, index: any, column: any) => (
                <span className="">
                    {row.transport_order_id ? (
                        guidePage ? (
                            <Link to={`/transport-orders/${row.transport_order_id}/edit`}>
                                #{row.transport_order_id}
                            </Link>
                        ) : (
                            row.transport_order_id
                        )
                    ) : (
                        ''
                    )}
                </span>
            )
        },
        {
            name: 'Sociedad',
            selector: (row: any) => row?.society,
            sortable: true,
            sortField: 'society',
            minWidth: '120px',
            cell: (row: GuideDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.society ? row.society : '' }}
                ></span>
            )
        },
        {
            name: 'Cliente',
            selector: (row: any) => row?.client,
            sortable: true,
            sortField: 'client',
            cell: (row: GuideDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.client ? row.client : '' }}
                ></span>
            )
        },
        {
            name: 'Transportista',
            selector: (row: any) => row?.transporter,
            sortable: true,
            sortField: 'transporter',
            minWidth: '120px',
            cell: (row: GuideDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.transporter ? row.transporter : '' }}
                ></span>
            )
        },
        {
            name: 'Conductor',
            selector: (row: any) => row?.carrier,
            sortable: true,
            sortField: 'carrier',
            minWidth: '120px',
            cell: (row: GuideDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.carrier ? row.carrier : '' }}
                ></span>
            )
        },
        {
            name: 'tracto',
            selector: (row: any) => row?.truck,
            sortable: true,
            sortField: 'truck',
            cell: (row: GuideDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.truck ? row.truck : '' }}
                ></span>
            )
        },
        {
            name: 'Fecha de emisión',
            selector: (row: any) => row?.issue_date,
            sortable: true,
            sortField: 'issue_date',
            minWidth: '110px',
            cell: (row: GuideDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date, 'YYYY-MM-DD')}</span>
            )
        },

        {
            name: 'Folio',
            selector: (row: any) => row?.folio,
            sortable: true,
            sortField: 'folio',
            cell: (row: GuideDataForm, index: any, column: any) => (
                <span className="">{row.folio}</span>
            )
        },
        {
            name: 'Producto',
            selector: (row: any) => row.product,
            sortable: true,
            sortField: 'product',
            cell: (row: GuideDataForm, index: any, column: any) => (
                <span className="">{row.product?.name}</span>
            )
        },

        {
            name: 'Cantidad',
            selector: (row: any) => row.quantity,
            sortable: true,
            sortField: 'quantity',
            cell: (row: GuideDataForm, index: any, column: any) => (
                <span className="">
                    {formatNumber(row.quantity ?? 0)} {row.unit}
                </span>
            )
        },

        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: GuideDataForm, index: any, column: any) => (
                <div className="">
                    {documentGuide && (
                        <ButtonTableAction
                            callbackFunction={() => documentGuide(row?.id ?? -1)}
                            classIcon={'mdi mdi-download'}
                            colorIcon={row.file ? 'text-success' : 'text-secondary'}
                            locked={!row.file}
                            errorMessage={'No se puede descargar este documento.'}
                            title={'Descargar documento'}
                        />
                    )}
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (row.is_approved === false || row.is_approved === null) && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                    {approved &&
                        (row.is_approved === false || row.is_approved === null) &&
                        auth &&
                        auth?.roles &&
                        !auth?.roles.includes('Conductor') && (
                            <>
                                <ButtonTableAction
                                    callbackFunction={() =>
                                        approved(row?.id ?? -1, true, 'aprobada')
                                    }
                                    classIcon={'mdi mdi-checkbox-marked-circle'}
                                    colorIcon={'text-info'}
                                    errorMessage={'No se puede aprobar este registro.'}
                                    title={'Aprobar'}
                                />
                                <ButtonTableAction
                                    callbackFunction={() =>
                                        approved(row?.id ?? -1, false, 'rechazada')
                                    }
                                    classIcon={'mdi mdi-close-circle'}
                                    colorIcon={'text-danger'}
                                    errorMessage={'No se puede rechazar este registro.'}
                                    title={'Rechazar'}
                                />
                            </>
                        )}
                    {cancel && (row.is_approved === false || row.is_approved === true) && (
                        <ButtonTableAction
                            callbackFunction={() => cancel(row?.id ?? -1, row.is_approved ?? false)}
                            classIcon={'fa fa-ban'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede anular este registro.'}
                            title={'Anular'}
                        />
                    )}
                    {showGuideHistory &&
                        auth &&
                        auth?.roles &&
                        !auth?.roles.includes('Conductor') && (
                            <ButtonTableAction
                                callbackFunction={() => showGuideHistory(row?.id ?? -1)}
                                classIcon={'mdi mdi-history'}
                                colorIcon={'text-info'}
                                errorMessage={'No se puede mostrar el historial de este registro.'}
                                title={'Mostrar historial'}
                            />
                        )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <GuideFilter
                        filter={filter}
                        setFilter={setFilter}
                        showTransportOrder={guidePage}
                        onSearch={onSearch}
                    />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={guides}
                progressPending={loading}
                isMobileStacked={true}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default GuideDataTable;
