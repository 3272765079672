import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import { IncidentDataForm } from '../../../app/models/Incident';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import useIncidentService from '../../../app/services/hooks/useIncidentService';
import IncidentForm from './forms/IncidentForm';

interface Props {
    transportOrderId: number;
    incidentId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;

    
}

const IncidentEdit = ({ transportOrderId, incidentId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const { editIncident, updateIncident, fetchingEditIncident, fetchingUpdateIncident } = useIncidentService();
    const [incident, setIncident] = useState<IncidentDataForm>();
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        editIncident(incidentId, {
            onSuccess: (response: ServiceResponse) => {
                setIncident(response.data.incident);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onCancel) onCancel();
            },
        });
    }, [incidentId]);

    const update = () => {
        if (showLoading) showLoading('loading', 'Guardando incidente...');
        if (!incident) return;

        updateIncident(incidentId, incident, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            },
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditIncident || !incident ? (
        <LazyLoading height={300} />
    ) : (
        <>
                <IncidentForm 
                incident={incident} 
                setIncident={setIncident} 
                errorFields={errorFields} 
                isEdit={true}
                />

                <div className="row justify-content-end">
                    <div className="col-auto">
                        <ButtonCancelForm callbackCancel={cancel} locked={fetchingUpdateIncident} />
                    </div>
                    <div className="col-auto">
                        <ButtonSaveForm callbackSave={update} locked={fetchingUpdateIncident} />
                    </div>
                </div>
        </>
    );
};

export default IncidentEdit;
