import { useState} from 'react'
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm'
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm'
import UnlockStateForm from './forms/UnlockStateForm'


interface Props {
    unlockStatus: (reason: string) => void;
    errorFields?: any;
    onCancel?: () => void;
}

const UnlockState = ({ unlockStatus, errorFields, onCancel }: Props) => {

    const [reason, setReason] = useState<string>('');
    
    const cancel = () => {
        if (onCancel) onCancel();
    };

    const action = () => {
        unlockStatus(reason)
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <UnlockStateForm
                        reason={reason}
                        setReason={setReason}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel}  />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action}  />
                </div>
            </div>
        </>
    );
}

export default UnlockState