import React, { useEffect, useState } from 'react';
import { formatNumberWithDots, formatNumberWithDecimals, formatFloattNumber } from '../../helpers';

type CurrencyProps = {
    name: string;
    type?: string | number;
    value?: string | number;
    onChange: (event: any) => void;
    onFocus?: () => void;
    className: string;
    placeholder?: string;
    disabled?: boolean;
};

const CustomInput = ({
    type,
    value,
    name,
    onChange,
    onFocus,
    className,
    placeholder,
    disabled = false
}: CurrencyProps) => {
    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {
        setInputValue(formattedInputValue(value));
    }, [value, type]);

    const formattedInputValue = (value: string | number | undefined): string => {
        if (type === 'FLOAT') {
            if (value) return formatNumberWithDecimals(value);
        } else if (type === 'BIGFLOAT') {
            if (value) return formatFloattNumber(value);
        } else {
            if (value) return formatNumberWithDots(value);
        }
        return value?.toString() || '';
    };

    const formattedOnChangeValue = (value: string): string => {
        if (type === 'FLOAT') {
            value = value.replace(/[^\d,]/g, '');
            const parts = value.split(',');
            if (parts.length > 1) {
                parts[1] = parts[1].substring(0, 2);
            }
            value = parts.join('.');
        } else if (type === 'BIGFLOAT') {
            value = value.replace(/[^\d,]/g, '');
            const parts = value.split(',');
            value = parts.join('.');
        } else {
            value = value.replace(/\D/g, '');
        }
        return value;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = formattedOnChangeValue(e.target.value);
        e.target.value = inputValue;
        onChange(e);
    };

    return (
        <input
            name={name}
            id={name}
            type="text"
            value={inputValue}
            className={className}
            onChange={handleChange}
            onFocus={onFocus}
            placeholder={placeholder}
            autoComplete="off"
            disabled={disabled}
        />
    );
};

export default CustomInput;
