import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import { useHistory } from 'react-router-dom';

import useExpenseService from '../../../app/services/hooks/useExpenseService';
import { ExpenseDataForm, defaultExpenseDataForm } from '../../../app/models/Expense';
import { Provider } from '../../../app/models/Provider';
import { ExpenseType } from '../../../app/models/ExpenseType';
import { ExpenseDocumentType } from '../../../app/models/ExpenseDocumentType';
import ExpenseForm from './forms/ExpenseForm';
import { LoadTicketDataForm, defaultLoadTicketDataForm } from '../../../app/models/LoadTicket';
import { Unit } from '../../../app/models/Unit';
import useLoadTicketService from '../../../app/services/hooks/useLoadTicketService';
import LoadTicketForm from './forms/LoadTicketForm';

interface Props {
    transportOrderId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const LoadTicketCreate = ({ onSaved, onCancel, onError, transportOrderId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const { createLoadTicket, storeLoadTicket, fetchingCreateLoadTicket, fetchingStoreLoadTicket } =
        useLoadTicketService();

    const [loadTicket, setLoadTicket] = useState<LoadTicketDataForm>({
        ...defaultLoadTicketDataForm,
        transport_order_id: transportOrderId
    });

    const [units, setUnits] = useState<Unit[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createLoadTicket(transportOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setUnits(response.data.units);
                const kgUnitId =
                    response.data.units.find((unit: any) => unit.name.toLowerCase() == 'kg')?.id ??
                    0;
                setLoadTicket({
                    ...loadTicket,
                    unit_id: kgUnitId
                });
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando ticket...');
        storeLoadTicket(transportOrderId, loadTicket, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateLoadTicket ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <LoadTicketForm
                        loadTicketForm={loadTicket}
                        setLoadTicketForm={setLoadTicket}
                        units={units}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreLoadTicket} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreLoadTicket} />
                </div>
            </div>
        </>
    );
};

export default LoadTicketCreate;
