import { Client } from './Client';
import { PaymentCondition } from './PaymentCondition';
import { PaymentMethod } from './PaymentMethod';
import { Society } from './Society';
import { Currency } from './Currency';
import { Quote } from './Quote';
import { TransportationOrderItemDataForm } from './TransportationOrderItem';

export interface DispatchProgram {
    id?: number;
    contact_email?: string;
    status?: string;
    status_selected?: DispatchProgramStatus;
    status_choices?: DispatchProgramStatus[];

    created_at?: string;
    updated_at?: string;
    deleted_at?: string;

    client_id?: number | string;
    client?: Client | string;

    created_user_id?: number | string;
    created_user?: string;

    // currency_id?: number | string;
    // currency?: Currency;

    last_modified_user_id?: number | string;
    last_modified_user?: string;

    // payment_condition_id?: number | string | null;
    // reference?: string | null;
    // days_count?: number;
    // payment_condition?: PaymentCondition;

    // payment_method_id?: number | string;
    // payment_method?: PaymentMethod;

    society_id?: number | string;
    society?: Society;

    transportation_order_item_id?: number;

    runner_id?: number;
    transportation_order_item?: TransportationOrderItemDataForm;
}

export interface DispatchProgramFilters {
    dispatch_program_status?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    dispatch_program_id?: number | null;
    transportation_order_id?: number | null;
    quote_id?: number | null;
    client?: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface DispatchProgramStatus {
    id: number | string;
    name: number | string;
}

export interface DispatchProgramShowDataForm {
    id?: number;
    contact_email: string;
    expired_at: string;
    client: string;
    quote_id?: number;
    quote?: Quote;
    runner?: string;
    payment_method: string;
    payment_condition: string;
    currency: string;
    society: string;
    status?: string;
    status_selected?: DispatchProgramStatus;
    status_choices?: DispatchProgramStatus[];
    created_at?: string;
    created_user?: string;
    updated_at?: string;
    last_modified_user?: string;
    transportation_order_item_id?: number;
    reference?: string;
    days_count?: number;
}

export const defaultDispatchProgram: DispatchProgram = {
    contact_email: '',
    client_id: 0,
    // payment_method_id: 0,
    // payment_condition_id: null,
    // currency_id: 0,
    society_id: 0
    // reference: '',
    // days_count: 0
};

export const defaultDispatchProgramShowDataForm: DispatchProgramShowDataForm = {
    id: 0,
    contact_email: '',
    expired_at: '',
    client: '',
    quote_id: 0,
    quote: undefined,
    payment_method: '',
    payment_condition: '',
    currency: '',
    society: '',
    status: '',
    status_selected: undefined,
    status_choices: [],
    created_at: '',
    created_user: '',
    updated_at: '',
    last_modified_user: '',
    reference: '',
    days_count: 0
};
