import { Group } from './Group';

export interface JobTitle {
    id?: number;
    name: string;
    description?: string;

    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface JobTitleFilters {
    id?: number | null;
    name?: string;
    is_active?: boolean;

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface JobTitleDataForm {
    id?: number;
    name: string;
    description?: string;
    groups?: Group[];
    is_active?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export const defaultJobTitle: JobTitle = {
    name: ''
};
