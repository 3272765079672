import React from 'react';
import DocumentForm from './forms/DocumentForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Document, DocumentDataForm } from '../../../app/models/Document';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    document: DocumentDataForm;
    setDocument: (Document: Document) => void;
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const DocumentFormContainer = ({
    fetching,
    action,
    cancel,
    document,
    setDocument,
    errorFields = null,
    setErrorFields
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <DocumentForm
                        documentForm={document}
                        setDocumentForm={setDocument}
                        errorFields={errorFields}
                        setErrorFields={setErrorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default DocumentFormContainer;
