import React, { useContext, useEffect, useState } from 'react';
import useJobTitleService from '../../../../app/services/hooks/useJobTitleService';
import LazyLoading from '../../../../components/LazyLoading';
import { JobTitleDataForm } from '../../../../app/models/JobTitle';
import JobTitleFormContainer from '../JobTitleFormContainer';
import { AppContext } from '../../../../contexts/AppContext';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import { Group } from '../../../../app/models/Group';

interface Props {
    jobTitleId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const JobTitleEdit = ({ jobTitleId, onSaved, onCancel, onError }: Props) => {
    const { fetchingEditJobTitle, editJobTitle, fetchingUpdateJobTitle, updateJobTitle } =
        useJobTitleService();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [groups, setGroups] = useState<Group[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
    const [jobTitle, setJobTitle] = useState<JobTitleDataForm>({ name: '' });

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando puesto...');
        editJobTitle(jobTitleId, {
            onSuccess: (response: ServiceResponse) => {
                setGroups(response.data.groups);
                const _job_title = response.data.job_title;
                _job_title.groups = response.data.job_title.groups.map((group: any) => {
                    return {
                        value: group.id,
                        label: group.name
                    };
                });
                setJobTitle(_job_title);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando puesto...');
        updateJobTitle(jobTitleId, jobTitle, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditJobTitle ? (
        <LazyLoading height="300" />
    ) : (
        <JobTitleFormContainer
            fetching={fetchingUpdateJobTitle}
            action={update}
            cancel={cancel}
            groups={groups}
            jobTitle={jobTitle}
            setJobTitle={setJobTitle}
            errorFields={errorFields}
        />
    );
};

export default JobTitleEdit;
