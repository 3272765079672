import React from 'react';
import { IconProps } from '../../types';

export const Toll: React.FC<IconProps> = ({ size = 28, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Capa_1"
            width={size}
            height={size}
            viewBox="0 0 56.686 56.686"
            fill="currentColor"
            {...rest}
        >
            {/* <g id="SVGRepo_bgCarrier" strokeWidth="0" />
       <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
       <g id="SVGRepo_iconCarrier"> 
       <g> 
        <g> 
          <path d="M295.225,142.697c-9.9-44.668-19.801-89.336-29.707-134.003c-16.718,0-33.435,0-50.15,0 c2.389,44.668,4.781,89.336,7.172,134.003H295.225z" /> 
          <path d="M226.354,214.003c3.145,58.703,6.286,117.404,9.426,176.107c38.094,0,76.188,0,114.281,0 c-13.014-58.702-26.021-117.404-39.029-176.107H226.354z" /> 
          <path d="M183.435,8.694c-16.716,0-33.434,0-50.149,0c-9.902,44.667-19.798,89.335-29.698,134.003h72.682 C178.656,98.029,181.043,53.361,183.435,8.694z" /> 
          <path d="M48.742,390.11c38.096,0,76.188,0,114.281,0c3.152-58.702,6.293-117.404,9.43-176.107H87.785 C74.775,272.706,61.763,331.409,48.742,390.11z" /> 
          <path d="M394.176,161.212H4.628c-2.556,0-4.628,2.072-4.628,4.628v25.02c0,2.556,2.072,4.628,4.628,4.628h25.048v37.476 c0,2.556,2.071,4.629,4.627,4.629h24.996c2.117,0,3.964-1.438,4.484-3.488l9.818-38.615h251.602l9.816,38.615 c0.52,2.052,2.369,3.488,4.486,3.488h24.992c2.559,0,4.629-2.073,4.629-4.629v-37.476h25.049c2.557,0,4.629-2.072,4.629-4.628 v-25.02C398.805,163.284,396.732,161.212,394.176,161.212z" /> 
          </g> 
        </g> 
      </g> */}

            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path d="M30.239,32.063c-0.104-0.063-0.211-0.124-0.322-0.178v-5.637c0-2.514-2.043-4.556-4.555-4.556 H10.176c-2.515,0-4.558,2.042-4.558,4.556v5.637C5.508,31.94,5.399,32,5.296,32.063l-0.285-2.701H0v2.581l5.038,0.288 c-0.941,0.668-1.546,1.685-1.546,2.826v5.163c0,1.284,0.763,2.412,1.914,3.061v3.47c0,1.174,1.365,2.125,3.048,2.125 S11.5,47.924,11.5,46.75v-2.886h12.014v2.886c0,1.174,1.362,2.125,3.046,2.125c1.683,0,3.048-0.951,3.048-2.125v-3.22 c1.435-0.577,2.437-1.84,2.437-3.31v-5.163c0-1.142-0.604-2.158-1.546-2.826l5.039-0.288v-2.581h-5.013L30.239,32.063z M7.441,26.248c0-1.508,1.226-2.733,2.734-2.733h15.187c1.507,0,2.732,1.227,2.732,2.733v5.174 c-0.077-0.004-0.153-0.011-0.229-0.011H7.67c-0.076,0-0.152,0.007-0.229,0.011V26.248z M22.779,38.094v1.214h-9.415v-1.214H22.779 z M6.53,39.824v-3.993h5.499v3.993H6.53z M12.757,42.801c-0.421,0-0.76-0.341-0.76-0.76s0.339-0.759,0.76-0.759 c0.42,0,0.759,0.34,0.759,0.759S13.177,42.801,12.757,42.801z M23.538,42.801c-0.419,0-0.759-0.341-0.759-0.76 s0.34-0.759,0.759-0.759c0.421,0,0.763,0.34,0.763,0.759S23.959,42.801,23.538,42.801z M29.765,39.824h-5.498v-3.993h5.498V39.824 z" />
                        <circle cx="44.752" cy="16.099" r="3.088" />
                        <path d="M47.997,37.915c-0.393-0.095-0.671-0.209-0.823-0.34c-0.155-0.133-0.229-0.3-0.229-0.521 c0-0.251,0.08-0.441,0.247-0.58c0.171-0.139,0.413-0.211,0.719-0.211c0.293,0,0.521,0.069,0.675,0.206 c0.152,0.137,0.249,0.353,0.287,0.641l0.008,0.068h0.791l-0.003-0.081c-0.021-0.462-0.196-0.832-0.513-1.099 c-0.236-0.2-0.538-0.327-0.898-0.376v-0.635h-0.812v0.655c-0.301,0.056-0.57,0.177-0.796,0.363 c-0.329,0.271-0.498,0.629-0.498,1.058c0,0.376,0.131,0.692,0.392,0.938c0.258,0.244,0.646,0.426,1.155,0.549 c0.455,0.107,0.784,0.24,0.973,0.392c0.181,0.146,0.268,0.338,0.268,0.583c0,0.301-0.094,0.531-0.284,0.699 c-0.191,0.165-0.463,0.25-0.811,0.25c-0.35,0-0.618-0.085-0.805-0.25c-0.188-0.167-0.282-0.403-0.287-0.715l-0.001-0.076h-0.785 l-0.017,0.206c0.005,0.378,0.181,0.718,0.521,1.01c0.283,0.239,0.609,0.395,0.976,0.458v0.752h0.812v-0.753 c0.367-0.06,0.683-0.199,0.936-0.419c0.357-0.302,0.541-0.699,0.541-1.175c0-0.398-0.148-0.731-0.439-0.996 C49.007,38.252,48.571,38.051,47.997,37.915z" />
                        <path d="M38.816,7.81v7.919h1.22V9.028h15.432v19.646h-6.799v-5.043c0-1.539-1.246-2.788-2.785-2.788 c-0.042,0-0.08,0.006-0.122,0.007c-0.228,0.001-0.459,0.042-0.692,0.116c-0.01,0.003-0.02,0.006-0.027,0.008 c-0.046,0.016-0.092,0.023-0.138,0.043l-10.163,4.102c-1.014,0.41-1.563,1.415-1.229,2.245c0.334,0.831,1.428,1.172,2.441,0.762 l7.145-2.882v3.431h-3.062v-0.763h-1.22v20.055h17.869V7.811L38.816,7.81L38.816,7.81z M47.84,44.756 c-3.491,0-6.332-2.842-6.332-6.333c0-3.493,2.841-6.333,6.332-6.333c3.495,0,6.336,2.84,6.336,6.333 C54.176,41.914,51.336,44.756,47.84,44.756z" />
                        <path d="M47.84,32.595c-3.213,0-5.824,2.614-5.824,5.827c0,3.214,2.611,5.826,5.824,5.826 c3.214,0,5.827-2.612,5.827-5.826C53.668,35.209,51.054,32.595,47.84,32.595z M47.84,43.519c-2.813,0-5.095-2.283-5.095-5.097 c0-2.815,2.28-5.097,5.095-5.097c2.815,0,5.098,2.281,5.098,5.097C52.938,41.236,50.656,43.519,47.84,43.519z" />
                    </g>
                </g>
            </g>
        </svg>
    );
};
