import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { Truck, TruckDataForm, TruckDocument, TruckFilters } from '../../models/Truck';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useTruckService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetTrucks, setFetchingGetTrucks] = useState(false);
    const [fetchingShowTruck, setFetchingShowTruck] = useState(false);
    const [fetchingCreateTruck, setFetchingCreateTruck] = useState(false);
    const [fetchingStoreTruck, setFetchingStoreTruck] = useState(false);
    const [fetchingEditTruck, setFetchingEditTruck] = useState(false);
    const [fetchingUpdateTruck, setFetchingUpdateTruck] = useState(false);
    const [fetchingDeleteTruck, setFetchingDeleteTruck] = useState(false);
    const [fetchingActiveTruck, setFetchingActiveTruck] = useState(false);

    const getTrucks = (truckFilters: TruckFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(truckFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.TRUCKS.GET_TRUCKS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetTrucks
        });
    };

    const showTruck = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRUCKS.GET_TRUCK.replace(':id', id.toString()),
            setFetching: setFetchingShowTruck
        });
    };

    const createTruck = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRUCKS.CREATE_TRUCK,
            setFetching: setFetchingCreateTruck
        });
    };

    const storeTruck = (truck: TruckDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TRUCKS.STORE_TRUCK,
            body: truck,
            requestType: 'multipart',
            setFetching: setFetchingStoreTruck
        });
    };

    const editTruck = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.TRUCKS.EDIT_TRUCK.replace(':id', id.toString()),
            setFetching: setFetchingEditTruck
        });
    };

    const updateTruck = (id: number, truck: TruckDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.TRUCKS.UPDATE_TRUCK.replace(':id', id.toString()),
            body: truck,
            requestType: 'multipart',
            setFetching: setFetchingUpdateTruck
        });
    };

    const deleteTruck = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.TRUCKS.DELETE_TRUCK.replace(':id', id.toString()),
            setFetching: setFetchingDeleteTruck
        });
    };

    const activeTruck = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.TRUCKS.ACTIVE_TRUCK.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveTruck
        });
    };

    return {
        fetchingGetTrucks,
        fetchingShowTruck,
        fetchingCreateTruck,
        fetchingStoreTruck,
        fetchingEditTruck,
        fetchingUpdateTruck,
        fetchingDeleteTruck,
        fetchingActiveTruck,
        getTrucks,
        showTruck,
        createTruck,
        storeTruck,
        editTruck,
        updateTruck,
        deleteTruck,
        activeTruck
    };
};

export default useTruckService;
