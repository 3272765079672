import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../../components/LazyLoading';
import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../../contexts/AppContext';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';

import { useHistory } from 'react-router-dom';

import useExpenseService from '../../../../app/services/hooks/useExpenseService';
import { ExpenseDataForm, defaultExpenseDataForm } from '../../../../app/models/Expense';
import { Provider } from '../../../../app/models/Provider';
import { ExpenseType } from '../../../../app/models/ExpenseType';
import { ExpenseDocumentType } from '../../../../app/models/ExpenseDocumentType';
import CalendarExpenseForm from '../forms/CalendarExpenseForm';
import { Currency } from '../../../../app/models/Currency';
import useNavigationPage from '../../../../hooks/useNavigationPage';

interface Props {
    calendarDate: string | Date;
    eventId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ExpenseCreate = ({ onSaved, onCancel, onError, calendarDate, eventId }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();
    const { navigationPage } = useNavigationPage();

    const {
        createCalendarExpense,
        storeExpense,
        storeExpenseByEvent,
        fetchingCreateCalendarExpense,
        fetchingStoreExpense
    } = useExpenseService();

    const [expense, setExpense] = useState<ExpenseDataForm>({
        ...defaultExpenseDataForm,
        issue_date: calendarDate.toString(),
        event_id: eventId
    });
    const [providers, setProviders] = useState<Provider[]>([]);
    const [expenseTypes, setExpenseTypes] = useState<ExpenseType[]>([]);
    const [expenseDocumentTypes, setExpenseDocumentTypes] = useState<ExpenseDocumentType[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createCalendarExpense({
            onSuccess: (response: ServiceResponse) => {
                setProviders(response.data.providers);
                setExpenseTypes(response.data.expense_types);
                setExpenseDocumentTypes(response.data.expense_document_types);
                setCurrencies(response.data.currencies);
                const default_currency = response.data.currencies.find(
                    (currency: Currency) => currency.name == 'CLP'
                );
                if (default_currency) {
                    setExpense({ ...expense, currency_id: default_currency.id });
                }
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando gasto de viaje...');
        storeExpenseByEvent(eventId, expense, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                if (response.data.is_new_event) {
                    window.location.href = `/calendar-by-worker/${response.data.event_id}/event/show?start_date=${response.data.start_date}&end_date=${response.data.end_date}&admin=true`;
                }
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                useReactConfirmAlert();
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateCalendarExpense ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <CalendarExpenseForm
                        selectedEventDate={calendarDate}
                        ExpenseForm={expense}
                        setExpenseForm={setExpense}
                        expenseTypes={expenseTypes}
                        expenseDocumentTypes={expenseDocumentTypes}
                        errorFields={errorFields}
                        providers={providers}
                        currencies={currencies}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreExpense} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreExpense} />
                </div>
            </div>
        </>
    );
};

export default ExpenseCreate;
