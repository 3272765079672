import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';

import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';

import { toast } from 'react-toastify';

import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import useProviderTypeService from '../../app/services/hooks/useProviderTypeService';
import { ProviderType, ProviderTypeFilters } from '../../app/models/ProviderType';
import ProviderTypeDataTable from './components/tables/ProviderTypeDataTable';
import ProviderTypeCreate from './components/ProviderTypeCreate';
import ProviderTypeEdit from './components/ProviderTypeEdit';
import { useHistory } from 'react-router-dom';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Tipo de proveedores',
        url: '/provider-types',
        isActive: true
    }
];

const ProviderTypes = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetProviderTypes, getProviderTypes, deleteProviderType, activeProviderType } =
        useProviderTypeService();
    const [providerTypes, setProviderTypes] = useState<ProviderType[]>([]);

    const [providerTypeIdEdit, setProviderTypeIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);
    const { navigationPage } = useNavigationPage();

    const [providerTypeFilter, setProviderTypeFilter] = useState<ProviderTypeFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [providerTypeFilter]);

    const getAllProviderTypes = () => {
        getProviderTypes(providerTypeFilter, {
            onSuccess: (response: ServiceResponse) => {
                setProviderTypes(response.data.provider_types);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (providerTypeId: number) => {
        setShowingEdit(true);
        setProviderTypeIdEdit(providerTypeId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setProviderTypeIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllProviderTypes();
    };

    const destroy = (providerTypeId: number) => {
        const _text = 'Está a punto de eliminar el tipo de proveedor #' + providerTypeId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando tipo de proveedor...');
                        deleteProviderType(providerTypeId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El tipo de proveedor no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const active = (providerTypeId: number, active: boolean) => {
        activeProviderType(providerTypeId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Tipo de proveedor" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate callbackCreate={showCreate} title="Nuevo tipo de proveedor" />
                    }
                />
                <ProviderTypeDataTable
                    providerTypes={providerTypes}
                    edit={showEdit}
                    destroy={destroy}
                    active={active}
                    loading={fetchingGetProviderTypes}
                    filter={providerTypeFilter}
                    setFilter={setProviderTypeFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Tipo de proveedor"
                    backdrop={true}
                    showFooter={false}
                >
                    <ProviderTypeCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Tipo de proveedor"
                    backdrop={true}
                    showFooter={false}
                >
                    <ProviderTypeEdit
                        providerTypeId={providerTypeIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default ProviderTypes;
