import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import {
    TransportOrder,
    TransportOrderDataForm,
    TransportOrderFilters
} from '../../app/models/TransportOrder';
import { useHistory } from 'react-router-dom';

import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import useGuideService from '../../app/services/hooks/useGuideService';
import GuideDataTable from '../WorkOrders/components/tables/GuideDataTable';
import { GuideDataForm, GuideFilters } from '../../app/models/Guide';
import { toast } from 'react-toastify';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Guias',
        url: '/Guides',
        isActive: true
    }
];

const Guide = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { navigationPage } = useNavigationPage();

    const {
        fetchingGetGuides,
        getGuides,
        getUrlDocumentGuide,
        downloadDocument,
        fetchingGetUrlDocument
    } = useGuideService();
    const [guides, setGuides] = useState<GuideDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [guideIdEdit, setGuideIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);

    const [filter, setFilter] = useState<GuideFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);

    const getAllGuides = () => {
        getGuides(filter, {
            onSuccess: (response: ServiceResponse) => {
                setGuides(response.data.guides);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (guideId: number) => {
        setShowingEdit(true);
        setGuideIdEdit(guideId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setGuideIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllGuides();
    };

    const document = (guideId: number) => {
        if (showLoading) showLoading('download', 'descargando archivo...');
        getUrlDocumentGuide(guideId, {
            onSuccess: (response: ServiceResponse) => {
                window.open(response.data.url_file);
                if (changeAnimation)
                    changeAnimation('downloadSuccess', 'archivo recuperado con exito', true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) {
                    hideLoading();
                }
                toast.error(response.message);
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Guias" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                {guides ? (
                    <GuideDataTable
                        guides={guides}
                        loading={fetchingGetGuides}
                        totalRows={totalRows}
                        filter={filter}
                        setFilter={setFilter}
                        guidePage={true}
                        documentGuide={document}
                        onSearch={reloadTable}
                    />
                ) : null}
            </DefaultCard>
        </>
    );
};

export default Guide;
