import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import { TollExpenseHistoryDataForm } from '../../../app/models/TollExpenseHistory';
import useTollExpenseService from '../../../app/services/hooks/useExpenseTollService';
import TollExpenseHistoryForm from './forms/TollExpenseHistoryForm';

interface Props {
    tollExpenseId: number;
    errorFields?: any;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    getApprovedTollExpense: (
        tollExpenseId: number,
        tollExpenseHistory: TollExpenseHistoryDataForm
    ) => void;
}

const TollExpenseHistoryCreate = ({
    onSaved,
    onCancel,
    onError,
    errorFields,
    getApprovedTollExpense,
    tollExpenseId
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();

    const { fetchingApprovedTollExpense, approvedTollExpense } = useTollExpenseService();

    const [tollexpenseHistory, setTollExpenseHistory] = useState<TollExpenseHistoryDataForm>({
        comment: '',
        is_approved: false
    });

    const store = () => {
        getApprovedTollExpense(tollExpenseId, tollexpenseHistory);
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <TollExpenseHistoryForm
                        tollExpenseHistoryDataForm={tollexpenseHistory}
                        setTollExpenseHistoryDataForm={setTollExpenseHistory}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingApprovedTollExpense}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingApprovedTollExpense} />
                </div>
            </div>
        </>
    );
};

export default TollExpenseHistoryCreate;
