import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { PaymentStatusLoad } from '../../../../app/models/PaymentStatusLoad';

interface Props {
    load: PaymentStatusLoad;
    setLoad: (load: PaymentStatusLoad) => void;
    errorFields?: any;
}

const PaymentStatusForm = ({ load, setLoad, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setLoad({ ...load, [name]: value });
    };

    return (
        <>
            <div className="mb-2 row">
                <h6 className="mb-4">
                    Los estados de pago se generarán siempre que todos los items de despacho se
                    encuentren en estado Finalizado
                </h6>

                <div className="w-50 mx-auto">
                    <label className="label mb-0">Fecha de emisión</label>
                    <input
                        type="date"
                        className={`form-control  ${fieldHasError('date_of_issue')}`}
                        name="date_of_issue"
                        id="date_of_issue"
                        value={load.date_of_issue}
                        onChange={handleChange}
                        placeholder="Ingrese una fecha de emisión"
                        onFocus={() => onFocusRemove('date_of_issue')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('date_of_issue')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentStatusForm;
