import React, { useEffect } from 'react';
import { Guide, GuideDataForm } from '../../../../app/models/Guide';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Product } from '../../../../app/models/Product';
import { Unit } from '../../../../app/models/Unit';
import { Carrier } from '../../../../app/models/Carrier';
import { Hopper } from '../../../../app/models/Hopper';
import { Truck } from '../../../../app/models/Truck';
import { Place } from '../../../../app/models/Place';
import CustomInput from '../../../../components/inputs/CustomInput';
import { processFile } from '../../../../utils/utils';

interface DispatchProgramItemProps {
    setGuide: (guide: GuideDataForm) => void;
    guide: GuideDataForm;
    products: Product[];

    carriers: Carrier[];

    hoppers: Hopper[];

    trucks: Truck[];

    destinies: Place[];
    origins: Place[];
    units: Unit[];
    errorFields?: any;
}

const GuideForm = ({
    setGuide,
    guide,
    units,
    origins,
    destinies,
    trucks,
    hoppers,
    carriers,
    products,
    errorFields
}: DispatchProgramItemProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, errors, setErrors } =
        useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChangeDocumentFile = async (e: FileList | null, name: string) => {
        if (e) {

            if (e) {
            
                const obj = { [name]:  e[0]};
               
                setGuide({ ...guide, ...obj });
            }

            // const file = e[0];
            // const fileExtension = file.name.split('.').pop()?.toLowerCase();
            // // Por problemas se dejo solo el processFile solo para imagenes Heic que al parecer aca no hay drama
            // if (fileExtension === 'heic' || file.type === 'image/heic') {
            //     const result = await processFile(file);
            //     if (result.error) {
            //         handleRemoveFile();
            //         setErrors({
            //             ...errors,
            //             file: [result.error]
            //         });
            //     } else {
            //         setGuide({ ...guide, ['file']: result.file });
            //     }
            // }else{
            //     setGuide({ ...guide, ['file']: file });
            // }
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setGuide({ ...guide, ...obj });
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setGuide({ ...guide, [name]: value });
    };

    const handleRemoveFile = () => {
        setGuide({ ...guide, ['file']: undefined });
        const fileInput = document.getElementById('file') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const handleRemoveWeighingTicket = () => {
        setGuide({ ...guide, ['weighing_ticket']: undefined });
        const fileInput = document.getElementById('weighing_ticket') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-md-12 col-form-label">Archivo Guia</label>

                    <InputButtonFile
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        name={'file'}
                        objectFile={guide?.file}
                        onFocus={() => onFocusRemove('file')}
                        handleRemoveFile={handleRemoveFile}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('file')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-md-12 col-form-label">Folio Guia</label>

                    <input
                        className={`form-control ${fieldHasError('folio')}`}
                        name="folio"
                        id="folio"
                        type="text"
                        value={guide.folio}
                        onChange={handleChange}
                        placeholder="Ingrese el folio"
                        onFocus={() => onFocusRemove('folio')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('folio')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <label className="col-form-label">Fecha de emisión </label>
                <div className="col-md-12">
                    <input
                        className={`form-control ${fieldHasError('issue_date')}`}
                        name="issue_date"
                        id="issue_date"
                        type="date"
                        value={guide.issue_date}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('issue_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('issue_date')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Conductor</label>
                    <SelectTwo
                        name="worker_id"
                        id="worker_id"
                        inputValue={guide.worker_id}
                        hasError={fieldHasError('worker_id') !== ''}
                        options={SelectTwoMapperOptions(carriers)}
                        onChange={(value: any) => handleChangeSelectTwo('worker_id', value.value)}
                        placeholder={'seleccione transporte'}
                        onFocus={() => onFocusRemove('worker_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('worker_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">tracto</label>
                    <SelectTwo
                        name="truck_id"
                        id="truck_id"
                        inputValue={guide.truck_id}
                        hasError={fieldHasError('truck_id') !== ''}
                        options={SelectTwoMapperOptions(trucks)}
                        onChange={(value: any) => handleChangeSelectTwo('truck_id', value.value)}
                        placeholder={'seleccione tracto'}
                        onFocus={() => onFocusRemove('truck_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('truck_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Tolva</label>
                    <SelectTwo
                        name="hopper_id"
                        id="hopper_id"
                        inputValue={guide.hopper_id}
                        hasError={fieldHasError('hopper_id') !== ''}
                        options={SelectTwoMapperOptions(hoppers)}
                        onChange={(value: any) => handleChangeSelectTwo('hopper_id', value.value)}
                        placeholder={'seleccione Semi Remolque'}
                        onFocus={() => onFocusRemove('hopper_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('hopper_id')}
                    </div>
                </div>
            </div>

            {/*origen destino*/}

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Origen</label>
                    <SelectTwo
                        name="origin_id"
                        id="origin_id"
                        inputValue={guide.origin_id}
                        hasError={fieldHasError('origin_id') !== ''}
                        options={SelectTwoMapperOptions(origins)}
                        onChange={(value: any) => handleChangeSelectTwo('origin_id', value.value)}
                        placeholder={'seleccione origen'}
                        onFocus={() => onFocusRemove('origin_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('origin_id')}
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="col-form-label">Destino</label>
                    <SelectTwo
                        name="destiny_id"
                        id="destiny_id"
                        inputValue={guide.destiny_id}
                        hasError={fieldHasError('destiny_id') !== ''}
                        options={SelectTwoMapperOptions(destinies)}
                        onChange={(value: any) => handleChangeSelectTwo('destiny_id', value.value)}
                        placeholder={'seleccione destino'}
                        onFocus={() => onFocusRemove('destiny_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('destiny_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Producto</label>
                    <SelectTwo
                        name="product_id"
                        id="product_id"
                        inputValue={guide.product_id}
                        options={SelectTwoMapperOptions(products, 'id', ['name'])}
                        hasError={fieldHasError('product_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('product_id', value.value)}
                        placeholder={'Seleccione Producto'}
                        onFocus={() => onFocusRemove('product_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('product_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Cantidad Neta</label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-6">
                            <CustomInput
                                name={'quantity'}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('quantity')}
                                className={`form-control ${fieldHasError('quantity')}`}
                                value={guide.quantity != 0 ? guide.quantity : ''}
                                placeholder="Ingrese cantidad"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('quantity')}
                            </div>
                        </div>

                        <div className="col-6">
                            <SelectTwo
                                name="unit_id"
                                id="unit_id"
                                inputValue={guide.unit_id}
                                options={SelectTwoMapperOptions(units)}
                                hasError={fieldHasError('unit_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('unit_id', value.value)
                                }
                                onFocus={() => onFocusRemove('unit_id')}
                                placeholder={'unidad'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('unit_id')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Ticket de pesaje</label>
                    <InputButtonFile
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        name={'weighing_ticket'}
                        objectFile={guide?.weighing_ticket}
                        onFocus={() => onFocusRemove('weighing_ticket')}
                        handleRemoveFile={handleRemoveWeighingTicket}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('weighing_ticket')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default GuideForm;
