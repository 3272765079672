import React, { useEffect } from 'react';
import { ExpenseHistoryDataForm } from '../../../../app/models/ExpenseHistory';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';

interface ExpenseHistoryFormProps {
    expenseHistoryDataForm: ExpenseHistoryDataForm;
    setExpenseHistoryDataForm: (expenseHistoryDataForm: ExpenseHistoryDataForm) => void;
    errorFields?: any;
}

const ExpenseHistoryForm = ({
    expenseHistoryDataForm,
    setExpenseHistoryDataForm,
    errorFields
}: ExpenseHistoryFormProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setExpenseHistoryDataForm({ ...expenseHistoryDataForm, [name]: value });
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Comentario</label>
                    <textarea
                        className={`form-control ${fieldHasError('comment')}`}
                        name="comment"
                        id="comment"
                        value={expenseHistoryDataForm.comment}
                        onChange={handleChange}
                        placeholder="Ingrese un comentario"
                        onFocus={() => onFocusRemove('comment')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('comment')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExpenseHistoryForm;
