import React, { ChangeEvent, useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Deduction } from '../../../../app/models/Deduction';
import { Bonus } from '../../../../app/models/Bonus';
import { JobTitle } from '../../../../app/models/JobTitle';
import { JobTitleLiquidationSetDataForm } from '../../../../app/models/JobTitleLiquidationSet';

interface Props {
    jobTitleLiquidationSet: JobTitleLiquidationSetDataForm;
    setJobTitleLiquidationSet: (jobTitleLiquidationSet: JobTitleLiquidationSetDataForm) => void;
    deductions: Deduction[];
    bonuses: Bonus[];
    jobTitles: JobTitle[];
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const JobTitleLiquidationSetForm = ({
    jobTitleLiquidationSet,
    setJobTitleLiquidationSet,
    deductions,
    bonuses,
    jobTitles,
    errorFields,
    setErrorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };
        setJobTitleLiquidationSet({ ...jobTitleLiquidationSet, ...obj });
    };

    if (!jobTitleLiquidationSet) return null;

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Puesto de trabajo</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="job_title_id"
                        id="job_title_id"
                        inputValue={jobTitleLiquidationSet.job_title_id}
                        options={SelectTwoMapperOptions(jobTitles)}
                        hasError={fieldHasError('job_title_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('job_title_id', value?.value)
                        }
                        placeholder={'Seleccione puesto de trabajo'}
                        onFocus={() => onFocusRemove('job_title_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('job_title_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Deducciones</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="deductions"
                        id="deductions"
                        inputValue={jobTitleLiquidationSet.deductions}
                        options={SelectTwoMapperOptions(deductions)}
                        hasError={fieldHasError('deductions') !== ''}
                        onChange={(value: any) =>
                            setJobTitleLiquidationSet({
                                ...jobTitleLiquidationSet,
                                deductions: value
                            })
                        }
                        isSortable={true}
                        isMulti={true}
                        placeholder={'Seleccione las deducciones'}
                        onFocus={() => onFocusRemove('deductions')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('deductions')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Bonificaciones</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="bonuses"
                        id="bonuses"
                        inputValue={jobTitleLiquidationSet.bonuses}
                        options={SelectTwoMapperOptions(bonuses)}
                        hasError={fieldHasError('bonuses') !== ''}
                        onChange={(value: any) =>
                            setJobTitleLiquidationSet({
                                ...jobTitleLiquidationSet,
                                bonuses: value
                            })
                        }
                        isSortable={true}
                        isMulti={true}
                        placeholder={'Seleccione los bonos'}
                        onFocus={() => onFocusRemove('bonuses')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('bonuses')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default JobTitleLiquidationSetForm;
