import React, { useEffect } from 'react';
import { setCleanInputError } from '../../../../utils/utils';
import { SplitOrderItem } from '../../../../app/models/TransportationOrderItem';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';

interface TransportationOrderItemProps {
    canBeEdited: boolean;
    // index: number;
    splitData: SplitOrderItem[];
    setSplitData: (splitData: SplitOrderItem[]) => void;
    errorFields?: any;
}

const TransportationOrderItemSplitForm = ({
    canBeEdited = true,
    // index,
    splitData,
    setSplitData,
    errorFields
}: TransportationOrderItemProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = event.target;
        setSplitData(
            splitData.map((item, _index) => {
                if (index === _index) {
                    return {
                        ...item,
                        [name]: name === 'quantity' ? parseInt(value) : value
                    };
                }
                return item;
            }) as SplitOrderItem[]
        );
    };

    const removeSplit = (index: number) => {
        const newSplitData = splitData.filter((_, i) => i !== index);
        setSplitData(newSplitData);
    };

    return (
        <>
            {canBeEdited
                ? splitData.map((split, index) => (
                      <div className="row mb-2" key={index}>
                          <div className="col-md">
                              <label className="label mb-0">Cantidad</label>
                              <input
                                  type="number"
                                  name="quantity"
                                  id="quantity"
                                  onFocus={setCleanInputError}
                                  className={`form-control ${fieldHasError('quantity')}`}
                                  value={splitData[index].quantity}
                                  onChange={(e) => handleChange(e, index)}
                              />
                              <div className="invalid-feedback" style={{ display: 'flex' }}>
                                  {fieldErrorMessage('quantity')}
                              </div>
                          </div>
                          <div className="col-md">
                              <label className="label mb-0">Fecha de Despacho</label>
                              <input
                                  type="date"
                                  className={`form-control ${fieldHasError('dispatch_date')}`}
                                  name="dispatch_date"
                                  id="dispatch_date"
                                  value={splitData[index].dispatch_date}
                                  onChange={(e) => handleChange(e, index)}
                                  placeholder="Ingrese una fecha de despacho"
                                  onFocus={setCleanInputError}
                              />
                              <div className="invalid-feedback" style={{ display: 'flex' }}>
                                  {fieldErrorMessage('dispatch_date')}
                              </div>
                          </div>
                          <div className="col-auto d-flex">
                              <div className="my-auto">
                                  <ButtonTableAction
                                      callbackFunction={() => removeSplit(index)}
                                      classIcon={'mdi mdi-delete'}
                                      colorIcon={'text-danger'}
                                      errorMessage={'No se puede eliminar este registro.'}
                                      title={'Eliminar'}
                                  />
                              </div>
                          </div>
                      </div>
                  ))
                : null}
        </>
    );
};

export default TransportationOrderItemSplitForm;
