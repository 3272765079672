import React, { useContext } from 'react';
import { InternalSettlementFilters } from '../../../../app/models/InternalSettlement';
import { AuthContext } from '../../../../contexts/AuthContext';
import { MonthlyReconociliationFilters } from '../../../../app/models/MonthlyReconciliation';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: MonthlyReconociliationFilters;
    setFilter: (filter: MonthlyReconociliationFilters) => void;
    onSearch: () => void;
};

const MonthlyReconciliationFilter = ({ filter, setFilter, onSearch }: Props) => {
    const { auth } = useContext(AuthContext);

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="row">
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="program_date">
                                Fecha de programa
                            </label>
                            <input
                                title="Fecha de programa"
                                type="month"
                                id="program_date"
                                name="program_date"
                                className="form-control form-control-sm"
                                value={filter.program_date}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className='col-md-auto'>
                        <div style={{marginTop: '22px'}}>
                            <ButtonSearchFilter callbackSearch={onSearch}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonthlyReconciliationFilter;
