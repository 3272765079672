export interface Bonus {
    id?: number;
    name: string;
    description?: string;
    amount?: number;
    currency_id?: number;
    type_of_bonuses?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    is_active?: boolean;
}

export interface BonusFilters {
    id?: number | null;
    name: string | '';
    description?: string | '';
    amount?: number | null;
    type_of_bonuses?: TypeOfBonuses[]
    start_date?: Date | string;
    end_date?: Date | string;
    is_active?: boolean | '';

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface TypeOfBonuses {
    value: number | string;
    label: number | string;
}

export type TypeOfBonusesValues = 'FIJA' | 'PORCENTAJE';

export const typeOfBonuses: TypeOfBonuses[] = [
    {
        value: 'FIJA',
        label: 'Fija'
    },
    {
        value: 'PORCENTAJE',
        label: 'Porcentaje'
    }
 ]

export const defaultBonus: Bonus = {
    name: '',
    description: '',
    start_date: '',
    end_date: ''
}