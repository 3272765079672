import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { AfpRateDataForm } from '../../../../app/models/AfpRate';
import { ValueType } from '../../../../app/models/AfpRate';

interface GeneralProps {
    afpRateForm: AfpRateDataForm;
    setAfpRateForm: (afpRate: AfpRateDataForm) => void;
    valueTypes: ValueType[];
    errorFields?: any;
}

const AfpRateForm = ({ afpRateForm, setAfpRateForm, valueTypes, errorFields }: GeneralProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        setAfpRateForm({ ...afpRateForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setAfpRateForm({ ...afpRateForm, ...obj });
    };

    if (!afpRateForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <label className="col-md-12 col-form-label">Valor</label>
                <div className="col-md-12">
                    <div className="row g-1">
                        <div className="col-6">
                            <input
                                type="number"
                                name="value"
                                id="value"
                                onFocus={() => onFocusRemove('value')}
                                className={`form-control ${fieldHasError('value')}`}
                                value={afpRateForm.value}
                                onChange={handleChange}
                                placeholder="Ingrese valor"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('value')}
                            </div>
                        </div>

                        <div className="col-6">
                            <SelectTwo
                                name="value_type"
                                id="value_type"
                                inputValue={afpRateForm.value_type}
                                options={SelectTwoMapperOptions(valueTypes, 'id', ['name'])}
                                hasError={fieldHasError('value_type') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('value_type', value.value)
                                }
                                onFocus={() => onFocusRemove('value_type')}
                                placeholder={'Tipo de valor'}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('value_type')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Válido desde</label>

                    <input
                        type="date"
                        className={`form-control ${fieldHasError('valid_from')}`}
                        name="valid_from"
                        id="valid_from"
                        value={afpRateForm.valid_from}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('valid_from')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('valid_from')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Válido hasta</label>

                    <input
                        type="date"
                        className={`form-control ${fieldHasError('valid_until')}`}
                        name="valid_until"
                        id="valid_until"
                        value={afpRateForm.valid_until}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('valid_until')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('valid_until')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AfpRateForm;
