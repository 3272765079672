import React, { useEffect, useState } from 'react';
import CurrencyInput from '../../../../components/inputs/CurrencyInput';
import { Bonus, TypeOfBonusesValues } from '../../../../app/models/Bonus';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { setCleanTextAreaError } from '../../../../utils/utils';
import { Currency } from '../../../../app/models/Currency';

interface Props {
    bonusForm: Bonus;
    setBonusForm: (bonus: Bonus) => void;
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const BonusForm = ({
    bonusForm,
    setBonusForm,
    errorFields,
    setErrorFields
}: Props) => {

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setBonusForm({ ...bonusForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setBonusForm({ ...bonusForm, ...obj });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Nombre</label>
                <div className="col-md-9">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={bonusForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre de la bonificación"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>

                <div className="col-md-12">
                    <label className="col-form-label">Descripción</label>
                    <textarea
                        className={`form-control ${fieldHasError('description')}`}
                        name="description"
                        id="description"
                        value={bonusForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BonusForm;
