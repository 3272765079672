import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import LazyLoading from '../../../../components/LazyLoading';
import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import {
    DocumentSetDataForm,
    Equipment,
    defaultDocumentSetDataForm
} from '../../../../app/models/DocumentSet';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useDocumentSetService from '../../../../app/services/hooks/useDocumentSetService';
import DocumentSetForm from '../forms/DocumentSetForm';
import { JobTitle } from '../../../../app/models/JobTitle';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DocumentSetCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const {
        storeDocumentSet,
        fetchingStoreDocumentSet,
        createDocumentSet,
        fetchingCreateDocumentSet
    } = useDocumentSetService();
    const [documentSet, setDocumentSet] = useState<DocumentSetDataForm>(defaultDocumentSetDataForm);
    const [documents, setDocuments] = useState<Document[]>([]);
    const [jobTitles, setJobTitles] = useState<JobTitle[]>([]);
    const [equipments, setEquipments] = useState<Equipment[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        createDocumentSet({
            onSuccess: (response: ServiceResponse) => {
                setDocuments(response.data.documents);
                setJobTitles(response.data.job_titles);
                setEquipments(response.data.equipments);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando set de documentos...');
        storeDocumentSet(documentSet, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateDocumentSet ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <DocumentSetForm
                        documentSetDataForm={documentSet}
                        setDocumentSet={setDocumentSet}
                        documents={documents}
                        jobTitles={jobTitles}
                        equipments={equipments}
                        errorFields={errorFields}
                        setErrorFields={setErrorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreDocumentSet} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreDocumentSet} />
                </div>
            </div>
        </>
    );
};

export default DocumentSetCreate;
