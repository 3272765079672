import React, { useContext, useEffect, useState } from 'react';
import DefaultCard from '../../../components/default/DefaultCard';
import CalendarRequestDataTable from '../components/tables/CalendarRequestDataTable';
import { Worker } from '../../../app/models/Workers';
import useCalendarRequestService from '../../../app/services/hooks/useCalendarRequestService';
import { useParams } from 'react-router-dom';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { CalendarRequest, CalendarRequestfilter } from '../../../app/models/CalendarRequest';
import { toast } from 'react-toastify';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import DefaultModal from '../../../components/default/DefaultModal';
import CalendarRequestFormContainer from '../../Calendar/components/containers/CalendarRequestFormContainer';
import CalendarRequestEdit from '../../WorkersRequests/components/CalendarRequestEdit';

type RouterParams = {
    id: string;
};

const CalendarRequestContainer = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        getWorkerCalendarRequests,
        approvedCalendarRequest,
        activeCalendarRequest,
        deleteCalendarRequest,
        getUrlDocumentCalendarRequest
    } = useCalendarRequestService();
    const [calendarRequests, setCalendarsRequests] = useState<CalendarRequest[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreateRequest, setShowingCreateRequest] = useState(false);
    const [calendarRequestIdEdit, setCalendarRequestIdEdit] = useState<number>(-1);
    const [filter, setFilter] = useState<CalendarRequestfilter>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        event_type_name: ''
    });

    const { id } = useParams<RouterParams>();

    useEffect(() => {
        reloadTable();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);

    const reloadTable = () => {
        getWorkerCalendarRequests(filter, parseInt(id), {
            onSuccess: (response: ServiceResponse) => {
                setCalendarsRequests(response.data.calendar_requests);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
            }
        });
    };

    const showRequest = () => {
        setShowingCreateRequest(true);
    };

    const hideRequest = () => {
        setShowingCreateRequest(false);
    };

    const active = (CalendarRequestId: number, active: boolean) => {
        activeCalendarRequest(CalendarRequestId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const download = (calendarRequestId: number) => {
        if (showLoading) showLoading('download', 'Descargando archivo...');
        getUrlDocumentCalendarRequest(calendarRequestId, {
            onSuccess: (response: ServiceResponse) => {
                window.open(response.data.url_file);
                if (changeAnimation)
                    changeAnimation('downloadSuccess', 'Archivo recuperado con exito', true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) {
                    hideLoading();
                }
                toast.error(response.message);
            }
        });
    };

    const approved = (calendarRequestId: number, value: boolean, message: string) => {
        const _text = `La solicitud # ${calendarRequestId} está apunto de ser ${
            value ? 'aprobada' : 'rechazada'
        }`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', `Cambiando estado de solicitud...`);
                        approvedCalendarRequest(calendarRequestId, value, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: `La solicitud no se ha ${message}.`
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const showEdit = (calendarRequestId: number) => {
        setShowingEdit(true);
        setCalendarRequestIdEdit(calendarRequestId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setCalendarRequestIdEdit(-1);
    };

    const destroy = (calendarRequestId: number) => {
        const _text = 'Está a punto de eliminar lo solicitud #' + calendarRequestId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando solicitud...');
                        deleteCalendarRequest(calendarRequestId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La solicitud no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <CalendarRequestDataTable
                            calendarRequests={calendarRequests}
                            totalRows={totalRows}
                            active={active}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                            download={download}
                            approved={approved}
                            destroy={destroy}
                            edit={showEdit}
                            worker={true}
                            onSearch={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreateRequest && (
                <DefaultModal
                    show={showingCreateRequest}
                    handleClose={hideRequest}
                    title="Añadir nueva solicitud"
                    backdrop={true}
                    showFooter={false}
                >
                    <CalendarRequestFormContainer
                        onSaved={() => {
                            reloadTable();
                            hideRequest();
                        }}
                        onCancel={hideRequest}
                        onError={hideRequest}
                        showWorkerField={true}
                    />
                </DefaultModal>
            )}
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Solicitud de Fecha"
                    backdrop={true}
                    showFooter={false}
                >
                    <CalendarRequestEdit
                        calendarRequestId={calendarRequestIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default CalendarRequestContainer;
