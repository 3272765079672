import React, { useContext, useState } from 'react';
import ActiveControl from '../../../../components/ActiveControl';
import useDocumentSetService from '../../../../app/services/hooks/useDocumentSetService';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { AppContext } from '../../../../contexts/AppContext';

interface Props {
    documentSetId: number;
    checkValue: boolean;
    callbackSuccess: () => void;
}

const DocumentSetSwitchChange = ({ documentSetId, checkValue, callbackSuccess }: Props) => {
    const { hideLoading } = useContext(AppContext);
    const [checked, setChecked] = useState<boolean>(checkValue);
    const { activeDocumentSet } = useDocumentSetService();

    const active = (value: boolean) => {
        setChecked(value);

        activeDocumentSet(documentSetId, value, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                callbackSuccess();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setChecked(!value);
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return <ActiveControl status={checked} id={documentSetId} callback={active} />;
};

export default DocumentSetSwitchChange;
