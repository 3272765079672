import React, { useEffect } from 'react';
import { CarrierDataForm } from '../../../../app/models/Carrier';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useRut from '../../../../hooks/useRut';
import { Provider } from '../../../../app/models/Provider';
import { setCleanTextAreaError } from '../../../../utils/utils';
import { TollDataForm } from '../../../../app/models/Toll';
import { Commune } from '../../../../app/models/Commune';
import { PaymentMethod } from '../../../../app/models/PaymentMethod';

interface GeneralProps {
    tollForm: TollDataForm;
    setTollForm: (truck: TollDataForm) => void;
    providers: Provider[];
    communes: Commune[];
    payment_methods: PaymentMethod[];
    errorFields?: any;
}

const TollForm = ({
    tollForm,
    setTollForm,
    providers,
    communes,
    payment_methods,
    errorFields
}: GeneralProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setTollForm({ ...tollForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setTollForm({ ...tollForm, ...obj });
    };

    const handleFixedRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        setTollForm({ ...tollForm, fixed_rate: checked });
    };

    if (!tollForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Nombre</label>

                    <input
                        className={`form-control ${fieldHasError('name')}`}
                        type="text"
                        name="name"
                        id="name"
                        value={tollForm.name}
                        onChange={handleChange}
                        placeholder="Ingrese un nombre"
                        onFocus={() => onFocusRemove('name')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Proveedores</label>
                    <SelectTwo
                        name="provider_id"
                        id="provider_id"
                        inputValue={tollForm.provider_id}
                        options={SelectTwoMapperOptions(providers, 'id', ['name'])}
                        hasError={fieldHasError('provider_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('provider_id', value.value)}
                        placeholder={'Seleccione Proveedor'}
                        onFocus={() => onFocusRemove('provider_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provider_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Comuna</label>
                    <SelectTwo
                        name="commune_id"
                        id="commune_id"
                        inputValue={tollForm.commune_id}
                        options={SelectTwoMapperOptions(communes, 'id', ['name'])}
                        hasError={fieldHasError('commune_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('commune_id', value.value)}
                        placeholder={'Seleccione comuna'}
                        onFocus={() => onFocusRemove('commune_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('commune_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Forma de pago</label>
                    <SelectTwo
                        name="payment_method"
                        id="payment_method"
                        inputValue={tollForm.payment_method}
                        options={SelectTwoMapperOptions(payment_methods, 'id', ['name'])}
                        hasError={fieldHasError('payment_method') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('payment_method', value.value)
                        }
                        placeholder={'Seleccione forma de pago'}
                        onFocus={() => onFocusRemove('payment_method')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('payment_method')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Latitud</label>
                    <input
                        name={`latitude`}
                        id={`latitude`}
                        type="number"
                        min={1000}
                        className={`form-control ${fieldHasError(`latitude`)}`}
                        value={tollForm.latitude}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove(`latitude`)}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('latitude')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Longitud</label>
                    <input
                        name={`longitude`}
                        id={`longitude`}
                        type="number"
                        min={1000}
                        className={`form-control ${fieldHasError(`longitude`)}`}
                        value={tollForm.longitude}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove(`longitude`)}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('longitude')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row align-items-center">
                <label htmlFor="fixed_rate" className="col-md-3 col-form-label">
                    Tarifa fija
                </label>
                <div className="col-9">
                    <div className="square-switch d-flex  form-check form-switch">
                        <input
                            type="checkbox"
                            name="fixed_rate"
                            id="fixed_rate"
                            className={`form-check-input ${tollForm.fixed_rate ? 'active' : ''}`}
                            checked={tollForm.fixed_rate}
                            onChange={handleFixedRateChange}
                        />
                        {fieldErrorMessage('fixed_rate')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Descripción</label>

                    <textarea
                        className={`form-control ${fieldHasError('description')}`}
                        name="description"
                        id="description"
                        value={tollForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TollForm;
