import React, { useEffect } from 'react';
import { EventTypeDataForm } from '../../../../app/models/EventType';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { colorsOptions } from '../../../../utils/utils';
import ColorPicker from '../../../../components/ColorPicker';

interface Props {
    eventTypeForm: EventTypeDataForm;
    setEventTypeForm: (eventType: EventTypeDataForm) => void;
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const EventTypeForm = ({ eventTypeForm, setEventTypeForm, errorFields, setErrorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setEventTypeForm({ ...eventTypeForm, [name]: value });
    };

    const handleCheckChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name } = event.target;

        setEventTypeForm({
            ...eventTypeForm,
            is_calendar_request: !eventTypeForm.is_calendar_request
        });
    };

    const handleCheckIsTravelExpenseChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        setEventTypeForm({ ...eventTypeForm, is_travel_expense: !eventTypeForm.is_travel_expense });
    };

    const handleCheckIsBussinesChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        setEventTypeForm({ ...eventTypeForm, is_business_day: !eventTypeForm.is_business_day });
    };

    const handleColorChange = (value: string) => {
        const obj = { ['color']: value };
        setEventTypeForm({ ...eventTypeForm, ...obj });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Nombre</label>
                <div className="col-md-9">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={eventTypeForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre del Evento"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>

            <label className="col-md-8 col-form-label">
                ¿Es un evento solicitable para el empleado?
            </label>

            <div className="mb-2 row needs-validation">
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="is_calendar_request"
                            value="is_calendar_request"
                            checked={eventTypeForm.is_calendar_request}
                            id="is_calendar_request"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="is_calendar_request">
                            Solicitable
                        </label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="external"
                            value="external"
                            checked={!eventTypeForm.is_calendar_request}
                            id="is_calendar_request"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="it_external">
                            No solicitable
                        </label>
                    </div>
                </div>
            </div>

            <label className="col-md-3 col-form-label">¿Aplica viático?</label>

            <div className="mb-2 row needs-validation">
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="is_travel_expense"
                            value="is_travel_expense"
                            checked={eventTypeForm.is_travel_expense}
                            id="is_travel_expense"
                            onChange={handleCheckIsTravelExpenseChange}
                        />
                        <label className="form-check-label" htmlFor="is_travel_expense">
                            Sí aplica
                        </label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="is_travel_expense"
                            value="is_travel_expense"
                            checked={!eventTypeForm.is_travel_expense}
                            id="is_travel_expense"
                            onChange={handleCheckIsTravelExpenseChange}
                        />
                        <label className="form-check-label" htmlFor="is_travel_expense">
                            No aplica
                        </label>
                    </div>
                </div>
            </div>

            <label className="col-md-8 col-form-label">¿Es considerado día trabajado?</label>

            <div className="mb-2 row needs-validation">
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="is_business_day"
                            value="is_business_day"
                            checked={eventTypeForm.is_business_day}
                            id="is_business_day"
                            onChange={handleCheckIsBussinesChange}
                        />
                        <label className="form-check-label" htmlFor="is_travel_expense">
                            Sí
                        </label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="is_business_day"
                            value="is_business_day"
                            checked={!eventTypeForm.is_business_day}
                            id="is_business_day"
                            onChange={handleCheckIsBussinesChange}
                        />
                        <label className="form-check-label" htmlFor="is_business_day">
                            No
                        </label>
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <ColorPicker
                        handleColorChange={handleColorChange}
                        color={eventTypeForm.color}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('color')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventTypeForm;
