import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useTransportOrderService from '../../../app/services/hooks/useTransportOrderService';
import { TransportationOrderStatus } from '../../../app/models/TransportationOrderStatus';
import { TransportOrderDataForm, TransportOrderFilters } from '../../../app/models/TransportOrder';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import DefaultModal from '../../../components/default/DefaultModal';
import TransportOrderEdit from './TransportOrderEdit';
import TransportOrderCreate from './TransportOrderCreate';
import { useHistory } from 'react-router-dom';
import useTransportOrderStatusHistoryService from '../../../app/services/hooks/useTransportOrderStatusHistoryService';
import TransportOrdersDataTable from './tables/TransportOrderDataTable';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import useGuideHistoryService from '../../../app/services/hooks/useGuideHistoryService';
import { GuideHistoryDataForm, GuideHistoryFilter } from '../../../app/models/GuideHistory';
import GuideHistoryDataTable from './tables/GuideHistoryDataTable';
import GuideHistoryEdit from './GuideHistoryEdit';
import useLoadTicketHistoryService from '../../../app/services/hooks/useLoadTicketHistoryService';
import {
    LoadTicketHistoryDataForm,
    LoadTicketHistoryFilter
} from '../../../app/models/LoadTicketsHistory';
import LoadTicketHistoryDataTable from './tables/LoadTicketHistoryDataTable';
import LoadTicketHistoryEdit from './LoadTicketHistoryEdit';

interface Props {
    loadTicketId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const LoadTicketHistoryContainer = ({ loadTicketId, onSaved, onCancel, onError }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { getAllLoadTickethistoriesByLoadTicket, fetchingGetLoadTicketHistoriesByLoadTicket } =
        useLoadTicketHistoryService();

    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);

    const [loadTicketHistoryIdEdit, setLoadTicketHistoryIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [loadTicketHistories, setLoadTicketHistories] = useState<LoadTicketHistoryDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<LoadTicketHistoryFilter>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const showEdit = (loadTicketHistoryId: number) => {
        setShowingEdit(true);
        setLoadTicketHistoryIdEdit(loadTicketHistoryId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setLoadTicketHistoryIdEdit(-1);
    };

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getAllLoadTickethistoriesByLoadTicket(filter, loadTicketId, {
            onSuccess: (response: ServiceResponse) => {
                setLoadTicketHistories(response.data.load_ticket_histories);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    if (onError) onError();
                }
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <LoadTicketHistoryDataTable
                            loadTicketHistories={loadTicketHistories}
                            edit={showEdit}
                            loading={fetchingGetLoadTicketHistoriesByLoadTicket}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                        />
                    </div>
                </div>
            </DefaultCard>

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar información"
                    backdrop={true}
                    showFooter={false}
                    centered={true}
                >
                    <LoadTicketHistoryEdit
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                        loadTicketHistoryId={loadTicketHistoryIdEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default LoadTicketHistoryContainer;
