import React, { useContext, useEffect, useState } from 'react';
import { EventType, EventTypeFilters } from '../../app/models/EventType';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import useEventTypeService from '../../app/services/hooks/useEventTypeService';
import DefaultModal from '../../components/default/DefaultModal';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import EventTypeCreate from './components/forms/EventTypeCreate';
import EventTypeEdit from './components/forms/EventTypeEdit';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import { useHistory } from 'react-router-dom';
import useNavigationPage from '../../hooks/useNavigationPage';
import EventTypeDataTable from './components/tables/EventTypeDataTable';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Tipos de Eventos',
        url: '/event-types',
        isActive: true
    }
];

const EventTypes = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetEventTypes, getEventTypes, deleteEventType, activeEventType } =
        useEventTypeService();
    const [eventTypes, setEventTypes] = useState<EventType[]>([]);
    const { navigationPage } = useNavigationPage();

    const [eventTypeIdEdit, setEventTypeIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [eventTypeFilter, setEventTypeFilter] = useState<EventTypeFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        name: ''
    });

    useEffect(() => {
        getAllEventTypes();
    }, [eventTypeFilter.page, eventTypeFilter.per_page, eventTypeFilter.sort, eventTypeFilter.order]);

    const getAllEventTypes = () => {
        getEventTypes(eventTypeFilter, {
            onSuccess: (response: ServiceResponse) => {
                setEventTypes(response.data.event_types);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (eventTypeId: number) => {
        setShowingEdit(true);
        setEventTypeIdEdit(eventTypeId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setEventTypeIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllEventTypes();
    };

    const destroy = (EventTypeId: number, EventTypeName: string) => {
        const _text = 'Está a punto de eliminar el evento ' + EventTypeName;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando evento...');
                        deleteEventType(EventTypeId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El evento no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const active = (EventTypeId: number, active: boolean) => {
        activeEventType(EventTypeId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Tipos de Eventos" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nuevo Evento" />}
                />
                <EventTypeDataTable
                    eventTypes={eventTypes}
                    edit={showEdit}
                    destroy={destroy}
                    active={active}
                    loading={fetchingGetEventTypes}
                    filter={eventTypeFilter}
                    setFilter={setEventTypeFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                    onSearch={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear evento"
                    backdrop={true}
                    showFooter={false}
                >
                    <EventTypeCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar evento"
                    backdrop={true}
                    showFooter={false}
                >
                    <EventTypeEdit
                        eventTypeId={eventTypeIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default EventTypes;
