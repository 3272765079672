import React, { useRef } from 'react';

type Props = {
    handleChangeDocumentFile: (files: any, type: string) => void;
    handleRemoveFile?: () => void;
    objectFile: any;
    name: string;
    onFocus?: (value: any) => void;
    disabled?: boolean;
    accept?: string;
};

const InputButtonFile = ({
    handleChangeDocumentFile,
    handleRemoveFile,
    objectFile,
    name,
    onFocus,
    disabled = false,
    accept = 'image/*,.docx,.xls,.pdf'
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const download = () => {
        if (typeof objectFile === 'string' && objectFile !== '') {
            window.open(objectFile, '_blank');
        } else {
            const url = window.URL.createObjectURL(objectFile);
            const a = document.createElement('a');
            const fileName = objectFile.name; // Obtener el nombre del archivo
            a.href = url;
            a.download = fileName;
            a.click();
        }
    };

    return (
        <>
            <input
                ref={inputRef}
                onChange={(e) => handleChangeDocumentFile(e.target.files, name)}
                className="d-none"
                type="file"
                disabled={disabled}
                accept={accept} // Agregado "image/*" para incluir imágenes
                // capture="environment" // Atributo para sugerir captura de cámara en iOS
                name={name}
                id={name}
            />

            <div className={'input-group mb-3'}>
                <span
                    onClick={download}
                    className={`input-group-text ${
                        objectFile
                            ? 'text-white bg-success border-success pointer m-0'
                            : 'text-white border-secondary bg-secondary secondary'
                    }`}
                    id="basic-addon1"
                >
                    <i className={`${objectFile ? 'bx bx-download' : 'bx bx-file'}`} />
                </span>

                <button
                    style={{ zIndex: 0 }}
                    onClick={handleUpload}
                    onFocus={onFocus}
                    disabled={disabled}
                    className={`form-control btn btn-outline-${
                        objectFile ? 'success mdi mdi-checkbox-marked-circle fs-5' : 'secondary'
                    }`}
                >
                    {objectFile
                        ? ' ok'
                        : // (
                          //     objectFile?.name ??
                          //     objectFile.split('/')[objectFile.split('/').length - 1]
                          // )
                          //       .substring(0, 10)
                          //       .toLowerCase()

                          'Cargar Archivo'}
                </button>

                {objectFile && handleRemoveFile && (
                    <span
                        onClick={handleRemoveFile}
                        className={
                            'input-group-text text-white bg-danger border-danger pointer m-0'
                        }
                        id="basic-addon1"
                    >
                        <i className={'mdi mdi-delete-forever'} />
                    </span>
                )}
            </div>
        </>
    );
};

export default InputButtonFile;
