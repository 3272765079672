import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import useMonthlyReconciliationService from '../../app/services/hooks/useMonthlyReconciliationService';
import {
    MonthlyReconociliationDataForm,
    MonthlyReconociliationFilters
} from '../../app/models/MonthlyReconciliation';
import MonthlyReconciliationDataTable from './components/tables/MonthlyReconciliationDataTable';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Cuadre mensual',
        url: '/monthly-reconciliations',
        isActive: true
    }
];

const MonthlyReconciliations = () => {
    const history = useHistory();

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingGetMonthlyReconciliations,
        fetchingGetExcelMonthlyReconciliations,
        getExcelAllMonthlyReconciliations,
        getMonthlyReconciliations
    } = useMonthlyReconciliationService();
    const [monthlyReconciliations, setMonthlyReconciliations] = useState<
        MonthlyReconociliationDataForm[]
    >([]);
    const { navigationPage } = useNavigationPage();

    const [totalRows, setTotalRows] = useState<number>(0);

    const [monthlyReconciliationFilter, setmonthlyReconciliationFilter] =
        useState<MonthlyReconociliationFilters>({
            page: 1,
            per_page: 10,
            sort: 'id',
            order: 'desc',
            program_date: moment().date(1).format('YYYY-MM')
        });

    useEffect(() => {
        reloadTable();
    }, []);

    const getAllmonthlyReconciliation = () => {
        getMonthlyReconciliations(monthlyReconciliationFilter, {
            onSuccess: (response: ServiceResponse) => {
                setMonthlyReconciliations(response.data.monthly_reconciliations);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const reloadTable = () => {
        getAllmonthlyReconciliation();
    };

    const downloadExcel = () => {
        if (showLoading) showLoading('download', 'Recuperando documento...');
        getExcelAllMonthlyReconciliations(monthlyReconciliationFilter, {
            onSuccess: (message: string) => {
                if (changeAnimation) changeAnimation('downloadSuccess', message, true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Cuadre mensual" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <MonthlyReconciliationDataTable
                    monthlyReconciliations={monthlyReconciliations}
                    loading={fetchingGetMonthlyReconciliations}
                    filter={monthlyReconciliationFilter}
                    setFilter={setmonthlyReconciliationFilter}
                    totalRows={totalRows}
                    downloadExcel={downloadExcel}
                    onSearch={reloadTable}
                />
            </DefaultCard>
        </>
    );
};

export default MonthlyReconciliations;
