import React, { useContext, useEffect } from 'react';

import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { AuthContext } from '../../../../contexts/AuthContext';
import { TollExpenseFilters } from '../../../../app/models/TollExpense';
import { PaymentMethod } from '../../../../app/models/PaymentMethod';
import { ExpenseDocumentType } from '../../../../app/models/ExpenseDocumentType';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: TollExpenseFilters;
    setFilter: (filter: TollExpenseFilters) => void;
    paymentMethods: PaymentMethod[];
    documentTypes: ExpenseDocumentType[];
    onSearch: () => void;
};

const TollExpenseFilter = ({ filter, setFilter, documentTypes, paymentMethods, onSearch }: Props) => {
    const { auth } = useContext(AuthContext);
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handleChangeSelectTwoFilter = (name: string, value: number | string | null) => {
        if (name === 'payment_methods') {
            console.log(value);
            // const updatedPaymentMethods: string[] = filter.payment_methods?.push(value)

            // console.log(value)
            // setFilter({
            //     ...filter,
            //     payment_methods: updatedPaymentMethods ?? []
            // });
        }
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        console.log(filter);
    }, [filter]);

    return (
        <div className="row">
            <div className="row">
                {(auth?.hasAllPermissions || auth?.roles?.includes('Administrador')) && (
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="carrier">
                                Conductor
                            </label>
                            <input
                                title="Conductor"
                                type="text"
                                id="carrier"
                                name="carrier"
                                className="form-control form-control-sm"
                                value={filter.carrier ?? ''}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                )}

                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="provider">
                            Proveedor
                        </label>
                        <input
                            title="Proveedor"
                            type="text"
                            id="provider"
                            name="provider"
                            className="form-control form-control-sm"
                            value={filter.provider ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="provider">
                            Nombre del peaje
                        </label>
                        <input
                            title="Nombre del peaje"
                            type="text"
                            id="toll"
                            name="toll"
                            className="form-control form-control-sm"
                            value={filter.toll ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="expense_document_type">
                            Tipo de documento
                        </label>
                        <select
                            id="expense_document_type"
                            name="expense_document_type"
                            className="form-control form-select-sm"
                            value={filter.expense_document_type ?? ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            {documentTypes.map((documentType) => (
                                <option key={documentType.id} value={documentType.id}>
                                    {documentType.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/*payment_method*/}
                <div className="col-md-auto">
                    <div className="mb-3 ">
                        <label className="font-size-10 mb-1" htmlFor="payment_methods">
                            Método de pago
                        </label>
                        <SelectTwo
                            id="payment_methods"
                            name="payment_methods"
                            inputValue={filter.payment_methods}
                            options={SelectTwoMapperOptions(paymentMethods!)}
                            onChange={(value: any) =>
                                setFilter({
                                    ...filter,
                                    payment_methods: value,
                                    payment_method_list: value.map((item: any) => item.label)
                                })
                            }
                            isMulti={true}
                            placeholder="Seleccione M. Pago"
                            size="small"
                        />
                    </div>
                </div>

                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TollExpenseFilter;
