import { Society } from './Society';

export interface SocietySignature {
    id?: number;
    name?: string;
    job_title?: string;
    signature?: string | null;
    society_id?: number;
    society?: Society;
}
export const defaultSocietySignatureDataForm: SocietySignature = {
    society_id: 1
};

export interface SocietySignatureDataForm {
    id?: number;
    name?: string;
    society_id?: number;
    user_id?: number;
    job_title?: string;
    signature?: string | null;
    society?: Society;
}

export interface SocietySignatureDataFormT {
    id?: number;
    name?: string;
    society_id?: number;
    job_title?: string;
    society?: Society;
}

export interface SocietySignatureFilters {
    id?: number;
    name?: string;
    job_title?: string;
    signatureUrl?: string;
    society?: Society | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}
