import { useContext, useEffect, useState } from 'react'
import Breadcrumbs, {BreadcrumbsItem} from '../../template/MainTheme/components/Breadcrumbs'
import DefaultCard from '../../components/default/DefaultCard'
import DefaultToolBar from '../../components/default/DefaultToolBar'
import ButtonCreate from '../../components/buttons/ButtonCreate'
import { ServiceResponse } from '../../app/services/shared/interfaces'
import useNavigationPage from '../../hooks/useNavigationPage'
import { toast } from 'react-toastify'
import { AppContext } from '../../contexts/AppContext'
import DefaultModal from '../../components/default/DefaultModal'
import useBonusService from '../../app/services/hooks/useBonusService'
import { Bonus, BonusFilters } from '../../app/models/Bonus'
import BonusDataTable from './components/tables/BonusDataTable'
import BonusCreate from './subpages/BonusCreate'
import BonusEdit from './subpages/BonusEdit'
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert'

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Bonificaciones',
        url: '/bonus',
        isActive: true
    }
];

const Bonuses = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { fetchingGetBonuses, getBonuses, deleteBonus, activeBonus } =
        useBonusService();
    const [showingCreate, setShowingCreate] = useState(false);
    const [bonus, setBonus] = useState<Bonus[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [bonusFilter, setBonusFilter] = useState<BonusFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        name: ''
    });

    useEffect(() => {
        reloadTable();
    }, [bonusFilter.page, bonusFilter.per_page, bonusFilter.sort, bonusFilter.order]);

    const getAllBonuses = () => {
        getBonuses(bonusFilter, {
            onSuccess: (response: ServiceResponse) => {
                setBonus(response.data.bonus);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const destroy = (bonusId: number) => {
        const _text = 'Está a punto de eliminar la bonificación #' + bonusId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando bonificación...');
                        deleteBonus(bonusId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La bonificación no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    }

    const showCreate = () => {
        setShowingCreate(true);
    };

    const showEdit = (deductionId: number) => {
        navigationPage('/bonus/' + deductionId + '/edit');
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllBonuses();
    };

    
    return (
        <>
            <Breadcrumbs pageSection="Bonificaciones" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate
                            callbackCreate={showCreate}
                            title="Nueva bonificación"
                        />
                    }
                />
                <BonusDataTable
                    bonuses={bonus}
                    totalRows={totalRows}
                    filter={bonusFilter}
                    loading={fetchingGetBonuses}
                    setFilter={setBonusFilter}
                    callbackSuccess={reloadTable}
                    destroy={destroy}
                    edit={showEdit}
                    onSearch={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear bonificación"
                    backdrop={true}
                    showFooter={false}>
                    <BonusCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

        </>
    );
}

export default Bonuses