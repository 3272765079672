import React from 'react';
import { ShiftDataForm, ShiftDays } from '../../../app/models/Shift';
import ShiftForm from './forms/ShiftForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { EventType } from '../../../app/models/EventType';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    workdays: ShiftDays[];
    shift: ShiftDataForm;
    setShift: (shift: ShiftDataForm) => void;
    errorFields?: any;
    eventTypes: EventType[];
}

const HolidayFormContainer = ({
    fetching,
    workdays,
    action,
    cancel,
    shift,
    setShift,
    eventTypes,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ShiftForm
                        shiftDataForm={shift}
                        setShiftDataForm={setShift}
                        workdays={workdays}
                        errorFields={errorFields}
                        eventTypes={eventTypes}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default HolidayFormContainer;
