import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';

import {
    CheckingAccountDataForm,
    CheckingAccountFilters
} from '../../../../app/models/CheckingAccount';
import CheckingAccountFilter from './CheckingAccountFilter';
import { momentParseDate } from '../../../../helpers';
import { formatNumber } from '../../../../utils/utils';

interface Props {
    checkingAccounts: CheckingAccountDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: CheckingAccountFilters;
    setFilter: (filter: CheckingAccountFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const CheckingAccountDataTable = ({
    checkingAccounts,
    totalRows,
    loading = false,
    edit,
    destroy,
    active,
    show,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Fecha de emisión',
            selector: (row: any) => row?.issue_date,
            sortable: true,
            sortField: 'issue_date',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date)}</span>
            )
        },
        {
            name: 'Conductor',
            selector: (row: any) => row?.carrier,
            sortable: true,
            sortField: 'carrier',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">
                    <div dangerouslySetInnerHTML={{ __html: row.carrier ? row.carrier : '' }} />
                </span>
            )
        },
        {
            name: 'Motivo',
            selector: (row: any) => row.transfer_reason,
            sortable: true,
            sortField: 'transfer_reason',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.transfer_reason}</span>
            )
        },
        {
            name: 'N° cuenta',
            selector: (row: any) => row.account_number,
            sortable: true,
            sortField: 'account_number',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.account_number}</span>
            )
        },
        {
            name: 'Banco',
            selector: (row: any) => row.bank,
            sortable: true,
            sortField: 'bank',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.bank}</span>
            )
        },
        {
            name: 'Tipo de cuenta',
            selector: (row: any) => row.account_type,
            sortable: true,
            sortField: 'account_type',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.account_type}</span>
            )
        },
        {
            name: 'Monto',
            selector: (row: any) => row?.amount,
            sortable: true,
            sortField: 'amount',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {formatNumber(row.amount ? row.amount : 0)} {row.currency}
                    </span>
                </>
            )
        },

        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <CheckingAccountFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={checkingAccounts}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default CheckingAccountDataTable;
