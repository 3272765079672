import React, { useEffect } from 'react';
import { Provider } from '../../../app/models/Provider';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import useRut from '../../../hooks/useRut';
import SelectTwo, { SelectTwoMapperOptions } from '../../../components/SelectTwo';
import { countriesOptions } from '../../../utils/utils';
import { ProviderType } from '../../../app/models/ProviderType';
import { Society } from '../../../app/models/Society';
import LazyLoading from '../../../components/LazyLoading';
import { User } from '../../../app/models/User';

type Props = {
    Provider: Provider;
    setProvider: (Provider: Provider) => void;
    errorFields?: any;
    providerTypes: ProviderType[];
    societies: Society[];
    users: User[];
    fetchingGetSocieties?: boolean;
    fetchingGetUser?: boolean;
    nextId?: number;
};

const ProviderForm = ({
    Provider,
    setProvider,
    providerTypes,
    societies,
    users,
    fetchingGetSocieties = false,
    fetchingGetUser = false,
    errorFields,
    nextId = 0
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        if (Provider.is_national === undefined) {
            setProvider({ ...Provider, is_national: true });
        }
    }, []);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        if (name == 'rut' || name == 'rut_contact') {
            setProvider({ ...Provider, [name]: formatRut(value) });
        } else {
            setProvider({ ...Provider, [name]: value });
        }
    };

    const handleCheckChange = () => {
        const isNational = !Provider.is_national;
        const updatedProvider = { ...Provider, is_national: isNational };

        if (!isNational && nextId !== undefined) {
            if (!Provider.id) {
                updatedProvider.id = nextId;
            }
            updatedProvider.rut = null;
        }
        setProvider(updatedProvider);
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setProvider({ ...Provider, [name]: value });
    };

    return (
        <>
            {fetchingGetSocieties ? (
                <LazyLoading height={300} />
            ) : (
                <>
                    <div className="mb-4 mt-2 row needs-validation">
                        <div className="col-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="national"
                                    value="national"
                                    checked={Provider.is_national}
                                    id="national"
                                    onChange={handleCheckChange}
                                />
                                <label className="form-check-label" htmlFor="national">
                                    Nacional
                                </label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="foreign"
                                    value="foreign"
                                    checked={!Provider.is_national}
                                    id="foreign"
                                    onChange={handleCheckChange}
                                />
                                <label className="form-check-label" htmlFor="foreign">
                                    Extranjero
                                </label>
                            </div>
                        </div>
                    </div>

                    {Provider.is_national && (
                        <div className="mb-2 row">
                            <label className="col-md-3 col-form-label">Rut</label>
                            <div className="col-md-9">
                                <input
                                    name="rut"
                                    id="rut"
                                    type="text"
                                    className="form-control"
                                    value={Provider.rut || ''}
                                    onChange={handleChange}
                                    onFocus={() => onFocusRemove('rut')}
                                    placeholder="Ingrese el RUT del proveedor"
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('rut')}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mb-2 row needs-validation">
                        <label className="col-md-3 col-form-label">Razón social</label>
                        <div className="col-md-9">
                            <input
                                name="business_name"
                                id="business_name"
                                type="text"
                                className={`form-control ${fieldHasError('business_name')}`}
                                value={Provider.business_name}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('business_name')}
                                placeholder="Ingrese razón social"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('business_name')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Nombre fantasía</label>
                        <div className="col-md-9">
                            <input
                                name="fantasy_name"
                                id="fantasy_name"
                                type="text"
                                className="form-control"
                                value={Provider.fantasy_name}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('fantasy_name')}
                                placeholder="Ingrese nombre de fantasía"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('fantasy_name')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">País</label>
                        <div className="col-md-9">
                            <SelectTwo
                                name="country"
                                id="country"
                                inputValue={Provider.country}
                                options={SelectTwoMapperOptions(countriesOptions, 'value', [
                                    'label'
                                ])}
                                hasError={fieldHasError('country') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('country', value?.value)
                                }
                                placeholder={'Seleccione Pais'}
                                onFocus={() => onFocusRemove('country')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('country')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label"> Dirección </label>
                        <div className="col-md-9">
                            <input
                                name="address"
                                id="address"
                                type="text"
                                className={`form-control ${fieldHasError('address')}`}
                                value={Provider.address}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('address')}
                                placeholder="Ingrese la dirección"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('address')}
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Tipo Proveedor</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="provider_type_id"
                        inputValue={Provider.provider_type_id}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('provider_type_id', value.value)
                        }
                        options={SelectTwoMapperOptions(providerTypes ?? [])}
                        placeholder="Seleccione el tipo de proveedor"
                        onFocus={() => onFocusRemove('provider_type_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provider_type_id')}
                    </div>
                </div>
            </div>

            <hr />
            <h5 className="mb-3">Datos de Contacto</h5>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Usuario</label>
                    <SelectTwo
                        name="user_id"
                        id="user_id"
                        inputValue={Provider.user_id}
                        options={SelectTwoMapperOptions(users, 'id', ['name'])}
                        hasError={fieldHasError('user_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('user_id', value?.value)}
                        placeholder={'Seleccione Usuario'}
                        onFocus={() => onFocusRemove('user_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('user_id')}
                    </div>
                </div>
            </div>
            {fetchingGetUser ? (
                <LazyLoading height={300} />
            ) : (
                <>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Rut Contacto</label>
                            <input
                                name="rut_contact"
                                id="rut_contact"
                                type="text"
                                className="form-control"
                                value={Provider.rut_contact}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('rut_contact')}
                                placeholder="Ingrese el RUT del contacto del proveedor"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('rut_contact')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <div className="col-md-6">
                            <label className="col-form-label">Nombre</label>
                            <input
                                name="first_name_contact"
                                id="first_name_contact"
                                type="text"
                                className={`form-control ${fieldHasError('first_name_contact')}`}
                                value={Provider.first_name_contact}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('first_name_contact')}
                                placeholder="Ingrese el nombre"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('first_name_contact')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Email</label>

                            <input
                                name="email_contact"
                                id="email_contact"
                                type="email"
                                className={`form-control ${fieldHasError('email_contact')}`}
                                value={Provider.email_contact}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('email_contact')}
                                placeholder="Ingrese el email"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('email_contact')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row needs-validation">
                        <div className="col-md-6">
                            <label className="col-form-label">Apellido Paterno</label>
                            <input
                                name="last_name_contact"
                                id="last_name_contact"
                                type="text"
                                className={`form-control ${fieldHasError('last_name_contact')}`}
                                value={Provider.last_name_contact}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('last_name_contact')}
                                placeholder="Ingrese el apellido paterno"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('last_name_contact')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Apellido Materno</label>

                            <input
                                name="second_last_name_contact"
                                id="second_last_name_contact"
                                type="text"
                                className={`form-control ${fieldHasError(
                                    'second_last_name_contact'
                                )}`}
                                value={Provider.second_last_name_contact}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('second_last_name_contact')}
                                placeholder="Ingrese el apellido materno"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('second_last_name_contact')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Teléfono Contacto</label>
                        <div className="col-md-9 ">
                            <div className="row g-1">
                                <div className="col-3">
                                    <select
                                        className="form-control form-control-custom pl-2"
                                        id="phone_code_contact"
                                        name="phone_code_contact"
                                        onChange={handleChange}
                                        value={Provider.phone_code_contact}
                                        onFocus={() => onFocusRemove('phone_code_contact')}
                                    >
                                        <option value="">--</option>
                                        <option value="+56">+56</option>
                                    </select>
                                </div>

                                <div className="col-9">
                                    <input
                                        name="phone_contact"
                                        id="phone_contact"
                                        type="number"
                                        className="form-control"
                                        value={Provider.phone_contact}
                                        onChange={handleChange}
                                        onFocus={() => onFocusRemove('phone_contact')}
                                        placeholder="Teléfono"
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('phone_contact')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ProviderForm;
