import { Afp } from './Afp';
import { Provision } from './Provision';
import { Bank } from './Bank';
import { BankAccountType } from './BankAccountType';
import { Group } from './Group';
import { JobTitleDataForm } from './JobTitle';

export interface User {
    id?: number;

    email?: string;
    password?: string;
    first_name?: string;
    last_name?: string;
    rut: string;
    second_last_name?: string;
    it_internal: boolean;
    birthdate: string;
    nationality?: string;
    gender?: string;
    address?: string;
    phone_code?: string;
    phone?: string;
    afp_id?: number;
    afp?: Afp;
    provision_id?: number;
    prevision?: Provision;
    bank_id?: number;
    bank?: Bank;
    account_type_id?: number;
    bank_account_type?: BankAccountType;
    account_number?: string;
    is_active: boolean;
    groups?: Group[];
    avatar?: File;
}

export interface UserPasswordDataForm {
    old_password?: string;
    password?: string;
    repeat_password?: string;
}

export interface IconPasswordChange {
    value: boolean;
    type: string;
}

export const defaultIconPasswordChange: IconPasswordChange = {
    value: true,
    type: 'password'
};

export const defaultUserDataForm: User = {
    first_name: '',
    last_name: '',
    second_last_name: '',
    email: '',
    password: '',
    address: '',
    groups: undefined,
    birthdate: '',
    it_internal: true,
    rut: '',
    is_active: true,
    phone: ''
};

export const defaultUserPasswordDataForm: UserPasswordDataForm = {
    password: '',
    repeat_password: ''
};

export interface UserFilters {
    rut?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    second_last_name?: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface UserDataForm {
    id?: number;
    user_id?: number;
    user?: User;
    rut: string;
    first_name?: string;
    last_name?: string;
    second_last_name?: string;
    password?: string;
    email?: string;
    it_internal: boolean;
    birthdate: string;
    nationality?: string;
    gender?: string;
    address?: string;
    phone_code?: string;
    phone?: string;
    afp_id?: number;
    shift_id?: string;
    afp?: Afp;
    provision_id?: number;
    prevision?: Provision;
    bank_id?: number;
    bank?: Bank;
    account_type_id?: number;
    bank_account_type?: BankAccountType;
    account_number?: string;
    is_active: boolean;
    groups?: Group[];
    roles?: Group[];
    jobTitles?: JobTitleDataForm[];
    avatar?: File;
}
