import React, { useEffect } from 'react';
import { GuideHistoryDataForm } from '../../../../app/models/GuideHistory';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';

interface GuideHistoryFormProps {
    guideHistoryDataForm: GuideHistoryDataForm;
    setGuideHistoryDataForm: (guideHistory: GuideHistoryDataForm) => void;
    errorFields?: any;
}

const GuideHistoryForm = ({
    guideHistoryDataForm,
    setGuideHistoryDataForm,
    errorFields
}: GuideHistoryFormProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setGuideHistoryDataForm({ ...guideHistoryDataForm, [name]: value });
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Comentario</label>
                    <textarea
                        className={`form-control ${fieldHasError('comment')}`}
                        name="comment"
                        id="comment"
                        value={guideHistoryDataForm.comment}
                        onChange={handleChange}
                        placeholder="Ingrese un comentario"
                        onFocus={() => onFocusRemove('comment')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('comment')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default GuideHistoryForm;
