export interface Clasification {
    id?: number;
    name?: string;
    description?: string;

    type: number;
    clasif_id?: number;
    clasif_two_id?: number;
    clasif_three_id?: number;

    account_number: string;
    is_active?: boolean;
}

export interface ClasificationDataForm {
    id?: number;
    name?: string;
    description?: string;

    type: number;
    clasif_id?: number;
    clasif_two_id?: number;
    clasif_three_id?: number;

    account_number: string;
    is_active?: boolean;
}

export interface ClasificationFilters {
    id?: number | null;
    name?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
    type?: number | '';
}

export const defaultClasificationDataForm: ClasificationDataForm = {
    name: '',
    description: '',
    type: 0,
    account_number: ''
};
