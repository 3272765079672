import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useCarrierService from '../../../app/services/hooks/useCarrierService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useSweetAlert from '../../../hooks/useSweetAlert';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { Tab, Tabs } from 'react-bootstrap';
import CarrierForm from '../components/forms/CarrierForm';
import { toast } from 'react-toastify';
import { Society } from '../../../app/models/Society';
import { User } from '../../../app/models/User';
import { Provider } from '../../../app/models/Provider';
import { Carrier, CarrierDataForm } from '../../../app/models/Carrier';
import DefaultCard from '../../../components/default/DefaultCard';
import CarrierDocumentsForm from '../../../components/GeneralDocumentsTransporterTable';
import DefaultModal from '../../../components/default/DefaultModal';
import CarrierCreate from '../components/CarrierCreate';
import GeneralDocumentsTransporterTable from '../../../components/GeneralDocumentsTransporterTable';
import CarrierDocumentCreate from '../components/CarrierDocumentCreate';
import LazyLoading from '../../../components/LazyLoading';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import CarrierHistoryDocument from '../components/CarrierHistoryDocument';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

type RouterParams = {
    id: string;
};

const CarrierEdit = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();
    const [verifyingCarrier, setVerifyingCarrier] = useState<boolean>(true);
    const [users, setUsers] = useState<User[]>([]);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [carrier, setCarrier] = useState<Carrier>({
        ['is_internal']: undefined,
        is_active: true
    });
    const [copyCarrier, setCopyCarrier] = useState<Carrier>();
    const [documentTypes, setDocumentTypes] = useState<any[]>([]);
    const [showingCreateDocument, setShowingCreateDocument] = useState(false);
    const [showingHistoryDocument, setShowingHistoryDocument] = useState(false);
    const [typeDocumentSelected, setTypeDocumentSelected] = useState<any>();
    const [errorFields, setErrorFields] = useState<any>();
    const { editCarrier, fetchingEditCarrier, updateCarrier, fetchingUpdateCarrier } =
        useCarrierService();

    useEffect(() => {
        edit(parseInt(id));
    }, [id]);

    useEffect(() => {
        console.log('aaa');
        if (carrier?.is_internal == copyCarrier?.is_internal) {
            console.log('igual');

            if (carrier?.is_internal == true) {
                setCarrier({
                    ...carrier,
                    society_id: copyCarrier?.society_id,
                    provider_id: undefined,
                    is_internal: true
                });
            } else if (carrier?.is_internal == false) {
                setCarrier({
                    ...carrier,
                    provider_id: copyCarrier?.provider_id,
                    society_id: undefined,
                    is_internal: false
                });
            }
        } else {
            console.log('diferente');
            if (carrier?.is_internal == true) {
                setCarrier({
                    ...carrier,
                    society_id: 0,
                    provider_id: undefined,
                    is_internal: true
                });
            } else if (carrier?.is_internal == false) {
                setCarrier({
                    ...carrier,
                    provider_id: 0,
                    society_id: undefined,
                    is_internal: false
                });
            }
        }
    }, [carrier?.is_internal]);

    const edit = (id: number) => {
        editCarrier(id, {
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);

                setCarrier(response.data.carrier);
                setCopyCarrier(response.data.carrier);
                setUsers(response.data.users);
                setProviders(response.data.providers);
                setDocumentTypes(
                    response.data.document_types.map((document_type: any) => {
                        switch (document_type[0]) {
                            case 'CARNET_DE_INDENTIDAD':
                                return {
                                    label: 'Carnet de identidad',
                                    value: document_type[0]
                                };
                            case 'LICENCIA_DE_CONDUCIR':
                                return {
                                    label: 'Licencia de conducir',
                                    value: document_type[0]
                                };
                            case 'EXAMENES_PRE_OCUPACIONALES':
                                return {
                                    label: 'Exámenes pre ocupacionales',
                                    value: document_type[0]
                                };
                            case 'CONTRATO':
                                return {
                                    label: 'Contrato',
                                    value: document_type[0]
                                };
                            case 'EXAMEN_DE_ALTURA':
                                return {
                                    label: 'Examen de altura',
                                    value: document_type[0]
                                };
                            case 'FINIQUITO':
                                return {
                                    label: 'Finiquito',
                                    value: document_type[0]
                                };
                            default:
                                return {
                                    label: document_type[0],
                                    value: document_type[0]
                                };
                        }
                    })
                );
                setVerifyingCarrier(false);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    const update = () => {
        if (carrier) {
            if (showLoading) showLoading('loading', 'Actualizando conductor...');

            updateCarrier(Number(carrier?.id), carrier, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    setErrorFields(undefined);
                    edit(parseInt(id));
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    const showCreateDocument = (type: string) => {
        setTypeDocumentSelected(type);
        setShowingCreateDocument(true);
    };

    const hideCreateDocument = () => {
        setShowingCreateDocument(false);
    };

    const showHistoryDocument = (type: string) => {
        setTypeDocumentSelected(type);
        setShowingHistoryDocument(true);
    };

    const hideHistoryDocument = () => {
        setShowingHistoryDocument(false);
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Conductores',
            url: '/carriers',
            isActive: false
        },
        {
            name: 'Editar #' + id,
            url: '/carriers/' + id + '/edit',
            isActive: true
        }
    ];

    if (verifyingCarrier) {
        return (
            <DefaultCard>
                <LazyLoading height={300} />
            </DefaultCard>
        );
    }

    return (
        <>
            <Breadcrumbs pageSection={`Editar #${id}`} breadcrumbs={breadcrumbs} />

            {/* Tabs */}

            <Tabs defaultActiveKey="general-data" id="quote-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <div className="row">
                        <div className="col-md-6">
                            <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                                {carrier && (
                                    <CarrierForm
                                        carrierForm={carrier}
                                        setCarrierForm={setCarrier}
                                        users={users}
                                        societies={societies}
                                        providers={providers}
                                        errorFields={errorFields}
                                    />
                                )}
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdateCarrier}
                                        />
                                    </div>
                                </div>
                            </DefaultCard>
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="management" title="Gestión" disabled>
                    Gestión
                </Tab>
            </Tabs>

            {carrier && (
                <div className="row">
                    <div className="col-md-12">
                        <DefaultCard>
                            <div className="h3 mt-0 mb-2 card-title">Documentos</div>
                            <GeneralDocumentsTransporterTable
                                data={carrier}
                                create={showCreateDocument}
                                showHistory={showHistoryDocument}
                                documentTypes={documentTypes}
                            />
                        </DefaultCard>
                    </div>
                </div>
            )}

            {showingCreateDocument ? (
                <DefaultModal
                    show={showingCreateDocument}
                    handleClose={hideCreateDocument}
                    title="Agregar Documento"
                    backdrop={true}
                    showFooter={false}
                >
                    <CarrierDocumentCreate
                        onSaved={() => {
                            edit(parseInt(id));
                            hideCreateDocument();
                        }}
                        typeDocument={typeDocumentSelected}
                        carrierId={Number(carrier?.id)}
                        onCancel={hideCreateDocument}
                        onError={hideCreateDocument}
                    />
                </DefaultModal>
            ) : null}

            {showingHistoryDocument ? (
                <DefaultModal
                    show={showingHistoryDocument}
                    handleClose={hideHistoryDocument}
                    title={`Historial de documentos de ${
                        documentTypes?.find(
                            (document_type: any) => document_type.value === typeDocumentSelected
                        ).label
                    }`}
                    backdrop={true}
                    size={'lg'}
                    showFooter={false}
                >
                    <CarrierHistoryDocument
                        typeDocument={typeDocumentSelected}
                        carrierId={Number(carrier?.id)}
                        onCancel={hideHistoryDocument}
                        onError={hideHistoryDocument}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default CarrierEdit;
