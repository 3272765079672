import React, { useState, useContext, useEffect } from 'react'
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useJobTitleLiquidationSetService from '../../app/services/hooks/useJobTitleLiquidationSetService';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import { toast } from 'react-toastify';
import useNavigationPage from '../../hooks/useNavigationPage';
import DefaultModal from '../../components/default/DefaultModal';
import { JobTitleLiquidationSetDataForm, JobTitleLiquidationSetFilters } from '../../app/models/JobTitleLiquidationSet';
import JobTitleLiquidationSetDataTable from './components/tables/JobTitleLiquidationSetDataTable';
import JobTitleLiquidationSetCreate from './components/subcomponents/JobTitleLiquidationSetCreate';
import JobTitleLiquidationSetEdit from './components/subcomponents/JobTitleLiquidationSetEdit';



const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Set de liquidaciones',
        url: '/document-sets',
        isActive: true
    }
]

const JobTitleLiquidationSet = () => {

    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);
    const [jobTitleLiquidationSets, setJobTitleLiquidationSets] = useState<JobTitleLiquidationSetDataForm[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [jobTitleLiquidationSetIdEdit, setJobTitleLiquidationSetIdEdit] = useState<number>(-1);
    const { getJobTitleLiquidationSets, activeJobTitleLiquidationSet, deleteJobTitleLiquidationSet, fetchingGetJobTitleLiquidationSets } = useJobTitleLiquidationSetService();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { navigationPage } = useNavigationPage();


    const [filter, setFilter] = useState<JobTitleLiquidationSetFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
    });

    useEffect(() => {
        reloadTable();
    }, []);

    const reloadTable = () => {
        getJobTitleLiquidationSets(filter, {
            onSuccess: (response: ServiceResponse) => {
                setJobTitleLiquidationSets(response.data.job_title_liquidation_sets);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const active = (jobTitleLiquiSetId: number, active: boolean) => {
        activeJobTitleLiquidationSet(jobTitleLiquiSetId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const destroy = (jobTitleLiquiSetId: number) => {
        const _text = 'Está a punto de eliminar el set #' + jobTitleLiquiSetId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando documento...');
                        deleteJobTitleLiquidationSet(jobTitleLiquiSetId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });
                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El documento no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (jobTitleLiquiSetId: number) => {
        setShowingEdit(true);
        setJobTitleLiquidationSetIdEdit(jobTitleLiquiSetId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setJobTitleLiquidationSetIdEdit(-1);
    };


    return (
        <>
            <Breadcrumbs pageSection='Set de Liquidaciones' breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col'>
                                <ButtonCreate callbackCreate={showCreate} title='Nuevo Set de liquidaciones' />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 mt-3'>
                        <JobTitleLiquidationSetDataTable
                            active={active}
                            jobTitleLiquidationSets={jobTitleLiquidationSets}
                            totalRows={totalRows}
                            loading={fetchingGetJobTitleLiquidationSets}
                            destroy={destroy}
                            edit={showEdit}
                            filter={filter}
                            setFilter={setFilter}
                            callbackSuccess={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
        
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Set de Liquidación"
                    backdrop={true}
                    showFooter={false}
                >
                    <JobTitleLiquidationSetCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar set"
                    backdrop={true}
                    showFooter={false}>
                    <JobTitleLiquidationSetEdit
                        jobTitleLiquidationSetId={jobTitleLiquidationSetIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    )
}

export default JobTitleLiquidationSet;
