import React from 'react';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: any;
    setFilter: (filter: any) => void;
    onSearch: () => void;
};

const WorkerFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="origin">
                            Rut
                        </label>
                        <input
                            title="Rut"
                            type="text"
                            id="rut"
                            name="rut"
                            className="form-control form-control-sm"
                            value={filter.rut}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="origin">
                            Nombre
                        </label>
                        <input
                            title="Nombre"
                            type="text"
                            id="first_name"
                            name="first_name"
                            className="form-control form-control-sm"
                            value={filter.first_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="origin">
                            Apellido Paterno
                        </label>
                        <input
                            title="Nombre"
                            type="text"
                            id="last_name"
                            name="last_name"
                            className="form-control form-control-sm"
                            value={filter.last_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkerFilter;
