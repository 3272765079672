import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';

import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

import { Provider } from '../../../app/models/Provider';
import { useHistory } from 'react-router-dom';

import useTollService from '../../../app/services/hooks/useTollService';
import { TollDataForm, defaultTollDataForm } from '../../../app/models/Toll';
import TollForm from './forms/TollForm';
import { Commune } from '../../../app/models/Commune';
import { PaymentMethod } from '../../../app/models/PaymentMethod';
import useNavigationPage from '../../../hooks/useNavigationPage';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TollCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();
    const { navigationPage } = useNavigationPage();

    const [providers, setProviders] = useState<Provider[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [toll, setToll] = useState<TollDataForm>(defaultTollDataForm);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingCreateToll, fetchingStoreToll, createToll, storeToll } = useTollService();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createToll({
            onSuccess: (response: ServiceResponse) => {
                setProviders(response.data.providers);
                setCommunes(response.data.communes);
                setPaymentMethods(response.data.payment_methods);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando peaje...');
        storeToll(toll, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                navigationPage('/tolls/' + response.data.toll_id + '/edit');
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateToll ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <TollForm
                        tollForm={toll}
                        setTollForm={setToll}
                        providers={providers}
                        communes={communes}
                        payment_methods={paymentMethods}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreToll} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreToll} />
                </div>
            </div>
        </>
    );
};

export default TollCreate;
