import React from 'react';
import { TollFilters } from '../../../../app/models/Toll';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: TollFilters;
    setFilter: (filter: TollFilters) => void;
    onSearch: () => void;
};

const TollFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="toll_id">
                            Id
                        </label>
                        <input
                            title="Id"
                            type="text"
                            id="toll_id"
                            name="toll_id"
                            className="form-control form-control-sm"
                            value={filter.toll_id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="description">
                            Descripción
                        </label>
                        <input
                            title="Descripción"
                            type="text"
                            id="description"
                            name="description"
                            className="form-control form-control-sm"
                            value={filter.description}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="name">
                            Nombre
                        </label>
                        <input
                            title="name"
                            type="text"
                            id="name"
                            name="name"
                            className="form-control form-control-sm"
                            value={filter.name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="provider">
                            Proveedor
                        </label>
                        <input
                            title="Proveedor"
                            type="text"
                            id="provider"
                            name="provider"
                            className="form-control form-control-sm"
                            value={filter.provider}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="commune">
                            Comuna
                        </label>
                        <input
                            title="Comuna"
                            type="text"
                            id="commune"
                            name="commune"
                            className="form-control form-control-sm"
                            value={filter.commune}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TollFilter;
