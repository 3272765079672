import { Document } from './Document';
import { JobTitle } from './JobTitle';

export interface DocumentSet {
    id?: number;
    name: string;
    documents?: Document[];
    is_active: boolean;
}

export interface DocumentSetDataForm {
    id?: number;
    name: string;
    documents?: Document[];
    job_titles?: JobTitle[];
    is_active?: boolean;
    // is_truck_document_set?: boolean;
    // is_hopper_document_set?: boolean;
    is_society_document_set?: boolean;
    has_equipment?: boolean;
    equipments?: Equipment[];
}

export interface DocumentSetFilters {
    id?: number | null;
    name: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultDocumentSetDataForm: DocumentSetDataForm = {
    name: '',
    is_active: true,
    documents: [],
    job_titles: [],
    equipments: [],
    // is_truck_document_set: false,
    // is_hopper_document_set: false,
    is_society_document_set: false
};

export interface Equipment {
    id?: number;
    name: string;
}
