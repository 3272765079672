import React, { useEffect } from 'react';
import { Afp } from '../../../../app/models/Afp';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { Bank } from '../../../../app/models/Bank';
import { ExpenseType } from '../../../../app/models/ExpenseType';
import { Account } from '../../../../app/models/Account';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';

type Props = {
    expenseTypeDataForm: ExpenseType;
    setExpenseTypeDataForm: (expenseTypeDataForm: ExpenseType) => void;
    accounts: Account[];
    errorFields?: any;
    is_system?: boolean;
};

const ExpenseTypeForm = ({
    expenseTypeDataForm,
    setExpenseTypeDataForm,
    accounts,
    errorFields,
    is_system = false
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setExpenseTypeDataForm({ ...expenseTypeDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        setExpenseTypeDataForm({ ...expenseTypeDataForm, [name]: value });
    };

    return (
        <>
            {!is_system && (
                <div className="mb-2 row needs-validation">
                    <label className="col-md-3 col-form-label">Nombre</label>
                    <div className="col-md-9">
                        <input
                            name="name"
                            id="name"
                            type="text"
                            className={`form-control ${fieldHasError('name')}`}
                            value={expenseTypeDataForm.name}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('name')}
                            placeholder="Ingrese el nombre tipo de gasto"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('name')}
                        </div>
                    </div>
                </div>
            )}

            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Cuenta contable</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="account_id"
                        id="account_id"
                        inputValue={expenseTypeDataForm.account_id}
                        options={SelectTwoMapperOptions(accounts, 'id', ['name'])}
                        hasError={fieldHasError('society_id') !== ''}
                        // className={`form-control ${fieldHasError('account')}`}
                        // value={expenseTypeDataForm.account}
                        onChange={(value: any) => handleChangeSelectTwo('account_id', value.value)}
                        placeholder={'Seleccione una cuenta contable'}
                        onFocus={() => onFocusRemove('account')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('account')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExpenseTypeForm;
