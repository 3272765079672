import { ContractDataForm } from '../../../app/models/Contract';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import ContractForm from './forms/ContractForm';
import { Status } from '../../../app/models/Status';
import { Worker } from '../../../app/models/Workers';
import { JobTitle } from '../../../app/models/JobTitle';
import { Shift } from '../../../app/models/Shift';
import { Afp } from '../../../app/models/Afp';
import { Provision } from '../../../app/models/Provision';
import { Currency } from '../../../app/models/Currency';
import { BankAccountReason, BankAccountType } from '../../../app/models/BankAccountType';
import { Bank } from '../../../app/models/Bank';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    contract: ContractDataForm;
    setContract: (contract: ContractDataForm) => void;
    errorFields?: any;
    status: Status[];
    workers: Worker[];
    currencies: Currency[];
    shifts: Shift[];
    jobTitles: JobTitle[];
}

const ContractFormContainer = ({
    fetching,
    action,
    cancel,
    contract,
    setContract,
    status,
    workers,
    currencies,
    errorFields = null,
    shifts,
    jobTitles,
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ContractForm
                        contract={contract}
                        setContract={setContract}
                        status={status}
                        workers={workers}
                        errorFields={errorFields}
                        currencies={currencies}
                        shifts={shifts}
                        jobTitles={jobTitles}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default ContractFormContainer;
