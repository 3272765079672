import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { Shift, ShiftDataForm, ShiftFilters } from '../../models/Shift';
import { EndPoints } from '../EndPoints';

const useShiftService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetShifts, setFetchingGetShifts] = useState(false);
    const [fetchingDeleteShift, setFetchingDeleteShift] = useState(false);
    const [fetchingActiveShift, setFetchingActiveShift] = useState(false);
    const [fetchingStoreShift, setFetchingStoreShift] = useState(false);
    const [fetchingCreateShift, setFetchingCreateShift] = useState(false);
    const [fetchingEditShift, setFetchingEditShift] = useState(false);
    const [fetchingUpdateShift, setFetchingUpdateShift] = useState(false);

    const getShifts = (ShiftFilters: ShiftFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(ShiftFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SHIFTS.GET_SHIFTS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetShifts
        });
    };

    const getShiftsByDate = (events: ServiceEvents = {}) => {
        const url = `${EndPoints.SHIFTS.GET_SHIFT_BY_DATE}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetShifts
        });
    };

    const storeShift = (Shift: Shift, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            url: EndPoints.SHIFTS.STORE_SHIFTS,
            requestType: 'json',
            body: Shift,
            setFetching: setFetchingStoreShift
        });
    };

    const createShift = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SHIFTS.CREATE_SHIFT,
            setFetching: setFetchingCreateShift
        });
    };

    const deleteShift = (shiftId: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SHIFTS.DELETE_SHIFT.replace(':id', shiftId.toString()),
            setFetching: setFetchingDeleteShift
        });
    };

    const activeShift = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SHIFTS.ACTIVE_SHIFT.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveShift
        });
    };

    const editShift = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SHIFTS.EDIT_SHIFT.replace(':id', id.toString()),
            setFetching: setFetchingEditShift
        });
    };

    const updateShift = (id: number, Shift: Shift, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            url: EndPoints.SHIFTS.UPDATE_SHIFT.replace(':id', id.toString()),
            requestType: 'json',
            body: Shift,
            setFetching: setFetchingUpdateShift
        });
    };

    return {
        fetchingGetShifts,
        fetchingActiveShift,
        fetchingStoreShift,
        fetchingEditShift,
        fetchingUpdateShift,
        fetchingDeleteShift,
        storeShift,
        createShift,
        activeShift,
        getShifts,
        getShiftsByDate,
        deleteShift,
        editShift,
        updateShift
    };
};

export default useShiftService;
