import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ExpenseType, ExpenseTypeFilters } from '../../../app/models/ExpenseType';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import DefaultModal from '../../../components/default/DefaultModal';
import ExpenseTypeDataTable from './tables/ExpenseTypeDataTable';
import ExpenseTypeCreate from './ExpenseTypeCreate';
import ExpenseTypeEdit from './ExpenseTypeEdit';
import useExpenseTypeService from '../../../app/services/hooks/useExpenseTypeService';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { Account } from '../../../app/models/Account';

interface Props {
    is_system: boolean;
}

const ExpenseTypesContainer = ({ is_system }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetExpenseTypes, getExpenseTypes, deleteExpenseType, activeExpenseType } =
        useExpenseTypeService();
    const [expenseTypes, setExpenseTypes] = useState<ExpenseType[]>([]);

    const [expenseTypeIdEdit, setExpenseTypeIdEdit] = useState<number>(-1);
    const [accounts, setAccounts] = useState<Account>();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);
    const { navigationPage } = useNavigationPage();

    const [expenseTypeFilter, setExpenseTypeFilter] = useState<ExpenseTypeFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [expenseTypeFilter]);

    const getAllExpenseTypes = () => {
        getExpenseTypes(is_system, expenseTypeFilter, {
            onSuccess: (response: ServiceResponse) => {
                setExpenseTypes(response.data.expense_types);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (expenseTypeId: number) => {
        setShowingEdit(true);
        setExpenseTypeIdEdit(expenseTypeId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setExpenseTypeIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllExpenseTypes();
    };

    const destroy = (expenseTypeId: number) => {
        const _text = 'Está a punto de eliminar el tipo de gasto #' + expenseTypeId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando tipo de gasto...');
                        deleteExpenseType(expenseTypeId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El tipo de gasto no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            {!is_system && (
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nuevo tipo de gasto" />}
                />
            )}

            <ExpenseTypeDataTable
                expenseTypes={expenseTypes}
                edit={showEdit}
                destroy={!is_system ? destroy : undefined}
                loading={fetchingGetExpenseTypes}
                filter={expenseTypeFilter}
                setFilter={setExpenseTypeFilter}
                totalRows={totalRows}
                callbackSuccess={reloadTable}
                is_system={is_system}
            />

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear tipo de gasto"
                    backdrop={true}
                    showFooter={false}
                >
                    <ExpenseTypeCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar tipo de gasto"
                    backdrop={true}
                    showFooter={false}
                >
                    <ExpenseTypeEdit
                        is_system={is_system}
                        expenseTypeId={expenseTypeIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default ExpenseTypesContainer;
