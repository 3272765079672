import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useDispatchProgramItemService from '../../../app/services/hooks/useDispatchProgramItemService';
import { toast } from 'react-toastify';
import { MassiveStatusForm } from '../../../app/models/DispatchProgramItem';
import TransportOrderReadyToDispatchProgramDataTable from './tables/TransportOrderReadyToDispatchProgramDataTable';
import useTransportOrderService from '../../../app/services/hooks/useTransportOrderService';
import { TransportOrderDataForm, TransportOrderFilters } from '../../../app/models/TransportOrder';

interface Props {
    dispatchProgramId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const MassiveUpdateDispatchProgramItems = ({
    dispatchProgramId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation, showProgressiveLoading } =
        useContext(AppContext);
    const history = useHistory();

    const {
        getTransportOrdersReadyToDispatchProgram,
        fetchingGetTransportOrdersReadyToDispatchProgram
    } = useTransportOrderService();

    const { massiveUpdateDispatchProgramItems, fetchingMassiveUpdateDispatchProgramItems } =
        useDispatchProgramItemService();

    const [transportOrders, setTransportOrders] = useState<TransportOrderDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [bulkTransportOrderForm, setbulkTransportOrderForm] = useState<MassiveStatusForm>({
        dispatch_program_id: dispatchProgramId,
        all_selected: false,
        selected_count: 0,
        rows_ids: []
    });

    const [filter, setFilter] = useState<TransportOrderFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const [folios, setFolios] = useState<any[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        getAllTransportOrdersToDispatchProgram();
    }, [filter]);

    const getAllTransportOrdersToDispatchProgram = () => {
        getTransportOrdersReadyToDispatchProgram(filter, dispatchProgramId, {
            onSuccess: (response: ServiceResponse) => {
                setTransportOrders(response.data.transport_orders);
                setTotalRows(response.data.total_rows);
                setFolios(response.data.folios);
            },
            onError: (response: ServiceResponse) => {
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                toast.error(response.message);
            }
        });
    };

    const loadTransportOrderData = (massiveChange: boolean) => {
        const _text = `Está a punto de ingresar 
        ${
            massiveChange
                ? 'todas las ordenes de transporte '
                : 'las ordenes de transporte seleccionadas '
        } a los registros de posiciones de despacho con estado de "Planificación"`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, llenar registros',
                    onClick: () => {
                        if (showLoading)
                            showLoading('loading', 'Llenando información en posiciones...');
                        massiveUpdateDispatchProgramItems(
                            {
                                ...bulkTransportOrderForm,
                                all_selected: massiveChange,
                                rows_ids: massiveChange ? [] : bulkTransportOrderForm.rows_ids
                            },
                            {
                                onSuccess: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();
                                    useReactConfirmAlert().successAlert({
                                        title: 'Éxito',
                                        message: response.message,
                                        message_list: response.data?.message_list ?? []
                                    });
                                    toast.success(response.message, {
                                        autoClose: 2500
                                    });

                                    if (onSaved) onSaved();
                                },
                                onError: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();
                                    useReactConfirmAlert().errorAlert({
                                        title: 'Error',
                                        message: response.message
                                    });
                                    toast.error(response.message);
                                },
                                onFieldError: (errorFields: ServiceResponse) => {
                                    if (hideLoading) hideLoading();
                                    setErrorFields(errorFields.data);
                                }
                            }
                        );
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message:
                                    'No se ha llenado la información de las posiciones de despacho.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <TransportOrderReadyToDispatchProgramDataTable
                transportOrders={transportOrders}
                bulkTransportOrderForm={bulkTransportOrderForm}
                setbulkTransportOrderForm={setbulkTransportOrderForm}
                filter={filter}
                setFilter={setFilter}
                loading={fetchingGetTransportOrdersReadyToDispatchProgram}
                totalRows={totalRows}
                loadTransportOrderData={loadTransportOrderData}
                folios={folios}
            />
        </>
    );
};

export default MassiveUpdateDispatchProgramItems;
