import { Society } from './Society';

export interface Provider {
    id?: number;
    rut: string | null;
    business_name: string;
    fantasy_name: string;
    country: string;
    address: string;

    rut_contact: string;
    first_name_contact: string;
    last_name_contact: string;
    second_last_name_contact: string;
    email_contact: string;
    phone_code_contact: string;
    phone_contact: string;
    is_active?: boolean;
    provider_type_id: number;
    society?: Society;
    society_id?: number;
    activity_id?: number;
    user_id?: number;
    is_national?: boolean;
}

export interface ProviderFilters {
    rut?: string | '';
    business_name?: string | '';
    fantasy_name?: string | '';
    is_active?: boolean;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultProvider: Provider = {
    rut: '',
    business_name: '',
    fantasy_name: '',
    country: '',
    address: '',

    rut_contact: '',
    first_name_contact: '',
    last_name_contact: '',
    second_last_name_contact: '',
    email_contact: '',
    phone_code_contact: '',
    phone_contact: '',

    provider_type_id: 0,
    activity_id: 0,
    society_id: undefined,
    user_id: undefined
};
