import { useContext, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

import { ServiceEvents } from '../shared/interfaces';
import { ExpenseDataForm, ExpenseFilters } from '../../models/Expense';
import { AppContext } from '../../../contexts/AppContext';
import { fetchDefaultHeaders } from '../shared/vars';
import { ExpenseHistoryDataForm, ExpenseHistoryFilter } from '../../models/ExpenseHistory';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ExpenseHistoryDataTable from '../../../pages/WorkOrders/components/tables/LoadTicketHistoryDataTable';

const useExpenseHistoryService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [fetchingGetExpenseHistoriesByExpense, setFetchingGetExpenseHistoriesByExpense] =
        useState(false);
    const [fetchingHistoryExpenseUpdate, setFetchingHistoryExpenseUpdate] = useState(false);
    const [fetchingHistoryExpenseEdit, setFetchingEditExpenseHistory] = useState(false);

    const getAllExpensehistoriesByExpense = (
        filter: ExpenseHistoryFilter,
        expense_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.EXPENSE_HISTORIES.GET_EXPENSE_HISTORIES_BY_EXPENSE.replace(
            ':expense_id',
            expense_id.toString()
        )}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetExpenseHistoriesByExpense
        });
    };

    const editExpenseHistory = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EXPENSE_HISTORIES.EDIT_EXPENSE_HISTORY.replace(':id', id.toString()),
            setFetching: setFetchingEditExpenseHistory
        });
    };

    const updateExpenseHistory = (
        id: number,
        expenseHistoryDataForm: ExpenseHistoryDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.EXPENSE_HISTORIES.UPDATE_EXPENSE_HISTORY.replace(':id', id.toString()),
            body: expenseHistoryDataForm,
            setFetching: setFetchingHistoryExpenseUpdate
        });
    };

    return {
        fetchingGetExpenseHistoriesByExpense,
        fetchingHistoryExpenseEdit,
        fetchingHistoryExpenseUpdate,
        getAllExpensehistoriesByExpense,
        editExpenseHistory,
        updateExpenseHistory
    };
};

export default useExpenseHistoryService;
