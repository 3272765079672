import React, { useContext, useEffect, useRef, useState } from 'react';
import useTransportationOrderItemService from '../../../app/services/hooks/useTransportationOrderItemService';
import {
    defaultTransportationOrderCreateItemDataForm,
    defaultTransportationOrderItemDataForm,
    TransportationOrderItemDataForm
} from '../../../app/models/TransportationOrderItem';
import { Product } from '../../../app/models/Product';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import { Place } from '../../../app/models/Place';
import { Service } from '../../../app/models/Service';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import TransportationOrderItemForm from './forms/TransportationOrderItemForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import LazyLoading from '../../../components/LazyLoading';
import { Routes } from '../../../app/models/RouteSegment';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    canBeEdited: boolean;
    transportationOrderId: number;
    transportationOrderItemId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message?: string) => void;
}

const TransportationOrderItemEdit = ({
    canBeEdited,
    transportationOrderId,
    transportationOrderItemId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        editTransportationOrderItem,
        updateTransportationOrderItem,
        fetchingEditTransportationOrderItem,
        fetchingUpdateTransportationOrderItem
    } = useTransportationOrderItemService();

    const {
        fetchingGetQuoteRouteSegment,
        fetchingShowRsToTransportationOrderItem,
        getQuoteRouteSegment,
        showRsToTransportationOrderItem
    } = useQuoteRouteSegmentService();

    const [errorFields, setErrorFields] = useState<any>();

    // const [transportationOrderItemForm, setTransportationOrderItemForm] =
    //     useState<TransportationOrderItemDataForm>({
    //         ...defaultTransportationOrderItemDataForm,
    //         transportation_order_id: transportationOrderId
    //     });

    const [transportationOrderItemForm, setTransportationOrderItemForm] =
        useState<TransportationOrderItemDataForm>({
            ...defaultTransportationOrderCreateItemDataForm,
            ['transportation_order_id']: transportationOrderId,
            ['service_id']: 0,
            ['product_id']: 0,
            ['quantity']: 0,
            ['route_id']: undefined,
            ['dispatch_date']: '',
            ['description']: '',
            ['agreed_distance']: 0
        });

    const [products, setProducts] = useState<Product[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [routes, setRoutes] = useState<Routes[]>([]);
    const [hasQuote, setHasQuote] = useState<boolean>(false);
    const routeIdSelected = useRef<number | undefined>(0);

    useEffect(() => {
        if (transportationOrderItemId) {
            edit();
        }
    }, [transportationOrderItemId]);

    // useEffect(() => {
    //     if (services.length) {
    //         setTransportationOrderItemForm({
    //             ...transportationOrderItemForm,
    //             service_id: services[0].id
    //         });
    //     }
    // }, [services]);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        editTransportationOrderItem(transportationOrderItemId, {
            onSuccess: (response: ServiceResponse) => {
                setProducts(response.data.products);
                setQuantityUnits(response.data.quantity_units);
                setCurrencies(response.data.currencies);
                setServices(response.data.services);

                if (response.data.routes_data.has_quote) {
                    setRoutes(response.data.routes_data.routes);

                    setHasQuote(true);
                } else {
                    setRoutes(response.data.routes_data.routes);
                }

                if (response.data.transportation_order_item.status_selected?.id === 'FINALIZADO') {
                    setDisabled(true);
                }

                routeIdSelected.current = response.data.transportation_order_item.route_id;
                setTransportationOrderItemForm({
                    ...transportationOrderItemForm,
                    ...response.data.transportation_order_item
                });

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError(response.message);
            }
        });
    };

    useEffect(() => {
        if (transportationOrderItemForm.route_id == undefined) {
            routeIdSelected.current = undefined;
        }
        if (
            transportationOrderItemForm.route_id &&
            transportationOrderItemForm.route_id != routeIdSelected.current
        ) {
            if (hasQuote) {
                getQuoteRouteSegment(transportationOrderItemForm.route_id, {
                    onSuccess: (response: ServiceResponse) => {
                        setErrorFields(undefined);
                        routeIdSelected.current = transportationOrderItemForm.route_id ?? 0;
                        setTransportationOrderItemForm({
                            ...transportationOrderItemForm,
                            ...defaultTransportationOrderCreateItemDataForm,
                            ...response.data.quote_route_segment
                        });
                    },
                    onError: (response: ServiceResponse) => {
                        setTransportationOrderItemForm({
                            ...transportationOrderItemForm,
                            route_id: routeIdSelected.current
                        });
                    }
                });
            } else {
                showRsToTransportationOrderItem(
                    transportationOrderItemId,
                    transportationOrderItemForm.route_id,
                    {
                        onSuccess: (response: ServiceResponse) => {
                            setErrorFields(undefined);
                            routeIdSelected.current = transportationOrderItemForm.route_id ?? 0;
                            if (response.data.instance_data) {
                                setTransportationOrderItemForm({
                                    ...transportationOrderItemForm,
                                    ...defaultTransportationOrderCreateItemDataForm,
                                    rate: response.data.instance_data.rate,
                                    currency_id: response.data.instance_data.currency_id,
                                    quantity_unit_id: response.data.instance_data.quantity_unit_id,
                                    rate_runner: response.data.instance_data.rate_runner,
                                    currency_rate_runner_id:
                                        response.data.instance_data.currency_rate_runner_id,
                                    quantity_unit_rate_runner_id:
                                        response.data.instance_data.quantity_unit_rate_runner_id,
                                    external_rate: response.data.instance_data.external_rate,
                                    currency_external_rate_id:
                                        response.data.instance_data.currency_external_rate_id,
                                    unit_external_rate_id:
                                        response.data.instance_data.unit_external_rate_id
                                });
                            } else {
                                setTransportationOrderItemForm({
                                    ...transportationOrderItemForm,
                                    ...defaultTransportationOrderCreateItemDataForm,
                                    ...response.data.route_segment,
                                    quantity_unit_id: response.data.route_segment.unit_id
                                });
                            }
                        },
                        onError: (response: ServiceResponse) => {
                            setTransportationOrderItemForm({
                                ...transportationOrderItemForm,
                                route_id: routeIdSelected.current
                            });
                        }
                    }
                );
            }
        }
    }, [transportationOrderItemForm.route_id]);

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando detalle pedido...');
        updateTransportationOrderItem(transportationOrderItemId, transportationOrderItemForm, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });

                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {!fetchingEditTransportationOrderItem ? (
                        <TransportationOrderItemForm
                            products={products}
                            quantityUnits={quantityUnits}
                            currencies={currencies}
                            services={services}
                            transportationOrderItemForm={transportationOrderItemForm}
                            routes={routes}
                            hasQuote={hasQuote}
                            setTransportationOrderItemForm={setTransportationOrderItemForm}
                            errorFields={errorFields}
                            canBeEdited={canBeEdited}
                            disabledEdit={true}
                            disabled={disabled}
                            fetching={
                                fetchingGetQuoteRouteSegment ||
                                fetchingShowRsToTransportationOrderItem
                            }
                        />
                    ) : (
                        <LazyLoading height="300" />
                    )}
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateTransportationOrderItem}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={update}
                        locked={fetchingUpdateTransportationOrderItem}
                    />
                </div>
            </div>
        </>
    );
};

export default TransportationOrderItemEdit;
