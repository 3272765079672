import React, { useState } from 'react';
import { HolidayFilters } from '../../../../app/models/Holiday';
import YearPicker from '../../../../utils/YearPicker';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: HolidayFilters;
    setFilter: (filter: HolidayFilters) => void;
    onSearch: () => void;
};

const HolidayFilter = ({ filter, setFilter, onSearch }: Props) => {
    const [selectedYear, setSelectedYear] = useState<Date | null>(
        new Date(new Date().getFullYear(), 0)
    );

    const handleYearChange = (year: Date | null) => {
        setSelectedYear(year);
        if (year) {
            setFilter({
                ...filter,
                year: year.getFullYear().toString()
            });
        }
    };

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="row">
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="issue_date_first">
                                Nombre del feriado
                            </label>
                            <input
                                title="Nombre del feriado"
                                type="text"
                                id="name"
                                name="name"
                                className="form-control form-control-sm"
                                value={filter.name}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="issue_date_last">
                                Selecciona el año
                            </label>
                            <YearPicker selectedYear={selectedYear} onChange={handleYearChange} />
                        </div>
                    </div>
                    <div className='col-md-auto'>
                        <div style={{marginTop: '22px'}}>
                            <ButtonSearchFilter callbackSearch={onSearch}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HolidayFilter;
