export const customStyles = {
    table: {
        style: {
            border: '1px solid #ddd'
        }
    },
    header: {
        style: {
            minHeight: '56px'
        }
    },
    head: {
        style: {}
    },
    headRow: {
        style: {
            backgroundColor: '#eff2f7'
        }
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: '#D4D3D3'
            }
        }
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: '#D4D3D3'
            }
        }
    },
    progress: {
        style: {
            border: 'transparent'
        }
    }
};

export const customInterspersedStyles = {
    table: {
        style: {
            border: '1px solid #ddd'
        }
    },
    header: {
        style: {
            minHeight: '56px'
        }
    },
    head: {
        style: {}
    },
    headRow: {
        style: {
            backgroundColor: '#00323E'
        }
    },
    rows: {
        style: {
            backgroundColor: '#FFFFFF',
            '&:nth-child(2n)': {
                backgroundColor: '#EFF2F7'
            }
        }
    },
    headCells: {
        style: {
            color: '#FFFFFF',

            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: '#D4D3D3'
            }
        }
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: '#D4D3D3'
            }
        }
    },
    progress: {
        style: {
            border: 'transparent'
        }
    }
};
