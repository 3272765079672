import { useState, useEffect, useContext } from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import WorkerEventForm from './forms/WorkerEventForm';
import { Worker } from '../../../app/models/Workers';
import { CalendarEventDataForm } from '../../../app/models/Event';
import useEventService from '../../../app/services/hooks/useEventService';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { Shift, ShiftDays, defaultShiftDataForm } from '../../../app/models/Shift';
import LazyLoading from '../../../components/LazyLoading';

interface Props {
    eventId?: number;
    selectedEventDate?: string | Date;
    selectedEventDateEnd?: string | Date;
    fetching?: boolean;
    action?: () => void;
    onCancel?: () => void;
    onSaved?: () => void;
    onError?: () => void;
    setCalendarEvent: (calendarEventDataForm: CalendarEventDataForm) => void;
    worker: Worker;
    calendarEvent: CalendarEventDataForm;
    editable?: boolean;
    selectedDate?: Date | string;
}

const WorkersEventCreate = ({
    selectedEventDate,
    selectedEventDateEnd,
    eventId,
    fetching,
    action,
    onCancel,
    onError,
    worker,
    calendarEvent,
    setCalendarEvent,
    onSaved,
    selectedDate,
    editable
}: Props) => {
    const [eventTypes, setEventTypes] = useState<any>();
    const [travelExpense, setTravelExpense] = useState<any>();
    const [shiftWorks, setShiftWorks] = useState<Shift[]>([defaultShiftDataForm]);
    const [shiftWorkDays, setShiftWorkDays] = useState<ShiftDays[]>([]);
    const [existingShiftWorkId, setExistingShiftWorkId] = useState<any>();
    const [errorFields, setErrorFields] = useState<any>();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        createEvent,
        fetchingCreateEvent,
        storeEvent,
        fetchingStoreEvent,
        createShiftWorkEvent,
        updateNextShiftWorkEvent
    } = useEventService();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createEvent({
            onSuccess: (response: ServiceResponse) => {
                setEventTypes(response.data.event_types);
                setShiftWorks(response.data.shift_works);
                setShiftWorkDays(response.data.shift_work_days);
                setTravelExpense(response.data.travel_expenses);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const cancel = () => {
        if (onCancel) onCancel();
    };

    const saveForm = () => {
        if (calendarEvent.is_shift_work) {
            createShiftWorkEvent(calendarEvent, {
                onSuccess: (response: ServiceResponse) => {
                    if (response.data.message !== '') {
                        confirm(response.data.message);
                        setExistingShiftWorkId(response.data.shift_work_event.id);
                    } else {
                        store();
                    }
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message, {
                        autoClose: 2000
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        } else {
            store();
        }
    };

    const confirm = (text: string) => {
        const _text = text;

        useReactConfirmAlert().requestConfirmation({
            title: 'Ya hay jornadas guardadas',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Modificar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Modificando turno...');
                        store();
                    }
                },
                cancelButton: {
                    label: 'Cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El turno no se ha creado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const store = () => {
        storeEvent(calendarEvent, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    return fetchingCreateEvent ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <WorkerEventForm
                        calendarEventDataForm={calendarEvent}
                        setCalendarEventDataForm={setCalendarEvent}
                        eventTypes={eventTypes}
                        shiftWorks={shiftWorks}
                        shiftWorkDays={shiftWorkDays}
                        travelExpenses={travelExpense}
                        errorFields={errorFields}
                        editable={editable}
                        selectedEventDate={selectedEventDate}
                        selectedEventDateEnd={selectedEventDateEnd}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={saveForm} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default WorkersEventCreate;
