import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import {
    JobTitleLiquidationSet,
    JobTitleLiquidationSetDataForm,
    JobTitleLiquidationSetFilters
} from '../../models/JobTitleLiquidationSet';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useJobTitleLiquidationSetService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetJobTitleLiquidationSets, setFetchingGetJobTitleLiquidationSet] =
        useState(false);
    const [fetchingDeleteJobTitleLiquidationSet, setFetchingDeleteJobTitleLiquidationSet] =
        useState(false);
    const [fetchingActiveJobTitleLiquidationSet, setFetchingActiveJobTitleLiquidationSet] =
        useState(false);
    const [fetchingStoreJobTitleLiquidationSet, setFetchingStoreJobTitleLiquidationSet] =
        useState(false);
    const [fetchingCreateJobTitleLiquidationSet, setFetchingCreateJobTitleLiquidationSet] =
        useState(false);
    const [fetchingEditJobTitleLiquidationSet, setFetchingEditJobTitleLiquidationSet] =
        useState(false);
    const [fetchingUpdateJobTitleLiquidationSet, setFetchingUpdateJobTitleLiquidationSet] =
        useState(false);

    const getJobTitleLiquidationSets = (
        JobTitleLiquidationSetFilters: JobTitleLiquidationSetFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(JobTitleLiquidationSetFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.JOB_TITLE_LIQUIDATION_SETS.GET_JOB_TITLE_LIQUIDATION_SETS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetJobTitleLiquidationSet
        });
    };

    const storeJobTitleLiquidationSet = (
        JobTitleLiquidationSet: JobTitleLiquidationSetDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.JOB_TITLE_LIQUIDATION_SETS.STORE_JOB_TITLE_LIQUIDATION_SETS,
            body: JobTitleLiquidationSet,
            setFetching: setFetchingStoreJobTitleLiquidationSet
        });
    };

    const createJobTitleLiquidationSet = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.JOB_TITLE_LIQUIDATION_SETS.CREATE_JOB_TITLE_LIQUIDATION_SETS,
            setFetching: setFetchingCreateJobTitleLiquidationSet
        });
    };

    const deleteJobTitleLiquidationSet = (jobTitleId: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.JOB_TITLE_LIQUIDATION_SETS.DELETE_JOB_TITLE_LIQUIDATION_SETS.replace(
                ':id',
                jobTitleId.toString()
            ),
            setFetching: setFetchingDeleteJobTitleLiquidationSet
        });
    };

    const activeJobTitleLiquidationSet = (
        id: number,
        active: boolean,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.JOB_TITLE_LIQUIDATION_SETS.ACTIVE_JOB_TITLE_LIQUIDATION_SETS.replace(
                ':id',
                id.toString()
            ),
            body: { active: active },
            setFetching: setFetchingActiveJobTitleLiquidationSet
        });
    };

    const editJobTitleLiquidationSet = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.JOB_TITLE_LIQUIDATION_SETS.EDIT_JOB_TITLE_LIQUIDATION_SETS.replace(
                ':id',
                id.toString()
            ),
            setFetching: setFetchingEditJobTitleLiquidationSet
        });
    };

    const updateJobTitleLiquidationSet = (
        id: number,
        JobTitleLiquidationSet: JobTitleLiquidationSetDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.JOB_TITLE_LIQUIDATION_SETS.UPDATE_JOB_TITLE_LIQUIDATION_SETS.replace(
                ':id',
                id.toString()
            ),
            body: JobTitleLiquidationSet,
            setFetching: setFetchingUpdateJobTitleLiquidationSet
        });
    };

    return {
        fetchingGetJobTitleLiquidationSets,
        fetchingActiveJobTitleLiquidationSet,
        fetchingDeleteJobTitleLiquidationSet,
        fetchingCreateJobTitleLiquidationSet,
        fetchingStoreJobTitleLiquidationSet,
        fetchingEditJobTitleLiquidationSet,
        fetchingUpdateJobTitleLiquidationSet,
        storeJobTitleLiquidationSet,
        createJobTitleLiquidationSet,
        activeJobTitleLiquidationSet,
        getJobTitleLiquidationSets,
        deleteJobTitleLiquidationSet,
        editJobTitleLiquidationSet,
        updateJobTitleLiquidationSet
    };
};

export default useJobTitleLiquidationSetService;
