import React from 'react';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { Holiday, HolidayFilters } from '../../../../app/models/Holiday';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import { momentParseDateWithDayOfWeek } from '../../../../helpers';
import HolidayFilter from './HolidayFilter';
import HolidaySwitchChange from '../HolidaySwitchChange';

interface Props {
    holidays: Holiday[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: HolidayFilters;
    setFilter: (filter: HolidayFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const HolidayDataTable = ({
    holidays,
    totalRows,
    loading = false,
    edit,
    destroy,
    active,
    show,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'Fecha',
            selector: (row: any) => row.this_year_date,
            sortable: true,
            sortField: 'this_year_date',
            cell: (row: any, index: any, column: any) => (
                <span className="">{momentParseDateWithDayOfWeek(row.this_year_date)}</span>
            )
        },
        {
            name: 'Nombre',
            selector: (row: any) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: any, index: any, column: any) => <span className="">{row.name}</span>
        },
        {
            name: 'Irrenunciable',
            selector: (row: any) => row.resignable,
            sortable: true,
            sortField: 'resignable',
            cell: (row: any, index: any, column: any) => (
                <div className="">{row.resignable ? <span>No</span> : <span>Sí</span>}</div>
            )
        },
        {
            name: 'Se repite',
            selector: (row: any) => row.is_repeat,
            sortable: true,
            sortField: 'is_repeat',
            cell: (row: any, index: any, column: any) => (
                <div className="">{row.is_repeat ? <span>Sí</span> : <span>No</span>}</div>
            )
        },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: false,
            sortField: 'is_active',
            cell: (row: any, index: any, column: any) => (
                <HolidaySwitchChange
                    checkValue={row.is_active ?? true}
                    productId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <HolidayFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={holidays}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default HolidayDataTable;
