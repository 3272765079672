import React, { useContext, useEffect, useState } from 'react';
import { defaultDispatchProgram, DispatchProgram } from '../../../app/models/DispatchProgram';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import DispatchProgramForm from './forms/DispatchProgramForm';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import useDispatchProgramService from '../../../app/services/hooks/useDispatchProgramService';
import { TransportationOrderDataForm } from '../../../app/models/TransportationOrder';
import { RunnerDataForm } from '../../../app/models/Runner';
import { useHistory } from 'react-router-dom';
import useSweetAlert from '../../../hooks/useSweetAlert';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { AppContext } from '../../../contexts/AppContext';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DispatchProgramCreate = ({ onSaved, onCancel, onError }: Props) => {
    const [societies, setSocieties] = useState<Society[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [transportationOrders, setTransportationOrders] = useState<TransportationOrderDataForm[]>(
        []
    );
    const [runners, setRunners] = useState<RunnerDataForm[]>([]);
    const { hideLoading } = useContext(AppContext);
    const [errorGetData, setErrorGetData] = useState('');

    const { navigationPage } = useNavigationPage();

    const {
        fetchingStoreDispatchProgram,
        storeDispatchProgram,
        fetchingCreateDispatchProgram,
        createDispatchProgram,
        fetchingShowTransportationOrderDispatchProgram,
        showTransportationOrderDispatchProgram
    } = useDispatchProgramService();

    const [dispatchProgram, setDispatchProgram] = useState<DispatchProgram>(defaultDispatchProgram);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        createDispatchProgram({
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setClients(response.data.clients);
                setTransportationOrders(response.data.transportation_orders);
                setRunners(response.data.runners);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                setErrorGetData(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {
        if (dispatchProgram.transportation_order_item_id) {
            showTransportationOrderDispatchProgram(
                dispatchProgram.transportation_order_item_id as number,
                {
                    onSuccess: (response: ServiceResponse) => {
                        setErrorFields(undefined);

                        setDispatchProgram({
                            ...dispatchProgram,
                            ...response.data.transportation_order
                        });
                    }
                }
            );
        }
    }, [dispatchProgram.transportation_order_item_id]);

    const store = () => {
        useSweetAlert().spinnerAlert('Guardando despacho...');
        storeDispatchProgram(dispatchProgram, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);
                navigationPage('/dispatch-programs/' + response.data.dispatch_program_id + '/edit');
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateDispatchProgram || fetchingShowTransportationOrderDispatchProgram ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <DispatchProgramForm
                        dispatchProgramForm={dispatchProgram}
                        setDispatchProgramForm={setDispatchProgram}
                        societies={societies}
                        clients={clients}
                        // paymentConditions={paymentConditions}
                        // paymentMethods={paymentMethods}
                        // currencies={currencies}
                        errorFields={errorFields}
                        transportationOrders={transportationOrders}
                        runners={runners}
                        // references={references}
                        // isCreated={true}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreDispatchProgram}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreDispatchProgram} />
                </div>
            </div>
        </>
    );
};

export default DispatchProgramCreate;
