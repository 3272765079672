import React, { useContext } from 'react';
import {
    TransportOrderDataForm,
    TransportOrderFilters
} from '../../../../app/models/TransportOrder';
import { momentParseDate } from '../../../../helpers';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import TransportOrderFilter from './TransportOrderFilter';
import { formatMoney } from '../../../../utils/utils';
import { useScreenDetector } from '../../../../hooks/useScreenDetector';
import circleGrey from '../../../../assets/images/icons/circle-color-grey.svg';
import circleYellow from '../../../../assets/images/icons/circle-color-yellow.svg';
import circleGreen from '../../../../assets/images/icons/circle-color-green.svg';
import { AuthContext } from '../../../../contexts/AuthContext';
import { Link } from 'react-router-dom';

interface Props {
    transportOrders: TransportOrderDataForm[];
    totalRows: number;
    statuses: any[];
    edit?: (transportOrderItemId: number) => void;
    updateExpenses?: (transportOrderItemId: number) => void;
    destroy?: (transportOrderItemId: number) => void;
    downloadExcel?: () => void;
    filter: TransportOrderFilters;
    setFilter: (filter: TransportOrderFilters) => void;
    loading: boolean;
    isShow?: boolean;
    onSearch: () => void;
}

const TransportOrdersDataTable = ({
    transportOrders,
    edit,
    destroy,
    downloadExcel,
    statuses,
    updateExpenses,
    totalRows = 0,
    filter,
    setFilter,
    loading,
    isShow = false,
    onSearch
}: Props) => {
    const { auth } = useContext(AuthContext);
    const columns = [
        {
            name: 'Orden de transporte',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Conductor',
            selector: (row: any) => row?.worker,
            sortable: true,
            sortField: 'worker',
            minWidth: '120px',
            cell: (row: any, index: any, column: any) => {
                const workerUser = row.worker?.user;
                const carrierUser = row.carrier?.user;

                const rut = workerUser?.rut || carrierUser?.rut;
                const firstName = workerUser?.first_name || carrierUser?.first_name;
                const lastName = workerUser?.last_name || carrierUser?.last_name;

                return (
                    <span className="">
                        {rut}
                        <br />
                        {firstName} {lastName}
                    </span>
                );
            }
        },

        {
            name: 'Tracto',
            selector: (row: any) => row?.truck,
            sortable: true,
            sortField: 'truck',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.truck ? row.truck : '' }}
                ></span>
            )
        },
        {
            name: 'Semi Remolque',
            selector: (row: any) => row?.hopper,
            sortable: true,
            sortField: 'hopper',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.hopper ? row.hopper : '' }}
                ></span>
            )
        },
        {
            name: 'Tramo',
            selector: (row: any) => row?.route_segment_id,
            sortable: true,
            sortField: 'route_segment_id'
            // cell: (row: TransportOrderDataForm, index: any, column: any) => (
            //     <span className="">

            //     </span>
            // )
        },

        {
            name: 'Origen',
            selector: (row: any) => row?.origin,
            sortable: true,
            sortField: 'origin',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span className="">{row.origin}</span>
            )
        },
        {
            name: 'Destino',
            selector: (row: any) => row?.destiny,
            sortable: true,
            sortField: 'destiny',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span className="">{row.destiny}</span>
            )
        },
        // commission
        {
            name: 'Comisión',
            selector: (row: any) => row?.commission,
            sortable: true,
            sortField: 'commission',
            cell: (row: any, index: any, column: any) => (
                <span className="">{formatMoney(row.commission)}</span>
            )
        },
        {
            name: 'Fecha de Programa',
            selector: (row: any) => row?.program_date,
            sortable: true,
            sortField: 'program_date',
            minWidth: '120px',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span className="">
                    {momentParseDate(row.program_date)} {row.program_time}
                </span>
            )
        },
        // {
        //     name: 'Hora de programa',
        //     selector: (row: any) => row?.program_time,
        //     sortable: true,
        //     sortField: 'program_time',
        //     cell: (row: TransportOrderDataForm, index: any, column: any) => (
        //         <span className="">{row.program_time}</span>
        //     )
        // },

        {
            name: 'Producto',
            selector: (row: any) => row?.program_time,
            sortable: true,
            sortField: 'product',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span className="">{row.product}</span>
            )
        },
        {
            name: 'Cliente',
            selector: (row: any) => row?.client,
            sortable: true,
            sortField: 'client',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span className="">{row.client}</span>
            )
        },
        {
            name: 'Gastos total',
            selector: (row: any) => row?.total_amount,
            sortable: true,
            sortField: 'total_amount',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span className="">{formatMoney(row.total_amount ?? 0)}</span>
            )
        },
        {
            name: 'Gastos peaje por rendir',
            selector: (row: any) => row?.toll_expense_reported,
            sortable: true,
            sortField: 'toll_expense_reported',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span className="">{formatMoney(row.toll_expense_reported ?? 0)}</span>
            )
        },
        {
            name: 'Gastos peaje sin rendir',
            selector: (row: any) => row?.toll_expense_not_reported,
            sortable: true,
            sortField: 'toll_expense_not_reported',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span className="">{formatMoney(row.toll_expense_not_reported ?? 0)}</span>
            )
        },
        {
            name: 'Otros gastos por rendir',
            selector: (row: any) => row?.other_expense_reported,
            sortable: true,
            sortField: 'other_expense_reported',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span className="">{formatMoney(row.other_expense_reported ?? 0)}</span>
            )
        },
        {
            name: 'Otros gastos sin rendir',
            selector: (row: any) => row?.other_expense_not_reported,
            sortable: true,
            sortField: 'other_expense_not_reported',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span className="">{formatMoney(row.other_expense_not_reported ?? 0)}</span>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            cell: (row: TransportOrderDataForm, index: any, column: any) => {
                const _status = statuses.find(
                    (subOrderStatus) => subOrderStatus.id === row.status
                )?.name;
                let _color = circleYellow;

                if (row.status === 'FINALIZADA') {
                    _color = circleGreen;
                } else if (row.status === 'PREPARANDO') {
                    _color = circleGrey;
                }

                return (
                    <span className="">
                        <img
                            src={_color}
                            alt="circle"
                            style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '5px',
                                marginBottom: '3px'
                            }}
                        />
                        {_status}
                    </span>
                );
            }
        },

        {
            name: 'Periodo orden de trabajo',
            selector: (row: any) => row?.period,
            sortable: true,
            sortField: 'period',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row.period, 'MM-YYYY')}</span>
            )
        },
        {
            name: 'En despacho',
            selector: (row: any) => row?.dispatch_program_item,
            sortable: true,
            sortField: 'dispatch_program_item',
            width: '150px',
            omit: auth?.roles?.includes('Administrador')
                ? false
                : auth?.roles?.includes('Conductor') && auth?.roles?.length === 1
                ? true
                : false,
            cell: (row: TransportOrderDataForm, index: any, column: any) =>
                row.dispatch_program_item && (
                    <Link
                        to={`/dispatch-programs/${row.dispatch_program_item.dispatch_program_id}/show`}
                    >
                        #{row.dispatch_program_item.dispatch_program_id} - posición{' '}
                        {row.dispatch_program_item.position}
                    </Link>
                )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: TransportOrderDataForm, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {updateExpenses && (
                        <ButtonTableAction
                            callbackFunction={() => updateExpenses(row.id ?? -1)}
                            classIcon={'mdi mdi-account-cash-outline'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede editar este registro'}
                            title={'Administrar guias, gastos y tickets'}
                        />
                    )}
                    {/*                    {updateExpenses && (*/}
                    {/*    <div className={'row'}>*/}
                    {/*        <div className="col">*/}
                    {/*            <ButtonTableAction*/}
                    {/*                callbackFunction={() => updateExpenses(row.id ?? -1)}*/}
                    {/*                classIcon={'mdi mdi-account-cash-outline'}*/}
                    {/*                colorIcon={'text-info'}*/}
                    {/*                errorMessage={'No se puede editar este registro'}*/}
                    {/*                title={'Administrar guias, gastos y tickets'}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        <div className="col-auto float-end">*/}
                    {/*            <ButtonTableAction*/}
                    {/*                callbackFunction={() => updateExpenses(row.id ?? -1)}*/}
                    {/*                classIcon={'mdi mdi-message-cog'}*/}
                    {/*                colorIcon={'text-warning'}*/}
                    {/*                errorMessage={'No se puede editar este registro'}*/}
                    {/*                title={'Administrar guias, gastos y tickets'}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];
    const { isMobile, isTablet, isDesktop } = useScreenDetector();

    return (
        <>
            <div className="row mt-3 me-0 ms-0">
                <div className="col">
                    <TransportOrderFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
                {isDesktop && (
                    <div className="col-auto" style={{ paddingTop: 22 }}>
                        <button className="btn btn-success btn-sm" onClick={downloadExcel}>
                            <i className="fa fa-file-excel" /> Descargar
                        </button>
                    </div>
                )}
            </div>

            <DefaultDataTable
                columns={columns}
                data={transportOrders}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
                isMobileStacked={true}
                isMobileIgnoreColumns={[
                    'Cliente',
                    'Tramo',
                    'Producto',
                    'Periodo orden de trabajo',
                    'Gastos total',
                    'Gastos peaje sin rendir',
                    'Otros gastos sin rendir',
                    'En despacho'
                ]}
            />
        </>
    );
};

export default TransportOrdersDataTable;
