export interface Unit {
    id?: number;
    name: string;
    is_active?: boolean;
}

export interface UnitFilters {
    id?: number | null;
    name?: string | '';
    is_active?: boolean | '';

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface UnitEquivalence {
    id?: number;
    from_unit?: Unit;
    from_unit_quantity: number;
    to_unit?: Unit;
    to_unit_quantity: number;
}

export const defaultUnitEquivalence: UnitEquivalence = {
    from_unit: undefined,
    from_unit_quantity: 0,
    to_unit: undefined,
    to_unit_quantity: 0
};

export interface UnitEquivalenceDataForm {
    id?: number;
    from_unit_id?: Unit;
    from_unit_quantity: number;
    to_unit_id?: Unit;
    to_unit_quantity: number;
}

export interface UnitEquivalenceFilters {
    id?: number | null;
    from_unit?: Unit;
    is_active?: boolean | '';

    page: number;
    per_page: number;
    sort: string;
    order: string;
}
