import moment from 'moment';
import { Society } from './Society';
import { Truck } from './Truck';

export interface WorkOrder {
    id?: number;
    period?: string;
    society_id?: number;
    society?: Society;
    truck_id?: number;
    truck?: Truck;
    start_date?: string;
    end_date?: string;
    plan_value?: number;
    real_value?: number;
    status?: string;

    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface WorkOrderDataForm {
    id?: number;
    period?: string;
    society_id?: number;
    society?: string;
    truck_id?: number;
    truck?: Truck;
    start_date?: string;
    total_expenses?: number;
    total_sales?: number;
    end_date?: string;
    plan_value?: number;
    real_value?: number;
    status?: string;
}

export interface WorkOrderFilters {
    work_order_id?: number | null;
    period_from?: string | '';
    period_until?: string | '';
    society_name?: string | null;
    truck_patent?: string | null;
    truck_brand_name?: string | null;
    truck_model_vehicle_name?: string | null;
    status?: string;

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultWorkOrder: WorkOrderDataForm = {
    period: moment().format('YYYY-MM'),
    society_id: 0,
    truck_id: 0
};

export interface WorkOrderStatusRequestDataForm {
    id: number | string;
    name: number | string;
    prev?: number | string;
    next?: number | string;
}
