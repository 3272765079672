import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { Bonus, BonusFilters } from '../../models/Bonus';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useBonusService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetBonuses, setFetchingGetBonus] = useState(false);
    const [fetchingDeleteBonus, setFetchingDeleteBonus] = useState(false);
    const [fetchingActiveBonus, setFetchingActiveBonus] = useState(false);
    const [fetchingStoreBonus, setFetchingStoreBonus] = useState(false);
    const [fetchingCreateBonus, setFetchingCreateBonus] = useState(false);
    const [fetchingEditBonus, setFetchingEditBonus] = useState(false);
    const [fetchingUpdateBonus, setFetchingUpdateBonus] = useState(false);

    const getBonuses = (BonusFilters: BonusFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(BonusFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.BONUSES.GET_BONUSES}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetBonus
        });
    };

    const createBonus = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BONUSES.CREATE_BONUSES,
            setFetching: setFetchingCreateBonus
        });
    };

    const storeBonus = (Bonus: Bonus, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BONUSES.STORE_BONUSES,
            body: Bonus,
            setFetching: setFetchingStoreBonus
        });
    };

    const deleteBonus = (BonusId: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.BONUSES.DELETE_BONUSES.replace(':id', BonusId.toString()),
            setFetching: setFetchingDeleteBonus
        });
    };

    const activeBonus = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.BONUSES.ACTIVE_BONUSES.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveBonus
        });
    };

    const editBonus = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.BONUSES.EDIT_BONUSES.replace(':id', id.toString()),
            setFetching: setFetchingEditBonus
        });
    };

    const updateBonus = (id: number, bonus: Bonus, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.BONUSES.UPDATE_BONUSES.replace(':id', id.toString()),
            body: bonus,
            setFetching: setFetchingUpdateBonus
        });
    };

    return {
        fetchingGetBonuses,
        fetchingActiveBonus,
        fetchingDeleteBonus,
        fetchingCreateBonus,
        fetchingStoreBonus,
        fetchingEditBonus,
        fetchingUpdateBonus,
        storeBonus,
        createBonus,
        activeBonus,
        getBonuses,
        deleteBonus,
        editBonus,
        updateBonus
    };
};

export default useBonusService;
