import React, { useContext } from 'react';
import useContractService from '../../../app/services/hooks/useContractService';
import ActiveControl from '../../../components/ActiveControl';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    ContractId: number;
    checkValue: boolean;
    callbackSuccess: () => void;
}

const ContractSwitchChange = ({ ContractId, checkValue, callbackSuccess }: Props) => {
    const [checked, setChecked] = React.useState<boolean>(checkValue);
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { activeContract, fetchingActiveContract } = useContractService();

    const active = (value: boolean) => {
        setChecked(value);

        activeContract(ContractId, value, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                callbackSuccess();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setChecked(!value);
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return <ActiveControl status={checked} id={ContractId} callback={active} />;
};

export default ContractSwitchChange;
