import React from 'react';
import { Shift, ShiftDays, ShiftFilters } from '../../../../app/models/Shift';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import ShiftSwitchChange from '../ShiftSwitchChange';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ShiftFilter from './ShiftFilter';
import { EventType } from '../../../../app/models/EventType';

interface Props {
    shifts: Shift[];
    eventTypes: EventType[];
    shiftWorkdays: ShiftDays[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: ShiftFilters;
    setFilter: (filter: ShiftFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const ShiftDataTable = ({
    shifts,
    eventTypes,
    shiftWorkdays,
    totalRows,
    loading = false,
    edit,
    destroy,
    active,
    show,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const getEventTypeName = (eventTypeId: number) => {
        const eventType = eventTypes.find((event) => event.id === eventTypeId);
        return eventType ? eventType.name : '';
    };

    const getShiftWorkdaysDescription = (shiftWorkId: number) => {
        return shiftWorkdays
            .filter(
                (workday) =>
                    workday.shift_work_id === shiftWorkId && typeof workday.event_type === 'number'
            )
            .map((workday) => {
                const eventTypeId =
                    typeof workday.event_type !== 'undefined' ? workday.event_type : -1;
                const eventTypeName = getEventTypeName(eventTypeId as number);
                return `${workday.number_of_days} día(s) - ${eventTypeName}`;
            });
    };

    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: any, index: any, column: any) => <span className="">{row.id}</span>
        },
        {
            name: 'Nombre',
            selector: (row: any) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: any, index: any, column: any) => <span className="">{row.name}</span>
        },
        {
            name: 'Descripción',
            selector: (row: any) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: any, index: any, column: any) => {
                const descriptions = getShiftWorkdaysDescription(row.id);
                return (
                    <span className="m-50">
                        {descriptions.map((description, i) => (
                            <span key={i}>
                                {description}
                                <br />
                            </span>
                        ))}
                    </span>
                );
            }
        },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: false,
            sortField: 'is_active',
            cell: (row: any, index: any, column: any) => (
                <ShiftSwitchChange
                    checkValue={row.is_active ?? true}
                    ShiftId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <div>
            <div className="row mt-3">
                <div className="col">
                    <ShiftFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={shifts}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </div>
    );
};

export default ShiftDataTable;
