import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import LazyLoading from '../../../components/LazyLoading';
import DefaultModal from '../../../components/default/DefaultModal';

import ExpenseDataTable from './tables/ExpenseDataTable';

import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useLoadTicketService from '../../../app/services/hooks/useLoadTicketService';
import { LoadTicketDataForm, LoadTicketFilters } from '../../../app/models/LoadTicket';
import LoadTicketCreate from './LoadTicketCreate';
import LoadTicketDataTable from './tables/LoadTicketDataTable';
import LoadTicketEdit from './LoadTicketEdit';
import {
    LoadTicketHistoryDataForm,
    LoadTicketHistoryFilter
} from '../../../app/models/LoadTicketsHistory';
import LoadTicketHistoryForm from './forms/LoadTicketHistoryForm';
import loadTicketForm from './forms/LoadTicketForm';
import LoadTicketHistoryDataTable from './tables/LoadTicketHistoryDataTable';
import LoadTicketHistoryEdit from './LoadTicketHistoryEdit';
import LoadTicketHistoryCreate from './LoadTicketHistoryCreate';
import LoadTicketHistoryContainer from './LoadTicketHistoryContainer';

interface Props {
    transportOrderId: number;
    refreshKey?: boolean
}

const initialFilter = {
    page: 1,
    per_page: 10,
    sort: 'id',
    order: 'desc'
};

const LoadTicketContainer = ({ transportOrderId, refreshKey }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const {
        getAllLoadTicketsByTransportOrder,
        fetchingGetLoadTicketsByTransportOrder,
        deleteLoadTicket,
        fetchingDeleteLoadTicket,
        getUrlDocumentLoadTicketOnTransportOrder,
        approvedLoadTicket,
        downloadDocument,
        cancelIsApprovedLoadTicket
    } = useLoadTicketService();

    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);

    const [loadTicketIdEdit, setLoadTicketIdEdit] = useState<number>(-1);

    const [loadTickets, setLoadTickets] = useState<LoadTicketDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<LoadTicketFilters>(initialFilter);

    const [showingCreateLoadTicketHistory, setShowingCreateLoadTicketHistory] = useState(false);
    const [loadTicketHistoryIdCreate, setLoadTicketHistoryIdCreate] = useState<number>(-1);

    const [showingGetLoadTicketHistories, setShowingGetLoadTicketHistories] = useState(false);
    const [loadTicketHistoryIdGet, setLoadTicketHistoryIdGet] = useState<number>(-1);

    const [loadTicketHistory, setLoadTicketHistory] = useState<LoadTicketHistoryDataForm>({
        comment: '',
        is_approved: true
    });

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        reloadTable();
    }, [refreshKey]);

    const reloadTable = () => {
        getAllLoadTicketsByTransportOrder(transportOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setLoadTickets(response.data.load_tickets);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const showEdit = (expenseId: number) => {
        setShowingEdit(true);
        setLoadTicketIdEdit(expenseId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setLoadTicketIdEdit(-1);
    };

    const showCreateLoadTicketHistory = (loadTicketId: number) => {
        setShowingCreateLoadTicketHistory(true);
        setLoadTicketHistoryIdCreate(loadTicketId);
    };

    const hideCreateLoadTicketHistory = () => {
        setShowingCreateLoadTicketHistory(false);
        setLoadTicketHistoryIdCreate(-1);
        useReactConfirmAlert().infoAlert({
            title: 'Cancelado',
            message: `El ticket de descarga no ha cambiado de estado.`
        });
    };

    const hideSuccessCreateLoadTicketHistory = () => {
        setShowingCreateLoadTicketHistory(false);
        setLoadTicketHistoryIdCreate(-1);
    };

    const showGetLoadTicketHistories = (loadTicketId: number) => {
        setShowingGetLoadTicketHistories(true);
        setLoadTicketHistoryIdGet(loadTicketId);
    };

    const hideGetLoadTicketHistories = () => {
        setShowingGetLoadTicketHistories(false);
        setLoadTicketHistoryIdGet(-1);
    };

    const destroy = (loadTicketId: number) => {
        const _text = 'Está a punto de eliminar el ticket #' + loadTicketId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando ticket...');
                        deleteLoadTicket(transportOrderId, loadTicketId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El ticket no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const document = (loadTicketId: number) => {
        if (showLoading) showLoading('download', 'descargando archivo...');
        getUrlDocumentLoadTicketOnTransportOrder(transportOrderId, loadTicketId, {
            onSuccess: (response: ServiceResponse) => {
                window.open(response.data.url_file);
                if (changeAnimation)
                    changeAnimation('downloadSuccess', 'archivo recuperado con exito', true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) {
                    hideLoading();
                }
                toast.error(response.message);
            }
        });
    };

    const cancel = (loadTicketId: number, approved: boolean) => {
        const _text = 'Está a punto de pasar el gasto #' + loadTicketId + ` de ${approved ? 'aprobado' : 'rechazado'} a nulo`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando gasto de viaje...');
                        cancelIsApprovedLoadTicket(loadTicketId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El gasto de viaje no ha cambiado de estado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const approved = (loadTicketId: number, value: boolean, message: string) => {
        const _text = `El ticket de descarga # ${loadTicketId} está apunto de ser ${message}`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (value) {
                            getApprovedLoadTicket(loadTicketId, loadTicketHistory);
                        } else {
                            showCreateLoadTicketHistory(loadTicketId);
                        }
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: `El ticket de descarga no se ha ${message}.`
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const getApprovedLoadTicket = (
        loadTicketId: number,
        loadTicketHistory: LoadTicketHistoryDataForm
    ) => {
        if (showLoading) showLoading('loading', `Cambiando estado de ticket de descarga...`);

        approvedLoadTicket(transportOrderId, loadTicketId, loadTicketHistory, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });

                reloadTable();
                hideSuccessCreateLoadTicketHistory();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    return (
        <>
            <DefaultCard>
                {!fetchingGetLoadTicketsByTransportOrder ? (
                    <div className="row">
                        <h5>Tickets de Descarga</h5>
                        <div className="col-12 mb-2 mt-2">
                            <ButtonCreate title="Nuevo ticket" callbackCreate={showCreate} />
                        </div>
                        <div className="col-12">
                            {loadTickets ? (
                                <LoadTicketDataTable
                                    loading={fetchingGetLoadTicketsByTransportOrder}
                                    loadTickets={loadTickets}
                                    totalRows={totalRows}
                                    edit={showEdit}
                                    // show={showGet}
                                    destroy={destroy}
                                    approved={approved}
                                    cancel={cancel}
                                    filter={filter}
                                    setFilter={setFilter}
                                    documentLoadTicket={document}
                                    showLoadTicketHistory={showGetLoadTicketHistories}
                                    onSearch={reloadTable}
                                />
                            ) : null}
                        </div>
                    </div>
                ) : (
                    <LazyLoading height="300" />
                )}
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear ticket"
                    backdrop={true}
                    showFooter={false}
                >
                    <LoadTicketCreate
                        transportOrderId={transportOrderId}
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar ticket"
                    backdrop={true}
                    showFooter={false}
                >
                    <LoadTicketEdit
                        loadTicketId={loadTicketIdEdit}
                        transportOrderId={transportOrderId}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}

            {/* {showingGet ? (
                <DefaultModal
                    show={showingGet}
                    handleClose={hideGet}
                    title="Mostrar ticket"
                    backdrop={true}
                    showFooter={false}
                >
                    <ShowExpense
                        onSaved={() => {
                            reloadTable();
                            hideGet();
                        }}
                        onCancel={hideGet}
                        transportOrderId={loadTicketIdEdit}
                    />
                </DefaultModal>
            ) : null} */}

            {showingCreateLoadTicketHistory ? (
                <DefaultModal
                    show={showingCreateLoadTicketHistory}
                    handleClose={hideCreateLoadTicketHistory}
                    title="Añadir información"
                    backdrop={true}
                    showFooter={false}
                >
                    <LoadTicketHistoryCreate
                        loadTicketId={loadTicketHistoryIdCreate}
                        getApprovedLoadTicket={getApprovedLoadTicket}
                        errorFields={errorFields}
                        onSaved={() => {
                            reloadTable();
                            hideSuccessCreateLoadTicketHistory();
                        }}
                        onCancel={hideCreateLoadTicketHistory}
                        onError={hideCreateLoadTicketHistory}
                    />
                </DefaultModal>
            ) : null}

            {showingGetLoadTicketHistories ? (
                <DefaultModal
                    show={showingGetLoadTicketHistories}
                    handleClose={hideGetLoadTicketHistories}
                    title="Historial ticket de descarga"
                    backdrop={true}
                    showFooter={false}
                    size="lg"
                >
                    <LoadTicketHistoryContainer
                        loadTicketId={loadTicketHistoryIdGet}
                        onCancel={hideGetLoadTicketHistories}
                        onError={hideGetLoadTicketHistories}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default LoadTicketContainer;
