import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useAccountService from '../../../app/services/hooks/useAccountService';
import { AccountDataForm, defaultAccountDataForm } from '../../../app/models/Account';
import { Clasification } from '../../../app/models/Clasification';
import AccountFormContainer from './AccountFormContainer';

interface Props {
    accountId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const AccountEdit = ({ accountId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditAccount, editAccount, fetchingUpdateAccount, updateAccount } =
        useAccountService();

    const [Account, setAccount] = useState<AccountDataForm>(defaultAccountDataForm);
    const [clasifs, setClasifs] = useState<Clasification[]>([]);
    const [clasifsTwo, setClasifsTwo] = useState<Clasification[]>([]);
    const [clasifsThree, setClasifsThree] = useState<Clasification[]>([]);
    const [clasifications, setClasifications] = useState<Clasification[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando cuenta contable...');
        editAccount(accountId, {
            onSuccess: (response: ServiceResponse) => {
                setAccount(response.data.account);
                setClasifs(response.data.clasifs);
                setClasifsTwo(response.data.clasifs_two);
                setClasifsThree(response.data.clasifs_three);
                setClasifications(response.data.clasifs_clasification);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando cuenta contable...');
        updateAccount(accountId, Account, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditAccount ? (
        <LazyLoading height="300" />
    ) : (
        <AccountFormContainer
            fetching={fetchingUpdateAccount}
            action={update}
            cancel={cancel}
            account={Account}
            setAccount={setAccount}
            clasifs={clasifs}
            clasifsTwo={clasifsTwo}
            clasifsThree={clasifsThree}
            clasifications={clasifications}
            errorFields={errorFields}
        />
    );
};

export default AccountEdit;
