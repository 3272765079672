import React, { useEffect, useState } from 'react';
import Select, { components, Props as SelectProps } from 'react-select';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { select2Config } from '../config';

interface SelectTwoProps {
    name?: string;
    id?: string;
    onFocus?: (value: any) => void;
    inputValue?: any;
    options: SelectTwoOption[];
    onChange?: (value: any) => void;
    placeholder?: string;
    hasError?: boolean;
    isMulti?: boolean;
    disabled?: boolean;
    size?: string;
    isSortable?: boolean;
}

interface SelectTwoOption {
    value: string | number | null;
    label: string;
}

export const SelectTwoMapperOptions = (
    options: any[],
    valueName = 'id',
    labelNames = ['name'],
    LabeLSeparation = ' | '
) => {
    if (!options) return [];

    return options.map((option: any) => {
        const labels: string[] = [];
        labelNames.map((labelName: string) => {
            labels.push(option[labelName]);
        });
        return {
            value: option[valueName],
            label: labels.join(LabeLSeparation)
        };
    });
};

const SortableMultiValueLabel = SortableHandle((props: any) => (
    <components.MultiValueLabel {...props} />
));

const SortableMultiValue = SortableElement((props: any) => (
    <components.MultiValue
        {...props}
        innerProps={{
            ...props.innerProps,
            onMouseDown: (e) => {
                e.preventDefault();
                e.stopPropagation();
            }
        }}
    />
));

const SortableSelect = SortableContainer(Select);
const selectedOption = (options: SelectTwoOption[], value: string | number | null) => {
    return options.find((option: SelectTwoOption) => option.value == value) || null;
};
const SelectTwo: React.FC<SelectTwoProps> = ({
    name,
    id,
    onFocus,
    inputValue,
    options,
    onChange,
    placeholder = 'Seleccione una opción',
    hasError = false,
    isMulti = false, // Cambiar a true porque este componente es multi-select
    disabled,
    isSortable = false,
    size = 'medium'
}) => {
    function arrayMove<T>(array: readonly T[], from: number, to: number) {
        const slicedArray = array.slice();
        slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);
        return slicedArray;
    }
    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        const newValue = arrayMove(inputValue, oldIndex, newIndex);
        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    };

    const sortableProps: any = {
        useDragHandle: true,
        axis: 'xy',
        onSortEnd: onSortEnd,
        distance: 4
    };

    const sortableComponents = isSortable
        ? {
              MultiValue: SortableMultiValue,
              MultiValueLabel: SortableMultiValueLabel
          }
        : {};

    return (
        <SortableSelect
            {...sortableProps}
            id={id}
            isMulti={isMulti}
            options={options}
            value={Array.isArray(inputValue) ? inputValue : selectedOption(options, inputValue)}
            onChange={onChange}
            components={sortableComponents}
            placeholder={placeholder}
            isDisabled={disabled}
            name={name}
            styles={select2Config(hasError, size)}
            isClearable={true}
            onFocus={onFocus}

            // Aquí puedes añadir más props si son necesarias
        />
    );
};

export default SelectTwo;
