import React from 'react';

import LazyLoading from '../../../components/LazyLoading';
import { WorkOrderStatusRequestDataForm } from '../../../app/models/WorkOrder';

interface Props {
    requestChangeStatus: (status: string) => void;
    requestUnlockStatus?: () => void;
    fetchingChangeStatus: boolean;
    statuses: WorkOrderStatusRequestDataForm[];
    status: string;
}

const ChangeStatus = ({ requestChangeStatus, requestUnlockStatus, fetchingChangeStatus, statuses, status }: Props) => {
    const parseStatusToName = (state: string) => {
        // switch (state) {
        //     case 'PREPARANDO':
        //         return 'Programado';
        //     case 'ACTIVA':
        //         return 'En proceso';
        //     case 'FINALIZADA':
        //         return 'Finalizado';
        //     default:
        //         return '';
        // }
        const statusObj = statuses.find((status) => status.id === state);

        return statusObj ? statusObj.name : '';
    };

    const nextStatus = (state: string) => {
        // switch (state) {
        //     case 'PREPARANDO':
        //         return 'ACTIVA';
        //     case 'ACTIVA':
        //         return 'FINALIZADA';
        //     default:
        //         return '';
        // }

        const transitionObj = statuses.find((status) => status.id === state);

        return transitionObj ? String(transitionObj.next) : '';
    };

    const prevStatus = (state: string) => {
        // switch (state) {
        //     case 'ACTIVA':
        //         return 'PREPARANDO';
        //     case 'FINALIZADA':
        //         return 'ACTIVA';
        //     default:
        //         return '';
        // }
        const transitionObj = statuses.find((status) => status.id === state);

        return transitionObj ? String(transitionObj.prev) : '';
    };

    return fetchingChangeStatus ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-4 text-center">
                    {parseStatusToName(prevStatus(String(status))) !== '' && 
                    parseStatusToName(nextStatus(String(status))) !== '' && (
                        <>
                            <div className="h3 mt-0 mb-2 card-title">Anterior Estado</div>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    requestChangeStatus(prevStatus(String(status)));
                                }}
                            >
                                {parseStatusToName(prevStatus(String(status)))}
                            </button>
                        </>
                    )}
                    {parseStatusToName(nextStatus(String(status))) === '' && requestUnlockStatus && (
                        <>
                            <div className="h3 mt-0 mb-2 card-title text-center">Desbloquear Estado</div>

                            <button
                                className="btn btn-primary text-center"
                                onClick={() => {
                                    requestUnlockStatus();
                                }}
                            >
                                Desbloquear
                            </button>
                        </>
                    )}
                </div>

                <div className="col-4 text-center">
                    <div className="h3 mt-0 mb-2 card-title text-center">Estado actual</div>

                    <div className="h5 mt-0 mb-2 card-title text-center">
                        <strong>{parseStatusToName(String(status))}</strong>
                    </div>
                </div>

                <div className="col-4 float-end text-center">
                    {parseStatusToName(nextStatus(String(status))) !== '' && (
                        <>
                            <div className="h3 mt-0 mb-2 card-title text-center">Siguiente Estado</div>

                            <button
                                className="btn btn-primary text-center"
                                onClick={() => {
                                    requestChangeStatus(nextStatus(String(status)));
                                }}
                            >
                                {parseStatusToName(nextStatus(String(status)))}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ChangeStatus;
