import { Clasification } from './Clasification';

export interface Account {
    id?: number;
    accountant?: string;
    account?: string;
    clasif?: Clasification;
    clasif_id?: number;
    cta?: string;
    clasif_two?: Clasification;
    clasif_two_id?: number;
    cta_two?: string;
    clasif_three?: Clasification;
    clasif_three_id?: number;
    clasification?: Clasification;
    Clasification_id?: Clasification;
    name?: string;
    is_created?: string;
}

export interface AccountDataForm {
    id?: number;
    accountant?: string;
    account?: string;
    clasif?: Clasification;
    clasif_id?: number;
    cta?: string;
    clasif_two?: Clasification;
    clasif_two_id?: number;
    cta_two?: string;
    clasif_three?: Clasification;
    clasif_three_id?: number;
    clasification?: string;
    clasification_id?: number;
    name?: string;
    is_created?: string;
}

export const defaultAccountDataForm: AccountDataForm = {
    name: '',
    accountant: '',
    account: '',
    clasif_id: 0,
    clasif_two_id: 0,
    clasif_three_id: 0,
    clasification_id: 0
};

export interface AccountFilters {
    id?: number | null;
    name?: string | '';
    accountant?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}
