import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { CalendarEvent } from '../../models/Event';

const useEventService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetEventsByWorker, setFetchingGetEventsByWorker] = useState(false);
    const [fetchingCreateEvent, setFetchingCreateEvent] = useState(false);
    const [fetchingEditEvent, setFetchingEditEvent] = useState(false);
    const [fetchingCreateShiftWorkEvent, setFetchingCreateShiftWorkEvent] = useState(false);
    const [fetchingStoreEvent, setFetchingStoreEvent] = useState(false);
    const [fetchingUpdateEvent, setFetchingUpdateEvent] = useState(false);
    const [fetchingUpdateShiftWorkEvent, setFetchingUpdateShiftWorkEvent] = useState(false);
    const [fetchingUpdateAllShiftWorkEvent, setFetchingUpdateAllShiftWorkEvent] = useState(false);
    const [fetchingUpdateNextShiftWorkEvent, setFetchingUpdateNextShiftWorkEvent] = useState(false);
    const [fetchingEventById, setFetchingEventById] = useState(false);
    const [fetchingDeleteShiftWorkEvent, setFetchingDeleteShiftWorkEvent] = useState(false);
    const [fetchingDeleteOneDayShiftWorkEvent, setFetchingDeleteOneDayShiftWorkEvent] =
        useState(false);
    const [fetchingDeleteNextDaysShiftWorkEvent, setFetchingDeleteNextDaysShiftWorkEvent] =
        useState(false);

    const getEventsByWorker = (date: string, view: string, events: ServiceEvents = {}) => {
        const url = `${EndPoints.EVENTS.GET_EVENTS_BY_WORKER}?date=${date}&view=${view}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetEventsByWorker
        });
    };

    const getEventsById = (id: number, date: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: `${EndPoints.EVENTS.GET_EVENT_BY_ID}?date=${date}`.replace(
                ':event_id',
                id.toString()
            ),
            setFetching: setFetchingEventById
        });
    };

    const createEvent = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EVENTS.CREATE_EVENT,
            setFetching: setFetchingCreateEvent
        });
    };

    const editEvent = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.EVENTS.EDIT_EVENT,
            setFetching: setFetchingEditEvent
        });
    };

    const updateEvent = (id: number, calendarEvent: CalendarEvent, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.EVENTS.UPDATE_EVENT.replace(':event_id', id.toString()),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            requestType: 'json',
            body: calendarEvent,
            setFetching: setFetchingUpdateEvent
        });
    };

    const updateShiftWorkEvent = (
        id: number,
        calendarEvent: CalendarEvent,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.EVENTS.UPDATE_SHIFT_WORK_EVENT.replace(':event_id', id.toString()),
            body: calendarEvent,
            setFetching: setFetchingUpdateShiftWorkEvent
        });
    };

    const updateAllShiftWorkEvent = (calendarEvent: CalendarEvent, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.EVENTS.UPDATE_ALL_SHIFT_WORK_EVENT,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            requestType: 'json',
            body: calendarEvent,
            setFetching: setFetchingUpdateAllShiftWorkEvent
        });
    };

    const updateNextShiftWorkEvent = (
        calendarEvent: CalendarEvent,
        date: string,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.EVENTS.UPDATE_NEXT_SHIFT_WORK_EVENT.replace(':date', date),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            requestType: 'json',
            body: calendarEvent,
            setFetching: setFetchingUpdateNextShiftWorkEvent
        });
    };

    const deleteShiftWorkEvent = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.EVENTS.DELETE_EVENT.replace(':event_id', id.toString()),
            setFetching: setFetchingDeleteShiftWorkEvent
        });
    };

    const deleteOneDayShiftWorkEvent = (id: number, date: string, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.EVENTS.DELETE_ONE_DAY_SHIFT_WORK_EVENT.replace(
                ':event_id',
                id.toString()
            ).replace(':date', date),
            setFetching: setFetchingDeleteOneDayShiftWorkEvent
        });
    };

    const deleteNextDaysShiftWorkEvent = (id: number, date: string, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.EVENTS.DELETE_NEXT_DAYS_SHIFT_WORK_EVENT.replace(
                ':event_id',
                id.toString()
            ).replace(':date', date),
            setFetching: setFetchingDeleteNextDaysShiftWorkEvent
        });
    };

    const createShiftWorkEvent = (calendarEvent: CalendarEvent, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.EVENTS.CREATE_SHIFT_WORK_EVENT,
            body: calendarEvent,
            setFetching: setFetchingCreateShiftWorkEvent
        });
    };

    const storeEvent = (calendarEvent: CalendarEvent, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.EVENTS.STORE_EVENTS,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            requestType: 'json',
            body: calendarEvent,
            setFetching: setFetchingStoreEvent
        });
    };

    return {
        fetchingGetEventsByWorker,
        fetchingStoreEvent,
        fetchingCreateShiftWorkEvent,
        fetchingEventById,
        fetchingUpdateEvent,
        fetchingCreateEvent,
        fetchingEditEvent,
        fetchingUpdateShiftWorkEvent,
        fetchingUpdateAllShiftWorkEvent,
        fetchingUpdateNextShiftWorkEvent,
        fetchingDeleteShiftWorkEvent,
        fetchingDeleteOneDayShiftWorkEvent,
        fetchingDeleteNextDaysShiftWorkEvent,
        getEventsByWorker,
        createEvent,
        editEvent,
        getEventsById,
        createShiftWorkEvent,
        storeEvent,
        updateEvent,
        updateShiftWorkEvent,
        updateAllShiftWorkEvent,
        updateNextShiftWorkEvent,
        deleteShiftWorkEvent,
        deleteOneDayShiftWorkEvent,
        deleteNextDaysShiftWorkEvent
    };
};

export default useEventService;
