import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';

type Props = {
    incident: any;
    setIncident: (incident: any) => void;
    errorFields?: any;
    isEdit?: boolean;
};

const IncidentForm = ({ incident, setIncident, errorFields, isEdit = false }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleFileChange = (event: FileList | null) => {
        if (event) {
            const file = event[0];
            setIncident({
                ...incident,
                evidence: file,
            });
        }
    };

    const handleChange = (
        event: 
        React.ChangeEvent<HTMLInputElement> 
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setIncident({ ...incident, [name]: value });
    };

    return (
        <div className="mb-2 row">
            <div className="col-md-12 mb-1 d-flex justify-content-between">
                <div className="flex-grow-1 me-2">
                    <label className="col-form-label">Fecha</label>
                    <input
                        name="incident_date"
                        id="incident_date"
                        type="date"
                        className={`form-control ${fieldHasError('incident_date')}`}
                        value={incident?.incident_date}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('incident_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('incident_date')}
                    </div>
                </div>

                <div className="flex-grow-1">
                    <label className="col-form-label">Hora</label>
                    <input
                        name="incident_hour"
                        id="incident_hour"
                        type="time"
                        className={`form-control ${fieldHasError('incident_hour')}`}
                        value={incident?.incident_hour}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('incident_hour')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('incident_hour')}
                    </div>
                </div>
            </div>

            <div className="col-md-12 mb-1">
                <label className="col-form-label">Evidencia</label>
                <InputButtonFile
                    name="evidence"
                    handleChangeDocumentFile={handleFileChange}
                    objectFile={incident?.evidence}
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('evidence')}
                </div>
            </div>

            <div className="col-md-12 mb-1">
                <label className="col-form-label">Motivo</label>
                <textarea
                    name="reason"
                    id="reason"
                    className={`form-control ${fieldHasError('reason')}`}
                    value={incident?.reason}
                    onChange={handleChange}
                    onFocus={() => onFocusRemove('reason')}
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('reason')}
                </div>
            </div>

            {isEdit && (
                <>
                    <div className="col-md-12 mb-1">
                        <label className="col-form-label">Solución</label>
                        <textarea
                            name="solution"
                            id="solution"
                            className={`form-control ${fieldHasError('solution')}`}
                            value={incident?.solution}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('solution')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('solution')}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default IncidentForm;
