import React, { useContext, useState } from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useHolidayService from '../../../app/services/hooks/useHolidayService';
import HolidayForm from './forms/HolidayForm';
import { HolidayDataForm } from '../../../app/models/Holiday';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const HolidayCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingStoreHoliday, storeHoliday, createHoliday } = useHolidayService();

    const [errorFields, setErrorFields] = useState<any>();
    const [holiday, setHoliday] = useState<HolidayDataForm>({ name: '' });

    const cancel = () => {
        if (onCancel) onCancel();
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando feriado...');
        storeHoliday(holiday, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <HolidayForm
                        holidayDataForm={holiday}
                        setHolidayDataForm={setHoliday}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreHoliday} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreHoliday} />
                </div>
            </div>
        </>
    );
};

export default HolidayCreate;
