import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useInternalSettlementService from '../../../app/services/hooks/useInternalSettlementService';
import { InternalSettlementShowData } from '../../../app/models/InternalSettlement';
import InternalSettlementShowForm from './forms/InternalSettlementShowForm';

interface Props {
    internalSettlementId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const InternalSettlementShow = ({ internalSettlementId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { showInternalSettlement, fetchingShowInternalSettlement } =
        useInternalSettlementService();
    const [internalSettlement, setInternalSettlement] = useState<InternalSettlementShowData>({});
    useEffect(() => {
        show();
    }, []);

    const show = () => {
        if (showLoading) showLoading('loading', 'Cargando liquidación interna...');
        showInternalSettlement(internalSettlementId, {
            onSuccess: (response: ServiceResponse) => {
                setInternalSettlement(response.data.internal_settlement);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingShowInternalSettlement ? (
        <LazyLoading height="300" />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <InternalSettlementShowForm internalSettlementDataForm={internalSettlement} />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonSaveForm title="Aceptar" callbackSave={cancel} />
                </div>
            </div>
        </>
    );
};

export default InternalSettlementShow;
