import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import { PaymentCondition } from '../../../app/models/PaymentCondition';
import useTransportationOrderService from '../../../app/services/hooks/useTransportationOrderService';
import { Currency } from '../../../app/models/Currency';
import {
    defaultTransportationOrderDataForm,
    TransportationOrderDataForm
} from '../../../app/models/TransportationOrder';
import LazyLoading from '../../../components/LazyLoading';
import DefaultCard from '../../../components/default/DefaultCard';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCreatedModifiedUserForm from '../../../components/default/DefaultCreatedModifiedUserForm';
import { toast } from 'react-toastify';
import TransportationOrderShowForm from '../components/forms/TransportationOrderShowForm';
import TransportationOrderItemsContainer from '../components/TransportationOrderItemsContainer';
import { RouterParams } from '../../../app/shared/interfaces';
import { Unit } from '../../../app/models/Unit';
import { AuthContext } from '../../../contexts/AuthContext';
import { TransportationQuoteDetail } from '../../../app/models/TransportationQuoteDetail';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';

const TransportationOrderEdit = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Pedidos de Transporte',
            url: '/transportation-orders',
            isActive: false
        },
        {
            name: 'Editar pedido de transporte #' + id,
            url: '/transportation-orders/' + id + '/edit',
            isActive: true
        }
    ];

    const { auth } = useContext(AuthContext);

    const {
        editTransportationOrder,
        fetchingEditTransportationOrder,
        fetchingShowTransportationOrder,
        updateTransportationOrder,
        showTransportationOrder,
        showQuoteTransportationOrder,
        updateTransportationOrderRateRunner
    } = useTransportationOrderService();

    const [transportationOrderForm, setTransportationOrderForm] =
        useState<TransportationOrderDataForm>(defaultTransportationOrderDataForm);

    const [errorFields, setErrorFields] = useState<any>();
    const [canBeEdited, setCanBeEdited] = useState(false);
    const { navigationPage } = useNavigationPage();

    const [quotes, setQuotes] = useState<TransportationQuoteDetail[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [societies, setSocieties] = useState<Society[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [paymentConditions, setPaymentsConditions] = useState<PaymentCondition[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentCondition[]>([]);

    useEffect(() => {
        reloadModule();
    }, []);

    const edit = (id: number) => {
        setCanBeEdited(false);
        editTransportationOrder(id, {
            onSuccess: (response: ServiceResponse) => {
                if (
                    response.data.transportation_order.transportation_quote_detail_id == '' ||
                    response.data.transportation_order.transportation_quote_detail_id == null
                ) {
                    setCanBeEdited(true);
                }

                setSocieties(response.data.societies);
                setClients(response.data.clients);
                setPaymentMethods(response.data.payment_methods);
                setPaymentsConditions(response.data.payment_conditions);
                setCurrencies(response.data.currencies);
                setQuantityUnits(response.data.quantity_units);
                setQuotes(response.data.quotes);

                setTransportationOrderForm(response.data.transportation_order);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2500
                });
                if (response.data.to_dashboard) {
                    navigationPage('/transportation-orders');
                }
            }
        });
    };

    const updateRateRunner = () => {
        if (showLoading) showLoading('loading', 'Actualizando tarifas...');

        updateTransportationOrderRateRunner(transportationOrderForm.id!, transportationOrderForm, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.success(response.message);
                setErrorFields(undefined);
                reloadModule();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const reloadModule = () => {
        edit(parseInt(id));
        // show(parseInt(id));
    };

    // useEffect(() => {
    //     if (transportationOrderForm.transportation_quote_detail_id) {
    //         console.log("viene quote detail id")
    //         showQuoteTransportationOrder(transportationOrderForm.transportation_quote_detail_id, {
    //             onSuccess: (response: ServiceResponse) => {
    //                 const quote: QuoteDataForm = response.data.quote;
    //                 console.log(quote)
    //                 setTransportationOrderForm({
    //                     ...transportationOrderForm,
    //                     contact_email: quote.contact_email,
    //                     expired_at: quote.expired_at,
    //                     society_id: quote.society_id,
    //                     client_id: quote.client_id,
    //                     payment_condition_id: quote.payment_condition_id,
    //                     payment_method_id: quote.payment_method_id,
    //                     currency_id: quote.currency_id,
    //                     transportation_quote_detail_id:
    //                         transportationOrderForm.transportation_quote_detail_id,
    //                     days_count:quote.days_count
    //                 });
    //             }
    //         });
    //     }
    // }, [transportationOrderForm.transportation_quote_detail_id]);

    // const show = (id: number) => {
    //     showTransportationOrder(id, {
    //         onSuccess: (response: ServiceResponse) => {
    //             setTransportationOrderForm(response.data.transportation_order);
    //             setCurrencies(response.data.currencies);
    //             setQuantityUnits(response.data.quantity_units);
    //         }
    //     });
    // };

    // const update = () => {
    //     updateTransportationOrder(transportationOrderForm.id!, transportationOrderForm, {
    //         onSuccess: (response: ServiceResponse) => {
    //             toast.success(response.message);
    //             reloadModule();
    //         },
    //         onError: (response: ServiceResponse) => {
    //             toast.error(response.message);
    //         },
    //         onFieldError: (errorFields: ServiceResponse) => {
    //             setErrorFields(errorFields.data);
    //         }
    //     });
    // };

    return (
        <>
            <Breadcrumbs
                pageSection={`Editar pedido de transporte #${id}`}
                breadcrumbs={breadcrumbs}
            />
            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingEditTransportationOrder ? (
                            // canBeEdited ? (
                            //     <>
                            //         <TransportationOrderForm
                            //             transportationOrderForm={transportationOrderForm}
                            //             setTransportationOrderForm={setTransportationOrderForm}
                            //             societies={societies}
                            //             clients={clients}
                            //             paymentConditions={paymentConditions}
                            //             paymentMethods={paymentMethods}
                            //             quantityUnits={quantityUnits}
                            //             currencies={currencies}
                            //             errorFields={errorFields}
                            //             quotes={quotes}
                            //         />
                            //         <div className="row justify-content-end">
                            //             <div className="col-auto">
                            //                 <ButtonSaveForm callbackSave={update} />
                            //             </div>
                            //         </div>
                            //     </>
                            // ) : (
                            <>
                                <TransportationOrderShowForm
                                    transportationOrderDataForm={transportationOrderForm}
                                    quotes={quotes}
                                />
                            </>
                        ) : (
                            // )
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="h3 mt-0 mb-2 card-title">Usuarios</div>
                            {!fetchingEditTransportationOrder ? (
                                canBeEdited ? (
                                    <>
                                        <DefaultCreatedModifiedUserForm
                                            createdBy={transportationOrderForm.created_user}
                                            createdAt={transportationOrderForm.created_at}
                                            updatedBy={transportationOrderForm.last_modified_user}
                                            updatedAt={transportationOrderForm.updated_at}
                                            status={transportationOrderForm.status}
                                        />
                                    </>
                                ) : (
                                    <DefaultCreatedModifiedUserForm
                                        createdBy={transportationOrderForm.created_user}
                                        createdAt={transportationOrderForm.created_at}
                                        updatedBy={transportationOrderForm.last_modified_user}
                                        updatedAt={transportationOrderForm.updated_at}
                                        status={transportationOrderForm.status}
                                        // status={
                                        //     transportationOrderForm.status_selected
                                        //         ? transportationOrderForm.status_selected.name.toString()
                                        //         : ''
                                        // }
                                    />
                                )
                            ) : (
                                <LazyLoading />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <>
                <TransportationOrderItemsContainer
                    transportationOrderId={parseInt(id)}
                    canBeEdited={canBeEdited}
                    currencyId={transportationOrderForm.currency_id}
                    transportationOrderForm={transportationOrderForm}
                    setTransportationOrderForm={setTransportationOrderForm}
                    reloadModule={reloadModule}
                />
            </>
        </>
    );
};

export default TransportationOrderEdit;
