import React from 'react';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: any;
    setFilter: (filter: any) => void;
    onSearch: () => void;
};

const HopperFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.name);
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="patent">
                            Patente
                        </label>
                        <input
                            title="Patente"
                            type="text"
                            id="patent"
                            name="patent"
                            className="form-control form-control-sm"
                            value={filter.patent}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="year">
                            Año
                        </label>
                        <input
                            title="Año"
                            type="number"
                            id="year"
                            name="year"
                            className="form-control form-control-sm"
                            value={filter.year}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="brand">
                            Marca
                        </label>
                        <input
                            title="Año"
                            type="text"
                            id="brand"
                            name="brand"
                            className="form-control form-control-sm"
                            value={filter.brand}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="vehicle_model">
                            Modelo
                        </label>
                        <input
                            title="Año"
                            type="text"
                            id="vehicle_model"
                            name="vehicle_model"
                            className="form-control form-control-sm"
                            value={filter.vehicle_model}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HopperFilter;
