import { useContext, useEffect, useState } from 'react';
import LazyLoading from '../../../components/LazyLoading';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { ExpenseHistoryDataForm } from '../../../app/models/ExpenseHistory';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useExpenseHistoryService from '../../../app/services/hooks/useExpenseHistoryService';
import ExpenseHistoryForm from './forms/ExpenseHistoryForm';

interface Props {
    expenseHistoryId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ExpenseHistoryEdit = ({ expenseHistoryId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const {
        editExpenseHistory,
        updateExpenseHistory,
        fetchingHistoryExpenseEdit,
        fetchingHistoryExpenseUpdate
    } = useExpenseHistoryService();

    const [expenseHistory, setExpenseHistory] = useState<ExpenseHistoryDataForm>({
        comment: ''
    });

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        editHistory();
    }, [expenseHistoryId]);

    const editHistory = () => {
        if (showLoading) showLoading('loading', 'Cargando información de historial...');
        editExpenseHistory(expenseHistoryId, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setExpenseHistory(response.data.expense_history);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onCancel) onCancel();
            }
        });
    };

    const updateHistory = () => {
        if (showLoading) showLoading('loading', 'Actualizando información de historial...');
        updateExpenseHistory(expenseHistoryId, expenseHistory, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingHistoryExpenseEdit ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ExpenseHistoryForm
                        expenseHistoryDataForm={expenseHistory}
                        setExpenseHistoryDataForm={setExpenseHistory}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingHistoryExpenseUpdate}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={updateHistory}
                        locked={fetchingHistoryExpenseUpdate}
                    />
                </div>
            </div>
        </>
    );
};

export default ExpenseHistoryEdit;
