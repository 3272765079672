import { useContext, useEffect, useState, ChangeEvent } from 'react';
import useDocumentService from '../../../app/services/hooks/useDocumentService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import DocumentForm from './forms/DocumentForm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { Document, defaultDocument, DocumentDataForm } from '../../../app/models/Document';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import DocumentFormContainer from './DocumentFormContainer';

interface GeneralDocumentProps {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DocumentCreate = ({ onSaved, onCancel, onError }: GeneralDocumentProps) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingStoreDocument, storeDocument, createDocument, fetchingCreateDocument } =
        useDocumentService();
    const [document, setDocument] = useState<Document>(defaultDocument);

    const [errorFields, setErrorFields] = useState<any>();

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando documento...');
        storeDocument(document, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateDocument ? (
        <LazyLoading height={300} />
    ) : (
        <DocumentFormContainer
            fetching={fetchingStoreDocument}
            action={store}
            cancel={cancel}
            document={document}
            setDocument={setDocument}
            errorFields={errorFields}
        />
    );
};

export default DocumentCreate;
