import React, { useContext } from 'react';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { TransportationQuoteDetail } from '../../../../app/models/TransportationQuoteDetail';
import { momentParseDate } from '../../../../helpers';
import { formatMoney, formatNumber } from '../../../../utils/utils';
import ButtonTableEdit from '../../../../components/buttons/ButtonTableEdit';
import ButtonTableDestroy from '../../../../components/buttons/ButtonTableDestroy';
import ButtonTableShow from '../../../../components/buttons/ButtonTableShow';
import { Roles } from '../../../../app/shared/enums';
import { AuthContext } from '../../../../contexts/AuthContext';
import { QuoteRouteSegmentFilters } from '../../../../app/models/QuoteRouteSegment';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';

interface Props {
    quoteRouteSegments: any[];
    callbackSuccess?: () => void;
    loading?: boolean;
    totalRows: number;
    edit?: (quoteDetailId: number) => void;
    destroy?: (quoteDetailId: number) => void;
    show?: (quoteDetailId: number) => void;
    filter: QuoteRouteSegmentFilters;
    setFilter: (filter: QuoteRouteSegmentFilters) => void;
    paginationRowsPerPageOptions?: any[];
}

const QuoteRouteSegmentDataTable = ({
    quoteRouteSegments,
    totalRows,
    filter,
    setFilter,
    loading = false,
    callbackSuccess,
    edit,
    destroy,
    show
}: Props) => {
    const { auth } = useContext(AuthContext);

    const columns = [
        {
            name: 'Ruta',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: any, index: any, column: any) => <span className="">{row.id}</span>
        },
        {
            name: 'Tramo',
            selector: (row: any) => row.route_segment_id,
            sortable: true,
            sortField: 'route_segment_id',
            width: '100px',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.route_segment_id}</span>
            )
        },
        {
            name: 'Origen',
            selector: (row: any) => row.origin,
            sortable: true,
            sortField: 'origin',
            cell: (row: any, index: any, column: any) => <span className="">{row.origin}</span>
        },
        {
            name: 'Destino',
            selector: (row: any) => row.destiny,
            sortable: true,
            sortField: 'destiny',
            cell: (row: any, index: any, column: any) => <span className="">{row.destiny}</span>
        },
        {
            name: 'KM real',
            selector: (row: any) => row.distance,
            sortable: true,
            sortField: 'distance',
            cell: (row: any, index: any, column: any) => {
                return formatNumber(row.distance);
            }
        },
        {
            name: 'KM Acordado',
            selector: (row: any) => row.agreed_distance,
            sortable: true,
            sortField: 'agreed_distance',
            cell: (row: any, index: any, column: any) => {
                return formatNumber(row.agreed_distance);
            }
        },
        {
            name: 'Tarifa de venta',
            selector: (row: any) => row.rate,
            sortable: true,
            sortField: 'rate',
            cell: (row: any, index: any, column: any) => {
                return formatMoney(row.currency && row.rate);
            }
        },
        {
            name: 'Unidad',
            selector: (row: any) => row.currency,
            sortable: true,
            sortField: 'unit',
            cell: (row: any, index: any, column: any) => (
                <span className="">{`${row.currency.name} / ${row.unit.name}`}</span>
            )
        },
        {
            name: 'Tarifa Runner',
            selector: (row: any) => row.rate_runner,
            sortable: true,
            sortField: 'rate_runner',
            cell: (row: any, index: any, column: any) => {
                return (
                    <div>
                        {`${formatMoney(
                            row.rate_runner,
                            row.currency_rate_runner && row.currency_rate_runner.name
                        )}` +
                            ` ${row.currency_rate_runner?.name} / ${row.quantity_unit_rate_runner?.name}`}
                    </div>
                );
            }
        },
        {
            name: 'Tarifa Transportista Externo',
            selector: (row: any) => row.external_rate,
            sortable: true,
            sortField: 'external_rate',
            width: '150px',
            cell: (row: any, index: any, column: any) => {
                return (
                    <div>
                        {`${formatMoney(
                            row.external_rate,
                            row.currency_external_rate && row.currency_external_rate.name
                        )}` +
                            ` ${row.currency_external_rate?.name} / ${row.unit_external_rate?.name}`}
                    </div>
                );
            }
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => {
                return (
                    <div>
                        {show && (
                            <ButtonTableAction
                                callbackFunction={() => show(row.id ?? -1)}
                                classIcon={'mdi mdi-eye'}
                                colorIcon={'text-info'}
                                errorMessage={'No se puede mostrar este registro.'}
                                title={'Ver'}
                            />
                        )}
                        {destroy &&
                            auth?.roles &&
                            (auth?.roles.includes(Roles.MANAGERS) ||
                                auth?.roles.includes(Roles.OPERATIONS_MANAGER)) && (
                                <ButtonTableAction
                                    callbackFunction={() => destroy(row.id ?? -1)}
                                    classIcon={'mdi mdi-delete'}
                                    colorIcon={'text-danger'}
                                    errorMessage={'No se puede eliminar este registro.'}
                                    title={'Eliminar'}
                                />
                            )}
                        {edit && (
                            <ButtonTableAction
                                callbackFunction={() => edit(row.id ?? -1)}
                                classIcon={'mdi mdi-pencil'}
                                colorIcon={'text-warning'}
                                errorMessage={'No se puede editar este registro.'}
                                title={'Editar'}
                            />
                        )}
                    </div>
                );
            }
        }
    ];

    return (
        <div className="row">
            <div className="col-12 mt-4">
                <DefaultDataTable
                    columns={columns}
                    data={quoteRouteSegments}
                    progressPending={loading}
                    filter={filter}
                    setFilter={setFilter}
                    paginationTotalRows={totalRows}
                />
            </div>
        </div>
    );
};

export default QuoteRouteSegmentDataTable;
