import React from 'react';

import RouteSegmentForm from './forms/RouteSegmentForm';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { RouteSegmentDataForm } from '../../../app/models/RouteSegment';
import { Place } from '../../../app/models/Place';
import { Currency } from '../../../app/models/Currency';
import { Unit } from '../../../app/models/Unit';
import { Toll } from '../../../app/models/Toll';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    routeSegmentForm: RouteSegmentDataForm;
    setRouteSegmentForm: (routeSegment: RouteSegmentDataForm) => void;
    origins: Place[];
    destinations: Place[];
    tolls: Toll[];
    errorFields?: any;
    currencies: Currency[];

    units: Unit[];
}

const RouteSegmentFormContainer = ({
    fetching,
    action,
    cancel,
    routeSegmentForm,
    setRouteSegmentForm,
    origins,
    destinations,
    tolls,
    currencies,
    units,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <RouteSegmentForm
                        routeSegmentForm={routeSegmentForm}
                        setRouteSegmentForm={setRouteSegmentForm}
                        origins={origins}
                        destinations={destinations}
                        tolls={tolls}
                        errorFields={errorFields}
                        currencies={currencies}
                        units={units}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default RouteSegmentFormContainer;
