import { useContext, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

import { ServiceEvents } from '../shared/interfaces';
import { GuideDataForm, GuideFilters } from '../../models/Guide';
import { AppContext } from '../../../contexts/AppContext';
import { fetchDefaultHeaders } from '../shared/vars';
import { GuideHistoryDataForm } from '../../models/GuideHistory';

const useGuideService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [fetchingGetGuidesByTransportOrder, setFetchingGetGuidesByTransportOrder] =
        useState(false);
    const [fetchingGetGuides, setFetchingGetGuides] = useState(false);
    const [fetchingStoreGuide, setFetchingStoreGuide] = useState(false);
    const [fetchingEditGuide, setFetchingEditGuide] = useState(false);
    const [fetchingUpdateGuide, setFetchingUpdateGuide] = useState(false);
    const [fetchingDeleteGuide, setFetchingDeleteGuide] = useState(false);
    const [fetchingCreateGuide, setFetchingCreateGuide] = useState(false);
    const [fetchingShowGuide, setFetchingShowGuide] = useState(false);
    const [fetchingGetUrlDocument, setFetchingGetUrlDocument] = useState(false);
    const [fetchingGetUrlDocumentOnTransportOrder, setFetchingGetUrlDocumentOnTransportOrder] =
        useState(false);
    const [fetchingApprovedGuide, setFetchingApprovedGuide] = useState(false);
    const [fetchingCancelIsApprovedGuide, setFetchingCancelIsApprovedGuide] = useState(false);

    const getAllGuidesByTransportOrder = (
        filter: GuideFilters,
        transport_order_id: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.GUIDES.GET_GUIDES_BY_TRANSPORT_ORDER.replace(
            ':transport_order_id',
            transport_order_id.toString()
        )}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetGuidesByTransportOrder
        });
    };

    const getGuides = (filter: GuideFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.GUIDES.GET_GUIDES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetGuides
        });
    };

    const createGuide = (transportOrderId: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.GUIDES.CREATE_GUIDE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ),
            setFetching: setFetchingCreateGuide
        });
    };

    const storeGuide = (
        transportOrderId: number,
        GuideDataForm: GuideDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.GUIDES.STORE_GUIDE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ),
            body: GuideDataForm,
            setFetching: setFetchingStoreGuide
        });
    };

    const editGuide = (transportOrderId: number, id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.GUIDES.EDIT_GUIDE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingEditGuide
        });
    };

    const updateGuide = (
        transportOrderId: number,
        id: number,
        GuideDataForm: GuideDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.GUIDES.UPDATE_GUIDE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            body: GuideDataForm,
            setFetching: setFetchingUpdateGuide
        });
    };

    const getUrlDocumentGuide = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.GUIDES.DOCUMENT_GUIDE.replace(':id', id.toString()),
            setFetching: setFetchingGetUrlDocument
        });
    };

    const getUrlDocumentGuideOnTransportOrder = (
        transportOrderId: number,
        id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.GUIDES.DOCUMENT_GUIDE_ON_TRANSPORT_ORDER.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingGetUrlDocumentOnTransportOrder
        });
    };

    const downloadDocument = (url_file: string) => {
        const link = document.createElement('a');
        link.href = url_file;
        link.download = 'documento guia';
        link.target = '_blank';
        document.body.appendChild(link);

        // Simular un clic en el enlace para iniciar la descarga
        link.click();

        if (changeAnimation)
            changeAnimation('downloadSuccess', 'archivo recuperado con exito', true);
    };

    // const showGuide = (id: number, events: ServiceEvents = {}) => {
    //     doGet({
    //         ...events,
    //         url: EndPointsPegasus.TRANSPORT_ORDERS.SHOW_TRANSPORT_ORDER.replace(
    //             ':id',
    //             id.toString()
    //         ),
    //         setFetching: setFetchingShowGuide
    //     });
    // };

    const deleteGuide = (transportOrderId: number, id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.GUIDES.DELETE_GUIDE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', id.toString()),
            setFetching: setFetchingDeleteGuide
        });
    };
    const approvedGuide = (
        transportOrderId: number,
        guideId: number,
        formData: GuideHistoryDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.GUIDES.APPROVED_GUIDE.replace(
                ':transport_order_id',
                transportOrderId.toString()
            ).replace(':id', guideId.toString()),
            body: formData,
            setFetching: setFetchingApprovedGuide
        });
    };

    const cancelIsApprovedGuide = (
        id: number,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.GUIDES.CANCEL_APPROVED_GUIDE.replace(':id', id.toString()),
            setFetching: setFetchingCancelIsApprovedGuide
        });
    };

    return {
        getAllGuidesByTransportOrder,
        getGuides,
        createGuide,
        storeGuide,
        editGuide,
        updateGuide,
        // showGuide,
        deleteGuide,
        approvedGuide,
        getUrlDocumentGuide,
        downloadDocument,
        getUrlDocumentGuideOnTransportOrder,
        cancelIsApprovedGuide,
        fetchingGetGuidesByTransportOrder,
        fetchingGetUrlDocumentOnTransportOrder,
        fetchingGetGuides,
        fetchingStoreGuide,
        fetchingEditGuide,
        fetchingUpdateGuide,
        fetchingShowGuide,
        fetchingDeleteGuide,
        fetchingCreateGuide,
        fetchingGetUrlDocument,
        fetchingApprovedGuide,
        fetchingCancelIsApprovedGuide
    };
};

export default useGuideService;
