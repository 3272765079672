import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { HopperDocument } from '../../models/Hopper';
import { DocumentNameFilters } from '../../models/Document';

const useHopperDocumentService = () => {
    const { doGet, doPost } = useFetch();

    const [
        fetchingGetHopperDocumentsByHopperAndDocumentType,
        setFetchingGetHopperDocumentsByHopperAndDocumentType
    ] = useState(false);
    const [fetchingGetHopperDocuments, setFetchingGetHopperDocuments] = useState(false);
    const [fetchingStoreHopperDocument, setFetchingStoreHopperDocument] = useState(false);

    const getHopperDocumentsByHopper = (hopper_id: number, filters: DocumentNameFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = EndPoints.HOPPER_DOCUMENT.GET_HOPPER_DOCUMENTS_BY_HOPPER.replace(
            ':id',
            hopper_id.toString()
        );
        doGet({
            ...events,
            url: `${url}?${queryString}`,
            setFetching: setFetchingGetHopperDocuments
        });
    };

    const storeHopperDocument = (hopperDocument: HopperDocument, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.HOPPER_DOCUMENT.STORE_HOPPER_DOCUMENT,
            requestType: 'multipart',
            body: hopperDocument,
            setFetching: setFetchingStoreHopperDocument
        });
    };

    const getHopperDocumentByHopperAndDocumentType = (
        hopper_id: number,
        document_id: number,
        events: ServiceEvents = {}
    ) => {
        const url = `${EndPoints.HOPPER_DOCUMENT.HISTORY_BY_HOPPER_AND_TYPE}`;
        doGet({
            ...events,
            url: url
                .replaceAll(':hopper_id', hopper_id.toString())
                .replaceAll(':document_id', document_id.toString()),
            setFetching: setFetchingGetHopperDocumentsByHopperAndDocumentType
        });
    };

    return {
        fetchingGetHopperDocumentsByHopperAndDocumentType,
        fetchingGetHopperDocuments,
        storeHopperDocument,
        getHopperDocumentsByHopper,
        getHopperDocumentByHopperAndDocumentType,
        fetchingStoreHopperDocument
    };
};

export default useHopperDocumentService;
