import { useContext, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useNavigationPage from '../../hooks/useNavigationPage';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import DefaultModal from '../../components/default/DefaultModal';
import useContractService from '../../app/services/hooks/useContractService';
import { Contract, ContractFilters } from '../../app/models/Contract';
import ContractDataTable from './components/tables/ContractDataTable';
import ContractCreate from './components/forms/ContractCreate';
import ContractEdit from './components/forms/ContractEdit';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Contratos de Trabajo',
        url: '/contracts',
        isActive: true
    }
];

const Contracts = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { fetchingGetContracts, getContracts, deleteContract, activeContract } =
        useContractService();
    const [showingCreate, setShowingCreate] = useState(false);
    const [showingEdit, setShowingEdit] = useState(false);

    const [contractIdEdit, setContractIdEdit] = useState<number>(-1);
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [contractFilter, setContractFilter] = useState<ContractFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [contractFilter.page, contractFilter.per_page, contractFilter.sort, contractFilter.order]);

    const getAllContracts = () => {
        getContracts(contractFilter, {
            onSuccess: (response: ServiceResponse) => {
                setContracts(response.data.contracts);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const destroy = (contractId: number) => {
        const _text = 'Está a punto de eliminar el contrato #' + contractId;
        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando contrato...');
                        deleteContract(contractId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El contrato no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const showEdit = (contractId: number) => {
        setShowingEdit(true);
        setContractIdEdit(contractId);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllContracts();
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setContractIdEdit(-1);
    };

    return (
        <>
            <Breadcrumbs pageSection="Contratos de Trabajo" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <DefaultToolBar
                    left={
                        <ButtonCreate
                            callbackCreate={showCreate}
                            title="Nuevo contrato de trabajo"
                        />
                    }
                />
                <ContractDataTable
                    contracts={contracts}
                    totalRows={totalRows}
                    filter={contractFilter}
                    loading={fetchingGetContracts}
                    setFilter={setContractFilter}
                    callbackSuccess={reloadTable}
                    edit={showEdit}
                    destroy={destroy}
                    onSearch={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Contrato de trabajo"
                    backdrop={true}
                    showFooter={false}
                >
                    <ContractCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                        onError={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Contrato de Trabajo"
                    backdrop={true}
                    showFooter={false}
                >
                    <ContractEdit
                        contractId={contractIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Contracts;
