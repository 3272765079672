import { PaymentMethod } from './PaymentMethod';
import { PaymentCondition } from './PaymentCondition';
import { Client } from './Client';
import { Currency } from './Currency';
import { Society } from './Society';
import { TransportationQuoteDetail } from './TransportationQuoteDetail';
import { QuoteStatus } from './QuoteStatus';
import { QuoteOfferType } from './QuoteOfferType';
import { QuoteRouteSegment } from './QuoteRouteSegment';

export interface Quote {
    id?: number;
    name?: string;
    contactEmail?: string;
    status?: number | string;
    statusSelected?: QuoteStatus;
    statusChoices?: QuoteStatus[];
    expiredAt?: Date | string;
    offer_type?: string;
    offer_type_selected?: QuoteOfferType;
    clientId?: number;
    client?: Client;
    paymentMethodId?: number;
    paymentMethod?: PaymentMethod;
    paymentConditionId?: number;
    paymentCondition?: PaymentCondition;
    currencyId?: number;
    currency?: Currency;
    societyId?: number;
    society?: Society;
    createdAt?: Date | string;
    updatedAt?: Date | string;
    transportationQuoteDetails?: TransportationQuoteDetail[];
    isRouteSegmentDocument?: boolean;
    comment?: string;
    client_id?: number;
}

export interface QuoteFilters extends QuoteDataFormFilters {
    quote_status?: string | null;
    start_date?: string | null;
    end_date?: string | null;
    quote_id?: number | null;
    client?: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface QuoteDataForm {
    id?: number;
    contact_email: string;
    expired_at: string;
    expired_in_days?: number;
    client_id: number | string;
    payment_method_id: number | string;
    payment_condition_id: number | string;
    reference?: string;
    offer_type?: string;
    offer_type_selected?: QuoteOfferType;
    days_count?: number;
    currenc?: Currency;
    currency_id: number | string;
    society_id: number | string;
    status?: string;
    status_selected?: QuoteStatus;
    status_choices?: QuoteStatusRequestDataForm[];
    quote_details?: TransportationQuoteDetailDataForm[];
    created_at?: string;
    created_user?: string;
    updated_at?: string;
    last_modified_user?: string;
    comment?: string;
    is_route_segment_document?: boolean;
}

export interface QuoteDataFormFilters {
    id?: number | null;
    contact_email?: string;
    expired_at?: string;
    expired_in_days?: number;
    client_id?: number | string;
    payment_method_id?: number | string;
    payment_condition_id?: number | string;
    reference?: string | '';
    offer_type?: string | '';
    offer_type_selected?: QuoteOfferType;
    days_count?: number;
    currenc?: Currency;
    currency_id?: number | string;
    society_id?: number | string;
    status?: string | '';
    status_selected?: QuoteStatus;
    status_choices?: QuoteStatusRequestDataForm[];
    quote_details?: TransportationQuoteDetailDataForm[];
    created_at?: string;
    created_user?: string;
    updated_at?: string;
    last_modified_user?: string;
    comment?: string;
    is_route_segment_document?: boolean;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface QuoteShowDataForm {
    id?: number;
    contact_email: string;
    expired_at?: string;
    client: string;
    payment_method: string;
    payment_condition: string;
    reference?: string;
    days_count?: number;
    offer_type?: string;
    currency: string;
    society: string;
    status?: string;
    status_selected?: QuoteStatus;
    status_choices?: QuoteStatusRequestDataForm[];
    quote_details?: TransportationQuoteDetailDataForm[];
    created_at?: string;
    created_user?: string;
    updated_at?: string;
    last_modified_user?: string;
    comment?: string;
    expired_in_days?: number;
    is_route_segment_document?: boolean;
}

export interface TransportationQuoteDetailDataForm {
    quote_id?: number;
    id?: number;
    description?: string;
    first_dispatch_date?: string;
    product_name?: string;
    product_rate?: number;
    product_unit?: string;
    currency?: string;
    service?: string;
    service_id?: number | string;
    destiny_id?: number | string;
    origin_id?: number | string;
    product_id?: number | string;
    route_segment_id?: number;
    quote_route_segment_id?: number | string | undefined;
    quote_route_segment?: QuoteRouteSegment;
    rate?: number | string;
    currency_id?: number | string;
    quantity?: number | string;
    quantity_unit_id?: number | string;
    distance?: number | string;
    agreed_distance?: number;
    rate_runner?: number | string;
    currency_rate_runner_id?: number | string;
    quantity_unit_rate_runner_id?: number | string;
    external_rate?: number;
    currency_external_rate_id?: number;
    unit_external_rate_id?: number;

    created_at?: string;
    updated_at?: string;
    action_to_do?: string;
}

export interface TransportationQuoteDetailDataFormFilters {
    quote_id?: number | null | undefined;
    id?: number | null;
    first_dispatch_date?: string | '';
    product_name?: string | '';
    product_rate?: number | null;
    product_unit?: string | '';
    currency?: any;
    service?: string | '';
    service_id?: number | string;
    destiny_id?: number | string;
    origin_id?: number | string;
    product_id?: number | string;
    route_segment_id?: number | null;
    quote_route_segment_id?: number | string | undefined;
    quote_route_segment?: QuoteRouteSegment;
    rate?: number | string;
    currency_id?: number | string;
    quantity?: number | string;
    quantity_unit_id?: number | string;
    distance?: number | string;
    agreed_distance?: number | string;
    created_at?: string | '';
    updated_at?: string | '';
    action_to_do?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface TransportationQuoteDetailShowDataForm {
    quote_id?: number;
    id?: number;
    description?: string;
    first_dispatch_date?: string;
    product_name?: string;
    product_rate?: number;
    product_unit?: string;
    quantity?: number | string;
    quantity_unit?: number | string;
    rate?: number;
    service?: string;
    currency?: string;
    destiny?: number | string;
    origin?: number | string;
    created_at?: string;
    updated_at?: string;
    route_segment_id?: number;
    quote_route_segment_id?: number;
    agreed_distance?: number | string;
    rate_runner?: number;
    currency_rate_runner?: string;
    quantity_unit_rate_runner?: string;
    external_rate?: number;
    currency_external_rate?: string;
    unit_external_rate?: string;
}

export interface QuoteStatusRequestDataForm {
    id?: number | string;
    name?: number | string;
}

export interface QuoteOfferTypeDataForm {
    id: number | string;
    name: number | string;
}

export interface ActionResquest {
    first_time?: string;
    add?: string;
    update?: string;
}

export interface CheckQuoteDocumentRoutes {
    is_route_segment_document?: boolean;
}
//init form
export const defaultQuoteDataForm: QuoteDataForm = {
    contact_email: '',
    expired_at: '',
    expired_in_days: 0,
    client_id: 0,
    payment_method_id: 0,
    payment_condition_id: 0,
    reference: '',
    days_count: 0,
    currency_id: 0,
    society_id: 0
};

export const defaultTransportationQuoteDetailDataForm: TransportationQuoteDetailDataForm = {
    rate: 0,
    currency_id: undefined,
    quantity_unit_id: undefined,
    distance: 0,
    agreed_distance: 0,

    action_to_do: undefined,

    rate_runner: 0,
    currency_rate_runner_id: undefined,
    quantity_unit_rate_runner_id: undefined,
    external_rate: 0,
    currency_external_rate_id: undefined,
    unit_external_rate_id: undefined,

    quote_route_segment_id: undefined
};

export const defaultEditTransportationQuoteDetailDataForm: TransportationQuoteDetailDataForm = {
    rate: 0,
    currency_id: undefined,
    quantity_unit_id: undefined,
    distance: 0,

    rate_runner: 0,
    currency_rate_runner_id: undefined,
    quantity_unit_rate_runner_id: undefined,
    external_rate: 0,
    currency_external_rate_id: undefined,
    unit_external_rate_id: undefined,

    quote_route_segment_id: undefined
};
