import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { fetchDefaultHeaders } from '../shared/vars';
import {
    QuoteRouteSegmentDataForm,
    QuoteRouteSegmentFilters
} from '../../models/QuoteRouteSegment';
import { CheckQuoteDocumentRoutes, QuoteDataForm } from '../../models/Quote';

const useQuoteRouteSegmentService = () => {
    const { doPost, doGet, doPut, doDelete } = useFetch();

    const [fetchingGetQuoteRouteSegments, setFetchingGetQuoteRouteSegments] = useState(false);
    const [fetchingShowRsToQuoteRouteSegment, setFetchingShowRsToQuoteRouteSegment] =
        useState(false);
    const [
        fetchingShowRsToTransportationQuoteDetail,
        SetFetchingShowRsToTransportationQuoteDetail
    ] = useState(false);
    const [fetchingShowRsToTransportationOrderItem, SetFetchingShowRsToTransportationOrderItem] =
        useState(false);
    const [fetchingCreateQuoteRouteSegment, setFetchingCreateQuoteRouteSegment] = useState(false);
    const [fetchingStoreQuoteRouteSegment, setFetchingStoreQuoteRouteSegment] = useState(false);
    const [fetchingEditQuoteRouteSegment, setFetchingEditQuoteRouteSegment] = useState(false);
    const [fetchingUpdateQuoteRouteSegment, setFetchingUpdateQuoteRouteSegment] = useState(false);
    const [fetchingDeleteQuoteRouteSegment, setFetchingDeleteQuoteRouteSegment] = useState(false);
    const [fetchingShowQuoteRouteSegment, setFetchingShowQuoteRouteSegment] = useState(false);
    const [fetchingGetQuoteRouteSegment, setFetchingGetQuoteRouteSegment] = useState(false);

    const getQuoteRouteSegments = (
        // routeSegmentFilters: RouteSegmentFilters,
        quoteId: number,
        quoteRouteSegmentFilters: QuoteRouteSegmentFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(quoteRouteSegmentFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.ROUTE_SEGMENTS.GET_QUOTE_ROUTE_SEGMENTS}?${queryString}`;
        doGet({
            ...events,
            url: url.replace(':id', quoteId.toString()),
            setFetching: setFetchingGetQuoteRouteSegments
        });
    };

    const showRsToQuoteRouteSegment = (
        quote_route_segment_id: number,
        route_segment_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.SHOW_RS_TO_QUOTE_ROUTE_SEGMENT.replace(
                ':quote_route_segment_id',
                quote_route_segment_id.toString()
            ).replace(':id', route_segment_id.toString()),
            setFetching: setFetchingShowRsToQuoteRouteSegment
        });
    };

    const showRsToTransportationQuoteDetail = (
        transportation_quote_detail_id: number,
        route_segment_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.SHOW_RS_TO_TRANSPORTATION_QUOTE_DETAIL.replace(
                ':transportation_quote_detail_id',
                transportation_quote_detail_id.toString()
            ).replace(':id', route_segment_id.toString()),
            setFetching: SetFetchingShowRsToTransportationQuoteDetail
        });
    };

    const showRsToTransportationOrderItem = (
        transportation_order_item_id: number,
        route_segment_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.SHOW_RS_TO_TRANSPORTATION_ORDER_ITEM.replace(
                ':transportation_order_item_id',
                transportation_order_item_id.toString()
            ).replace(':id', route_segment_id.toString()),
            setFetching: SetFetchingShowRsToTransportationOrderItem
        });
    };

    const createQuoteRouteSegment = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.CREATE_QUOTE_ROUTE_SEGMENT,
            setFetching: setFetchingCreateQuoteRouteSegment
        });
    };

    const storeQuoteRouteSegment = (
        quoteRouteSegmentDataForm: QuoteRouteSegmentDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.STORE_QUOTE_ROUTE_SEGMENT,
            body: quoteRouteSegmentDataForm,
            setFetching: setFetchingStoreQuoteRouteSegment
        });
    };

    const editQuoteRouteSegment = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.EDIT_QUOTE_ROUTE_SEGMENT.replace(':id', id.toString()),
            setFetching: setFetchingEditQuoteRouteSegment
        });
    };

    const updateQuoteRouteSegment = (
        id: number,
        routeSegmentDataFrom: QuoteRouteSegmentDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.UPDATE_QUOTE_ROUTE_SEGMENT.replace(':id', id.toString()),
            body: routeSegmentDataFrom,
            setFetching: setFetchingUpdateQuoteRouteSegment
        });
    };

    const deleteQuoteRouteSegment = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.DELETE_QUOTE_ROUTE_SEGMENT.replace(':id', id.toString()),
            setFetching: setFetchingDeleteQuoteRouteSegment
        });
    };
    const showQuoteRouteSegment = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.SHOW_QUOTE_ROUTE_SEGMENT.replace(':id', id.toString()),
            setFetching: setFetchingShowQuoteRouteSegment
        });
    };
    const getQuoteRouteSegment = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.ROUTE_SEGMENTS.GET_QUOTE_ROUTE_SEGMENT.replace(':id', id.toString()),
            setFetching: setFetchingGetQuoteRouteSegment
        });
    };

    const getUrlRouteSegmentFilters = (
        url: URL,
        route_segment_id: number | null = null,
        origin: string | null = null,
        destiny: string | null = null,
        start_date: string | null = null,
        end_date: string | null = null
    ) => {
        if (route_segment_id) {
            url.searchParams.append('id', String(route_segment_id));
        }

        if (start_date) {
            url.searchParams.append('start_date', start_date);
        }
        if (end_date) {
            url.searchParams.append('end_date', end_date);
        }

        if (origin) {
            url.searchParams.append('origin', String(origin));
        }
        if (destiny) {
            url.searchParams.append('destiny', String(destiny));
        }
        return url;
    };

    return {
        fetchingGetQuoteRouteSegments,
        fetchingShowRsToQuoteRouteSegment,
        fetchingShowRsToTransportationQuoteDetail,
        fetchingShowRsToTransportationOrderItem,
        fetchingCreateQuoteRouteSegment,
        fetchingStoreQuoteRouteSegment,
        fetchingEditQuoteRouteSegment,
        fetchingUpdateQuoteRouteSegment,
        fetchingDeleteQuoteRouteSegment,
        fetchingShowQuoteRouteSegment,
        fetchingGetQuoteRouteSegment,
        getQuoteRouteSegments,
        showRsToQuoteRouteSegment,
        showRsToTransportationQuoteDetail,
        showRsToTransportationOrderItem,
        createQuoteRouteSegment,
        storeQuoteRouteSegment,
        editQuoteRouteSegment,
        updateQuoteRouteSegment,
        deleteQuoteRouteSegment,
        showQuoteRouteSegment,
        getQuoteRouteSegment
    };
};

export default useQuoteRouteSegmentService;
