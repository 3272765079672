import React, { useContext, useEffect, useRef, useState } from 'react';
import { defaultProvider, Provider } from '../../../app/models/Provider';
import useProviderService from '../../../app/services/hooks/useProviderService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import useSweetAlert from '../../../hooks/useSweetAlert';
import { Place } from '../../../app/models/Place';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import {
    RouteSegment,
    RouteSegmentDataForm,
    defaultRouteSegmentDataForm
} from '../../../app/models/RouteSegment';
import useRouteSegmentService from '../../../app/services/hooks/useRouteSegmentService';
import RouteSegmentFormContainer from './RouteSegmentFormContainer';
import { toast } from 'react-toastify';
import QuoteRouteSegmentFormContainer from './QuoteRouteSegmentFormContainer';
import {
    QuoteRouteSegmentDataForm,
    defaultQuoteRouteSegmentDataForm
} from '../../../app/models/QuoteRouteSegment';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    quoteRouteSegmentIdEdit: number;
    quoteId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const QuoteRouteSegmentEdit = ({
    quoteRouteSegmentIdEdit,
    quoteId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const {
        fetchingEditQuoteRouteSegment,
        editQuoteRouteSegment,
        fetchingUpdateQuoteRouteSegment,
        updateQuoteRouteSegment,
        fetchingShowRsToQuoteRouteSegment,
        showRsToQuoteRouteSegment
    } = useQuoteRouteSegmentService();

    const [units, setUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [errorGetData, setErrorGetData] = useState('');
    const [routeSegments, setRouteSegments] = useState<RouteSegment[]>([]);
    const routeSegmentIdSelected = useRef<number | undefined>(0);

    const [quoteRouteSegment, setQuoteRouteSegment] = useState<QuoteRouteSegmentDataForm>({
        ...defaultQuoteRouteSegmentDataForm,
        ['quote_id']: quoteId,
        ['route_segment_id']: undefined,
        ['agreed_distance']: 0
    });

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando opción de ruta...');
        editQuoteRouteSegment(quoteRouteSegmentIdEdit, {
            onSuccess: (response: ServiceResponse) => {
                setUnits(response.data.units);
                setCurrencies(response.data.currencies);
                setRouteSegments(response.data.route_segments);
                routeSegmentIdSelected.current = response.data.quote_route_segment.route_segment_id;
                setQuoteRouteSegment(response.data.quote_route_segment);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorGetData(response.message);
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (quoteRouteSegment.route_segment_id == undefined) {
            routeSegmentIdSelected.current = undefined;
        }

        if (
            quoteRouteSegment.route_segment_id &&
            quoteRouteSegment.route_segment_id != routeSegmentIdSelected.current
        ) {
            showRsToQuoteRouteSegment(quoteRouteSegmentIdEdit, quoteRouteSegment.route_segment_id, {
                onSuccess: (response: ServiceResponse) => {
                    setErrorFields(undefined);
                    routeSegmentIdSelected.current = quoteRouteSegment.route_segment_id ?? 0;
                    if (response.data.instance_data) {
                        setQuoteRouteSegment({
                            ...quoteRouteSegment,
                            ...defaultQuoteRouteSegmentDataForm,
                            agreed_distance: response.data.instance_data.agreed_distance,
                            distance: response.data.instance_data.agreed_distance,
                            rate: response.data.instance_data.rate,
                            currency_id: response.data.instance_data.currency_id,
                            unit_id: response.data.instance_data.unit_id,
                            rate_runner: response.data.instance_data.rate_runner,
                            currency_rate_runner_id:
                                response.data.instance_data.currency_rate_runner_id,
                            quantity_unit_rate_runner_id:
                                response.data.instance_data.quantity_unit_rate_runner_id,
                            external_rate: response.data.instance_data.external_rate,
                            currency_external_rate_id:
                                response.data.instance_data.currency_external_rate_id,
                            unit_external_rate_id: response.data.instance_data.unit_external_rate_id
                        });
                    } else {
                        setQuoteRouteSegment({
                            ...quoteRouteSegment,
                            ...defaultQuoteRouteSegmentDataForm,
                            ...response.data.route_segment
                        });
                    }
                },
                onError: () => {
                    setQuoteRouteSegment({
                        ...quoteRouteSegment,
                        route_segment_id: routeSegmentIdSelected.current
                    });
                }
            });
        }
    }, [quoteRouteSegment.route_segment_id]);

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando opción de ruta...');

        updateQuoteRouteSegment(quoteRouteSegmentIdEdit, quoteRouteSegment, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditQuoteRouteSegment || fetchingShowRsToQuoteRouteSegment ? (
        <LazyLoading height="300" />
    ) : (
        <QuoteRouteSegmentFormContainer
            fetching={fetchingUpdateQuoteRouteSegment}
            action={update}
            cancel={cancel}
            quoteRouteSegmentForm={quoteRouteSegment}
            setQuoteRouteSegmentForm={setQuoteRouteSegment}
            routeSegments={routeSegments}
            currencies={currencies}
            units={units}
            errorFields={errorFields}
        />
    );
};

export default QuoteRouteSegmentEdit;
