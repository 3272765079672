import { TravelExpenseDataForm } from '../../../app/models/TravelExpense';
import { momentParseDateToDate } from '../../../helpers';

export const selectTravelExpenseById = (
    list: TravelExpenseDataForm[],
    searchedId: number | undefined,
    startDate: Date | string | undefined
): TravelExpenseDataForm[] | null => {
    if (searchedId == undefined) return null;
    if (startDate == undefined) return null;

    const filteredTravelExpensesByIds = list.filter((obj) =>
        obj?.event_type_ids?.includes(searchedId)
    );

    const filteredTravelExpenses = filteredTravelExpensesByIds.filter((travelExpense) => {
        const traExStDate = momentParseDateToDate(travelExpense.start_date);
        const traExEndDate = momentParseDateToDate(travelExpense.end_date);
        const caEventStDate = momentParseDateToDate(startDate);

        if (caEventStDate && traExStDate && traExEndDate) {
            if (caEventStDate >= traExStDate && caEventStDate < traExEndDate) {
                return travelExpense;
            }
        }
    });

    return filteredTravelExpenses;
};
