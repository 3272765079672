import React, { useEffect } from 'react';
import { CarrierDataForm } from '../../../../app/models/Carrier';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useRut from '../../../../hooks/useRut';
import { User } from '../../../../app/models/User';
import { Society } from '../../../../app/models/Society';
import { Provider } from '../../../../app/models/Provider';
import { Brand } from '../../../../app/models/Brand';
import { VehicleModel } from '../../../../app/models/VehicleModel';
import { TruckDataForm } from '../../../../app/models/Truck';
import { DocumentSet } from '../../../../app/models/DocumentSet';

interface GeneralTruckProps {
    hopperForm: TruckDataForm;
    setHopperForm: (truck: TruckDataForm) => void;
    societies: Society[];
    providers: Provider[];
    brands: Brand[];
    documentSets: DocumentSet[];
    vehicleModels: VehicleModel[];
    isEdit?: boolean;
    errorFields?: any;
}

const HopperForm = ({
    hopperForm,
    setHopperForm,
    societies,
    providers,
    brands,
    isEdit,
    vehicleModels,
    documentSets,
    errorFields
}: GeneralTruckProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = event.target;
        console.log(name);
        setHopperForm({ ...hopperForm, [name]: value });
    };

    const handleCheckChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        setHopperForm({ ...hopperForm, is_internal: !hopperForm.is_internal });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setHopperForm({ ...hopperForm, ...obj });
    };

    if (!hopperForm) return null;

    return (
        <>
            <div className="mb-2 row needs-validation">
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="internal"
                            value="internal"
                            checked={hopperForm.is_internal}
                            id="it_internal"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="it_internal">
                            Interno
                        </label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="external"
                            value="external"
                            checked={!hopperForm.is_internal}
                            id="it_external"
                            onChange={handleCheckChange}
                        />
                        <label className="form-check-label" htmlFor="it_external">
                            Externo
                        </label>
                    </div>
                </div>
            </div>

            {hopperForm.is_internal ? (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Sociedades</label>
                        <SelectTwo
                            name="society_id"
                            id="society_id"
                            inputValue={hopperForm.society_id}
                            options={SelectTwoMapperOptions(societies, 'id', ['name'])}
                            hasError={fieldHasError('society_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('society_id', value.value)
                            }
                            placeholder={'Seleccione Sociedad'}
                            onFocus={() => onFocusRemove('society_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('society_id')}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Proveedores</label>
                        <SelectTwo
                            name="provider_id"
                            id="provider_id"
                            inputValue={hopperForm.provider_id}
                            options={SelectTwoMapperOptions(providers, 'id', ['name'])}
                            hasError={fieldHasError('provider_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('provider_id', value.value)
                            }
                            placeholder={'Seleccione Proveedor'}
                            onFocus={() => onFocusRemove('provider_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('provider_id')}
                        </div>
                    </div>
                </div>
            )}

            <div className="col-md-6">
                <label className="col-form-label">Patente</label>
                <input
                    name="patent"
                    id="patent"
                    type="text"
                    className={`form-control ${fieldHasError('patent')}`}
                    value={hopperForm.patent}
                    onChange={handleChange}
                    onFocus={() => onFocusRemove('patent')}
                    placeholder="Ingrese n° patente"
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('patent')}
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Marca</label>
                    <SelectTwo
                        name="brand_id"
                        id="brand_id"
                        inputValue={hopperForm.brand_id}
                        options={SelectTwoMapperOptions(brands, 'id', ['name'])}
                        hasError={fieldHasError('brand_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('brand_id', value.value)}
                        placeholder={'Seleccione Marca'}
                        onFocus={() => onFocusRemove('brand_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('brand_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Modelo</label>
                    <SelectTwo
                        name="vehicle_model_id"
                        id="vehicle_model_id"
                        inputValue={hopperForm.vehicle_model_id}
                        options={SelectTwoMapperOptions(vehicleModels, 'id', ['name'])}
                        hasError={fieldHasError('vehicle_model_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('vehicle_model_id', value.value)
                        }
                        placeholder={'Seleccione Modelo'}
                        onFocus={() => onFocusRemove('vehicle_model_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('vehicle_model_id')}
                    </div>
                </div>
            </div>

            <div className="col-md-12">
                <label className="col-form-label">Año</label>
                <input
                    name={`year`}
                    id={`year`}
                    type="number"
                    min={1000}
                    className={`form-control ${fieldHasError(`year`)}`}
                    value={hopperForm.year}
                    onChange={handleChange}
                    onFocus={() => onFocusRemove(`year`)}
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('year')}
                </div>
            </div>

            <div className="col-md-12">
                <label className="col-form-label">VIN</label>
                <input
                    name="vin"
                    id="vin"
                    type="text"
                    className={`form-control ${fieldHasError('vin')}`}
                    value={hopperForm.vin}
                    onChange={handleChange}
                    onFocus={() => onFocusRemove('vin')}
                    placeholder="Ingrese n° VIN"
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('vin')}
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Set de documentos</label>
                    <SelectTwo
                        name="document_sets"
                        id="document_sets"
                        inputValue={SelectTwoMapperOptions(documentSets)}
                        disabled={true}
                        options={SelectTwoMapperOptions(documentSets)}
                        hasError={fieldHasError('document_sets') !== ''}
                        onChange={(value: any) =>
                            setHopperForm({
                                ...hopperForm,
                                document_sets: value
                            })
                        }
                        isSortable={true}
                        isMulti={true}
                        placeholder={'Seleccione los set de documentos'}
                        onFocus={() => onFocusRemove('document_sets')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('document_sets')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HopperForm;
