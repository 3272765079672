import React from 'react';
import { IconProps } from '../types';

export const Provision: React.FC<IconProps> = ({ size = 22, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            width={size}
            height={size}
            viewBox="0 0 490 490"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g id="XMLID_45_">
                        <g>
                            <path d="M439.976,110c-0.001-2.602-0.992-5.159-2.904-7.071l-100-100c-1.912-1.913-4.47-2.904-7.071-2.904 V0H60c-5.522,0-10,4.477-10,10v470c0,5.523,4.478,10,10,10h370c5.522,0,10-4.477,10-10V110H439.976z M340,34.142L405.857,100H340 V34.142z M70,470V20h250v90c0,5.523,4.478,10,10,10h90v350H70z" />
                            <path d="M125,180.858l-17.929-17.929l-14.143,14.143l25,25C119.882,204.024,122.44,205,125,205 s5.118-0.976,7.071-2.929l50-50l-14.143-14.143L125,180.858z" />
                            <rect x="200" y="150" width="50" height="20" />
                            <rect x="200" y="180" width="110" height="20" />
                            <rect x="265" y="150" width="130" height="20" />
                            <path d="M125,260.858l-17.929-17.929l-14.143,14.143l25,25C119.882,284.024,122.44,285,125,285 s5.118-0.976,7.071-2.929l50-50l-14.143-14.143L125,260.858z" />
                            <rect x="200" y="230" width="50" height="20" />
                            <rect x="200" y="260" width="110" height="20" />
                            <rect x="265" y="230" width="130" height="20" />
                            <path d="M125,340.858l-17.929-17.929l-14.143,14.143l25,25C119.882,364.024,122.44,365,125,365 s5.118-0.976,7.071-2.929l50-50l-14.143-14.143L125,340.858z" />
                            <rect x="200" y="310" width="50" height="20" />
                            <rect x="200" y="340" width="110" height="20" />
                            <rect x="265" y="310" width="130" height="20" />
                            <path d="M125,420.858l-17.929-17.929l-14.143,14.143l25,25C119.882,444.024,122.44,445,125,445 s5.118-0.976,7.071-2.929l50-50l-14.143-14.143L125,420.858z" />
                            <rect x="200" y="390" width="50" height="20" />
                            <rect x="200" y="420" width="110" height="20" />
                            <rect x="265" y="390" width="130" height="20" />
                            <rect x="95" y="45" width="50" height="20" />
                            <rect x="95" y="75" width="105" height="20" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
