import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';

import { VehicleType } from '../../../../app/models/VehicleType';
import { VehicleModelDataForm } from '../../../../app/models/VehicleModel';

type Props = {
    vehicleModelDataForm: VehicleModelDataForm;
    setVehicleModelDataForm: (vehicleModelDataForm: VehicleModelDataForm) => void;
    vehicleTypes: VehicleType[];
    errorFields?: any;
};

const VehicleModelForm = ({
    vehicleModelDataForm,
    setVehicleModelDataForm,
    vehicleTypes,
    errorFields
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setVehicleModelDataForm({ ...vehicleModelDataForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setVehicleModelDataForm({ ...vehicleModelDataForm, ...obj });
    };

    return (
        <>
            <div className="mb-2 row needs-validation">
                <label className="col-md-3 col-form-label">Nombre</label>
                <div className="col-md-9">
                    <input
                        name="name"
                        id="name"
                        type="text"
                        className={`form-control ${fieldHasError('name')}`}
                        value={vehicleModelDataForm.name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('name')}
                        placeholder="Ingrese el nombre de modelo"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Tipo de vehículo</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="vehicle_type"
                        id="vehicle_type"
                        inputValue={vehicleModelDataForm.vehicle_type}
                        options={SelectTwoMapperOptions(vehicleTypes, 'id', ['name'])}
                        hasError={fieldHasError('vehicle_type') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('vehicle_type', value.value)
                        }
                        placeholder={'Seleccione tipo de vehículo'}
                        onFocus={() => onFocusRemove('vehicle_type')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('vehicle_type')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default VehicleModelForm;
