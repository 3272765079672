import moment from 'moment';
import { Bank } from './Bank';
import { BankAccountType } from './BankAccountType';
import { Carrier } from './Carrier';
import { Currency } from './Currency';
import { Expense } from './Expense';
import { MovementType } from './MovementType';
import { TransferReason } from './TransferReason';

export interface CheckingAccount {
    id?: number;
    issue_date?: string;
    carrier?: Carrier;
    worker_id?: number;
    bank?: Bank;
    bank_id?: number;
    transfer_reason?: TransferReason;
    transfer_reason_id?: number;

    account_number?: string;
    account_type?: BankAccountType;
    account_type_id?: number;
    amount?: number;
    currency?: Currency;
    currency_id?: number;
    is_active?: boolean;
    movement_type?: MovementType;
    expenses?: Expense[];
    description?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
    file?: File;
}

export interface CheckingAccountFilters {
    page: number;
    per_page: number;
    sort: string;
    order: string;
    issue_date_first?: string | '';
    issue_date_last?: string | '';
    carrier?: string | '';
    transfer_reason?: string | '';

}

export interface CheckingAccountDataForm {
    id?: number;
    issue_date?: string;
    worker_id?: number;
    carrier?: string;
    bank?: string;
    bank_id?: number;

    transfer_reason_id?: number;

    transfer_reason?: string;
    account_number?: string;
    account_type?: string;
    account_type_id?: number;
    amount?: number;
    currency_id?: number;
    currency?: string;
    expenses?: Expense[];
    expenses_ids?: number[];
    movement_type?: string;
    description?: string;
    file?: File;
    file_url?: string;
}

export const defaultCheckingAccountData: CheckingAccountDataForm = {
    bank_id: undefined,
    transfer_reason_id: undefined,
    account_type_id: undefined,
    account_number: '',
    expenses: undefined
};
