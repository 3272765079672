import React from 'react';

import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Provider } from '../../../app/models/Provider';
import { RouteSegment, RouteSegmentDataForm } from '../../../app/models/RouteSegment';
import { Place } from '../../../app/models/Place';
import { Currency } from '../../../app/models/Currency';
import { Unit } from '../../../app/models/Unit';
import RouteSegmentForm from './forms/RouteSegmentForm';
import { QuoteRouteSegmentDataForm } from '../../../app/models/QuoteRouteSegment';
import QuoteRouteSegmentForm from './forms/QuoteRouteSegmentForm';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    quoteRouteSegmentForm: QuoteRouteSegmentDataForm;
    setQuoteRouteSegmentForm: (quoteRouteSegment: QuoteRouteSegmentDataForm) => void;
    routeSegments: RouteSegment[];
    currencies: Currency[];
    units: Unit[];
    errorFields?: any;
}

const QuoteRouteSegmentFormContainer = ({
    fetching,
    action,
    cancel,
    quoteRouteSegmentForm,
    setQuoteRouteSegmentForm,
    routeSegments,
    currencies,
    units,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <QuoteRouteSegmentForm
                        quoteRouteSegmentForm={quoteRouteSegmentForm}
                        setQuoteRouteSegmentForm={setQuoteRouteSegmentForm}
                        routeSegments={routeSegments}
                        currencies={currencies}
                        units={units}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default QuoteRouteSegmentFormContainer;
