import React, { useContext, useEffect, useState } from 'react';
import useHolidayService from '../../../app/services/hooks/useHolidayService';
import LazyLoading from '../../../components/LazyLoading';

import { HolidayDataForm } from '../../../app/models/Holiday';
import HolidayFormContainer from './HolidayFormContainer';
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';

interface Props {
    holidayId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const HolidayEdit = ({ holidayId, onSaved, onCancel, onError }: Props) => {
    const { fetchingEditHoliday, editHoliday, fetchingUpdateHoliday, updateHoliday } =
        useHolidayService();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [errorFields, setErrorFields] = useState<any>();
    const [holiday, setHoliday] = useState<HolidayDataForm>({ name: '' });

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando feriado...');
        editHoliday(holidayId, {
            onSuccess: (response: ServiceResponse) => {
                setHoliday(response.data.holiday);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando feriado...');
        updateHoliday(holidayId, holiday, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditHoliday ? (
        <LazyLoading height="300" />
    ) : (
        <HolidayFormContainer
            fetching={fetchingUpdateHoliday}
            action={update}
            cancel={cancel}
            holiday={holiday}
            setHoliday={setHoliday}
            errorFields={errorFields}
        />
    );
};

export default HolidayEdit;
