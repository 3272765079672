import React, { useContext, useEffect, useState } from 'react';
import useJobTitleService from '../../../../app/services/hooks/useJobTitleService';
import { defaultJobTitle, JobTitleDataForm } from '../../../../app/models/JobTitle';
import { AppContext } from '../../../../contexts/AppContext';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import JobTitleFormContainer from '../JobTitleFormContainer';
import LazyLoading from '../../../../components/LazyLoading';
import { DefaultGroup, Group, GroupDataForm } from '../../../../app/models/Group';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const JobTitleCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingStoreJobTitle, storeJobTitle, createJobTitle, fetchingCreateJobTitle } =
        useJobTitleService();
    const [groups, setGroups] = useState<Group[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
    const [jobTitle, setJobTitle] = useState<JobTitleDataForm>(defaultJobTitle);

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        createJobTitle({
            onSuccess: (response: ServiceResponse) => {
                setGroups(response.data.groups);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando puesto de trabajo...');
        storeJobTitle(jobTitle, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateJobTitle ? (
        <LazyLoading height={300} />
    ) : (
        <JobTitleFormContainer
            fetching={fetchingStoreJobTitle}
            action={store}
            cancel={cancel}
            jobTitle={jobTitle}
            groups={groups}
            setJobTitle={setJobTitle}
            errorFields={errorFields}
        />
    );
};

export default JobTitleCreate;
