import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';

import { ServiceEvents, ServiceEventsDocuments } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { MonthlyReconociliationFilters } from '../../models/MonthlyReconciliation';

const useMonthlyReconciliationService = () => {
    const { doGet, doPost, doPut, doDelete, doGetDocument } = useFetch();

    const [fetchingGetMonthlyReconciliations, setFetchingGetMonthlyReconciliations] =
        useState(false);
    const [fetchingGetExcelMonthlyReconciliations, setFetchingGetExcelMonthlyReconciliations] =
        useState(false);

    const getMonthlyReconciliations = (
        MonthlyReconciliationFilters: MonthlyReconociliationFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(MonthlyReconciliationFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.MONTHLY_RECONCILIATIONS.GET_MONTHLY_RECONCILIATIONS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetMonthlyReconciliations
        });
    };

    const getExcelAllMonthlyReconciliations = (
        MonthlyReconciliationFilters: MonthlyReconociliationFilters,
        events: ServiceEventsDocuments = {}
    ) => {
        // const {showLoading, hideLoading, changeAnimation} = useContext(AppContext);
        const queryString = Object.entries(MonthlyReconciliationFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.MONTHLY_RECONCILIATIONS.GET_EXCEL_MONTHLY_RECONCILIATIONS}?${queryString}`;

        doGetDocument({
            ...events,
            url: url,
            successData: {
                nameDocument: 'cuadre_mensual.xlsx',
                message: 'Descarga exitosa.'
            },
            setFetching: setFetchingGetExcelMonthlyReconciliations
        });
    };

    return {
        fetchingGetMonthlyReconciliations,
        fetchingGetExcelMonthlyReconciliations,
        getMonthlyReconciliations,
        getExcelAllMonthlyReconciliations
    };
};

export default useMonthlyReconciliationService;
