import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import { UserDataForm, defaultUserDataForm } from '../../../app/models/User';
import useUserService from '../../../app/services/hooks/useUserService';
import UserFormContainer from './UserFormContainer';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { Bank } from '../../../app/models/Bank';
import { Provision } from '../../../app/models/Provision';
import { Afp } from '../../../app/models/Afp';
import { Group } from '../../../app/models/Group';

interface Props {
    userId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const UserEdit = ({ userId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditUser, editUser, fetchingUpdateUser, updateUser } = useUserService();

    const [afps, setAfps] = useState<Afp[]>([]);
    const [provisions, setProvisions] = useState<Provision[]>([]);
    const [banks, setBanks] = useState<Bank[]>([]);
    const [bankAccountTypes, setBankAccountTypes] = useState<BankAccountType[]>([]);
    const [user, setUser] = useState<UserDataForm>(defaultUserDataForm);
    const [groups, setGroups] = useState<Group[]>([]);
    const [jobTitleGroups, setJobTitleGroups] = useState<[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    useEffect(() => {
        console.log(user);
    }, [user]);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando usuario...');
        editUser(userId, {
            onSuccess: (response: ServiceResponse) => {
                setAfps(response.data.afps);
                setProvisions(response.data.previsions);
                setBanks(response.data.banks);
                setBankAccountTypes(response.data.bank_account_types);
                setGroups(response.data.groups);
                setJobTitleGroups(response.data.job_title_groups);

                const _user = response.data.user;
                _user.groups = response.data.user.groups.map((group: any) => ({
                    value: group.id,
                    label: group.name
                }));
                _user.jobTitles = response.data.job_title_groups.map((group: any) => ({
                    value: group.id,
                    label: group.name
                }));

                setUser(_user);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando usuario...');
        updateUser(userId, user, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditUser ? (
        <LazyLoading height="300" />
    ) : (
        <UserFormContainer
            fetching={fetchingUpdateUser}
            action={update}
            cancel={cancel}
            userForm={user}
            setUserForm={setUser}
            afps={afps}
            groups={groups}
            jobTitles={jobTitleGroups}
            provisions={provisions}
            bankAccountTypes={bankAccountTypes}
            banks={banks}
            errorFields={errorFields}
        />
    );
};

export default UserEdit;
