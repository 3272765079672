import React, { useContext, useEffect, useState } from 'react';
import { Place } from '../../app/models/Place';
import { ServiceEventsDocuments, ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import { FuelConsumption, FuelConsumptionFilters } from '../../app/models/FuelConsumption';
import useFuelConsumptionService from '../../app/services/hooks/useFuelConsumptionService';
import FuelConsumptionDataTable from './components/tables/FuelConsumptionDataTable';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Consumo combustible',
        url: '/fuel-consumptions',
        isActive: true
    }
];

const FuelConsumptions = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetFuelConsumptions, getFuelConsumptions, getExcelAllFuelConsumptions } =
        useFuelConsumptionService();
    const [fuelConsumptions, setFuelConsumptions] = useState<FuelConsumption[]>([]);

    const [fuelConsumptionIdEdit, setFuelConsumptionIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);
    const { navigationPage } = useNavigationPage();

    const [fuelConsumptionFilter, setfuelConsumptionFilter] = useState<FuelConsumptionFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc',
        charge_date_from: moment().date(1).format('YYYY-MM-DD'),
        charge_date_until: moment().format('YYYY-MM-DD')
    });

    useEffect(() => {
        reloadTable();
    }, []);

    const getAllFuelConsumptions = () => {
        getFuelConsumptions(fuelConsumptionFilter, {
            onSuccess: (response: ServiceResponse) => {
                setFuelConsumptions(response.data.fuel_consumptions);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (fuelConsumptionId: number) => {
        setShowingEdit(true);
        setFuelConsumptionIdEdit(fuelConsumptionId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setFuelConsumptionIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllFuelConsumptions();
    };

    const downloadExcel = () => {
        if (showLoading) showLoading('download', 'Recuperando documento...');
        getExcelAllFuelConsumptions(fuelConsumptionFilter, {
            onSuccess: (message: string) => {
                if (changeAnimation) changeAnimation('downloadSuccess', message, true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Consumo combustible" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <FuelConsumptionDataTable
                    fuelConsumptions={fuelConsumptions}
                    loading={fetchingGetFuelConsumptions}
                    filter={fuelConsumptionFilter}
                    setFilter={setfuelConsumptionFilter}
                    totalRows={totalRows}
                    downloadExcel={downloadExcel}
                    onSearch={reloadTable}
                />
            </DefaultCard>
        </>
    );
};

export default FuelConsumptions;
