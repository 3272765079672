import React, { useEffect, useRef } from 'react';
import { Carrier, CarrierDocument } from '../app/models/Carrier';
import InputButtonFile from '../components/inputs/InputButtonFile';
import useHandleErrorFields from '../hooks/useHandleErrorFields';
import { TruckDocument } from '../app/models/Truck';
import { HopperDocument } from '../app/models/Hopper';
import { SocietyDocument } from '../app/models/Society';

type Props = {
    document: CarrierDocument | TruckDocument | HopperDocument | SocietyDocument;
    setDocument: (
        document: CarrierDocument | TruckDocument | HopperDocument | SocietyDocument
    ) => void;
    typeDocument: string;
    errorFields?: any;
};

const GeneralDocumentTransporterForm = ({
    typeDocument,
    document,
    setDocument,
    errorFields
}: any) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleFileChange = (event: FileList | null, type: string) => {
        if (event) {
            const file = event[0];
            setDocument({
                ...document,
                file: file,
                name: file.name
            });
        }
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value, type } = event.target;
        if (type === 'checkbox') {
            event.target = event.target as HTMLInputElement;
            if (event.target.checked) {
                setDocument({ ...document, [name]: event.target.checked, expire_date: '' });
            } else {
                setDocument({ ...document, [name]: event.target.checked });
            }
        } else {
            if (name === 'expire_date') {
                setDocument({ ...document, [name]: value, is_expire_date_indefinite: false });
            } else {
                setDocument({ ...document, [name]: value });
            }
        }
    };

    return (
        <div className="mb-2 row">
            <label className="col-md-3 col-form-label">Archivo</label>
            <div className="col-md-9 ">
                <InputButtonFile
                    name={'file'}
                    handleChangeDocumentFile={handleFileChange}
                    objectFile={document?.file}
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('file')}
                </div>
            </div>
            <label className="col-md-3 col-form-label">Fecha de emisión</label>
            <div className="col-md-9 ">
                <input
                    name={`issue_date`}
                    id={`issue_date`}
                    type="date"
                    className={`form-control ${fieldHasError(`issue_date`)}`}
                    value={document?.issue_date}
                    onChange={handleChange}
                    onFocus={() => onFocusRemove(`issue_date`)}
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage(`issue_date`)}
                </div>
            </div>
            <label className="col-md-3 col-form-label">Fecha de vencimiento</label>
            <div className="col-md-9 ">
                <input
                    name={`expiration_date`}
                    id={`expiration_date`}
                    type="date"
                    className={`form-control ${fieldHasError(`expiration_date`)}`}
                    value={document?.expiration_date}
                    onChange={handleChange}
                    onFocus={() => onFocusRemove(`expiration_date`)}
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage(`expiration_date`)}
                </div>
            </div>

            <div className="col-md-9 offset-md-3">
                <div className="col-md-3 form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name={`is_expire_date_indefinite`}
                        id={`is_expire_date_indefinite`}
                        checked={document?.is_expire_date_indefinite}
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="indefinite">
                        Indefinido
                    </label>
                </div>
            </div>
        </div>
    );
};

export default GeneralDocumentTransporterForm;
