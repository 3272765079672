import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { Deduction, DeductionFilters } from '../../models/Deduction';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useDeductionService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetDeductions, setFetchingGetDeductions] = useState(false);
    const [fetchingDeleteDeduction, setFetchingDeleteDeduction] = useState(false);
    const [fetchingActiveDeduction, setFetchingActiveDeduction] = useState(false);
    const [fetchingStoreDeduction, setFetchingStoreDeduction] = useState(false);
    const [fetchingCreateDeduction, setFetchingCreateDeduction] = useState(false);
    const [fetchingEditDeduction, setFetchingEditDeduction] = useState(false);
    const [fetchingUpdateDeduction, setFetchingUpdateDeduction] = useState(false);

    const getDeductions = (DeductionFilters: DeductionFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(DeductionFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.DEDUCTIONS.GET_DEDUCTIONS}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetDeductions
        });
    };

    const createDeduction = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DEDUCTIONS.CREATE_DEDUCTIONS,
            setFetching: setFetchingCreateDeduction
        });
    };

    const storeDeduction = (Deduction: Deduction, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.DEDUCTIONS.STORE_DEDUCTIONS,
            body: Deduction,
            setFetching: setFetchingStoreDeduction
        });
    };

    const deleteDeduction = (DeductionId: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.DEDUCTIONS.DELETE_DEDUCTIONS.replace(':id', DeductionId.toString()),
            setFetching: setFetchingDeleteDeduction
        });
    };

    const activeDeduction = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.DEDUCTIONS.ACTIVE_DEDUCTIONS.replace(':id', id.toString()),
            body: { active: active },
            setFetching: setFetchingActiveDeduction
        });
    };

    const editDeduction = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DEDUCTIONS.EDIT_DEDUCTIONS.replace(':id', id.toString()),
            setFetching: setFetchingEditDeduction
        });
    };

    const updateDeduction = (id: number, deduction: Deduction, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.DEDUCTIONS.UPDATE_DEDUCTIONS.replace(':id', id.toString()),
            body: deduction,
            setFetching: setFetchingUpdateDeduction
        });
    };

    return {
        fetchingGetDeductions,
        fetchingActiveDeduction,
        fetchingDeleteDeduction,
        fetchingCreateDeduction,
        fetchingStoreDeduction,
        fetchingEditDeduction,
        fetchingUpdateDeduction,
        storeDeduction,
        createDeduction,
        activeDeduction,
        getDeductions,
        deleteDeduction,
        editDeduction,
        updateDeduction
    };
};

export default useDeductionService;
