import React, { useEffect } from 'react';
import { ClientDataForm } from '../../../../app/models/Client';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { BankAccountType } from '../../../../app/models/BankAccountType';
import { Bank } from '../../../../app/models/Bank';
import { Provision } from '../../../../app/models/Provision';
import { Afp } from '../../../../app/models/Afp';
import { countriesOptions, nationalityOptions } from '../../../../utils/utils';
import useRut from '../../../../hooks/useRut';
import { User } from '../../../../app/models/User';
interface GeneralClientProps {
    clientForm: ClientDataForm;
    setClientForm: (client: ClientDataForm) => void;
    users: User[];
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
}

const ClientForm = ({
    clientForm,
    setClientForm,
    users,
    errorFields,
    setErrorFields
}: GeneralClientProps) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value, type } = event.target;
        if (type === 'checkbox') {
            event.target = event.target as HTMLInputElement;
            setClientForm({ ...clientForm, [name]: event.target.checked });
        } else {
            if (name == 'rut') {
                setClientForm({ ...clientForm, [name]: formatRut(value) });
            } else {
                setClientForm({ ...clientForm, [name]: value });
            }
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if (name === 'user_id') {
            const user = users.find((user) => user.id === value);
            if (user) {
                obj['first_name_contact'] = user.first_name ?? '';
                obj['last_name_contact'] = user.last_name ?? '';
                obj['email_contact'] = user.email ?? '';
                obj['phone_contact'] = user.phone ?? '';
                obj['phone_code_contact'] = user.phone_code ?? '';
                obj['second_last_name_contact'] = user.second_last_name ?? '';
                if (setErrorFields)
                    setErrorFields({
                        ...errorFields,
                        first_name_contact: '',
                        last_name_contact: '',
                        email_contact: '',
                        phone_contact: '',
                        second_last_name_contact: ''
                    });
            }
        }
        setClientForm({ ...clientForm, ...obj });
    };

    if (!clientForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">RUT</label>
                    <input
                        name="rut"
                        id="rut"
                        type="text"
                        className="form-control"
                        value={clientForm.rut}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('rut')}
                        placeholder="Ingrese el RUT"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('rut')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Razon Social</label>
                    <input
                        name="business_name"
                        id="business_name"
                        type="text"
                        className={`form-control ${fieldHasError('business_name')}`}
                        value={clientForm.business_name}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('business_name')}
                        placeholder="Ingrese el nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('business_name')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Pais</label>
                    <SelectTwo
                        name="country"
                        id="country"
                        inputValue={clientForm.country}
                        options={SelectTwoMapperOptions(countriesOptions, 'value', ['label'])}
                        hasError={fieldHasError('country') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('country', value?.value)}
                        placeholder={'Seleccione Pais'}
                        onFocus={() => onFocusRemove('country')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('country')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label"> Dirección </label>
                    <input
                        name="address"
                        id="address"
                        type="text"
                        className={`form-control ${fieldHasError('address')}`}
                        value={clientForm.address}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('address')}
                        placeholder="Ingrese la dirección"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('address')}
                    </div>
                </div>
            </div>

            <hr />
            <h5 className="mb-3">Datos de Contacto</h5>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Usuario</label>
                    <SelectTwo
                        name="user_id"
                        id="user_id"
                        inputValue={clientForm.user_id}
                        options={SelectTwoMapperOptions(users, 'id', ['name'])}
                        hasError={fieldHasError('user_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('user_id', value?.value)}
                        placeholder={'Seleccione Usuario'}
                        onFocus={() => onFocusRemove('user_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('user_id')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Nombre</label>
                    <input
                        name="first_name_contact"
                        id="first_name_contact"
                        type="text"
                        className={`form-control ${fieldHasError('first_name_contact')}`}
                        value={clientForm.first_name_contact}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('first_name_contact')}
                        placeholder="Ingrese el nombre"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('first_name_contact')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Email</label>

                    <input
                        name="email_contact"
                        id="email_contact"
                        type="email"
                        className={`form-control ${fieldHasError('email_contact')}`}
                        value={clientForm.email_contact}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('email_contact')}
                        placeholder="Ingrese el email"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('email_contact')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <div className="col-md-6">
                    <label className="col-form-label">Apellido Paterno</label>
                    <input
                        name="last_name_contact"
                        id="last_name_contact"
                        type="text"
                        className={`form-control ${fieldHasError('last_name_contact')}`}
                        value={clientForm.last_name_contact}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('last_name_contact')}
                        placeholder="Ingrese el apellido paterno"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('last_name_contact')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Apellido Materno</label>

                    <input
                        name="second_last_name_contact"
                        id="second_last_name_contact"
                        type="text"
                        className={`form-control ${fieldHasError('second_last_name_contact')}`}
                        value={clientForm.second_last_name_contact}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('second_last_name_contact')}
                        placeholder="Ingrese el apellido materno"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('second_last_name_contact')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row needs-validation">
                <div className="col-md-12 ">
                    <label className=" col-form-label">Teléfono</label>
                    <div className="row">
                        <div className="col-auto">
                            <select
                                className="form-control form-control-custom pl-2"
                                id="phone_code_contact"
                                name="phone_code_contact"
                                onChange={handleChange}
                                value={clientForm.phone_code_contact}
                                onFocus={() => onFocusRemove('phone_code')}
                            >
                                <option defaultValue={'+56'} value="+56">
                                    +56
                                </option>
                            </select>
                        </div>

                        <div className="col">
                            <input
                                name="phone_contact"
                                id="phone_contact"
                                type="number"
                                className="form-control"
                                value={clientForm.phone_contact}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('phone_contact')}
                                placeholder="Teléfono"
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('phone_contact')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientForm;
