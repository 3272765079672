import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import useGuideHistoryService from '../../../app/services/hooks/useGuideHistoryService';
import { GuideHistoryDataForm, GuideHistoryFilter } from '../../../app/models/GuideHistory';
import GuideHistoryDataTable from './tables/GuideHistoryDataTable';
import GuideHistoryEdit from './GuideHistoryEdit';

interface Props {
    guideId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const GuideHistoryContainer = ({ guideId, onSaved, onCancel, onError }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { getAllGuidehistoriesByGuide, fetchingGetGuideHistoriesByGuide } =
        useGuideHistoryService();

    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);

    const [guideHistoryIdEdit, setGuideHistoryIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [guideHistories, setGuideHistories] = useState<GuideHistoryDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<GuideHistoryFilter>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const showEdit = (guideHistoryId: number) => {
        setShowingEdit(true);
        setGuideHistoryIdEdit(guideHistoryId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setGuideHistoryIdEdit(-1);
    };

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getAllGuidehistoriesByGuide(filter, guideId, {
            onSuccess: (response: ServiceResponse) => {
                setGuideHistories(response.data.guide_histories);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    if (onError) onError();
                }
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <GuideHistoryDataTable
                            guideHistories={guideHistories}
                            edit={showEdit}
                            loading={fetchingGetGuideHistoriesByGuide}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                        />
                    </div>
                </div>
            </DefaultCard>

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar información"
                    backdrop={true}
                    showFooter={false}
                    centered={true}
                >
                    <GuideHistoryEdit
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                        guideHistoryId={guideHistoryIdEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default GuideHistoryContainer;
