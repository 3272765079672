import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import useFetch from '../../../hooks/useFetch';
import { EndPoints } from '../EndPoints';

const useWorkOrderStatusHistoryService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [
        fetchingGetWorkOrderStatusHistoriesByWorkOrder,
        setFetchingGetWorkOrderStatusHistoriesByWorkOrder
    ] = useState(false);

    const getAllWorkOrderStatusHistoriesByWorkOrder = (
        work_order_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.WORK_ORDER_STATUS_HISTORIES.GET_WORK_ORDER_STATUS_HISTORIES_BY_WORK_ORDER.replace(
                ':work_order_id',
                work_order_id.toString()
            ),
            setFetching: setFetchingGetWorkOrderStatusHistoriesByWorkOrder
        });
    };

    return {
        getAllWorkOrderStatusHistoriesByWorkOrder,
        fetchingGetWorkOrderStatusHistoriesByWorkOrder
    };
};

export default useWorkOrderStatusHistoryService;
