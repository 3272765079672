import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import useNavigationPage from '../../../hooks/useNavigationPage';
import useExpenseHistoryService from '../../../app/services/hooks/useExpenseHistoryService';
import { ExpenseHistoryDataForm, ExpenseHistoryFilter } from '../../../app/models/ExpenseHistory';
import ExpenseHistoryDataTable from './tables/ExpenseHistoryDataTable';
import ExpenseHistoryEdit from './ExpenseHistoryEdit';

interface Props {
    expenseId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ExpenseHistoryContainer = ({ expenseId, onSaved, onCancel, onError }: Props) => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { getAllExpensehistoriesByExpense, fetchingGetExpenseHistoriesByExpense } =
        useExpenseHistoryService();

    const { navigationPage } = useNavigationPage();

    const [showingCreate, setShowingCreate] = useState(false);

    const [expenseHistoryIdEdit, setExpenseHistoryIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [expenseHistories, setExpenseHistories] = useState<ExpenseHistoryDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<ExpenseHistoryFilter>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const showEdit = (expenseHistoryId: number) => {
        setShowingEdit(true);
        setExpenseHistoryIdEdit(expenseHistoryId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setExpenseHistoryIdEdit(-1);
    };

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getAllExpensehistoriesByExpense(filter, expenseId, {
            onSuccess: (response: ServiceResponse) => {
                setExpenseHistories(response.data.expense_histories);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    if (onError) onError();
                }
            }
        });
    };

    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <ExpenseHistoryDataTable
                            expenseHistories={expenseHistories}
                            edit={showEdit}
                            loading={fetchingGetExpenseHistoriesByExpense}
                            totalRows={totalRows}
                            filter={filter}
                            setFilter={setFilter}
                        />
                    </div>
                </div>
            </DefaultCard>

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar información"
                    backdrop={true}
                    showFooter={false}
                    centered={true}
                >
                    <ExpenseHistoryEdit
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                        expenseHistoryId={expenseHistoryIdEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default ExpenseHistoryContainer;
