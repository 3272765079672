import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ActiveControl from '../../../../components/ActiveControl';

import { Bank, BankFilters } from '../../../../app/models/Bank';
import { FuelConsumption, FuelConsumptionFilters } from '../../../../app/models/FuelConsumption';
import { momentParseDate } from '../../../../helpers';
import { formatAmount } from '../../../../utils/utils';

import FuelConsumptionFilter from './FuelConsumptionFilters';

interface Props {
    fuelConsumptions: FuelConsumption[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: FuelConsumptionFilters;
    setFilter: (filter: FuelConsumptionFilters) => void;
    paginationRowsPerPageOptions?: any[];
    downloadExcel?: () => void;
    onSearch: () => void;
}

const FuelConsumptionDataTable = ({
    fuelConsumptions,
    totalRows,
    loading = false,
    filter,
    setFilter,
    downloadExcel,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Dominio',
            selector: (row: any) => row.holderDomain,
            sortable: true,
            sortField: 'holderDomain',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{row.holderDomain}</span>
            )
        },
        {
            name: 'Documento conductor',
            selector: (row: any) => row.driverDocument,
            sortable: true,
            sortField: 'driverDocument',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{row.driverDocument}</span>
            )
        },
        {
            name: 'Id posición de carga',
            selector: (row: any) => row.chargePositionId,
            sortable: true,
            sortField: 'chargePositionId',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{row.chargePositionId}</span>
            )
        },
        {
            name: 'Fecha de carga',
            selector: (row: any) => row.chargeDate,
            sortable: true,
            sortField: 'chargeDate',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{momentParseDate(row?.chargeDate)}</span>
            )
        },
        {
            name: 'Guia carga',
            selector: (row: any) => row.chargeGuide,
            sortable: true,
            sortField: 'chargeGuide',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{row.chargeGuide}</span>
            )
        },
        {
            name: 'Estación combustible carga',
            selector: (row: any) => row.chargeFuelStation,
            sortable: true,
            sortField: 'chargeFuelStation',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{row.chargeFuelStation}</span>
            )
        },
        {
            name: 'Litros carga',
            selector: (row: any) => row.chargeLiters,
            sortable: true,
            sortField: 'chargeLiters',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{formatAmount(row.chargeLiters ?? 0)}</span>
            )
        },
        {
            name: 'Odómetro',
            selector: (row: any) => row.chargeOdometer,
            sortable: true,
            sortField: 'chargeOdometer',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{formatAmount(row.chargeOdometer ?? 0)}</span>
            )
        },
        {
            name: 'Kilometraje',
            selector: (row: any) => row.chargeKilometer,
            sortable: true,
            sortField: 'chargeKilometer',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{formatAmount(row.chargeKilometer ?? 0)}</span>
            )
        },
        {
            name: 'Id posición referencia',
            selector: (row: any) => row.referencePositionId,
            sortable: true,
            sortField: 'referencePositionId',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{row.referencePositionId}</span>
            )
        },
        {
            name: 'Fecha posición referencia',
            selector: (row: any) => row.referencePositionDate,
            sortable: true,
            sortField: 'referencePositionDate',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{momentParseDate(row?.referencePositionDate)}</span>
            )
        },
        {
            name: 'Odómetro referencia',
            selector: (row: any) => row.referenceOdometer,
            sortable: true,
            sortField: 'referenceOdometer',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{formatAmount(row.referenceOdometer ?? 0)}</span>
            )
        },
        {
            name: 'Tfu referencia',
            selector: (row: any) => row.referenceTfu,
            sortable: true,
            sortField: 'referenceTfu',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{formatAmount(row.referenceTfu ?? 0)}</span>
            )
        },
        {
            name: 'Litros calculados',
            selector: (row: any) => row.calculedDataLiters,
            sortable: true,
            sortField: 'calculedDataLiters',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{formatAmount(row.calculedDataLiters ?? 0)}</span>
            )
        },
        {
            name: 'Kilometros calculados',
            selector: (row: any) => row.calculedDataKilometers,
            sortable: true,
            sortField: 'calculedDataKilometers',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{formatAmount(row.calculedDataKilometers ?? 0)}</span>
            )
        },
        {
            name: 'Rendimiento calculado',
            selector: (row: any) => row.calculedDataPerformance,
            sortable: true,
            sortField: 'calculedDataPerformance',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{formatAmount(row.calculedDataPerformance ?? 0)}</span>
            )
        },
        {
            name: 'Rendimiento por carga calculado',
            selector: (row: any) => row.calculedDataPerformancePerCharge,
            sortable: true,
            sortField: 'calculedDataPerformancePerCharge',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">{formatAmount(row.calculedDataPerformancePerCharge ?? 0)}</span>
            )
        },
        {
            name: 'Diferencia litro por carga calculado',
            selector: (row: any) => row.calculedDataDiferenceLitersPerCharge,
            sortable: true,
            sortField: 'calculedDataDiferenceLitersPerCharge',
            cell: (row: FuelConsumption, index: any, column: any) => (
                <span className="">
                    {formatAmount(row.calculedDataDiferenceLitersPerCharge ?? 0)}
                </span>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <FuelConsumptionFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
                <div className="col-auto" style={{ paddingTop: 22 }}>
                    <button className="btn btn-success btn-sm" onClick={downloadExcel}>
                        <i className="fa fa-file-excel" /> Descargar
                    </button>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={fuelConsumptions}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default FuelConsumptionDataTable;
