import React from 'react';
import { IconProps } from '../../types';

export const Report: React.FC<IconProps> = ({ size = 22, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            width={size}
            height={size}
            viewBox="0 0 472.615 472.615"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <polygon points="351.738,9.848 351.738,88.615 430.504,88.615 " />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M332.045,108.308V0H32.265v472.615H440.35V108.308H332.045z M157.068,422.141H84.453V213.73h72.615V422.141z M272.615,422.141h-72.615V272.807h72.615V422.141z M388.162,422.141h-72.615v-90.257h72.615V422.141z" />
                    </g>
                </g>
            </g>
        </svg>
    );
};
