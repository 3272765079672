import React, { useContext, useEffect, useState } from 'react';

import useUserService from '../../../app/services/hooks/useUserService';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import UserForm from './forms/UserForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { Afp } from '../../../app/models/Afp';
import { Provision } from '../../../app/models/Provision';
import { Bank } from '../../../app/models/Bank';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { defaultUserDataForm, UserDataForm } from '../../../app/models/User';
import { Group } from '../../../app/models/Group';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const UserCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [afps, setAfps] = useState<Afp[]>([]);
    const [provisions, setProvisions] = useState<Provision[]>([]);
    const [banks, setBanks] = useState<Bank[]>([]);
    const [bankAccountTypes, setBankAccountTypes] = useState<BankAccountType[]>([]);
    const [user, setUser] = useState<UserDataForm>(defaultUserDataForm);
    const [groups, setGroups] = useState<Group[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingStoreUser, storeUser, fetchingCreateUser, createUser } = useUserService();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createUser({
            onSuccess: (response: ServiceResponse) => {
                setAfps(response.data.afps);
                setProvisions(response.data.provisions);
                setBanks(response.data.banks);
                setBankAccountTypes(response.data.bank_account_types);
                setGroups(response.data.groups);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        // useSweetAlert().spinnerAlert("Guardando usuario...");
        if (showLoading) showLoading('loading', 'Guardando usuario...');
        storeUser(user, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateUser ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <UserForm
                        userForm={user}
                        setUserForm={setUser}
                        afps={afps}
                        provisions={provisions}
                        banks={banks}
                        bankAccountTypes={bankAccountTypes}
                        errorFields={errorFields}
                        groups={groups}
                        isCreate={true}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreUser} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreUser} />
                </div>
            </div>
        </>
    );
};

export default UserCreate;
