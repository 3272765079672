import { Contract, ContractFilters } from '../../../../app/models/Contract';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { formatNumberWithDots } from '../../../../helpers';
import ContractSwitchChange from '../ContractSwitchChange';
import ContractFilter from './ContractFilter';

interface Props {
    contracts: Contract[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: ContractFilters;
    setFilter: (filter: ContractFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
    onSearch: () => void;
}

const ContractDataTable = ({
    contracts,
    totalRows,
    loading = false,
    edit,
    active,
    show,
    destroy,
    filter,
    setFilter,
    callbackSuccess,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: Contract, index: any, column: any) => <span className="">{row.id}</span>
        },
        {
            name: 'Trabajador',
            selector: (row: any) => row.worker_name,
            sortable: true,
            sortField: 'worker_name',
            cell: (row: any, index: any, column: any) => <span className="">{row.worker_name}</span>
        },
        {
            name: '¿Es indefinido?',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.is_indefinite}</span>
            )
        },
        {
            name: 'Fecha de inicio',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: any, index: any, column: any) => <span className="">{row.start_date}</span>
        },
        {
            name: 'Fecha de fin',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: any, index: any, column: any) => <span className="">{row.end_date}</span>
        },
        {
            name: 'Puesto',
            selector: (row: any) => row.job_title,
            sortable: true,
            sortField: 'job_title',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row?.job_title?.name}</span>
            )
        },
        {
            name: 'Salario base',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: any, index: any, column: any) => (
                <span className="">{formatNumberWithDots(row.base_salary)}</span>
            )
        },
        {
            name: 'Gratificación',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: any, index: any, column: any) => (
                <span className="">{row.applies_gratification}</span>
            )
        },
        // {
        //     name: 'Estatus',
        //     selector: (row: any) => row.status,
        //     sortable: true,
        //     sortField: 'status',
        //     cell: (row: Contract, index: any, column: any) => <span className="">{row.status}</span>
        // },
        {
            name: 'Activo',
            selector: (row: any) => row.is_active,
            sortable: true,
            sortField: 'is_active',
            cell: (row: Contract, index: any, column: any) => (
                <ContractSwitchChange
                    checkValue={row.is_active ?? true}
                    ContractId={row.id!}
                    callbackSuccess={callbackSuccess!}
                />
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: Contract, index: any, column: any) => (
                <div className="">
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <div>
            <div className="row mt-3">
                <div className="col">
                    <ContractFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={contracts}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </div>
    );
};

export default ContractDataTable;
