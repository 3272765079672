import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import useRouteSegmentService from '../../../app/services/hooks/useRouteSegmentService';
import { Place } from '../../../app/models/Place';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import {
    defaultRouteSegmentDataForm,
    RouteSegmentDataForm
} from '../../../app/models/RouteSegment';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import LazyLoading from '../../../components/LazyLoading';
import RouteSegmentFormContainer from './RouteSegmentFormContainer';
import { Toll } from '../../../app/models/Toll';

interface Props {
    routeSegmentId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const RouteSegmentEdit = ({ routeSegmentId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingEditRouteSegment,
        editRouteSegment,
        fetchingUpdateRouteSegment,
        updateRouteSegment
    } = useRouteSegmentService();
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [units, setUnits] = useState<Unit[]>([]);
    const [origins, setOrigins] = useState<Place[]>([]);
    const [destinations, setDestinations] = useState<Place[]>([]);
    const [tolls, setTolls] = useState<Toll[]>([]);
    const [routeSegment, setRouteSegment] = useState<RouteSegmentDataForm>(
        defaultRouteSegmentDataForm
    );

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando tramo...');
        editRouteSegment(routeSegmentId, {
            onSuccess: (response: ServiceResponse) => {
                setOrigins(response.data.origins);
                setDestinations(response.data.destinations);
                setTolls(response.data.tolls);
                setCurrencies(response.data.currencies);
                setUnits(response.data.units);
                const _route_segment = response.data.route_segment;
                _route_segment.tolls = response.data.route_segment.tolls.map((toll: any) => {
                    return {
                        value: toll.id,
                        label: toll.name
                    };
                });
                setRouteSegment(_route_segment);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando tramo...');
        updateRouteSegment(routeSegmentId, routeSegment, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditRouteSegment ? (
        <LazyLoading height="300" />
    ) : (
        <RouteSegmentFormContainer
            fetching={fetchingUpdateRouteSegment}
            action={update}
            cancel={cancel}
            currencies={currencies}
            units={units}
            routeSegmentForm={routeSegment}
            setRouteSegmentForm={setRouteSegment}
            origins={origins}
            destinations={destinations}
            tolls={tolls}
            errorFields={errorFields}
        />
    );
};

export default RouteSegmentEdit;
