export interface Deduction {
    id?: number;
    name: string;
    description?: string;
    amount?: number;
    currency_id?: number | string;
    type_of_deductions?: string;
    type_of_deduction?: string;
    start_date?: Date | string;
    end_date?: Date | string;
    is_active?: boolean;
}

export interface DeductionFilters {
    id?: number | null;
    name: string | '';
    description?: string | '';
    amount?: number | null;
    type_of_deductions?: TypeOfDeductions[]
    start_date?: Date | string;
    end_date?: Date | string;
    is_active?: boolean | '';

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface TypeOfDeductions {
    value: number | string;
    label: number | string;
}

export interface DeductionBasis {
    value: number | string;
    label: number | string;
}

export type TypeOfDeductionsValues = 'FIJO' | 'PORCENTAJE' | 'CALCULADO';

export type DeductionsBasisValues = 'TOTAL_IMPONIBLE' | 'BASE_TRIBUTARIA' | 'GASTO_DEL_EMPLEADOR';

export const typeOfDeductions: TypeOfDeductions[] = [
    {
        value: 'FIJA',
        label: 'Fija'
    },
    {
        value: 'PORCENTAJE',
        label: 'Porcentaje'
    }
 ]

 export const DeductionBasis: DeductionBasis[] = [
    {
        value: 'TOTAL_IMPONIBLE',
        label: 'Total Imponible'
    },
    {
        value: 'BASE_TRIBUTARIA',
        label: 'Base Tributaria'
    },
    {
        value: 'GASTO_DEL_EMPLEADOR',
        label: 'Gasto Del Empleador'
    }
 ]

export const defaultDeduction: Deduction = {
    name: '',
    description: '',
    start_date: '',
    end_date: ''
}