import React, { useContext, useEffect, useRef, useState } from 'react';
import { Product } from '../../../app/models/Product';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import { Service } from '../../../app/models/Service';
import useTransportationOrderItemService from '../../../app/services/hooks/useTransportationOrderItemService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import {
    defaultTransportationOrderCreateItemDataForm,
    defaultTransportationOrderItemDataForm,
    TransportationOrderItemCreateDataForm,
    TransportationOrderItemDataForm
} from '../../../app/models/TransportationOrderItem';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import TransportationOrderItemForm from './forms/TransportationOrderItemForm';
import LazyLoading from '../../../components/LazyLoading';
import { Routes } from '../../../app/models/RouteSegment';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';

import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    canBeEdited: boolean;
    transportationOrderId: number;
    currencyId: number | string;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message?: string) => void;
}

const TransportationOrderItemCreate = ({
    canBeEdited,
    transportationOrderId,
    currencyId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        createTransportationOrderItem,
        storeTransportationOrderItem,
        fetchingCreateTransportationOrderItem,
        fetchingStoreTransportationOrderItem
    } = useTransportationOrderItemService();

    const {
        fetchingGetQuoteRouteSegment,
        fetchingShowRsToTransportationOrderItem,
        getQuoteRouteSegment,
        showRsToTransportationOrderItem
    } = useQuoteRouteSegmentService();

    const [errorFields, setErrorFields] = useState<any>();

    const [transportationOrderItemForm, setTransportationOrderItemForm] =
        useState<TransportationOrderItemDataForm>({
            ...defaultTransportationOrderCreateItemDataForm,
            ['transportation_order_id']: transportationOrderId,
            ['service_id']: 0,
            ['product_id']: 0,
            ['quantity']: 0,
            ['route_id']: 0,
            ['dispatch_date']: '',
            ['description']: '',
            ['agreed_distance']: 0
        });

    const [products, setProducts] = useState<Product[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [routes, setRoutes] = useState<Routes[]>([]);
    const [hasQuote, setHasQuote] = useState<boolean>(false);
    const routeIdSelected = useRef<number | undefined>(0);

    useEffect(() => {
        create();
    }, []);

    // useEffect(() => {
    //     if (services.length) {
    //         setTransportationOrderItemForm({
    //             ...transportationOrderItemForm,
    //             service_id: services[0].id
    //         });
    //     }
    // }, [services]);

    useEffect(() => {
        if (transportationOrderItemForm.route_id == undefined) {
            routeIdSelected.current = undefined;
        }
        if (
            transportationOrderItemForm.route_id &&
            transportationOrderItemForm.route_id != routeIdSelected.current
        ) {
            if (hasQuote) {
                getQuoteRouteSegment(transportationOrderItemForm.route_id, {
                    onSuccess: (response: ServiceResponse) => {
                        setErrorFields(undefined);
                        routeIdSelected.current = transportationOrderItemForm.route_id ?? 0;
                        setTransportationOrderItemForm({
                            ...transportationOrderItemForm,
                            ...defaultTransportationOrderCreateItemDataForm,
                            ...response.data.quote_route_segment
                        });
                    },
                    onError: (response: ServiceResponse) => {
                        setTransportationOrderItemForm({
                            ...transportationOrderItemForm,
                            route_id: routeIdSelected.current
                        });
                    }
                });
            } else {
                showRsToTransportationOrderItem(0, transportationOrderItemForm.route_id, {
                    onSuccess: (response: ServiceResponse) => {
                        setErrorFields(undefined);
                        routeIdSelected.current = transportationOrderItemForm.route_id ?? 0;
                        setTransportationOrderItemForm({
                            ...transportationOrderItemForm,
                            ...defaultTransportationOrderCreateItemDataForm,
                            ...response.data.route_segment,
                            quantity_unit_id: response.data.route_segment.unit_id
                        });
                    },
                    onError: (response: ServiceResponse) => {
                        setTransportationOrderItemForm({
                            ...transportationOrderItemForm,
                            route_id: routeIdSelected.current
                        });
                    }
                });
            }
        }
    }, [transportationOrderItemForm.route_id]);

    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createTransportationOrderItem(transportationOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setProducts(response.data.products);
                setQuantityUnits(response.data.quantity_units);
                setCurrencies(response.data.currencies);
                setServices(response.data.services);

                if (response.data.routes_data.quote_route_segment) {
                    setRoutes(response.data.routes_data.routes);
                    setHasQuote(true);
                    setTransportationOrderItemForm({
                        ...transportationOrderItemForm,
                        route_id: response.data.routes_data.quote_route_segment.id,
                        ...response.data.routes_data.quote_route_segment
                    });
                } else {
                    setRoutes(response.data.routes_data.routes);
                }
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando detalle pedido...');

        storeTransportationOrderItem(transportationOrderItemForm, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {!fetchingCreateTransportationOrderItem ? (
                        <>
                            <TransportationOrderItemForm
                                products={products}
                                quantityUnits={quantityUnits}
                                currencies={currencies}
                                services={services}
                                routes={routes}
                                hasQuote={hasQuote}
                                transportationOrderItemForm={transportationOrderItemForm}
                                setTransportationOrderItemForm={setTransportationOrderItemForm}
                                errorFields={errorFields}
                                canBeEdited={true}
                                fetching={
                                    fetchingGetQuoteRouteSegment ||
                                    fetchingShowRsToTransportationOrderItem
                                }
                            />
                            {/*<TransportationOrderRatesForm*/}
                            {/*    currencies={currencies}*/}
                            {/*    quantityUnits={quantityUnits}*/}
                            {/*    transportationOrderitemForm={transportationOrderItemForm}*/}
                            {/*    setTransportationOrderItemForm={setTransportationOrderItemForm}*/}
                            {/*    errorFields={errorFields}*/}
                            {/*/> */}
                        </>
                    ) : (
                        <LazyLoading height="300" />
                    )}
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreTransportationOrderItem}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={store}
                        locked={fetchingStoreTransportationOrderItem}
                    />
                </div>
            </div>
        </>
    );
};

export default TransportationOrderItemCreate;
