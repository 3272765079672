import { useContext, useState, useEffect } from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useTravelExpenseService from '../../../app/services/hooks/useTravelExpenseService';
import TravelExpenseForm from './forms/TravelExpenseForm';
import { AppContext } from '../../../contexts/AppContext';
import {
    TravelExpenseDataForm,
    defaultTravelExpenseDataForm
} from '../../../app/models/TravelExpense';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import { EventType } from '../../../app/models/EventType';
import LazyLoading from '../../../components/LazyLoading';
import { Currency } from '../../../app/models/Currency';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const TravelExpenseCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingStoreTravelExpenses,
        storeTravelExpense,
        createTravelExpense,
        fetchingCreateTravelExpenses
    } = useTravelExpenseService();
    const [errorFields, setErrorFields] = useState<any>();
    const [eventTypes, setEventTypes] = useState<EventType[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [travelExpense, setTravelExpense] = useState<TravelExpenseDataForm>(
        defaultTravelExpenseDataForm
    );

    const cancel = () => {
        if (onCancel) onCancel();
    };

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createTravelExpense({
            onSuccess: (response: ServiceResponse) => {
                setEventTypes(response.data.event_types);
                setCurrencies(response.data.currencies);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando viático...');

        storeTravelExpense(travelExpense, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    return fetchingCreateTravelExpenses ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <TravelExpenseForm
                        travelExpenseDataForm={travelExpense}
                        setTravelExpenseDataForm={setTravelExpense}
                        errorFields={errorFields}
                        eventTypes={eventTypes}
                        currencies={currencies}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreTravelExpenses}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreTravelExpenses} />
                </div>
            </div>
        </>
    );
};

export default TravelExpenseCreate;
