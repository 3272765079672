import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import LazyLoading from '../../../../components/LazyLoading';
import ButtonCancelForm from '../../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../../components/buttons/ButtonSaveForm';
import {
    JobTitleLiquidationSet,
    JobTitleLiquidationSetDataForm,
    defaultJobTitleLiquidationSetDataForm
} from '../../../../app/models/JobTitleLiquidationSet';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import JobTitleLiquidationSetForm from '../forms/JobTitleLiquidationSetForm';
import useJobTitleLiquidationSetService from '../../../../app/services/hooks/useJobTitleLiquidationSetService';
import { Deduction } from '../../../../app/models/Deduction';
import { Bonus } from '../../../../app/models/Bonus';
import { JobTitle } from '../../../../app/models/JobTitle';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const JobTitleLiquidationSetCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const [jobTitleLiquidationSet, setJobTitleLiquidationSet] =
        useState<JobTitleLiquidationSetDataForm>(defaultJobTitleLiquidationSetDataForm);

    const [deductions, setDeductions] = useState<Deduction[]>([]);
    const [bonuses, setBonuses] = useState<Bonus[]>([]);
    const [jobTitles, setJobTitles] = useState<JobTitle[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    const {
        createJobTitleLiquidationSet,
        storeJobTitleLiquidationSet,
        fetchingCreateJobTitleLiquidationSet,
        fetchingStoreJobTitleLiquidationSet
    } = useJobTitleLiquidationSetService();

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        createJobTitleLiquidationSet({
            onSuccess: (response: ServiceResponse) => {
                setDeductions(response.data.deductions);
                setBonuses(response.data.bonuses);
                setJobTitles(response.data.job_titles);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando set de liquidación...');
        storeJobTitleLiquidationSet(jobTitleLiquidationSet, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateJobTitleLiquidationSet ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <JobTitleLiquidationSetForm
                        jobTitleLiquidationSet={jobTitleLiquidationSet}
                        setJobTitleLiquidationSet={setJobTitleLiquidationSet}
                        deductions={deductions}
                        bonuses={bonuses}
                        jobTitles={jobTitles}
                        errorFields={errorFields}
                        setErrorFields={setErrorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreJobTitleLiquidationSet}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={store}
                        locked={fetchingStoreJobTitleLiquidationSet}
                    />
                </div>
            </div>
        </>
    );
};

export default JobTitleLiquidationSetCreate;
