import React, { useContext, useEffect, useState } from 'react';
import { defaultEventType, EventType } from '../../../../app/models/EventType';
import useEventTypeService from '../../../../app/services/hooks/useEventTypeService';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import LazyLoading from '../../../../components/LazyLoading';
import EventTypeFormContainer from '../EventTypeFormContainer';
import { AppContext } from '../../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';

interface Props {
    eventTypeId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const EventTypeEdit = ({ eventTypeId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditEventType, editEventType, fetchingUpdateEventType, updateEventType } =
        useEventTypeService();

    const [eventType, setEventType] = useState<EventType>(defaultEventType);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando evento...');
        editEventType(eventTypeId, {
            onSuccess: (response: ServiceResponse) => {
                setEventType(response.data.event_type);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando evento...');
        updateEventType(eventTypeId, eventType, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditEventType ? (
        <LazyLoading height="300" />
    ) : (
        <EventTypeFormContainer
            fetching={fetchingUpdateEventType}
            action={update}
            cancel={cancel}
            eventType={eventType}
            setEventType={setEventType}
            errorFields={errorFields}
        />
    );
};

export default EventTypeEdit;
