import React, { useContext, useState } from 'react';
import useIncidentService from '../../../app/services/hooks/useIncidentService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import { IncidentStatusRequestDataForm } from '../../../app/models/Incident';

interface Props {
    incidentId: number;
    statuses: IncidentStatusRequestDataForm[];
    selectedStatus: IncidentStatusRequestDataForm;
    callbackSuccess?: () => void;
}

const ChangeStatusIncident = ({ incidentId, statuses, selectedStatus, callbackSuccess }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const [selected, setSelected] = useState<IncidentStatusRequestDataForm>(selectedStatus);

    const { openIncident, closeIncident, annulIncident } = useIncidentService();

    const eventChangeStatus = {
        onSuccess: (response: ServiceResponse) => {
            if (hideLoading) hideLoading();
            if (callbackSuccess) {
                callbackSuccess();
            }

            useReactConfirmAlert().successAlert({
                title: 'Éxito',
                message: 'Se ha cambiado el estado del incidente # ' + incidentId
            });
        },
        onError: (response: ServiceResponse) => {
            if (hideLoading) hideLoading();
            useReactConfirmAlert().errorAlert({
                title: 'Error',
                message: response.message
            });
            toast.error(response.message);
            setSelected(selectedStatus);
        }
    };

    const changeStatus = (value: string) => {
        if (value === selected) return;

        const _status = statuses.find((status: IncidentStatusRequestDataForm) => status.id == value)

        setSelected(_status!);


        const _text = 'Está a punto de cambiar el estado del incidente ' +
        incidentId+
        ' a ' + 
        (_status ? _status.name : '');

         

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Sí, cambiar estado',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estado...');
                        if (value === 'OPEN') {
                            openIncident(incidentId, eventChangeStatus);
                        } else if (value === 'CLOSE') {
                            closeIncident(incidentId, eventChangeStatus);
                        } else if (value === 'ANNULLED') {
                            annulIncident(incidentId, eventChangeStatus);
                        } else {
                            if (hideLoading) hideLoading();
                            toast.error('Estado no reconocido');
                        }
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setSelected(selectedStatus);
                    }
                }
            }
        });
    };

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => changeStatus(e.target.value)}
        >
            <option value={selectedStatus.id}>{selectedStatus.name}</option>
            {statuses.map((status) => {
                return (
                    <option key={status.id} value={status.id}>
                        {status.name}
                    </option>
                );
            })}
        </select>
    );
};

export default ChangeStatusIncident;
