export interface EventType {
    id?: number;
    name: string;
    color: string;
    is_active?: boolean;
    is_calendar_request?: boolean;
    is_travel_expense?: boolean;
    is_business_day?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export interface EventTypeFilters {
    id?: number | null;
    name?: string | null;
    color?: string | '';
    is_active?: boolean | '';
    is_calendar_request?: boolean;
    is_travel_expense?: boolean;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface EventTypeDataForm {
    id?: number;
    name: string;
    color: string;
    is_calendar_request?: boolean;
    is_travel_expense?: boolean;
    is_business_day?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

export const defaultEventType: EventType = {
    name: '',
    color: '#FFFFFF'
};
