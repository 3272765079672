import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { toast } from 'react-toastify';
import { Provider } from '../../../app/models/Provider';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import useTollService from '../../../app/services/hooks/useTollService';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { TollDataForm, defaultTollDataForm } from '../../../app/models/Toll';
import TollForm from '../components/forms/TollForm';
import TollRateContainer from './TollRateContainer';
import { Commune } from '../../../app/models/Commune';
import { PaymentMethod } from '../../../app/models/PaymentMethod';

type RouterParams = {
    id: string;
};

const TollEdit = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();
    const [verifyingToll, setVerifyingToll] = useState<boolean>(true);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [toll, setToll] = useState<TollDataForm>(defaultTollDataForm);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [errorFields, setErrorFields] = useState<any>();
    const { editToll, updateToll, fetchingEditToll, fetchingShowToll, fetchingUpdateToll } =
        useTollService();

    useEffect(() => {
        edit(parseInt(id));
    }, [id]);

    const edit = (id: number) => {
        editToll(id, {
            onSuccess: (response: ServiceResponse) => {
                setToll(response.data.toll);
                setProviders(response.data.providers);
                setCommunes(response.data.communes);
                setPaymentMethods(response.data.payment_methods);

                setVerifyingToll(false);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    const update = () => {
        if (toll) {
            if (showLoading) showLoading('loading', 'Actualizando...');

            updateToll(Number(toll?.id), toll, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    setErrorFields(undefined);
                    edit(parseInt(id));
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Peajes',
            url: '/tolls',
            isActive: false
        },
        {
            name: 'Editar #' + id,
            url: '/tolls/' + id + '/edit',
            isActive: true
        }
    ];

    if (verifyingToll) {
        return (
            <DefaultCard>
                <LazyLoading height={300} />
            </DefaultCard>
        );
    }

    return (
        <>
            <Breadcrumbs pageSection={`Editar #${id}`} breadcrumbs={breadcrumbs} />

            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {toll && (
                            <TollForm
                                tollForm={toll}
                                setTollForm={setToll}
                                providers={providers}
                                communes={communes}
                                payment_methods={paymentMethods}
                                errorFields={errorFields}
                            />
                        )}
                        <div className="row justify-content-end">
                            <div className="col-auto">
                                <ButtonSaveForm callbackSave={update} locked={fetchingUpdateToll} />
                            </div>
                        </div>
                    </DefaultCard>
                </div>
            </div>

            {!fetchingEditToll && !fetchingShowToll ? (
                <>
                    <TollRateContainer tollId={parseInt(id)} />
                </>
            ) : null}
        </>
    );
};

export default TollEdit;
