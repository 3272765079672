import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { DocumentSet, DocumentSetDataForm, DocumentSetFilters } from '../../models/DocumentSet';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';

const useDocumentSetService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetDocumentSets, setFetchingGetDocumentSets] = useState(false);
    const [fetchingDeleteDocumentSet, setFetchingDeleteDocumentSet] = useState(false);
    const [fetchingActiveDocumentSet, setFetchingActiveDocumentSet] = useState(false);
    const [fetchingStoreDocumentSet, setFetchingStoreDocumentSet] = useState(false);
    const [fetchingEditDocumentSet, setFetchingEditDocumentSet] = useState(false);
    const [fetchingUpdateDocumentSet, setFetchingUpdateDocumentSet] = useState(false);
    const [fetchingCreateDocumentSet, setFetchingCreateDocumentSet] = useState(false);

    const getDocumentSets = (
        DocumentSetFilters: DocumentSetFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(DocumentSetFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.DOCUMENT_SETS.GET_DOCUMENT_SET}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetDocumentSets
        });
    };

    const createDocumentSet = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DOCUMENT_SETS.CREATE_DOCUMENT_SET,
            setFetching: setFetchingCreateDocumentSet
        });
    };

    const storeDocumentSet = (documentSet: DocumentSetDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.DOCUMENT_SETS.STORE_DOCUMENT_SET,
            body: documentSet,
            setFetching: setFetchingStoreDocumentSet
        });
    };

    const editDocumentSet = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.DOCUMENT_SETS.EDIT_DOCUMENT_SET.replace(':id', id.toString()),
            setFetching: setFetchingEditDocumentSet
        });
    };

    const activeDocumentSet = (id: number, active: boolean, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            body: { active: active },
            url: EndPoints.DOCUMENT_SETS.ACTIVE_DOCUMENT_SET.replace(':id', id.toString()),
            setFetching: setFetchingActiveDocumentSet
        });
    };

    const updateDocumentSet = (
        id: number,
        documentSet: DocumentSetDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.DOCUMENT_SETS.UPDATE_DOCUMENT_SET.replace(':id', id.toString()),
            body: documentSet,
            setFetching: setFetchingUpdateDocumentSet
        });
    };

    const deleteDocumentSet = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.DOCUMENT_SETS.DELETE_DOCUMENT_SET.replace(':id', id.toString()),
            setFetching: setFetchingDeleteDocumentSet
        });
    };

    return {
        fetchingGetDocumentSets,
        fetchingDeleteDocumentSet,
        fetchingActiveDocumentSet,
        fetchingEditDocumentSet,
        fetchingUpdateDocumentSet,
        fetchingStoreDocumentSet,
        fetchingCreateDocumentSet,
        getDocumentSets,
        deleteDocumentSet,
        editDocumentSet,
        activeDocumentSet,
        updateDocumentSet,
        storeDocumentSet,
        createDocumentSet
    };
};

export default useDocumentSetService;
